import { injectSaga } from '../store/sagas';
import ordersSaga from './OrdersView/OrdersViewSaga';
import orderDetailsSaga from './OrderDetails/OrderDetailsSaga';
import OrderItemsSelectionSaga from './OrderDetails/OrderItemsSelection/OrderItemsSelectionSaga';
import summaryViewSaga from './OrderDetails/SummaryView/SummaryViewSaga';
import StatusViewSaga from './OrderDetails/StatusView/StatusViewSaga';
import ShipmentViewSaga from './OrderDetails/ShipmentView/ShipmentViewSaga';
import HelpDataSaga from './HelpData/HelpDataSaga';
import HistoryViewSaga from './OrderDetails/HistoryView/HistoryViewSaga';
import BillingViewSaga from './OrderDetails/BillingView/BillingViewSaga';

const registerOrdersSagas = () => {
  injectSaga(HistoryViewSaga);
  injectSaga(HelpDataSaga);
  injectSaga(ordersSaga);
  injectSaga(orderDetailsSaga);
  injectSaga(summaryViewSaga);
  injectSaga(StatusViewSaga);
  injectSaga(ShipmentViewSaga);
  injectSaga(BillingViewSaga);
  injectSaga(OrderItemsSelectionSaga);
};

export default registerOrdersSagas;
