import React from 'react';
import PropTypes from 'prop-types';
import analyticsService from '../../../../../services/analyticsService';
import Button from '../../../../shared/Button';
import Icon from '../../../../shared/Icon';
import { COLORS } from '../../../../../constants';
import Thumbnail from '../../../../shared/Thumbnail';
import { NECK_LABEL_PRICE } from '../../../../../const/NeckLabel';
import Tooltip from '../../../../shared/Tooltip';

class VariantListLine extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.space.id !== nextProps.space.id ||
      this.props.selected !== nextProps.selected ||
      this.props.space.images !== nextProps.space.images ||
      this.props.space.previewImage !== nextProps.space.previewImage ||
      this.props.space.images.some(i => !nextProps.space.images.some(ni => ni === i)) ||
      this.props.variant.neck_tag_id !== nextProps.variant.neck_tag_id ||
      this.props.variant.neck_tag_image_url !== nextProps.variant.neck_tag_image_url
    );
  }

  isVariantValid(variant) {
    return variant.spaces.every(s => this.isSpaceValid(s));
  }

  isSpaceValid(space) {
    if (!space.images || !space.images.length) {
      return true;
    } else if (space.dumpedState) {
      return space.dumpedState.images.current.images.every(i => i.scale <= 1);
    } else if (
      space.il &&
      space.il.layers.length > 0 &&
      space.il.layers.some(l => l.images && l.images.length)
    ) {
      // some print IL layers can be design layers which include in print
      // they will not have images
      const imageLayer = space.il.layers.find(l => l.images && l.images.length);
      return imageLayer.images.every(i => i.scaleDx <= 1);
    } else {
      return space.images.every(
        i =>
          i.width >= space.template.requiredImageSize.width &&
          i.height >= space.template.requiredImageSize.height
      );
    }
  }

  getCost(variant) {
    const priceDelta = 0.001;
    const neckLabelCost =
      !!variant.neck_tag_id || !!variant.neck_tag_image_url ? NECK_LABEL_PRICE : 0;
    const price =
      !variant.maxCost || Math.abs(variant.maxCost - variant.minCost) < priceDelta
        ? '$' + (variant.minCost + neckLabelCost || 0).toFixed(2)
        : '$' +
          (variant.minCost + neckLabelCost || 0).toFixed(2) +
          '-$' +
          (variant.maxCost + neckLabelCost || 0).toFixed(2);

    return price;
  }

  onUploadImageClick() {
    analyticsService.trackWithDynamicSource('Image upload', 'SKU image upload clicked');
    this.props.onUploadImageClick();
  }

  render() {
    let hasImages = !!(this.props.space && this.props.space.images.length);

    let variantImage = null;
    if (this.props.space && this.props.space.base64 && this.props.space.images.length) {
      variantImage = this.props.space.base64;
    }

    let valid = this.isVariantValid(this.props.variant);
    let cost = this.getCost(this.props.variant);

    return (
      <div className="variant-list-line-container mb-3 position-relative">
        {!valid ? (
          <Icon className="display-icon-status" fill={COLORS.ochre} icon="alertCircle" />
        ) : null}
        {valid && this.props.space.images && this.props.space.images.length ? (
          <Icon className="display-icon-status" fill={COLORS.monstera} icon="check" />
        ) : null}
        <Tooltip content={this.props.variant.sku} position="top" containerClassName="w-100">
          <div
            className={`d-flex mb-2 variant-list-line ${this.props.selected ? 'active' : ''} ${
              valid ? '' : 'invalid'
            }`}
            onClick={() => this.props.onSelect()}
          >
            {variantImage ? <Thumbnail className="small" image={variantImage} /> : null}
            <div className="variant-name p-2 pl-3">{this.props.variant.sku}</div>
            <div className="variant-price p-2 text-right">{cost}</div>
          </div>
        </Tooltip>
        {!this.props.selected ? null : (
          <div className="variant-list-line-details d-flex justify-content-end">
            <Button
              className="button-default medium mr-2 upload-artwork"
              onClick={() => this.onUploadImageClick()}
            >
              <Icon icon="image" />
              {hasImages ? 'Replace artwork' : 'Upload artwork'}
            </Button>
            {this.props.disableDelete ? null : (
              <Button
                className="button-default medium delete-sku"
                onClick={() => this.props.onVariantDeleteClick()}
              >
                <Icon icon="trash2" />
                Delete SKU
              </Button>
            )}
          </div>
        )}
        <style jsx>
          {`
            .variant-list-line-container :global(.display-icon-status) {
              position: absolute;
              left: -30px;
              top: 9px;
              font-size: 19px;
            }
            .variant-list-line {
              border: 1px solid ${COLORS.neutralDark200};
              cursor: pointer;
              position: relative;
              white-space: nowrap;
              border-radius: 4px;
              overflow: hidden;
            }
            .variant-list-line.invalid,
            .variant-list-line.invalid .variant-icon,
            .variant-list-line.invalid .variant-price {
              border-color: ${COLORS.ochre};
            }
            .variant-list-line-container :global(.cerulean-thumbnail) {
              border: none;
            }
            .variant-icon {
              border-right: 1px solid ${COLORS.neutralDark200};
            }
            .variant-name {
              text-align: left;
              text-overflow: ellipsis;
              overflow: hidden;
              flex-grow: 1;
            }
            .variant-price {
              border-left: 1px solid ${COLORS.neutralDark200};
              min-width: 4rem;
            }
          `}
        </style>
      </div>
    );
  }

  static propTypes = {
    disableDelete: PropTypes.bool,
    variant: PropTypes.object,
    space: PropTypes.object,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    onUploadImageClick: PropTypes.func,
    onVariantDeleteClick: PropTypes.func
  };
}

export default VariantListLine;
