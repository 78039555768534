import { combineReducers } from 'redux-immutable';
import providersReducer from './components/Providers/ProvidersReducer';
import storesReducer from './components/Stores/StoresReducer';
import searchReducer from './components/Search/SearchReducer';
import productsReducer from './components/Products/ProductsReducer';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

export default combineReducers({
  providers: providersReducer,
  stores: storesReducer,
  search: searchReducer,
  products: productsReducer
});
