class TemplateInitializationService {
  prepareSpaceTemplate(variantTemplate, spaceId) {
    var spaceInfo = variantTemplate.Spaces.find(s => s.Id === spaceId);
    if (!spaceInfo) {
      return null;
    }

    if (!spaceInfo.Layers.some(l => l.Type.toLowerCase() === 'image')) {
      return null;
    }

    let smallestImageSize = spaceInfo.Layers.filter(l => l.Type === 'Image').reduce(
      (a, l) => {
        const lw = l.X2 - l.X1;
        const lh = l.Y2 - l.Y1;
        a.width = Math.min(lw, a.width);
        a.height = Math.min(lh, a.height);
        return a;
      },
      { width: Number.MAX_SAFE_INTEGER, height: Number.MAX_SAFE_INTEGER }
    );

    return {
      rotation: spaceInfo.DefaultRotation || 0,
      spaceId: spaceInfo.Id,
      id: spaceInfo.Id,
      dpi: variantTemplate.DPI || 300,
      requiredImageSize: smallestImageSize,
      layers: spaceInfo.Layers.map(l => {
        let ml = {
          id: l.Id,
          type: l.Type.toLowerCase(), // design | image | viewport | action
          mask: l.Description === 'mask',
          zindex: l.ZIndex,
          x1: l.X1,
          x2: l.X2,
          y1: l.Y1,
          y2: l.Y2,
          imageurl: l.BackgroundImageUrl || l.OverlayImageUrl || l.BleedImageUrl,
          print: l.IncludeInPrint === true
        };

        if (ml.type === 'image') {
          ml.imageFill = l.ImageFill === 'CanFill' ? 'CanFill' : 'MustFill';
          ml.positioning = l.Positioning;
        }

        return ml;
      })
    };
  }
}

// singleton
export default new TemplateInitializationService();
