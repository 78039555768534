import { PREPARING, READY } from './ProductDesignViewActions';
import { RESET } from '../../../store/actions/globalActions';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['hub', 'linkProduct', 'productDesignView']);

const productDesignViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global' || action.payload.scope === 'link') {
        return defaultState;
      } else {
        return state;
      }
    }
    case PREPARING: {
      return state
        .set('connectExistingData', action.payload.connectExistingData)
        .set('stores', action.payload.stores)
        .set('preparing', true)
        .set('ready', false);
    }
    case READY: {
      return state.set('preparing', false).set('ready', true);
    }
    default:
      return state;
  }
};

export default productDesignViewReducer;
