import React from 'react';
import PropTypes from 'prop-types';
import './PrintGuidelines.scss';

export const PrintGuidelines = props => {
  return (
    <div className="print-guidelines">
      <span className="title">Must-follow Guidelines</span>
      <hr />
      <span>Safe Print Area</span>
      <div className="preview lines" />
      <div className="bleed-line">
        <span>Bleed Line</span>
        <div className="preview dots" />
      </div>
      <hr />
    </div>
  );
};

export default PrintGuidelines;
