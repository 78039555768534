import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB-LINK', 'PRODUCT_SELECTION_VIEW');

// ------------------------------------
// Constants
// ------------------------------------
export const READY = getActionName.async('READY');

export const INIT = getActionName.async('INIT');

export const LOAD_STORE_PRODUCTS = getActionName.async('LOAD_STORE_PRODUCTS');

export const CHECK_STORE_INDEXING = getActionName.async('CHECK_STORE_INDEXING');
