import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { fetchShippingData } from './ShipmentViewService';
import { updateShipmentData, GET_SHIPMENT_DATA } from './ShipmentViewActions';

export function* handleShipmentDataRequest(action) {
  let ordersData = yield call(fetchShippingData, action.payload.orderId);
  yield put(updateShipmentData(fromJS(ordersData)));
}

export function* watchShipmentRequest() {
  yield takeLatest(GET_SHIPMENT_DATA, handleShipmentDataRequest);
}

export default function* rootSaga() {
  yield all([watchShipmentRequest()]);
}
