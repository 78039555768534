import { getActionName } from '../../ProductSelectionActions';

// ------------------------------------
// Constants
// ------------------------------------
export const CATEGORY_HOVER = getActionName('CATEGORY_HOVER');
export const CATEGORY_SELECT = getActionName('CATEGORY_SELECT');
export const SUBCATEGORY_SELECT = getActionName('SUBCATEGORY_SELECT');
export const SUBCATEGORY_FORCE_SCROLL_DONE = getActionName('SUBCATEGORY_FORCE_SCROLL_DONE');

// ------------------------------------
// Actions
// ------------------------------------
export const selectCategory = (type, value = '') => {
  return {
    type,
    payload: value
  };
};

export const forceScrollDone = type => {
  return {
    type: SUBCATEGORY_FORCE_SCROLL_DONE
  };
};
