import { fetch, post, fileUpload, $delete } from '../../../../utils/http';
import { getImageResizerUrl, getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import Config from '../../../../config';
import { getGuid } from '../../../../utils/random';

const getAuthQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId')
  }
});

class ImageSelectionService {
  checkRecipeImages() {
    // NOTE: type=null means all
    let url = `${Config.get('api')}images?type=null&take=1&skip=0`;
    return fetch(url, getAuthQueryParams()).then(apiData => apiData.length > 0);
  }

  getImageTypeString(type) {
    switch (type) {
      case 1:
        return 'square';
      case 2:
        return 'landscape';
      case 3:
        return 'portrait';
      default:
        return 'all';
    }
  }

  getImageTypeNumber(type) {
    switch (type) {
      case 'square':
        return 1;
      case 'landscape':
        return 2;
      case 'portrait':
        return 3;
      default:
        return 4; // all
    }
  }

  getRecipeImages(typeString, page = 0) {
    let type;
    let url;

    // Match all custom sizes like 1200x750, 1500x750, etc.
    let customType = typeString.match(/\d{1,}[x]\d{1,}/g);

    // Check if there are custom sizes
    if (customType && customType.length) {
      if (customType.length === 1) {
        // Single size: Use the old format with width and height parameters
        const [width, height] = customType[0].split('x');
        customType = `width=${width}&height=${height}`;
      } else {
        // Multiple sizes: Use the new format with imageSizes[0].Width and imageSizes[0].Height
        customType = customType
          .map((size, index) => {
            const [width, height] = size.split('x');
            return `imageSizes[${index}].width=${width}&imageSizes[${index}].height=${height}`;
          })
          .join('&');
      }
    } else {
      type = this.getImageTypeNumber(typeString);
    }

    // if customType exist, then ignore type
    if (customType) {
      url = `${Config.get('api')}images?take=${this.pageSize}&skip=${
        page * this.pageSize
      }&type=null&${customType}`;
    } else {
      // types: Recent=0, Square=1, Landscape=2, Portrait=3, All=4
      url =
        type === 0 // recent tab
          ? `${Config.get('api')}images?recentDays=${this.recentDays}&take=${
              this.recentPageSize
            }&skip=0`
          : `${Config.get('api')}images?take=${this.pageSize}&skip=${page * this.pageSize}&type=${
              type === 4 ? 'null' : type
            }`;
    }

    return fetch(url, getAuthQueryParams()).then(apiData => this.mapRecipeImages(apiData));
  }

  getImageType(image) {
    const info = JSON.parse(image.InfoJSON);

    if (info.Digitization && info.Digitization.toLowerCase() === 'completed') {
      return 'emb';
    } else if (info.StitchFileUrl) {
      return 'dst';
    } else {
      return image.Url.split('.').pop();
    }
  }

  mapRecipeImages(apiData) {
    return apiData
      .filter(i => !!i.Url)
      .map(i => {
        const info = JSON.parse(i.InfoJSON);
        const img = {
          id: i.Id,
          type: i.Type,
          previewUrl: getSmallPreviewImageResizerUrl(i.Url),
          url: getImageResizerUrl(i.Url, 1000, 1000),
          fullSizeUrl: i.Url,
          width: info.Width,
          height: info.Height,
          caption: 'Missing title',
          imageType: this.getImageType(i),
          resolution: info.Width * info.Height,
          digitization: info.Digitization || '',
          stitchFileUrl: info.StitchFileUrl || '',
          embroideryPreviewUrl: info.EmbroideryPreviewUrl || '',
          colorKeyUrl: info.ColorKeyUrl || ''
        };

        return img;
      });
  }

  uploadImageFromUrl(url) {
    return post(`${Config.get('imageUploadApi')}upload/urls`, JSON.stringify({ urls: [url] }), {
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'X-Gooten-Secret': 'GTN-60ef2171'
      }
    }).then(apiData => ({ url: apiData[0].Url, error: !apiData[0].Uploaded }));
  }

  getS3Config() {
    return fetch(`${Config.get('api')}images/getAwsSignature/aws`, getAuthQueryParams());
  }

  uploadImageFile(file, s3Config, name = '', ext = '') {
    let fileType;
    switch (ext.toLowerCase()) {
      case 'pdf':
        fileType = 'image/pdf';
        break;
      case 'dst':
        fileType = 'image/dst';
        break;
      default:
        fileType = file.type;
        break;
    }

    const bucket = Config.get('env') === 'staging' ? 'printio-widget-dev' : 'printio-widget-live';
    const uploadUrl = `https://${bucket}.s3.amazonaws.com/`;
    const imgName = `${getGuid()}.${ext.length ? ext : file.name.split('.').pop()}`;
    let formData = new FormData();
    formData.append('key', imgName);
    formData.append('acl', 'public-read');
    formData.append('bucket', bucket);
    formData.append('AWSAccessKeyId', 'AKIAITBFM26J6GIXM5QA');
    formData.append('policy', s3Config.policy);
    formData.append('signature', s3Config.signature);
    formData.append('Content-Type', fileType);
    formData.append('success_action_status', '201');
    formData.append('file', file, name.length ? name : file.name);
    return fileUpload(uploadUrl, formData).then(() => ({
      url: `${uploadUrl}${imgName}`
    }));
  }

  createRecipeImages(recipeImagesReqBody) {
    return post(
      `${Config.get('api')}images/createRecipeImages/submit?recipeid=${Config.get('recipeId')}`,
      JSON.stringify(recipeImagesReqBody),
      {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }
    ).then(createdImages => this.mapRecipeImages(createdImages));
  }

  removeRecipeImage(recipeImageId) {
    return $delete(`${Config.get('api')}images?id=${recipeImageId}`, null, {
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      ...getAuthQueryParams()
    });
  }

  isFileImage(f) {
    // f can be File holder (like for DST files) which holds file and some additional data
    // like in this format: { file: File, ...additionalData }
    const file = f.file || f;
    return (
      ['png', 'jpeg', 'jpg'].includes(file.name?.split('.').pop().toLowerCase()) ||
      file.type?.indexOf('image') !== -1
    );
  }

  isUrlImage(url) {
    return ['png', 'jpeg', 'jpg'].includes(url.split('.').pop().toLowerCase());
  }

  filterImageFiles(files) {
    return Array.isArray(files)
      ? files.filter(f => this.isFileImage(f))
      : files && this.isFileImage(files)
      ? files
      : null;
  }

  filterImageUrls(urls) {
    return Array.isArray(urls)
      ? urls.filter(url => this.isUrlImage(url))
      : urls && this.isUrlImage(urls)
      ? urls
      : null;
  }
}

ImageSelectionService.prototype.pageSize = 50;
ImageSelectionService.prototype.recentDays = 1;
ImageSelectionService.prototype.recentPageSize = 10;

export default new ImageSelectionService();
