import { GET_SOME_DATA } from './PricingModalActions';

const initialState = {
  pricingModal: {}
};

export const pricingModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SOME_DATA:
      return Object.assign({}, state, {
        pricingModal: true
      });
    default:
      return state;
  }
};
