import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../config';

class CartViewService {
  loadGootenMappingData(query) {
    // url
    const url = `${Config.get('storeApi')}gooten_mappings`;

    // request params
    const queryParams = {
      ...this.getAuthQueryParams(),
      ids: query.ids
    };

    return fetch(url, { queryParams }).then(res => {
      return res;
    });
  }

  // recipeId, partnerBillingKey auth params
  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new CartViewService();
