import React from 'react';
import { COLORS } from '../../constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Tooltip from './Tooltip';

const TableRow = ({
  columns,
  children,
  size = 'auto',
  onClick,
  header,
  className,
  disabled,
  id,
  scrollableX
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={`table-container ${className || ''} ${size} ${header ? 'header-row' : ''}`}
    >
      {children}
      <style jsx>
        {`
          .table-container {
            position: relative;
            display: grid;
            width: 100%;
            grid-template-columns: ${columns || `repeat(${children.length}, auto)`};
            cursor: ${disabled ? 'not-allowed' : 'default'};
            overflow-x: ${scrollableX ? 'auto' : 'unset'};
            overflow-y: visible;
          }
        `}
      </style>
    </div>
  );
};

TableRow.propTypes = {
  columns: PropTypes.string,
  children: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'auto']),
  onClick: PropTypes.func,
  header: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  scrollableX: PropTypes.bool
};

const Cell = ({
  children,
  rightAlign,
  onClick,
  className,
  header,
  noTextWrap,
  to,
  maxWidth,
  sticky,
  tooltip,
  tooltipPosition,
  dataEvent
}) => {
  const divTag = () => {
    return (
      <div
        onClick={onClick}
        data-event={!tooltip || !to ? dataEvent : null}
        className={`${className || ''} ${
          header ? 'header-cell' : ''
        } table-cell body-text-2 d-flex align-items-center ${
          rightAlign ? 'justify-content-end' : 'justify-content-start'
        }`}
      >
        {children}
        <style jsx>
          {`
            .table-cell {
              height: auto;
              background: ${COLORS.neutralLight50};
              box-shadow: inset 0px -1px 0px ${COLORS.neutralLight300};
              white-space: ${noTextWrap ? 'nowrap' : 'normal'};
              text-overflow: ellipsis;
              color: ${COLORS.neutralDark200};
              line-height: 1.2;
              padding: 0.25rem 1rem;
              text-overflow: ellipsis;
              max-width: ${maxWidth || 'none'};
              position: ${sticky ? 'sticky' : 'static'};
              z-index: ${sticky ? '1' : 'auto'};
              left: 0;
            }
            :global(.table-container:last-of-type) .table-cell {
              box-shadow: none;
            }
            :global(.table-container:first-of-type) .table-cell {
              box-shadow: inset 0px -1px 0px ${COLORS.neutralLight300};
            }
            :global(.table-container.header-row) .table-cell,
            .header-cell {
              background: ${COLORS.neutralLight50};
              box-shadow: none;
              font-weight: 800;
            }
            :global(.table-container.header-row) .table-cell {
              box-shadow: none;
            }
            :global(.table-container.header-row) .table-cell {
              font-weight: 800;
            }
            :global(.table-container.large) .table-cell {
              min-height: 6rem;
            }
            :global(.table-container.medium) .table-cell {
              min-height: 4rem;
            }
            :global(.table-container.small) .table-cell {
              min-height: 3rem;
              padding: 0 0.5rem;
            }
            :global(.table-container.auto) .table-cell {
              min-height: 3rem;
              height: auto;
            }
          `}
        </style>
      </div>
    );
  };

  const toTag = () => {
    return (
      <div data-event={!tooltip ? dataEvent : null} className="cell-href h-100">
        <Link className="no-text-decoration" to={to}>
          {divTag()}
        </Link>
        <style jsx>
          {`
            .cell-href {
              display: block;
              position: ${sticky ? 'sticky' : 'static'};
              z-index: ${sticky ? '1' : 'unset'};
              left: 0;
              box-shadow: inset 0px -1px 0px ${COLORS.neutralLight300};
            }
            .cell-href :global(.table-cell) {
              height: 100% !important;
            }
          `}
        </style>
      </div>
    );
  };

  return tooltip ? (
    <div className="w-100" data-event={dataEvent}>
      <Tooltip
        containerClassName="table-tooltip h-100"
        content={tooltip}
        position={tooltipPosition}
      >
        {to ? toTag() : divTag()}
      </Tooltip>
      <style jsx>
        {`
          :global(.table-tooltip) {
            width: 100% !important;
          }
        `}
      </style>
    </div>
  ) : to ? (
    toTag()
  ) : (
    divTag()
  );
};

Cell.propTypes = {
  children: PropTypes.any,
  rightAlign: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  header: PropTypes.bool,
  noTextWrap: PropTypes.bool,
  maxWidth: PropTypes.string,
  sticky: PropTypes.bool,
  tooltip: PropTypes.string,
  to: PropTypes.string,
  dataEvent: PropTypes.string,
  tooltipPosition: PropTypes.string
};

export { TableRow, Cell };
