import React from 'react';
import { ICONS } from '../../constants.js';
import PropTypes from 'prop-types';

const Icon = ({
  icon,
  fill = 'inherit',
  width = 24,
  height = 24,
  className = '',
  small = false,
  viewBox = ''
}) => {
  return (
    <svg
      className={className}
      width={icon === 'gootenLogoType' ? 72 : width}
      height={height}
      viewBox={viewBox || (icon === 'gootenLogoType' ? '0 0 72 24' : '0 0 24 24')}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      {ICONS[icon] && (
        <>
          {typeof ICONS[icon] === 'string' ? (
            <path d={ICONS[icon]} fill={fill} />
          ) : (
            <>
              {ICONS[icon].map((x, y) => (
                <path key={y} fill={fill} fillRule="evenodd" clipRule="evenodd" d={x} />
              ))}
            </>
          )}
        </>
      )}

      <style jsx>
        {`
          svg {
            width: ${small ? '1rem' : 'auto'};
            min-width: ${width}px;
            height: ${small ? '1rem' : 'auto'};
            line-height: inherit;
          }
        `}
      </style>
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  small: PropTypes.bool,
  viewBox: PropTypes.string
};

export default Icon;
