import { SEARCH_BAR_CHANGE } from './SearchBarActions';
import * as Actions from '../../atoms/CategoriesNav/CategoriesNavActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SEARCH_BAR_CHANGE]: (state, action) => action.payload
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = '', action) => {
  if (action.type === Actions.CATEGORY_SELECT || action.type === Actions.SUBCATEGORY_SELECT) {
    return '';
  }
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
