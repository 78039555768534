import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import EmailsContent from './EmailsContent';
import EmailServices from '../EmailServices/EmailServices';
import { EmailsContentModel } from '../../Models/EmailsContentModel';
import { UPLOAD_LOGO, TOGGLE_UPLOAD_FROM_URL, CHANGE_ULOAD_FROM_URL } from './EmailsContentActions';
import { initActionNameFor } from '../BaseCard/BaseCardActions';

const initAction = initActionNameFor(EmailServices.name);

export default baseCardReducer(
  EmailsContent.name,
  (
    state = new Map({
      data: new EmailsContentModel(),
      logo: '',
      loading: true,
      busy: false,
      uploadFromUrlOpen: false,
      uploadFromUrl: '',
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case initAction.SUCCESS:
        const data = new EmailsContentModel(action.payload.data);
        return state
          .set('data', data)
          .set('unmodified', data)
          .set('logo', action.payload.data.PartnerLogoUrl)
          .set('loading', false);
      case UPLOAD_LOGO.ASYNC:
        return state.set('busy', true);
      case UPLOAD_LOGO.FAIL:
        return state.set('busy', false);
      case UPLOAD_LOGO.SUCCESS:
        return state
          .set('busy', false)
          .set('logo', action.payload.url)
          .set('uploadFromUrlOpen', false);
      case TOGGLE_UPLOAD_FROM_URL:
        return state.set('uploadFromUrlOpen', !state.get('uploadFromUrlOpen'));
      case CHANGE_ULOAD_FROM_URL:
        return state.set('uploadFromUrl', action.payload);
      default:
        return state;
    }
  },
  EmailsContentModel
);
