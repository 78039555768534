import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class HistoryService {
  fetchHistory(filterDataObject) {
    const url = `${Config.get('adminApi')}OrdersApi/GetHistory`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(filterDataObject)
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new HistoryService();
