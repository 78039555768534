import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import { changeActionNameFor } from '../BaseCard/BaseCardActions';
import BillingAddress from './BillingAddress';
import { BillingAddressModel } from '../../Models/BillingAddressModel';
import { usStatesIndex, caStatesIndex } from 'gooten-components/src/utils/states';

const countryStates = {
  US: usStatesIndex,
  CA: caStatesIndex
};

const change = changeActionNameFor(BillingAddress.name);

const getValue = (name, payload, state) =>
  payload.field === name ? payload.value : state.getIn(['data', name]);

export default baseCardReducer(
  BillingAddress.name,
  (
    state = new Map({
      data: new BillingAddressModel(),
      loading: true,
      validationIssues: new Map(),
      busy: false,
      open: true
    }),
    action
  ) => {
    if (action.type === change) {
      const cc = getValue('CountryCode', action.payload, state);
      const st = getValue('State', action.payload, state);
      return state.update('validationIssues', v =>
        v.set('CountryCode', !cc).set('State', !!countryStates[cc] && !countryStates[cc][st])
      );
    } else {
      return state;
    }
  },
  BillingAddressModel
);
