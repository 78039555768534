import { takeLatest, call, put, all } from 'redux-saga/effects';
import pastOrdersService from '../PastOrdersView/components/PastOrders/PastOrdersService';
import hubProductsService from '../HubProductsView/components/HubProducts/HubProductsService';
import { CHECK_SOURCES, checkSourcesSuccess, checkSourcesFail } from './OrderFromViewActions';
import PagedQuery from '../../models/PagedQuery';

export function* checkSourcesHandler(action) {
  try {
    const query = new PagedQuery({ pageSize: 1 });
    // correct, effects will get executed in parallel
    const [orderItems, hubProducts] = yield all([
      call([pastOrdersService, pastOrdersService.loadPastOrderItems], query),
      call([hubProductsService, hubProductsService.loadHubProductItems], query)
    ]);
    const res = {
      hasPastOrders: orderItems.total > 0,
      hasHubProducts: hubProducts.total > 0
    };

    yield put(checkSourcesSuccess(res));
  } catch (err) {
    yield put(checkSourcesFail(err));
    throw err;
  }
}

export function* watchCheckSources() {
  yield takeLatest(CHECK_SOURCES.ASYNC, checkSourcesHandler);
}

export default function* rootSaga() {
  yield all([watchCheckSources()]);
}
