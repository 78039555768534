import { ActionNameFactory } from '../../utils/actions';

export const getActionName = ActionNameFactory('COMPONENTS', 'PRODUCT_PUBLISH');

export const UPDATE_DATA = getActionName('UPDATE_DATA');
export const DATA_READY = getActionName('DATA_READY');
export const CONFIGURE = getActionName('CONFIGURE');
export const CHANGE_STORE_FIELD = getActionName('CHANGE_STORE_FIELD');
export const CHANGE_STORAGE_FIELD = getActionName('CHANGE_STORAGE_FIELD');
export const COMPONENT_MOUNTED = getActionName('COMPONENT_MOUNTED');
export const SET_ERROR = getActionName('SET_ERROR');
export const VALIDATION_UPDATE = getActionName('VALIDATION_UPDATE');
export const RESET_PUBLISH_DATA = getActionName('RESET_PUBLISH_DATA');
export const SET_ORIENTATION_CHANGED = getActionName('SET_ORIENTATION_CHANGED');
export const BACKUP_SKUS = getActionName('BACKUP_SKUS');
export const SET_WARNING_DIALOG = getActionName('SET_WARNING_DIALOG');

export const updatePublishData = payload => ({
  type: UPDATE_DATA,
  payload
});

export const getReady = () => ({
  type: DATA_READY
});

export const configure = payload => ({
  type: CONFIGURE,
  payload
});

export const changeStoreField = payload => ({
  type: CHANGE_STORE_FIELD,
  payload
});

export const changeStorageField = payload => ({
  type: CHANGE_STORAGE_FIELD,
  payload
});

export const componentMounted = () => ({
  type: COMPONENT_MOUNTED
});

export const setError = payload => ({
  type: SET_ERROR,
  payload
});

export const updateValidation = payload => ({
  type: VALIDATION_UPDATE,
  payload
});

export const resetPublishData = () => ({
  type: RESET_PUBLISH_DATA
});

export const setOrientationChanged = payload => ({
  type: SET_ORIENTATION_CHANGED,
  payload
});

export const backupSkus = payload => ({
  type: BACKUP_SKUS,
  payload
});

export const setWarningDialog = payload => ({
  type: SET_WARNING_DIALOG,
  payload
});
