import { createSelector } from 'reselect';
import { storeIdSelector } from '../LinkProductSelectors';

export const rootSelector = state => state.getIn(['hub', 'linkProduct', 'productSelectionView']);

export const pageSelector = createSelector([rootSelector], root => root.get('page'));

export const searchSelector = createSelector([rootSelector], root => root.get('search'));

export const viewBySelector = createSelector([rootSelector], root => root.get('viewBy'));

export const getProductSelectionQuery = createSelector(
  [storeIdSelector, pageSelector, searchSelector, viewBySelector],
  (storeId, page, search, viewBy) => ({
    storeId,
    page,
    search,
    viewBy
  })
);

export const providerPathSelector = createSelector([rootSelector], root =>
  root.get('providerPath')
);

export const isReadySelector = createSelector([rootSelector], root => root.get('ready'));

export const selectedProductSelector = createSelector([rootSelector], root =>
  root.get('selectedProduct')
);

export const productsIndexingSelector = createSelector([rootSelector], root =>
  root.get('productsIndexing')
);
