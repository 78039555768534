import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import scrollIntoView from 'scroll-into-view-if-needed';
import { whiteSpaces } from './../../../utils/regex';
import ListItem from '../ListItem';

class OptionsModal extends React.PureComponent {
  onClick = () => {
    this.props.onDismiss();
  };

  componentDidMount() {
    document.addEventListener('click', this.onClick);

    scrollIntoView(document.querySelector('.options-modal'), {
      scrollMode: 'if-needed',
      block: 'nearest'
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClick);
  }

  addIcon(itemName) {
    switch (itemName) {
      case 'Place an Order':
        return 'shoppingCart';
      case 'Edit':
        return 'edit';
      case 'Create Product':
        return 'edit3';
      case 'Duplicate':
        return 'copy';
      case 'Stop Fulfillment':
        return 'xSquare';
      case 'Unpublish':
        return 'slash';
      case 'View Pricing':
        return 'dollarSign';
      case 'Export Pricing List':
        return 'download';
      case 'View In Store':
      case 'View Details':
        return 'eye';
      case 'Publish':
        return 'shoppingBag';
      case 'Delete':
      case 'Remove':
        return 'trash';
      default:
        return '';
    }
  }

  render() {
    const items = this.props.items.map((item, position) => (
      <ListItem
        className="item"
        onSelect={() =>
          this.props.onClickItem(
            typeof item == 'string' ? item.replace(whiteSpaces, '').toLowerCase() : item,
            item
          )
        }
        key={position}
        text={typeof item == 'string' ? item : item.text}
        leftIcon={typeof item == 'string' ? this.addIcon(item) : item.icon}
      />
    ));

    return (
      <div className="options-modal dropshadow-2">
        <ReactCSSTransitionGroup
          transitionAppear
          transitionAppearTimeout={300}
          transitionEnter={false}
          transitionLeave={false}
          transitionName="fade"
        >
          {items}
        </ReactCSSTransitionGroup>
        <style jsx>
          {`
            .options-modal {
              background: white;
              position: absolute;
              z-index: 1000;
              padding: 0.25rem 1rem;
              border-radius: 4px;
              margin-bottom: 0.5rem;
            }
          `}
        </style>
      </div>
    );
  }
}

OptionsModal.propTypes = {
  items: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default OptionsModal;
