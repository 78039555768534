import { combineReducers } from 'redux-immutable';
import ordersViewReducer from './OrdersView/OrdersViewReducer';
import orderDetailsReducer from './OrderDetails/OrderDetailsReducer';
import summaryReducer from './OrderDetails/SummaryView/SummaryViewReducer';
import statusReducer from './OrderDetails/StatusView/StatusViewReducer';
import shipmentReducer from './OrderDetails/ShipmentView/ShipmentViewReducer';
import HelpDataReducer from './HelpData/HelpDataReducer';
import HistoryViewReducer from './OrderDetails/HistoryView/HistoryViewReducer';
import BillingViewReducer from './OrderDetails/BillingView/BillingViewReducer';
import orderItemsSelectionReducer from './OrderDetails/OrderItemsSelection/OrderItemsSelectionReducer';
import initialState from '../store/initialState';
import { RESET } from './OrderActions';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const combinedReducer = combineReducers({
  helpData: HelpDataReducer,
  billingView: BillingViewReducer,
  historyView: HistoryViewReducer,
  ordersView: ordersViewReducer,
  orderDetails: orderDetailsReducer,
  summaryView: summaryReducer,
  shipmentView: shipmentReducer,
  statusView: statusReducer,
  orderItemsSelectionView: orderItemsSelectionReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    return initialState.get('orders');
  }
  return combinedReducer(state, action);
};
