import { fromJS } from 'immutable';
import PagedCollection from '../models/PagedCollection';

// ========================================================
// Define Initial State
// ========================================================

const initialState = {
  orders: {
    billingView: {
      loading: false,
      costBreakdown: [],
      orderBilling: {},
      errors: []
    },
    historyView: {
      loading: false,
      errors: [],
      filters: {
        OrderId: '',
        Page: 1,
        Size: 50,
        UserType: '',
        StartDate: '',
        EndDate: ''
      },
      data: {
        items: [],
        total: 0
      }
    },
    helpData: {
      acceptableStatuses: [],
      allowedStatuses: [],
      hasShopifyConnectionWarning: null,
      isBillingAddressMissing: null,
      isOnCredit: null,
      isPaymentMethodMissing: null,
      partnerPaymentIssueCount: null,
      partnersList: null,
      vendorsList: [],
      partnerGroupsList: []
    },
    ordersView: {
      searchFields: {
        group: 4,
        itemsType: 'Order',
        itemsMode: 'Order',
        page: 1,
        pageSize: 25,
        BeginningDate: '',
        BillingName: '',
        Desc: true,
        EndingDate: '',
        OrderId: '',
        PartnerIds: '',
        PartnerGroupIds: [],
        PartnerName: '',
        PartnerOrderId: '',
        Platform: '',
        ShippingEmail: '',
        ShippingName: '',
        ShortId: '',
        SimpleSearch: '',
        SortField: 'dateCreated',
        StatusIds: [],
        VendorId: '',
        VendorIds: [],
        VendorName: '',
        Version: ''
      },
      searchData: {
        total: null,
        items: [],
        tabs: [],
        key: null
      },
      searchLoading: false,
      errors: []
    },
    summaryView: {
      loading: false,
      countries: {
        loading: false,
        data: []
      },
      addressTypes: {
        loading: false,
        data: []
      },
      productionTimes: {
        loading: false,
        data: {},
        indexedData: []
      },
      changeSkuView: {
        loading: false,
        isOpen: false,
        variants: [],
        partnerInfo: {}
      },
      editingShippingAddress: false,
      editingBillingAddress: false,
      summaryData: {},
      disableShippingAddressEdit: false
    },
    statusView: {
      loading: false,
      statusData: [],
      acceptableStatuses: []
    },
    shipmentView: {
      loading: false,
      shipmentData: []
    },
    orderDetails: {
      loading: false,
      stateData: {},
      summaryData: {},
      billingData: {},
      itemsDataOrig: [],
      itemsData: []
    },
    orderItemsSelectionView: {
      archivedStatuses: {}
    }
  },
  location: {
    history: []
  },
  hub: {
    connectStoreView: {
      provider: '',
      connecting: false,
      initData: {},
      errorMsg: ''
    },
    hubView: {
      // default providers
      providers: {
        items: [
          {
            id: 'all',
            name: 'All'
          },
          {
            id: 'storage',
            name: 'Storage'
          },
          {
            id: 'shopify',
            name: 'Shopify'
          },
          {
            id: 'etsy',
            name: 'Etsy',
            isBeta: false
          },
          {
            id: 'woocommerce',
            name: 'Woo',
            isBeta: false
          },
          {
            id: 'bigcommerce',
            name: 'BigCommerce',
            isBeta: false
          },
          {
            id: 'tiktok',
            name: 'TikTok Shop',
            isBeta: true
          }
        ],
        selected: undefined
      },
      stores: {
        loaded: false,
        items: [],
        selected: undefined
      },
      search: '',
      products: {
        cachedQueries: {},
        currentQuery: '',
        // contains publish products tasks, { task_id: 'guid-string', req: {}, res: {} }
        // res is not exists until task is completed
        tasks: [],
        // items: [], // contains all products
        selected: [],
        viewBy: undefined, // published|unpublished
        display: 'grid', // grid|list
        page: 1, // current page
        pageSize: 25, // items shown on page
        updating: false,
        notification: {
          show: false,
          message: ''
        },
        showSampleOrderModal: true
      }
    },
    editProductView: {
      step: 'initial'
    },
    duplicateProductView: {
      step: 'initial'
    },
    placeOrder: {
      isReady: false,
      selectedVariantIDs: [],
      editProductID: 0,
      setOrderAsSample: false
    },
    linkProduct: {
      productSelectionView: {
        storeId: null,
        products: new PagedCollection(),
        productsIndexing: {},
        ready: false,
        page: 1,
        search: '',
        viewBy: null,
        selectedProduct: null
      },
      SKUsSelectionView: {
        variantsMappings: [],
        ready: false,
        hash: null,
        selectedProductId: null,
        currentVariantIndex: 0,
        regions: [],
        selectedRegionIndex: 0,
        selectedSubCategoryIndex: 0,
        options: [],
        page: 1,
        skuSelectedNotification: {},
        hubProductSelection: {
          query: null,
          queryInProgress: false,
          cachedProducts: [],
          topNotification: {
            show: false,
            message: ''
          }
        }
      },
      productDesignView: {}
    }
  },
  settings: {
    cards: {}
  },
  customOrderForm: {
    // createNewView: {},
    pastOrdersView: {
      search: '',
      pastOrders: {
        loaded: false,
        // NOTE: That we may add caching like in HubView.products
        // We may cache api responses by key in cachedQueries obj
        // or just rewrite items with latest response from api - no cache
        // items currently shown on the page
        items: [],
        totalPages: 0,
        // selected items
        selected: [],
        // current page
        page: 1,
        // page size
        pageSize: 8
      }
    },
    hubProductsView: {
      search: '',
      hubProducts: {
        loaded: false,
        // NOTE: That we may add caching like in HubView.products
        // We may cache api responses by key in cachedQueries obj
        // or just rewrite items with latest response from api - no cache
        // items currently shown on the page
        items: [],
        sortValue: '',
        totalPages: 1,
        // selected items
        selected: [],
        // current page
        page: 1,
        // page size
        pageSize: 8
      }
    },
    editDesignView: {
      item: {
        productId: 0,
        sku: 0,
        images: []
      },
      loadingStatus: 'initial' // initial|productsLoaded|variantsLoaded|detailsLoaded|productNotAvailable|ready
    },
    cartView: {
      request: {
        products: []
      },
      loadingStatus: 'ready'
    },
    orderFromView: {
      loaded: false,
      hasPastOrders: false,
      hasHubProducts: false
    }
  },
  dashboard: {
    main: {
      hasOrders: null,
      ordersCountTotal: 0,
      ordersCountIssues: 0,
      ordersCountMonth: 0
    }
  }
};

export default fromJS(initialState);
