import { injectSaga } from '../store/sagas';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import {
  STORES_FETCH,
  STORE_LOG_OUT,
  storesFetchSuccess,
  storesFetchFail,
  STORE_CONNECT
} from 'gooten-components/src/components/Stores/StoresActions';
import { storesLoad } from '../Hub/HubView/components/Stores/StoresActions';
import storeService from '../Hub/HubView/components/Stores/StoresService';

export function* storesFetchAsyncHandler(action) {
  try {
    // call and pass correct this as 1st array param
    let data = yield call([storeService, storeService.getUserStores]);
    yield put(storesFetchSuccess(data));
  } catch (err) {
    yield put(storesFetchFail(err));
    throw err;
  }
}

export function* reloadStoresHandler() {
  yield put(storesLoad(true));
}

export function* watchStoresFetchAsync() {
  yield takeEvery(STORES_FETCH.ASYNC, storesFetchAsyncHandler);
}

export function* watchStoreConnectSuccess() {
  yield takeEvery(STORE_CONNECT.SUCCESS, reloadStoresHandler);
}

export function* watchStoreLogOutSuccess() {
  yield takeEvery(STORE_LOG_OUT.SUCCESS, reloadStoresHandler);
}

function* rootSaga() {
  yield all([watchStoresFetchAsync(), watchStoreConnectSuccess(), watchStoreLogOutSuccess()]);
}

export default () => {
  injectSaga(rootSaga);
};
