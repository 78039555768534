import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import ProfileInformation from './ProfileInformation';
import { ProfileInformationModel } from '../../Models/ProfileInformationModel';
import { UPLOAD } from './ProfileInformationActions';
import { initActionNameFor } from '../BaseCard/BaseCardActions';

const initAction = initActionNameFor(ProfileInformation.name);

export default baseCardReducer(
  ProfileInformation.name,
  (
    state = new Map({
      data: new ProfileInformationModel(),
      logo: '',
      loading: true,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case initAction.ASYNC:
        return state.set('loading', true);
      case initAction.SUCCESS:
        return state.set('logo', action.payload.data.get('Logo'));
      case UPLOAD.ASYNC:
        return state.set('busy', true);
      case UPLOAD.FAIL:
        return state.set('busy', false);
      case UPLOAD.SUCCESS:
        return state.set('busy', false).set('logo', action.payload.url);
      default:
        return state;
    }
  },
  ProfileInformationModel
);
