import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'PRODUCT_SELECTION');

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCT_SELECTION_CLICK_OUTSIDE = getActionName('PRODUCT_SELECTION_CLICK_OUTSIDE');

// ------------------------------------
// Actions
// ------------------------------------
export const clickOutside = () => {
  return {
    type: PRODUCT_SELECTION_CLICK_OUTSIDE
  };
};
