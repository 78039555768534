import { FETCH_ORDERSTATS_SUCCESS, FETCH_ORDERSTATS_FAIL } from './MainViewActions';

import initialState from '../../store/initialState';

const defaultState = initialState.getIn(['dashboard', 'main']);

const mainViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_ORDERSTATS_SUCCESS: {
      return state
        .set('hasOrders', action.payload.HasOrders)
        .set('ordersCountTotal', action.payload.OrderCountLifetime)
        .set('ordersCountIssues', action.payload.OrderCountInIssue)
        .set('ordersCountMonth', action.payload.OrderCountCurrentMonth);
    }
    case FETCH_ORDERSTATS_FAIL: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default mainViewReducer;
