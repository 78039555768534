import { List, Map, fromJS } from 'immutable';
import Store from '../../../../models/Store';
import { fetch, post, postJson } from 'gooten-components/src/utils/http';
import Config from '../../../../config';

// TODO: Redundant, needs to be removed
// use gooten-components/src/services/storesService

class StoresService {
  mapStore(store) {
    return new Store({
      id: store.id,
      storeName: store.nick_name,
      nickName: store.nick_name,
      provider: store.type,
      hasProducts: store.settings.products_count > 0,
      hasProductsToLink: !!store.settings.unconnected_products_count,
      collections: new List(
        (store.collections || []).map(c => new Map({ id: c.id, name: c.name }))
      ),
      tags: new List((store.tags || []).map(t => new Map({ name: t.name }))),
      settings: fromJS(store.settings)
    });
  }

  getAuthQueryParams(skipCache) {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey'),
      skipCache: skipCache || false
    };
  }

  getUserStores(skipCache) {
    const url = `${Config.get('storeApi')}stores`;
    return fetch(url, { queryParams: this.getAuthQueryParams(skipCache) }).then(resp => {
      return new List(resp.stores.map(this.mapStore));
    });
  }

  getUserStoresByParams(recipeId, apiKey) {
    const url = `${Config.get('storeApi')}stores`;
    return fetch(url, { queryParams: { recipeId: recipeId, apiKey: apiKey } }).then(resp => {
      return new List(resp.stores.map(this.mapStore));
    });
  }

  getStoreData(id) {
    const url = `${Config.get('storeApi')}stores/${id}/settings`;
    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(resp => {
      return resp.store;
    });
  }

  storeLogOut(storeId) {
    const url = `${Config.get('storeApi')}stores/${storeId}/disconnect`;
    return post(url, null, { queryParams: this.getAuthQueryParams() });
  }

  getOAuth(provider, storeName, redirectUrl) {
    const url = `${Config.get('storeApi')}stores/oauth/${provider}`;
    const queryParams = {
      ...this.getAuthQueryParams(),
      storeName: storeName,
      urlRedirect: redirectUrl
    };

    return fetch(url, { queryParams: queryParams }).then(resp => resp.oauth);
  }

  storeConnect(oauth) {
    const url = `${Config.get('storeApi')}stores/oauth/etsy`;
    const body = {
      integration: {
        'store-name': oauth.storeName,
        'token-key': oauth.tokenKey,
        'token-secret': oauth.tokenSecret,
        'oauth-verifier': oauth.verifierCode
      }
    };
    const queryParams = this.getAuthQueryParams();
    return postJson(url, body, { queryParams }).then(resp => {
      return resp.error ? resp : this.mapStore(resp.store);
    });
  }

  storeV3Connect(oauth) {
    const url = `${Config.get('storeApi')}stores/oauth/etsy-v3`;
    const body = {
      integration: {
        'store-name': oauth.storeName,
        'code-verifier': oauth.codeVerifier,
        'redirect-uri': oauth.redirectUrl,
        code: oauth.code,
        state: oauth.state
      }
    };
    const queryParams = this.getAuthQueryParams();
    return postJson(url, body, { queryParams }).then(resp => {
      return resp.error ? resp : this.mapStore(resp.store);
    });
  }

  storeConnectBigCommerce(oauth) {
    const url = `${Config.get('storeApi')}stores/oauth/bigcommerce`;
    const body = {
      integration: {
        'store-id': oauth.storeId,
        token: oauth.token
      }
    };
    const queryParams = this.getAuthQueryParams();
    return postJson(url, body, { queryParams }).then(resp => {
      return resp.error ? resp : this.mapStore(resp.store);
    });
  }
}

// singleton
export default new StoresService();
