import { injectSaga } from '../store/sagas';
import advancedSettingsSaga from './Cards/AdvancedSettings/AdvancedSettingsSaga';
import profileInformationSaga from './Cards/ProfileInformation/ProfileInformationSaga';
import passwordSaga from './Cards/Password/PasswordSaga';
import billingAddressSaga from './Cards/BillingAddress/BillingAddressSaga';
import billingHistorySaga from './Cards/BillingHistory/BillingHistorySaga';
import emailPreferencesSaga from './Cards/EmailPreferences/EmailPreferencesSaga';
import creditCardSaga from './Cards/CreditCard/CreditCardSaga';
import apiKeysSaga from './Cards/ApiKeys/ApiKeysSaga';
import webhooksSaga from './Cards/Webhooks/WebhooksSaga';
import inviteTeamMemberSaga from './Cards/InviteTeamMember/InviteTeamMemberSaga';
import pendingInvitationsSaga from './Cards/PendingInvitations/PendingInvitationsSaga';
import yourTeamSaga from './Cards/YourTeam/YourTeamSaga';
import emailServicesSaga from './Cards/EmailServices/EmailServicesSaga';
import emailsContentSaga from './Cards/EmailsContent/EmailsContentSaga';
import emailCredentialsSaga from './Cards/EmailCredentials/EmailCredentialsSaga';
import accountActivitySaga from './Cards/AccountActivity/AccountActivitySaga';
import fulfillmentSettingsSaga from './Cards/FulfillmentSettings/FulfillmentSettingsSaga';
import returnAddress from './Cards/ReturnAddress/ReturnAddressSaga';
import neckLabelsSaga from './Cards/NeckLabels/NeckLabelsSaga';
import taxExemptionSaga from './Cards/TaxExemption/TaxExemptionSaga';
import packingSlipsSage from './Cards/PackingSlips/PackingSlipsSaga';

const registerSettingsSagas = () => {
  injectSaga(advancedSettingsSaga);
  injectSaga(profileInformationSaga);
  injectSaga(passwordSaga);
  injectSaga(billingAddressSaga);
  injectSaga(billingHistorySaga);
  injectSaga(emailPreferencesSaga);
  injectSaga(creditCardSaga);
  injectSaga(apiKeysSaga);
  injectSaga(webhooksSaga);
  injectSaga(inviteTeamMemberSaga);
  injectSaga(pendingInvitationsSaga);
  injectSaga(yourTeamSaga);
  injectSaga(emailServicesSaga);
  injectSaga(emailsContentSaga);
  injectSaga(emailCredentialsSaga);
  injectSaga(accountActivitySaga);
  injectSaga(fulfillmentSettingsSaga);
  injectSaga(returnAddress);
  injectSaga(neckLabelsSaga);
  injectSaga(taxExemptionSaga);
  injectSaga(packingSlipsSage);
};

export default registerSettingsSagas;
