import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ size = 24, className, color = '#092DC5' }) => {
  return (
    <>
      <div className={`${className || ''} smile`}>
        <svg
          width={size}
          height={size}
          viewBox={`0 0 24 24`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51808 6.3459 2.7612 8.17316C2.00433 10.0004 1.80629 12.0111 2.19215 13.9509C2.578 15.8907 3.5304 17.6725 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12"
            stroke="#EDEDED"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.76121C17.0401 3.26375 18.1425 4.00035 19.0711 4.92894C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38643 22 10.6868 22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22"
            stroke={color}
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.76121C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C4.00035 5.85752 3.26375 6.95991 2.7612 8.17317C2.25866 9.38642 2 10.6868 2 12"
            stroke="#EDEDED"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
        <style jsx>
          {`
            .smile {
              height: ${size}px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 1;
            }
            .smile svg {
              margin: 0;
              animation-name: spinner;
              animation-iteration-count: infinite;
              animation-duration: 0.5s;
              animation-delay: 0;
              animation-timing-function: linear;
            }
            @keyframes spinner {
              from {
                transform: rotate(0turn);
              }
              to {
                transform: rotate(1turn);
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string
};

export default LoadingSpinner;
