import React from 'react';
import PropTypes from 'prop-types';
import Dialogue from '../../Dialogue';

class CancelConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  openModal = () => {
    this.setState({
      isOpen: true
    });
  };

  hideModal = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    return (
      <Dialogue
        isOpen={this.state.isOpen}
        cancelText="NO"
        actionText="LEAVE"
        cancelClick={this.hideModal}
        destructiveClick={this.props.confirm}
        title="Are you sure you want to leave?"
      >
        Your work may not be saved.
      </Dialogue>
    );
  }
}

CancelConfirmationModal.propTypes = {
  confirm: PropTypes.func
};

export default CancelConfirmationModal;
