import { createSelector } from 'reselect';
import { rootSelector } from './../../../../SKUsSelectionViewSelectors';
// import { sortBy } from 'gooten-components/src/utils/array'
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';
import productSelectionService from './../../services/productSelectionService';
import { getDateNowStr } from 'gooten-components/src/utils/random';
import { fromJS, List } from 'immutable';

/**
 * Returns array of maps, where key is query
 */
export const cachedProductsSelector = createSelector([rootSelector], root =>
  root.getIn(['hubProductSelection', 'cachedProducts'])
);

export const querySelector = createSelector(
  [rootSelector],
  root =>
    root.getIn(['hubProductSelection', 'query']) ||
    fromJS(productSelectionService.getInitialQuery())
);

/**
 * Returns all cached products
 */
const allCachedProductsSelector = createSelector([cachedProductsSelector], cachedProducts =>
  cachedProducts
    .toJS()
    .reduce((a, c) => Object.values(a).concat(Object.values(c)), [])
    .map(a => a.products)
    .reduce((a, c) => a.concat(c), [])
);

/**
 * Returns all cached products for current QUERY
 */
const allCachedQueryProductsSelector = createSelector(
  [cachedProductsSelector, querySelector],
  (cachedProducts, query) => {
    const cachedProductsForFilterQuery = cachedProducts
      .toJS()
      .filter(
        item =>
          Object.keys(item)[0].search(
            productSelectionService.getRegexForFilter(query.get('search'))
          ) > -1
      );

    return cachedProductsForFilterQuery
      .reduce((a, c) => Object.values(a).concat(Object.values(c)), [])
      .map(a => a.products)
      .reduce((a, c) => a.concat(c), []);
  }
);

export const hasMoreProductsSelector = createSelector(
  [querySelector],
  query => query.get('pageSize') * query.get('page') < (query.get('total') || 0)
);

/**
 * Filter locally is possible only when all products are loaded for initial query (search==='')
 */
export const canFilterLocallySelector = createSelector(
  [allCachedQueryProductsSelector, cachedProductsSelector, querySelector],
  (products, cachedProducts, query) =>
    !!products.length &&
    query.get('search') === '' &&
    products.length === Object.values(cachedProducts.first().toJS())[0].total
);

export const hubProductsForSelectSelector = createSelector(
  [allCachedQueryProductsSelector],
  products => {
    if (!products.length) {
      return [];
    }

    // TO-DO hardcoded for now, until we get sections from API...
    const firstSection = [{ key: -1, label: 'All Products', isSection: true }];

    let productsForSelect = products.map(p => ({
      key: p.id,
      label: p.name,
      imageUrl: p.image && getSmallPreviewImageResizerUrl(p.image)
    }));
    // .sort(sortBy('label'))

    // TO-DO hardcoded for now, until we get sections from API...
    return firstSection.concat(productsForSelect);
  }
);

export const queryInProgressSelector = createSelector([rootSelector], root =>
  root.getIn(['hubProductSelection', 'queryInProgress'])
);

const selectedProductIdSelector = createSelector([rootSelector], root =>
  root.getIn(['hubProductSelection', 'selectedProductId'])
);

export const selectedProductSelector = createSelector(
  [allCachedProductsSelector, selectedProductIdSelector],
  (cachedProducts, selectedProductId) =>
    fromJS(cachedProducts.find(item => item.id === selectedProductId))
);

// Publish request selectors

const productSelectionSelector = state =>
  state.getIn(['hub', 'linkProduct', 'productSelectionView']);

const gootenMappingForPublish = (mapping, position) =>
  fromJS({
    images: mapping.getIn(['uniqueProduct', 'gootenMapping', 'images']),
    items: mapping.getIn(['uniqueProduct', 'gootenMapping', 'items']).map(item => ({
      product_id: item.get('productId'),
      product_variant_sku: item.get('sku'),
      preconfigurations: item.get('preconfigurations').map(pc => ({
        il: pc.get('il'),
        space_id: pc.get('spaceId'),
        space_desc: pc.get('spaceDesc'),
        image_id: pc.get('imageId'),
        url: pc.get('url')
      }))
    })),
    orientation: mapping.getIn(['uniqueProduct', 'gootenMapping', 'orientation']),
    sku: `${
      mapping.getIn(['variant', 'sku']) ||
      mapping.getIn(['uniqueProduct', 'gootenMapping', 'items', 0, 'sku'])
    }-${getDateNowStr()}`,
    preview_url: mapping.getIn(['uniqueProduct', 'imageUrl']),
    has_main_mockup: position === 0
  });

const overrides = (productSelection, mappings) => ({
  overrides: {
    product: {
      id: productSelection.getIn(['selectedProduct', 'id']),
      options: [],
      variants: mappings.map(mapping => ({
        id: mapping.getIn(['variant', 'id']),
        option_values: mapping.getIn(['variant', 'options']).map((option, pos) => ({
          position: pos + 1,
          value: option.get('value')
        }))
      }))
    }
  }
});

const publishTasksSelector = createSelector(
  [productSelectionSelector, rootSelector],
  (productSelection, root) =>
    new List([
      {
        name: 'store-product-link',
        options: {
          store_id: productSelection.get('storeId'),
          publish: productSelection.getIn(['selectedProduct', 'status']) === 'published',
          update_preview: false
        },
        ...overrides(
          productSelection,
          root.get('variantsMappings').filter(mapping => mapping.get('uniqueProduct'))
        )
      }
    ])
);

const publishVariantsSelector = createSelector([rootSelector], root =>
  root
    .get('variantsMappings')
    .filter(mapping => mapping.get('uniqueProduct'))
    .map((mapping, position) => {
      const gootenMapping = gootenMappingForPublish(mapping, position);

      return fromJS({
        // it might happen that sku is missing in partner's product (product on partner's store). if we gonna sync it with
        // Gooten's product, we will need sku. In that case create new one from unique product data + suffix(time stamp)
        sku:
          mapping.getIn(['variant', 'sku']) ||
          `${mapping.getIn([
            'uniqueProduct',
            'gootenMapping',
            'items',
            0,
            'sku'
          ])}-${getDateNowStr()}`,
        customer_price: mapping.getIn(['variant', 'price', 'price']),
        option_values: new List(),
        images: gootenMapping.get('images'),
        gooten_mapping: gootenMapping
      });
    })
);

export const publishRequestSelector = createSelector(
  [publishTasksSelector, publishVariantsSelector, productSelectionSelector],
  (tasks, variants, productSelection) =>
    fromJS({
      product: {
        name: productSelection.getIn(['selectedProduct', 'name']),
        description_html: productSelection.getIn(['selectedProduct', 'description']),
        tags: productSelection.getIn(['selectedProduct', 'tags']),
        collections: productSelection.getIn(['selectedProduct', 'collections']),
        variants
      },
      tasks
    })
);

export const publishInProgressSelector = createSelector(
  [rootSelector],
  root => !!root.getIn(['hubProductSelection', 'publishInProgress'])
);

export const publishConfigSelector = createSelector([productSelectionSelector], productSelection =>
  fromJS({
    stores: [
      {
        id: productSelection.getIn(['selectedProduct', 'storeId']),
        name: productSelection.getIn(['selectedProduct', 'storeName'])
      }
    ]
  })
);

export const topNotificationSelector = createSelector([rootSelector], root =>
  root.getIn(['hubProductSelection', 'topNotification'])
);
