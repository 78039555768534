import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'ORDERS_DETAILS_VIEW');

export const GET_SHARED_ORDER_DATA = getActionName('GET_SHARED_ORDER_DATA');
export const GET_SHARED_ORDER_DATA_ERROR = getActionName('GET_SHARED_ORDER_DATA_ERROR');
export const CLEAR_SHARED_ORDER_DATA = getActionName('CLEAR_SHARED_ORDER_DATA');
export const UPDATE_SUMMARY_DATA = getActionName('UPDATE_SUMMARY_DATA');
export const UPDATE_BILLING_INFO = getActionName('UPDATE_BILLING_INFO');
export const UPDATE_ORDER_META = getActionName.async('UPDATE_ORDER_META');
export const UPDATE_ORDER_ITEMS_META = getActionName.async('UPDATE_ORDER_ITEMS_META');
export const UPDATE_ITEMS_DATA = getActionName('UPDATE_ITEMS_DATA');
export const UPDATE_STATE_DATA = getActionName('UPDATE_STATE_DATA');

export const SAVE_SHIPPING_ADDRESS = getActionName('SAVE_SHIPPING_ADDRESS');
export const SAVE_BILLING_ADDRESS = getActionName('SAVE_BILLING_ADDRESS');
export const UPDATE_SHIPPING_ADDRESS = getActionName('UPDATE_SHIPPING_ADDRESS');
export const UPDATE_SHIPPING_ADDRESS_FAILED = getActionName('UPDATE_SHIPPING_ADDRESS_FAILED');
export const UPDATE_BILLING_ADDRESS = getActionName('UPDATE_BILLING_ADDRESS');
export const IGNORE_SUGGESTED_ADDRESS = getActionName('IGNORE_SUGGESTED_ADDRESS');
export const UPDATE_IGNORED_SHIPPING_ADDRESS = getActionName('UPDATE_IGNORED_SHIPPING_ADDRESS');
export const APPLY_SUGGESTED_ADDRESS = getActionName('APPLY_SUGGESTED_ADDRESS');
export const UPDATE_APPLIED_SHIPPING_ADDRESS = getActionName('UPDATE_APPLIED_SHIPPING_ADDRESS');
export const CHANGE_SKU = getActionName('CHANGE_SKU');
export const UPDATE_SHIPPING_METHOD = getActionName.async('UPDATE_SHIPPING_METHOD');

export const UPDATE_ITEMS_STATUS = getActionName.async('UPDATE_ITEMS_STATUS');
export const CHANGE_TRACKING_NUMBER = getActionName.async('CHANGE_TRACKING_NUMBER');

export const SEND_SHIPPING_CONFIRMATION = getActionName.async('SEND_SHIPPING_CONFIRMATION');
export const SEND_ORDER_CONFIRMATION = getActionName.async('SEND_ORDER_CONFIRMATION');

export const REVERT_IMAGE = getActionName.async('REVERT_IMAGE');

export const SUBMIT_IMAGE_FILE = getActionName.async('SUBMIT_IMAGE_FILE');
export const SUBMIT_IMAGE_URL = getActionName.async('SUBMIT_IMAGE_URL');

export const SET_ACTIVE_IMAGE = getActionName('SET_ACTIVE_IMAGE');

export const STORE_REAL_IMAGE_DIMENSIONS = getActionName('STORE_REAL_IMAGE_DIMENSIONS');
export const SPLIT_ORDER_ITEM = getActionName('SPLIT_ORDER_ITEM');
export const SPLIT_ORDER_ITEM_ERROR = getActionName('SPLIT_ORDER_ITEM_ERROR');

export function getSharedOrderData(orderId) {
  return {
    type: GET_SHARED_ORDER_DATA,
    payload: { orderId }
  };
}

export function getSharedOrderDataError(err) {
  return {
    type: GET_SHARED_ORDER_DATA_ERROR,
    payload: err
  };
}

export function clearSharedOrderData(orderId) {
  return {
    type: CLEAR_SHARED_ORDER_DATA
  };
}

export function updateSummaryData(data) {
  return {
    type: UPDATE_SUMMARY_DATA,
    payload: { data }
  };
}

export function updateStateData(data) {
  return {
    type: UPDATE_STATE_DATA,
    payload: { data }
  };
}

export function updateBillingInfo(data) {
  return {
    type: UPDATE_BILLING_INFO,
    payload: { data }
  };
}

export function updateItemsData(itemsData, updatedItems) {
  return {
    type: UPDATE_ITEMS_DATA,
    payload: { itemsData, updatedItems }
  };
}

export const updateOrderMeta = (orderId, meta) => ({
  type: UPDATE_ORDER_META.ASYNC,
  payload: { orderId, meta }
});

export const updateOrderMetaSuccess = (updatedItems, requestBody) => ({
  type: UPDATE_ORDER_META.SUCCESS,
  payload: { updatedItems, requestBody }
});

export const updateOrderMetaFailure = error => ({
  type: UPDATE_ORDER_META.FAIL,
  payload: { error }
});

export const updateOrderItemsMeta = (orderId, itemIndexes, itemIds, meta) => ({
  type: UPDATE_ORDER_ITEMS_META.ASYNC,
  payload: { orderId, itemIndexes, itemIds, meta }
});

export const updateOrderItemsMetaSuccess = (updatedData, requestBody) => ({
  type: UPDATE_ORDER_ITEMS_META.SUCCESS,
  payload: { updatedData, requestBody }
});

export const updateOrderItemsMetaFailure = error => ({
  type: UPDATE_ORDER_ITEMS_META.FAIL,
  payload: { error }
});

// SUMMARY PAGE
export function saveShippingAddress(orderId, data) {
  return {
    type: SAVE_SHIPPING_ADDRESS,
    payload: { orderId, data }
  };
}

export function saveBillingAddress(orderId, data) {
  return {
    type: SAVE_BILLING_ADDRESS,
    payload: { orderId, data }
  };
}

export function ignoreSuggestedAddress(orderId, data) {
  return {
    type: IGNORE_SUGGESTED_ADDRESS,
    payload: { orderId, data }
  };
}

export function updateIgnoredSuggestedAddress(data) {
  return {
    type: UPDATE_IGNORED_SHIPPING_ADDRESS,
    payload: { data }
  };
}

export function applySuggestedAddress(orderId, data) {
  return {
    type: APPLY_SUGGESTED_ADDRESS,
    payload: { orderId, data }
  };
}

export function updateAppliedShippingAddress(data) {
  return {
    type: UPDATE_APPLIED_SHIPPING_ADDRESS,
    payload: { data }
  };
}

export function updateShippingAddress(data) {
  return {
    type: UPDATE_SHIPPING_ADDRESS,
    payload: { data }
  };
}

export function updateShippingAddressFailed(data) {
  return {
    type: UPDATE_SHIPPING_ADDRESS_FAILED,
    payload: { data }
  };
}

export function updateBillingAddress(data) {
  return {
    type: UPDATE_BILLING_ADDRESS,
    payload: { data }
  };
}

export function changeSku(changeSkuData, summaryData, sku, orderItemId) {
  return {
    type: CHANGE_SKU,
    payload: { changeSkuData, summaryData, sku, orderItemId }
  };
}

export const updateShippingMethod = data => ({
  type: UPDATE_SHIPPING_METHOD.ASYNC,
  payload: data
});

export const updateShippingMethodSuccess = data => ({
  type: UPDATE_SHIPPING_METHOD.SUCCESS,
  payload: data
});

// STATUS PAGE UPDATE ITEMS
export const updateItemsStatus = items => ({
  type: UPDATE_ITEMS_STATUS.ASYNC,
  payload: items
});

export const updateItemsStatusSuccess = (updatedItems, requestBody) => ({
  type: UPDATE_ITEMS_STATUS.SUCCESS,
  payload: { updatedItems, requestBody }
});

export const updateItemsStatusFailure = error => ({
  type: UPDATE_ITEMS_STATUS.FAIL,
  payload: { error }
});

export const changeTrackingNumber = data => ({
  type: CHANGE_TRACKING_NUMBER.ASYNC,
  payload: { data }
});

export const changeTrackingNumberSuccess = (updatedItems, requestBody) => ({
  type: CHANGE_TRACKING_NUMBER.SUCCESS,
  payload: { updatedItems, requestBody }
});

// EMAIL CONFIRMATIONS

export const sendShippingConfirmation = orderId => ({
  type: SEND_SHIPPING_CONFIRMATION.ASYNC,
  payload: orderId
});

export const sendShippingConfirmationSuccess = success => ({
  type: SEND_SHIPPING_CONFIRMATION.SUCCESS,
  payload: { success }
});

export const sendShippingConfirmationFailure = error => ({
  type: SEND_SHIPPING_CONFIRMATION.FAIL,
  payload: { error }
});

export const sendOrderConfirmation = orderId => ({
  type: SEND_ORDER_CONFIRMATION.ASYNC,
  payload: orderId
});

export const sendOrderConfirmationSuccess = success => ({
  type: SEND_ORDER_CONFIRMATION.SUCCESS,
  payload: { success }
});

export const sendOrderConfirmationFailure = error => ({
  type: SEND_ORDER_CONFIRMATION.FAIL,
  payload: { error }
});

// IMAGE VIEW ACTIONS

export const SUBMIT_MANIP_COMMAND = getActionName.async('SUBMIT_MANIP_COMMAND');

export const submitManipCommand = (imageId, manip) => ({
  type: SUBMIT_MANIP_COMMAND.ASYNC,
  payload: { imageId, manip }
});

export const submitManipCommandSuccess = success => ({
  type: SUBMIT_MANIP_COMMAND.SUCCESS,
  payload: { success }
});

export const submitManipCommandFailure = error => ({
  type: SUBMIT_MANIP_COMMAND.FAIL,
  payload: { error }
});

//
export const submitImageFile = (imageId, file) => ({
  type: SUBMIT_IMAGE_FILE.ASYNC,
  payload: { imageId, file }
});

export const submitImageFileSuccess = payload => ({
  type: SUBMIT_IMAGE_FILE.SUCCESS,
  payload
});

export const submitImageFileFailure = error => ({
  type: SUBMIT_IMAGE_FILE.FAIL,
  payload: { error }
});

//
export const submitImageUrl = (imageId, url) => ({
  type: SUBMIT_IMAGE_URL.ASYNC,
  payload: { imageId, url }
});

export const submitImageUrlSuccess = payload => ({
  type: SUBMIT_IMAGE_URL.SUCCESS,
  payload
});

export const submitImageUrlFailure = error => ({
  type: SUBMIT_IMAGE_URL.FAIL,
  payload: { error }
});

//
export const revertImage = (imageId, oldImage, itemIndex, imageIndex) => ({
  type: REVERT_IMAGE.ASYNC,
  payload: { imageId, oldImage, itemIndex, imageIndex }
});

export const revertImageSuccess = success => ({
  type: REVERT_IMAGE.SUCCESS,
  payload: { success }
});

export const revertImageFailure = error => ({
  type: REVERT_IMAGE.FAIL,
  payload: { error }
});

//

export function storeRealImageDimensions(width, height, index, imageIndex) {
  return {
    type: STORE_REAL_IMAGE_DIMENSIONS,
    payload: { width, height, index, imageIndex }
  };
}

export function splitOrderItem(orderId, orderItemId) {
  return {
    type: SPLIT_ORDER_ITEM,
    payload: { orderId, orderItemId }
  };
}

export function splitOrderItemError(err) {
  return {
    type: SPLIT_ORDER_ITEM_ERROR,
    payload: err
  };
}

// VENDOR VIEW ACTIONS

export const GET_ORDER_FAILED_INFO = getActionName.async('GET_ORDER_FAILED_INFO');

export const getOrderFailedInfo = orderId => ({
  type: GET_ORDER_FAILED_INFO.ASYNC,
  payload: orderId
});

export const getOrderFailedInfoSuccess = data => ({
  type: GET_ORDER_FAILED_INFO.SUCCESS,
  payload: data
});

export const getOrderFailedInfoFailure = error => ({
  type: GET_ORDER_FAILED_INFO.FAIL,
  payload: { error }
});

export const GET_ORDER_LOGS = getActionName.async('GET_ORDER_LOGS');

export const getOrderLogs = orderId => ({
  type: GET_ORDER_LOGS.ASYNC,
  payload: orderId
});

export const getOrderLogsSuccess = data => ({
  type: GET_ORDER_LOGS.SUCCESS,
  payload: data
});

export const getOrderLogsFailure = error => ({
  type: GET_ORDER_LOGS.FAIL,
  payload: { error }
});

export const REATTEMPT_ORDER = getActionName.async('REATTEMPT_ORDER');

export const reattemptOrder = attemptId => ({
  type: REATTEMPT_ORDER.ASYNC,
  payload: attemptId
});

export const reattemptOrderSuccess = data => ({
  type: REATTEMPT_ORDER.SUCCESS,
  payload: data
});

export const reattemptOrderFailure = error => ({
  type: REATTEMPT_ORDER.FAIL,
  payload: error
});

// OTHERS

export function setActiveImage(itemId) {
  return {
    type: SET_ACTIVE_IMAGE,
    payload: itemId
  };
}
