import { postJson, fetch } from 'gooten-components/src/utils/http';
import Config from 'gooten-components/src/config';
import { SERVICE_LIVE_URL, SERVICE_STAGING_URL } from './constants';

class StoreBulkService {
  authKey = 'LpE4fY/+HA7xIcoy+nCpedTyMYt1BKMJ';

  getServiceUrl() {
    return Config.get('env') === 'staging' ? SERVICE_STAGING_URL : SERVICE_LIVE_URL;
  }

  bulkMigrationValidateCsv(partnerId, provider, storeName, data) {
    let url = `${this.getServiceUrl()}/api/v1/sync/validate?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;
    url += `&partner-id=${encodeURIComponent(partnerId)}`;
    url += `&provider=${encodeURIComponent(provider)}`;
    url += `&store-name=${encodeURIComponent(storeName)}`;

    const body = data.map((item, index) => {
      return {
        line: index,
        ...item
      };
    });

    return postJson(url, body);
  }

  bulkCreationValidateCsv(partnerId, provider, storeName, data) {
    let url = `${this.getServiceUrl()}/api/v1/validate?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;
    url += `&partner-id=${encodeURIComponent(partnerId)}`;
    url += `&provider=${encodeURIComponent(provider)}`;
    url += `&store-name=${encodeURIComponent(storeName)}`;

    const body = data.map((item, index) => {
      return {
        line: index,
        ...item
      };
    });

    return postJson(url, body);
  }

  bulkMigrationPublishCsv(partnerId, email, provider, storeName, uploadMockups, data) {
    let url = `${this.getServiceUrl()}/api/v1/sync/publish?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;
    url += `&email=${encodeURIComponent(email)}`;
    url += `&partner-id=${encodeURIComponent(partnerId)}`;
    url += `&provider=${encodeURIComponent(provider)}`;
    url += `&store-name=${encodeURIComponent(storeName)}`;
    url += `&upload-mockups=${encodeURIComponent(uploadMockups)}`;

    const body = data.map((item, index) => {
      return {
        line: index,
        ...item
      };
    });

    return postJson(url, body);
  }

  bulkMigrationFetchHistory() {
    let url = `${this.getServiceUrl()}/api/v1/sync/history?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;

    return fetch(url);
  }

  bulkCreationFetchHistory() {
    let url = `${this.getServiceUrl()}/api/v1/history?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;

    return fetch(url);
  }

  bulkCreationPublishCsv(partnerId, email, provider, storeName, publishToStore, data) {
    let url = `${this.getServiceUrl()}/api/v1/publish?`;
    url += `auth-key=${encodeURIComponent(this.authKey)}`;
    url += `&email=${encodeURIComponent(email)}`;
    url += `&partner-id=${encodeURIComponent(partnerId)}`;
    url += `&provider=${encodeURIComponent(provider)}`;
    url += `&store-name=${encodeURIComponent(storeName)}`;
    url += `&publish-to-store=${encodeURIComponent(publishToStore)}`;

    const body = data.map((item, index) => {
      return {
        line: index,
        ...item
      };
    });

    return postJson(url, body);
  }
}

export default new StoreBulkService();
