import React from 'react';
import PropTypes from 'prop-types';
import CancelButton from '../../components/shared/CancelButton';
import Config from '../../config';

import './PanelLayout.scss';
import Button from '../../components/shared/Button';
import Icon from '../../components/shared/Icon';

const renderButton = (collapsed, togglePanel) => {
  if (!togglePanel) {
    return null;
  }

  let cssClass = 'button-default large btn-toggle' + (collapsed ? ' panel-collapsed' : '');
  let iconClass = collapsed ? 'chevronLeft' : 'chevronRight';
  return (
    <Button iconOnly className={cssClass} onClick={() => togglePanel(false)}>
      <Icon icon={iconClass} />
    </Button>
  );
};

const getComponent = (children, key) => {
  return children.filter(c => c.key === key);
};

const getPanelContent = (children, collapsed) => {
  if (collapsed) {
    return null;
  } else {
    return <div className="panel-content">{getComponent(children, 'panel')}</div>;
  }
};

const hideStatusBar = props => {
  return Config.get('editMode') || Config.get('createMode') || Config.get('linkMode');
};

export const PanelLayout = props => {
  return (
    <div
      className={
        'panel-layout-container' +
        (props.collapsed ? ' collapsed' : '') +
        (props.noStatusBar || hideStatusBar() ? ' no-status-bar' : '')
      }
    >
      <div className="main-container">
        <div className="main-content">{getComponent(props.children, 'content')}</div>
      </div>

      <div className="panel-container">
        {renderButton(props.collapsed, props.togglePanel)}
        {getPanelContent(props.children, props.collapsed)}
        <div
          className={'buttons-container' + (props.collapsed || props.hideButtons ? ' hidden' : '')}
        >
          <Button
            className={
              'continue-button large' + (props.nextButtonHidden ? ' button-default' : ' button-cta')
            }
            disabled={props.nextButtonDisabled}
            onClick={() => props.onNextButtonClick()}
          >
            {props.nextButtonLabel}
          </Button>
          <CancelButton
            className="create-new-go-back"
            ignoreConfirmation={props.ignoreBackConfirmation}
            cancel={() => props.onBackButtonClick()}
          >
            {props.backButtonLabel}
          </CancelButton>
        </div>
      </div>
    </div>
  );
};

PanelLayout.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  togglePanel: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  backButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  nextButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  nextButtonHidden: PropTypes.bool,
  ignoreBackConfirmation: PropTypes.bool,
  hideButtons: PropTypes.bool,
  noStatusBar: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool
};

export default PanelLayout;
