/* eslint-disable prefer-promise-reject-errors */
const isIE = () => {
  const ieregexp = /msie/i;
  const ie11regexp = /trident\//i;
  if (navigator && navigator.userAgent) {
    const ua = navigator.userAgent.toLowerCase();
    return ieregexp.test(ua) || ie11regexp.test(ua);
  }
};

const isChrome = () => {
  const chromeregexp = /chrome/i;
  if (navigator && navigator.userAgent) {
    const ua = navigator.userAgent.toLowerCase();
    return chromeregexp.test(ua);
  }
};

const checkPopupBlocked = (popupWindow, openFailedCb) => {
  if (!openFailedCb) return;

  const _isPopupBlocked = () => popupWindow.innerHeight <= 0;

  if (popupWindow) {
    if (isChrome()) {
      setTimeout(() => {
        if (_isPopupBlocked()) {
          openFailedCb();
        }
      }, 200);
    } else {
      popupWindow.onload = () => {
        if (_isPopupBlocked()) {
          openFailedCb({ status: OAUTH_STATUS.POPUP_BOCKED });
        }
      };
    }
  } else {
    openFailedCb({ status: OAUTH_STATUS.POPUP_BOCKED });
  }
};

export const generalOauthPath = '/admin-assets/scripts/areas/shopify/vendors/';
export const shopyfyPermissions = [
  'read_products',
  'write_products',
  'read_orders',
  'write_orders',
  'read_fulfillments',
  'write_fulfillments',
  'write_inventory',
  'read_assigned_fulfillment_orders',
  'write_assigned_fulfillment_orders',
  'write_third_party_fulfillment_orders'
];

export const parseStoreUrl = url =>
  // eslint-disable-next-line no-useless-escape
  url ? (url.match(/[^\/\/\]]+(?=\.myshopify\.com)/) || [])[0] || url : url;

export const getShopifyOAuthUrl = (storeName, clientId, redirectPage) => {
  let port = window.location.port;
  if (port && port !== 80) {
    port = ':' + port;
  }
  const redirect = redirectPage
    ? window.location.protocol +
      '//' +
      window.location.hostname +
      port +
      generalOauthPath +
      redirectPage
    : window.location.protocol +
      '//' +
      window.location.hostname +
      port +
      '/Admin/Shopify/ConnectHubStore';
  storeName = storeName.replace('.myshopify.com', '');
  const url =
    'https://' +
    storeName +
    '.myshopify.com/admin/oauth/authorize?client_id=' +
    clientId +
    '&scope=' +
    shopyfyPermissions.join(',') +
    '&redirect_uri=' +
    redirect;
  return url;
};

export const OAUTH_STATUS = {
  OK: 'OK',
  REQUEST_FAILED: 'REQUEST_FAILED',
  POPUP_BOCKED: 'POPUP_BOCKED'
};

export const openOAuthPage = oauthUrl => {
  return new Promise((resolve, reject) => {
    let _window = {};
    let _isIE = isIE();
    const _onMessageReceive = e => {
      //ignore react-dev-tools and other messages
      const data = e.data || (e.originalEvent && e.originalEvent.data);
      if (!data || typeof data != 'string' || e.origin !== window.origin) {
        return;
      }
      let obj = data;
      let hadError = false;
      try {
        obj = JSON.parse(data);
        if (!obj.fromGeneric) return;
      } catch (err) {
        hadError = true;
      }
      if (e.source || e.originalEvent) {
        const source = e.source || e.originalEvent.source;
        source.close();
      } else {
        _window.close();
      }
      window.removeEventListener('message', _onMessageReceive);
      // eslint-disable-next-live prefer-promise-reject-errors
      if (hadError) return reject({ status: OAUTH_STATUS.REQUEST_FAILED });
      resolve(obj);
    };
    if (_isIE) {
      let port = window.location.port;
      if (port && port !== 80) {
        port = ':' + port;
      }
      let genUrl =
        window.location.protocol +
        '//' +
        window.location.hostname +
        port +
        '/admin-assets/scripts/areas/shopify/vendors' +
        '/generic-oauth.html';

      // we're passing the URL we want to go to,
      const gotoUrl = genUrl + '?goto=' + encodeURIComponent(oauthUrl);

      window.addEventListener('message', _onMessageReceive, false);

      _window = window.open(gotoUrl, '_blank');
    } else {
      window.addEventListener('message', _onMessageReceive);
      _window = window.open(oauthUrl, '_blank');
    }
    checkPopupBlocked(_window, reject);
  });
};
