import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const UPDATE_CARD = customActionFor('CREDIT_CARD').async('UPDATE_CARD');
export const REMOVE_METHOD = customActionFor('CREDIT_CARD').async('REMOVE_METHOD');
export const BRAINTREE_TOKEN = customActionFor('CREDIT_CARD')('BRAINTREE_TOKEN');
export const UPDATE_PAYPAL = customActionFor('CREDIT_CARD').async('UPDATE_PAYPAL');
export const UPDATE_DEFAULT_METHOD = customActionFor('CREDIT_CARD').async('UPDATE_DEFAULT_METHOD');
export const GET_SETUP_INTENT_ID = customActionFor('CREDIT_CARD').async('GET_SETUP_INTENT_ID');
export const SAVE_SETUP_INTENT_ID = customActionFor('CREDIT_CARD').async('SAVE_SETUP_INTENT_ID');
export const SHOW_CREDIT_CARD_MODAL =
  customActionFor('CREDIT_CARD').async('SHOW_CREDIT_CARD_MODAL');
export const HANDLE_STRIPE_RESPONSE =
  customActionFor('CREDIT_CARD').async('HANDLE_STRIPE_RESPONSE');

// ------------------------------------
// Actions
// ------------------------------------

export const updateCard = payload => ({
  type: UPDATE_CARD.ASYNC,
  payload
});

export const removeMethod = methodToken => ({
  type: REMOVE_METHOD.ASYNC,
  payload: { methodToken }
});

export const updateToken = payload => ({
  type: BRAINTREE_TOKEN,
  payload
});

export const getSetupIntentId = payload => ({
  type: GET_SETUP_INTENT_ID.ASYNC,
  payload
});

export const saveSetupIntentId = payload => ({
  type: SAVE_SETUP_INTENT_ID,
  payload
});

export const handleStripeResponse = payload => ({
  type: HANDLE_STRIPE_RESPONSE.ASYNC,
  payload
});

export const showCreditCardModal = payload => ({
  type: SHOW_CREDIT_CARD_MODAL,
  payload
});

export const updatePayPal = payload => ({
  type: UPDATE_PAYPAL.ASYNC,
  payload
});

export const updateDefault = methodToken => ({
  type: UPDATE_DEFAULT_METHOD.ASYNC,
  payload: { methodToken }
});
