import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import InviteTeamMember from './InviteTeamMember';
import { InviteTeamMemberModel } from '../../Models/InviteTeamMemberModel';
import { changeActionNameFor, updateActionNameFor } from '../BaseCard/BaseCardActions';

const change = changeActionNameFor(InviteTeamMember.name);
const update = updateActionNameFor(InviteTeamMember.name);
export default baseCardReducer(
  InviteTeamMember.name,
  (
    state = new Map({
      loading: false,
      data: new InviteTeamMemberModel(),
      isPristine: true,
      invited: false,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case change:
        return state.set('isPristine', false).set('invited', false);
      case update.SUCCESS:
        return state
          .set('busy', false)
          .set('isPristine', true)
          .set('invited', true)
          .set('data', new InviteTeamMemberModel());
      default:
        return state;
    }
  }
);
