import { combineReducers } from 'redux-immutable';
import profileInformation from './Cards/ProfileInformation/ProfileInformationReducer';
import password from './Cards/Password/PasswordReducer';
import passwordByEmail from './Cards/PasswordByEmail/PasswordByEmailReducer';
import advancedSettings from './Cards/AdvancedSettings/AdvancedSettingsReducer';
import billingAddress from './Cards/BillingAddress/BillingAddressReducer';
import billingHistory from './Cards/BillingHistory/BillingHistoryReducer';
import emailPreferences from './Cards/EmailPreferences/EmailPreferencesReducer';
import creditCard from './Cards/CreditCard/CreditCardReducer';
import apiKeys from './Cards/ApiKeys/ApiKeysReducer';
import webhooks from './Cards/Webhooks/WebhooksReducer';
import inviteTeamMember from './Cards/InviteTeamMember/InviteTeamMemberReducer';
import pendingInvitations from './Cards/PendingInvitations/PendingInvitationsReducer';
import yourTeam from './Cards/YourTeam/YourTeamReducer';
import neckLabels from './Cards/NeckLabels/NeckLabelsReducer';
import emailServices from './Cards/EmailServices/EmailServicesReducer';
import emailsContent from './Cards/EmailsContent/EmailsContentReducer';
import emailCredentials from './Cards/EmailCredentials/EmailCredentialsReducer';
import partnerTier from './Cards/PartnerTier/PartnerTierReducer';
import accountActivity from './Cards/AccountActivity/AccountActivityReducer';
import fulfillmentSettings from './Cards/FulfillmentSettings/FulfillmentSettingsReducer';
import returnAddress from './Cards/ReturnAddress/ReturnAddressReducer';
import taxExemption from './Cards/TaxExemption/TaxExemptionReducer';
import { COUNTRIES_LOADED, CLEAR_DATA } from './Cards/BaseCard/BaseCardActions';

// Partner user id should be configured in hub config
// but can be owerrided by this value to access user specific settings (profile information, etc.)
const partnerUserId = (state = null, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return null;
    default:
      return state;
  }
};

const countries = (state = null, action) => {
  switch (action.type) {
    case COUNTRIES_LOADED:
      return action.payload;
    default:
      return state;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default combineReducers({
  partnerUserId,
  countries,
  cards: combineReducers({
    profileInformation,
    password,
    passwordByEmail,
    advancedSettings,
    fulfillmentSettings,
    returnAddress,
    billingAddress,
    billingHistory,
    emailPreferences,
    creditCard,
    apiKeys,
    webhooks,
    partnerTier,
    inviteTeamMember,
    pendingInvitations,
    yourTeam,
    neckLabels,
    emailServices,
    emailsContent,
    emailCredentials,
    accountActivity,
    taxExemption
  })
});
