import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB', 'CUSTOMER_REFUND');

export const INIT_CUSTOMER_REFUND = getActionName('INIT_CUSTOMER_REFUND');
export const SAVE_CUSTOMER_REFUND_DATA = getActionName('SAVE_CUSTOMER_REFUND_DATA');
export const SAVE_CUSTOMER_REFUND_HISTORY = getActionName('SAVE_CUSTOMER_REFUND_HISTORY');
export const SAVE_PARTNER_DETAILS = getActionName('SAVE_PARTNER_DETAILS');
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const SUBMIT_CUSTOMER_REFUND = getActionName('SUBMIT_CUSTOMER_REFUND');
export const TOAST_TEXT = getActionName('TOAST_TEXT');
export const INIT_ALL_REASONS = getActionName('INIT_ALL_REASONS');
export const REASON_SELECT = getActionName('REASON_SELECT');
export const REFRESH = getActionName('REFRESH');

export const initCustomerRefund = payload => ({
  type: INIT_CUSTOMER_REFUND,
  payload
});

export const saveCustomerRefundData = payload => ({
  type: SAVE_CUSTOMER_REFUND_DATA,
  payload
});

export const saveCustomerRefundHistory = payload => ({
  type: SAVE_CUSTOMER_REFUND_HISTORY,
  payload
});

export const showLoading = payload => ({
  type: SHOW_LOADING,
  payload
});

export const submitCustomerRefund = payload => ({
  type: SUBMIT_CUSTOMER_REFUND,
  payload
});

export const savePartnerDetails = payload => ({
  type: SAVE_PARTNER_DETAILS,
  payload
});

export const toastText = payload => ({
  type: TOAST_TEXT,
  payload
});

export const initAllReasons = payload => ({
  type: INIT_ALL_REASONS,
  payload
});

export const reasonSelect = payload => ({
  type: REASON_SELECT,
  payload
});

export const refresh = () => ({
  type: REFRESH
});
