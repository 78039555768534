import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { DEACTIVATE, DOWNLOAD_DATA } from './AdvancedSettingsActions';
import settingsApiService from '../../SettingsApiService';
import { partnerUserIdSelector } from '../../SettingsSelectors';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import analyticsService from 'gooten-components/src/services/analyticsService';

export function* deactivateAsyncHandler(action) {
  try {
    const user = yield select(partnerUserIdSelector);
    yield call(settingsApiService.deactivateAccount, user, action.payload.data);
    yield put(fetchAsyncSuccess(DEACTIVATE, action.payload));
    yield put(push('Request sent.', 'success'));
    analyticsService.track('Settings - Advanced Settings', `Deactivate requested`, 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(DEACTIVATE, err));
    yield put(
      push('This email is not associated with a Gooten account or password incorrect.', 'failure')
    );
  }
}

export function* downloadDataAsyncHandler(action) {
  try {
    const user = yield select(partnerUserIdSelector);
    yield call(settingsApiService.downoadData, user, action.payload.data);
    yield put(fetchAsyncSuccess(DOWNLOAD_DATA, action.payload));
    yield put(push('Request sent.', 'success'));
    analyticsService.track('Settings - Advanced Settings', `Data download requested`, 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(DOWNLOAD_DATA, err));
    yield put(push('Request failed.', 'failure'));
  }
}

export function* watchDeactivateAsync() {
  yield takeLatest(DEACTIVATE.ASYNC, deactivateAsyncHandler);
}

export function* watchDownloadDataAsync() {
  yield takeLatest(DOWNLOAD_DATA.ASYNC, downloadDataAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchDeactivateAsync(), watchDownloadDataAsync()]);
}
