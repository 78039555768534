import { List, Map, fromJS } from 'immutable';
import {
  HIDE_TASK_NOTIFICATION,
  PRODUCT_SELECT,
  PRODUCT_UNSELECT,
  PAGE_CHANGE,
  PAGE_SIZE_CHANGE,
  QUERY_CHANGE,
  QUERY_LOAD,
  QUERY_INVALIDATE,
  VARIANTS_LOAD,
  CONNECTIONS_LOAD,
  PRODUCTS_UPDATING,
  PRODUCT_SELECT_BY_ID,
  PRODUCT_SELECT_EXCLUSIVE,
  SHOW_NOTIFICATION,
  COLLECTIONS_LOAD
} from './ProductsActions';
import {
  UNPUBLISH_PRODUCTS,
  PUBLISH_PRODUCTS,
  DELETE_PRODUCTS,
  DISPLAY_CHANGE,
  VIEW_BY_CHANGE,
  SORT_BY_CHANGE,
  SELECT_ALL,
  UNSELECT_ALL,
  UNLINK_PRODUCTS,
  PERSONALIZE_PRODUCTS,
  DEPERSONALIZE_PRODUCTS
} from '../Actions/ActionsActions';
import { RESET } from '../../../../store/actions/globalActions';
import { PUBLISH } from 'gooten-components/src/store/actions/dataActions';
import { PROVIDER_SELECT } from '../Providers/ProvidersActions';
import { STORE_SELECT, STORE_LOG_OUT } from '../Stores/StoresActions';
import { SEARCH_CHANGE } from '../Search/SearchActions';
import initialState from '../../../../store/initialState';
import { VARIANTS_FETCHED } from '../../../PlaceOrderView/PlaceOrderViewActions';
import { SHOW_SAMPLE_ORDER_MODAL } from './OrderSampleModal/OrderSampleModalActions';

const getFailedActionError = action => {
  switch (action.type) {
    case PUBLISH_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from marking as Published'
      };
    }
    case UNPUBLISH_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from marking as Unpublished'
      };
    }
    case DELETE_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from being deleted'
      };
    }
    case UNLINK_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from stoping fulfillment'
      };
    }
    case PERSONALIZE_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from being personalized'
      };
    }
    case DEPERSONALIZE_PRODUCTS.FAIL: {
      return {
        error: 'Something is preventing this product from being depersonalized'
      };
    }
  }
};

const defaultState = initialState.getIn(['hub', 'hubView', 'products']);
const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        // reset state only if scope is global
        return defaultState;
      } else {
        return state;
      }
    }

    case PRODUCT_SELECT: {
      return state
        .update('selected', selected => selected.push(fromJS(action.payload.product)))
        .set('variants', null);
    }

    case COLLECTIONS_LOAD.SUCCESS: {
      const index = listName =>
        state.get(`${listName}`).findIndex(item => item.get('id') === action.payload.productId);
      const updatedState = (listName, selectedIndex, oldState) =>
        selectedIndex !== -1
          ? oldState.updateIn([`${listName}`, selectedIndex], selected =>
              selected.set(
                'collections',
                fromJS(action.payload.collections.map(c => ({ id: c.id, name: c.title })))
              )
            )
          : oldState;

      const newState = updatedState('selected', index('selected'), state);
      return state.get('connections')
        ? updatedState('connections', index('connections'), newState)
        : newState;
    }

    case PRODUCT_SELECT_BY_ID: {
      return state
        .update('selected', selected =>
          selected.push(
            state
              .get('cachedQueries')
              .get(state.get('currentQuery'))
              .get('items')
              .find(item => item.get('id') === action.payload)
          )
        )
        .set('variants', null);
    }

    case PRODUCT_SELECT_EXCLUSIVE: {
      return state
        .set('selected', new List([fromJS(action.payload.product)]))
        .set('variants', null);
    }

    case PRODUCT_UNSELECT: {
      return state.update('selected', selected =>
        selected.filter(s => s.get('id') !== action.payload.product.id)
      );
    }

    case PAGE_CHANGE: {
      return state.set('page', action.payload).set('selected', new List());
    }

    case PAGE_SIZE_CHANGE: {
      return state.set('pageSize', action.payload).set('page', 1);
    }

    case DISPLAY_CHANGE: {
      return state.set('display', action.payload);
    }

    case VIEW_BY_CHANGE: {
      return state.set('viewBy', action.payload).set('page', 1).set('selected', new List());
    }

    case SORT_BY_CHANGE: {
      return state.set('sortBy', action.payload).set('page', 1).set('selected', new List());
    }

    case SELECT_ALL: {
      const items = new List(
        state.get('cachedQueries').get(state.get('currentQuery')).get('items')
      );

      if (action.payload === 'unpublished') {
        return state.set(
          'selected',
          items.filter(
            item =>
              item.get('status').toLowerCase() === 'storage' ||
              item.get('status').toLowerCase() === 'unpublished'
          )
        );
      } else if (action.payload) {
        return state.set(
          'selected',
          items.filter(item => item.get('status').toLowerCase() === action.payload)
        );
      }
      return state.set('selected', items);
    }

    case UNSELECT_ALL: {
      return state.set('selected', new List());
    }

    case PRODUCTS_UPDATING: {
      return state.set('updating', action.payload.value);
    }

    case QUERY_INVALIDATE: {
      return state
        .set('cachedQueries', defaultState.get('cachedQueries'))
        .set('selected', new List());
    }

    case PROVIDER_SELECT:
    case STORE_SELECT: {
      if (action.payload.reset) {
        return state.set('viewBy', undefined).set('page', 1).set('selected', new List());
      } else {
        return state;
      }
    }

    case SEARCH_CHANGE: {
      return state.set('page', 1).set('selected', new List());
    }

    case QUERY_CHANGE: {
      return state.set('currentQuery', action.payload);
    }

    case QUERY_LOAD.SUCCESS: {
      return state.update('cachedQueries', cachedQueries =>
        cachedQueries.set(action.payload.key, action.payload.data)
      );
    }

    case VARIANTS_LOAD.SUCCESS: {
      return state.set('variants', action.payload.data);
    }

    case CONNECTIONS_LOAD.SUCCESS: {
      return state.set('connections', action.payload.connections);
    }

    case PUBLISH.SUCCESS: {
      return state
        .set('selected', defaultState.get('selected'))
        .set('cachedQueries', defaultState.get('cachedQueries'))
        .set('currentQuery', defaultState.get('currentQuery'))
        .set('page', defaultState.get('page'))
        .set('viewBy', defaultState.get('viewBy'))
        .set('sortBy', defaultState.get('viewBy'))
        .update('tasks', tasks => {
          return tasks.push(
            new Map({
              id: action.payload.taskId || +new Date(),
              request: fromJS(action.payload.request),
              notify: true,
              isEmbroidery: action.payload.isEmbroidery,
              publishType: action.payload.publishType,
              status: 'success', // 'processing' | 'success' | 'failed'
              results: fromJS(action.payload.results)
            })
          );
        });
    }

    case PUBLISH.FAIL: {
      return state.update('tasks', tasks => {
        return tasks.push(
          new Map({
            id: action.payload.taskId || +new Date(),
            request: fromJS(action.payload.request),
            notify: true,
            reasons: action.payload.reasons,
            publishType: action.payload.publishType,
            status: 'failed', // 'processing' | 'success' | 'failed'
            error: action.payload.error
          })
        );
      });
    }

    case PUBLISH_PRODUCTS.FAIL:
    case UNPUBLISH_PRODUCTS.FAIL:
    case UNLINK_PRODUCTS.FAIL:
    case PERSONALIZE_PRODUCTS.FAIL:
    case DEPERSONALIZE_PRODUCTS.FAIL:
    case DELETE_PRODUCTS.FAIL: {
      return state.update('tasks', tasks => {
        return tasks.push(
          new Map({
            id: action.payload.taskId || +new Date(),
            request: fromJS(action.payload.request),
            notify: true,
            status: 'failed', // 'processing' | 'success' | 'failed'
            ...getFailedActionError(action)
          })
        );
      });
    }

    case STORE_LOG_OUT.SUCCESS: {
      return state
        .set('selected', defaultState.get('selected'))
        .set('cachedQueries', defaultState.get('cachedQueries'))
        .set('currentQuery', defaultState.get('currentQuery'))
        .set('page', defaultState.get('page'))
        .set('viewBy', defaultState.get('viewBy'));
    }

    case HIDE_TASK_NOTIFICATION: {
      return state.update('tasks', tasks =>
        tasks.update(
          tasks.findIndex(t => t.id === action.payload.taskId),
          task => task.set('notify', false)
        )
      );
    }

    case VARIANTS_FETCHED: {
      return state.update('selected', selected =>
        selected.map(item => {
          const variantsHolder = action.payload.find(o => o.get('productId') === item.get('id'));
          return item.set(
            'variants',
            (variantsHolder && variantsHolder.get('variants')) || new List()
          );
        })
      );
    }

    case SHOW_NOTIFICATION: {
      return state.set('notification', fromJS(action.payload));
    }

    case SHOW_SAMPLE_ORDER_MODAL: {
      return state.set('showSampleOrderModal', action.payload);
    }

    default:
      return state;
  }
};

export default productsReducer;
