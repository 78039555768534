import { Map, List, fromJS } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import YourTeam from './YourTeam';
import { YourTeamModel } from '../../Models/YourTeamModel';
import { UPDATE_ACCCESS, REMOVE_MEMBER } from './YourTeamActions';
import { initActionNameFor } from '../BaseCard/BaseCardActions';

const initYourTeam = initActionNameFor(YourTeam.name);

export default baseCardReducer(
  YourTeam.name,
  (
    state = new Map({
      data: new List(),
      loading: true,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case initYourTeam.SUCCESS:
        return state.set('permissions', fromJS(action.payload.data.Permissions));
      case UPDATE_ACCCESS.ASYNC:
      case REMOVE_MEMBER.ASYNC:
        return state.set('busy', true);
      case REMOVE_MEMBER.FAIL:
      case UPDATE_ACCCESS.FAIL:
      case UPDATE_ACCCESS.SUCCESS:
        return state.set('busy', false).update('data', data =>
          data.update(
            data.findIndex(i => i.get('Id') === action.payload.get('Id')),
            i => i.set('Permissions', action.payload.get('Permissions'))
          )
        );
      case REMOVE_MEMBER.SUCCESS:
        return state.set('busy', false).set(
          'data',
          state.get('data').filter(i => i.get('Id') !== action.payload.get('Id'))
        );
      default:
        return state;
    }
  },
  YourTeamModel
);
