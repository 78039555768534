import { injectSaga } from '../store/sagas';
import actionsSaga from './HubView/components/Actions/ActionsSaga';
import storesSaga from './HubView/components/Stores/StoresSaga';
import productsSaga from './HubView/components/Products/ProductsSaga';
import editProductSaga from './EditProductView/EditProductViewSaga';
import duplicateProductSaga from './DuplicateProductView/DuplicateProductViewSaga';
import registerLinkProductSagas from './LinkProduct/LinkProductSaga';
import placeOrderSaga from './PlaceOrderView/PlaceOrderViewSaga';
import connectStoreSaga from './ConnectStoreView/ConnectStoreViewSaga';

const registerHubSagas = () => {
  injectSaga(actionsSaga);
  injectSaga(storesSaga);
  injectSaga(productsSaga);
  injectSaga(editProductSaga);
  injectSaga(duplicateProductSaga);
  injectSaga(connectStoreSaga);
  injectSaga(placeOrderSaga);

  registerLinkProductSagas();
};

export default registerHubSagas;
