import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import { initActionNameFor } from '../BaseCard/BaseCardActions';
import CreditCard from './CreditCard';
import { CreditCardModel } from '../../Models/CreditCardModel';
import {
  UPDATE_CARD,
  UPDATE_PAYPAL,
  REMOVE_METHOD,
  UPDATE_DEFAULT_METHOD,
  BRAINTREE_TOKEN,
  GET_SETUP_INTENT_ID,
  SAVE_SETUP_INTENT_ID,
  SHOW_CREDIT_CARD_MODAL,
  HANDLE_STRIPE_RESPONSE,
  SET_STRIPE_BUSY
} from './CreditCardActions';

const init = initActionNameFor(CreditCard.name);

export default baseCardReducer(
  CreditCard.name,
  (
    state = new Map({
      data: new CreditCardModel(),
      loading: true,
      braintreeToken: '',
      busy: false,
      open: true,
      loadingSetupIntentId: false,
      setupIntentId: '',
      clientSecret: '',
      showingCreditCardModal: false,
      stripeFormBusy: false
    }),
    action
  ) => {
    switch (action.type) {
      case init.ASYNC:
        return state.set('loading', true);
      case init.SUCCESS:
        return state.set('loading', false);
      case BRAINTREE_TOKEN:
        return state.set('braintreeToken', action.payload);
      case SAVE_SETUP_INTENT_ID:
        return state
          .set('setupIntentId', action.payload.SetupIntentId)
          .set('clientSecret', action.payload.ClientId)
          .set('loadingSetupIntentId', false);
      case SHOW_CREDIT_CARD_MODAL:
        return state.set('showingCreditCardModal', action.payload);
      case UPDATE_CARD.ASYNC:
      case UPDATE_PAYPAL.ASYNC:
      case REMOVE_METHOD.ASYNC:
      case UPDATE_DEFAULT_METHOD.ASYNC:
        return state.set('busy', true);
      case GET_SETUP_INTENT_ID.ASYNC:
        return state.set('loadingSetupIntentId', true);
      case HANDLE_STRIPE_RESPONSE.ASYNC:
        return state.set('stripeFormBusy', true);
      case UPDATE_PAYPAL.FAIL:
      case UPDATE_CARD.FAIL:
      case REMOVE_METHOD.FAIL:
      case UPDATE_DEFAULT_METHOD.FAIL:
        return state.set('busy', false);
      case GET_SETUP_INTENT_ID.FAIL:
      case HANDLE_STRIPE_RESPONSE.FAIL:
        return state.set('showingCreditCardModal', false).set('stripeFormBusy', false);
      case UPDATE_PAYPAL.SUCCESS:
      case UPDATE_CARD.SUCCESS:
      case REMOVE_METHOD.SUCCESS:
      case UPDATE_DEFAULT_METHOD.SUCCESS:
        return state.set('busy', false).set('data', new CreditCardModel(action.payload.newData));
      case HANDLE_STRIPE_RESPONSE.SUCCESS:
        return state.set('showingCreditCardModal', false).set('stripeFormBusy', false);
      default:
        return state;
    }
  },
  CreditCardModel
);
