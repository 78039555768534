import { connect } from 'react-redux';
import ImageEditor from './ImageEditor';
import { ILHistorySelector, bulkEditAvailableSelector } from './ImageEditorSelectors';
import {
  imageEditorChange,
  imageEditorDxChange,
  imageEditorUndo,
  imageEditorRedo,
  imageEditorBulkModeToggle
} from './ImageEditorActions';
import { bulkEditSelector } from '../../../../store/selectors/productDataSelectors';
import { shouldChangeOrientation } from '../../../SKUSelection/SKUSelectionSelectors';
import { isEmbroiderySelected } from '../Embroidery/EmbroiderySelectors';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  saveState: editorState => imageEditorChange(editorState),
  saveDx: dx => imageEditorDxChange(dx),
  undo: () => imageEditorUndo(),
  redo: () => imageEditorRedo(),
  toggleBulkMode: bulk => imageEditorBulkModeToggle(bulk)
};

const mapStateToProps = state => ({
  ilHistory: ILHistorySelector(state),
  bulkEdit: bulkEditSelector(state),
  bulkEditingAvailability: bulkEditAvailableSelector(state),
  changeOrientation: shouldChangeOrientation(state),
  // editing should be disabled for Embroidery products
  disableImageEditing: isEmbroiderySelected(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditor);
