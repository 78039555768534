import React from 'react';
import PropTypes from 'prop-types';
import './ValuesList.scss';

export const ValuesList = ({ values }) => (
  <div className="component-values-list">
    {values.map(function (value, j) {
      return (
        <div
          key={j}
          dangerouslySetInnerHTML={{
            __html: value.name
          }}
        />
      );
    })}
  </div>
);

ValuesList.propTypes = {
  values: PropTypes.array.isRequired
};

ValuesList.displayName = 'ValuesList';

export default ValuesList;
