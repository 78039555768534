import {
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH,
  SKU_SELECT,
  SELECT_PRODUCTS,
  PREVIEWS_PREPARE,
  EXCLUSIVE_MOCKUPS_PREPARE,
  UPDATE_SPACE_PREVIEW,
  TEMPLATES_FETCH,
  IMAGE_ADD,
  VARIANT_DELETE,
  DUMP_EDITOR_STATE,
  CLEAN_SELECTION,
  ADD_TO_CART,
  RESET_SELECTED_PRODUCT,
  SET_CART_ITEM_EDIT_MODE,
  EDIT_CART_ITEM,
  UPDATE_CART_ITEM,
  UPDATE_CART_ITEM_PREVIEW,
  REMOVE_CART_ITEM,
  TOGGLE_ITEM_AS_SAMPLE,
  CLEAR_CART,
  UPDATE_CART_ITEM_QUANTITY,
  UPDATE_CART_ITEM_PRICING,
  SET_ORDER_SUMMARY_LOADING,
  UPDATE_ORDER_SUMMARY_TAX,
  UPDATE_ORDER_SUMMARY_SUB_TOTAL,
  UPDATE_ORDER_SUMMARY_SHIP_TOTAL,
  UPDATE_ORDER_SUMMARY_SURCHARGE,
  SHOW_CART_ITEM_PREVIEW,
  DOWNLOAD_CART_ITEM_PREVIEW,
  CLOSE_CART_ITEM_PREVIEW,
  RESTORE_CART,
  UPDATE_SHIPPING_COUNTRY,
  FETCH_COUNTRIES,
  APPEND_TO_IL_HISTORY,
  BULK_UPDATE_SPACES_IL,
  EDITOR_BULK_UNDO,
  EDITOR_BULK_REDO,
  SET_DISABLED_SKUS,
  UPDATE_PRODUCTION_TIME_DATA,
  SET_COF_NECK_LABEL,
  LOADED_ITEM_PRICE,
  SHOPIFY_REDIRECT_REQUIRED_STORES
} from '../actions/dataActions';
import { RESET } from '../actions/globalActions';
import { mapVariants } from '../../models/Variant';
import { mapVariantSpace } from '../../models/VariantSpace';
import { fromJS, List, Map } from 'immutable';
import { sortSKUs } from '../../utils/skus';
import { sanitizeCountryCode } from '../../utils/countries';
import { removeDuplicatesBy } from '../../utils/array';
import Log from '../../services/logService';
import { getSkuItemPriceKey } from '../../components/Cart/services/ItemsPriceInfoService';
import { ArchivedReason } from '../../const/ArchivedReasons';
import Config from '../../config';
import { rearrangeOptions } from '../../utils/productOptions';

const cartDefaultState = fromJS({
  items: [],
  totals: {
    isLoading: false,
    items: null,
    shipping: null,
    tax: null
  },
  isCartItemPreviewShown: false,
  previewImageUrl: undefined,
  restored: false,
  errors: []
});

const defaultState = fromJS({
  data: {
    categories: [],
    products: [],
    cart: cartDefaultState,
    countries: [],
    shippingCountry: undefined,
    bulkUpdates: {},
    shopifyRedirectRequiredStores: []
  }
});

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PRODUCTS_FETCH.SUCCESS]: (state, action) => {
    // NOTE: With current hacky link flow PRODUCTS_FETCH may occur when navigating from old shopify pages to hub
    // and this may finish when products are actually loaded already
    // and b/c it is overwritting it  - selectedSkus and selectedProductId may be lost
    // which cause bug with empty sku list on link flow
    // https://github.com/printdotio/gooten-hub/issues/252
    // To fix it - check if products are there and don't overwrite them
    if (
      !state.get('products') ||
      !state.get('products').size ||
      state.get('partnerId') != Config.get('partnerId') //products loaded depends on partner, not all partners are able to use same products.
    ) {
      return state
        .set('products', new List(action.payload.products))
        .set('categories', new List(action.payload.categories))
        .set('partnerId', Config.get('partnerId'));
    } else {
      return state;
    }
  },

  [PRODUCT_DETAILS_FETCH.SUCCESS]: (state, action) => {
    state.get('products').forEach((product, index) => {
      if (product.name === action.payload.product.name) {
        const newPayload = rearrangeOptions(action.payload);
        const record = state.get('products').get(index).set('details', newPayload);
        state = state.setIn(['products', index], record);
      }
    });
    return state;
  },

  [PRODUCT_VARIANTS_FETCH.SUCCESS]: (state, action) => {
    state.get('products').forEach((product, index) => {
      if (product.name === action.payload.productName) {
        const record = state.get('products').get(index).set('variants', action.payload.variants);
        state = state.setIn(['products', index], record);
      }
    });
    return state;
  },

  [TEMPLATES_FETCH.SUCCESS]: (state, action) => {
    let skus = [];
    let disabledSkus = []; // holds skus that are disabled(removed) from Gooten
    // check if any sku has been deleted(disabled)
    const products = state.get('products');
    const allVariants = new Set(
      state.get('selectedProductIds').reduce((a, c) => {
        const selectedProduct = products.find(p => p.id === c);
        if (selectedProduct) {
          return a.concat(selectedProduct.get('variants').map(v => v.sku.toLowerCase()));
        } else {
          return a;
        }
      }, [])
    );

    state.get('selectedSkus').forEach((variant, index) => {
      const template = variant.get('template') || action.payload.templates[variant.get('sku')];

      // if sku doesn't have template info, mark it as Discontinued...
      if (!template) {
        Log.report(`Template not found for SKU: ${variant.get('sku')}`);
        disabledSkus.push(variant.set('archivedReason', ArchivedReason.discontinuedProduct));
        return;
      }

      // check if sku is in sku list (allVariants)...
      if (!allVariants.has(variant.get('sku').toLowerCase())) {
        Log.report(`SKU not found in sku list: ${variant.get('sku')}`);
        // if sku, which isn't in sku list, but it is in action.payload.disabledSkus,
        // take sku status from action.payload.disabledSkus...
        const ds = action.payload.disabledSkus?.find(ds => ds.gooten_sku === variant.get('sku'));
        if (ds) {
          disabledSkus.push(variant.set('archivedReason', ds.archived_reason));
          return;
        }

        // if sku isn't in sku list, mark it as Discontinued...
        disabledSkus.push(variant.set('archivedReason', ArchivedReason.discontinuedProduct));
        return;
      }
      let isOutOfStock = false;
      if (action.payload.disabledSkus) {
        const ds = action.payload.disabledSkus.find(ds => ds.gooten_sku === variant.sku);
        if (ds) {
          if (ds.archived_reason != ArchivedReason.outOfStock) {
            disabledSkus.push(variant.set('archivedReason', ds.archived_reason));
            return;
          } else {
            isOutOfStock = true;
          }
        }
      }

      if (variant.get('template')) {
        if (isOutOfStock) {
          variant.set('archivedReason', ArchivedReason.outOfStock);
        }
        return skus.push(variant);
      }

      const existingSpaces = variant.get('spaces') ? variant.get('spaces').toJS() : [];
      const spaces = new List(
        template.Spaces.map((s, i) => {
          // NOTE: If something changed in template - spaceIds are changed too
          // We trying to match by spaceId first
          let existingSpace = existingSpaces.find(es => es.id === s.Id);
          if (!existingSpace && existingSpaces.length) {
            // if space by id not found but some existing spaces are there
            // match by index
            console.warn(`Space id doesn't match template for sku ${variant.get('sku')}`);
            existingSpace = existingSpaces[i];
          }
          return mapVariantSpace(s, template, existingSpace);
        }).filter(s => !!s.get('template'))
      );

      // insert Pro previews data into Redux...
      const proPreviewScenes = new List(action.payload.proPreviewTemplates[variant.get('sku')]);

      let record = state
        .get('selectedSkus')
        .get(index)
        .set('template', fromJS(template))
        .set('spaces', spaces);

      if (proPreviewScenes.size) {
        record = record.set(
          'proPreview',
          new Map({
            scenes: proPreviewScenes,
            imageUrls: new List()
          })
        );
      }

      // insert External mockup data into Redux...
      if (template.ExternalMockups) {
        record = record.set(
          'externalMockup',
          new Map({
            mockups: new List(template.ExternalMockups),
            imageUrls: new List()
          })
        );
      }

      record = record.set('archivedReason', template.ArchivedReason);

      skus.push(record);
    });

    // skip sorting skus if it is Link mode
    if (action.payload.isLinkMode) {
      return state.set('selectedSkus', new List(skus));
    }
    const sortedSkusByTemplate = skus.sort((v1, v2) =>
      v1.getIn(['template', 'size', 'width']) * v1.getIn(['template', 'size', 'height']) >
      v2.getIn(['template', 'size', 'width']) * v2.getIn(['template', 'size', 'height'])
        ? 1
        : -1
    );

    const sortedSkus = sortSKUs(sortedSkusByTemplate);
    return state
      .set('selectedSkus', new List(sortedSkus))
      .set('disabledSkus', new List(disabledSkus));
  },

  [SELECT_PRODUCTS]: (state, action) =>
    state
      .set('selectedProductIds', fromJS(action.payload)) // used for multiproduct
      .set('selectedProductId', action.payload[0]), // used for single product

  [SKU_SELECT]: (state, action) => {
    // SKU_SELECT holds new and/or existing skus. Map these skus, and for existing skus hold sku indexes,
    // for new skus assign new indexes...
    let finalSkus = mapVariants(action.payload.skus);
    return state.set('selectedSkus', new List(finalSkus));
  },

  [PREVIEWS_PREPARE.SUCCESS]: (state, action) =>
    state.update('selectedSkus', selectedSkus => {
      action.payload.items.forEach(updatedSku => {
        selectedSkus = selectedSkus.update(updatedSku.index, variant =>
          variant.update('spaces', spaces => {
            updatedSku.spaces.forEach(updatedSpace => {
              spaces = spaces.update(
                spaces.findIndex(v => v.get('id') === updatedSpace.id),
                space => {
                  if (updatedSpace.previewImgManipCmd) {
                    space = space
                      .set('previewImgManipCmd', updatedSpace.previewImgManipCmd)
                      .set('previewImgUrl', updatedSpace.previewImgUrl);
                  }

                  if (updatedSpace.printImgManipCmd) {
                    space = space
                      .set('printImgManipCmd', updatedSpace.printImgManipCmd)
                      .set('printImgUrl', updatedSpace.printImgUrl);
                  }

                  return space;
                }
              );
            });
            return spaces;
          })
        );
      });
      return selectedSkus;
    }),

  [PREVIEWS_PREPARE.FAIL]: (state, action) => {
    console.log('-------------------------------');
    console.log('--------- PUBLISH FAIL --------');
    console.log('-------------------------------');
    return state;
  },

  [EXCLUSIVE_MOCKUPS_PREPARE.SUCCESS]: (state, action) =>
    state.update('selectedSkus', selectedSkus => {
      action.payload.forEach(updatedSku => {
        selectedSkus = selectedSkus.update(updatedSku.index, variant => {
          let v = variant.update('spaces', spaces => {
            updatedSku.spaces.forEach(updatedSpace => {
              spaces = spaces.update(
                spaces.findIndex(v => v.get('id') === updatedSpace.id),
                space => {
                  if (updatedSpace.smallPrintImgUrl) {
                    space = space
                      .set('smallPrintImgManipCmd', updatedSpace.smallPrintImgManipCmd)
                      .set('smallPrintImgUrl', updatedSpace.smallPrintImgUrl);
                  }
                  if (updatedSpace.printImgManipCmd) {
                    space = space
                      .set('printImgManipCmd', JSON.stringify(updatedSpace.printImgManipCmd))
                      .set('printImgUrl', updatedSpace.printImgUrl);
                  }
                  return space;
                }
              );
            });
            return spaces;
          });

          if (v.get('proPreview')) {
            v = v.setIn(['proPreview', 'imageUrls'], new List(updatedSku.proPreview.imageUrls));
          }

          if (v.get('externalMockup')) {
            v = v.setIn(
              ['externalMockup', 'imageUrls'],
              new List(updatedSku.externalMockup.imageUrls)
            );
          }

          return v;
        });
      });

      return selectedSkus;
    }),

  [IMAGE_ADD]: (state, action) => {
    let skuName;
    return state
      .update('selectedSkus', selectedSkus => {
        action.payload.images.forEach(image => {
          selectedSkus = selectedSkus.update(image.variantIndex, variant => {
            skuName = variant.get('sku');
            return variant.update('spaces', spaces =>
              spaces.update(
                spaces.findIndex(v => v.get('id') === image.spaceId),
                space =>
                  space
                    .set('images', new List([fromJS(image)]))
                    .set('dumpedState', null)
                    .set('il', null)
              )
            );
          });
        });
        return selectedSkus;
      })
      .update('bulkUpdates', bulkUpdates => bulkUpdates.delete(skuName));
  },

  [VARIANT_DELETE]: (state, action) =>
    state.update('selectedSkus', selectedSkus => selectedSkus.delete(action.payload.variantIndex)),

  [DUMP_EDITOR_STATE]: (state, action) => {
    const il = action.payload.il;
    return state.update('selectedSkus', selectedSkus =>
      selectedSkus.update(
        action.payload.variantIndex,
        variant =>
          variant &&
          variant.update('spaces', spaces =>
            spaces.update(
              spaces.findIndex(v => v.get('id') === action.payload.spaceId),
              space => space && space.set('dumpedState', action.payload.editorState).set('il', il)
            )
          )
      )
    );
  },

  [UPDATE_SPACE_PREVIEW]: (state, action) => {
    return state.update('selectedSkus', selectedSkus =>
      selectedSkus.update(
        action.payload.variantIndex,
        variant =>
          variant &&
          variant.update('spaces', spaces =>
            spaces.update(
              spaces.findIndex(v => v.get('id') === action.payload.spaceId),
              space => space && space.set('base64', action.payload.base64)
            )
          )
      )
    );
  },

  [RESET]: (state, action) => {
    return (
      state
        // .set('products', new List()) //we only update product data on partner change
        .set('selectedProductId', undefined)
        .set('selectedSkus', new List())
        .set('disabledSkus', new List())
        .set('selectedProductIds', new List())
    );
  },

  [CLEAN_SELECTION]: (state, action) => {
    return state
      .set('selectedProductId', undefined)
      .set('selectedSkus', new List())
      .set('disabledSkus', new List())
      .set('selectedProductIds', new List());
  },

  [ADD_TO_CART]: (state, action) => {
    let newCartItems = new List(state.getIn(['cart', 'items']));
    newCartItems = newCartItems.concat(action.payload.items);
    return state.setIn(['cart', 'items'], newCartItems);
  },

  [RESTORE_CART]: (state, action) => {
    return state
      .setIn(['cart', 'items'], action.payload.items)
      .set('shippingCountry', sanitizeCountryCode(action.payload.shippingCountry))
      .setIn(['cart', 'restored'], true);
  },

  [RESET_SELECTED_PRODUCT]: (state, action) => {
    return state
      .set('selectedProductId', undefined)
      .set('selectedSkus', new List())
      .set('disabledSkus', new List())
      .set('selectedProductIds', new List());
  },

  [EDIT_CART_ITEM]: (state, action) => {
    return state
      .set('selectedSkus', new List([action.payload.item.get('sku')]))
      .set('selectedProductId', action.payload.item.get('productId'))
      .set('selectedProductIds', new List([action.payload.item.get('productId')]))
      .set('editItemIndex', action.payload.index);
  },

  // when user leaves Image Upload editor, set edit mode to false
  [SET_CART_ITEM_EDIT_MODE]: (state, action) => {
    let newState = state.set(
      'imageUploadConfig',
      fromJS({ isCartItemEditMode: action.payload.isCartItemEditMode })
    );
    if (!newState.get('isCartItemEditMode')) {
      newState = newState.set('editItemIndex', undefined);
    }
    return newState;
  },

  [UPDATE_CART_ITEM]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      return items.set(
        action.payload.index,
        action.payload.item
          .set(
            'sku',
            state
              .get('selectedSkus')
              .get(0)
              .set('orientation', action.payload.item.getIn(['sku', 'orientation']))
              .set('savedOrientation', action.payload.item.getIn(['sku', 'savedOrientation']))
          )
          .set('previewImageUrl', undefined)
          .set('largePreviewImageUrl', undefined)
      );
    });
  },

  [UPDATE_CART_ITEM_PREVIEW]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      // Check if cart item wasn't removed
      const cartItemIndex = items.findIndex(x => x.get('_random_id') === action.payload.cartItemId);
      if (cartItemIndex >= 0) {
        return items.setIn([cartItemIndex, 'previewImageUrl'], action.payload.previewImageUrl);
      } else {
        return items;
      }
    });
  },

  [UPDATE_PRODUCTION_TIME_DATA]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      const updatedCartItems = items.toJS().map(item => {
        if (item.productId == action.payload.productId) {
          return {
            ...item,
            productionTimeData: action.payload.productionTimeData
          };
        } else {
          return item;
        }
      });

      return fromJS(updatedCartItems);
    });
  },

  [REMOVE_CART_ITEM]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      return items.remove(action.payload.index);
    });
  },

  [TOGGLE_ITEM_AS_SAMPLE]: (state, action) =>
    state.updateIn(['cart', 'items'], items =>
      items.setIn(
        [action.payload.index, 'isSample'],
        !items.getIn([action.payload.index, 'isSample'])
      )
    ),

  [CLEAR_CART]: (state, action) => {
    // check if Cart was restored or not before clearing,
    // and set that value after clearing Cart
    const cartRestored = state.getIn(['cart', 'restored']);
    return state
      .set('cart', cartDefaultState)
      .setIn(['cart', 'restored'], cartRestored)
      .set('shippingCountry', undefined);
  },

  [UPDATE_CART_ITEM_QUANTITY]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      var index = items.findIndex(item => item === action.payload.item);
      return items.setIn([index, 'quantity'], action.payload.newQuantity);
    });
  },

  [LOADED_ITEM_PRICE]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      return items.map(item => {
        const loadedPrice = action.payload.value;
        return item.set('loaded', loadedPrice);
      });
    });
  },

  [UPDATE_CART_ITEM_PRICING]: (state, action) => {
    return state.updateIn(['cart', 'items'], items => {
      return items.map(item => {
        const skuKey = getSkuItemPriceKey(item);
        const pricing = action.payload.pricing.get(skuKey, new Map());

        if (pricing.getIn(['PartnerPrice', 'Price']) > 0) {
          return item.set('pricing', pricing);
        }

        return item;
      });
    });
  },

  [SHOW_CART_ITEM_PREVIEW.ASYNC]: (state, action) => {
    return state.update('cart', cart => {
      return cart.set('isCartItemPreviewShown', true);
    });
  },

  [SHOW_CART_ITEM_PREVIEW.SUCCESS]: (state, action) => {
    return state.update('cart', cart => {
      let updatedCart = cart.set('previewImageUrl', action.payload.largePreviewImageUrl);

      // Set largePreviewImageUrl to not generate it next time
      if (action.payload.cartItemId) {
        updatedCart = updatedCart.update('items', items => {
          const index = items.findIndex(
            item => item.get('_random_id') === action.payload.cartItemId
          );
          if (index >= 0) {
            return items.setIn(
              [index, 'largePreviewImageUrl'],
              action.payload.largePreviewImageUrl
            );
          } else {
            console.warn(
              'Can not update cart item large preview, item not found',
              action.payload.cartItemId
            );
            return items;
          }
        });
      }

      return updatedCart;
    });
  },

  [DOWNLOAD_CART_ITEM_PREVIEW.SUCCESS]: (state, action) => {
    if (action.payload.cartItemId) {
      return state.updateIn(['cart', 'items'], items => {
        const index = items.findIndex(item => item.get('_random_id') === action.payload.cartItemId);
        if (index >= 0) {
          return items.setIn([index, 'largePreviewImageUrl'], action.payload.largePreviewImageUrl);
        } else {
          console.warn(
            'Can not update cart item large preview, item not found',
            action.payload.cartItemId
          );
          return items;
        }
      });
    } else {
      return state;
    }
  },

  [CLOSE_CART_ITEM_PREVIEW]: (state, action) => {
    return state.update('cart', cart => {
      return cart.set('isCartItemPreviewShown', false).delete('previewImageUrl');
    });
  },

  [SET_ORDER_SUMMARY_LOADING]: (state, action) => {
    return state.updateIn(['cart', 'totals', 'isLoading'], isLoading => {
      return action.payload.isLoading;
    });
  },

  [UPDATE_ORDER_SUMMARY_TAX]: (state, action) =>
    state
      .setIn(['cart', 'totals', 'tax'], action.payload.tax)
      .setIn(['cart', 'errors'], fromJS(action.payload.errors)),

  [UPDATE_ORDER_SUMMARY_SUB_TOTAL]: (state, action) =>
    state.setIn(['cart', 'totals', 'items'], action.payload.subTotal),

  [UPDATE_ORDER_SUMMARY_SHIP_TOTAL]: (state, action) =>
    state.setIn(['cart', 'totals', 'shipping'], action.payload.shipTotal),

  [UPDATE_ORDER_SUMMARY_SURCHARGE]: (state, action) =>
    state.setIn(['cart', 'totals', 'surcharge'], action.payload.surcharge),

  [UPDATE_SHIPPING_COUNTRY]: (state, action) => {
    // Once country is changed - reset cart items prices and total
    return state
      .set('shippingCountry', sanitizeCountryCode(action.payload.countryCode))
      .updateIn(['cart', 'items'], items => items.map(item => item.delete('pricing')));
  },

  [FETCH_COUNTRIES.SUCCESS]: (state, action) =>
    state.set(
      'countries',
      removeDuplicatesBy(obj => obj.code, action.payload.countries)
    ),

  [APPEND_TO_IL_HISTORY]: (state, action) => {
    const { SKUWithIL, spaceIndex } = action.payload;
    SKUWithIL.forEach((il, skuKey) => {
      if (!state.get('bulkUpdates').has(skuKey)) {
        state = state.setIn(['bulkUpdates', skuKey], new Map());
      }
      if (!state.hasIn(['bulkUpdates', skuKey, `${spaceIndex}`])) {
        state = state.setIn(
          ['bulkUpdates', skuKey, `${spaceIndex}`],
          new Map().set('past', new List()).set('future', new List())
        );
      }
      state = state
        .updateIn(['bulkUpdates', skuKey, `${spaceIndex}`, 'past'], past => past.push(il))
        .setIn(['bulkUpdates', skuKey, `${spaceIndex}`, 'future'], new List());
    });
    return state;
  },

  [BULK_UPDATE_SPACES_IL]: (state, action) => {
    // for bulk changes, update ILs of affected spaces
    return state.update('selectedSkus', skus => {
      return skus.map(sku => {
        return sku.updateIn(['spaces', action.payload.spaceIndex], space => {
          if (space) {
            const matchedSpace = action.payload.spaces.find(
              s =>
                s.getIn(['space', 'id']) === space.id &&
                s.get('sku').toLowerCase() === sku.get('sku').toLowerCase() &&
                s.get('index') === sku.get('index')
            );
            if (matchedSpace) {
              space = space.set('il', matchedSpace.getIn(['space', 'il']));
            }
            space = space.set('dumpedState', null);
            return space;
          }
        });
      });
    });
  },

  [EDITOR_BULK_UNDO]: (state, action) => {
    /*
    To perform an UNDO operation we need to:
    1. build a list of SKUs that have images for selectedSpaceIndex
    2. iterate over sku names and try to find a past il element in data.bulkUpdates.SKUNAME.SPACE_INDEX.past,
       update current candidate,
       clear future and reduce elements of past by 1
    3. update selectedSkus with current candidate
     */
    const selectedSpaceIndex = action.payload.selectedSpaceIndex;
    let applicableSkus = new List();
    state.get('selectedSkus').forEach((sku, index) => {
      const images = sku.getIn(['spaces', selectedSpaceIndex, 'images']);
      // some skus may have diff amt of spaces
      if (images && images.size > 0) {
        applicableSkus = applicableSkus.push(sku.get('sku'));
      }
    });
    if (applicableSkus.size === 0) return;
    let currentSelectedSkusCandidate = state.get('selectedSkus');
    applicableSkus.forEach((skuName, index) => {
      const pasts = state.getIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'past']);
      if (!pasts || !pasts.last()) {
        return;
      }
      const past = pasts.last();
      currentSelectedSkusCandidate = currentSelectedSkusCandidate.map(currentSku => {
        if (currentSku.get('sku').toLowerCase() !== skuName.toLowerCase()) return currentSku;
        else {
          return currentSku.updateIn(['spaces', selectedSpaceIndex], space =>
            space.set('dumpedState', null).set('il', past)
          );
        }
      });
      state = state
        .updateIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'future'], future => {
          const currentIl = state
            .get('selectedSkus')
            .find(s => s.get('sku') === skuName)
            .getIn(['spaces', selectedSpaceIndex, 'il']);
          return future.insert(0, currentIl);
        })
        .updateIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'past'], past =>
          past.delete(-1)
        );
    });
    state = state.set('selectedSkus', currentSelectedSkusCandidate);
    return state;
  },
  [EDITOR_BULK_REDO]: (state, action) => {
    /*
    To perform an REDU operation we need to:
    1. build a list of SKUs that have images for selectedSpaceIndex
    2. iterate over sku names and try to find a future il element in data.bulkUpdates.SKUNAME.SPACE_INDEX.future,
       update current candidate,
       reduce elements of future by 1
       push to past from a current IL
    3. update selectedSkus with current candidate
     */
    const selectedSpaceIndex = action.payload.selectedSpaceIndex;
    let applicableSkus = new List();
    state.get('selectedSkus').forEach((sku, index) => {
      const images = sku.getIn(['spaces', selectedSpaceIndex, 'images']);
      // some skus may have diff amt of spaces
      if (images && images.size > 0) {
        applicableSkus = applicableSkus.push(sku.get('sku'));
      }
    });
    if (applicableSkus.size === 0) return;
    let currentSelectedSkusCandidate = state.get('selectedSkus');
    applicableSkus.forEach((skuName, index) => {
      const future = state.getIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'future', 0]);
      if (!future) {
        return;
      }
      currentSelectedSkusCandidate = currentSelectedSkusCandidate.map(currentSku => {
        if (currentSku.get('sku').toLowerCase() !== skuName.toLowerCase()) return currentSku;
        else {
          return currentSku.updateIn(['spaces', selectedSpaceIndex], space =>
            space.set('dumpedState', null).set('il', future)
          );
        }
      });
      state = state
        .updateIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'past'], past => {
          const currentIl = state
            .get('selectedSkus')
            .find(s => s.get('sku') === skuName)
            .getIn(['spaces', selectedSpaceIndex, 'il']);
          return past.push(currentIl);
        })
        .updateIn(['bulkUpdates', skuName, `${selectedSpaceIndex}`, 'future'], past =>
          past.delete(0)
        );
    });
    state = state.set('selectedSkus', currentSelectedSkusCandidate);
    return state;
  },
  [SET_DISABLED_SKUS]: (state, action) => {
    return state.set('disabledSkus', action.payload);
  },
  [SET_COF_NECK_LABEL]: (state, action) => {
    return state.set(
      'selectedSkus',
      state.get('selectedSkus').map(sku => {
        const skuNeckLabel = action?.payload?.skuNeckLabels?.find(nl => nl.sku == sku.get('sku'));

        return skuNeckLabel
          ? sku
              .set('neck_tag_id', skuNeckLabel.neckTagId)
              .set('neck_tag_image_url', skuNeckLabel.neckTagImgUrl)
          : sku.delete('neck_tag_id').delete('neck_tag_image_url');
      })
    );
  },
  [SHOPIFY_REDIRECT_REQUIRED_STORES]: (state, action) => {
    return state.set('shopifyRedirectRequiredStores', action.payload.value);
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = defaultState.get('data'), action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
