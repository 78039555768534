import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import PasswordByEmail from './PasswordByEmail';

export default baseCardReducer(
  PasswordByEmail.name,
  (
    state = new Map({
      loading: false,
      isPristine: true,
      busy: false,
      open: true
    })
  ) => state
);
