import { ActionNameFactory } from '../../../../utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'SUMMARY_VIEW');

export const GET_SOME_DATA = getActionName('GET_SOME_DATA');

export function getSomeData() {
  return {
    type: GET_SOME_DATA
  };
}
