import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

export const downloadBlob = (blob, fileName) => {
  if (navigator.msSaveOrOpenBlob) {
    // IE11 or Edge
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const tempDownloadLink = document.createElement('a');
    tempDownloadLink.style.display = 'none';
    document.body.appendChild(tempDownloadLink);

    tempDownloadLink.setAttribute('href', window.URL.createObjectURL(blob));
    tempDownloadLink.setAttribute('download', fileName);
    tempDownloadLink.click();
    document.body.removeChild(tempDownloadLink);
  }
};

export const downloadImages = (images, zipFileName) => {
  const zip = new JSZip();
  let Promise = window.Promise;
  if (!Promise) {
    Promise = JSZip.external.Promise;
  }

  return new Promise((resolve, reject) => {
    images.forEach(image => {
      // eslint-disable-next-line promise/param-names
      const promise = new Promise((res, rej) => {
        JSZipUtils.getBinaryContent(`${image.url}?t=${Date.now()}`, (err, data) => {
          if (err) {
            rej(err);
            reject(err);
          } else {
            res(data);
          }
        });
      });
      zip.file(`${image.sku}.${image.url.indexOf('.png') !== -1 ? 'png' : 'jpg'}`, promise, {
        binary: true
      });
    });

    zip.generateAsync({ type: 'blob' }).then(blob => {
      downloadBlob(blob, zipFileName);
      resolve();
    });
  });
};
