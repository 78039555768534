import { fromJS, List } from 'immutable';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import {
  FETCH_STORES,
  FETCH_USER_DATA,
  VALIDATE_CSV,
  PUBLISH_CSV,
  LOAD_CSV,
  FETCH_HISTORY,
  RESET_VALIDATE_PUBLISH
} from './StoreBulkCreationActions';

export const defaultState = fromJS({
  loading: false,
  providers: [],
  stores: [],
  fetchStoresError: '',
  csvData: []
});

export const mapStoresToState = (state, stores) => {
  if (stores) {
    const items = [];
    const providers = [];
    stores
      .filter(s => s.provider === 'shopify' || s.provider === 'etsy')
      .forEach(s => {
        if (!providers.includes(s.provider)) {
          providers.push(s.provider);
        }
        items.push({
          id: s.id,
          storeName: s.storeName,
          provider: s.provider
        });
      });
    return state
      .set('providers', fromJS(providers))
      .set('stores', fromJS(items))
      .set('fetchStoresError', '');
  }
  return state;
};

export const mapValidateCSVResultToMessage = (state, result) => {
  var data = state.get('csvData').toJS();
  let message = '';
  result.errors.forEach(
    e =>
      (message += `${e.line != null ? `Line ${e.line}:` : ''} ${
        e.line != null && data && data[e.line] && data[e.line]['sku']
          ? e.message.replace(/(?<=SKU ')[^']+/g, data[e.line]['sku'])
          : e.message
      }
       <br />`)
  );
  return message;
};

const mapCsvValidateResultToState = (state, response) => {
  if (response.success === true) {
    return state.set('validateCsvSuccess', 'The provided data is valid').set('loading', false);
  } else {
    const errorMessage = mapValidateCSVResultToMessage(state, response);
    return state.set('validateCsvError', errorMessage).set('loading', false);
  }
};

const StoreBulkCreationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      return defaultState;

    case FETCH_USER_DATA.ASYNC:
      return state.set('fetchEmailErrorMsg', '').set('loading', true);
    case FETCH_USER_DATA.SUCCESS:
      return state.set('emailAddress', action.payload.emailAddress).set('loading', false);
    case FETCH_USER_DATA.FAIL:
      return state
        .set('fetchEmailErrorMsg', action.payload.err)
        .set('emailAddress', '')
        .set('loading', false);

    case FETCH_STORES.ASYNC:
      return state.set('fetchStoresError', '').set('loading', true);
    case FETCH_STORES.SUCCESS:
      return mapStoresToState(state, action.payload.stores).set('loading', false);
    case FETCH_STORES.FAIL:
      return state
        .set('fetchStoresError', action.payload.err)
        .set('providers', List())
        .set('stores', List())
        .set('loading', false);

    case LOAD_CSV.ASYNC: {
      return state
        .set('loading', true)
        .set('csvData', List())
        .set('validateCsvError', '')
        .set('validateCsvSuccess', '')
        .set('publishCsvSuccess', '')
        .set('publishCsvError', '');
    }

    case LOAD_CSV.SUCCESS: {
      return state.set('csvData', fromJS(action.payload)).set('loading', false);
    }

    case LOAD_CSV.FAIL: {
      return state.set('loading', false);
    }

    case VALIDATE_CSV.ASYNC:
      return state
        .set('validateCsvError', '')
        .set('validateCsvSuccess', '')
        .set('publishCsvSuccess', '')
        .set('publishCsvError', '')
        .set('loading', true);
    case VALIDATE_CSV.SUCCESS:
      return mapCsvValidateResultToState(state, action.payload.response);
    case VALIDATE_CSV.FAIL:
      return state
        .set('validateCsvError', action.payload.err)
        .set('validateCsvSuccess', '')
        .set('loading', false);

    case PUBLISH_CSV.ASYNC: {
      return state.set('publishCsvSuccess', '').set('publishCsvError', '').set('loading', true);
    }
    case PUBLISH_CSV.SUCCESS: {
      let response = action.payload.response;
      if (response.success === true) {
        return state
          .set('publishCsvSuccess', 'The data was sucessfully imported')
          .set('loading', false);
      } else {
        return state
          .set(
            'publishCsvError',
            'An error occured while importing the data. Please take a look at History results-csv'
          )
          .set('loading', false);
      }
    }
    case PUBLISH_CSV.FAIL: {
      return state
        .set('publishCsvError', action.payload.errorMsg)
        .set('publishCsvSuccess', '')
        .set('loading', false);
    }

    case FETCH_HISTORY.ASYNC: {
      return state.set('loading', true).set('history', null).set('fetchHistoryError', '');
    }
    case FETCH_HISTORY.SUCCESS: {
      return state.set('history', fromJS(action.payload)).set('loading', false);
    }
    case FETCH_HISTORY.FAIL: {
      return state
        .set('fetchHistoryError', action.payload.errorMsg)
        .set('history', null)
        .set('loading', false);
    }

    case RESET_VALIDATE_PUBLISH: {
      return state
        .set('validateCsvError', '')
        .set('validateCsvSuccess', '')
        .set('publishCsvSuccess', '')
        .set('publishCsvError', '');
    }

    default:
      return state;
  }
};

export default StoreBulkCreationReducer;
