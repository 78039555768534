import React from 'react';
import PropTypes from 'prop-types';
import { Product } from '../../../../../models/Product';
import { cancelable } from '../../../../../utils/promise';
import analyticsService from '../../../../../services/analyticsService';
import CatalogCard from '../../../../shared/CatalogCard';
import { isEmbroideryProduct } from '../../../../ImageUpload/atoms/Embroidery/EmbroiderySelectors';
import Chip from '../../../../shared/Chip';
import ProductCard from '../../../../shared/ProductCard';
import DropDownContainer from '../../../../shared/DropDown/DropDownContainer';
import OptionsModal from '../../../../shared/OptionsModal';
import ListItem from '../../../../shared/ListItem';

class ProductBox extends React.PureComponent {
  static propTypes = {
    product: PropTypes.instanceOf(Product),
    isActive: PropTypes.bool,
    onActivate: PropTypes.func,
    onClickLearnMore: PropTypes.func,
    onClickGetStarted: PropTypes.func
  };

  state = {
    imageLoaded: false,
    showItemMenu: false
  };

  componentDidMount() {
    this.updateImage(this.props);
    this.setState({ label: this.productLabel() });
  }

  // check and create label for product
  productLabel() {
    // check for neck label
    if (this.props.product.necklabel) {
      return { title: 'Custom Neck Label', fill: '#fdc200', color: 'black' };
    } else if (isEmbroideryProduct(this.props.product)) {
      // check is embroidery product and create label
      return { title: 'Embroidery', fill: '#10A3BB', color: 'white' };
    }
  }

  updateImage(props) {
    if (props.product.image) {
      this.imageLoadTask = cancelable(
        new Promise((resolve, reject) => {
          var img = new Image();
          img.onload = () => {
            resolve();
          };
          img.src = props.product.image;
        })
      );

      this.imageLoadTask.promise.then(() => {
        this.setState({ imageLoaded: true });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product.image !== this.props.product.image) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ imageLoaded: false });
      this.updateImage(this.props);
    }
  }

  componentWillUnmount() {
    this.imageLoadTask.cancel();
  }

  render() {
    let btnFocusHolder = null;

    const modalOptions = () => [
      'View Details',
      'View Pricing',
      'Export Pricing List',
      'Create Product'
    ];

    const onClickGetStarted = () => {
      analyticsService.trackWithDynamicSource(
        'Create Product',
        'Gooten product selected',
        this.props.product.name
      );

      analyticsService.trackWithDynamicSource(
        'Create Product',
        'Get started button clicked',
        this.props.product.name
      );

      this.props.onClickGetStarted(this.props.product.id);
    };

    const handleSelectModalOption = option => {
      switch (option) {
        case 'viewdetails':
          onClickLearnMore();
          break;
        case 'viewpricing':
          onClickViewPricing();
          break;
        case 'exportpricinglist':
          onClickExportPricingList();
          break;
        case 'createproduct':
          onClickGetStarted();
          break;
      }
    };

    const onClickLearnMore = () => {
      analyticsService.trackWithDynamicSource(
        'Create Product',
        'Learn more clicked',
        this.props.product.name
      );
      this.props.onClickLearnMore(this.props.product.name);

      if (btnFocusHolder !== null) {
        btnFocusHolder.focus();
      }
    };

    const onClickViewPricing = () => {
      this.props.showPricingModal(this.props.product.name);
    };

    const onClickExportPricingList = () => {
      this.props.exportPricingList(this.props.product.name);
    };

    const touchCapture = e => {
      if (!this.props.isActive) {
        e.preventDefault();
        e.stopPropagation();
        e.preventDefault();
        this.props.onActivate();
      }
    };

    return (
      <div
        ref={button => {
          btnFocusHolder = button;
        }}
        className="product-container position-relative"
        onTouchEndCapture={e => {
          touchCapture(e);
        }}
      >
        <ProductCard
          type="hub"
          title={this.props.product.name}
          fullLengthTitle
          description={
            this.state.label ? <Chip className="blue mt-3">{this.state.label.title}</Chip> : null
          }
          selected={this.props.isActive} // blue background for selected
          loading={!this.state.imageLoaded}
          image={this.props.product.image}
          selectClick={null} // selecting checkbox (not visible when set as null)
          productClick={onClickGetStarted} // click on image
          editItemClick={onClickGetStarted} // click on text description
          additionalInfo={<span>Starting at {this.props.product.cheapest_price}</span>}
          menuClick={() => this.setState({ showItemMenu: !this.state.showItemMenu })}
          // errorInfo={errorInfo}
        />
        {this.state.showItemMenu && (
          <OptionsModal
            items={modalOptions()}
            onClickItem={handleSelectModalOption}
            onDismiss={() => this.setState({ showItemMenu: !this.state.showItemMenu })}
          />
        )}
        <style jsx>
          {`
            .item-menu-container {
              background: white;
              position: absolute;
              z-index: 1000;
              top: 3rem;
              right: 1rem;
              border-radius: 4px;
              margin-bottom: 0.5rem;
              padding: 0.25rem 1rem;
            }
            :global(.options-modal) {
              top: 3.25rem;
              right: 1rem;
            }
          `}
        </style>
      </div>
    );
  }
}

export default ProductBox;
