import { fromJS } from 'immutable';

export default class Config {
  static config = undefined;

  static init(config) {
    if (this.config) {
      throw new Error('Config override is not allowed');
    }
    this.config = fromJS(config);
  }

  static get(key) {
    if (!this.config) {
      throw new Error('Config is not defined');
    }

    return this.config.get(key);
  }

  static toJS() {
    if (!this.config) {
      throw new Error('Config is not defined');
    }

    return this.config.toJS();
  }

  static destroy() {
    this.config = undefined;
  }
}
