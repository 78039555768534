import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../config';

const fetchAcceptableStatusesRequest = bodyData => {
  const url = `${Config.get('adminApi')}OrderDetailsApi/GetAcceptableStatuses`;

  const requestConfig = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${Config.get('adminApiToken')}`,
      'content-type': `application/json`
    },
    queryParams: {
      orderId: bodyData
    }
  };

  return { url, requestConfig };
};

const StatusViewService = () => {
  const execute = async ({ url, requestConfig }) => fetch(url, requestConfig);
  return {
    // fetchOrderData: async (bodyData) => execute(fetchOrderDataRequest(bodyData)),
    fetchAcceptableStatuses: async bodyData => execute(fetchAcceptableStatusesRequest(bodyData))
  };
};

export default StatusViewService();
