import { UPDATE_ACCEPTABLE_STATUSES } from './StatusViewActions';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['orders', 'statusView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_ACCEPTABLE_STATUSES:
      return state.set('acceptableStatuses', action.payload.data);
    default:
      return state;
  }
};
