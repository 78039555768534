import { createSelector } from 'reselect';
import { getPossibleVendors } from '../../utils/vendor';
import { cofShippingCountrySelector } from '../../store/selectors/countriesSelectors';
import Config from '../../config';
import { dataSelector } from '../../store/selectors/productDataSelectors';

const isCOFSelector = state => Config.get('cof');

const allProductsSelector = createSelector([dataSelector], data =>
  data.get('products').filterNot(x => x.out_of_stock)
);

export const hasSelectedProductSelector = createSelector(
  [dataSelector],
  data => !!data.get('selectedProductId')
);

export const countryFilteredProductsSelector = createSelector(
  [isCOFSelector, cofShippingCountrySelector, allProductsSelector],
  (isCOF, shippingCountry, allProducts) => {
    if (isCOF && shippingCountry) {
      return allProducts.filter(
        product => getPossibleVendors(product.vendor_routing, shippingCountry).length > 0
      );
    } else {
      return allProducts;
    }
  }
);

export const productsCountSelector = createSelector(
  [allProductsSelector],
  allProducts => allProducts.size
);

export const sidebarSelector = state => state.get('productSelection').get('sidebar');

/**
 * Used for partner specific products. It looks for `partner_ids` from catalog
 */
export const isProductSupported = product =>
  product &&
  // if "partner_ids" is empty, it means that product is available for all partners...
  (!product.get('partner_ids') ||
    // if "partner_ids" exist, check if our partner id is included...
    product.get('partner_ids').includes(Config.get('partnerId')));
