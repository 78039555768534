import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'DATA');

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCTS_FETCH = getActionName.async('PRODUCTS_FETCH');
export const PRODUCT_DETAILS_FETCH = getActionName.async('PRODUCT_DETAILS_FETCH');
export const PRODUCT_VARIANTS_FETCH = getActionName.async('PRODUCT_SKUS_FETCH');
export const PREVIEWS_PREPARE = getActionName.async('PREVIEWS_PREPARE');
export const EXCLUSIVE_MOCKUPS_PREPARE = getActionName.async('EXCLUSIVE_MOCKUPS_PREPARE');
export const PUBLISH_PREPARE = getActionName.async('PUBLISH_PREPARE');
export const PUBLISH = getActionName.async('PUBLISH');
export const TEMPLATES_FETCH = getActionName.async('TEMPLATES_FETCH');
export const GET_NECK_TAG_PREVIEWS = getActionName.async('GET_NECK_TAG_PREVIEWS');
export const SELECT_PRODUCTS = getActionName('SELECT_PRODUCTS');
export const SKU_SELECT = getActionName('SKU_SELECT');
export const IMAGE_ADD = getActionName('IMAGE_ADD');
export const VARIANT_DELETE = getActionName('VARIANT_DELETE');
export const DUMP_EDITOR_STATE = getActionName('DUMP_EDITOR_STATE');
export const UPDATE_SPACE_PREVIEW = getActionName('UPDATE_SPACE_PREVIEW');
export const ADD_TO_CART = getActionName('ADD_TO_CART');
export const RESET_SELECTED_PRODUCT = getActionName('RESET_SELECTED_PRODUCT');
export const EDIT_CART_ITEM = getActionName('EDIT_CART_ITEM');
export const UPDATE_CART_ITEM = getActionName('UPDATE_CART_ITEM');
export const UPDATE_CART_ITEM_PREVIEW = getActionName('UPDATE_CART_ITEM_PREVIEW');
export const REMOVE_CART_ITEM = getActionName('REMOVE_CART_ITEM');
export const TOGGLE_ITEM_AS_SAMPLE = getActionName('TOGGLE_ITEM_AS_SAMPLE');
export const CLEAR_CART = getActionName('CLEAR_CART');
export const UPDATE_CART_ITEM_QUANTITY = getActionName('UPDATE_CART_ITEM_QUANTITY');
export const UPDATE_CART_ITEM_PRICING = getActionName('UPDATE_CART_ITEM_PRICING');
export const SHOW_CART_ITEM_PREVIEW = getActionName.async('SHOW_CART_ITEM_PREVIEW');
export const DOWNLOAD_CART_ITEM_PREVIEW = getActionName.async('DOWNLOAD_CART_ITEM_PREVIEW');
export const CLOSE_CART_ITEM_PREVIEW = getActionName('CLOSE_CART_ITEM_PREVIEW');
export const SET_ORDER_SUMMARY_LOADING = getActionName('SET_ORDER_SUMMARY_LOADING');
export const UPDATE_ORDER_SUMMARY_TAX = getActionName('UPDATE_ORDER_SUMMARY_TAX');
export const UPDATE_ORDER_SUMMARY_SUB_TOTAL = getActionName('UPDATE_ORDER_SUMMARY_SUB_TOTAL');
export const UPDATE_ORDER_SUMMARY_SHIP_TOTAL = getActionName('UPDATE_ORDER_SUMMARY_SHIP_TOTAL');
export const UPDATE_ORDER_SUMMARY_SURCHARGE = getActionName('UPDATE_ORDER_SUMMARY_SURCHARGE');
export const SET_CART_ITEM_EDIT_MODE = getActionName('SET_CART_ITEM_EDIT_MODE');
export const RESTORE_CART = getActionName('RESTORE_CART');
export const UPDATE_SHIPPING_COUNTRY = getActionName('UPDATE_SHIPPING_COUNTRY');
export const FETCH_COUNTRIES = getActionName.async('FETCH_COUNTRIES');
export const CLEAN_SELECTION = getActionName('CLEAN_SELECTION');
export const APPEND_TO_IL_HISTORY = getActionName('APPEND_TO_IL_HISTORY');
export const BULK_UPDATE_SPACES_IL = getActionName('BULK_UPDATE_SPACES_IL');
export const UPDATE_PRODUCTION_TIME_DATA = getActionName('UPDATE_PRODUCTION_TIME_DATA');
export const UPDATE_SPACES_PREVIEWS_BY_SPACE_INDEX = getActionName(
  'UPDATE_SPACES_PREVIEWS_BY_SPACE_INDEX'
);
export const EDITOR_BULK_UNDO = getActionName('EDITOR_BULK_UNDO');
export const EDITOR_BULK_REDO = getActionName('EDITOR_BULK_REDO');
export const SET_DISABLED_SKUS = getActionName('SET_DISABLED_SKUS');
export const SET_COF_NECK_LABEL = getActionName('SET_COF_NECK_LABEL');
export const NECK_LABEL_SELECTION_CHANGED = getActionName('NECK_LABEL_SELECTION_CHANGED');
export const LOADED_ITEM_PRICE = getActionName('LOADED_ITEM_PRICE');
export const SHOPIFY_REDIRECT_REQUIRED_STORES = getActionName('SHOPIFY_REDIRECT_REQUIRED_STORES');

// ------------------------------------
// Actions
// ------------------------------------
export function fetchAsync(action, payload, meta = {}) {
  return {
    type: action.ASYNC,
    payload,
    meta
  };
}

export function fetchAsyncSuccess(action, payload) {
  return {
    type: action.SUCCESS,
    payload
  };
}

export function fetchAsyncFail(action, err) {
  let error;
  try {
    error = JSON.parse(JSON.stringify(err, ['message', 'arguments', 'type', 'name', 'stack']));
  } catch (e) {}

  return {
    type: action.FAIL,
    payload: err,
    error
  };
}

export function fetchAsyncCancel(action, payload) {
  return {
    type: action.CANCEL,
    payload
  };
}

export function selectProducts(payload) {
  return {
    type: SELECT_PRODUCTS,
    payload
  };
}

export function skuSelect(skus) {
  return {
    type: SKU_SELECT,
    payload: { skus }
  };
}

export function templatesInit() {
  return {
    type: TEMPLATES_FETCH.ASYNC,
    payload: {}
  };
}

export function imageAdd(images) {
  return {
    type: IMAGE_ADD,
    payload: { images }
  };
}

export function variantDelete(variantIndex) {
  return {
    type: VARIANT_DELETE,
    payload: { variantIndex }
  };
}

export function dumpEditorState(variantIndex, spaceId, editorState, il) {
  return {
    type: DUMP_EDITOR_STATE,
    payload: { variantIndex, spaceId, editorState, il }
  };
}

export function updateSpacePreview(variantIndex, spaceId, base64) {
  return {
    type: UPDATE_SPACE_PREVIEW,
    payload: { variantIndex, spaceId, base64 }
  };
}

export function addToCart(items) {
  return {
    type: ADD_TO_CART,
    payload: { items }
  };
}

export function resetSelectedProduct() {
  return {
    type: RESET_SELECTED_PRODUCT
  };
}

export function editCartItem(item, index) {
  return {
    type: EDIT_CART_ITEM,
    payload: { item, index }
  };
}

export function updateCartItem(item, index) {
  return {
    type: UPDATE_CART_ITEM,
    payload: { item, index }
  };
}

export function updateCartItemPreview(previewImageUrl, cartItemId) {
  return {
    type: UPDATE_CART_ITEM_PREVIEW,
    payload: { previewImageUrl, cartItemId }
  };
}

export function removeCartItem(index) {
  return {
    type: REMOVE_CART_ITEM,
    payload: { index }
  };
}

export function toggleItemAsSample(index) {
  return {
    type: TOGGLE_ITEM_AS_SAMPLE,
    payload: { index }
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART
  };
}

export function updateCartItemQuantity(item, newQuantity) {
  return {
    type: UPDATE_CART_ITEM_QUANTITY,
    payload: { item, newQuantity }
  };
}

export function updateCartItemPricing(pricing) {
  return {
    type: UPDATE_CART_ITEM_PRICING,
    payload: { pricing }
  };
}

export function showCartItemPreviewAsync(item) {
  return {
    type: SHOW_CART_ITEM_PREVIEW.ASYNC,
    payload: { item }
  };
}

export function showCartItemPreviewSuccess(largePreviewImageUrl, cartItemId = null) {
  // cartItemId is optional since it's required if cart item needs to be updated
  return {
    type: SHOW_CART_ITEM_PREVIEW.SUCCESS,
    payload: { largePreviewImageUrl, cartItemId }
  };
}

export function downloadCartItemPreviewAsync(item) {
  return {
    type: DOWNLOAD_CART_ITEM_PREVIEW.ASYNC,
    payload: { item }
  };
}

export function downloadCartItemPreviewSuccess(largePreviewImageUrl, cartItemId) {
  // cartItemId is optional since it's required if cart item needs to be updated
  return {
    type: DOWNLOAD_CART_ITEM_PREVIEW.SUCCESS,
    payload: { largePreviewImageUrl, cartItemId }
  };
}

export function closeCartItemPreview() {
  return {
    type: CLOSE_CART_ITEM_PREVIEW
  };
}

export function setOrderSummaryLoading(isLoading) {
  return {
    type: SET_ORDER_SUMMARY_LOADING,
    payload: { isLoading }
  };
}

export function updateOrderSummaryTax(tax, errors) {
  return {
    type: UPDATE_ORDER_SUMMARY_TAX,
    payload: { tax, errors }
  };
}

export function updateOrderSummarySubTotal(subTotal) {
  return {
    type: UPDATE_ORDER_SUMMARY_SUB_TOTAL,
    payload: { subTotal }
  };
}

export function updateOrderSummaryShipTotal(shipTotal) {
  return {
    type: UPDATE_ORDER_SUMMARY_SHIP_TOTAL,
    payload: { shipTotal }
  };
}

export function updateOrderSummarySurcharge(surcharge) {
  return {
    type: UPDATE_ORDER_SUMMARY_SURCHARGE,
    payload: { surcharge }
  };
}

export function setCartItemEditMode(isCartItemEditMode) {
  return {
    type: SET_CART_ITEM_EDIT_MODE,
    payload: { isCartItemEditMode }
  };
}

export function restoreCart(items, shippingCountry) {
  return {
    type: RESTORE_CART,
    payload: { items, shippingCountry }
  };
}

export function updateShippingCountry(countryCode) {
  return {
    type: UPDATE_SHIPPING_COUNTRY,
    payload: { countryCode }
  };
}

export function cleanSelection() {
  return {
    type: CLEAN_SELECTION
  };
}

export function appendILHistory(SKUWithIL, spaceIndex) {
  return {
    type: APPEND_TO_IL_HISTORY,
    payload: { SKUWithIL, spaceIndex }
  };
}

export function bulkUpdateSpacesILs(spaces, spaceIndex) {
  return {
    type: BULK_UPDATE_SPACES_IL,
    payload: { spaces, spaceIndex }
  };
}

export function updateProductionTimeData(productId, productionTimeData) {
  return {
    type: UPDATE_PRODUCTION_TIME_DATA,
    payload: { productId, productionTimeData }
  };
}

export function updateSpacePreviewsBySpaceIndex(selectedSpaceIndex) {
  return {
    type: UPDATE_SPACES_PREVIEWS_BY_SPACE_INDEX,
    payload: { selectedSpaceIndex }
  };
}

export function editorBulkUndo(selectedSpaceIndex, selectedVariant) {
  return {
    type: EDITOR_BULK_UNDO,
    payload: { selectedSpaceIndex, selectedVariant }
  };
}

export function editorBulkRedo(selectedSpaceIndex, selectedVariant) {
  return {
    type: EDITOR_BULK_REDO,
    payload: { selectedSpaceIndex, selectedVariant }
  };
}

export const setDisabledSkus = disabledSkus => ({
  type: SET_DISABLED_SKUS,
  payload: disabledSkus
});

export function getNeckTagPreviews() {
  return {
    type: GET_NECK_TAG_PREVIEWS
  };
}

export function setCofNeckLabel(neckLabel) {
  return {
    type: SET_COF_NECK_LABEL,
    payload: neckLabel
  };
}

export const neckLabelSelectionChanged = neckTag => ({
  type: NECK_LABEL_SELECTION_CHANGED,
  payload: neckTag
});

export const loadedItemPrice = value => ({
  type: LOADED_ITEM_PRICE,
  payload: { value }
});

export const setShopifyRedirectRequiredStores = value => ({
  type: SHOPIFY_REDIRECT_REQUIRED_STORES,
  payload: { value }
});
