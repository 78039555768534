import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { hashHistory } from 'react-router';
import OrdersDetailsService from 'gooten-components/src/services/orderDetailsService';
import {
  GET_SHARED_ORDER_DATA,
  UPDATE_ORDER_META,
  updateOrderMetaFailure,
  updateOrderMetaSuccess,
  UPDATE_ORDER_ITEMS_META,
  updateOrderItemsMetaFailure,
  updateOrderItemsMetaSuccess,
  updateSummaryData,
  updateBillingInfo,
  UPDATE_ITEMS_STATUS,
  updateItemsStatusSuccess,
  updateItemsStatusFailure,
  SEND_SHIPPING_CONFIRMATION,
  SEND_ORDER_CONFIRMATION,
  sendShippingConfirmationSuccess,
  sendShippingConfirmationFailure,
  sendOrderConfirmationSuccess,
  sendOrderConfirmationFailure,
  updateItemsData,
  SUBMIT_MANIP_COMMAND,
  submitManipCommandSuccess,
  submitManipCommandFailure,
  CHANGE_TRACKING_NUMBER,
  changeTrackingNumberSuccess,
  REVERT_IMAGE,
  revertImageSuccess,
  revertImageFailure,
  submitImageFileSuccess,
  submitImageFileFailure,
  SUBMIT_IMAGE_FILE,
  submitImageUrlSuccess,
  submitImageUrlFailure,
  SUBMIT_IMAGE_URL,
  getOrderFailedInfoSuccess,
  getOrderFailedInfoFailure,
  GET_ORDER_FAILED_INFO,
  REATTEMPT_ORDER,
  reattemptOrderSuccess,
  reattemptOrderFailure,
  SAVE_SHIPPING_ADDRESS,
  SAVE_BILLING_ADDRESS,
  IGNORE_SUGGESTED_ADDRESS,
  APPLY_SUGGESTED_ADDRESS,
  CHANGE_SKU,
  updateIgnoredSuggestedAddress,
  updateAppliedShippingAddress,
  updateShippingAddress,
  updateBillingAddress,
  getSharedOrderData,
  getSharedOrderDataError,
  updateStateData,
  UPDATE_SHIPPING_METHOD,
  updateShippingMethodSuccess,
  SPLIT_ORDER_ITEM,
  splitOrderItemError,
  updateShippingAddressFailed,
  getOrderLogsSuccess,
  getOrderLogsFailure,
  GET_ORDER_LOGS
} from './OrderDetailsActions';
import {
  updateEditingShippingAddress,
  updateEditingBillingAddress,
  disableShippingAddressEdit
} from './SummaryView/SummaryViewActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import { allStatuses } from './StatusView/allStatuses';
import { ableToEditShipping, itemsData, summaryData } from './OrderDetailsSelectors';
import { ordersList } from '../OrdersView/OrdersViewSelectors';
import { handleSearchRequest } from '../OrdersView/OrdersViewSaga';
import { delay } from 'redux-saga';
import { string } from 'prop-types';

export function* handleSharedOrderDataRequest(action) {
  try {
    let stateData = yield call([OrdersDetailsService, OrdersDetailsService.fetchGetState]);
    yield put(updateStateData(fromJS(stateData)));

    let ordersData = yield call(
      [OrdersDetailsService, OrdersDetailsService.getOrderSummaryData],
      action.payload.orderId
    );
    yield put(updateSummaryData(fromJS(ordersData)));

    let billingData = yield call(
      [OrdersDetailsService, OrdersDetailsService.getBillingData],
      ordersData.PartnerInternalId
    );
    yield put(updateBillingInfo(fromJS(billingData)));

    let itemsData = yield call(
      [OrdersDetailsService, OrdersDetailsService.getItemsData],
      action.payload.orderId
    );

    // check for order statuses and perform adequate actions...
    yield put(
      disableShippingAddressEdit(!ableToEditShipping(ordersData?.OrderStatuses?.map(o => o.Id)))
    );

    // the code below is used to convert the api response into a more usable format.  If we update the api
    // in the future we can remove this function
    const updatedItems = itemsData.reduce((combined, vender) => {
      const newVender = vender.Items.map(item => {
        return { ...item, Name: vender.Name };
      });
      return [...combined, ...newVender];
    }, []);

    // we can remove the saving of 'itemsData' after we finish the migration to react of the s
    // duplicate/refund/reprint pages. We are only saving the original format as it is needed to pass to angular
    yield put(updateItemsData(fromJS(itemsData), fromJS(updatedItems)));
  } catch (err) {
    yield put(getSharedOrderDataError(err.Message));
  }
}

export function* updateOrderMetaAsync(action) {
  try {
    const updateMeta = yield call(
      [OrdersDetailsService, OrdersDetailsService.updateOrderMeta],
      action.payload.orderId,
      action.payload.meta
    );
    yield put(updateOrderMetaSuccess(updateMeta, action.payload));
    yield put(push(`Order notes updated successfully`));
  } catch (err) {
    yield put(updateOrderMetaFailure(UPDATE_ORDER_META, err.msg));
    yield put(push('Error Updating Items'));
  }
}

export function* updateOrderItemsMetaAsync(action) {
  try {
    const updateMeta = yield call(
      [OrdersDetailsService, OrdersDetailsService.updateOrderItemsMeta],
      action.payload.orderId,
      action.payload.itemIds,
      action.payload.meta
    );
    yield put(updateOrderItemsMetaSuccess(updateMeta, action.payload));
    yield put(push(`Order items notes updated successfully`));
  } catch (err) {
    yield put(updateOrderItemsMetaFailure(UPDATE_ORDER_ITEMS_META, err.msg));
    yield put(push('Error Updating Items'));
  }
}

// SUMMARY PAGE ACTIONS
export function* handleSaveShippingAddressRequest(action) {
  try {
    let response = yield call(
      [OrdersDetailsService, OrdersDetailsService.saveShippingAddress],
      action.payload
    );
    if (response.MediationId) {
      yield put(push('Shipping address updated'));
      yield put(updateShippingAddress(fromJS(response)));
      yield put(updateEditingShippingAddress(false));
    } else {
      yield put(push('Shipping address update failed'));
      yield put(updateShippingAddressFailed());
    }
  } catch (err) {
    yield put(push(typeof err === `string` ? err : 'Shipping address update failed'));
    yield put(updateShippingAddressFailed());
  }
}

export function* handleSaveBillingAddressRequest(action) {
  try {
    yield call([OrdersDetailsService, OrdersDetailsService.saveBillingAddress], action.payload);
    yield put(push('Billing address updated'));
    yield put(updateBillingAddress(fromJS(action.payload)));
    yield put(updateEditingBillingAddress(false));
  } catch (err) {
    yield put(push('Billing address update failed'));
  }
}

export function* handleIgnoreSuggestedAddressRequest(action) {
  try {
    yield call([OrdersDetailsService, OrdersDetailsService.ignoreSuggestedAddress], action.payload);
    yield put(push('Suggested address ignored'));
    yield put(updateIgnoredSuggestedAddress(fromJS(action.payload)));
    yield call(delay, 2000);
    yield call(reloadLocation);
  } catch (err) {
    yield put(push('Suggested address ignore failed; ' + err));
    yield call(delay, 2000);
    yield call(reloadLocation);
  }
}

export function* handleApplySuggestedAddressRequest(action) {
  try {
    let response = yield call(
      [OrdersDetailsService, OrdersDetailsService.applySuggestedAddress],
      action.payload
    );
    yield put(push('Suggested address applied'));
    yield put(updateAppliedShippingAddress(fromJS(response)));
    yield call(delay, 2000);
    yield call(reloadLocation);
  } catch (err) {
    yield put(push('Suggested address update failed; ' + err));
    yield call(delay, 2000);
    yield call(reloadLocation);
  }
}

function* reloadLocation() {
  const currentPath = hashHistory.getCurrentLocation().pathname;
  hashHistory.replace('/orders-new');
  yield call(delay, 100);
  hashHistory.replace(currentPath);
}

export function* handleChangeSkuRequest(action) {
  try {
    let response = yield call(
      [OrdersDetailsService, OrdersDetailsService.changeSku],
      action.payload
    );
    if (response.HadError) {
      yield put(push(response.Errors[0]?.ErrorMessage || 'Failed to update Order Item!'));
    } else {
      yield put(push('Order Item updated!'));
    }
    setTimeout(function () {
      location.reload();
    }, 2000);
  } catch (err) {
    yield put(push('Failed to update Order Item!'));
  }
}

export function* handleUpdateShippingMethod(action) {
  try {
    let response = yield call(
      [OrdersDetailsService, OrdersDetailsService.updateShippingMethod],
      action.payload
    );
    if (response.Success) {
      yield put(push('Updated shipping method'));
      yield put(updateShippingMethodSuccess(action.payload));
    } else {
      yield put(push('Shipping method update failed: ' + response.Errors));
    }
  } catch (err) {
    yield put(push('Shipping method update failed'));
  }
}

// EMAIL CONFIRMATION ACTIONS

export function* sendShippingConfirmationAsync(action) {
  try {
    yield put(push('Shipping confirmation sent'));
    const shippingConfirm = yield call(
      [OrdersDetailsService, OrdersDetailsService.sendShippingConfirmation],
      action.payload
    );
    yield put(sendShippingConfirmationSuccess(shippingConfirm));
    // yield (put(push('Email confirmation sent')))
  } catch (err) {
    // yield (put(push('Error Sending Email')))
    yield put(sendShippingConfirmationFailure(SEND_SHIPPING_CONFIRMATION, err.msg));
  }
}

export function* sendOrderConfirmationAsync(action) {
  try {
    yield put(push('Order confirmation sent'));
    const orderConfirm = yield call(
      [OrdersDetailsService, OrdersDetailsService.sendOrderConfirmation],
      action.payload
    );
    yield put(sendOrderConfirmationSuccess(orderConfirm));
    // yield (put(push('Email confirmation sent')))
  } catch (err) {
    // yield (put(push('Error Sending Email')))
    yield put(sendOrderConfirmationFailure(SEND_ORDER_CONFIRMATION, err.msg));
  }
}

export function* watchGetLatestSharedData() {
  yield takeLatest(GET_SHARED_ORDER_DATA, handleSharedOrderDataRequest);
}
export function* watchMetaUpdate() {
  yield takeLatest(UPDATE_ORDER_META.ASYNC, updateOrderMetaAsync);
}
export function* watchMetaUpdateSuccess() {
  yield takeLatest(UPDATE_ORDER_META.SUCCESS, updateOrderMetaSuccess);
}
export function* watchItemsMetaUpdate() {
  yield takeLatest(UPDATE_ORDER_ITEMS_META.ASYNC, updateOrderItemsMetaAsync);
}
export function* watchItemsMetaUpdateSuccess() {
  yield takeLatest(UPDATE_ORDER_ITEMS_META.SUCCESS, updateOrderItemsMetaSuccess);
}

// ITEMS STATUS UPDATE IN STATUS TAB

export function* updateItemsStatusAsync(action) {
  // Note: handle partner manually changes from NeedsCusomization to SendToProduction (FakeReadyToPrint)
  function* handleCustomization(showSuccessToast) {
    const summary = yield select(summaryData);
    const orderId = summary.OrderId;
    try {
      yield call([OrdersDetailsService, OrdersDetailsService.hadCustomization], {
        ids: [`${orderId}`],
        comments: action.payload.comments
      });
    } catch (err) {
      if (err.Message) {
        yield put(push(`Fail: ${err.Message}`));
      } else {
        // The success action is in error catch because API returns nothing when successful
        if (showSuccessToast) yield put(push('Updated status to ready to print'));
        yield put(updateItemsStatusSuccess(null, action.payload));
      }
    }
  }

  if (action.payload.statusId === 'FakeReadyToPrint') {
    yield call(handleCustomization, true);
  } else {
    // The normal status update method
    try {
      // NOTE: Only check if we need to use hadCustomization endpoint if
      // order is set to cancelled (34) or hold (31). Check before status change
      let sendToHadCustomization = false;
      if ([31, 34].includes(action.payload.statusId)) {
        const allItems = yield select(itemsData);

        if (allItems.filter(x => x.StatusId === 35).length === action.payload.ids.length) {
          sendToHadCustomization = true;
        }
      }
      // ***

      let method = OrdersDetailsService.updateItemsStatus;
      const changedItems = yield call(method, action.payload);
      const currentStatus = allStatuses.find(x => x.Id === action.payload.statusId);

      if (sendToHadCustomization) {
        yield call(handleCustomization, false);
      }

      if (changedItems.FailedItems.length) {
        yield put(
          push(
            `Fail: Status cannot be changed to '${
              currentStatus.Name
            }' for item(s) ${changedItems.FailedItems.join(', ')}`
          )
        );
        // perform redux update only on successful item changes
        const changedItemsWithoutFailedItems = action.payload.ids.filter(
          it => !changedItems.FailedItems.includes(it)
        );
        action.payload.ids = changedItemsWithoutFailedItems;
        yield put(updateItemsStatusSuccess(changedItemsWithoutFailedItems, action.payload));
      } else {
        yield put(updateItemsStatusSuccess(changedItems, action.payload));

        if (action.payload.ids.length && currentStatus) {
          yield put(
            push(
              `Status changed to '${currentStatus.Name}' for item(s) ${action.payload.ids.join(
                ', '
              )}`
            )
          );
        }
      }
    } catch (err) {
      yield put(updateItemsStatusFailure(UPDATE_ITEMS_STATUS, err.msg));
      const errorMsg = err.Message ? `Fail: ${err.Message}` : 'Error updating items';
      yield put(push(errorMsg));
    }
  }

  const allOrderItems = yield select(itemsData);
  // check for order statuses and disable/enable the edit button for shipping address
  yield put(disableShippingAddressEdit(!ableToEditShipping(allOrderItems?.map(o => o.statusId))));
}

export function* changeTrackingAsync(action) {
  try {
    let method = OrdersDetailsService.changeTrackingNumber;
    const x = yield call(method, action.payload.data);
    yield put(changeTrackingNumberSuccess(x, action.payload.data));
    if (action.payload.data.ids.length) {
      yield put(
        push(`Changed tracking number to
      '${action.payload.data.trackingNumber}' for item(s) ${action.payload.data.ids.join(', ')}`)
      );
    }
  } catch (err) {
    yield put(push('Error Updating Items'));
  }
}

// IMAGES VIEW ACTIONS

export function* submitManipCommandAsync(action) {
  try {
    const manipCommands = yield call(
      [OrdersDetailsService, OrdersDetailsService.postManipCommand],
      action.payload.imageId,
      action.payload.manip
    );
    yield put(submitManipCommandSuccess(manipCommands, action.payload));
    yield put(push(`Manip commands submitted succesfully`));
  } catch (err) {
    yield put(submitManipCommandFailure(SUBMIT_MANIP_COMMAND, err.ExceptionMessage));
    yield put(push('Error updating item: ' + err.ExceptionMessage));
  }
}

export function* submitRevertCommand(action) {
  try {
    const revert = yield call(
      [OrdersDetailsService, OrdersDetailsService.submitRevert],
      action.payload.imageId
    );
    yield put(revertImageSuccess(revert, action.payload));
    yield put(push(`Image reverted succesfully`));
  } catch (err) {
    console.log(err);
    yield put(revertImageFailure(REVERT_IMAGE, err));
    yield put(push(`Error reverting image`));
  }
}

export function* submitImageFileAsync(action) {
  try {
    const newImage = yield call(
      [OrdersDetailsService, OrdersDetailsService.postOrderImageFileUpdate],
      action.payload.imageId,
      action.payload.file
    );
    yield put(submitImageFileSuccess(newImage, action.payload));
    yield put(push(`New Image uploaded succesfully`));
  } catch (err) {
    console.log(err);
    yield put(submitImageFileFailure(SUBMIT_IMAGE_FILE, err));
    yield put(push(`Error uploading image`));
  }
}

export function* submitImageUrlAsync(action) {
  try {
    const newImage = yield call(
      [OrdersDetailsService, OrdersDetailsService.postOrderImageUrlUpdate],
      action.payload.imageId,
      action.payload.url
    );
    yield put(submitImageUrlSuccess(newImage, action.payload));
    yield put(push(`New Image uploaded succesfully`));
  } catch (err) {
    console.log(err);
    yield put(submitImageUrlFailure(SUBMIT_IMAGE_URL, err));
    yield put(push(`Error uploading image`));
  }
}

export function* submitSplitOrderItemRequest(action) {
  try {
    yield call(
      [OrdersDetailsService, OrdersDetailsService.submitSplitOrderItem],
      action.payload.orderId,
      action.payload.orderItemId
    );

    yield put(getSharedOrderData(action.payload.orderId));
    yield put(push(`Order split successfully`));

    // NOTE: This is checking if the user is changing status on Orders (table)
    // page or OrderSummary (Image tab). Refresh search when order is split
    const ordersListSearch = yield select(ordersList);
    const isOnOrdersTablePage = ordersListSearch.get('total');

    if (isOnOrdersTablePage) {
      yield call(delay, 1000);
      yield call(handleSearchRequest);
    }
  } catch (err) {
    yield put(splitOrderItemError(err));
    yield put(push(`Error splitting order${err.Message && ': ' + err.Message}`));
  }
}

// VENDOR VIEW ACTIONS

export function* getOrderFailedInfoAsync(action) {
  try {
    const failedInfo = yield call(
      [OrdersDetailsService, OrdersDetailsService.getOrderFailedInfo],
      action.payload
    );

    const info = JSON.parse(failedInfo.data);
    var id = parseInt(action.payload);
    var orderInfo = info.Orders.find(x => x.OrderId === id) || {};

    if (orderInfo.AttemptId) {
      const trace = yield call(
        [OrdersDetailsService, OrdersDetailsService.getTraceAttempt],
        orderInfo.AttemptId
      );
      const traceData = JSON.parse(trace.data);
      orderInfo.Trace = traceData.Trace;
    }

    yield put(getOrderFailedInfoSuccess(orderInfo, action.payload));
  } catch (err) {
    console.log('error getting data');
    yield put(getOrderFailedInfoFailure(GET_ORDER_FAILED_INFO, err));
  }
}

export function* getOrderLogs(action) {
  try {
    const orderLogs = yield call(
      [OrdersDetailsService, OrdersDetailsService.getOrderLogs],
      action.payload
    );

    yield put(getOrderLogsSuccess(orderLogs.logs, action.payload));
  } catch (err) {
    console.log('error getting data');
    yield put(getOrderLogsFailure(GET_ORDER_LOGS, err));
  }
}

export function* reAttemptOrderAsync(action) {
  try {
    yield put(push(`Successfully resubmitted order`));
    yield put(reattemptOrderSuccess(action.payload));
    yield call([OrdersDetailsService, OrdersDetailsService.reAttemptOrder], action.payload);
  } catch (err) {
    yield put(push(`Error resubmitting order`));
    yield put(reattemptOrderFailure(err));
  }
}

export function* watchReattemptOrderAsync() {
  yield takeLatest(REATTEMPT_ORDER.ASYNC, reAttemptOrderAsync);
}
export function* watchReattemptOrderAsyncSuccess() {
  yield takeLatest(REATTEMPT_ORDER.SUCCESS, watchReattemptOrderAsyncSuccess);
}
export function* watchReattemptOrderAsyncFailure() {
  yield takeLatest(REATTEMPT_ORDER.FAIL, watchReattemptOrderAsyncFailure);
}

export function* watchGetOrderFailedInfoCommand() {
  yield takeLatest(GET_ORDER_FAILED_INFO.ASYNC, getOrderFailedInfoAsync);
}
export function* watchGetOrderFailedInfoCommandSuccess() {
  yield takeLatest(GET_ORDER_FAILED_INFO.SUCCESS, getOrderFailedInfoSuccess);
}

export function* watchGetOrderLogsCommand() {
  yield takeLatest(GET_ORDER_LOGS.ASYNC, getOrderLogs);
}

export function* watchHandleSaveShippingAddressRequest() {
  yield takeLatest(SAVE_SHIPPING_ADDRESS, handleSaveShippingAddressRequest);
}
export function* watchHandleSaveBillingAddressRequest() {
  yield takeLatest(SAVE_BILLING_ADDRESS, handleSaveBillingAddressRequest);
}
export function* watchHandleIgnoreSuggestedAddressRequest() {
  yield takeLatest(IGNORE_SUGGESTED_ADDRESS, handleIgnoreSuggestedAddressRequest);
}
export function* watchHandleApplySuggestedAddressRequest() {
  yield takeLatest(APPLY_SUGGESTED_ADDRESS, handleApplySuggestedAddressRequest);
}
export function* watchChangeSkuRequest() {
  yield takeLatest(CHANGE_SKU, handleChangeSkuRequest);
}
export function* watchUpdateShippingMethodRequest() {
  yield takeLatest(UPDATE_SHIPPING_METHOD.ASYNC, handleUpdateShippingMethod);
}
export function* watchUpdateShippingMethodSuccess() {
  yield takeLatest(UPDATE_SHIPPING_METHOD.SUCCESS, updateShippingMethodSuccess);
}

export function* watchSubmitManipCommand() {
  yield takeLatest(SUBMIT_MANIP_COMMAND.ASYNC, submitManipCommandAsync);
}
export function* watchUpdateItemsUpdate() {
  yield takeLatest(UPDATE_ITEMS_STATUS.ASYNC, updateItemsStatusAsync);
}
export function* watchUpdateItemsUpdateSuccess() {
  yield takeLatest(UPDATE_ITEMS_STATUS.SUCCESS, updateItemsStatusSuccess);
}
export function* watchSubmitRevertCommand() {
  yield takeLatest(REVERT_IMAGE.ASYNC, submitRevertCommand);
}
export function* watchSubmitImageFile() {
  yield takeLatest(SUBMIT_IMAGE_FILE.ASYNC, submitImageFileAsync);
}
export function* watchSubmitImageUrl() {
  yield takeLatest(SUBMIT_IMAGE_URL.ASYNC, submitImageUrlAsync);
}
export function* watchSubmitSplitOrderItemRequest() {
  yield takeLatest(SPLIT_ORDER_ITEM, submitSplitOrderItemRequest);
}

export function* watchSendShippingConfirmation() {
  yield takeLatest(SEND_SHIPPING_CONFIRMATION.ASYNC, sendShippingConfirmationAsync);
}
export function* watchSendShippingConfirmationSuccess() {
  yield takeLatest(SEND_SHIPPING_CONFIRMATION.SUCCESS, sendShippingConfirmationSuccess);
}

export function* watchSendOrderConfirmation() {
  yield takeLatest(SEND_ORDER_CONFIRMATION.ASYNC, sendOrderConfirmationAsync);
}
export function* watchSendOrderConfirmationSuccess() {
  yield takeLatest(SEND_ORDER_CONFIRMATION.SUCCESS, sendOrderConfirmationSuccess);
}

export function* watchChangeTracking() {
  yield takeLatest(CHANGE_TRACKING_NUMBER.ASYNC, changeTrackingAsync);
}

export default function* rootSaga() {
  yield all([
    watchGetLatestSharedData(),
    watchMetaUpdate(),
    watchMetaUpdateSuccess(),
    watchItemsMetaUpdate(),
    watchItemsMetaUpdateSuccess(),
    watchUpdateItemsUpdate(),
    watchHandleSaveShippingAddressRequest(),
    watchHandleSaveBillingAddressRequest(),
    watchHandleIgnoreSuggestedAddressRequest(),
    watchHandleApplySuggestedAddressRequest(),
    watchChangeSkuRequest(),
    watchUpdateShippingMethodRequest(),
    watchUpdateItemsUpdateSuccess(),
    watchUpdateItemsUpdateSuccess(),
    watchSendShippingConfirmation(),
    watchSendShippingConfirmationSuccess(),
    watchSendOrderConfirmation(),
    watchSendOrderConfirmationSuccess(),
    watchSubmitManipCommand(),
    watchSubmitRevertCommand(),
    watchSubmitImageFile(),
    watchSubmitImageUrl(),
    watchSubmitSplitOrderItemRequest(),
    watchChangeTracking(),
    watchGetOrderFailedInfoCommand(),
    watchGetOrderFailedInfoCommandSuccess(),
    watchGetOrderLogsCommand(),
    watchReattemptOrderAsync(),
    watchReattemptOrderAsyncSuccess(),
    watchReattemptOrderAsyncFailure()
  ]);
}
