import { CHANGE_STEP_DUPLICATE } from './DuplicateProductViewActions';
import { RESET } from '../../store/actions/globalActions';
import initialState from '../../store/initialState';

const defaultState = initialState.getIn(['hub', 'duplicateProductView']);

const duplicateProductViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        return defaultState;
      } else {
        return state;
      }
    }
    case CHANGE_STEP_DUPLICATE: {
      return state.set('step', action.payload.step);
    }
    default:
      return state;
  }
};

export default duplicateProductViewReducer;
