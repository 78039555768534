import { combineReducers } from 'redux-immutable';
import pastOrdersViewReducer from './PastOrdersView/PastOrdersViewReducer';
import hubProductsViewReducer from './HubProductsView/HubProductsViewReducer';
import editDesignViewReducer from './EditDesignView/EditDesignViewReducer';
import cartViewReducer from './CartView/CartViewReducer';
import orderFromViewReducer from './OrderFromView/OrderFromViewReducer';

// ------------------------------------
// Reducer
// ------------------------------------

export default combineReducers({
  pastOrdersView: pastOrdersViewReducer,
  hubProductsView: hubProductsViewReducer,
  editDesignView: editDesignViewReducer,
  cartView: cartViewReducer,
  orderFromView: orderFromViewReducer
});
