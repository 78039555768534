import { delay } from 'redux-saga';
import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import {
  UNPUBLISH_PRODUCTS,
  PUBLISH_PRODUCTS,
  DELETE_PRODUCTS,
  UNLINK_PRODUCTS,
  PERSONALIZE_PRODUCTS,
  DEPERSONALIZE_PRODUCTS,
  storeCustomizationSettings,
  unselectAll
} from './ActionsActions';
import { setUpdating, queryInvalidate, QUERY_LOAD } from '../Products/ProductsActions';
import { selectedProductsSelector } from './../Products/ProductsSelectors';
import {
  selectedStoreSelector,
  selectedStoreHasProductsToLinkSelector
} from '../Stores/StoresSelectors';
import actionsService from 'gooten-components/src/services/actionsService';
import Config from '../../../../config';
import { DELETE_PRODUCT } from 'gooten-components/src/components/ProductPublish/atoms/shared/ProductVariants/ProductVariantsActions';
import { searchChange } from '../Search/SearchActions';
import { refreshStoreData } from '../Stores/StoresActions';

export function* publishProductsAsyncHandler(action) {
  try {
    yield call(delay, 500);
    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);

    yield put(setUpdating(true));
    // call and pass correct this as 1st array param
    yield call([actionsService, actionsService.publishProducts], products);
    yield put(fetchAsyncSuccess(PUBLISH_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(PUBLISH_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

export function* unpublishProductsAsyncHandler(action) {
  try {
    yield call(delay, 500);
    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);

    yield put(setUpdating(true));
    // call and pass correct this as 1st array param
    yield call([actionsService, actionsService.unpublishProducts], products);
    yield put(fetchAsyncSuccess(UNPUBLISH_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(UNPUBLISH_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

export function* deleteProductsAsyncHandler(action) {
  try {
    yield call(delay, 500);
    yield put(setUpdating(true));

    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);
    yield call([actionsService, actionsService.deleteProducts], products);
    yield put(fetchAsyncSuccess(DELETE_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(DELETE_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

export function* unlinkProductsAsyncHandler(action) {
  try {
    yield put(setUpdating(true));
    yield call(delay, 500);
    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);
    // call and pass correct this as 1st array param
    yield call([actionsService, actionsService.unlinkProducts], products);
    yield put(fetchAsyncSuccess(UNLINK_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(UNLINK_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

export function* productsUpdateActionsSuccessHandler(action) {
  // NOTE: Debounce here to load products only once all actions finished
  // wait 500ms so this can be canceled while waiting
  yield call(delay, 500);

  // dispatch QUERY_INVALIDATE
  yield put(queryInvalidate());
}

export function* productsUnlinkActionsSuccessHandler(action) {
  const hadProductsToLink = yield select(selectedStoreHasProductsToLinkSelector);
  if (!hadProductsToLink) {
    const store = yield select(selectedStoreSelector);
    yield put(refreshStoreData(store));
  }
  yield productsUpdateActionsSuccessHandler(action);
}

export function* watchPublishProductsAsync() {
  yield all([takeEvery(PUBLISH_PRODUCTS.ASYNC, publishProductsAsyncHandler)]);
}

export function* watchUnpublishProductsAsync() {
  yield all([takeEvery(UNPUBLISH_PRODUCTS.ASYNC, unpublishProductsAsyncHandler)]);
}

export function* watchDeleteProductsAsync() {
  yield all([takeEvery(DELETE_PRODUCTS.ASYNC, deleteProductsAsyncHandler)]);
}

export function* watchUnlinkProductsAsync() {
  yield all([takeEvery(UNLINK_PRODUCTS.ASYNC, unlinkProductsAsyncHandler)]);
}

export function* watchProductsUpdateActionsSuccess() {
  // NOTE: this watches when some of products update actions finished with SUCCESS
  // and then dispatch QUERY_INVALIDATE action
  yield all([
    takeLatest(DELETE_PRODUCTS.SUCCESS, productsUpdateActionsSuccessHandler),
    takeLatest(UNPUBLISH_PRODUCTS.SUCCESS, productsUpdateActionsSuccessHandler),
    takeLatest(PUBLISH_PRODUCTS.SUCCESS, productsUpdateActionsSuccessHandler),
    takeLatest(UNLINK_PRODUCTS.SUCCESS, productsUnlinkActionsSuccessHandler),
    takeLatest(PERSONALIZE_PRODUCTS.SUCCESS, productsUpdateActionsSuccessHandler),
    takeLatest(DEPERSONALIZE_PRODUCTS.SUCCESS, productsUpdateActionsSuccessHandler)
  ]);
}

export function* handlePersonalizeProductsAsync(action) {
  try {
    yield call(delay, 500);
    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);

    yield put(setUpdating(true));
    // call and pass correct this as 1st array param
    yield call([actionsService, actionsService.personalizeProducts], products);
    yield put(fetchAsyncSuccess(PERSONALIZE_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(PERSONALIZE_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

export function* handleDepersonalizeProductsAsync(action) {
  try {
    yield call(delay, 500);
    const products = action.payload.size ? action.payload : yield select(selectedProductsSelector);

    yield put(setUpdating(true));
    // call and pass correct this as 1st array param
    yield call([actionsService, actionsService.depersonalizeProducts], products);
    yield put(fetchAsyncSuccess(DEPERSONALIZE_PRODUCTS));
  } catch (err) {
    yield put(fetchAsyncFail(DEPERSONALIZE_PRODUCTS, err));
  } finally {
    yield put(setUpdating(false));
  }
}

function* handleDeleteProductFromPublishPage() {
  yield put(unselectAll());
  yield put(searchChange());
  yield put(queryInvalidate());
}

export function* watchPersonalizeProductsAsync() {
  yield all([takeEvery(PERSONALIZE_PRODUCTS.ASYNC, handlePersonalizeProductsAsync)]);
}

export function* watchDepersonalizeProductAsync() {
  yield all([takeEvery(DEPERSONALIZE_PRODUCTS.ASYNC, handleDepersonalizeProductsAsync)]);
}

function* watchDeleteProductFromPublishPage() {
  yield takeLatest([DELETE_PRODUCT.SUCCESS], handleDeleteProductFromPublishPage);
}

export default function* rootSaga() {
  yield all([
    watchUnpublishProductsAsync(),
    watchPublishProductsAsync(),
    watchDeleteProductsAsync(),
    watchProductsUpdateActionsSuccess(),
    watchUnlinkProductsAsync(),
    watchPersonalizeProductsAsync(),
    watchDepersonalizeProductAsync(),
    watchDeleteProductFromPublishPage()
  ]);
}
