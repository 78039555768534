import { connect } from 'react-redux';
import VariantsList from './VariantsList';
import {
  selectedVariantSelector,
  selectedVariantIndexSelector,
  selectedSpaceSelector,
  selectedSpaceIndexSelector,
  detailedVariantsSelector,
  skuOverflow,
  isCartItemEditMode
} from '../../ImageUploadSelectors';
import { initImageModal, variantSelect } from '../../ImageUploadActions';
import { variantDelete } from '../../../../store/actions/dataActions';
import { shouldChangeOrientation } from '../../../SKUSelection/SKUSelectionSelectors';
import Config from '../../../../config';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/

const mapDispatchToProps = {
  variantSelect: variantSelect,
  variantDelete: variantDelete,
  initImageModal: initImageModal
};

const mapStateToProps = state => {
  const COFEditDesignMode =
    Config.get('steps')
      .find(x => x.get('id') === 'ImageUpload')
      .getIn(['config', 'editDesignMode', 'active']) === true;
  let props = {
    disableDelete:
      isCartItemEditMode(state) ||
      Config.get('editMode') ||
      Config.get('linkMode') ||
      COFEditDesignMode,
    variants: detailedVariantsSelector(state) || [],
    currentVariantIndex: selectedVariantIndexSelector(state),
    currentVariant: selectedVariantSelector(state),
    skuOverflow: skuOverflow(state),
    shouldChangeOrientation: shouldChangeOrientation(state)
  };

  if (props.currentVariant) {
    props.currentSpace = selectedSpaceSelector(state);
  }

  if (!props.currentSpace) {
    return props;
  }

  props.currentSpaceIndex = selectedSpaceIndexSelector(state);

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(VariantsList);
