import { SIDE_BAR_UPDATE, SIDE_BAR_OPEN, SIDE_BAR_CLOSE } from './ProductDetailsActions';
import { CATEGORY_SELECT, SUBCATEGORY_SELECT } from '../CategoriesNav/CategoriesNavActions';
import { PRODUCT_SELECTION_CLICK_OUTSIDE } from '../../ProductSelectionActions';
import { BACK } from '../../../../containers/NavManager/NavManagerActions';
import { GLOBAL_CLOSE_SIDEBAR } from '../../../../store/actions/globalActions';

const initialState = {
  productInfo: {}
};

export const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIDE_BAR_OPEN:
      return Object.assign({}, state, {
        isOpen: true
      });
    case BACK:
    case GLOBAL_CLOSE_SIDEBAR:
    case SIDE_BAR_CLOSE: {
      return Object.assign({}, state, {
        isOpen: false
      });
    }
    case SIDE_BAR_UPDATE:
      return Object.assign({}, state, {
        productInfo: action.payload
      });
    case CATEGORY_SELECT:
    case SUBCATEGORY_SELECT:
    case PRODUCT_SELECTION_CLICK_OUTSIDE: {
      return Object.assign({}, state, {
        productInfo: {},
        isOpen: false
      });
    }
    default:
      return state;
  }
};
