import Config from '../config';
import Analytics from './analyticsService';

class LogService {
  _getAirbrakeClient() {
    if (window.GTN && window.GTN.__airbrake) {
      return window.GTN.__airbrake;
    }
  }

  _getCurrentSys() {
    const locationHash = window.location.hash;
    let sys = '';
    if (~locationHash.indexOf('custom-order-form')) {
      sys = 'COF';
    } else if (~locationHash.indexOf('hub')) {
      sys = 'Hub';
    } else if (~locationHash.indexOf('onboardingdashboard')) {
      sys = 'Home';
    }

    return sys;
  }

  _formatErrorMsg(msg) {
    return `${msg} PartnerId=${Config.get('partnerId')} Sys=${this._getCurrentSys()}`;
  }

  _notify(err, msg, details) {
    const errorMsg = this._formatErrorMsg(msg);
    const airbrake = this._getAirbrakeClient();
    if (airbrake) {
      if (err) {
        // Swap original error message with custom since it has additional params
        // which we use for Search
        details.originalErrorMessage = err.message;
        err.message = errorMsg;
      }
      airbrake
        .notify({
          error: err || errorMsg,
          environment: {
            userName: Config.get('adminUserName')
          },
          params: details,
          context: {
            environment: 'production',
            user: {
              id: Config.get('partnerId'),
              name: Config.get('adminUserName'),
              email: '',
              username: Config.get('adminUserName')
            }
          }
        })
        .then(notice => {
          if (notice.id) {
            console.debug('Airbrake notice id', notice.id);
          } else {
            console.warn('Airbrake notify failed', notice.error);
          }
        });
    }

    console.error(`Log: ${errorMsg}`, err, details);
  }

  withFriendlyMsg(msg, err, details) {
    err.friendlyMsg = msg;
    err.details = details;
    return err;
  }

  debug(msg, obj = {}) {
    console.debug(msg, obj);
  }

  info(msg, obj = {}) {
    console.info(msg, obj);
  }

  warn(msg, obj = {}) {
    console.warn(msg, obj);
  }

  /*
  Logs error object with custom message and details obj.
   */
  error(err, msg, details = {}) {
    this._notify(err, msg, details);
    Analytics._track('js', 'error2', null, msg, {
      partnerId: Config.get('partnerId'),
      system: this._getCurrentSys()
    });
  }

  /*
  Reports error message and details obj.
  */
  report(msg, details = {}) {
    this._notify(null, msg, details);
    Analytics._track('js', 'error2', null, msg, {
      partnerId: Config.get('partnerId'),
      system: this._getCurrentSys()
    });
  }
}

// singleton
export default new LogService();
