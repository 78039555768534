import { takeLatest, put, all, call } from 'redux-saga/effects';
import { UPLOAD } from './ProfileInformationActions';
import { watchInitAsync, watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import analyticsService from 'gooten-components/src/services/analyticsService';
import ProfileInformation from './ProfileInformation';
import YourTeam from '../YourTeam/YourTeam';
import { initFor } from '../BaseCard/BaseCardActions';
import settingsApiService from '../../SettingsApiService';
import EmailPreferences from '../EmailPreferences/EmailPreferences';

export function* uploadDoneAsyncHandler(action) {
  yield put(push('Logo updated.', 'success'));
}

export function* watchUploadDone() {
  yield takeLatest(UPLOAD.SUCCESS, uploadDoneAsyncHandler);
}

export function* uploadFailAsyncHandler(action) {
  yield put(push('Failed to update logo.', 'failure'));
}

export function* watchUploadFail() {
  yield takeLatest(UPLOAD.FAIL, uploadFailAsyncHandler);
}

function* onSuccess(state, payload) {
  if (
    state.getIn(['data', 'FirstName']) !== state.getIn(['unmodified', 'FirstName']) ||
    state.getIn(['data', 'LastName']) !== state.getIn(['unmodified', 'LastName'])
  ) {
    analyticsService.track('Settings - Profile', 'Name changed', 'Settings');
  }
  if (state.getIn(['data', 'LegalName']) !== state.getIn(['unmodified', 'LegalName'])) {
    analyticsService.track('Settings - Profile', 'Legal business name changed', 'Settings');
  }
  if (state.getIn(['data', 'EmailAddress']) !== state.getIn(['unmodified', 'EmailAddress'])) {
    yield put(initFor(EmailPreferences.name)());
    analyticsService.track('Settings - Profile', 'Email address changed', 'Settings');
  }
  if (payload.userId) {
    yield put(initFor(YourTeam.name, YourTeam.messages)());
  }
}

export default function* rootSaga() {
  yield all([
    watchInitAsync(ProfileInformation.name, ProfileInformation.messages, true),
    watchUpdateAsync(ProfileInformation.name, ProfileInformation.messages, true, onSuccess),
    watchUploadDone(),
    watchUploadFail()
  ]);
}
