import { connect } from 'react-redux';
import { multiproductSelector, nameSelector } from './ProductSectionSelectors';
import { ProductSection } from './ProductSection';
import { selectedProduct } from '../../SKUSelection/SKUSelectionSelectors';
import { openSideBar } from '../../ProductSelection/atoms/ProductDetails/ProductDetailsActions';
import { PRODUCT_DETAILS_FETCH, fetchAsync } from '../../../store/actions/dataActions';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/
const mapDispatchToProps = dispatch => {
  return {
    openProductInfo: productType => {
      dispatch(openSideBar());
      dispatch(fetchAsync(PRODUCT_DETAILS_FETCH, productType));
    }
  };
};

const mapStateToProps = state => {
  return {
    title: nameSelector(state),
    regions: selectedProduct(state).details.product.regions,
    productType: selectedProduct(state).name,
    isMultiproduct: multiproductSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSection);
