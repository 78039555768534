import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { injectSaga } from '../store/sagas';
import { fromJS } from 'immutable';
import { FETCH_REPORT_DATA } from './ProductionReportActions';
import service from './ProductionReportService';
import { fetchAsyncFail, fetchAsyncSuccess } from 'gooten-components/src/store/actions/dataActions';
import Config from '../../src/config';

function* handleInitProductionReport() {
  try {
    // fetch production report data...
    const report = yield call([service, service.getReportData], Config.get('partnerId'));
    yield put(fetchAsyncSuccess(FETCH_REPORT_DATA, fromJS(report)));
  } catch (error) {
    // handle error here...
    yield put(
      fetchAsyncFail(FETCH_REPORT_DATA, 'Error while fetching report. Please try later...')
    );
  }
}

function* watchInitProductionReport() {
  yield takeLatest(FETCH_REPORT_DATA.ASYNC, handleInitProductionReport);
}

function* rootSaga() {
  yield all([watchInitProductionReport()]);
}

const registerSaga = () => {
  injectSaga(rootSaga);
};

export default registerSaga;
