import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail
} from 'gooten-components/src/store/actions/dataActions';

export const getActionName = ActionNameFactory('ORDERS', 'ORDERS_VIEW');

export const UPDATE_PAGE_NUMBER = getActionName('UPDATE_PAGE_NUMBER');
export const ORDERS_REQUEST = getActionName.async('ORDERS_REQUEST');
export const CHANGE_STATUS = getActionName.async('CHANGE_STATUS');
export const CHANGE_TRACKING = getActionName.async('CHANGE_TRACKING');
export const SEARCH_START = getActionName('SEARCH_START');
export const UPDATE_SEARCH_DATA = getActionName('UPDATE_SEARCH_DATA');
export const UPDATE_SEARCH_DATA_FAIL = getActionName('UPDATE_SEARCH_DATA_FAIL');
export const UPDATE_TAB_NUMBER = getActionName('UPDATE_TAB_NUMBER');
export const TOGGLE_SELECTED_ROW = getActionName('TOGGLE_SELECT_ROW');
export const TOGGLE_SELECTED_ROWS = getActionName('TOGGLE_SELECTED_ROWS');
export const UPDATE_SEARCH_FIELDS = getActionName('UPDATE_SEARCH_FIELDS');
export const UPDATE_SEARCH_FIELDS_OBJECT = getActionName('UPDATE_SEARCH_FIELDS_OBJECT');
export const UPDATE_SEARCH_FIELDS_OBJECT_INITIAL = getActionName(
  'UPDATE_SEARCH_FIELDS_OBJECT_INITIAL'
);
export const UPDATE_ORDER_ORDER_ITEM = getActionName('UPDATE_ORDER_ORDER_ITEM');
export const UPDATE_PERSONALIZED_IMAGES = getActionName.async('UPDATE_PERSONALIZED_IMAGES');

export const changeStatusAsync = payload => fetchAsync(CHANGE_STATUS, payload);
export const changeStatusSuccess = payload => fetchAsyncSuccess(CHANGE_STATUS, payload);
export const changeStatusFail = payload => fetchAsyncFail(CHANGE_STATUS, payload);

export const changeTrackingAsync = payload => fetchAsync(CHANGE_TRACKING, payload);
export const changeTrackingSuccess = payload => fetchAsyncSuccess(CHANGE_TRACKING, payload);
export const changeTrackingFail = payload => fetchAsyncFail(CHANGE_TRACKING, payload);

export function toggleSelectedRow(data) {
  return {
    type: TOGGLE_SELECTED_ROW,
    payload: data
  };
}

export function toggleSelectedRows(selected) {
  return {
    type: TOGGLE_SELECTED_ROWS,
    payload: { selected }
  };
}

export function updateTabNumber(tabNumber) {
  return {
    type: UPDATE_TAB_NUMBER,
    payload: { tabNumber }
  };
}

export function updatePageNumber(pageNumber) {
  return {
    type: UPDATE_PAGE_NUMBER,
    payload: { pageNumber }
  };
}

export function searchStart(searchQueryString) {
  return {
    type: SEARCH_START,
    payload: { searchQueryString }
  };
}

export function ordersRequestAsync(request) {
  return {
    type: ORDERS_REQUEST.ASYNC,
    payload: { request }
  };
}

export function updateSearchData(data) {
  return {
    type: UPDATE_SEARCH_DATA,
    payload: { data }
  };
}

export function updateSearchDataFail(errors) {
  return {
    type: UPDATE_SEARCH_DATA_FAIL,
    payload: { errors }
  };
}

export function updateSearchFields(key, value, sortField = null) {
  return {
    type: UPDATE_SEARCH_FIELDS,
    payload: { key, value, sortField }
  };
}

export function updateSearchFieldsWithObjectInitial(searchObject) {
  return {
    type: UPDATE_SEARCH_FIELDS_OBJECT_INITIAL,
    payload: { searchObject }
  };
}

export function updateSearchFieldsWithObject(searchObject) {
  return {
    type: UPDATE_SEARCH_FIELDS_OBJECT,
    payload: { searchObject }
  };
}

export function updateOrderOrderItem(searchObject) {
  return {
    type: UPDATE_ORDER_ORDER_ITEM,
    payload: { searchObject }
  };
}

export function updatePersonalizedImages(data) {
  return {
    type: UPDATE_PERSONALIZED_IMAGES.ASYNC,
    data
  };
}

export function updatePersonalizedImagesSucess(data) {
  return {
    type: UPDATE_PERSONALIZED_IMAGES.SUCCESS,
    data
  };
}

export function updatePersonalizedImagesFail(data) {
  return {
    type: UPDATE_PERSONALIZED_IMAGES.FAIL,
    data
  };
}
