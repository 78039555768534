import * as ProductListActions from './components/ProductList/ProductListActions';
import * as ProductFilteringActions from './components/ProductsFiltering/ProductsFilteringActions';
import { RESET } from '../../../store/actions/globalActions';
import {
  LOAD_STORE_PRODUCTS,
  CHECK_STORE_INDEXING,
  INIT,
  READY
} from './ProductSelectionViewActions';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['hub', 'linkProduct', 'productSelectionView']);

const productSelectionViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global' || action.payload.scope === 'link') {
        return defaultState;
      } else {
        return state;
      }
    }

    case INIT.ASYNC: {
      return (
        state
          // Link Flow independent store setting
          .set('storeId', action.payload.storeId)
      );
    }

    case INIT.SUCCESS: {
      return state.set('providerPath', action.payload.providerPath);
    }
    case CHECK_STORE_INDEXING.SUCCESS: {
      return state.set('productsIndexing', action.payload);
    }

    case LOAD_STORE_PRODUCTS.SUCCESS: {
      return state.set('products', action.payload).set('ready', true);
    }
    case READY: {
      return state.set('ready', true);
    }

    case ProductListActions.PRODUCT_SELECT: {
      return state.set('selectedProduct', action.payload.selected ? action.payload.product : null);
    }
    case ProductListActions.PAGE_SELECT: {
      return state.set('page', action.payload);
    }

    case ProductFilteringActions.CHANGE_SEARCH: {
      return state.set('search', action.payload).set('page', 1);
    }
    case ProductFilteringActions.CHANGE_VIEWBY: {
      return state.set('viewBy', action.payload).set('page', 1);
    }

    default:
      return state;
  }
};

export default productSelectionViewReducer;
