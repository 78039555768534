import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Thumbnail from './Thumbnail';
import Icon from './Icon';
import DropDownContainer from './DropDown/DropDownContainer';

const PageHeader = ({
  title,
  secondaryText,
  secondaryButtonClass,
  secondaryClick,
  secondaryHref,
  secondaryDisabled,
  primaryText,
  primaryClick,
  primaryHref,
  primaryDisabled,
  thumbnail,
  menuItems,
  backClick,
  backText,
  backHref,
  arrowLeftClick,
  arrowRightClick,
  customButtons,
  className,
  size
}) => {
  return (
    <>
      <div className={`${size ? size + '-layout m-auto' : ''} pt-4 header-padding`} />
      <div
        className={`${
          size ? size + '-layout m-auto' : ''
        } navigation-row d-flex align-items-center justify-content-between ${className || ''}`}
      >
        {(backClick || backHref) && (
          <a href={backHref || null}>
            <Button
              onClick={backClick}
              className={`button-white medium ml-n2 pr-2 ${
                typeof backText === 'string' ? backText.replace(/\s+/g, '-').toLowerCase() : ''
              }`}
            >
              <Icon icon="chevronLeft" className="ml-n1 mr-2" /> {backText || 'Back'}
            </Button>
          </a>
        )}
        <div className="nav-buttons d-flex align-items-center justify-content-end mr-n2">
          {arrowLeftClick && (
            <Button onClick={arrowLeftClick} iconOnly className="button-white medium">
              <Icon icon="arrowLeft" />
            </Button>
          )}
          {arrowRightClick && (
            <Button onClick={arrowRightClick} iconOnly className="button-white medium">
              <Icon icon="arrowRight" />
            </Button>
          )}
        </div>
      </div>
      <div
        className={`${
          size ? size + '-layout m-auto' : ''
        } pt-3 title-row d-flex align-items-center justify-content-between`}
      >
        <div className="thumb-and-title d-flex align-items-center justify-content-start">
          {thumbnail && <Thumbnail image={thumbnail} className="medium mr-3" />}
          <div className="title-1 w-100">{title && title}</div>
        </div>
        <div className="button-holder d-flex align-items-center justify-content-end">
          {(secondaryClick || secondaryHref) && (
            <a href={secondaryHref || null}>
              <Button
                className={`large-only ${secondaryButtonClass || 'button-secondary'} large ml-2`}
                disabled={secondaryDisabled}
                onClick={secondaryClick}
              >
                {secondaryText}
              </Button>
            </a>
          )}
          {(primaryClick || primaryHref) && (
            <a href={primaryHref || null}>
              <Button
                className="large-only button-primary large ml-2"
                disabled={primaryDisabled}
                onClick={primaryClick}
              >
                {primaryText}
              </Button>
            </a>
          )}
          <div className="large-only">{customButtons}</div>
          {menuItems && (
            <div className="ml-2">
              <DropDownContainer rightAlign buttonOnly>
                {menuItems}
              </DropDownContainer>
            </div>
          )}
        </div>
      </div>
      <div className={`${size ? size + '-layout' : ''} mobile-buttons pt-3`}>
        {(primaryClick || primaryHref) && (
          <a className="w-100" href={primaryHref || null}>
            <Button className="button-primary large w-100" onClick={primaryClick}>
              {primaryText}
            </Button>
          </a>
        )}
        {customButtons && <div className="w-100 d-flex">{customButtons}</div>}
      </div>
      <style jsx>
        {`
          a {
            text-decoration: none !important;
          }
          .navigation-row :global(a[disabled]),
          .title-row :global(a[disabled]),
          .mobile-buttons :global(a[disabled]) {
            pointer-events: none !important;
          }
          .header-padding,
          .navigation-row,
          .title-row,
          .mobile-buttons {
            padding-right: 2rem;
            padding-left: 2rem;
          }
          @media screen and (max-width: 767px) {
            .header-padding,
            .navigation-row,
            .title-row,
            .mobile-buttons {
              padding-right: 1rem;
              padding-left: 1rem;
            }
          }
          .navigation-row {
            max-height: 3rem;
          }
          .title-row {
            height: ${thumbnail ? '4rem' : '2.5rem'};
          }
          .title-1,
          .thumb-and-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .thumb-and-title :global(.thumbnail) {
            min-width: 64px;
          }
          @media screen and (min-width: 767px) {
            .mobile-buttons {
              display: none;
            }
          }
          @media screen and (max-width: 767px) {
            .button-holder :global(.large-only) {
              display: none;
            }
            .mobile-buttons {
              display: flex;
            }
            .title-1 {
              font-size: 1.5rem;
            }
          }
          .standard-layout {
            max-width: 66rem;
          }
          .wide-layout {
            max-width: 72.5rem;
          }
          .full-layout {
            max-width: 100%;
          }
          .small-layout {
            max-width: 52rem;
          }
          .extra-small-layout {
            max-width: 40rem;
          }
        `}
      </style>
    </>
  );
};
PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
  secondaryText: PropTypes.string,
  secondaryClick: PropTypes.func,
  secondaryButtonClass: PropTypes.string,
  secondaryHref: PropTypes.string,
  secondaryDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
  primaryClick: PropTypes.func,
  primaryHref: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  thumbnail: PropTypes.string,
  menuItems: PropTypes.array,
  backClick: PropTypes.func,
  backHref: PropTypes.string,
  backText: PropTypes.string,
  arrowLeftClick: PropTypes.func,
  arrowRightClick: PropTypes.func,
  customButtons: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.oneOf(['standard', 'wide', 'full', 'small', 'extra-small'])
};

export default PageHeader;
