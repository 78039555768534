import { createSelector } from 'reselect';
import { searchSelector } from '../SearchBar/SearchBarSelectors';
import { dataSelector } from '../../../../store/selectors/productDataSelectors';

export const allCategoriesSelector = createSelector([dataSelector], data => data.get('categories'));

const productSelectionState = state => state.get('productSelection');

export const categorySelector = createSelector(
  [productSelectionState, allCategoriesSelector],
  (productSelection, allCategories) =>
    productSelection.get('selectedCategory') || allCategories.first()
);

export const selectedSubCategorySelector = createSelector(
  [productSelectionState],
  productSelectionState => productSelectionState.get('selectedSubcategory')
);

export const selectedCategorySelector = createSelector(
  [allCategoriesSelector, selectedSubCategorySelector, categorySelector, searchSelector],
  (allCategories, selectedSubCategory, selectedCategory, search) => {
    if (search.length) {
      return (
        allCategories.find(category => category.get('name').indexOf('All') !== -1) ||
        allCategories.first()
      );
    }

    if (selectedSubCategory === null) {
      return selectedCategory;
    }
    return allCategories.find(cat => cat.name === selectedSubCategory.parentCategoryName);
  }
);

export const hoveredCategorySelector = createSelector(
  [productSelectionState, selectedCategorySelector],
  (productSelection, selectedCategory) =>
    productSelection.get('hoveredCategory') || selectedCategory
);

export const subCategoriesSelector = createSelector(
  [allCategoriesSelector, hoveredCategorySelector],
  (allCategories, hoveredCategory) => hoveredCategory.get('subcategories')
);

export const needForceScrollSelector = createSelector(
  [productSelectionState],
  productSelectionState => productSelectionState.get('forceScrollToSelectedSubCategory')
);
