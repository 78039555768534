import { delay } from 'redux-saga';
import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { hashHistory } from 'react-router';
import {
  fetchAsyncSuccess,
  fetchAsyncFail,
  fetchAsync,
  PUBLISH,
  setShopifyRedirectRequiredStores
} from 'gooten-components/src/store/actions/dataActions';
import { getShopifyOAuthUrl } from 'gooten-components/src/utils/oauth';
import {
  PAGE_CHANGE,
  PAGE_SIZE_CHANGE,
  QUERY_PARAMS_CHANGE,
  queryParamsChange,
  queryChange,
  QUERY_LOAD,
  VARIANTS_LOAD,
  COLLECTIONS_LOAD,
  SHOPIFY_REDIRECT_REQUIRED
} from './ProductsActions';
import { shopyfyPermissions } from 'gooten-components/src/utils/oauth';
import { FETCH_SHOPIFY_TOKEN_SCOPES } from '../Actions/ActionsActions';
import { PROVIDER_SELECT } from '../Providers/ProvidersActions';
import { STORE_SELECT } from '../Stores/StoresActions';
import { SEARCH_CHANGE, searchChange } from '../Search/SearchActions';
import { VIEW_BY_CHANGE, SORT_BY_CHANGE, unselectAll } from '../Actions/ActionsActions';
import productsService from './ProductsService';
import { productsQuerySelector } from './ProductsSelectors';
import { allStoresSelector } from '../Stores/StoresSelectors';
import { List } from 'immutable';
import adminApiService from 'gooten-components/src/services/adminApiService';
import Config from '../../../../config';

export function* productsQueryParamsChangeHandler() {
  yield put(queryParamsChange());
}

export function* watchProductsQueryParamsChange() {
  // NOTE: this watches when some of products query related params are changed
  // and then dispatch QUERY_CHANGED action
  yield all([
    takeLatest(PROVIDER_SELECT, productsQueryParamsChangeHandler),
    takeLatest(STORE_SELECT, productsQueryParamsChangeHandler),
    takeLatest(VIEW_BY_CHANGE, productsQueryParamsChangeHandler),
    takeLatest(SORT_BY_CHANGE, productsQueryParamsChangeHandler),
    takeLatest(SEARCH_CHANGE, productsQueryParamsChangeHandler),
    takeLatest(PAGE_SIZE_CHANGE, productsQueryParamsChangeHandler),
    takeLatest(PAGE_CHANGE, productsQueryParamsChangeHandler)
  ]);
}

export function* productsQueryChangeHandler() {
  // get current state, sagas executes after reducers
  // so state is up to date here
  const q = yield select(productsQuerySelector);
  const key = productsService.getQueryCacheKey(q);

  // need create query key from query params from state
  // key format: {provider-store-viewBy-search-pageSize-page}
  yield put(queryChange(key));
}

export function* watchProductsQueryChange() {
  yield takeLatest(QUERY_PARAMS_CHANGE, productsQueryChangeHandler);
}

export function* productsQueryLoadHandler(action) {
  // NOTE: QUERY_CHANGE can occure too offten
  // wait 500ms so this can be canceled while waiting
  yield call(delay, 500);
  try {
    let data = yield call(
      [productsService, productsService.loadProductsQuery],
      action.payload.query
    );
    const key = productsService.getQueryCacheKey(action.payload.query);
    yield put(fetchAsyncSuccess(QUERY_LOAD, { data, key }));
  } catch (err) {
    yield put(fetchAsyncFail(QUERY_LOAD, err));
    throw err;
  }
}

export function* watchProductsQueryLoad() {
  yield takeLatest(QUERY_LOAD.ASYNC, productsQueryLoadHandler);
}

function* fetchShopifyTokenScopesAsync() {
  const partnerId = Config.get('partnerId');

  try {
    // call and pass correct this as 1st array param
    const scopes = yield call(
      [adminApiService, adminApiService.get],
      'ShopifyAccounts/GetAccessTokenScopes',
      {
        partnerId: partnerId
      }
    );

    yield call(delay, 500);

    const stores = yield select(allStoresSelector);
    const shopifyRedirectRequiredStores = [];
    for (let i = 0; i < stores.size; i++) {
      const store = stores.get(i);
      const provider = scopes.ShopifyAccessScopes.filter(
        provider => provider.StoreName == store.get('storeName')
      )[0];

      // Go through Stores and their access scopes and redirect to Auth if any of them have changed
      for (let j = 0; j < shopyfyPermissions.length; j++) {
        let permission = shopyfyPermissions[j];

        if (
          provider &&
          provider.AccessScope.access_scopes.filter(scope => scope.handle == permission).length < 1
        ) {
          // Push Shopify Store Name
          shopifyRedirectRequiredStores.push(provider.StoreName);
        }
      }

      if (shopifyRedirectRequiredStores.length > 0) {
        yield put(setShopifyRedirectRequiredStores(shopifyRedirectRequiredStores));
      }
    }
  } catch (err) {
    console.error('Error Checking Shopify Access Token Permissions - ', err);
  }
}
function* watchFetchShopifyTokenScopes() {
  yield takeLatest([FETCH_SHOPIFY_TOKEN_SCOPES.ASYNC], fetchShopifyTokenScopesAsync);
}

export function* productVariantsLoadHandler(action) {
  try {
    let data = yield call(
      [productsService, productsService.loadProductVariants],
      action.payload.product
    );
    yield put(fetchAsyncSuccess(VARIANTS_LOAD, { data }));
  } catch (err) {
    yield put(fetchAsyncFail(VARIANTS_LOAD, err));
    throw err;
  }
}

export function* productCollectionsLoadHandler(action) {
  try {
    // load collections ONLY for store products...
    if (action.payload.product.type === 'store') {
      const collections = yield call(
        [productsService, productsService.loadProductCollections],
        action.payload.product
      );
      yield put(
        fetchAsyncSuccess(COLLECTIONS_LOAD, { collections, productId: action.payload.product.id })
      );
    } else {
      // otherwise return [], because for storage products collections are omitted...
      yield put(fetchAsyncSuccess(COLLECTIONS_LOAD, { collections: new List() }));
    }
  } catch (err) {
    // if error occur, don't break the flow, just pass empty collections
    yield put(fetchAsyncSuccess(COLLECTIONS_LOAD, { collections: new List() }));
  }
}

export function* watchProductVariantsLoad() {
  yield takeLatest(VARIANTS_LOAD.ASYNC, productVariantsLoadHandler);
}

export function* watchProductCollectionsLoad() {
  yield takeLatest(COLLECTIONS_LOAD.ASYNC, productCollectionsLoadHandler);
}

// Publish async task handling
export function* publishAsyncSuccessHandler(action) {
  // NOTE: Products reducer handle PUBLISH.ASYNC-SUCCESS action
  // and put task into state.products.task array

  // NOTE: This action got dispatched from components Publish step
  // it means that we are currently in components route:
  // either create-product, edit-product, link-product
  // so once publish success we need redirect back to hub pages
  // Need check what was the page before

  // NOTE: Always redirect to all page
  // since we don't know how to show loading products on store landing page
  yield put(unselectAll());
  // NOTE: Duplicated call
  // once stores got deleted they will be reloaded in Store component
  // yield put(storesLoad())
  yield put(searchChange());
  hashHistory.push('/hub/all');

  // window.hashHistory = hashHistory
  // const location = yield select(locationToRedirectWhenPublishSuccess)
  // if (location && location.get('pathname')) {
  //   console.log('redirecting to ', location)
  //   hashHistory.push(location.get('pathname'))
  // } else {
  //   hashHistory.push('/hub/all')
  // }
}

export function* watchPublishAsyncSuccess() {
  // This watch when publish successfully submitted and processing being started by backend
  yield takeLatest(PUBLISH.SUCCESS, publishAsyncSuccessHandler);
}

export function* watchPublishAsyncFail() {
  // This watch when publish successfully submitted and processing being started by backend
  yield takeLatest(PUBLISH.FAIL, publishAsyncSuccessHandler);
}

// NOTE: Not used since we do sync publish for now
// export function* monitorTasks () {
//   while (true) {
//     // debounce by 10 sec
//     yield call(delay, 10000)
//     // check if there are any tasks
//     const processingTasks = yield select(processingTasksSelector)
//     if (processingTasks.size) {
//       // NOTE: Make ajax call to check the processing tasks statuses
//       // add result and status
//       const monitorRequests = processingTasks.map(t =>
//         productsService.getTaskResult(t.get('id'))
//           .then(res => {
//             if (res.status !== 'processing') {
//               // TODO: put action PRODUCT_TASK_COMPLETE
//               // { taskId: 'guid', results: [], status: 'success | failed' }
//             }
//           }))
//       // TODO: Wait until all requests completed to not spam api
//       yield Promise.all(monitorRequests.map(reflect))
//     }
//   }
// }

export default function* rootSaga() {
  yield all([
    watchProductsQueryParamsChange(),
    watchProductsQueryChange(),
    watchProductsQueryLoad(),
    watchProductVariantsLoad(),
    watchPublishAsyncFail(),
    watchPublishAsyncSuccess(),
    watchProductCollectionsLoad(),
    watchFetchShopifyTokenScopes()
    // monitorTasks()
  ]);
}
