import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'STORES');

// ------------------------------------
// Constants
// ------------------------------------
export const STORE_SELECT = getActionName('STORE_SELECT');
export const STORES_FETCH = getActionName.async('STORES_FETCH');
export const STORE_REFRESH = getActionName.async('STORE_REFRESH');
export const STORE_LOG_OUT = getActionName.async('STORE_LOG_OUT');
// ------------------------------------
// Actions
// ------------------------------------
export function storeSelect({ store = undefined, reset = true }) {
  return {
    type: STORE_SELECT,
    payload: { store, reset }
  };
}

export function storesLoad(skipCache = false) {
  return fetchAsync(STORES_FETCH, { skipCache });
}

export function refreshStoreData(storeId) {
  return fetchAsync(STORE_REFRESH, { storeId });
}

export function storeLogOut(storeId) {
  return fetchAsync(STORE_LOG_OUT, { storeId });
}
