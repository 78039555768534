import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
// import { helpData } from './HelpDataSelectors'
import HelpDataService from './HelpDataService';
import { FETCH_HELP_DATA, updateHelpData } from './HelpDataActions';

export function* handleFetchHelpData() {
  const helpDataRequest = yield call([HelpDataService, HelpDataService.fetchHelpData]);
  yield put(updateHelpData(fromJS(helpDataRequest)));
}

export function* watchSearch() {
  yield takeLatest(FETCH_HELP_DATA.ASYNC, handleFetchHelpData);
}

export default function* rootSaga() {
  yield all([watchSearch()]);
}
