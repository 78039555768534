import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB', 'REPRINT_SELF_SERVICE');

export const INIT_SELF_SERVICE = getActionName('INIT_SELF_SERVICE');
export const UPLOAD_IMAGE = getActionName('UPLOAD_IMAGE');
export const SAVE_IMAGE_URL = getActionName('SAVE_IMAGE_URL');
export const SAVE_REPRINT_DATA = getActionName('SAVE_REPRINT_DATA');
export const SAVE_ORDER_SUMMARY = getActionName('SAVE_ORDER_SUMMARY');
export const SAVE_PARTNER_DETAILS = getActionName('SAVE_PARTNER_DETAILS');
export const SAVE_PARTNER_PREFERENCES = getActionName('SAVE_PARTNER_PREFERENCES');
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const SUBMIT_REPRINT = getActionName('SUBMIT_REPRINT');
export const TOAST_TEXT = getActionName('TOAST_TEXT');
export const INIT_ALL_REASONS = getActionName('INIT_ALL_REASONS');
export const REASON_SELECT = getActionName('REASON_SELECT');
export const SET_SELECTED_RULES = getActionName('SET_SELECTED_RULES');

export const initSelfService = () => ({
  type: INIT_SELF_SERVICE
});

export const uploadImage = payload => ({
  type: UPLOAD_IMAGE,
  payload
});

export const saveImageUrl = payload => ({
  type: SAVE_IMAGE_URL,
  payload
});

export const saveReprintData = payload => ({
  type: SAVE_REPRINT_DATA,
  payload
});

export const saveOrderSummary = payload => ({
  type: SAVE_ORDER_SUMMARY,
  payload
});

export const showLoading = payload => ({
  type: SHOW_LOADING,
  payload
});

export const submitReprint = payload => ({
  type: SUBMIT_REPRINT,
  payload
});

export const savePartnerDetails = payload => ({
  type: SAVE_PARTNER_DETAILS,
  payload
});

export const savePartnerPreferences = payload => ({
  type: SAVE_PARTNER_PREFERENCES,
  payload
});

export const toastText = payload => ({
  type: TOAST_TEXT,
  payload
});

export const initAllReasons = payload => ({
  type: INIT_ALL_REASONS,
  payload
});

export const reasonSelect = payload => ({
  type: REASON_SELECT,
  payload
});

export const setSelectedRules = payload => ({
  type: SET_SELECTED_RULES,
  payload
});
