import { getActionName } from '../../ProductSelectionActions';

// ------------------------------------
// Constants
// ------------------------------------
export const SEARCH_BAR_CHANGE = getActionName('SEARCH_BAR_CHANGE');

// ------------------------------------
// Actions
// ------------------------------------
export function searchBarChange(value = '') {
  return {
    type: SEARCH_BAR_CHANGE,
    payload: value
  };
}
