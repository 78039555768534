import { Map } from 'immutable';
import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import productsService from './ProductsService';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'PRODUCTS');

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCT_SELECT = getActionName('PRODUCT_SELECT');
export const PRODUCT_SELECT_BY_ID = getActionName('PRODUCT_SELECT_BY_ID');
export const PRODUCT_SELECT_EXCLUSIVE = getActionName('PRODUCT_SELECT_EXCLUSIVE');
export const PRODUCT_UNSELECT = getActionName('PRODUCT_UNSELECT');
export const PAGE_CHANGE = getActionName('PAGE_CHANGE');
export const PAGE_SIZE_CHANGE = getActionName('PAGE_SIZE_CHANGE');
export const QUERY_PARAMS_CHANGE = getActionName('QUERY_PARAMS_CHANGE');
export const QUERY_CHANGE = getActionName('QUERY_CHANGE');
export const QUERY_LOAD = getActionName.async('QUERY_LOAD');
export const QUERY_LOAD_IGNORE = getActionName('QUERY_LOAD_IGNORE');
export const QUERY_INVALIDATE = getActionName('QUERY_INVALIDATE');
export const VARIANTS_LOAD = getActionName.async('VARIANTS_LOAD');
export const COLLECTIONS_LOAD = getActionName.async('COLLECTIONS_LOAD');
export const CONNECTIONS_LOAD = getActionName.async('CONNECTIONS_LOAD');
export const HIDE_TASK_NOTIFICATION = getActionName('HIDE_TASK_NOTIFICATION');
export const PRODUCTS_UPDATING = getActionName('PRODUCTS_UPDATING');
export const SHOW_NOTIFICATION = getActionName('SHOW_NOTIFICATION');

// ------------------------------------
// Actions
// ------------------------------------
export const productSelect = product => ({
  type: PRODUCT_SELECT,
  payload: { product }
});

export const productSelectById = productId => ({
  type: PRODUCT_SELECT_BY_ID,
  payload: productId
});

export const productSelectExclusive = product => ({
  type: PRODUCT_SELECT_EXCLUSIVE,
  payload: { product }
});

export const productUnselect = product => ({
  type: PRODUCT_UNSELECT,
  payload: { product }
});

export const pageChange = (page = 1) => ({
  type: PAGE_CHANGE,
  payload: page
});

export const pageSizeChange = (pageSize = 25) => ({
  type: PAGE_SIZE_CHANGE,
  payload: pageSize
});

export const queryParamsChange = () => ({
  type: QUERY_PARAMS_CHANGE
});

export const queryChange = (query = '') => ({
  type: QUERY_CHANGE,
  payload: query
});

export const queryLoad = (query = new Map()) => {
  // NOTE: validate query and return ignore action
  // if query not valid
  if (!productsService.isQueryValid(query)) {
    return {
      type: QUERY_LOAD_IGNORE,
      payload: {
        query: query.toJS(),
        reason: 'INVALID'
      }
    };
  } else {
    return fetchAsync(QUERY_LOAD, { query });
  }
};

export const queryInvalidate = () => ({
  type: QUERY_INVALIDATE
});

export const variantsLoad = product => fetchAsync(VARIANTS_LOAD, { product });

export const hideTaskNotitication = taskId => ({
  type: HIDE_TASK_NOTIFICATION,
  payload: { taskId }
});

export const setUpdating = value => ({
  type: PRODUCTS_UPDATING,
  payload: { value }
});

export const showNotification = payload => ({
  type: SHOW_NOTIFICATION,
  payload
});
