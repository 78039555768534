import { connect } from 'react-redux';
import BaseCard from './BaseCard';
import { openStateSelector, loadingStatusSelector, busyStatusSelector } from './BaseCardSelectors';
import { toggleFor } from './BaseCardActions';

// const mapDispatchToProps = {
//  toggleCard: (cardName) => toggleCardFor(cardName)()
// }

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleCard: () => {
    dispatch(toggleFor(ownProps.card)());
  }
});

const mapStateToProps = (state, ownProps) => ({
  isOpen: openStateSelector(state, ownProps.card),
  isLoading: loadingStatusSelector(state, ownProps.card),
  isBusy: busyStatusSelector(state, ownProps.card)
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseCard);
