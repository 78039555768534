import { Record } from 'immutable';

export const EmailCredentialsModel = new Record({
  customServiceName: 'Your',
  MailMethod: 0,
  DisplayName: '',
  OrdersEmail: '',
  ShippingEmail: '',
  SmtpServer: '',
  SmtpPort: '',
  SmtpUsername: '',
  SmtpPassword: '',
  SmtpUseSSL: false,
  PartnerContactEmail: ''
});
