import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'IMAGE_UPLOAD');

// ------------------------------------
// Constants
// ------------------------------------
export const IS_READY_CHANGE = getActionName('IS_READY_CHANGE');
export const WORKING_JOBS_CHANGE = getActionName('WORKING_JOBS_CHANGE');
export const DEFAULT_VARIANT_SELECT = getActionName('DEFAULT_VARIANT_SELECT');
export const VARIANT_SELECT = getActionName('VARIANT_SELECT');
export const EDITOR_MODE_CHANGE = getActionName('EDITOR_MODE_CHANGE');
export const TOGGLE_PANEL = getActionName('TOGGLE_PANEL');
export const EDITOR_NEXT = getActionName('EDITOR_NEXT');
export const EDITOR_BACK = getActionName('EDITOR_BACK');
export const RESET_IMAGE_UPLOAD = getActionName('RESET_IMAGE_UPLOAD');
export const HIDE_IMAGES_MISSING_MODAL = getActionName('HIDE_IMAGES_MISSING_MODAL');
export const SHOW_IMAGES_MISSING_MODAL = getActionName('SHOW_IMAGES_MISSING_MODAL');
export const IMAGES_PREPARE = getActionName('IMAGES_PREPARE');
export const SHOW_SIDE_BAR = getActionName('SHOW_SIDE_BAR');
export const INIT_ITEMS_TO_PROCESS = getActionName('INIT_ITEMS_TO_PROCESS');
export const DECREMENT_ITEMS_TO_PROCESS = getActionName('DECREMENT_ITEMS_TO_PROCESS');
export const INIT_IMAGE_MODAL = getActionName('INIT_IMAGE_MODAL');
export const SET_NECK_LABELS_PREVIEWS_LOADING = getActionName('SET_NECK_LABELS_PREVIEWS_LOADING');

// ------------------------------------
// Actions
// ------------------------------------

export function setReady() {
  return {
    type: IS_READY_CHANGE,
    payload: true
  };
}

export function startWorking(jobsCount = 1) {
  return {
    type: WORKING_JOBS_CHANGE,
    payload: jobsCount
  };
}

export function finishWorking() {
  return {
    type: WORKING_JOBS_CHANGE,
    payload: -1
  };
}

export function initItemsToProcess(numItems) {
  return {
    type: INIT_ITEMS_TO_PROCESS,
    payload: numItems
  };
}

export function decrementItemsToProcess() {
  return {
    type: DECREMENT_ITEMS_TO_PROCESS
  };
}

export function variantSelect(skuIndex, spaceIndex, prevSkuIndex, prevSpaceIndex) {
  return {
    type: VARIANT_SELECT,
    payload: { skuIndex, spaceIndex, prevSkuIndex, prevSpaceIndex }
  };
}

export function defaultVariantSelect(variants) {
  return {
    type: DEFAULT_VARIANT_SELECT,
    payload: { variants }
  };
}

export function modeChange(mode) {
  return {
    type: EDITOR_MODE_CHANGE,
    payload: { mode }
  };
}

export function togglePanel() {
  return {
    type: TOGGLE_PANEL,
    payload: {}
  };
}

export function editorNext() {
  return {
    type: EDITOR_NEXT,
    payload: {}
  };
}

export function editorBack() {
  return {
    type: EDITOR_BACK,
    payload: {}
  };
}

export function resetImageUpload() {
  return {
    type: RESET_IMAGE_UPLOAD,
    payload: {}
  };
}

export function hideImagesMissingModal() {
  return {
    type: HIDE_IMAGES_MISSING_MODAL,
    payload: {}
  };
}

export function showImagesMissingModal() {
  return {
    type: SHOW_IMAGES_MISSING_MODAL,
    payload: {}
  };
}

export function prepareImages(images) {
  return {
    type: IMAGES_PREPARE,
    payload: { images }
  };
}

export function showSideBar(value) {
  return {
    type: SHOW_SIDE_BAR,
    payload: value
  };
}

// Image Editor Implementation from Orders-New Page

export const EDIT_ORDERED_IMAGE = getActionName('EDIT_ORDERED_IMAGE');

export function editOrderedImage() {
  return {
    type: EDIT_ORDERED_IMAGE,
    payload: {}
  };
}

// Loader for order creation with large number of variants

export const SET_BULK_LOADER_PROGRESS = getActionName('SET_BULK_LOADER_PROGRESS');

export function setBulkLoaderProgress(current, total) {
  return {
    type: SET_BULK_LOADER_PROGRESS,
    payload: { current, total }
  };
}

export const initImageModal = payload => ({
  type: INIT_IMAGE_MODAL,
  payload
});

export function setNeckLabelsPreviewsLoading(loading) {
  return { type: SET_NECK_LABELS_PREVIEWS_LOADING, payload: loading };
}
