import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import BaseCard from '../BaseCard';
import security from '../../SecurityService';
import Multiselect from 'gooten-components/src/components/shared/Multiselect';
import Icon from 'gooten-components/src/components/shared/Icon';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';
import Config from 'gooten-components/src/config';

const teamAccessClass =
  'fixed-lg team-access-container col-lg-4 col-md-8 col-sm-6 col-xs-9 ' +
  'col-lg-offset-0 col-md-offset-3 col-sm-offset-3 col-xs-offset-3';

const multiselectConfig = {
  multiple: false,
  showFilter: false,
  showBulkAction: false,
  preventEmpty: true,
  wrapMode: true,
  getCustomTitle: selectedOptions => {
    return selectedOptions.length === 3
      ? 'Admin...'
      : selectedOptions.find(i => i.key === 2) && selectedOptions.find(i => i.key === 3)
      ? 'Customer Support...'
      : selectedOptions.length
      ? selectedOptions
          .sort((a, b) => a.key - b.key)
          .map(o => o.label)
          .join(', ')
      : null;
  }
};

const roles = [
  {
    key: 1,
    label: 'Admin'
  },
  {
    key: 2,
    label: 'Customer Service'
  },
  {
    key: 3,
    label: 'Reporting'
  },
  {
    key: 4,
    label: 'Customer Service + Reporting'
  }
];

const mapPermissionsToRole = permissions =>
  ~permissions.indexOf(1)
    ? [roles[0]]
    : ~permissions.indexOf(2)
    ? ~permissions.indexOf(3)
      ? [roles[3]]
      : [roles[1]]
    : ~permissions.indexOf(3)
    ? [roles[2]]
    : [];

const mapRoleToPermissions = role => (role === 1 ? [1, 2, 3] : role === 4 ? [2, 3] : [role]);

class YourTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRemoveMemberDialogShown: false,
      member: null
    };
  }

  static get name() {
    return 'yourTeam';
  }
  static get messages() {
    return { onInitFailure: 'Failed to load Team Members information' };
  }

  componentDidMount() {
    this.props.init();
  }

  updateAccess(item, values) {
    const role = values[0];
    if (role) {
      this.props.updateAccess(item.set('Permissions', mapRoleToPermissions(role.key)));
    }
  }

  toUserProfile(userId) {
    const partnerQuery = this.props.location.query.partnerId
      ? `&partnerId=${this.props.location.query.partnerId}`
      : '';
    this.props.router.push(`/settings/team/profileInformation?user=${userId}${partnerQuery}`);
  }

  renderItem(item, first) {
    const userIsAdmin = security.isAdmin();
    const itsMe = security.getPartnerUserId() === item.get('Id');
    const selectedRoles = mapPermissionsToRole(item.get('Permissions'));
    return (
      <div key={item.get('Id')} className="col-xs-12 item-line-row">
        <div className="row">
          <div className="user-remove-container">
            {first ? null : <hr />}
            <div className="col-md-1 col-sm-2 col-xs-3 fixed-column">
              <div className="item-block">
                {userIsAdmin && !itsMe && !item.IsOwner ? (
                  this.props.ready ? (
                    <span
                      className="user-remove mt-2"
                      onClick={() =>
                        this.setState({ isRemoveMemberDialogShown: true, member: item })
                      }
                    >
                      <Icon icon="user" className="avatar-icon" />
                      <Icon icon="x" className="close-icon mt-n1" />
                    </span>
                  ) : (
                    <span className="user-remove">
                      <Icon icon="user" className="avatar-icon icon-disabled" />
                    </span>
                  )
                ) : (
                  <span className="user-remove">
                    <Icon icon="user" className="avatar-icon icon-transparent" />
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3 col-md-3 col-sm-3 col-xs-9">
              <div className="item-block ellipsis">
                <a className="settings-link" onClick={() => this.toUserProfile(item.get('Id'))}>
                  {item.get('FullName')}
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8 col-xs-9 col-sm-offset-0 col-xs-offset-3">
              <div className="item-block ellipsis">{item.get('Email')}</div>
            </div>
          </div>
          <div className={teamAccessClass}>
            <label className="item-block access-label">Access:</label>
            {item.IsOwner ? (
              <span className="item-block">Owner</span>
            ) : (
              <Multiselect
                title="Select"
                options={roles}
                selectedOptions={selectedRoles}
                onChange={s => this.updateAccess(item, s)}
                config={multiselectConfig}
              />
            )}
          </div>
        </div>
        <style jsx>
          {`
            .user-icon {
              cursor: pointer;
            }

            .access-label {
              margin-right: 10px;
            }
          `}
        </style>
      </div>
    );
  }

  handleCloseRemoveMemberDialog() {
    this.setState({ isRemoveMemberDialogShown: false });
  }

  handleRemoveMember() {
    this.handleCloseRemoveMemberDialog();
    this.props.removeMember(this.state.member);
  }

  removeMemberDialog() {
    return (
      <Dialogue
        isOpen={this.state.isRemoveMemberDialogShown}
        title="Remove your team member"
        cancelClick={() => this.handleCloseRemoveMemberDialog()}
        actionText="Remove"
        primaryClick={() => this.handleRemoveMember()}
      >
        {`Removing team member ${this.state.member?.get('Email')} will delete them from your team.
      You will need to invite the member again to restore them back.`}
      </Dialogue>
    );
  }

  getSettingUpMyAccountKbLink = () => {
    const partnerGroup = Config.get('partnerGroup');
    if (!partnerGroup)
      return 'https://help.gooten.com/hc/en-us/articles/360047740491-Setting-up-my-account';

    switch (partnerGroup.get('Id')) {
      case 1:
        return 'https://fujihelp.gooten.com/hc/en-us/articles/4519629744795-How-do-I-set-up-my-account-';
      case 2:
        return 'https://sensariahelp.gooten.com/hc/en-us/articles/7612130756251-How-do-I-set-up-my-account-';
      default:
        return 'https://help.gooten.com/hc/en-us/articles/360047740491-Setting-up-my-account';
    }
  };

  render() {
    this.inputs = {};
    return (
      <div>
        {this.removeMemberDialog()}
        <BaseCard card={YourTeam.name} title="Your team">
          <div className="your-team-card">
            {this.props.data.size > 0 ? (
              <div className="row hidden-sm hidden-xs">
                <div className="col-xs-12">
                  <p className="card-description">
                    These are the current users attached to your account. Team member permissions
                    are defined via&nbsp;
                    <a
                      href={this.getSettingUpMyAccountKbLink()}
                      target="_blank"
                      className="settings-link"
                    >
                      these roles.
                    </a>
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <p className="card-description">
                    You’re the boss of this page! When a team member accepts your invitation they’ll
                    show up here. You can also alter their permissions, so they only see what you
                    need them to.
                  </p>
                </div>
              </div>
            )}
            <div className="row">
              {this.props.data.map((item, index) => this.renderItem(item, index === 0))}
            </div>
          </div>
        </BaseCard>
      </div>
    );
  }
}

YourTeam.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(List),
  updateAccess: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  router: PropTypes.object,
  location: PropTypes.object,
  ready: PropTypes.bool
};

export default YourTeam;
