const countryCodeFixesMap = {
  GB: 'UK'
};

export const sanitizeCountryCode = countryCode => {
  if (countryCode) {
    const fixedCountryCode = countryCodeFixesMap[countryCode.trim().toUpperCase()];
    return fixedCountryCode || countryCode;
  }
};
