import React from 'react';
import PropTypes from 'prop-types';
import './Section.scss';

export const Section = props => (
  <div className="component-section">
    <div className="title">{props.title}</div>
    <div className="content">{props.content}</div>
  </div>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node
};

Section.displayName = 'Section';

export default Section;
