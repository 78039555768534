import { Record, List, Map } from 'immutable';
import { getSmallPreviewImageResizerUrl } from 'gooten-js-utils/src/url';

const OrderItemImage = new Record({
  spaceId: '',
  imageUrl: '',
  imageId: 0
});

const OrderItem = new Record({
  orderId: 0,
  orderItemId: 0,
  productId: 0,
  productName: '',
  productInfo: '',
  thumbnailUrl: '',
  sku: '',
  quantity: 0,
  status: '',
  price: 0.0,
  formattedPrice: '',
  meta: new Map(),
  images: new List(),
  IsReOrderable: null
});

export default OrderItem;

export const mapOrderItem = orderItem =>
  new OrderItem({
    orderId: orderItem.OrderId,
    orderItemId: orderItem.OrderItemId,
    productId: orderItem.ProductId,
    productName: orderItem.Product,
    productInfo: orderItem.Options.sort((a, b) => b.SortValue < a.SortValue)
      .map(option => option.Value)
      .join(', '),
    thumbnailUrl:
      orderItem.Images && orderItem.Images.length && orderItem.Images[0].SourceUrl
        ? // NOTE: using getSmallPreviewImageResizerUrl since it is same url
          // as for preview generation - so browser will cache it
          getSmallPreviewImageResizerUrl(orderItem.Images[0].SourceUrl)
        : null,
    sku: orderItem.Sku,
    quantity: orderItem.Quantity,
    status: orderItem.Status,
    price: orderItem.Price.Price,
    formattedPrice: orderItem.Price.FormattedPrice,
    meta: new Map(orderItem.MetaJson ? JSON.parse(orderItem.MetaJson) : {}),
    images: new List(
      orderItem.Images.map(
        i =>
          new OrderItemImage({
            spaceId: i.SpaceId,
            imageUrl: i.SourceUrl,
            imageId: i.RecipeImageId
          })
      )
    ),
    IsReOrderable: orderItem.IsReOrderable
  });
