import Log from 'gooten-components/src/services/logService';

class AddToCartService {
  filterUnorderableSKUs(skus, variants, possibleVendors) {
    const orderableVariants = variants
      .filter(variant =>
        possibleVendors.some(vendorId =>
          variant.vendors
            .filter(v => v.orderable)
            .map(vendor => vendor.vendorId)
            .includes(vendorId)
        )
      )
      .map(v => v.sku);
    const unOrderableSKUs = skus.filter(s => !~orderableVariants.indexOf(s));

    if (unOrderableSKUs.length) {
      Log.warn(`These SKUs can not be ordered: ${unOrderableSKUs}`);
      Log.report('Add to cart failed: Unorderable SKUs', {
        skus,
        unOrderableSKUs
      });
    }

    return unOrderableSKUs;
  }
}

export default new AddToCartService();
