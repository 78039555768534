import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Tooltip from './Tooltip.js';
import Icon from './Icon.js';

const Chip = ({
  className,
  circle,
  children,
  onClick,
  noTooltip,
  statusNumber,
  iconLeft,
  iconRight
}) => {
  const getCircleColor = circleColor => {
    switch (circleColor) {
      case 'red':
        return COLORS.cayennePepper;
      case 'blue':
        return COLORS.gootenBlue;
      case 'green':
        return COLORS.monstera;
      case 'yellow':
        return COLORS.bananaPeel;
      default:
        return COLORS.neutralDark500;
    }
  };

  const getStatusInfo = number => {
    switch (number) {
      case 27:
        return {
          Name: 'AddressIssue',
          color: COLORS.cayennePepper,
          Description: 'Order needs your attention, there is an issue with the shipping address'
        };
      case 31:
        return {
          Name: 'Hold',
          color: COLORS.cayennePepper,
          Description: 'Order is on hold'
        };
      case 13:
        return {
          Name: 'HoldByCIImageIssue',
          color: COLORS.cayennePepper,
          Description: 'Order is delayed due to an item grouped in the order having an image issue'
        };
      case 12:
        return {
          Name: 'ImageIssue',
          color: COLORS.cayennePepper,
          Description:
            'Order requires action, the print image has an issue which is blocking printing, please update the image'
        };
      case 29:
        return {
          Name: 'PaymentIssue',
          color: COLORS.cayennePepper,
          Description:
            'Order needs your attention, there was an error processing your payment method'
        };
      case 25:
        return {
          Name: 'ShippingIssue',
          color: COLORS.cayennePepper,
          Description:
            'Order needs your attention due to a shipping issue, check the tracking link for details'
        };
      case 20:
        return {
          Name: 'VendorAPIIssue',
          color: COLORS.cayennePepper,
          Description:
            'Order is delayed due to a production issue, Gooten will contact you if additional information is needed'
        };
      case 32:
        return {
          Name: 'NeedsManualApproval',
          color: COLORS.bananaPeel,
          Description:
            '<div>Order is being manually reviewed to ensure quality.  To learn more visit our <a href="https://help.gooten.com/hc/en-us/articles/360047746151" target="_blank">knowledge base</a>.</div>'
        };
      case 7:
        return {
          Name: 'Pending',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and is currently in a wait period'
        };
      case 11:
        return {
          Name: 'InProduction',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and in production'
        };
      case 19:
        return {
          Name: 'ManuallyInProduction',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and in production'
        };
      case 22:
        return {
          Name: 'Printing',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and is currently being printed'
        };
      case 15:
        return {
          Name: 'Test',
          color: COLORS.gootenBlue,
          Description: null
        };
      case 34:
        return {
          Name: 'Cancelled',
          color: COLORS.neutralDark200,
          Description: 'Order is no longer active and has been cancelled'
        };
      case 33:
        return {
          Name: 'CancelledRefunded',
          color: COLORS.neutralDark200,
          Description: 'Order is no longer active, you and your customer have been refunded'
        };
      case 9:
        return {
          Name: 'VendorCancelled',
          color: COLORS.neutralDark200,
          Description: 'Order is no longer active and has been cancelled'
        };
      case 1:
        return {
          Name: 'Shipped',
          color: COLORS.monstera,
          Description: 'Order has shipped and a tracking link is now available.'
        };
      case 24:
        return {
          Name: 'OutForDelivery',
          color: COLORS.gootenBlue,
          Description:
            "Order is on schedule, it's been produced and packed and is awaiting pickup by the courier"
        };
      case 23:
        return {
          Name: 'Delivered',
          color: COLORS.monstera,
          Description: 'Order has been successfully delivered'
        };
      case 30:
        return {
          Name: 'PrePayment',
          color: COLORS.gootenBlue,
          Description:
            'Order is on schedule, PayPal was selected as payment method and the transaction has not been completed'
        };
      case 18:
        return {
          Name: 'ReadyForAccounting',
          color: COLORS.gootenBlue,
          Description:
            'Order is on schedule, payment is undergoing a security check and will be processed after passing'
        };
      case 28:
        return {
          Name: 'PaymentProcessing',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and payment is being processed'
        };
      case 26:
        return {
          Name: 'ReadyForImageDl',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule, your print images are being downloaded and processed'
        };
      case 14:
        return {
          Name: 'New',
          color: COLORS.gootenBlue,
          Description: null
        };
      case 8:
        return {
          Name: 'ReadyForPrint',
          color: COLORS.gootenBlue,
          Description: 'Order is on schedule and will be sent to manufacturer shortly'
        };
      case 35:
        return {
          Name: 'NeedsPersonalization',
          color: COLORS.bananaPeel,
          Description: 'Order requires personalization before production starts'
        };
      case 36:
        return {
          Name: 'InDigitization',
          color: COLORS.bananaPeel,
          Description: 'Your artwork is being digitized'
        };
      case 37:
        return {
          Name: 'NeedsDigitizationApprove',
          color: COLORS.bananaPeel,
          Description: 'Artwork is digitized for embroidery and requires your approval.'
        };
      case 21:
        return {
          Name: 'BilledRefunded',
          color: COLORS.neutralDark200,
          Description: 'Order is no longer active and you have been refunded'
        };
      case 38:
        return {
          Name: 'OrderDataIssue',
          color: COLORS.cayennePepper,
          Description:
            'Order requires action, there is some missing data that needs to be rectified'
        };
      case 39:
        return {
          Name: 'DiscontinuedItem',
          color: COLORS.cayennePepper,
          Description:
            '<div>Product is no longer available and cannot be fulfilled. Replace or remove the item to resume order fulfillment.<br><a href="https://help.gooten.com/hc/en-us/articles/360051971751-Out-of-Stock-Updates#h_01EQ11AT2TTM109RGD9RW41WKT" target="_blank">Learn More</a><div>'
        };
      case 40:
        return {
          Name: 'OutOfStockItem',
          color: COLORS.cayennePepper,
          Description:
            '<div>The order requires action; an order item is out of stock. To process the order, <a href="https://help.gooten.com/hc/en-us/articles/360054563632-Change-SKUs-for-out-of-stock-products-in-your-order" target="_blank">change</a> or <a href="https://help.gooten.com/hc/en-us/articles/360047288712-Cancel-or-Change-an-Order#h_01EPFJ7JJCS8EDSKR0PB4RWNN1" target="_blank">cancel</a> the affected SKU, or <a href="https://help.gooten.com/hc/en-us/requests/new" target="_blank">contact partner support</a> for assistance.</div>'
        };
      case 41:
        return {
          Name: 'NeedsDigitization',
          color: COLORS.bananaPeel,
          Description: '<div>Order requires digitization</div>'
        };
      case 42:
        return {
          Name: 'HoldByCIDigitization',
          color: COLORS.cayennePepper,
          Description:
            '<div>Order is delayed due to an item grouped in the order being digitized</div>'
        };
      case 'FakeReadyToPrint':
        return {
          Name: 'SendToProduction',
          color: COLORS.gootenBlue,
          Description: 'Ready to send to production'
        };
      default:
        return null;
    }
  };

  const chipBody = (
    <div onClick={onClick} className={`${className || ''} chip button-1`}>
      {(getStatusInfo(statusNumber) || circle) && <div className="circle mr-1 ml-n1" />}
      {iconLeft && <Icon icon={iconLeft} className="ml-n2" small />}
      {getStatusInfo(statusNumber) ? getStatusInfo(statusNumber).Name : children}
      {iconRight && <Icon icon={iconRight} className="mr-n2" small />}
      <style jsx>
        {`
          .chip {
            border-radius: 16px;
            height: 1.5rem;
            padding: 0 0.75rem;
            width: fit-content;
            color: ${COLORS.neutralDark500};
            background: ${COLORS.neutralLight200};
            font-weight: 800;
            display: flex;
            align-items: center;
          }
          :global(.cerulean-tooltip) {
            max-width: 13rem !important;
          }
          .circle {
            background-color: ${getStatusInfo(statusNumber)
              ? getStatusInfo(statusNumber).color
              : getCircleColor(circle)};
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
          }
          .default {
            color: ${COLORS.neutralDark500};
            background: ${COLORS.neutralLight200};
          }
          .green {
            color: ${COLORS.monstera600};
            fill: ${COLORS.monstera600};
            background: ${COLORS.monstera200};
          }
          .blue {
            color: ${COLORS.gootenBlue};
            fill: ${COLORS.gootenBlue};
            background: ${COLORS.gootenBlue200};
          }
          .red {
            color: ${COLORS.cayennePepper600};
            fill: ${COLORS.cayennePepper600};
            background: ${COLORS.cayennePepper200};
          }
          .yellow {
            color: ${COLORS.bananaPeel600};
            fill: ${COLORS.cayennePepper600};
            background: ${COLORS.bananaPeel200};
          }
        `}
      </style>
    </div>
  );

  return (
    <>
      {noTooltip ? (
        chipBody
      ) : (
        <Tooltip
          content={getStatusInfo(statusNumber) ? getStatusInfo(statusNumber).Description : ''}
        >
          {chipBody}
        </Tooltip>
      )}
    </>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  circle: PropTypes.oneOf(['blue', 'green', 'red', 'yellow', 'default', '']),
  children: PropTypes.any,
  statusNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noTooltip: PropTypes.bool,
  onClick: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string
};

export default Chip;
