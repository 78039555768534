import React from 'react';
import { COLORS } from '../../constants.js';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
import Icon from './Icon.js';

const Button = ({
  children,
  className = 'button-default large',
  disabled = false,
  iconOnly = false,
  type = 'button',
  round = false,
  onClick,
  id,
  loading,
  iconLeft,
  iconRight,
  loaderColor,
  dataEvent,
  form
}) => {
  return (
    <button
      data-event={
        dataEvent ||
        (typeof children === 'string'
          ? children
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/\s+/g, '-')
              .toLowerCase()
          : null)
      }
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${className || ''} ${disabled ? 'disabled' : ''} ${
        typeof children === 'string' ? children.replace(/\s+/g, '-').toLowerCase() : ''
      }`}
      id={id}
      form={form}
    >
      <div className="button-1">
        <span className="height-setter">&nbsp;</span>
        {iconLeft && (
          <Icon
            className={`icon-left ${!className.includes('large') ? 'mr-1 ml-n1' : 'ml-n2'}`}
            small={className.includes('small')}
            icon={iconLeft}
          />
        )}
        {children}
        {iconRight && (
          <Icon
            className={`${!className.includes('large') ? 'ml-1 mr-n1' : 'ml-2 mr-n2'}`}
            small={className.includes('small')}
            icon={iconRight}
          />
        )}
      </div>
      {loading && <LoadingSpinner color={loaderColor} />}

      <style jsx>
        {`
          .height-setter {
            width: 0px;
          }
          button {
            border: none;
            outline: none;
            border-radius: ${round ? '50%' : '4px'};
            font-size: 0.8125rem !important;
            width: fit-content;
            transition-duration: .3s;
            cursor: pointer;
            position: relative;
            font-family: Avenir-Roman, Avenir Heavy, sans-serif;
          }
          button div {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            color: ${loading ? '#ffffff00' : 'inherit'}
          }
          button :global(svg) {
            margin-right: ${iconOnly ? '0' : '.5rem'};
          }
          button.large :global(svg), button.extra-large :global(svg)  {
            margin-left: ${iconOnly ? '0' : '-.5rem'};
          }
          .extra-large {
            height: 3rem;
            padding: ${iconOnly ? '0' : '0 1rem'};
            width: ${iconOnly ? '3rem' : 'auto'};
          }
          .large {
            height: 2.5rem;
            padding: ${iconOnly ? '0' : '0 1rem'};
            width: ${iconOnly ? '2.5rem' : 'auto'};
          }
          .medium {
            height: 2rem;
            padding: ${iconOnly ? '0' : '0 .5rem'};
            width: ${iconOnly ? '2rem' : 'auto'};
          }
          .small {
            height: 1.5rem;
            padding: ${iconOnly ? '0' : '0 0.5rem'};
            width: ${iconOnly ? '1.5rem' : 'auto'};
          }
          button.small :global(svg) {
            margin: ${iconOnly ? '0' : '0 .25rem 0 -.25rem'}
          }
          .full-width {
            min-width: 100%;
          }

          .button-default {
            color: ${COLORS.neutralDark500} !important;
            fill: ${COLORS.neutralDark200};
            background: ${COLORS.neutralLight200};
          }
          .button-default:hover {
            background: ${COLORS.neutralLight300};
          }

          .button-overlay {
            background: ${COLORS.overlay300}
          }

          .button-primary {
            color: ${COLORS.neutralLight50} !important;
            fill: ${COLORS.neutralLight50};
            background: ${COLORS.gootenBlue500};
          }
          .button-primary:hover {
            background: ${COLORS.gootenBlue600};
          }
          .button-secondary {
            color: ${COLORS.gootenBlue500} !important;
            fill: ${COLORS.gootenBlue500};
            background: ${COLORS.gootenBlue200};
          }
          .button-secondary:hover {
            background: ${COLORS.gootenBlue300};
          }
          .button-destructive {
            color: ${COLORS.neutralLight50} !important;
            fill: ${COLORS.neutralLight50};
            background: ${COLORS.cayennePepper};
          }
          .button-destructive:hover {
            background: ${COLORS.cayennePepper600};
          }
          .button-cta {
            color: ${COLORS.neutralLight50} !important;
            fill: ${COLORS.neutralLight50};
            background: ${COLORS.coral500};
          }
          .button-cta:hover {
            background: ${COLORS.coral600};
          }
          .button-white {
            color: ${COLORS.neutralDark500} !important;
            fill: ${COLORS.neutralDark200};
            background: ${COLORS.neutralLight50};
          }
          .button-white:hover {
            background: ${COLORS.neutralLight300};
          }
          .disabled {
            color: ${COLORS.neutralLight600} !important;
            fill: ${COLORS.neutralLight600};
            background: ${COLORS.neutralLight50};
            cursor: not-allowed !important;
            pointer-events: none;
            border: 1px solid ${COLORS.neutralLight200}
          }
          .border {
            border: 1px solid ${COLORS.neutralLight300};
          }
          .border-selected {
            border: 1px solid ${COLORS.gootenBlue};
          }
          .outline-secondary {
            outline-offset: 2px;
            outline: 2px solid ${COLORS.gootenBlue300};
          }

          @media (max-width: 768px) {
            .sm-media-full {
              min-width: 100%;
            }
        `}
      </style>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  round: PropTypes.bool,
  iconOnly: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  loading: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  dataEvent: PropTypes.string,
  form: PropTypes.string
};

export default Button;
