import { injectSaga } from '../store/sagas';
import storeBulkMigrationSaga from './StoreBulkMigration/StoreBulkMigrationSaga';
import storeBulkCreationSaga from './StoreBulkCreation/StoreBulkCreationSaga';

const registerAdminSagas = () => {
  injectSaga(storeBulkMigrationSaga);
  injectSaga(storeBulkCreationSaga);
};

export default registerAdminSagas;
