import { all } from 'redux-saga/effects';
import { watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import InviteTeamMember from './InviteTeamMember';
import analyticsService from 'gooten-components/src/services/analyticsService';

const onSuccess = (state, payload) => {
  analyticsService.track('Settings - Team', 'Team member invited', 'Settings');
};

export default function* rootSaga() {
  yield all([watchUpdateAsync(InviteTeamMember.name, InviteTeamMember.messages, false, onSuccess)]);
}
