import { all, take, takeLatest, call, put, select } from 'redux-saga/effects';
import { watchInitAsync, watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import { initFor, initActionNameFor } from '../BaseCard/BaseCardActions';
import EmailPreferences from './EmailPreferences';
import settingsApiService from '../../SettingsApiService';
import analyticsService from 'gooten-components/src/services/analyticsService';
import { partnerUserIdSelector } from '../../SettingsSelectors';
import {
  UPDATE_OCCASIONAL_EMAILS,
  ADD_FULFILLMENT_CONTACT_EMAIL,
  DELETE_FULFILLMENT_CONTACT_EMAIL,
  ENABLE_FULFILLMENT_CONTACT_EMAIL,
  DISABLE_FULFILLMENT_CONTACT_EMAIL
} from './EmailPreferencesActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';

const onOff = val => (val ? 'on' : 'off');

const enabledOccasionalEmailsModel = {
  emailsProductCatalog: true,
  emailsWebinarEvents: true,
  emailsNewFeatures: true
};

const disabedOccasionalEmailsModel = {
  emailsProductCatalog: false,
  emailsWebinarEvents: false,
  emailsNewFeatures: false
};

const onSuccess = (state, payload) => {
  if (
    state.getIn(['data', 'ReceiveOccasionalEmails']) !==
    state.getIn(['unmodified', 'ReceiveOccasionalEmails'])
  ) {
    analyticsService.track(
      'Settings - Email Preferences',
      `Occasional email toggle ${onOff(state.getIn(['data', 'ReceiveOccasionalEmails']))}`,
      'Settings'
    );
  }
  if (
    state.getIn(['data', 'ReceiveConfirmationShippingEmails']) !==
    state.getIn(['unmodified', 'ReceiveConfirmationShippingEmails'])
  ) {
    analyticsService.track(
      'Settings - Email Preferences',
      `Shipping notification toggle ${onOff(
        state.getIn(['data', 'ReceiveConfirmationShippingEmails'])
      )}`,
      'Settings'
    );
  }
};

export function* updateOccasionalEmailsAsyncHandler(action) {
  let result = null;
  try {
    const userId = yield select(partnerUserIdSelector);
    const occasionalEmailsModel = action.payload.enabled
      ? enabledOccasionalEmailsModel
      : disabedOccasionalEmailsModel;

    result = yield call(settingsApiService.updateOccasionalEmails, userId, action.payload.enabled);
    analyticsService.track(
      'Save',
      'Updated Email Preferences',
      'Settings',
      JSON.stringify(occasionalEmailsModel)
    );
    analyticsService.identify({
      email: result.Email,
      name: result.Name,
      partnerName: result.PartnerName,
      ...occasionalEmailsModel
    });
    yield put(fetchAsyncSuccess(UPDATE_OCCASIONAL_EMAILS, action.payload));
    yield put(push(EmailPreferences.messages.onSuccess, 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE_OCCASIONAL_EMAILS, err));
    yield put(push(EmailPreferences.messages.onFailure, 'failure'));
  }
}

export function* watchUpdateOccasionalEmails() {
  yield takeLatest(UPDATE_OCCASIONAL_EMAILS.ASYNC, updateOccasionalEmailsAsyncHandler);
}

function* fulfillmentContactEmailsHandler(action) {
  try {
    switch (action.type) {
      case ADD_FULFILLMENT_CONTACT_EMAIL.ASYNC:
        yield call(settingsApiService.addFulfillmentContactEmail, action.payload.email);
        break;

      case DELETE_FULFILLMENT_CONTACT_EMAIL.ASYNC:
        yield call(settingsApiService.deleteFulfillmentContactEmail, action.payload.email);
        break;

      case DISABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC:
        yield call(settingsApiService.disableFulfillmentContactEmail, action.payload.email);
        break;

      case ENABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC:
        yield call(settingsApiService.enableFulfillmentContactEmail, action.payload.email);
        break;
    }
    yield put(initFor(EmailPreferences.name)());
    // wait until the data is loaded, succesfully or not
    yield take([
      initActionNameFor(EmailPreferences.name).SUCCESS,
      initActionNameFor(EmailPreferences.name).FAIL
    ]);
    yield put(push(EmailPreferences.messages.onSuccess, 'success'));
  } catch (err) {
    yield put(push(EmailPreferences.messages.onFailure, 'failure'));
  }
}

export function* watchFullfillmentContactEmails() {
  yield takeLatest(
    [
      ADD_FULFILLMENT_CONTACT_EMAIL.ASYNC,
      DELETE_FULFILLMENT_CONTACT_EMAIL.ASYNC,
      DISABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC,
      ENABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC
    ],
    fulfillmentContactEmailsHandler
  );
}

export default function* rootSaga() {
  yield all([
    watchUpdateOccasionalEmails(),
    watchFullfillmentContactEmails(),
    watchInitAsync(EmailPreferences.name, EmailPreferences.messages, true),
    watchUpdateAsync(EmailPreferences.name, EmailPreferences.messages, true, onSuccess)
  ]);
}
