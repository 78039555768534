import { Map, Record } from 'immutable';
import { DEACTIVATE, DOWNLOAD_DATA } from './AdvancedSettingsActions';
import AdvancedSettings from './AdvancedSettings';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import { changeActionNameFor } from '../BaseCard/BaseCardActions';

const Data = new Record({ Email: '', Password: '' });

const change = changeActionNameFor(AdvancedSettings.name);
export default baseCardReducer(
  AdvancedSettings.name,
  (
    state = new Map({
      canDeactivate: true,
      canDownloadData: true,
      isPristine: true,
      data: new Data(),
      loading: false,
      busy: false,
      open: false
    }),
    action
  ) => {
    switch (action.type) {
      case DEACTIVATE.ASYNC:
        return state.set('canDeactivate', false).set('busy', true);
      case DEACTIVATE.FAIL:
        return state.set('canDeactivate', true).set('isPristine', true).set('busy', false);
      case DEACTIVATE.SUCCESS:
        return state.set('data', new Data()).set('isPristine', true).set('busy', false);
      case DOWNLOAD_DATA.ASYNC:
        return state.set('canDownloadData', false).set('busy', true);
      case DOWNLOAD_DATA.FAIL:
        return state.set('canDownloadData', true).set('busy', false);
      case DOWNLOAD_DATA.SUCCESS:
        return state.set('busy', false);
      case change:
        return state.set('isPristine', false);
      default:
        return state;
    }
  }
);
