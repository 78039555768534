import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import TaxExemption from './TaxExemption';
import { TaxExemptionModel } from '../../Models/TaxExemptionModel';

export default baseCardReducer(
  TaxExemption.name,
  (
    state = new Map({
      data: new TaxExemptionModel(),
      loading: true,
      open: true
    })
  ) => state,
  TaxExemptionModel
);
