import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import AddToCartRequest from '../../models/AddToCartRequest';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_CART', 'CART_VIEW');

// ------------------------------------
// Action Types
// ------------------------------------
export const ADD_TO_CART_REQUEST = getActionName.async('ADD_TO_CART_REQUEST');
export const ADD_TO_CART_REQUEST_PRODUCT_ADDED = getActionName('ADD_TO_CART_REQUEST_PRODUCT_ADDED');
export const UPDATE_LOADING_STATUS = getActionName('UPDATE_LOADING_STATUS');
export const UPDATE_SOURCE = getActionName('UPDATE_SOURCE');
export const UPDATE_REQUEST_DATA = getActionName('UPDATE_REQUEST_DATA');

// ------------------------------------
// Actions
// ------------------------------------
export function addToCartRequestAsync(request = new AddToCartRequest(), source = null) {
  return {
    type: ADD_TO_CART_REQUEST.ASYNC,
    payload: { request, source }
  };
}

export function addToCartRequestProductAdded(productId = 0) {
  return {
    type: ADD_TO_CART_REQUEST_PRODUCT_ADDED,
    payload: { productId }
  };
}

export function updateLoadingStatus(status = 'initial') {
  return {
    type: UPDATE_LOADING_STATUS,
    payload: { status }
  };
}

export function updateSource(source = '') {
  return {
    type: UPDATE_SOURCE,
    payload: { source }
  };
}

export function updateRequestData(products) {
  return {
    type: UPDATE_REQUEST_DATA,
    payload: { products }
  };
}
