import { takeLatest, put, call, all } from 'redux-saga/effects';
import { RESEND_INVITE, REMOVE_INVITE } from './PendingInvitationsActions';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import YourTeam from '../YourTeam/YourTeam';
import { initFor, updateActionNameFor } from '../BaseCard/BaseCardActions';
import InviteTeamMember from '../InviteTeamMember/InviteTeamMember';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';

const inviteTeamMemberAction = updateActionNameFor(InviteTeamMember.name);
const reloadAction = initFor(YourTeam.name, YourTeam.messages);

export function* resendInviteAsyncHandler(action) {
  try {
    yield call(settingsApiService.resendInvite, action.payload);
    yield put(fetchAsyncSuccess(RESEND_INVITE, action.payload));
    yield put(push('Invite resent.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(RESEND_INVITE, err));
    yield put(push('Failed to resend invite.', 'failure'));
  }
}

export function* watchResendInvite() {
  yield takeLatest(RESEND_INVITE.ASYNC, resendInviteAsyncHandler);
}

export function* removeInviteAsyncHandler(action) {
  try {
    yield call(settingsApiService.removeInvite, action.payload);
    yield put(fetchAsyncSuccess(REMOVE_INVITE, action.payload));
    yield put(push('Invite removed.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(REMOVE_INVITE, err));
    yield put(push('Failed to remove invite.', 'failure'));
  }
}

export function* watchRemoveInvite() {
  yield takeLatest(REMOVE_INVITE.ASYNC, removeInviteAsyncHandler);
}

export function* reloadAsyncHandler(action) {
  yield put(reloadAction());
}

export function* watchNewInvite() {
  yield takeLatest(inviteTeamMemberAction.SUCCESS, reloadAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchResendInvite(), watchRemoveInvite(), watchNewInvite()]);
}
