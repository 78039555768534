import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Toast from '../../../shared/Toast';

export const ToastMessage = props => {
  useEffect(() => {
    setTimeout(() => {
      props.clearToast();
    }, 5000);
  });

  return (
    <>
      <div className="toast-container">
        <Toast className="mt-3" isOpen={true} closeClick={() => props.clearToast()}>
          {props.toastMessage}
        </Toast>
      </div>
      <style jsx>
        {`
          .toast-container {
            position: absolute;
            top: 0.5rem;
            right: 0;
            left: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 1010;
          }
        `}
      </style>
    </>
  );
};

ToastMessage.propTypes = {
  toastMessage: PropTypes.string,
  clearToast: PropTypes.func
};

export default ToastMessage;
