import templateInitializationService from '../services/templateInitializationService';
import { getEditorImageResizerUrl } from 'gooten-js-utils/src/url';
import { Record, List, fromJS } from 'immutable';

export const VariantSpace = new Record({
  id: undefined,
  name: undefined,
  images: new List(),
  template: undefined,
  il: undefined,
  dumpedState: undefined,
  base64: undefined,
  previewImgManipCmd: undefined,
  printImgManipCmd: undefined,
  smallPrintImgManipCmd: undefined,
  previewImgUrl: undefined,
  printImgUrl: undefined,
  smallPrintImgUrl: undefined,
  smallPreviewImageUrl: undefined,
  // used in COF for comparsion to check whether prp image was edited
  prpIL: undefined
});

export const mapVariantSpace = (spaceObj, variantTemplate, existingSpace = {}) => {
  // This is final map space happens after templates were loaded
  // so we can compare our preconfiguration with fresh templates.
  //
  // Existing space will exists in case of editing or re-ordering existing product.
  // It will contains print ready images by default and IL if available
  // If IL is available we will replace print ready images with original images from IL
  // to allow editor restore it state from IL.
  // Use fresh template image layer id
  const freshImgLayerId = spaceObj.Layers.find(l => l.Type === 'Image').Id;
  let images = existingSpace.images || [];
  if (existingSpace.il && existingSpace.il.layers) {
    // There is a possibility that template was changed and IL is outdated.
    // But we still going to re-use it's images - since print ready image may not be generated yet.
    // So far this is the only concern of not using print-ready images if IL is outdated.

    // NOTE: In case if il exists - layerId, width, height should be specified
    // and imageUrl should be resized to 1000x1000 - editor size
    const imageLayers = existingSpace.il.layers.filter(l => l.images);
    imageLayers.forEach(l =>
      l.images.forEach(i => {
        i.url = getEditorImageResizerUrl(i.url);
      })
    );
    // Override print ready image url with original image URL and correct sizes.
    // We use original image here since we have IL and editor will restore it.
    // If IL is outdated and ignored it will do initial positioning
    // according to product rules.
    //
    images = Array.prototype.concat(
      ...images.map(im =>
        Array.prototype.concat(
          ...imageLayers.map(l =>
            l.images.map(li => ({
              layerId: freshImgLayerId,
              imageUrl: li.url,
              imageId: im.imageId,
              width: li.realSourceWidth,
              height: li.realSourceHeight
            }))
          )
        )
      )
    );
  }

  // NOTE: We can't re-use IL if template was changed.
  // We know that template was changed if space id's doesn't match
  let canUseIL = existingSpace.il && existingSpace.il.spaceId === spaceObj.Id;
  if (existingSpace.il && existingSpace.il.layers && !canUseIL) {
    const ilImageLayerId = existingSpace.il.layers.find(l => l.images).layerId;
    // But spaceId will change if new layers were added
    // So we try match by layer Id
    if (ilImageLayerId === freshImgLayerId) {
      console.warn('Restoring IL since layer id match');
      canUseIL = true;
    }
  }

  return new VariantSpace({
    id: spaceObj.Id,
    name: spaceObj.Description || spaceObj.Id,
    images: new List(images),
    il: canUseIL ? existingSpace.il : null,
    // used in COF for comparsion to check whether prp image was edited
    prpIL: existingSpace.prpIL,
    template: fromJS(
      templateInitializationService.prepareSpaceTemplate(variantTemplate, spaceObj.Id)
    )
  });
};

export const mapExistingVariantSpace = spaceObj =>
  new VariantSpace({
    ...spaceObj,
    template: fromJS(spaceObj.template),
    images: new List(spaceObj.images)
  });
