import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import EditDesignItem from '../../models/EditDesignItem';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_EDIT_DESIGN', 'EDIT_DESIGN_VIEW');

// ------------------------------------
// Action Types
// ------------------------------------
export const EDIT_DESIGN = getActionName('EDIT_DESIGN');
export const UPDATE_PRODUCT_ID = getActionName('UPDATE_PRODUCT_ID');
export const UPDATE_LOADING_STATUS = getActionName('UPDATE_LOADING_STATUS');
export const INIT_EDIT_WITH_PRP_SKU = getActionName('INIT_EDIT_WITH_PRP_SKU');

// ------------------------------------
// Actions
// ------------------------------------
export function editDesign(item = new EditDesignItem()) {
  return {
    type: EDIT_DESIGN,
    payload: { item }
  };
}

export function updateProductId(productId) {
  return {
    type: UPDATE_PRODUCT_ID,
    payload: { productId }
  };
}

export function updateLoadingStatus(status) {
  return {
    type: UPDATE_LOADING_STATUS,
    payload: { status }
  };
}

export const initEditWithPrpSku = prpSku => ({
  type: INIT_EDIT_WITH_PRP_SKU,
  payload: prpSku
});
