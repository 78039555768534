import { combineReducers } from 'redux-immutable';
import productSelectionViewReducer from './ProductSelectionView/ProductSelectionViewReducer';
import SKUsSelectionViewReducer from './SKUsSelectionView/SKUsSelectionViewReducer';
import productDesignViewReducer from './ProductDesignView/ProductDesignViewReducer';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

export default combineReducers({
  productSelectionView: productSelectionViewReducer,
  SKUsSelectionView: SKUsSelectionViewReducer,
  productDesignView: productDesignViewReducer
});
