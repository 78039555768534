import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from '../../../shared/SearchInput';

export const SearchBar = props => {
  return (
    <div className="search-bar-container d-flex align-items-end">
      <SearchInput searchText={props.searchText} onSearchChange={props.doSearch} />
      {props.showTitle && props.searchText && props.searchText.length && (
        <div className="search-result-title">
          <p className="title-2 mt-3">Search Result for "{props.searchText}"</p>
        </div>
      )}
      <style jsx>
        {`
          .search-bar-container {
            flex-direction: column;
          }
          .search-bar-container :global(.search-input-container) {
            width: calc(100% - 17rem);
            margin-top: 4.5rem;
          }
          @media screen and (max-width: 991px) {
            .search-bar-container :global(.search-input-container) {
              width: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

SearchBar.propTypes = {
  doSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  showTitle: PropTypes.bool
};

export default SearchBar;
