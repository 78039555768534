import { Record, List } from 'immutable';

// Hold data for 1 store
const Store = new Record({
  id: 0, // RecipeIntegrationId
  storeName: '', // Real store name - needed in case of Shopify, old Admin use it in URL
  nickName: '', // Partner custom name for his store, display it
  provider: '', // Shopify, WooCommerce, etc...
  hasProducts: false, // Whether to show connect existing or nots
  hasProductsToLink: false,
  collections: new List(), // store collections
  tags: new List(), // store tags
  settings: undefined // Advanced settings for store
});

export default Store;
