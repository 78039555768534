import { all } from 'redux-saga/effects';
import { watchInitAsync, watchChangePageAsync } from '../BaseCard/BaseCardSaga';

import TaxExemption from './TaxExemption';

export default function* rootSaga() {
  yield all([
    watchInitAsync(TaxExemption.name, TaxExemption.messages),
    watchChangePageAsync(TaxExemption.name)
  ]);
}
