import Config from '../config';

const acl = {
  nav: {
    menu: 3
  },
  page: {
    billing: 2,
    fulfillment: 2,
    team: 2
  }
};

class SecurityService {
  access = (feature, name) =>
    acl[feature] && acl[feature][name] && acl[feature][name] > Config.get('roleLevel');
  isSuperAdmin = () => Config.get('isSuperAdmin');
  isAdmin = () => Config.get('roleLevel') <= 1;
  isVendor = () => Config.get('isVendor');
  isPartner = () => Config.get('isPartner');
  isPartnerAdmin = () => Config.get('isPartnerAdmin');
  isPartnerOwner = () => Config.get('partnerOwnerUserId') === Config.get('partnerUserId');
  getPartnerUserId = () => Config.get('partnerUserId');
  getFeatures = () => (Config.get('features') && Config.get('features').toJS()) || {};
}

export default new SecurityService();
