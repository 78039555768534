import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class BillingService {
  fetchOrderItemsCostsBreakdown(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/getOrderItemsCostsBreakdown`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: payload.orderId
      }
    });
  }

  fetchOrderBilling(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/getOrderBilling`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: payload.orderId
      }
    });
  }
}

export default new BillingService();
