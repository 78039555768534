/* eslint-disable prefer-promise-reject-errors */
import { fromJS } from 'immutable';
import Config from '../config';
import Log from 'gooten-components/src/services/logService';
import { fetchNeckTags } from 'gooten-components/src/services/addOns';
import adminApi from '../services/adminApiService';
import { fetchBlob, postJson } from 'gooten-components/src/utils/http';
import imageService from 'gooten-components/src/components/shared/ImageSelectionModal/services/imageSelectionService';
import { tokenizeCard } from 'gooten-components/src/services/braintree';

const parseBraintreeMessage = message => {
  const errorMatch = message.match(/(avs|cvv|card type|number is invalid)/i);

  switch (errorMatch && errorMatch[0].toLowerCase()) {
    case 'avs':
      return 'Billing address does not match address associated with this card';
    case 'cvv':
      return 'CVV code does not match the card';
    case 'card type':
      return 'Credit card type is not accepted';
    case 'number is invalid':
      return 'Credit card number is invalid';
    default:
      return message;
  }
};

const adminApiAdapter = {
  profileInformation: {
    get: params => `usersApi/PartnerUserProfile/${params.user}`,
    post: params => `usersApi/UpdateProfile/${params.user}`,
    mapTo: data => data,
    mapFrom: (responce, data) => fromJS(responce)
  },
  password: {
    post: params => `usersApi/ChangePassword/${params.user}`,
    mapTo: data => data,
    mapFrom: (responce, data) => fromJS(data)
  },
  creditCard: {
    get: () => `partnersApi/GetPaymentsSettings?partnerId=${Config.get('partnerId')}`,
    mapTo: data => data,
    mapFrom: (responce, data) => fromJS(responce)
  },
  billingAddress: {
    get: () => `partnersApi/GetBillingAddress?partnerId=${Config.get('partnerId')}`,
    post: () => `partnersApi/UpdateBusinessInformation?partnerId=${Config.get('partnerId')}`,
    mapTo: data => ({ ...data, Website: data.Website ? data.Website.toLowerCase() : '' }),
    mapFrom: (responce, data) =>
      fromJS(responce).set('Website', responce.Website ? responce.Website.toLowerCase() : '')
  },
  fulfillmentSettings: {
    get: () => `partnersApi/GetFulfillmentSettings?partnerId=${Config.get('partnerId')}`,
    post: () => `partnersApi/UpdateFulfillmentSettings?partnerId=${Config.get('partnerId')}`,
    mapTo: data => data,
    mapFrom: (responce, data) => fromJS(responce)
  },
  apiKeys: {
    get: () => `partnersApi/getKeySettings?partnerId=${Config.get('partnerId')}`,
    mapFrom: (responce, data) =>
      Config.get('env') === 'live' ? fromJS(responce.Live) : fromJS(responce.Staging)
  },
  webhooks: {
    get: () => `partnersApi/getWebhooksSettings?partnerId=${Config.get('partnerId')}`,
    post: () => `partnersApi/updateWebhooksSettings?partnerId=${Config.get('partnerId')}`,
    mapTo: data => data,
    mapFrom: (responce, data) => fromJS(responce)
  },
  taxExemption: {
    get: () => `partnersApi/GetTaxExemptionSettings?partnerId=${Config.get('partnerId')}`,
    mapFrom: (response, data) => fromJS(response)
  },
  billingHistory: {
    get: () => `partnersApi/GetBillingHistory?partnerId=${Config.get('partnerId')}`,
    mapFrom: (responce, data) => responce
  },
  inviteTeamMember: {
    put: () => `partnersApi/SendInvite?partnerId=${Config.get('partnerId')}`,
    mapTo: data => data,
    mapFrom: (responce, data) => responce
  },
  yourTeam: {
    get: () => `partnersApi/getTeamMemberSettings?partnerId=${Config.get('partnerId')}`,
    mapTo: data => data,
    mapFrom: (responce, data) => ({ ...responce, Records: responce.TeamMembers })
  },
  accountActivity: {
    get: () => `partnersApi/GetAllHistory?partnerId=${Config.get('partnerId')}`,
    mapFrom: (responce, data) => responce
  },
  neckLabels: {
    get: () => fetchNeckTags()
  },
  emailPreferences: {
    get: params =>
      `usersApi/GetUserEmailPreferences/${params.user}?partnerId=${Config.get('partnerId')}`,
    post: params =>
      `partnersApi/updateNotificationSettings?partnerId=${Config.get(
        'partnerId'
      )}&modelType=emailCompanyUser`,
    mapTo: data => data,
    mapFrom: (responce, data) => {
      if (responce.jsonSettings) {
        Log.debug('NOTIFICATION SETTINGS:', responce.jsonSettings);
      }
      return responce;
    }
  },
  emailServices: {
    get: params => `partnersApi/getNotificationSettings?partnerId=${Config.get('partnerId')}`,
    mapFrom: (responce, data) => responce
  }
};

class SettingsApiService {
  get(card, params) {
    const path = adminApiAdapter[card].get(params);

    if (typeof path.then === 'function') {
      return path;
    }

    const queryParams = params.page ? { page: params.page, size: params.pageSize } : {};

    return adminApi.get(path, queryParams).then(res => {
      return adminApiAdapter[card].mapFrom(res);
    });
  }

  update(card, params, data) {
    if (adminApiAdapter[card].post) {
      const path = adminApiAdapter[card].post(params);
      return adminApi.post(path, adminApiAdapter[card].mapTo(data)).then(res => {
        return adminApiAdapter[card].mapFrom(res, data);
      });
    } else if (adminApiAdapter[card].put) {
      const path = adminApiAdapter[card].put(params);
      return adminApi.put(path, adminApiAdapter[card].mapTo(data)).then(res => {
        return adminApiAdapter[card].mapFrom(res, data);
      });
    }
  }

  deactivateAccount(user, data) {
    const path =
      user !== undefined ? `usersApi/RequestDeactivate/${user}` : `usersApi/RequestDeactivate`;

    return adminApi.post(path, data.toJS()).then(res => {
      return data;
    });
  }

  downoadData(user, data) {
    const path =
      user !== undefined ? `usersApi/RequestDownloadData/${user}` : `usersApi/RequestDownloadData/`;

    return adminApi.post(path, data.toJS()).then(res => {
      return data;
    });
  }

  getBraintreeToken() {
    return adminApi.get(`PartnersApi/GetBrainTreeClientToken?partnerId=${Config.get('partnerId')}`);
  }

  updatePayPal(payload, token) {
    const { nonce, braintreeToken, isDefault } = payload;
    return new Promise((resolve, reject) => {
      adminApi
        .put(`PartnersApi/AddPaymentMethod?partnerId=${Config.get('partnerId')}`, {
          PaymentMethodNonce: nonce,
          Token: token,
          MethodType: 'PayPal',
          MakeDefault: isDefault
        })
        .then(
          data => {
            Config.get('clearOrdersCache')();
            resolve(data);
          },
          err => {
            err.json().then(
              r => {
                reject(r);
              },
              e => {
                reject({ msg: 'Failed to update PayPal' });
              }
            );
          }
        );
    });
  }

  updateCreditCard(cardData, token) {
    if (cardData.token) {
      return new Promise((resolve, reject) => {
        adminApi
          .put(
            `PartnersApi/UpdatePaymentMethod?partnerId=${Config.get('partnerId')}&token=${
              cardData.token
            }`,
            {
              paymentMethodType: 'CreditCard',
              nameOnCard: cardData.name,
              expirationMonth: cardData.month,
              expirationYear: cardData.year,
              cvv: cardData.cvv,
              isDefault: cardData.makeDefault,
              billingAddress: {
                line1: cardData.billingAddress.line1,
                line2: cardData.billingAddress.line2,
                city: cardData.billingAddress.city,
                state: cardData.billingAddress.state,
                postalCode: cardData.billingAddress.postalCode,
                countryCode: cardData.billingAddress.country
              },
              captchaToken: cardData.captchaToken
            }
          )
          .then(data => {
            Config.get('clearOrdersCache')();
            resolve(data);
          })
          .catch(err =>
            err
              .json()
              .then(err => reject({ msg: parseBraintreeMessage(err.msg) }))
              .catch(_ => reject({ msg: 'Failed to update Credit Card' }))
          );
      });
    } else {
      // Reuse from paymentService
      return new Promise((resolve, reject) => {
        tokenizeCard(cardData, token)
          .then(res => {
            return adminApi.put(
              `PartnersApi/AddPaymentMethod?partnerId=${Config.get('partnerId')}`,
              {
                PaymentMethodNonce: res.nonce,
                MethodType: 'CreditCard',
                captchaToken: cardData.captchaToken,
                MakeDefault: cardData.makeDefault
              }
            );
          })
          .then(res => {
            Config.get('clearOrdersCache')();
            resolve(res);
          })
          .catch(err => {
            // Check client side braintree error first, e.g. card number invalid
            try {
              reject({ msg: err.details.originalError.error.message });
            } catch {
              // Check admin API server side braintree error
              if (err.json) {
                err
                  .json()
                  .then(err => reject({ msg: parseBraintreeMessage(err.msg) }))
                  .catch(_ => reject({ msg: 'Failed to add Credit Card' }));
              } else {
                reject({ msg: 'Failed to add Credit Card' });
              }
            }
          });
      });
    }
  }

  removePaymentMethod(token) {
    return adminApi
      .post(`PartnersApi/RemovePaymentMethod?partnerId=${Config.get('partnerId')}&token=${token}`)
      .then(response => response);
  }

  updateDefaultPaymentMethod(token) {
    return adminApi
      .post(
        `PartnersApi/UpdateDefaultPaymentMethod?partnerId=${Config.get('partnerId')}&token=${token}`
      )
      .then(res => res);
  }

  getSetupIntentId() {
    return adminApi
      .post(`PaymentGateway/InitPaymentMethod?partnerId=${Config.get('partnerId')}`)
      .then(res => res);
  }

  savePaymentMethod(id, primaryPaymentMethod) {
    return adminApi
      .post(`PaymentGateway/ConfirmPaymentMethod?partnerId=${Config.get('partnerId')}`, {
        paymentMethodId: id,
        preferred: primaryPaymentMethod
      })
      .then(res => res);
  }

  uploadFromUrl(url) {
    return new Promise((resolve, reject) => {
      const onLoad = blob => {
        const uploadUrl = `PartnersApi/UpdateLogo?partnerId=${Config.get(
          'partnerId'
        )}&onlyEmailLogo=true`;
        const parts = url.split('.');
        const ext = parts.pop();
        const name = `${parts.pop().split('/').pop()}.${ext}`;
        adminApi
          .uploadFile(uploadUrl, blob, name)
          .then(res => {
            resolve(res);
          })
          .catch(err => reject(err));
      };
      fetchBlob(url, {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then(onLoad)
        .catch(() => {
          imageService
            .uploadImageFromUrl(url)
            .then(apiData => {
              if (apiData.error) {
                reject();
              } else {
                fetchBlob(apiData.url, {
                  mode: 'cors',
                  headers: {
                    'Access-Control-Allow-Origin': '*'
                  }
                })
                  .then(onLoad)
                  .catch(err => reject(err));
              }
            })
            .catch(err => reject(err));
        });
    });
  }

  sendPasswordResetEmail(userId) {
    return adminApi.post(`UsersApi/SendResetEmail/${userId}`, {}).then(res => res);
  }

  updateOccasionalEmails(userId, enabled) {
    return adminApi
      .post(`UsersApi/UpdateOccasionalEmails/${userId}`, { Send: enabled })
      .then(res => res);
  }

  addFulfillmentContactEmail(email) {
    return adminApi.post(
      `PartnersApi/AddFulfillmentContactEmail?partnerId=${Config.get(
        'partnerId'
      )}&email=${encodeURIComponent(email)}`
    );
  }

  deleteFulfillmentContactEmail(email) {
    return adminApi.delete(
      `PartnersApi/DeleteFulfillmentContactEmail?partnerId=${Config.get(
        'partnerId'
      )}&email=${encodeURIComponent(email)}`
    );
  }

  disableFulfillmentContactEmail(email) {
    return adminApi.put(
      `PartnersApi/DisableFulfillmentContactEmail?partnerId=${Config.get(
        'partnerId'
      )}&email=${encodeURIComponent(email)}`
    );
  }

  enableFulfillmentContactEmail(email) {
    return adminApi.put(
      `PartnersApi/EnableFulfillmentContactEmail?partnerId=${Config.get(
        'partnerId'
      )}&email=${encodeURIComponent(email)}`
    );
  }

  resendInvite(item) {
    return adminApi
      .put(`PartnersApi/ResendInvite?partnerId=${Config.get('partnerId')}`, item.toJS())
      .then(res => {
        return { id: item.get('Id') };
      });
  }

  removeInvite(item) {
    return adminApi
      .delete(
        `PartnersApi/RevokeInvite?partnerId=${Config.get('partnerId')}&inviteId=${item.get('Id')}`
      )
      .then(res => {
        return { id: item.get('Id') };
      });
  }

  updateAccess(item) {
    return adminApi
      .put(
        `PartnersApi/SaveTeamMemberPermissions?partnerId=${Config.get('partnerId')}`,
        item.toJS()
      )
      .then(res => {
        return item;
      });
  }

  removeMember(item) {
    const pId = Config.get('partnerId');
    return adminApi
      .delete(`PartnersApi/RemoveTeamMember?partnerId=${pId}&memberId=${item.get('Id')}`)
      .then(res => {
        return { id: item.get('Id') };
      });
  }

  updateNotificationSettings(data, modelType) {
    return adminApi
      .post(
        `partnersApi/updateNotificationSettings?partnerId=${Config.get(
          'partnerId'
        )}&modelType=${modelType}`,
        data
      )
      .then(res => {
        Log.debug('NOTIFICATION SETTINGS:', res.jsonSettings);
        return res;
      });
  }

  testConnection(data) {
    return adminApi.post(`partnersApi/TestSmtpSettings`, data).then(res => {
      return res;
    });
  }

  testWebhooks(data) {
    return adminApi
      .post(`partnersApi/TestWebhookSettings?partnerId=${Config.get('partnerId')}`, data)
      .then(res => {
        return res;
      });
  }
}

export default new SettingsApiService();
