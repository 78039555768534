import { connect } from 'react-redux';
import CloseButton from './CloseButton';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(null, mapDispatchToProps)(CloseButton);
