import { put, call, all, takeLatest } from 'redux-saga/effects';
import {
  FETCH_ORDERSTATS_REQUEST,
  FETCH_ORDERSTATS_SUCCESS,
  FETCH_ORDERSTATS_FAIL
} from './MainViewActions';
import storageService from 'gooten-components/src/services/storageService';

const apiUrl = '/admin/api/usersapi/getorders';
const authToken = 'Bearer ' + storageService.getItem('token');

export const api = (url, auth) =>
  fetch(url, {
    headers: {
      Authorization: auth
    }
  }).then(response => response.json());

export function* fetchOrderStatsHandler() {
  try {
    const orderStats = yield call(api, apiUrl, authToken);
    yield put({ type: FETCH_ORDERSTATS_SUCCESS, payload: orderStats });
  } catch (error) {
    yield put({ type: FETCH_ORDERSTATS_FAIL, payload: error });
  }
}

export function* watchFetchOrderStats() {
  yield takeLatest(FETCH_ORDERSTATS_REQUEST, fetchOrderStatsHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchFetchOrderStats()]);
}
