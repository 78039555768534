import { Record } from 'immutable';

export const YourTeamModel = new Record({
  Id: '',
  FullName: '',
  IsDisabled: false,
  Email: '',
  IsOwner: false,
  Permissions: []
});
