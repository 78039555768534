import { fetch, post, fileUpload } from '../utils/http';
import Config from '../config';

class OrderDetailsService {
  getOrderSummaryData(orderId) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/GetSummary?orderId=${orderId}`;

    return fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  getBillingData(partnerId) {
    const url = `${Config.get(
      'adminApi'
    )}AdminPartnersApi/GetBillingDetails?partnerId=${partnerId}`;

    return fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  fetchGetState() {
    const url = `${Config.get('adminApi')}usersapi/getstate`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  updateOrderMeta(orderId, meta) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/UpdateOrderMeta`;

    // clone meta and convert meta Admmin Notes to readable encode via escape
    const clonedMeta = JSON.parse(JSON.stringify(meta));
    clonedMeta.AdminComments = clonedMeta.AdminComments ? escape(clonedMeta.AdminComments) : '';

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: orderId
      },
      body: JSON.stringify({ Meta: clonedMeta })
    });
  }

  updateOrderItemsMeta(orderId, itemIds, meta) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/UpdateOrderItemsMeta`;

    // clone meta and convert meta Admmin Notes to readable encode via escape
    const clonedMeta = JSON.parse(JSON.stringify(meta));
    clonedMeta.AdminComments = clonedMeta.AdminComments ? escape(clonedMeta.AdminComments) : '';

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: orderId
      },
      body: JSON.stringify({ Meta: clonedMeta, ItemIds: itemIds })
    });
  }

  sendShippingConfirmation(orderId) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/shippingConfirmation`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`,
        accept: 'application/json, text/plain, */*'
      },
      queryParams: {
        orderId: orderId
      }
    });
  }

  sendOrderConfirmation(orderId) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/orderConfirmation`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`,
        accept: 'application/json, text/plain, */*'
      },
      queryParams: {
        orderId: orderId
      }
    });
  }

  getItemsData(bodyData) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/GetItems`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`,
        accept: 'application/json, text/plain, */*'
      },
      queryParams: {
        orderId: bodyData,
        page: 1,
        size: 9999
      }
    });
  }

  // SUMMARY TAB
  saveShippingAddress(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/saveShippingAddress`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: payload.orderId
      },
      body: JSON.stringify(payload.data)
    });
  }

  saveBillingAddress(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/saveBillingAddress?orderId=${
      payload.orderId
    }`;

    return post(url, JSON.stringify(payload.data), {
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  ignoreSuggestedAddress(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/ignoreSuggestedAddress?orderId=${
      payload.orderId
    }`;

    return post(url, JSON.stringify(payload.data), {
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  applySuggestedAddress(payload) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/applySuggestedAddress?orderId=${
      payload.orderId
    }`;

    return post(url, JSON.stringify(payload.data), {
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  changeSku(payload) {
    const url = `${Config.get('api')}v/1/source/api/OrderUpdate/`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        recipeid: payload.changeSkuData.partnerInfo.PartnerSafeId,
        partnerBillingKey: payload.changeSkuData.partnerInfo.PartnerBillingKey
      },
      body: JSON.stringify({
        OrderItemUpdates: {
          NewPRPSku: payload.sku,
          OrderItemId: payload.orderItemId
        },
        OrderSafeId: payload.summaryData.SafeId
      })
    });
  }

  updateShippingMethod(payload) {
    const url = `${Config.get('adminApi')}OrdersApi/ChangeShippingMethod`;
    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(payload)
    });
  }

  // STATUS TAB
  updateItemsStatus(items) {
    const url = `${Config.get('adminApi')}OrdersApi/ChangeStatus`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify({
        comments: items.comments,
        ids: items.ids,
        statusId: items.statusId
      })
    });
  }

  hadCustomization(bodyData) {
    const url = `${Config.get('adminApi')}OrdersApi/HadCustomization`;

    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(bodyData)
    });
  }

  changeTrackingNumber(items) {
    const url = `${Config.get('adminApi')}OrdersApi/ChangeTrackingNumber`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify({
        trackingNumber: items.trackingNumber,
        orderItemIds: items.ids
      })
    });
  }

  // IMAGES TAB
  postOrderImageUrlUpdate(imageId, newUrl) {
    const url = `${Config.get('adminApi')}ImagesApi/SetUrl`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        imageId: imageId
      },
      body: JSON.stringify({
        url: newUrl
      })
    });
  }

  postOrderImageFileUpdate(imageId, file) {
    const uploadUrl = `${Config.get('adminApi')}ImagesApi/Upload?imageId=${imageId}`;
    let formData = new FormData();
    formData.append('file', file[0], file[0].name);
    const headers = {
      Authorization: 'Bearer ' + Config.get('adminApiToken')
    };

    return fileUpload(uploadUrl, formData, { headers }).then(res => res);
  }

  postManipCommand(imageId, imageManipCmd) {
    const url = `${Config.get('adminApi')}ImagesApi/UpdateImageManip`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        imageId: imageId
      },
      body: JSON.stringify({ imageManipCmd })
    });
  }

  submitRevert(imageId) {
    const url = `${Config.get('adminApi')}ImagesApi/RevertImage`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        imageId: imageId
      },
      body: JSON.stringify({ imageId })
    });
  }

  submitSplitOrderItem(orderId, orderItemId) {
    const url = `${Config.get('adminApi')}OrdersApi/SplitOrderItem`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify({
        OrderId: parseInt(orderId),
        OrderItemId: orderItemId
      })
    });
  }

  // VENDORS TAB
  getOrderFailedInfo(orderId) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/GetOrderFailedInfo`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: orderId
      }
    });
  }

  getOrderLogs(orderId) {
    const url = `${Config.get('adminApi')}OrdersApi/GetOrderLogsByVendor`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        orderId: orderId
      }
    });
  }

  getTraceAttempt(attemptId) {
    return fetch(
      `${Config.get('adminApi')}OrderDetailsApi/GetAttemptTrace?attemptId=${attemptId}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${Config.get('adminApiToken')}`,
          'content-type': `application/json`
        }
      }
    );
  }

  reAttemptOrder(attemptId) {
    const url = `${Config.get('adminApi')}OrderDetailsApi/GetOrderReattempt`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        attemptId: attemptId
      }
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new OrderDetailsService();
