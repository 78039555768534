import Config from '../config';
import sha1 from 'sha1';

const stubAnalytics = Object.freeze({
  pageView: obj => console.log(`Analytics:Page View - ${document.title} - ${obj.path}`),
  track: (source, action, category, value, obj) =>
    console.log(
      `Analytics:Track [${category}] [${source}] [${action}]` +
        ` ${value || ''} ${obj ? ': ' + JSON.stringify(obj) : ''}`
    ),
  identify: obj => {},
  registerIntent: obj => {}
});

const gTagAnalytics =
  window.gtag &&
  Object.freeze({
    version: 2,
    pageView: ({ pagePath } = {}) =>
      gtag('event', 'page_view', {
        page_path: pagePath
      }),

    track: (source, action, category, value, obj = {}, metadata = {}) => {
      gtag('event', action, {
        ...obj,
        event_category: category,
        event_label: source,
        event_value: value
      });
    },

    identify: model => {
      gtag(
        'set',
        'user_properties',
        Object.assign(
          {},
          model.userId && { admin_user_id: model.userId },
          model.partnerId && { partner_id: model.partnerId },
          model.partnerName && { partner_name: model.partnerName },
          model.userType && { user_type: model.userType }
        )
      );
    },

    registerIntent: obj => {}
  });

const v1ToV2Map = Object.freeze({
  'Hub - Create Flow': {
    'Product created successfully': 'publish_to_store_click'
  },
  'Hub - Image upload': {
    'Bulk image upload clicked': 'artwork_upload_click',
    'SKU image upload clicked': 'SKU_image_upload_click'
  },
  'Hub - Product preview': {
    Edit: 'edit_mockup_click',
    Remove: 'remove_mockup_click',
    Download: 'download_mockup_click'
  },
  'Image Editor': {
    'Image moved': 'artwork_placement_click',
    'Align Horizontal clicked': 'align_horizontal_click',
    'Align Vertical clicked': 'align_vertical_click',
    'Rotate clicked': 'rotate_click',
    'Zoom in': 'zoom_in',
    'Zoom out': 'zoom_out',
    'Undo clicked': 'undo_click',
    'Crop clicked': 'crop_click',
    'Crop successful': 'crop_confirm_click'
  },
  '*': {
    'Gooten product selected': 'select_product'
  }
});

class AnalyticsService {
  _getAnalyticsObj() {
    const analyticsTargets = [
      window.PIO && window.PIO._analytics,
      gTagAnalytics,
      process.env.NODE_ENV !== 'production' && stubAnalytics
    ].filter(x => x);

    return Object.freeze({
      pageView: (data = {}) => analyticsTargets.forEach(analytics => analytics.pageView(data)),

      track: (source, action, category, value, obj = {}, metadata = {}) =>
        analyticsTargets.forEach(analytics => {
          if (analytics.version === metadata.version) {
            // make a defensive copy to avoid mutations in https://github.com/printdotio/printio-analytics
            analytics.track(source, action, category, value, { ...obj }, { ...metadata });
          }
        }),

      identify: (data = {}) => analyticsTargets.forEach(analytics => analytics.identify(data)),

      registerIntent: (data = {}) =>
        analyticsTargets.forEach(analytics => analytics.registerIntent(data))
    });
  }

  fbqTrack(event) {
    if (window.fbq) {
      window.fbq('track', event);
    }
  }

  pageView(obj) {
    this._getAnalyticsObj().pageView(obj);
  }

  registerIntent(obj) {
    this._getAnalyticsObj().registerIntent(obj);
  }

  identify(obj) {
    this._getAnalyticsObj().identify(obj);
  }

  /*
   * Wrap internal analytics.track() method as is.
   * CAUTION: It's not integration specific!
   */
  _track(source, action, category = null, value, obj = {}, metadata = {}) {
    // source is required, otherwise event will be ignored
    const prefixes = ['Hub', 'Image Editor', 'COF', 'Admin', 'js'];

    const sendToFreshsales =
      !prefixes.some(s => source && source.indexOf(s) === 0) ||
      (source === 'Settings' && action === 'Team member invited') ||
      (source === 'Onboarding Dashboard - Main' && action === 'Accessed');

    this._getAnalyticsObj().track(source, action, category, value, obj, {
      // filter out events on segment for Freshsales
      // b/c events limit exceeded
      integrations: {
        Freshsales: sendToFreshsales
      },
      ...metadata
    });

    // hub reverses category and source
    const [src, cat] = category === 'Hub' ? [category, source] : [source, category || obj.source];

    // prefer lookup on category -> source -> wildcard
    const v2Map = v1ToV2Map[cat] || v1ToV2Map[src] || v1ToV2Map['*'];

    const v2Action = v2Map && v2Map[action];

    if (v2Action) {
      this._getAnalyticsObj().track(src, v2Action, cat, value, obj, {
        ...metadata,
        version: 2
      });
    }
  }

  /*
   * Handle integration specific events tracking!
   */
  track(source, action, category, value, obj = {}) {
    if (category === 'Hub' || source === 'Image Editor') {
      // Support old way to not ruin existing analytics funnels
      this._track(source, action, category, value, obj);
    } else {
      // Track new events more flexible
      // pass source like `COF - New Product Flow` in obj
      // pass category `COF` as source
      // pass category as null
      obj = { ...obj, source: source };
      this._track(category, action, null, value, obj);
    }
  }

  /*
   * Use source and category from config.
   */
  trackWithConfigSource(action, value, obj) {
    const source = Config.get('analyticsSource');
    const category = Config.get('analyticsCategory');
    this.track(source, action, category, value, obj);
  }

  /*
   * Append category from config to source param.
   */
  trackWithDynamicSource(source, action, value, obj) {
    const category = Config.get('analyticsCategory');
    source = `${category} - ${source}`;
    this.track(source, action, category, value, obj);
  }

  // Impact Analytics
  impactIdentify(customerId, customerEmail) {
    if (window.ire) {
      const sha1Email = sha1(customerEmail);
      window.ire('identify', { customerId, customerEmail: sha1Email });
    }
  }

  impactOrderTrack(orderId, customerId, customerEmail) {
    if (window.ire) {
      console.debug('impact.identify:', { orderId, customerId, customerEmail });
      const sha1Email = sha1(customerEmail);
      window.ire(
        'trackConversion',
        21395,
        {
          orderId,
          customerId,
          customerEmail: sha1Email
        },
        {
          verifySiteDefinitionMatch: true
        }
      );
    }
  }
}

export default new AnalyticsService();
