import Config from './../../../../../config';

export const showSectionOrientationSelector = state => {
  // TO-DO hardcoded for now...
  // canvas wraps 43, framed prints 41, folded cards 45, throw pillows 94,
  // wood prints 100, framed canvas 119, canvas minis, tabletop canvas 1144
  const productIds =
    Config.get('env') === 'staging'
      ? [43, 41, 45, 94, 100, 119, 1129, 1144]
      : [43, 41, 76, 93, 98, 120, 132, 149];

  return productIds.includes(state.get('data').get('selectedProductId'));
};
