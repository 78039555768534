import { takeEvery, call, put, all } from 'redux-saga/effects';
import {
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  fetchAsyncSuccess,
  fetchAsyncFail
} from '../../store/actions/dataActions';
import { updateSideBar } from './atoms/ProductDetails/ProductDetailsActions';
import productDataService from '../../services/productDataService';
import Log from '../../services/logService';
import { isProductSupported } from './ProductSelectionSelectors';

export function* productsFetchAsyncHandler(action) {
  let productsData;
  try {
    // call and pass correct this as 1st array param
    productsData = yield call([productDataService, productDataService.getProductsWithCategories]);
    let { categories, products } = productsData;

    // filter out non-supported products for partner
    const condition = product => isProductSupported(product);
    products = yield call(productDataService.filterOutProducts, products, condition);

    yield put(fetchAsyncSuccess(PRODUCTS_FETCH, { categories, products }));
  } catch (err) {
    yield put(fetchAsyncFail(PRODUCTS_FETCH, err));
    throw Log.withFriendlyMsg('Failed to fetch products', err, { action, productsData });
  }
}

export function* productDetailsFetchAsyncHandler(action) {
  let productDetails = null;
  try {
    productDetails = yield call(productDataService.getProductDetails, action.payload);
    yield put(updateSideBar(productDetails.product));

    productDataService.sortSubCategories(productDetails);

    yield put(fetchAsyncSuccess(PRODUCT_DETAILS_FETCH, productDetails));
  } catch (err) {
    yield put(fetchAsyncFail(PRODUCT_DETAILS_FETCH, err));
    throw Log.withFriendlyMsg('Failed to fetch product details', err, { action, productDetails });
  }
}

// Our watcher Saga: spawn a new productDataFetchAsyncHandler task on each PRODUCT_DATA_FETCH_ASYNC
export function* watchProductsFetchAsync() {
  yield takeEvery(PRODUCTS_FETCH.ASYNC, productsFetchAsyncHandler);
}

export function* watchProductDetailsFetchAsync() {
  yield takeEvery(PRODUCT_DETAILS_FETCH.ASYNC, productDetailsFetchAsyncHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchProductsFetchAsync(), watchProductDetailsFetchAsync()]);
}
