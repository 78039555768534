class CloseOnClickOutsideManager {
  constructor() {
    this.closableComponents = [];
    document.body.addEventListener('click', e => {
      // check that click wasn't on dropdown or modal
      if (
        e.target.getAttribute('class') &&
        (e.target.getAttribute('class').indexOf('dropdown') > -1 ||
          e.target.getAttribute('class').indexOf('landing') > -1)
      ) {
        return;
      }

      this.closeOthers();
    });
  }

  register(item) {
    this.closableComponents.push(item);
  }

  unregister(item) {
    this.closableComponents = this.closableComponents.filter(i => i !== item);
  }

  closeOthers(item) {
    this.closableComponents.forEach(i => {
      if (i !== item) {
        i.close();
      }
    });
  }
}

export default new CloseOnClickOutsideManager();
