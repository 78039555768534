import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import { Link } from 'react-router';
import Icon from './Icon.js';

const Tab = ({ className, children, active, to, onClick }) => {
  return (
    <Link className="tab-link" to={to && to} onClick={() => onClick && onClick()}>
      <div className={`${className} tab mr-2 ml-2 pl-2 pr-2`}>
        <div className="tab-text d-flex title-3">{children}</div>
        {active && <div className="tab-marker fade-in" />}
      </div>
      <style jsx>
        {`
          .tab {
            position: relative;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;
          }
          .tab-text {
            color: ${active ? COLORS.gootenBlue : COLORS.neutralDark200};
            fill: ${active ? COLORS.gootenBlue : COLORS.neutralDark200};
            white-space: nowrap;
          }
          :global(.tab-container a) {
            text-decoration: none !important;
          }
          .tab-text:hover {
            color: ${COLORS.gootenBlue};
            fill: ${COLORS.gootenBlue};
          }
          .tab-marker {
            position: absolute;
            bottom: 0;
            opacity: 1;
            right: 0;
            left: 0;
            height: 0.25rem;
            border-radius: 4px 4px 0 0;
            background: ${COLORS.gootenBlue};
            animation-duration: 0.3s;
            animation-timing-function: ease-in-out;
          }
          .fade-in {
            animation-name: fade-in;
          }
          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          @media screen and (max-width: 767px) {
            .tab {
              display: ${active ? 'flex' : 'none'};
            }
          }
        `}
      </style>
    </Link>
  );
};

const TabContainer = ({ children, className, hideControls }) => {
  const activeIndex = children.findIndex(i => i && i.props.active);
  let prev, next;
  const prevLink =
    ~activeIndex && (prev = children[activeIndex - 1]) ? (
      <Link to={prev.props.to} onClick={prev.props.onClick}>
        <Icon icon="chevronLeft" />
      </Link>
    ) : hideControls && children.length === 1 ? null : (
      <br />
    );

  const nextLink =
    ~activeIndex && (next = children[activeIndex + 1]) ? (
      <Link to={next.props.to} onClick={next.props.onClick}>
        <Icon icon="chevronRight" />
      </Link>
    ) : hideControls && children.length === 1 ? null : (
      <br />
    );
  return (
    <div className={`tab-container d-flex ${className || ''}`}>
      <div className="visible-xs arrow-container" key="prev">
        {prevLink}
      </div>
      {children}
      <div className="visible-xs arrow-container" key="next">
        {nextLink}
      </div>
      <style jsx>
        {`
          .arrow-container {
            height: 1.5rem;
          }
          .tab-container {
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            height: 100%;
            -webkit-scrollbar-width: none;
            -moz-scrollbar-width: none;
            -ms-scrollbar-width: none;
            scrollbar-width: none;
            -ms-overflow-style: none;
          }
          .visible-xs {
            display: none;
          }
          @media screen and (max-width: 767px) {
            .visible-xs {
              display: ${hideControls ? 'none!important' : 'block!important'};
            }
            .tab-container :global(.tab) {
              display: ${hideControls ? 'flex !important' : ''};
            }
            .tab-container {
              justify-content: center;
              width: 100%;
            }
          }
          .tab-container::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}
      </style>
    </div>
  );
};

TabContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  hideControls: PropTypes.bool
};

Tab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  active: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func
};

export { Tab, TabContainer };
