import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB', 'PLACE_ORDER_VIEW');

export const LOAD_VARIANTS = getActionName('LOAD_VARIANTS');
export const VARIANTS_FETCHED = getActionName('VARIANTS_FETCHED');
export const IS_READY = getActionName('IS_READY');
export const SELECT_VARIANT = getActionName('SELECT_VARIANT');
export const REMOVE_VARIANT = getActionName('REMOVE_VARIANT');
export const SELECT_ALL_SKUS = getActionName('SELECT_ALL_SKUS');
export const DESELECT_ALL_SKUS = getActionName('DESELECT_ALL_SKUS');
export const SET_EDIT_PRODUCT_ID = getActionName('SET_EDIT_PRODUCT_ID');
export const SET_ORDER_AS_SAMPLE = getActionName('SET_ORDER_AS_SAMPLE');

export const loadVariants = payload => ({
  type: LOAD_VARIANTS,
  payload
});

export const setReady = payload => ({
  type: IS_READY,
  payload
});

export const variantsFetched = payload => ({
  type: VARIANTS_FETCHED,
  payload
});

export const selectVariant = payload => ({
  type: SELECT_VARIANT,
  payload
});

export const removeVariant = payload => ({
  type: REMOVE_VARIANT,
  payload
});

export const selectAll = payload => ({
  type: SELECT_ALL_SKUS,
  payload
});

export const deselectAll = () => ({
  type: DESELECT_ALL_SKUS
});

export const setEditProductID = payload => ({
  type: SET_EDIT_PRODUCT_ID,
  payload
});

export const setOrderAsSample = () => ({
  type: SET_ORDER_AS_SAMPLE
});
