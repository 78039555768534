import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import CoreLayout from 'gooten-components/src/layouts/CoreLayout';
import Loader from 'gooten-components/src/components/shared/Loader';
import Toast from 'gooten-components/src/components/shared/Toast';
import CustomerRefundHistory from './atoms/CustomerRefundHistory';
import CustomerRefundForm from './atoms/CustomerRefundForm';

const CustomerRefund = ({
  customerRefundReasons,
  initCustomerRefund,
  customerRefundData,
  customerRefundHistory,
  showLoading,
  submitCustomerRefund,
  toastText,
  clearToast,
  reasonCode,
  router,
  reasonSelect
}) => {
  useEffect(() => {
    initCustomerRefund({ orderId: router.params.orderId });
  }, []);

  const errorToast = () => (
    <div className="toast-container" onClick={() => clearToast()}>
      <Toast
        className="mt-3"
        isOpen={!!toastText.length}
        textButtonClick={() => clearToast()}
        buttonText="Close"
      >
        {toastText}
      </Toast>
      <style jsx>{`
        position: absolute;
        top: 10px;
        right: 0;
        left: 0;
      `}</style>
    </div>
  );

  const orderData = customerRefundData.get('orderSummary');

  return (
    <CoreLayout>
      <div className="customer-refund">
        {!!toastText.length && errorToast()}
        {showLoading ? <Loader /> : null}
        <div className="title-1">Order {router.params.orderId}</div>

        {orderData && (
          <CustomerRefundForm
            customerRefundReasons={customerRefundReasons}
            customerRefundData={customerRefundData}
            customerRefundHistory={customerRefundHistory}
            submitCustomerRefund={submitCustomerRefund}
            showLoading={showLoading}
            reasonCode={reasonCode}
            reasonSelect={reasonSelect}
          />
        )}

        {customerRefundHistory && (
          <CustomerRefundHistory customerRefundHistory={customerRefundHistory} />
        )}
      </div>
      <style jsx>
        {`
          margin: 20px;
          width: 80%;
          display: flex;
          flex-direction: column;

          .customer-refund :global(.select-container) {
            margin-bottom: 0;
          }
        `}
      </style>
    </CoreLayout>
  );
};

CustomerRefund.propTypes = {
  router: PropTypes.object,
  customerRefundReasons: PropTypes.instanceOf(List).isRequired,
  initCustomerRefund: PropTypes.func.isRequired,
  submitCustomerRefund: PropTypes.func.isRequired,
  clearToast: PropTypes.func.isRequired,
  customerRefundHistory: PropTypes.instanceOf(List).isRequired,
  customerRefundData: PropTypes.object.isRequired,
  showLoading: PropTypes.bool.isRequired,
  toastText: PropTypes.string.isRequired,
  reasonCode: PropTypes.string.isRequired,
  reasonSelect: PropTypes.func.isRequired
};

export default CustomerRefund;
