import { Map } from 'immutable';
import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_REQUEST_PRODUCT_ADDED,
  UPDATE_LOADING_STATUS,
  UPDATE_SOURCE,
  UPDATE_REQUEST_DATA
} from './CartViewActions';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import AddToCartRequest from '../../models/AddToCartRequest';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const defaultState = new Map({
  request: new AddToCartRequest(),
  source: null,
  loadingStatus: 'ready',
  sourceHistory: null
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_REQUEST_DATA:
      return state.set('request', action.payload.products);
    case ADD_TO_CART_REQUEST.ASYNC:
      return state
        .set('request', action.payload.request)
        .set('source', action.payload.source)
        .set('sourceHistory', action.payload.source)
        .set('loadingStatus', 'initial');
    case ADD_TO_CART_REQUEST.SUCCESS:
      return state
        .set('request', new AddToCartRequest())
        .set('source', null)
        .set('loadingStatus', 'ready');
    case ADD_TO_CART_REQUEST.FAIL:
      return state
        .set('request', new AddToCartRequest())
        .set('source', null)
        .set('loadingStatus', 'failed');
    case ADD_TO_CART_REQUEST_PRODUCT_ADDED:
      return state.updateIn(['request', 'products'], products =>
        products.filter(p => p.productId !== action.payload.productId)
      );
    case UPDATE_LOADING_STATUS:
      return state.set('loadingStatus', action.payload.status);
    case UPDATE_SOURCE:
      return state.set('source', action.payload.source);
    case RESET:
      return state
        .set('request', new AddToCartRequest())
        .set('source', null)
        .set('loadingStatus', 'ready');
    default:
      return state;
  }
};
