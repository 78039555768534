import { combineReducers } from 'redux-immutable';
import * as ACTIONS from './SKUSelectionActions';
import { fromJS, Map, List } from 'immutable';
import { GLOBAL_CLOSE_SIDEBAR, RESET } from '../../store/actions/globalActions';
import { ADD_TO_CART, EDIT_CART_ITEM } from '../../store/actions/dataActions';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const selectedOptionsReducer = (state = new Map(), action) => {
  switch (action.type) {
    case ACTIONS.SELECT_OPTION: {
      let newState = state.update(action.payload.optionId, option =>
        option ? option.push(action.payload.valueId) : new List([action.payload.valueId])
      );

      if (action.payload.optionId === 'orientation' && action.payload.valueId !== 'square') {
        newState = newState.update('orientation', options =>
          options.filter(o => o === 'square').push(action.payload.valueId)
        );
      }
      return newState;
    }

    case ACTIONS.SELECT_BATCH_OPTIONS: {
      state = state.withMutations(state =>
        action.payload.options.forEach(opt => {
          state = state.update(opt.optionId, () => new List(opt.values));
        })
      );
      return state;
    }

    case ACTIONS.DESELECT_OPTION: {
      let newState = state.update(action.payload.optionId, option =>
        option.filter(f => f !== action.payload.valueId)
      );
      if (newState.get(action.payload.optionId).isEmpty()) {
        newState = newState.remove(action.payload.optionId);
      }
      return newState;
    }

    case ACTIONS.SET_CASCADE_OPTION: {
      let currentState = state.toJS();
      currentState[action.payload.optionId] = [action.payload.valueId];
      return fromJS(currentState);
    }

    case ACTIONS.DESELECT_BATCH_OPTIONS: {
      let currentState = state.toJS();
      action.payload.optionIds.forEach(x => {
        delete currentState[x];
      });
      return fromJS(currentState);
    }

    case ACTIONS.CLEAN_SELECTED_OPTIONS:
    case ADD_TO_CART: {
      return new Map();
    }

    default:
      return state;
  }
};

const groupReducer = (state = 1, action) => {
  switch (action.type) {
    case ACTIONS.GOTO_NEXT_GROUP: {
      return ++state;
    }
    case ACTIONS.GOTO_PREV_GROUP: {
      return --state;
    }
    case ACTIONS.GOTO_GROUP: {
      return action.payload.groupId;
    }
    case ACTIONS.CLEAN_SELECTED_OPTIONS:
    case ADD_TO_CART: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const notificationsBarReducer = (state = false, action) => {
  switch (action.type) {
    case GLOBAL_CLOSE_SIDEBAR: {
      return false;
    }
    case ACTIONS.CLEAN_SELECTED_OPTIONS: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const dontShowMeAgainNotificationsReducer = (state = new List(), action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_DONT_SHOW_ME_AGAIN_NOTIFICATIONS_ITEM: {
      let current = state.toJS();
      if (current.includes(action.payload)) {
        current = current.filter(x => x !== action.payload);
      } else {
        current.push(action.payload);
      }

      return fromJS(current);
    }
    default: {
      return state;
    }
  }
};

const regionsReducer = (state = new List(), action) => {
  switch (action.type) {
    case ACTIONS.SELECT_REGIONS: {
      let current = state.toJS();

      // skip if region is already selected
      if (action.payload.regions.every(x => current.includes(x))) {
        return state;
      } else {
        action.payload.regions.forEach(x => current.push(x));
      }

      return fromJS(current);
    }
    case ACTIONS.DESELECT_REGION: {
      let current = state.toJS();

      if (current.includes(action.payload.region)) {
        return fromJS(current.filter(r => r !== action.payload.region));
      }

      return state;
    }

    case ACTIONS.CLEAN_SELECTED_REGIONS:
    case ADD_TO_CART: {
      return new List();
    }

    default: {
      return state;
    }
  }
};

const hiddenNotificationsReducer = (state = new List(), action) => {
  switch (action.type) {
    case ACTIONS.HIDE_MARKED_NOTIFICATIONS: {
      let current = state.toJS();
      let currentSet = new Set(current);
      let payloadSet = new Set(action.payload);
      let union = new Set([...currentSet, ...payloadSet]);

      return fromJS(Array.from(union));
    }
    default: {
      return state;
    }
  }
};

const panelCollapsedStateReducer = (state = false, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_PANEL: {
      return !state;
    }
    default: {
      return state;
    }
  }
};

const currentImageReducer = (state = 0, action) => {
  switch (action.type) {
    case ACTIONS.GOTO_GROUP:
    case ACTIONS.GOTO_NEXT_GROUP:
    case ACTIONS.GOTO_PREV_GROUP:
    case ADD_TO_CART: {
      return 0;
    }
    case ACTIONS.SET_CURRENT_IMAGE: {
      return action.payload.i;
    }
    default: {
      return state;
    }
  }
};
const orientationDefaultState = fromJS({ saved: [], changed: false });

const orientationReducer = (state = orientationDefaultState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_ORIENTATION:
      return state.set('saved', action.payload);
    case ACTIONS.SET_DEFAULT_ORIENTATION:
      return state.set('default', action.payload);
    case ACTIONS.ORIENTATION_CHANGED:
      return state.set('changed', action.payload);
    case ACTIONS.CLEAN_SELECTED_OPTIONS:
      return orientationDefaultState;
    case ACTIONS.SET_PUBLISHED_ORIENTATION_CHANGED:
      return state.set('published', action.payload);
    default:
      return state;
  }
};

const selectedBrandDefaultState = fromJS({ brand: '' });
const selectedBrandReducer = (state = selectedBrandDefaultState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_BRAND:
      return state.set('brand', action.payload);
    case ACTIONS.CLEAN_SELECTED_OPTIONS:
      return selectedBrandDefaultState;
    default:
      return state;
  }
};

const selectedGenderDefaultState = fromJS({ gender: '' });
const selectedGenderReducer = (state = selectedGenderDefaultState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_GENDER:
      return state.set('gender', action.payload);
    case ACTIONS.CLEAN_SELECTED_OPTIONS:
      return selectedGenderDefaultState;
    default:
      return state;
  }
};

const selectedSearchDefaultState = fromJS({ search: '' });
const searchStringReducer = (state = selectedSearchDefaultState, action) => {
  switch (action.type) {
    case ACTIONS.SEARCH_TEXT:
      return state.set('search', action.payload);
    case ACTIONS.CLEAN_SELECTED_OPTIONS:
      return selectedSearchDefaultState;
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  selectedOptions: selectedOptionsReducer,
  selectedRegions: regionsReducer,
  groupId: groupReducer,
  notificationsBarActive: notificationsBarReducer,
  markedAsDontShowMeAgainNotifications: dontShowMeAgainNotificationsReducer,
  hiddenNotifications: hiddenNotificationsReducer,
  panelCollapsedState: panelCollapsedStateReducer,
  currentImage: currentImageReducer,
  orientation: orientationReducer,
  selectedBrand: selectedBrandReducer,
  selectedGender: selectedGenderReducer,
  searchString: searchStringReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET || action.type === EDIT_CART_ITEM) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
