import { List } from 'immutable';
import PagedCollection from '../../../../models/PagedCollection';
import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../../config';
import { mapDiffProduct } from '../../../../models/Product';

class HubProductsService {
  loadHubProductItems(query) {
    const url = `${Config.get('storeApi')}products/all`;

    const queryParams = {
      ...this.getAuthQueryParams(),
      search: query.searchText,
      page: query.page,
      pageSize: query.pageSize,
      countryCode: query.countryCode,
      sort: query.sortValue,
      connected: true,
      unique: true
    };

    return fetch(url, { queryParams }).then(res => {
      let data = new PagedCollection({
        items: new List(res.products.map(mapDiffProduct)),
        totalPages: Math.ceil(res.total / query.pageSize),
        total: res.total
      });

      return data;
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new HubProductsService();
