export const arrangePrice = (min, max) => {
  const absMin = Math.abs(min);
  const absMax = Math.abs(max);
  return Math.round(absMin * 100) === Math.round(absMax * 100)
    ? '$' + Math.max(absMin, absMax).toFixed(2)
    : '$' + Math.min(absMin, absMax).toFixed(2) + '-' + Math.max(absMin, absMax).toFixed(2);
};

export const parseCurrency = str => {
  str = (str + '').replace(/[^\d,.-]/g, '');
  var sign = str.charAt(0) === '-' ? '-' : '+';
  var minor = str.match(/[.,](\d+)$/);
  str = str.replace(/[.,]\d*$/, '').replace(/\D/g, '');
  return Number(sign + str + (minor ? '.' + minor[1] : ''));
};

/* eslint-disable no-template-curly-in-string */
const VALUE_NOT_AVAILABLE = '-.--';
const FALLBACK_CURRENCY_FORMAT = '${1}';

// This method can convert Price objects which API return
// Can conver price as usual numbers
// NOTE: Passing 0 price here will result in VALUE_NOT_AVAILABLE '-.--', which is nice :)
export const formatPrice = (priceObject, currencyFormatString = FALLBACK_CURRENCY_FORMAT) => {
  var price = VALUE_NOT_AVAILABLE;
  var currencyFormat = currencyFormatString;
  var currencyDigits;

  if (priceObject) {
    price = priceObject.Price || priceObject;
    currencyFormat = priceObject.CurrencyFormat || currencyFormat;
    currencyDigits = priceObject.CurrencyDigits;
  }

  if (currencyDigits === undefined) {
    currencyDigits = 2;
  }

  if (price === VALUE_NOT_AVAILABLE) {
    return currencyFormat.replace('{1}', price);
  } else {
    return currencyFormat.replace('{1}', Number(price).toFixed(currencyDigits));
  }
};

// Conver JS floats to price
// Helps with avoiding issues like this
// 0.1 + 0.2 = 0.30000000000000004
// roundFloatPrice(0.30000000000000004) = 0.3
export const roundFloatPrice = float => parseFloat(float.toFixed(2));

// Convert JS stings to price
// Value may contains $
// roundStringPrice('$10.101') = 10.10
export const roundStringPrice = value => roundFloatPrice(parseFloat(value.replace(/\$/g, '')));

export const isPrice = num => !isNaN(parseFloat(num)) && isFinite(num);
