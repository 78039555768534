export const ArchivedReason = {
  available: 0,
  temporarilyDiscontinued: 1,
  discontinuedProduct: 2,
  outOfStock: 3
};

export const getArchivedReason = disabledReason => {
  switch (disabledReason) {
    case ArchivedReason.available:
      return 'Unavailable';
    case ArchivedReason.temporarilyDiscontinued:
      return 'Temporarily disabled';
    case ArchivedReason.discontinuedProduct:
      return 'Discontinued';
    case ArchivedReason.outOfStock:
      return 'Out of Stock';
  }
};
