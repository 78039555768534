import React from 'react';
import PropTypes from 'prop-types';
import './CloseButton.scss';
import Button from '../Button';
import Icon from '../Icon';

export const CloseButton = ({ close }) => (
  <Button iconOnly className="component-close-button button-default medium" onClick={close}>
    <Icon icon="x" />
  </Button>
);

CloseButton.propTypes = {
  close: PropTypes.func
};

CloseButton.displayName = 'CloseButton';

export default CloseButton;
