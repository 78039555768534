import {
  GET_SHARED_ORDER_DATA,
  UPDATE_SUMMARY_DATA,
  UPDATE_STATE_DATA,
  UPDATE_BILLING_INFO,
  UPDATE_ORDER_META,
  UPDATE_ORDER_ITEMS_META,
  SEND_SHIPPING_CONFIRMATION,
  SEND_ORDER_CONFIRMATION,
  SAVE_SHIPPING_ADDRESS,
  SAVE_BILLING_ADDRESS,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_BILLING_ADDRESS,
  IGNORE_SUGGESTED_ADDRESS,
  APPLY_SUGGESTED_ADDRESS,
  UPDATE_APPLIED_SHIPPING_ADDRESS,
  UPDATE_IGNORED_SHIPPING_ADDRESS,
  CHANGE_SKU,
  UPDATE_ITEMS_DATA,
  UPDATE_ITEMS_STATUS,
  SUBMIT_MANIP_COMMAND,
  CHANGE_TRACKING_NUMBER,
  GET_ORDER_FAILED_INFO,
  REVERT_IMAGE,
  SUBMIT_IMAGE_FILE,
  SUBMIT_IMAGE_URL,
  REATTEMPT_ORDER,
  GET_SHARED_ORDER_DATA_ERROR,
  SET_ACTIVE_IMAGE,
  STORE_REAL_IMAGE_DIMENSIONS,
  UPDATE_SHIPPING_METHOD,
  SPLIT_ORDER_ITEM,
  SPLIT_ORDER_ITEM_ERROR,
  UPDATE_SHIPPING_ADDRESS_FAILED,
  GET_ORDER_LOGS
} from './OrderDetailsActions';
import initialState from '../../store/initialState';
import { fromJS } from 'immutable';

const defaultState = initialState.getIn(['orders', 'orderDetails']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SHARED_ORDER_DATA:
      return state.set('loading', true).set('errorMessage', null);
    case GET_SHARED_ORDER_DATA_ERROR:
      return state.set('loading', false).set('errorMessage', action.payload);
    case UPDATE_STATE_DATA:
      return state.set('stateData', action.payload.data);
    case UPDATE_SUMMARY_DATA:
      return state.set('summaryData', action.payload.data);
    case UPDATE_BILLING_INFO:
      return state.set('billingData', action.payload.data);
    case UPDATE_ITEMS_DATA:
      return state
        .set('loading', false)
        .set('itemsData', action.payload.updatedItems)
        .set('itemsDataOrig', action.payload.itemsData); /* this can be removed after we
        finish the react version of duplicate/refund/reprint */
    case UPDATE_ORDER_META.ASYNC:
      state = state
        .setIn(['summaryData', 'Meta', 'AdminComments'], fromJS(action.payload.meta.AdminComments))
        .setIn(
          ['summaryData', 'Meta', 'RefundReprintReason'],
          fromJS(action.payload.meta.RefundReprintReason)
        );
      return state.set('loading', true);
    case UPDATE_ORDER_META.SUCCESS:
      return state.set('loading', false);
    case UPDATE_ORDER_META.FAIL:
      return state.set('loading', false);
    case UPDATE_ORDER_ITEMS_META.ASYNC:
      state = action.payload.itemIndexes.reduce(
        (s, index) =>
          s
            .setIn(
              ['itemsData', index, 'Meta', 'AdminComments'],
              fromJS(action.payload.meta.AdminComments)
            )
            .setIn(
              ['itemsData', index, 'Meta', 'RefundReprintReason'],
              fromJS(action.payload.meta.RefundReprintReason)
            ),
        state
      );
      return state.set('loading', true);
    case UPDATE_ORDER_ITEMS_META.SUCCESS:
      return state.set('loading', false);
    case UPDATE_ORDER_ITEMS_META.FAIL:
      return state.set('loading', false);
    case SEND_SHIPPING_CONFIRMATION.ASYNC:
      return state.set('loading', true);
    case SEND_SHIPPING_CONFIRMATION.SUCCESS:
      return state.set('loading', false);
    case SEND_SHIPPING_CONFIRMATION.FAIL:
      return state.set('loading', false);

    // SUMMARY TAB
    case SAVE_SHIPPING_ADDRESS:
      return state.set('loading', true);
    case UPDATE_SHIPPING_ADDRESS:
      const shippingData = action.payload.data.toJS();
      return state
        .set('loading', false)
        .setIn(['editingShippingAddress'], false)
        .setIn(['summaryData', 'MediationId'], shippingData.MediationId)
        .setIn(['summaryData', 'MediationSettings'], shippingData.MediationSettings)
        .setIn(
          ['summaryData', 'ShippingAddress', 'AddressTypeId'],
          shippingData.UpdatedAddress.AddressTypeId
        )
        .setIn(['summaryData', 'ShippingAddress', 'City'], shippingData.UpdatedAddress.City)
        .setIn(
          ['summaryData', 'ShippingAddress', 'CountryCode'],
          shippingData.UpdatedAddress.CountryCode
        )
        .setIn(['summaryData', 'ShippingAddress', 'Email'], shippingData.UpdatedAddress.Email)
        .setIn(
          ['summaryData', 'ShippingAddress', 'FirstName'],
          shippingData.UpdatedAddress.FirstName
        )
        .setIn(['summaryData', 'ShippingAddress', 'LastName'], shippingData.UpdatedAddress.LastName)
        .setIn(['summaryData', 'ShippingAddress', 'Line1'], shippingData.UpdatedAddress.Line1)
        .setIn(['summaryData', 'ShippingAddress', 'Line2'], shippingData.UpdatedAddress.Line2)
        .setIn(['summaryData', 'ShippingAddress', 'Phone'], shippingData.UpdatedAddress.Phone)
        .setIn(
          ['summaryData', 'ShippingAddress', 'PostalCode'],
          shippingData.UpdatedAddress.PostalCode
        )
        .setIn(['summaryData', 'ShippingAddress', 'State'], shippingData.UpdatedAddress.State);
    case UPDATE_SHIPPING_ADDRESS_FAILED:
      return state.set('loading', false);
    case SAVE_BILLING_ADDRESS:
      return state.set('loading', true);
    case UPDATE_BILLING_ADDRESS:
      const billingData = action.payload.data.toJS().data;
      return state
        .set('loading', false)
        .setIn(['editingBillingAddress'], false)
        .setIn(['summaryData', 'BillingAddress', 'AddressTypeId'], billingData.AddressTypeId)
        .setIn(['summaryData', 'BillingAddress', 'City'], billingData.City)
        .setIn(['summaryData', 'BillingAddress', 'CountryCode'], billingData.CountryCode)
        .setIn(['summaryData', 'BillingAddress', 'Email'], billingData.Email)
        .setIn(['summaryData', 'BillingAddress', 'FirstName'], billingData.FirstName)
        .setIn(['summaryData', 'BillingAddress', 'LastName'], billingData.LastName)
        .setIn(['summaryData', 'BillingAddress', 'Line1'], billingData.Line1)
        .setIn(['summaryData', 'BillingAddress', 'Line2'], billingData.Line2)
        .setIn(['summaryData', 'BillingAddress', 'Phone'], billingData.Phone)
        .setIn(['summaryData', 'BillingAddress', 'PostalCode'], billingData.PostalCode)
        .setIn(['summaryData', 'BillingAddress', 'State'], billingData.State);
    case IGNORE_SUGGESTED_ADDRESS:
      return state.set('loading', true);
    case UPDATE_IGNORED_SHIPPING_ADDRESS:
      return state
        .set('loading', false)
        .setIn(['summaryData', 'MediationId'], null)
        .setIn(['summaryData', 'MediationSettings'], null);
    case APPLY_SUGGESTED_ADDRESS:
      return state.set('loading', true);
    case UPDATE_APPLIED_SHIPPING_ADDRESS:
      const appliedShippingData = action.payload.data.toJS();
      return state
        .set('loading', false)
        .setIn(['summaryData', 'MediationId'], null)
        .setIn(['summaryData', 'MediationSettings'], null)
        .setIn(
          ['summaryData', 'ShippingAddress', 'AddressTypeId'],
          appliedShippingData.AddressTypeId
        )
        .setIn(['summaryData', 'ShippingAddress', 'City'], appliedShippingData.City)
        .setIn(['summaryData', 'ShippingAddress', 'CountryCode'], appliedShippingData.CountryCode)
        .setIn(['summaryData', 'ShippingAddress', 'Email'], appliedShippingData.Email)
        .setIn(['summaryData', 'ShippingAddress', 'FirstName'], appliedShippingData.FirstName)
        .setIn(['summaryData', 'ShippingAddress', 'LastName'], appliedShippingData.LastName)
        .setIn(['summaryData', 'ShippingAddress', 'Line1'], appliedShippingData.Line1)
        .setIn(['summaryData', 'ShippingAddress', 'Line2'], appliedShippingData.Line2)
        .setIn(['summaryData', 'ShippingAddress', 'Phone'], appliedShippingData.Phone)
        .setIn(['summaryData', 'ShippingAddress', 'PostalCode'], appliedShippingData.PostalCode)
        .setIn(['summaryData', 'ShippingAddress', 'State'], appliedShippingData.State);
    case CHANGE_SKU:
      return state.set('loading', true);
    case UPDATE_SHIPPING_METHOD.SUCCESS:
      action.payload.ShipmentMethods.forEach(shipGroup => {
        state
          .get('itemsData')
          .toJS()
          .forEach((item, itemIndex) => {
            if (shipGroup.OrderItemIds.includes(item.Item)) {
              state = state.setIn(
                ['itemsData', itemIndex, 'ShipmentMethod'],
                shipGroup.NewShippingMethod
              );
            }
          });
      });
      return state.set('loading', false);

    // UPDATE ITEMS STATUS IN STATUS TAB
    case UPDATE_ITEMS_STATUS.ASYNC:
      return state.set('loading', true);
    case UPDATE_ITEMS_STATUS.SUCCESS:
      action.payload.requestBody.ids.forEach(product => {
        const index = state
          .get('itemsData')
          .toJS()
          .map(e => e.Item)
          .indexOf(product);
        state = state.setIn(['itemsData', index, 'StatusId'], action.payload.requestBody.statusId);
      });
      return state.set('loading', false);
    case UPDATE_ITEMS_STATUS.FAIL:
      return state.set('loading', false);
    case CHANGE_TRACKING_NUMBER.SUCCESS:
      action.payload.requestBody.ids.forEach(product => {
        const index = state
          .get('itemsData')
          .toJS()
          .map(e => e.Item)
          .indexOf(product);
        state = state.setIn(['itemsData', index, 'StatusId'], 1);
      });
      return state.set('loading', false);

    // EMAIL ACTIONS
    case SEND_ORDER_CONFIRMATION.ASYNC:
      return state.set('loading', true);
    case SEND_ORDER_CONFIRMATION.SUCCESS:
      return state.set('loading', false);
    case SEND_ORDER_CONFIRMATION.FAIL:
      return state.set('loading', false);

    // IMAGE VIEW ACTIONS
    case SUBMIT_MANIP_COMMAND.ASYNC:
      return state.set('loading', true);
    case SUBMIT_MANIP_COMMAND.SUCCESS:
    case SUBMIT_MANIP_COMMAND.FAIL:
      return state.set('loading', false);

    case REVERT_IMAGE.ASYNC:
      const oldImageData = JSON.parse(action.payload.oldImage);
      const imageIndex = action.payload.imageIndex;
      const itemIndex = action.payload.itemIndex;
      state = state.setIn(
        ['itemsData', itemIndex, 'Images', imageIndex, 'SourceUrl'],
        oldImageData.SourceUrl
      );
      state = state.setIn(
        ['itemsData', itemIndex, 'Images', imageIndex, 'ManipCommand'],
        oldImageData.ManipCommand
      );
      return state.set('loading', true);
    case REVERT_IMAGE.SUCCESS:
    case REVERT_IMAGE.FAIL:
      return state.set('loading', false);

    case SUBMIT_IMAGE_FILE.ASYNC:
      return state.set('loading', true);
    case SUBMIT_IMAGE_FILE.SUCCESS:
      state
        .get('itemsData')
        .toJS()
        .forEach((item, itemIndex) => {
          item.Images.forEach((image, imageIndex) => {
            if (action.payload.Id === image.Id) {
              state = state.setIn(
                ['itemsData', itemIndex, 'Images', imageIndex, 'SourceUrl'],
                action.payload.SourceUrl
              );
              state = state.setIn(['itemsData', itemIndex, 'StatusId'], 26);
              state = state.setIn(
                ['itemsData', itemIndex, 'Images', imageIndex, 'ManipCommand'],
                action.payload.ManipCommand
              );
            }
          });
        });
      return state.set('loading', false);
    case SUBMIT_IMAGE_FILE.FAIL:
      return state.set('loading', false);

    case SUBMIT_IMAGE_URL.ASYNC:
      return state.set('loading', true);
    case SUBMIT_IMAGE_URL.SUCCESS:
      state
        .get('itemsData')
        .toJS()
        .forEach((item, itemIndex) => {
          item.Images.forEach((image, imageIndex) => {
            if (action.payload.Id === image.Id) {
              state = state.setIn(
                ['itemsData', itemIndex, 'Images', imageIndex, 'SourceUrl'],
                action.payload.SourceUrl
              );
              state = state.setIn(['itemsData', itemIndex, 'StatusId'], 26);
              state = state.setIn(
                ['itemsData', itemIndex, 'Images', imageIndex, 'ManipCommand'],
                action.payload.ManipCommand
              );
            }
          });
        });
      return state.set('loading', false);
    case SUBMIT_IMAGE_URL.FAIL:
      return state.set('loading', false);
    case STORE_REAL_IMAGE_DIMENSIONS:
      return state
        .setIn(
          ['itemsData', action.payload.index, 'Images', action.payload.imageIndex, 'trueWidth'],
          action.payload.width
        )
        .setIn(
          ['itemsData', action.payload.index, 'Images', action.payload.imageIndex, 'trueHeight'],
          action.payload.height
        );
    case SPLIT_ORDER_ITEM:
      return state.set('loading', true);
    case SPLIT_ORDER_ITEM_ERROR:
      return state.set('loading', false);

    // VENDOR TAB ACTIONS
    case GET_ORDER_FAILED_INFO.ASYNC:
      return state.set('loading', true);
    case GET_ORDER_FAILED_INFO.SUCCESS:
      return state.set('failedOrderInfo', action.payload).set('loading', false);
    case GET_ORDER_FAILED_INFO.FAIL:
      return state.set('loading', false);

    case GET_ORDER_LOGS.ASYNC:
      return state.set('loading', true);
    case GET_ORDER_LOGS.SUCCESS:
      return state.set('orderLogs', action.payload).set('loading', false);
    case GET_ORDER_LOGS.FAIL:
      return state.set('loading', false);

    case REATTEMPT_ORDER.ASYNC:
      return state.set('loading', true);
    case REATTEMPT_ORDER.SUCCESS:
    case REATTEMPT_ORDER.FAIL:
      return state.set('loading', false);

    case SET_ACTIVE_IMAGE:
      return state.set('activeImage', action.payload);

    default:
      return state;
  }
};
