import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ADMIN', 'SHOPIFY_BULK_MIGRATION');

export const INIT_SHOPIFY_BULK_MIGARTION = getActionName('INIT_SHOPIFY_BULK_MIGARTION');
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const FETCH_STORES = getActionName.async('FETCH_STORES');
export const CLEAN_STORES = getActionName('CLEAN_STORES');
export const VALIDATE_CSV = getActionName.async('VALIDATE_CSV');
export const PUBLISH_CSV = getActionName.async('PUBLISH_CSV');
export const LOAD_CSV = getActionName.async('LOAD_CSV');
export const FETCH_HISTORY = getActionName.async('FETCH_HISTORY');
export const RESET_VALIDATE_PUBLISH = getActionName('RESET_VALIDATE_PUBLISH');

export const initStoreBulkMigration = () => ({
  type: INIT_SHOPIFY_BULK_MIGARTION
});

export const showLoading = payload => ({
  type: SHOW_LOADING,
  payload
});

export const fetchSuccess = payload => ({
  type: FETCH_STORES.ASYNC,
  payload
});

export const fetchStoresSuccess = payload => ({
  type: FETCH_STORES.SUCCESS,
  payload
});

export const fetchSuccessFail = payload => ({
  type: FETCH_STORES.FAIL,
  payload
});

export const cleanStores = payload => ({
  type: CLEAN_STORES,
  payload
});

export const validateCsv = payload => ({
  type: VALIDATE_CSV.ASYNC,
  payload
});

export const validateCsvSuccess = payload => ({
  type: VALIDATE_CSV.SUCCESS,
  payload
});

export const validateCsvFail = payload => ({
  type: VALIDATE_CSV.FAIL,
  payload
});

export const publishCsv = payload => ({
  type: PUBLISH_CSV.ASYNC,
  payload
});

export const publishCsvSuccess = payload => ({
  type: PUBLISH_CSV.SUCCESS,
  payload
});

export const publishCsvFail = payload => ({
  type: PUBLISH_CSV.FAIL,
  payload
});

export const loadCsv = payload => ({
  type: LOAD_CSV.ASYNC,
  payload
});

export const loadCsvSuccess = payload => ({
  type: LOAD_CSV.SUCCESS,
  payload
});

export const loadCsvFail = payload => ({
  type: LOAD_CSV.FAIL,
  payload
});

export const fetchHistory = payload => ({
  type: FETCH_HISTORY.ASYNC,
  payload
});

export const fetchHistorySuccess = payload => ({
  type: FETCH_HISTORY.SUCCESS,
  payload
});

export const fetchHistoryFail = payload => ({
  type: FETCH_HISTORY.FAIL,
  payload
});

export const resetValidateAndPublish = payload => ({
  type: RESET_VALIDATE_PUBLISH,
  payload
});
