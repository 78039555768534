import { Map, List } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import BillingHistory from './BillingHistory';
import { BillingHistoryModel } from '../../Models/BillingHistoryModel';

export default baseCardReducer(
  BillingHistory.name,
  (
    state = new Map({
      data: new List(),
      page: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      busy: false,
      open: true
    })
  ) => state,
  BillingHistoryModel
);
