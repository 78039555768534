import { combineReducers } from 'redux-immutable';
import locationReducer from './locationReducer';
import hubReducer from '../../Hub/HubReducer';
import ordersReducer from '../../Orders/OrdersReducer';
import customOrderFormReducer from '../../CustomOrderForm/CustomOrderFormReducer';
import dashboardReducer from '../../OnboardingDashboard/DashboardReducer';
import settingsReducer from '../../Settings/SettingsReducer';
import reprintSelfServiceReducer from '../../ReprintSelfService/ReprintSelfServiceReducer';
import customerRefundReducer from '../../CustomerRefund/CustomerRefundReducer';
import productionReportReducer from '../../ProductionReport/ProductionReportReducer';
import adminReducer from '../../Tools/AdminReducer';

const safeAddReducer = (reducersStorage, key, reducer) => {
  if (!Object.hasOwnProperty.call(reducersStorage, key)) {
    reducersStorage[key] = reducer;
  }
};

export const makeRootReducer = (asyncReducers, rootReducer) => {
  safeAddReducer(asyncReducers, 'location', locationReducer);
  safeAddReducer(asyncReducers, 'orders', ordersReducer);
  safeAddReducer(asyncReducers, 'hub', hubReducer);
  safeAddReducer(asyncReducers, 'customOrderForm', customOrderFormReducer);
  safeAddReducer(asyncReducers, 'dashboard', dashboardReducer);
  safeAddReducer(asyncReducers, 'settings', settingsReducer);
  safeAddReducer(asyncReducers, 'reprintSelfService', reprintSelfServiceReducer);
  safeAddReducer(asyncReducers, 'customerRefund', customerRefundReducer);
  safeAddReducer(asyncReducers, 'productionReport', productionReportReducer);
  safeAddReducer(asyncReducers, 'admin', adminReducer);

  const combinedReducer = combineReducers({
    ...asyncReducers
  });

  rootReducer = (state, action) => {
    return combinedReducer(state, action);
  };

  return rootReducer;
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers, store.rootReducer));
};

export default makeRootReducer;
