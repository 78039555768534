import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB-LINK', 'SKUS_SELECTION_VIEW');

// ------------------------------------
// Constants
// ------------------------------------

export const INIT = getActionName.async('INIT');

export const SET_READY = getActionName('SET_READY');

export const CAN_GO_NEXT = getActionName('CAN_GO_NEXT');

export const SELECT_PRODUCT = getActionName('SELECT_PRODUCT');

export const SELECT_REGION_INDEX = getActionName('SELECT_REGION_INDEX');

export const SELECT_SUB_CATEGORY_INDEX = getActionName('SELECT_SUB_CATEGORY_INDEX');

export const LOAD_SKUS = getActionName.async('LOAD_SKUS');

export const SKU_SELECT = getActionName('SKU_SELECT');

export const SKIP_MAPPING = getActionName('SKIP_MAPPING');

export const SET_REGIONS_TO_ACTIVE = getActionName('SET_REGIONS_TO_ACTIVE');

export const SET_SUB_CATEGORIOS_TO_ACTIVE = getActionName('SET_SUB_CATEGORIOS_TO_ACTIVE');

export const SET_SELECTION = getActionName('SET_SELECTION');

export const DESELECT_LAST_SKU = getActionName('DESELECT_LAST_SKU');

export const RESET_NOTIFICATION = getActionName('RESET_NOTIFICATION');

// ------------------------------------
// Actions
// ------------------------------------

export const selectProduct = productId => {
  return {
    type: SELECT_PRODUCT,
    payload: productId
  };
};

export const selectRegionIndex = index => {
  return {
    type: SELECT_REGION_INDEX,
    payload: index
  };
};

export const selectSubCategoryIndex = index => {
  return {
    type: SELECT_SUB_CATEGORY_INDEX,
    payload: index
  };
};

export const setRegionsToActive = () => {
  return {
    type: SET_REGIONS_TO_ACTIVE
  };
};

export const setSubCategoriesToActive = () => {
  return {
    type: SET_SUB_CATEGORIOS_TO_ACTIVE
  };
};

export const setReady = value => {
  return {
    type: SET_READY,
    payload: value
  };
};

export const canGoNext = value => {
  return {
    type: CAN_GO_NEXT,
    payload: value
  };
};

export const selectSKU = (sku, value) => {
  return {
    type: SKU_SELECT,
    payload: { sku, value }
  };
};

export const deselectLastSKU = () => {
  return {
    type: DESELECT_LAST_SKU
  };
};

export const skipMapping = () => {
  return {
    type: SKIP_MAPPING
  };
};

export const setSelection = value => {
  return {
    type: SET_SELECTION,
    payload: value
  };
};

export const resetNotification = () => {
  return {
    type: RESET_NOTIFICATION
  };
};
