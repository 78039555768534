import { Map } from 'immutable';
import { PartnerTierModel } from '../../Models/PartnerTierModel';

import baseCardReducer from '../BaseCard/BaseCardReducer';

export default baseCardReducer(
  'partnerTier',
  (
    state = new Map({
      open: true,
      loading: false,
      busy: false,
      data: new PartnerTierModel()
    })
  ) => state,
  PartnerTierModel
);
