import { SHOW_LOADING, HIDE_LOADING, SHOW_BACKDROP, HIDE_BACKDROP } from '../actions/globalActions';
import { Map } from 'immutable';

export const backdropReducer = (state = new Map(), action) => {
  switch (action.type) {
    case SHOW_LOADING: {
      return state.set('loading', true);
    }
    case HIDE_LOADING: {
      return state.set('loading', false);
    }
    case SHOW_BACKDROP: {
      return state.set('backdrop', true);
    }
    case HIDE_BACKDROP: {
      return state.set('backdrop', false);
    }
    default: {
      return state;
    }
  }
};
