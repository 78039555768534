import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import ReturnAddressService from './ReturnAddressService';
import {
  LOAD_INITIAL_DATA,
  GET_COUNTRIES,
  GET_ADDRESS_TYPES,
  GET_PARTNER_DETAILS,
  SET_SELECTION,
  UPDATE_SELECTION,
  SAVE_RETURN_ADDRESS,
  USE_EXISTING_RETURN_ADDRESS,
  updateCountries,
  updateAddressTypes,
  updateSelection,
  updateReturnAddress,
  updatePartnerDetails,
  cancelSaving
} from './ReturnAddressActions';
import { customAddressId } from './ReturnAddressSelectors';

export function* handleLoadInitialDataRequest(action) {
  let countries = yield call([ReturnAddressService, ReturnAddressService.fetchCountries]);
  yield put(updateCountries(fromJS(countries)));
  let addressTypes = yield call([ReturnAddressService, ReturnAddressService.fetchAddressTypes]);
  yield put(updateAddressTypes(fromJS(addressTypes)));
  let partnerDetails = yield call([ReturnAddressService, ReturnAddressService.fetchPartnerDetails]);
  yield put(updatePartnerDetails(fromJS(partnerDetails)));
}

export function* handleSetSelectionRequest(action) {
  const hasCustomAddressSet = false;
  const selection = action.payload.selection;
  const addressId = yield select(customAddressId);

  // if they chose the billing address OR they chose a custom address when one has been set previously
  if (selection === 1 || (selection === 2 && addressId)) {
    try {
      let returnAddressResponse = yield call(
        [ReturnAddressService, ReturnAddressService.setReturnAddressType],
        selection
      );
      yield put(updateSelection(selection));
      yield put(push('Return Address Updated'));
    } catch (e) {
      yield put(cancelSaving());
      yield put(push('Error : ' + e.Error));
    }
  } else {
    // they chose the custom address radio button but do not have a custom address set yet
    yield put(cancelSaving());
    yield put(updateSelection(selection));
  }
}

export function* handleSaveReturnAddressRequest(action) {
  try {
    let response = yield call(
      [ReturnAddressService, ReturnAddressService.saveReturnAddress],
      action.payload
    );

    yield put(updateReturnAddress(fromJS(response)));
    yield put(push('Return address updated'));
  } catch (err) {
    yield put(push('Return address update failed'));
  }
}

export function* handleUseExistingReturnAddressRequest(action) {
  try {
    yield put(push('Return address updated'));
  } catch (err) {
    yield put(push('Return address update failed'));
  }
}

export function* watchSearch() {
  yield takeLatest(LOAD_INITIAL_DATA, handleLoadInitialDataRequest);
  yield takeLatest(SET_SELECTION, handleSetSelectionRequest);
  yield takeLatest(SAVE_RETURN_ADDRESS, handleSaveReturnAddressRequest);
  yield takeLatest(USE_EXISTING_RETURN_ADDRESS, handleUseExistingReturnAddressRequest);
}

export default function* rootSaga() {
  yield all([watchSearch()]);
}
