import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';

const checkSVG = (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7488 1.66286L3.95735 10.4626L0.289307 6.75597L1.71091 5.34918L3.8762 7.53727L10.2514 0.337036L11.7488 1.66286Z"
      fill="white"
    />
  </svg>
);

const partialSVG = (
  <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H9C9.55229 0 10 0.447715 10 1C10 1.55228 9.55229 2 9 2H1C0.447715 2 0 1.55228 0 1Z"
      fill="white"
    />
  </svg>
);

const Checkbox = ({
  disabled,
  checked,
  className,
  label,
  secondaryText,
  onChange,
  partial,
  noBorder
}) => {
  return (
    <label className={`${className || ''} ${disabled ? 'disabled' : ''}`}>
      <input type="checkbox" checked={checked} disabled={disabled} readOnly onClick={onChange} />
      <div className="indicator">{checked && (partial ? partialSVG : checkSVG)}</div>
      {label && (
        <div className="label-container">
          <div className="mt-0 heavy body-text-2 heavy m-0">{label}</div>
          {secondaryText && <p className="caption-text d-flex m-0">{secondaryText}</p>}
        </div>
      )}
      <style jsx>
        {`
          // input must be 'visible' to screen readers for accessiblity
          input {
            border: 0;
            clip: rect(0 0 0 0);
            clippath: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
          }
          .label-container {
            margin-left: 0.75rem;
          }
          label {
            min-height: ${secondaryText ? '2.5rem' : 'auto'};
            display: flex !important;
            align-items: ${label ? 'flex-start' : 'center'};
            justify-content: ${label ? 'flex-start' : 'center'};
            line-height: 160%;
            cursor: pointer;
            padding: ${!label || noBorder ? 'auto' : '.75rem'};
            border: ${!label || noBorder
              ? 'none'
              : `1px solid ${checked ? COLORS.gootenBlue : COLORS.neutralLight400}`};
            border-radius: 4px;
          }
          .indicator {
            box-sizing: border-box;
            border-radius: 2px;
            min-width: 1.125rem;
            height: 1.125rem;
            border: ${checked
              ? `1px solid ${COLORS.gootenBlue500}`
              : `1px solid ${COLORS.neutralLight400}`};
            background: ${checked ? COLORS.gootenBlue500 : COLORS.neutralLight50};
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.125rem 0;
          }
          .disabled {
            cursor: not-allowed;
          }
        `}
      </style>
    </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.any,
  secondaryText: PropTypes.string,
  partial: PropTypes.bool,
  noBorder: PropTypes.bool
};

export default Checkbox;
