import { connect } from 'react-redux';
import SearchBar from './SearchBar';
import { searchBarChange } from './SearchBarActions';
import { searchSelector } from './SearchBarSelectors';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  doSearch: query => searchBarChange(query)
};

const mapStateToProps = state => ({
  searchText: searchSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
