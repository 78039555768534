export const allStatuses = [
  {
    Name: 'AddressIssue',
    Id: 27,
    Description: 'Order needs your attention, there is an issue with the shipping address'
  },
  {
    Name: 'Hold',
    Id: 31,
    Description: 'Order is on hold'
  },
  {
    Name: 'HoldByCIImageIssue',
    Id: 13,
    Description: 'Order is delayed due to an item grouped in the order having an image issue'
  },
  {
    Name: 'ImageIssue',
    Id: 12,
    Description:
      'Order requires action, the print image has an issue which is blocking printing, please update the image'
  },
  {
    Name: 'PaymentIssue',
    Id: 29,
    Description: 'Order needs your attention, there was an error processing your payment method'
  },
  {
    Name: 'ShippingIssue',
    Id: 25,
    Description:
      'Order needs your attention due to a shipping issue, check the tracking link for details'
  },
  {
    Name: 'VendorAPIIssue',
    Id: 20,
    Description:
      'Order is delayed due to a production issue, Gooten will contact you if additional information is needed'
  },
  {
    Name: 'NeedsManualApproval',
    Id: 32,
    Description: 'Order is delayed due to an additional quality check'
  },
  {
    Name: 'Pending',
    Id: 7,
    Description: 'Order is on schedule and is currently in a wait period'
  },
  {
    Name: 'InProduction',
    Id: 11,
    Description: 'Order is on schedule and in production'
  },
  {
    Name: 'ManuallyInProduction',
    Id: 19,
    Description: 'Order is on schedule and in production'
  },
  {
    Name: 'Printing',
    Id: 22,
    Description: 'Order is on schedule and is currently being printed'
  },
  {
    Name: 'Test',
    Id: 15,
    Description: null
  },
  {
    Name: 'Cancelled',
    Id: 34,
    Description: 'Order is no longer active and has been cancelled'
  },
  {
    Name: 'CancelledRefunded',
    Id: 33,
    Description: 'Order is no longer active, you and your customer have been refunded'
  },
  {
    Name: 'VendorCancelled',
    Id: 9,
    Description: 'Order is no longer active and has been cancelled'
  },
  {
    Name: 'Shipped',
    Id: 1,
    Description: 'Order has shipped and a tracking link is now available.'
  },
  {
    Name: 'OutForDelivery',
    Id: 24,
    Description:
      "Order is on schedule, it's been produced and packed and is awaiting pickup by the courier"
  },
  {
    Name: 'Delivered',
    Id: 23,
    Description: 'Order has been successfully delivered'
  },
  {
    Name: 'PrePayment',
    Id: 30,
    Description:
      'Order is on schedule, PayPal was selected as payment method and the transaction has not been completed'
  },
  {
    Name: 'ReadyForAccounting',
    Id: 18,
    Description:
      'Order is on schedule, payment is undergoing a security check and will be processed after passing'
  },
  {
    Name: 'PaymentProcessing',
    Id: 28,
    Description: 'Order is on schedule and payment is being processed'
  },
  {
    Name: 'ReadyForImageDl',
    Id: 26,
    Description: 'Order is on schedule, your print images are being downloaded and processed'
  },
  {
    Name: 'New',
    Id: 14,
    Description: null
  },
  {
    Name: 'ReadyForPrint',
    Id: 8,
    Description: 'Order is on schedule and will be sent to manufacturer shortly'
  },
  {
    Name: 'NeedsPersonalization',
    Id: 35,
    Description: 'Order requires personalization before production starts'
  },
  {
    Name: 'InDigitization',
    Id: 36,
    Description: 'Your artwork is being digitized'
  },
  {
    Name: 'NeedsDigitizationApprove',
    Id: 37,
    Description: 'Artwork is digitized for embroidery and requires your approval.'
  },
  {
    Name: 'BilledRefunded',
    Id: 21,
    Description: 'Order is no longer active and you have been refunded'
  },
  {
    Name: 'OrderDataIssue',
    Id: 38,
    Description: 'Order requires action, there is some missing data that needs to be rectified'
  },
  {
    Name: 'DiscontinuedItem',
    Id: 39,
    Description:
      'Product is no longer available and cannot be fulfilled. Replace or remove the item to resume order fulfillment'
  },
  {
    Name: 'OutOfStockItem',
    Id: 40,
    Description:
      'The order requires action; an order item is out of stock. To process the order, change or cancel the affected SKU, or contact partner support for assistance'
  }
];
