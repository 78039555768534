import { Record, List } from 'immutable';

export const Category = new Record({
  name: '',
  subcategories: new List()
});

export const SubCategory = new Record({
  name: '',
  parentCategoryName: ''
});

export const mapCategory = obj =>
  new Category({
    name: obj.name,
    subcategories: new List(obj.subcategories.map(c => new SubCategory(c)))
  });
