import React from 'react';
import PropTypes from 'prop-types';
import './ProductDetails.scss';
import ProductName from './ProductName';
import Section from './Section';
import ImageGrid from './ImageGrid';
import ColorsPreview from './ColorsPreview';
import ValuesList from './ValuesList';
import analyticsService from '../../../../services/analyticsService';
import Button from '../../../shared/Button';

class ProductDetails extends React.Component {
  static propTypes = {
    gotoNextStep: PropTypes.func.isRequired,
    selectProduct: PropTypes.func.isRequired,
    productName: PropTypes.string,
    productCategory: PropTypes.string,
    productId: PropTypes.number,
    minPrice: PropTypes.string,
    maxPrice: PropTypes.string,
    cheapestShipping: PropTypes.string,
    productImages: PropTypes.array,
    showImages: PropTypes.bool,
    productOptions: PropTypes.array,
    onGetStarted: PropTypes.func,
    hidePick: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.onClickGetStarted = this.onClickGetStarted.bind(this);
  }

  exists = array => array && array.length !== 0;

  onClickGetStarted() {
    analyticsService.trackWithDynamicSource(
      'Create Product',
      'Gooten product selected',
      this.props.productName
    );

    this.props.selectProduct(this.props.productId);
    this.props.gotoNextStep();
    this.props.onGetStarted();
  }

  render() {
    const loading = (
      <div className="side-bar-content">
        <ProductName productName="Loading..." />
      </div>
    );

    const productInfo = (
      <div className="side-bar-content">
        <ProductName productName={this.props.productName} />
        <Section
          title="Prices"
          content={
            this.props.maxPrice
              ? this.props.minPrice !== this.props.maxPrice
                ? `${this.props.minPrice} - ${this.props.maxPrice}`
                : `${this.props.minPrice}`
              : `Starting at ${this.props.minPrice}`
          }
        />
        <hr />
        <Section
          title="Shipping Prices"
          content={`Cheapest standard: ${this.props.cheapestShipping}`}
        />
        {this.props.showImages && <hr />}
        {this.exists(this.props.productImages) && this.props.showImages && (
          <Section
            title="Product Images"
            content={<ImageGrid images={this.props.productImages} />}
          />
        )}
        {this.exists(this.props.productOptions) &&
          this.props.productOptions.map((option, i) => {
            if (option.name === 'Colors') {
              return (
                <div key={i}>
                  <hr />
                  <Section title={option.name} content={<ColorsPreview colors={option.values} />} />
                </div>
              );
            } else if (option.values) {
              return (
                <div key={i}>
                  <hr />
                  <Section title={option.name} content={<ValuesList values={option.values} />} />
                </div>
              );
            }
          })}
        {this.props.hidePick ? null : (
          <div className="side-bar-get-started-button-container">
            <Button className="button-secondary large full-width" onClick={this.onClickGetStarted}>
              Pick this
            </Button>
          </div>
        )}
      </div>
    );

    return this.props.productName ? productInfo : loading;
  }
}

ProductDetails.displayName = 'ProductDetails';

export default ProductDetails;
