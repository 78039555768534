import { getActionName } from '../../ProductSelectionActions';

// ------------------------------------
// Constants
// ------------------------------------
export const SIDE_BAR_OPEN = getActionName('SIDE_BAR_OPEN');
export const SIDE_BAR_UPDATE = getActionName('SIDE_BAR_UPDATE');
export const SIDE_BAR_CLOSE = getActionName('SIDE_BAR_CLOSE');

// ------------------------------------
// Actions
// ------------------------------------
export const openSideBar = () => ({
  type: SIDE_BAR_OPEN
});

export const updateSideBar = productInfo => ({
  type: SIDE_BAR_UPDATE,
  payload: productInfo
});

export const closeSideBar = () => ({
  type: SIDE_BAR_CLOSE
});
