import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../config';

class OrdersViewService {
  fetchOrderData(bodyData) {
    const url = `${Config.get('adminApi')}OrdersApi/Search`;

    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(bodyData)
    });
  }

  changeStatus(bodyData) {
    // Example body data
    // ids array of ints
    // statusId ints
    const url = `${Config.get('adminApi')}OrdersApi/ChangeStatus`;

    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(bodyData)
    });
  }

  hadCustomization(bodyData) {
    const url = `${Config.get('adminApi')}OrdersApi/HadCustomization`;

    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(bodyData)
    });
  }

  // NOTE: API for updating individual items to 'ReadyForProduction'
  // in Needs Personalization Tab (Orders Search Page)
  hadCustomizationOrderItem(bodyData) {
    const url = `${Config.get('adminApi')}OrdersApi/HadCustomizationOrderItem`;

    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      body: JSON.stringify(bodyData)
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new OrdersViewService();
