import { createSelector } from 'reselect';
import { List, Map } from 'immutable';
import { selectedStoreNameSelector } from '../Stores/StoresSelectors';
import productsService from './ProductsService';

export const productsStateSelector = state => state.getIn(['hub', 'hubView', 'products']);

const hubStateSelector = state => state.getIn(['hub', 'hubView']);

export const isUpdatingSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('updating')
);

export const selectedProductsSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('selected')
);

export const connectionsSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('connections')
);

export const displaySelector = createSelector([productsStateSelector], productsState =>
  productsState.get('display')
);

export const viewBySelector = createSelector([productsStateSelector], productsState =>
  productsState.get('viewBy')
);

export const sortBySelector = createSelector([productsStateSelector], productsState =>
  productsState.get('sortBy')
);

export const productsQuerySelector = createSelector(
  [hubStateSelector],
  hubState =>
    new Map({
      provider: hubState.getIn(['providers', 'selected']),
      store: hubState.getIn(['stores', 'selected']),
      viewBy: hubState.getIn(['products', 'viewBy']),
      sortBy: hubState.getIn(['products', 'sortBy']),
      search: hubState.get('search'),
      pageSize: hubState.getIn(['products', 'pageSize']),
      page: hubState.getIn(['products', 'page']),
      // NOTE: collections used only for loading products for store landing page
      // few products from each collection
      collections: hubState.getIn(['stores', 'selected'])
        ? hubState
            .get('stores')
            .get('items')
            .find(s => s.get('id') === hubState.getIn(['stores', 'selected']))
            .get('collections')
            .map(c => c.get('name'))
        : new List()
    })
);

export const hasProductsQuerySearchParamsSelector = createSelector(
  [productsQuerySelector],
  productsQuery =>
    !!(productsQuery.get('viewBy') || productsQuery.get('search') || productsQuery.get('page') > 1)
);

const productsQueryKeySelector = createSelector([productsQuerySelector], productsQuery =>
  productsService.getQueryCacheKey(productsQuery)
);

export const productsQueryDataSelector = createSelector(
  [productsStateSelector, productsQueryKeySelector],
  (productsState, queryCacheKey) => {
    // NOTE: Get currentQuery key and check if cached queries exist
    // if not exist return { loaded: false }
    const key = queryCacheKey;
    if (key && productsState.get('cachedQueries').has(key)) {
      return new Map({
        loaded: true,
        data: productsState.get('cachedQueries').get(key)
      });
    } else {
      return new Map({
        loaded: false
      });
    }
  }
);

export const currentPageSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('page')
);

export const currentPageSizeSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('pageSize')
);

export const totalPagesCountSelector = createSelector(
  [productsStateSelector, productsQueryDataSelector],
  (productsState, productsQueryData) => {
    if (!productsQueryData.get('loaded')) {
      return 0;
    } else {
      return Math.ceil(productsQueryData.get('data').get('total') / productsState.get('pageSize'));
    }
  }
);

export const productUrlInStoreSelector = createSelector(
  [selectedStoreNameSelector, selectedProductsSelector],
  (selectedStoreName, selectedProducts) =>
    productsService.getProductUrlInStore(
      selectedStoreName,
      selectedProducts && selectedProducts.get(0)
    )
);

// Publish async task handling
// const locationStateSelector = (state) =>
//   state.get('location')

// export const locationToRedirectWhenPublishSuccess = createSelector(
//   [locationStateSelector],
//   (locationStateSelector) => locationStateSelector.get('history')
//     .skipLast(1).reverse().find(l =>
//       l.get('action') === 'POP' &&
//       (~l.get('pathname').indexOf('/hub') &&
//         !~l.get('pathname').indexOf('/link-product') &&
//         !~l.get('pathname').indexOf('/edit-product') &&
//         !~l.get('pathname').indexOf('/create-product')
//       )
//     )
// )

export const taskNotificationSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('tasks').find(t => t.get('notify'))
);

export const processingTasksSelector = createSelector([productsStateSelector], productsState =>
  productsState.get('tasks').filter(t => t.get('status') === 'processing')
);

export const pendingProductsSelector = createSelector(
  [processingTasksSelector],
  processingTasks => {
    let pendingProducts = new List();

    // Skip processing
    /* processingTasks.forEach(pt => {
      const request = pt.get('request')
      request.get('tasks').forEach(t => {
        switch (t.get('name')) {
          // TODO: Think on how better map pending tasks to pending products
          case 'store-product-create':
            pendingProducts = pendingProducts.push(new Map({
              name: request.get('product').get('name'),
              type: 'store',
              connectedVariants: request.get('product').get('variants').size,
              totalVariants: request.get('product').get('variants').size
            }))
            break

          case 'storage-product-create':
            pendingProducts = pendingProducts.push(new Map({
              name: request.get('product').get('name'),
              type: 'storage'
            }))
            break
        }
      })
    }) */

    return pendingProducts;
  }
);

export const storeToCheckLinkSelector = createSelector([hubStateSelector], hubState => {
  if (
    hubState.getIn(['providers', 'selected']) === 'shopify' &&
    hubState.getIn(['stores', 'loaded']) &&
    hubState.getIn(['stores', 'selected'])
  ) {
    const store = hubState
      .getIn(['stores', 'items'])
      .find(s => s.get('id') === hubState.getIn(['stores', 'selected']));
    return !store.get('hasProductsToLink') &&
      typeof store.getIn(['settings', 'indexing_products_count']) === 'number'
      ? store.get('id')
      : null;
  }
  return null;
});

export const currentProviderSelector = createSelector(
  [hubStateSelector],
  hubState => hubState.getIn(['providers', 'selected']) || ''
);

export const notificationSelector = createSelector([productsStateSelector], products =>
  products.get('notification')
);

export const currentQueryProductsSelector = createSelector(
  [productsStateSelector],
  productsState =>
    productsState.getIn(['cachedQueries', productsState.get('currentQuery'), 'items']) || new List()
);

export const showSampleOrderModalSelector = createSelector([productsStateSelector], state =>
  state.get('showSampleOrderModal')
);

export const shopifyRedirectRequiredStoresSelector = state =>
  state.getIn(['data', 'shopifyRedirectRequiredStores']);
