import React from 'react';
import PropTypes from 'prop-types';
import './ProductName.scss';

export const ProductName = ({ productName }) => (
  <div className="component-product-name">
    {/* replace here to make css break the words */}
    <h1 className="text-style">{productName.replace('/', '/ ')}</h1>
  </div>
);

ProductName.propTypes = {
  productName: PropTypes.string
};

ProductName.displayName = 'ProductName';

export default ProductName;
