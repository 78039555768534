import { combineReducers } from 'redux-immutable';
import hubProductsReducer from './components/HubProducts/HubProductsReducer';
import { SEARCH_CHANGE } from './HubProductsViewActions';
import { RESET } from '../../store/actions/globalActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RESET]: (state, action) => (action.payload.scope === 'global' ? '' : state),

  [SEARCH_CHANGE]: (state, action) => {
    return action.payload.searchText;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const searchReducer = (state = '', action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default combineReducers({
  hubProducts: hubProductsReducer,
  search: searchReducer
});
