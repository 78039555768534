import { UPDATE_HELP_DATA } from './HelpDataActions';
import initialState from '../../store/initialState';

const defaultState = initialState.getIn(['orders', 'helpData']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_HELP_DATA:
      return action.payload;
    default:
      return state;
  }
};
