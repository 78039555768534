import { connect } from 'react-redux';
import ApparelPanel from './ApparelPanel';
import { allItemsSelector, brandOptions, genderOptions } from './ApparelPanelSelectors';
import {
  gotoGroup,
  selectBatchOptions,
  brandSelect,
  genderSelect,
  searchChanged
} from '../../SKUSelectionActions';

const mapDispatchToProps = {
  gotoGroup: groupId => gotoGroup(groupId),
  selectBatchOptions: opts => selectBatchOptions(opts),
  brandSelect: brandId => brandSelect(brandId),
  genderSelect: genderId => genderSelect(genderId),
  searchChanged: search => searchChanged(search)
};

const mapStateToProps = state => {
  return {
    allItems: allItemsSelector(state),
    selectedBrandId: state.get('SKUSelection').get('selectedBrand').get('brand'),
    selectedGenderId: state.get('SKUSelection').get('selectedGender').get('gender'),
    search: state.get('SKUSelection').get('searchString').get('search'),
    genderOptions: genderOptions(state),
    brandOptions: brandOptions(state),
    selectedOptions: state.get('SKUSelection').get('selectedOptions')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApparelPanel);
