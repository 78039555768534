import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { injectSaga } from '../store/sagas';
import { fromJS } from 'immutable';
import {
  INIT_CUSTOMER_REFUND,
  SUBMIT_CUSTOMER_REFUND,
  saveCustomerRefundData,
  saveCustomerRefundHistory,
  showLoading,
  savePartnerDetails,
  toastText,
  initAllReasons,
  refresh
} from './CustomerRefundActions';
import service from './CustomerRefundService';
import { submitCustomerRefundRequestSelector } from './CustomerRefundSelectors';
import { delay } from 'redux-saga';

function* handleInitCustomerRefund(action) {
  yield put(showLoading(true));
  try {
    const customerRefundData = service.getCustomerRefundData(action.payload.orderId);
    if (customerRefundData) {
      // fetch all reasons list...
      let reasons = yield call(service.getAllCustomerRefundReasons);
      yield put(initAllReasons(fromJS(reasons)));

      // fetch and store partner details...
      const partnerDetails = yield call(
        [service, service.getPartnerDetails],
        customerRefundData.orderSummary.PartnerInternalId
      );
      yield put(savePartnerDetails(fromJS(partnerDetails)));

      // fetch history and put to state...
      const customerRefundHistory = yield call(
        [service, service.getOrderCustomerRefundHistory],
        customerRefundData.orderSummary.SafeId,
        partnerDetails
      );
      yield put(saveCustomerRefundHistory(fromJS(customerRefundHistory)));

      // store customer refund data that was passed in via local storage, to state...
      yield put(saveCustomerRefundData(fromJS(customerRefundData)));

      yield put(showLoading(false));
    } else {
      window.location.hash = `#/orders-new/${action.payload.orderId}/summary`;
    }
  } catch (error) {
    yield put(toastText('Customer refund initialization error.'));
  }
}

function* handleSubmitCustomerRefund(action) {
  yield put(showLoading(true));
  const request = yield select(submitCustomerRefundRequestSelector);

  // update notes from payload, because they are stored in component state,
  // not in global state, and passed in with action...
  request.body.ItemRefund = parseFloat(action.payload.itemAmount);
  request.body.ItemTaxRefund = parseFloat(action.payload.itemTaxAmount);
  request.body.ShippingRefund = parseFloat(action.payload.shippingAmount);
  request.body.ShippingTaxRefund = parseFloat(action.payload.shippingTaxAmount);
  request.body.Reason = action.payload.reason?.get('name');
  request.body.ReasonCode = action.payload.reason?.get('code');

  try {
    yield call([service, service.submitCustomerRefund], request);
    yield put(showLoading(false));
    yield put(toastText('Customer refund request successfully placed.'));
    // after aprox 3 seconds, redirect to orders page. during that 3 secs, Toast
    // is diplayed...
    yield call(delay, 3000);
    // clear customer refund data state
    yield put(refresh());
    window.location.hash = `#/orders-new/${action.payload.orderId}/summary`;
  } catch (error) {
    yield put(toastText('Unknown error occured. Please contact customer support.'));
    yield put(showLoading(false));
  }
}

function* watchInitCustomerRefund() {
  yield takeLatest(INIT_CUSTOMER_REFUND, handleInitCustomerRefund);
}

function* watchSubmitCustomerRefund() {
  yield takeLatest(SUBMIT_CUSTOMER_REFUND, handleSubmitCustomerRefund);
}

function* rootSaga() {
  yield all([watchInitCustomerRefund(), watchSubmitCustomerRefund()]);
}

const registerSaga = () => {
  injectSaga(rootSaga);
};

export default registerSaga;
