import Config from '../../../../config';

export const pricingListUrl = () => {
  const partnerGroup = Config.get('partnerGroup');
  return partnerGroup != null
    ? `https://gtnadminassets.blob.core.windows.net/productpricing/${partnerGroup
        .get('Name')
        .toLowerCase()}/`
    : `https://gtnadminassets.blob.core.windows.net/productpricing/`;
};
