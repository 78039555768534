import { Record, List } from 'immutable';
import Config from '../config';

export const Product = new Record({
  id: undefined,
  name: '',
  type: 'product',
  necklabel: false,
  out_of_stock: false,
  cheapest_price: '$0.00',
  cheapest_shipping: '$0.00',
  categories: new List(),
  subcategories: new List(),
  image: '',
  details: undefined,
  variants: undefined,
  vendor_routing: '',
  embroidery: false,
  partner_ids: ''
});

export const mapProduct = obj =>
  new Product({
    ...obj,
    id: obj[Config.get('productIdPropName')],
    partner_ids: obj.partner_ids ? obj.partner_ids.toString() : ''
  });
