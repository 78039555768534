import { fromJS } from 'immutable';
import { STORE_SELECT, STORES_FETCH, STORE_REFRESH, STORE_LOG_OUT } from './StoresActions';
import { STORE_CONNECT } from '../../../ConnectStoreView/ConnectStoreViewActions';
import { PROVIDER_SELECT } from '../Providers/ProvidersActions';
import { RESET } from '../../../../store/actions/globalActions';
import { PUBLISH } from 'gooten-components/src/store/actions/dataActions';
import initialState from '../../../../store/initialState';

const defaultState = initialState.getIn(['hub', 'hubView', 'stores']);

const storesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        // reset state only if scope is global
        return defaultState;
      } else {
        return state;
      }
    }

    case STORE_SELECT: {
      return state.set('selected', action.payload.store);
    }

    case STORES_FETCH.SUCCESS: {
      return state.set('items', fromJS(action.payload.stores)).set('loaded', true);
    }

    case STORE_REFRESH.SUCCESS: {
      return state.update('items', items =>
        items.update(
          items.findIndex(i => i.get('id') === action.payload.data.id),
          item =>
            item
              .set('settings', fromJS(action.payload.data.settings))
              .set('hasProducts', action.payload.data.settings.products_count > 0)
              .set('hasProductsToLink', !!action.payload.data.settings.unconnected_products_count)
        )
      );
    }

    case PROVIDER_SELECT: {
      if (action.payload.reset) {
        return state.set('selected', undefined);
      } else {
        return state;
      }
    }

    case PUBLISH.SUCCESS: {
      // Need to reload store to fetch new collections
      return state
        .set('selected', defaultState.get('selected'))
        .set('loaded', defaultState.get('loaded'))
        .set('items', defaultState.get('items'));
    }

    case STORE_LOG_OUT.SUCCESS: {
      // just remove store and reset selected
      return state
        .set('selected', defaultState.get('selected'))
        .update('items', items => items.filter(i => i.get('id') !== action.payload.storeId));
    }

    case STORE_CONNECT.SUCCESS: {
      // add connected store and make it selected
      return state
        .set('selected', action.payload.store.id)
        .update('items', items => items.push(action.payload.store));
    }

    default:
      return state;
  }
};

export default storesReducer;
