import React from 'react';
import PropTypes from 'prop-types';
import CategoriesNav from './atoms/CategoriesNav';
import SearchBar from './atoms/SearchBar';
import ProductList from './atoms/ProductList';
import PricingModal from './atoms/PricingModal';
import SideBar from '../shared/SideBar';
import ProductDetails from './atoms/ProductDetails';
import Loader from '../shared/Loader';
import analyticsService from '../../services/analyticsService';
import PageBody from '../shared/PageBody';
import ToastMessage from './atoms/ToastMessage';
import { pricingListUrl } from './atoms/PricingModal/PricingModalSelectors';

class ProductSelectionComponent extends React.Component {
  static propTypes = {
    hasData: PropTypes.bool.isRequired,
    hasSelectedProduct: PropTypes.bool,
    loadData: PropTypes.func.isRequired,
    cleanSelection: PropTypes.func.isRequired,
    location: PropTypes.object,
    useProduct: PropTypes.func,
    gotoNextStep: PropTypes.func,
    sidebar: PropTypes.object,
    closeSideBar: PropTypes.func
  };

  state = {
    viewProductPricing: null,
    toastMessage: null
  };

  componentDidMount() {
    analyticsService.trackWithConfigSource('Product Selection Page Reached');
    if (this.preselectedProductId) {
      // set selected product from provided in query string
      this.props.useProduct(this.preselectedProductId);
    }
    if (!this.props.hasData) {
      this.props.loadData();
    } else if (!this.preselectedProductId) {
      // product in query sting was not provided - clean any selection
      this.props.cleanSelection();
    }
  }

  componentDidUpdate() {
    if (this.props.hasSelectedProduct && this.props.hasData) {
      // product was preselected and data received - redirect to SKU selection step
      this.props.gotoNextStep();
    }
  }

  get preselectedProductId() {
    // try to get preselected product from url
    // "location" for this component is optional
    return this.props.location && this.props.location.query.product
      ? parseInt(this.props.location.query.product)
      : null;
  }

  render() {
    const exportPricingList = productName => {
      let fileName = productName
        .replace(/[^a-z]/gi, '_') // replace all non-alpha characters with an underscore
        .replace(/_+/g, '_') // combine multiple underscores next to each other into one
        .replace(/_$/, '') // remove trailing underscores
        .toLowerCase(); // make all characters lowercase

      const a = document.createElement('a');
      a.href = `${pricingListUrl()}${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      this.setState({
        toastMessage: `Price List for ${productName} is being downloaded.`
      });
    };

    let content = null;
    if (!this.props.hasData || this.preselectedProductId || this.props.hasSelectedProduct) {
      content = <Loader />;
    } else {
      content = (
        <PageBody size="wide" background="none">
          <div className="title-1 ml-3 catalog-title">Product Catalog</div>
          <SearchBar showTitle />
          <div className="categories-and-list d-flex">
            <CategoriesNav />
            <ProductList
              showPricingModal={productName => {
                this.setState({ viewProductPricing: productName });
              }}
              exportPricingList={productName => exportPricingList(productName)}
            />
          </div>
          <SideBar isOpen={this.props.sidebar.isOpen} onClose={() => this.props.closeSideBar()}>
            <ProductDetails onGetStarted={() => this.props.closeSideBar()} />
          </SideBar>
          {this.state.viewProductPricing && (
            <PricingModal
              productName={this.state.viewProductPricing}
              exportPricingList={productName => exportPricingList(productName)}
              closePricingModal={() => this.setState({ viewProductPricing: null })}
            />
          )}
          {this.state.toastMessage && (
            <ToastMessage
              toastMessage={this.state.toastMessage}
              clearToast={() => this.setState({ toastMessage: null })}
            />
          )}
        </PageBody>
      );
    }

    return (
      <div className="product-selection-container">
        {content}
        <style jsx>
          {`
            :global(.catalog-title) {
              position: absolute;
              top: 1.5rem;
            }
            @media screen and (max-width: 991px) {
              .product-selection-container {
                padding-top: 1.5rem;
              }
            }
          `}
        </style>
      </div>
    );
  }
}

export default ProductSelectionComponent;
