import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'ACTIONS');

// ------------------------------------
// Constants
// ------------------------------------
export const DISPLAY_CHANGE = getActionName('DISPLAY_CHANGE');
export const VIEW_BY_CHANGE = getActionName('VIEW_BY_CHANGE');
export const SORT_BY_CHANGE = getActionName('SORT_BY_CHANGE');

export const SELECT_ALL = getActionName('SELECT_ALL');
export const UNSELECT_ALL = getActionName('UNSELECT_ALL');

export const UNPUBLISH_PRODUCTS = getActionName.async('UNPUBLISH_PRODUCTS');
export const PUBLISH_PRODUCTS = getActionName.async('PUBLISH_PRODUCTS');
export const DELETE_PRODUCTS = getActionName.async('DELETE_PRODUCTS');
export const UNLINK_PRODUCTS = getActionName.async('UNLINK_PRODUCTS');
export const PERSONALIZE_PRODUCTS = getActionName.async('PERSONALIZE_PRODUCTS');
export const DEPERSONALIZE_PRODUCTS = getActionName.async('DEPERSONALIZE_PRODUCTS');
export const FETCH_SHOPIFY_TOKEN_SCOPES = getActionName.async('FETCH_SHOPIFY_TOKEN_SCOPES');

// ------------------------------------
// Actions
// ------------------------------------
export const displayChange = (display = 'grid') => ({
  type: DISPLAY_CHANGE,
  payload: display
});

export const sortByChange = (sortBy = undefined) => ({
  type: SORT_BY_CHANGE,
  payload: sortBy
});

export const viewByChange = (viewBy = undefined) => ({
  type: VIEW_BY_CHANGE,
  payload: viewBy
});

export const selectAll = status => ({
  type: SELECT_ALL,
  payload: status
});

export const unselectAll = () => ({
  type: UNSELECT_ALL
});

export const unpublishProducts = products => fetchAsync(UNPUBLISH_PRODUCTS, products);

export function deleteProducts(products) {
  return fetchAsync(DELETE_PRODUCTS, products);
}

export const unlinkProducts = products => fetchAsync(UNLINK_PRODUCTS, products);

export const publishProducts = products => fetchAsync(PUBLISH_PRODUCTS, products);

export const personalizeProducts = products => fetchAsync(PERSONALIZE_PRODUCTS, products);

export const depersonalizeProducts = products => fetchAsync(DEPERSONALIZE_PRODUCTS, products);

export const fetchShopifyTokenScopes = partnerId =>
  fetchAsync(FETCH_SHOPIFY_TOKEN_SCOPES, partnerId);
