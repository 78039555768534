import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { injectSaga } from '../store/sagas';
import { fromJS } from 'immutable';
import {
  UPLOAD_IMAGE,
  saveImageUrl,
  INIT_SELF_SERVICE,
  saveReprintData,
  saveOrderSummary,
  showLoading,
  SUBMIT_REPRINT,
  savePartnerDetails,
  savePartnerPreferences,
  toastText,
  initAllReasons,
  REASON_SELECT,
  setSelectedRules
} from './ReprintSelfServiceActions';
import service from './ReprintSelfServiceService';
import { submitReprintRequestSelector, selectedRulesSelector } from './ReprintSelfServiceSelectors';
import { delay } from 'redux-saga';

function* handleUploadImage(action) {
  try {
    yield put(showLoading(true));
    const result = yield call([service, service.uploadImageEvidence], action.payload);

    if (typeof result === 'string') {
      yield put(saveImageUrl([result]));
    } else {
      const imageUrls = result.filter(p => p.status === 'fulfilled').map(i => i.value);
      yield put(saveImageUrl(imageUrls));

      const errors = result.filter(p => p.status === 'rejected');
      if (errors.length) {
        let errorMsg = `${errors.length} of the selected images are not valid`;
        if (errors.length === 1) {
          errorMsg = 'Selected image is not valid';
        }
        yield put(toastText(errorMsg));
      }
    }

    yield put(showLoading(false));
  } catch (error) {
    console.warn(error);
    yield put(showLoading(false));
  }
}

function* handleInitSelfService() {
  try {
    const reprintData = service.getReprintData();
    if (reprintData) {
      // fetch vendors who support Reprint Self Service...
      const supportedVendorIds = yield call(service.getSupportedVendorIds);

      // fetch all reasons list...
      let reasons = yield call(service.getAllReasons);
      if (reasons) {
        // filter our reasons that are not supported in Reprint Self Service...
        reasons = reasons.filter(r => r.IsSelfService);

        // this is a placeholder reason, it can't be selected in when submitting reprint...
        reasons.splice(0, 0, { Id: 0, ReasonName: '-' });
        yield put(initAllReasons(fromJS(reasons)));
      }

      // mark items supported by vendor...
      reprintData.items = reprintData.items.map(item => ({
        ...item,
        supported: supportedVendorIds.includes(item.VendorId)
      }));

      // if any of items is unsupported for reprinting by vendor, inform user about that...
      if (reprintData.items.find(item => !item.supported)) {
        yield put(toastText('Some of items are not supported, and will not be reprinted.'));
      }

      // store reprint data that was passed in via local storage, to Reprint state
      yield put(saveReprintData(fromJS(reprintData)));

      // fetch and put to state, order summary...
      const orderSummary = yield call([service, service.getOrderSummary], reprintData.orderId);
      yield put(saveOrderSummary(fromJS(orderSummary)));

      // fetch and store partner details...
      const partnerDetails = yield call(
        [service, service.getPartnerDetails],
        orderSummary.PartnerInternalId
      );
      yield put(savePartnerDetails(fromJS(partnerDetails)));

      // fetch and store partner preferences...
      const partnerPreferences = yield call(
        [service, service.getPartnerPreferences],
        orderSummary.PartnerInternalId
      );
      yield put(savePartnerPreferences(fromJS(partnerPreferences)));
    }
  } catch (error) {
    // TO-DO cover case when reprintData is null
  }
}

function* handleSubmitReprint(action) {
  yield put(showLoading(true));
  const request = yield select(submitReprintRequestSelector);

  // update notes from payload, because they are stored in component state,
  // not in global state, and passed in with action...
  request.Meta.AdminComments = action.payload.adminComments;
  request.Meta.RefundReprintReason = action.payload.refundReprintReason;

  try {
    yield call([service, service.submitReprint], request);
    yield put(showLoading(false));
    yield put(toastText('Order successfully placed.'));
    // after aprox 3 seconds, redirect to orders page. during that 3 secs, Toast
    // is diplayed...
    yield call(delay, 3000);
    window.location.hash = '#/orders-new';
  } catch (error) {
    let errorMessage = 'Unknown error occured. Please contact customer support.';
    if (error.HadError && error.Errors.length && error.Errors[0].ErrorMessage) {
      errorMessage = error.Errors[0].ErrorMessage;
    }
    yield put(toastText(errorMessage));
    yield put(showLoading(false));
  }
}

function* handleReasonSelection(action) {
  const rules = yield call([service, service.getRules], action.payload);
  yield put(setSelectedRules(fromJS(rules)));
}

function* watchUploadImage() {
  yield takeLatest(UPLOAD_IMAGE, handleUploadImage);
}

function* watchInitSelfService() {
  yield takeLatest(INIT_SELF_SERVICE, handleInitSelfService);
}

function* watchSubmitReprint() {
  yield takeLatest(SUBMIT_REPRINT, handleSubmitReprint);
}

function* watchReasonSelection() {
  yield takeLatest(REASON_SELECT, handleReasonSelection);
}

function* rootSaga() {
  yield all([
    watchUploadImage(),
    watchInitSelfService(),
    watchSubmitReprint(),
    watchReasonSelection()
  ]);
}

const registerSaga = () => {
  injectSaga(rootSaga);
};

export default registerSaga;
