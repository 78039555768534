import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('HUB-LINK', 'HUB_PRODUCT_SELECTION');

export const ALL_PRODUCTS_FETCH = getActionName.async('ALL_PRODUCTS_FETCH');
export const VARIANTS_FETCH = getActionName.async('VARIANTS_FETCH');
export const SET_QUERY = getActionName('SET_QUERY');
export const UPDATE_QUERY = getActionName('UPDATE_QUERY');
export const QUERY_NEXT_PAGE = getActionName('QUERY_NEXT_PAGE');
export const QUERY_SEARCH = getActionName('QUERY_SEARCH');
export const QUERY_IN_PROGRESS = getActionName('QUERY_IN_PROGRESS');
export const SELECT_PRODUCT = getActionName('SELECT_PRODUCT');
export const PUBLISH_PRODUCT = getActionName('PUBLISH_PRODUCT');
export const PUBLISH_IN_PROGRESS = getActionName('PUBLISH_IN_PROGRESS');
export const SHOW_TOP_NOTIFICATION = getActionName('SHOW_TOP_NOTIFICATION');

export const setQuery = payload => {
  return {
    type: SET_QUERY,
    payload
  };
};

export const updateQuery = (field, value) => {
  return {
    type: UPDATE_QUERY,
    payload: { field, value }
  };
};

export const queryNextPage = () => {
  return {
    type: QUERY_NEXT_PAGE
  };
};

export const querySearch = payload => {
  return {
    type: QUERY_SEARCH,
    payload
  };
};

export const queryInProgress = payload => {
  return {
    type: QUERY_IN_PROGRESS,
    payload
  };
};

export const selectProduct = payload => {
  return {
    type: SELECT_PRODUCT,
    payload
  };
};

export const publishProduct = () => {
  return {
    type: PUBLISH_PRODUCT
  };
};

export const publishInProgress = payload => {
  return {
    type: PUBLISH_IN_PROGRESS,
    payload
  };
};

export const showTopNotification = (show, message) => {
  return {
    type: SHOW_TOP_NOTIFICATION,
    payload: { show, message }
  };
};
