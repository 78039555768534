import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'SKU_SELECTION');

// ------------------------------------
// Constants
// ------------------------------------
export const SELECT_OPTION = getActionName('SELECT_OPTION');
export const SELECT_REGIONS = getActionName('SELECT_REGIONS');
export const DESELECT_REGION = getActionName('DESELECT_REGION');
export const SELECT_BATCH_OPTIONS = getActionName('SELECT_BATCH_OPTIONS');
export const DESELECT_BATCH_OPTIONS = getActionName('DESELECT_BATCH_OPTIONS');
export const DESELECT_OPTION = getActionName('DESELECT_OPTION');
export const GOTO_NEXT_GROUP = getActionName('GOTO_NEXT_GROUP');
export const GOTO_PREV_GROUP = getActionName('GOTO_PREV_GROUP');
export const GOTO_GROUP = getActionName('GOTO_GROUP');
export const SET_CASCADE_OPTION = getActionName('SET_CASCADE_OPTION');
export const TOGGLE_DONT_SHOW_ME_AGAIN_NOTIFICATIONS_ITEM = getActionName(
  'TOGGLE_DONT_SHOW_ME_AGAIN_NOTIFICATIONS_ITEM'
);
export const HIDE_MARKED_NOTIFICATIONS = getActionName('HIDE_MARKED_NOTIFICATIONS');
export const CLEAN_SELECTED_OPTIONS = getActionName('CLEAN_SELECTED_OPTIONS');
export const CLEAN_SELECTED_REGIONS = getActionName('CLEAN_SELECTED_REGIONS');
export const TOGGLE_PANEL = getActionName('TOGGLE_PANEL');
export const SET_CURRENT_IMAGE = getActionName('SET_CURRENT_IMAGE');
export const SAVE_ORIENTATION = getActionName('SAVE_ORIENTATION');
export const SET_DEFAULT_ORIENTATION = getActionName('SET_DEFAULT_ORIENTATION');
export const ORIENTATION_CHANGED = getActionName('ORIENTATION_CHANGED');
export const SET_PUBLISHED_ORIENTATION_CHANGED = getActionName('SET_PUBLISHED_ORIENTATION_CHANGED');
export const CANCEL_SKU_SELECTION = getActionName('CANCEL_SKU_SELECTION');

export const SELECT_BRAND = getActionName('SELECT_BRAND');
export const SELECT_GENDER = getActionName('SELECT_GENDER');
export const SEARCH_TEXT = getActionName('SEARCH_TEXT');
// ------------------------------------
// Actions
// ------------------------------------

export const setCurrentImage = i => {
  return {
    type: SET_CURRENT_IMAGE,
    payload: { i }
  };
};

export const selectOption = (optionId, valueId) => {
  return {
    type: SELECT_OPTION,
    payload: { optionId, valueId }
  };
};

export const selectBatchOptions = options => {
  return {
    type: SELECT_BATCH_OPTIONS,
    payload: { options }
  };
};

export const deselectBatchOptions = optionIds => {
  return {
    type: DESELECT_BATCH_OPTIONS,
    payload: { optionIds }
  };
};

export const deselectOption = (optionId, valueId) => {
  return {
    type: DESELECT_OPTION,
    payload: { optionId, valueId }
  };
};

export const setCascadeOption = (optionId, valueId, topOptionsIds) => {
  return {
    type: SET_CASCADE_OPTION,
    payload: { optionId, valueId, topOptionsIds }
  };
};

export const gotoNextGroup = () => {
  return {
    type: GOTO_NEXT_GROUP
  };
};

export const gotoPrevGroup = () => {
  return {
    type: GOTO_PREV_GROUP
  };
};

export const gotoGroup = groupId => {
  return {
    type: GOTO_GROUP,
    payload: { groupId }
  };
};

export const toggleDontShowMeAgain = key => {
  return {
    type: TOGGLE_DONT_SHOW_ME_AGAIN_NOTIFICATIONS_ITEM,
    payload: key
  };
};

export const hideMarkedNotifications = keys => {
  return {
    type: HIDE_MARKED_NOTIFICATIONS,
    payload: keys
  };
};

export const cleanSelectedOptions = () => {
  return {
    type: CLEAN_SELECTED_OPTIONS
  };
};

export const cleanSelectedRegions = () => {
  return {
    type: CLEAN_SELECTED_REGIONS
  };
};

export const selectRegions = regions => {
  return {
    type: SELECT_REGIONS,
    payload: { regions }
  };
};

export const deselectRegion = region => {
  return {
    type: DESELECT_REGION,
    payload: { region }
  };
};

export const togglePanel = () => {
  return {
    type: TOGGLE_PANEL
  };
};

export const saveOrientation = payload => ({
  type: SAVE_ORIENTATION,
  payload
});

export const setDefaultOrientation = payload => ({
  type: SET_DEFAULT_ORIENTATION,
  payload
});

export const orientationChanged = payload => ({
  type: ORIENTATION_CHANGED,
  payload
});

export const setPublishedOrientationChanged = payload => ({
  type: SET_PUBLISHED_ORIENTATION_CHANGED,
  payload
});

export const cancelSkuSelection = () => ({
  type: CANCEL_SKU_SELECTION
});

export const brandSelect = payload => ({
  type: SELECT_BRAND,
  payload
});

export const genderSelect = payload => ({
  type: SELECT_GENDER,
  payload
});

export const searchChanged = (value = '') => {
  return {
    type: SEARCH_TEXT,
    payload: value
  };
};
