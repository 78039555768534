import { injectSaga } from '../../store/sagas';
import productSelectionViewSaga from './ProductSelectionView/ProductSelectionViewSaga';
import SKUsSelectionViewSaga from './SKUsSelectionView/SKUsSelectionViewSaga';
import productDesignViewSaga from './ProductDesignView/ProductDesignViewSaga';
import hubProductSelectionSaga from './SKUsSelectionView/components/ProductSelection/components/HubProductSelection/HubProductSelectionSaga';

const registerLinkProductSagas = () => {
  injectSaga(productSelectionViewSaga);
  injectSaga(SKUsSelectionViewSaga);
  injectSaga(productDesignViewSaga);
  injectSaga(hubProductSelectionSaga);
};

export default registerLinkProductSagas;
