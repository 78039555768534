import { fromJS } from 'immutable';
import defaultState from '../initialState';
import { LOCATION_CHANGE } from '../actions/locationActions';
import { RESET } from '../actions/globalActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RESET]: (state, action) => {
    if (action.payload.scope === 'global') {
      // reset state only if scope is global
      return defaultState.get('location');
    } else {
      return state;
    }
  },
  [LOCATION_CHANGE]: (state, action) =>
    state.update('history', history => history.push(fromJS(action.payload)))
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = defaultState.get('location'), action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
