import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ProductList.scss';
import ProductListLine from './ProductListLine';
import { List } from 'immutable';
import { SubCategory } from '../../../../models/Category';
import { animateScrollTo } from '../../../../utils/scroll';
import EmptySearchResult from './EmptySearchResult';
import { COLORS } from '../../../../constants';
import Config from '../../../../config';

export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProduct: 0,
      selectedSubCategoryName: this.props.selectedSubCategory
        ? this.props.selectedSubCategory.get('name')
        : null
    };
    this.scrollToSelectedSubCategory = this.scrollToSelectedSubCategory.bind(this);
    this.onClickGetStarted = this.onClickGetStarted.bind(this);
    this.onActivate = this.onActivate.bind(this);
  }

  scrollToSelectedSubCategory() {
    // gooten-components-container is mount node
    if (
      this.props.selectedSubCategory === null &&
      document.getElementsByClassName('gooten-components-container')[0]
    ) {
      document.getElementsByClassName('gooten-components-container')[0].scrollTop = 0;
      this.setState({ needScroll: false });
    } else if (this.state.needScroll) {
      animateScrollTo(
        document.getElementsByClassName('gooten-components-container')[0],
        `product-list-line-${this.state.selectedSubCategoryName.toLowerCase().replace(/ /g, '-')}`,
        300,
        200
      );
      this.props.forceScrollDone();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (
        this.props.selectedSubCategory &&
        this.props.selectedSubCategory.get('name') !== this.state.selectedSubCategoryName
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(
          {
            selectedSubCategoryName: this.props.selectedSubCategory.get('name'),
            needScroll: true
          },
          () => {
            this.scrollToSelectedSubCategory();
          }
        );
      } else if (!this.state.needScroll && this.props.needForceScroll) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ needScroll: true }, () => {
          this.scrollToSelectedSubCategory();
        });
      } else if (this.state.needScroll) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ needScroll: false });
      }
    }
  }

  componentDidMount() {
    this.scrollToSelectedSubCategory();
  }

  onClickGetStarted(productId) {
    this.props.selectProduct(productId);
    this.props.gotoNextStep();
  }

  onActivate(productId) {
    this.setState({ activeProduct: productId });
  }

  arrayMoveItem(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  render() {
    // filter out any product categories with no items
    let categoriesWithProducts = this.props.productLists
      ? this.props.productLists.toJS().filter(p => p.products.size)
      : [];

    const cbhSettings = Config.get('cbhSettings') && Config.get('cbhSettings').toJS();

    // if this partner is a CBH partner, we have the ability to order the products in a custom order
    if (cbhSettings && cbhSettings.Config.productListingOrder.length > 0) {
      let currentPos = 0;
      // custom ordering was defined.  Loop through each product type and reorder them accordingly
      for (let i = 0; i < cbhSettings.Config.productListingOrder.length; i++) {
        let foundIndex = categoriesWithProducts.findIndex(
          x => x.name === cbhSettings.Config.productListingOrder[i]
        );
        if (foundIndex >= 0) {
          this.arrayMoveItem(categoriesWithProducts, foundIndex, currentPos);
          currentPos++;
        }
      }
    }

    // convert the product data to jsx
    let productListData = categoriesWithProducts.map((item, i) => (
      <ProductListLine
        key={i}
        products={item.products}
        subcategoryName={item.name}
        activeProductId={this.state.activeProduct}
        onActivate={this.onActivate}
        onClickGetStarted={this.onClickGetStarted}
        onClickLearnMore={this.props.onClickLearnMore}
        showPricingModal={this.props.showPricingModal}
        exportPricingList={this.props.exportPricingList}
      />
    ));

    return (
      <div className="product-list-container w-100">
        {productListData.length > 0 ? (
          productListData
        ) : (
          <EmptySearchResult catalogSearch>
            <p>Try searching for a different product</p>
            <style jsx>{`
              color: ${COLORS.grey};
              font-size: 1.125rem;
            `}</style>
          </EmptySearchResult>
        )}
      </div>
    );
  }
}

ProductList.propTypes = {
  productLists: PropTypes.instanceOf(List).isRequired,
  selectedSubCategory: PropTypes.instanceOf(SubCategory),
  // eslint-disable-next-line react/no-unused-prop-types
  needForceScroll: PropTypes.bool.isRequired,
  onClickLearnMore: PropTypes.func.isRequired,
  gotoNextStep: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  forceScrollDone: PropTypes.func.isRequired
};

export default ProductList;
