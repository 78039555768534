import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'CHECKOUT');

export const SET_SHIPPING_ADDRESS = getActionName('SET_SHIPPING_ADDRESS');
export const UPDATE_SHIPPING_METHODS = getActionName('UPDATE_SHIPPING_METHODS');
export const UPDATE_SURCHARGE_COSTS = getActionName('UPDATE_SURCHARGE_COSTS');
export const SET_SHIPPING_METHOD_FOR_GROUP = getActionName('SET_SHIPPING_METHOD_FOR_GROUP');
export const PLACE_ORDER = getActionName.async('PLACE_ORDER');
export const CLEAR_ORDER = getActionName('CLEAR_ORDER');

export const setShippingAddress = address => ({
  type: SET_SHIPPING_ADDRESS,
  payload: {
    address
  }
});

export const updateShippingMethods = (shippingMethods, errors) => ({
  type: UPDATE_SHIPPING_METHODS,
  payload: {
    shippingMethods,
    errors
  }
});

export const updateSurchargeCosts = surchargeCosts => ({
  type: UPDATE_SURCHARGE_COSTS,
  payload: { surchargeCosts }
});

export const setShippingMethodForGroup = (SKUs, shippingMethodId) => ({
  type: SET_SHIPPING_METHOD_FOR_GROUP,
  payload: {
    SKUs,
    shippingMethodId
  }
});

export const clearOrder = () => ({
  type: CLEAR_ORDER
});
