import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('HUB', 'ConnectStoreView');

// ------------------------------------
// Constants
// ------------------------------------
export const INIT_CONNECT = getActionName.async('INIT_CONNECT');
export const STORE_CONNECT = getActionName.async('STORE_CONNECT');
export const WOO_STORE_CONNECT = getActionName.async('WOO_STORE_CONNECT');

// ------------------------------------
// Actions
// ------------------------------------
export function initStoreConnect(provider, state) {
  return {
    type: INIT_CONNECT.ASYNC,
    payload: { provider, state }
  };
}

export function connectStore(provider, store) {
  return {
    type: STORE_CONNECT.ASYNC,
    payload: { provider, store }
  };
}

export function connectStoreSuccess(store) {
  return {
    type: STORE_CONNECT.SUCCESS,
    payload: { store }
  };
}

export function connectStoreFail(errorMsg) {
  return {
    type: STORE_CONNECT.FAIL,
    payload: { errorMsg }
  };
}

export function connectStoreCancel() {
  return {
    type: STORE_CONNECT.CANCEL,
    payload: {}
  };
}
