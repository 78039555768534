import {
  SEARCH_START,
  ORDERS_REQUEST,
  UPDATE_SEARCH_DATA,
  UPDATE_PAGE_NUMBER,
  UPDATE_TAB_NUMBER,
  TOGGLE_SELECTED_ROW,
  TOGGLE_SELECTED_ROWS,
  UPDATE_SEARCH_FIELDS,
  UPDATE_SEARCH_FIELDS_OBJECT,
  UPDATE_SEARCH_FIELDS_OBJECT_INITIAL,
  CHANGE_STATUS,
  CHANGE_TRACKING,
  UPDATE_ORDER_ORDER_ITEM,
  UPDATE_SEARCH_DATA_FAIL,
  UPDATE_PERSONALIZED_IMAGES
} from './OrdersViewActions';
import { fromJS } from 'immutable';
import initialState from './../../store/initialState';

const defaultState = initialState.getIn(['orders', 'ordersView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_SELECTED_ROWS:
      const updatedRowsSelected = state
        .getIn(['searchData', 'items'])
        .toJS()
        .map(item => {
          item.selectedByUser = !action.payload.selected;
          return item;
        });
      return state.setIn(['searchData', 'items'], fromJS(updatedRowsSelected));
    case TOGGLE_SELECTED_ROW:
      const updatedState = state
        .getIn(['searchData', 'items'])
        .toJS()
        .map(item => {
          if (action.payload.type === 'Order' && item.OrderId === action.payload.orderId) {
            item.selectedByUser = !item.selectedByUser;
          }

          if (action.payload.type === 'OrderItem' && item.OrderItemId === action.payload.orderId) {
            item.selectedByUser = !item.selectedByUser;
          }

          return item;
        });
      return state.setIn(['searchData', 'items'], fromJS(updatedState));
    case SEARCH_START:
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .setIn(['searchData', 'total'], fromJS(null))
        .setIn(['searchData', 'items'], fromJS([]))
        .setIn(['searchData', 'key'], fromJS(null));
    case UPDATE_PAGE_NUMBER:
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .setIn(['searchFields', 'page'], action.payload.pageNumber);
    case UPDATE_TAB_NUMBER:
      return state
        .set('errors', [])
        .setIn(['searchFields', 'group'], action.payload.tabNumber)
        .setIn(['searchFields', 'page'], 1);
    case UPDATE_SEARCH_DATA:
      let searchFields = state.get('searchFields');
      const searchPartnerIds = searchFields.get('PartnerIds');
      const items = action.payload.data.get('items');
      // query was filtered by partner id and has results
      if (searchPartnerIds.size > 0 && items.size > 0) {
        // partner search allowed ony by single partner so we can take it from first item
        const partnerName = items.getIn([0, 'PartnerName']);
        // populate with partner name
        searchFields = searchFields.set(
          'PartnerIds',
          searchPartnerIds.map(item =>
            item instanceof Object
              ? item
              : fromJS({
                  key: item,
                  label: partnerName
                })
          )
        );
      }
      return state
        .set('errors', [])
        .set('searchData', action.payload.data)
        .set('searchFields', searchFields)
        .set('searchLoading', false);
    case UPDATE_SEARCH_DATA_FAIL:
      return state.set('errors', action.payload.errors).set('searchLoading', false);
    case CHANGE_STATUS.ASYNC:
      return state.set('errors', []).set('searchLoading', true);
    case CHANGE_STATUS.SUCCESS:
      return state
        .setIn(['searchData', 'items'], fromJS(action.payload))
        .set('searchLoading', false);
    case CHANGE_TRACKING.SUCCESS:
      return state.updateIn(['searchData', 'items'], items =>
        items.map(item =>
          item.set(
            'TrackingNumber',
            (action.payload.ids || []).includes(item.get('OrderItemId'))
              ? action.payload.trackingNumber
              : item.get('TrackingNumber')
          )
        )
      );
    case CHANGE_STATUS.FAIL:
      return state.set('errors', action.payload).set('searchLoading', false);
    case ORDERS_REQUEST.ASYNC:
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .setIn(['searchData', 'total'], fromJS(null))
        .setIn(['searchData', 'items'], fromJS([]))
        .setIn(['searchData', 'key'], fromJS(null));
    case ORDERS_REQUEST.SUCCESS:
      return state.set('searchLoading', false).set('searchData', action.payload.response);
    case ORDERS_REQUEST.FAIL:
      return state
        .set('searchLoading', false)
        .set('errors', ['There was a problem loading orders']);
    case UPDATE_SEARCH_FIELDS:
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .setIn(['searchFields', action.payload.key], action.payload.value)
        .setIn(['searchFields', 'page'], 1)
        .setIn(
          ['searchFields', 'SortField'],
          action.payload.sortField || state.getIn(['searchFields', 'SortField'])
        )
        .setIn(['searchData', 'total'], fromJS(null))
        .setIn(['searchData', 'items'], fromJS([]))
        .setIn(['searchData', 'key'], fromJS(null));
    case UPDATE_SEARCH_FIELDS_OBJECT:
      const updatedSearchFieldState = {
        ...state.get('searchFields').toJS(),
        ...action.payload.searchObject,
        page: 1
      };
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .set('searchFields', fromJS(updatedSearchFieldState));
    case UPDATE_SEARCH_FIELDS_OBJECT_INITIAL:
      const updatedSearchFieldStateInitial = {
        ...state.get('searchFields').toJS(),
        ...action.payload.searchObject
      };
      return state
        .set('errors', [])
        .set('searchLoading', true)
        .set('searchFields', fromJS(updatedSearchFieldStateInitial));
    case UPDATE_ORDER_ORDER_ITEM:
      return state.set(
        'searchFields',
        fromJS({
          ...state.get('searchFields').toJS(),
          ...action.payload.searchObject,
          page: 1
        })
      );
    case UPDATE_PERSONALIZED_IMAGES.ASYNC:
      return state.set('searchLoading', true);
    case UPDATE_PERSONALIZED_IMAGES.SUCCESS:
    case UPDATE_PERSONALIZED_IMAGES.FAIL:
      return state.set('searchLoading', false);
    default:
      return state;
  }
};
