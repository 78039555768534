import { fromJS } from 'immutable';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import {
  UPDATE_CARD,
  REMOVE_METHOD,
  UPDATE_PAYPAL,
  UPDATE_DEFAULT_METHOD,
  GET_SETUP_INTENT_ID,
  SAVE_SETUP_INTENT_ID,
  HANDLE_STRIPE_RESPONSE,
  updateToken,
  saveSetupIntentId
} from './CreditCardActions';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import { watchInitAsync } from '../BaseCard/BaseCardSaga';
import { cardSelectorFor } from '../BaseCard/BaseCardSelectors';
import { initFor } from '../BaseCard/BaseCardActions';
import CreditCard from './CreditCard';
import analyticsService from 'gooten-components/src/services/analyticsService';

const cardStateSelector = cardSelectorFor(CreditCard.name);

export function* UpdatePaypalAsyncHandler(action) {
  let result = null;
  try {
    const state = yield select(cardStateSelector);
    const braintreeToken = state.get('braintreeToken');
    result = yield call(settingsApiService.updatePayPal, action.payload, braintreeToken);
    const newData = fromJS(result);
    yield put(fetchAsyncSuccess(UPDATE_PAYPAL, { ...action.payload, newData }));
    const init = initFor(CreditCard.name);
    yield put(init());
    yield put(push('PayPal information updated', 'success'));
    analyticsService.track('Settings - Credit Card', 'Payment Method Added', 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE_CARD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* UpdateCardAsyncHandler(action) {
  let result = null;
  try {
    const state = yield select(cardStateSelector);
    const braintreeToken = state.get('braintreeToken');
    result = yield call(
      settingsApiService.updateCreditCard,
      action.payload.data.toJS(),
      braintreeToken
    );
    const newData = fromJS(result);
    yield put(fetchAsyncSuccess(UPDATE_CARD, { ...action.payload, newData }));
    yield put(push('Credit Card information updated', 'success'));
    analyticsService.track('Settings - Credit Card', 'Payment Method Added', 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE_CARD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* RemoveMethodAsyncHandler(action) {
  let result = null;
  try {
    result = yield call(settingsApiService.removePaymentMethod, action.payload.methodToken);
    const newData = fromJS(result);
    yield put(fetchAsyncSuccess(REMOVE_METHOD, { ...action.payload, newData }));
    yield put(push('Payment Method removed', 'success'));
    analyticsService.track('Settings - Credit Card', 'Payment Method Removed', 'Settings');
    const init = initFor(CreditCard.name);
    yield put(init());
  } catch (err) {
    yield put(fetchAsyncFail(REMOVE_METHOD, err));
    yield put(push('Failed to remove Payment Method', 'failure'));
  }
}

export function* UpdateDefaultPaymentMethodAsyncHandler(action) {
  let result = null;
  try {
    result = yield call(settingsApiService.updateDefaultPaymentMethod, action.payload.methodToken);
    const newData = fromJS(result);
    yield put(fetchAsyncSuccess(UPDATE_DEFAULT_METHOD, { ...action.payload, newData }));
    yield put(push('Default payment method updated', 'success'));
    analyticsService.track('Settings - Credit Card', 'Default Payment Method Updated', 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE_DEFAULT_METHOD, err.msg));
    yield put(push(err.msg, 'failure'));
  }
}

export function* GetSetupIntentIdAsyncHandler(action) {
  try {
    const result = yield call(settingsApiService.getSetupIntentId);
    if (result.SetupIntentId && result.ClientId) {
      yield put(saveSetupIntentId(result));
    } else {
      yield put(fetchAsyncFail(GET_SETUP_INTENT_ID));
      yield put(push('Stripe Customer ID not found', 'failure'));
    }
  } catch (err) {
    yield put(fetchAsyncFail(GET_SETUP_INTENT_ID));
    yield put(push('Stripe Customer ID not found', 'failure'));
  }
}

export function* HandleStripeResponseHandler(data) {
  try {
    if (data.payload.error) {
      yield put(fetchAsyncFail(HANDLE_STRIPE_RESPONSE));
      yield put(push(data.payload.error.message, 'failure'));
    } else {
      const result = yield call(
        settingsApiService.savePaymentMethod,
        data.payload.result.setupIntent.payment_method,
        data.payload.primaryPaymentMethod
      );
      if (result['paymentMethodId']) {
        const newData = fromJS(result);
        yield put(fetchAsyncSuccess(UPDATE_CARD, { newData }));
        yield put(fetchAsyncSuccess(HANDLE_STRIPE_RESPONSE));
        yield put(push('Card added successfully', 'success'));
      } else {
        yield put(fetchAsyncFail(HANDLE_STRIPE_RESPONSE));
        yield put(push('Failed to save card info', 'failure'));
      }
    }
  } catch (err) {
    yield put(fetchAsyncFail(HANDLE_STRIPE_RESPONSE));
    yield put(push('Failed to save card info', 'failure'));
  }
}

export function* watchUpdateCard() {
  yield takeLatest(UPDATE_CARD.ASYNC, UpdateCardAsyncHandler);
}

export function* watchRemoveMethod() {
  yield takeLatest(REMOVE_METHOD.ASYNC, RemoveMethodAsyncHandler);
}

export function* watchUpdatePaypal() {
  yield takeLatest(UPDATE_PAYPAL.ASYNC, UpdatePaypalAsyncHandler);
}

export function* watchUpdateDefaultPaymentMethod() {
  yield takeLatest(UPDATE_DEFAULT_METHOD.ASYNC, UpdateDefaultPaymentMethodAsyncHandler);
}

export function* watchGetSetupIntentId() {
  yield takeLatest(GET_SETUP_INTENT_ID.ASYNC, GetSetupIntentIdAsyncHandler);
}

export function* watchHandleStripeResponse() {
  yield takeLatest(HANDLE_STRIPE_RESPONSE.ASYNC, HandleStripeResponseHandler);
}

export function* preInit() {
  const result = yield call(settingsApiService.getBraintreeToken);
  yield put(updateToken(result.token));
}

export default function* rootSaga() {
  yield all([
    watchInitAsync(CreditCard.name, CreditCard.messages, false, preInit),
    watchUpdateCard(),
    watchUpdatePaypal(),
    watchRemoveMethod(),
    watchUpdateDefaultPaymentMethod(),
    watchGetSetupIntentId(),
    watchHandleStripeResponse()
  ]);
}
