import {
  HUB_PRODUCTS_LOAD,
  TOGGLE_HUB_PRODUCT_SELECTED,
  DESELECT_ALL,
  SET_CURRENT_PAGE,
  SET_SORT_VALUE
} from './HubProductsActions';
import { SEARCH_CHANGE } from '../../HubProductsViewActions';
import { RESET } from '../../../../store/actions/globalActions';
import { ADD_TO_CART_REQUEST } from '../../../CartView/CartViewActions';
import initialState from '../../../../store/initialState';

const defaultState = initialState.getIn(['customOrderForm', 'hubProductsView', 'hubProducts']);

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // NOTE: We have reset action which reset state to initial
  [RESET]: (state, action) => (action.payload.scope === 'global' ? defaultState : state),

  [HUB_PRODUCTS_LOAD.SUCCESS]: (state, action) =>
    state
      .set('items', action.payload.hubProducts.items)
      .set('total', action.payload.hubProducts.total)
      .set('totalPages', action.payload.hubProducts.totalPages)
      .set('loaded', true),
  [TOGGLE_HUB_PRODUCT_SELECTED]: (state, action) =>
    state.update('selected', selectedItems => {
      if (action.payload.isSelected) {
        return selectedItems.push(action.payload.hubProduct);
      } else {
        return selectedItems.filter(item => item.id !== action.payload.hubProduct.id);
      }
    }),
  [DESELECT_ALL]: (state, action) => state.set('selected', defaultState.get('selected')),
  [ADD_TO_CART_REQUEST.ASYNC]: (state, action) =>
    state.set('selected', defaultState.get('selected')),
  [SET_CURRENT_PAGE]: (state, action) =>
    state.set('page', action.payload.page).set('loaded', false),
  [SET_SORT_VALUE]: (state, action) =>
    state.set('sortValue', action.payload.sortValue).set('loaded', false),
  [SEARCH_CHANGE]: (state, action) => state.set('page', 1).set('loaded', false)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
