import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  initActionNameFor,
  updateActionNameFor,
  changePageActionNameFor,
  initFor
} from './BaseCardActions';
import { cardSelectorFor } from './BaseCardSelectors';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import Config from '../../../config';

import Log from 'gooten-components/src/services/logService';

const errorCodeToMessage = {
  PAYMENT_GATEWAY_ERROR:
    'We are experiencing an issue with our payment gateway provider. Please try again later'
};

const initAsyncHandler = (card, actionType, messages, userSpecific, preInit) =>
  function* initAsyncHandler(action) {
    let data = null;
    try {
      if (preInit) {
        yield call(preInit);
      }
      const user =
        action.payload && action.payload.userId
          ? action.payload.userId
          : Config.get('partnerUserId');
      const params = {
        user: userSpecific ? user : null,
        page: action.payload ? action.payload.page : null,
        pageSize: action.payload ? action.payload.pageSize : null
      };
      data = yield call(settingsApiService.get, card, params);
      yield put(fetchAsyncSuccess(actionType, { data, card }));
    } catch (err) {
      if (messages && messages.onInitFailure) {
        yield put(push(messages.onInitFailure, 'failure'));
      }
      if (err && errorCodeToMessage[err]) {
        yield put(push(errorCodeToMessage[err], 'failure'));
      }
      yield put(fetchAsyncFail(actionType, { err, card }));
      Config.get('logSettingErrors') &&
        Log.error(err, `Failed to fetch ${card}`, { action, data, card });
    }
  };

const updateAsyncHandler = (card, actionType, messages, userSpecific, onSuccess) =>
  function* (action) {
    let result = null;
    messages = messages || {};
    try {
      const user = userSpecific
        ? action.payload && action.payload.userId
          ? action.payload.userId
          : Config.get('partnerUserId')
        : null;
      const cardState = yield select(cardSelectorFor(card));
      result = yield call(settingsApiService.update, card, { user }, action.payload.data.toJS());
      yield put(fetchAsyncSuccess(actionType, action.payload));
      if (onSuccess) {
        yield call(onSuccess, cardState, action.payload);
      }
      if (messages.onSuccess) {
        yield put(push(messages.onSuccess, 'success'));
      }
    } catch (err) {
      const errorMessage = err ? err.msg || err.Message : null;
      yield put(fetchAsyncFail(actionType, { err, card }));
      if (messages.onFailure && (!err || !err.validationError)) {
        yield put(push(errorMessage || messages.onFailure, 'failure'));
      }
      if (Config.get('logSettingErrors') && (!err || !err.noLogError) && !messages.noLogError) {
        Log.error(err, `Failed to update ${card}`, { action, result, card });
      }
    }
  };

export function* watchInitAsync(card, messages, userSpecific, preInit) {
  const action = initActionNameFor(card);
  yield takeLatest(action.ASYNC, initAsyncHandler(card, action, messages, userSpecific, preInit));
}

export function* watchUpdateAsync(card, messages, userSpecific, onSuccess) {
  const action = updateActionNameFor(card);
  yield takeLatest(
    action.ASYNC,
    updateAsyncHandler(card, action, messages, userSpecific, onSuccess)
  );
}

export function* watchChangePageAsync(card) {
  yield takeLatest(changePageActionNameFor(card), reloadAsyncHandler(card));
}

const reloadAsyncHandler = card =>
  function* (action) {
    yield put(initFor(card)(action.payload));
  };
