/**
 * Created by tengai on 2/28/17.
 */
export const compareProductNames = (arg1, arg2) => {
  arg1 = arg1.toLowerCase();
  arg2 = arg2.toLowerCase();

  // T-Shirts
  if (arg1 === 't-shirts' && (arg2.startsWith('t ') || arg2.startsWith('ts'))) {
    arg2 = arg2.replace('t ', 't-').replace('ts', 't-s');
  }

  return arg1.indexOf(arg2) >= 0;
};
