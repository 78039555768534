import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import ApiKeys from './ApiKeys';
import { ApiKeysModel } from '../../Models/ApiKeysModel';

export default baseCardReducer(
  ApiKeys.name,
  (
    state = new Map({
      data: new ApiKeysModel(),
      loading: true,
      busy: false,
      open: true
    })
  ) => state,
  ApiKeysModel
);
