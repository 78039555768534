import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button.js';
import { COLORS } from '../../constants.js';

const Dialogue = ({
  title,
  children,
  className,
  isOpen,
  cancelClick,
  cancelText,
  actionText,
  destructiveClick,
  primaryClick,
  ctaClick,
  noBackdrop
}) => {
  return (
    <>
      <div
        className={`dialogue dropshadow-3 ${className || ''} m-auto ${
          typeof title === 'string' ? title.replace(/\s+/g, '-').toLowerCase() : ''
        }`}
      >
        <div className="dialogue-body p-4">
          {title && <div className="title-2 heavy">{title}</div>}
          <div className="pt-2 body-text-2">{children}</div>
          <div className="dialogue-footer mt-4 d-flex row m-0">
            {cancelClick && (
              <Button className="button-default large m-1" onClick={cancelClick}>
                {cancelText || 'Cancel'}
              </Button>
            )}
            {destructiveClick && (
              <Button className="button-destructive large m-1" onClick={destructiveClick}>
                {actionText}
              </Button>
            )}
            {primaryClick && (
              <Button className="button-primary large m-1" onClick={primaryClick}>
                {actionText}
              </Button>
            )}
            {ctaClick && (
              <Button className="button-cta large m-1" onClick={ctaClick}>
                {actionText}
              </Button>
            )}
          </div>
        </div>
      </div>
      {!noBackdrop && <div className="cerulean-backdrop" onClick={cancelClick} />}
      <style jsx>
        {`
          * {
            box-sizing: border-box;
          }
          .cerulean-backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: ${COLORS.overlay500};
            z-index: 1009;
            display: ${isOpen ? 'block' : 'none'};
          }
          .dialogue-footer {
            justify-content: flex-end;
            flex-wrap: wrap;
          }
          .heavy {
            font-family: Avenir Heavy, Avenir-Roman, sans-serif;
          }
          .dialogue {
            z-index: 1010;
            border: 1px solid ${COLORS.neutralLight300};
            border-radius: 6px;
            text-align: left;
            width: 25rem;
            position: relative;
            display: ${isOpen ? 'block' : 'none'};
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: ${COLORS.neutralLight50};
            animation-duration: 0.5s;
            animation-name: fade-in;
          }
          @keyframes fade-in {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .small {
            width: 18rem;
          }
          .small :global(button) {
            flex-wrap: wrap;
          }
          @media screen and (max-width: 576px) {
            .dialogue {
              width: 18rem;
            }
            .dialogue-footer {
              flex-wrap: wrap;
            }
            .dialogue-footer :global(button) {
              width: 100%;
            }
          }
        `}
      </style>
    </>
  );
};

Dialogue.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
  cancelClick: PropTypes.func,
  cancelText: PropTypes.string,
  actionText: PropTypes.string,
  destructiveClick: PropTypes.func,
  primaryClick: PropTypes.func,
  ctaClick: PropTypes.func,
  noBackdrop: PropTypes.bool
};

export default Dialogue;
