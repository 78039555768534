import { delay } from 'redux-saga';
import { takeLatest, take, put, select, all, call } from 'redux-saga/effects';
import {
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH,
  TEMPLATES_FETCH,
  fetchAsync,
  selectProducts,
  skuSelect,
  addToCart
} from 'gooten-components/src/store/actions/dataActions';
import {
  ADD_TO_CART_REQUEST,
  updateLoadingStatus,
  updateRequestData,
  addToCartRequestProductAdded,
  addToCartRequestAsync
} from './CartViewActions';
import {
  hasRequiredProductInfoSelector,
  hasProductsSelector,
  requiredProductInfoSelector,
  selectedProductSelector
} from './CartViewSelectors';
import { mapAddToCartRequestFromGootenMappings } from '../../models/AddToCartRequest';
import CartViewService from './CartViewService';
import AddToCartService from '../shared/AddToCart/AddToCartService';
import { possibleVendorsSelector } from 'gooten-components/src/components/SKUSelection/SKUSelectionSelectors';
import {
  addToCartSelector,
  isWorkingSelector
} from 'gooten-components/src/components/ImageUpload/ImageUploadSelectors';
import {
  WORKING_JOBS_CHANGE,
  IS_READY_CHANGE
} from 'gooten-components/src/components/ImageUpload/ImageUploadActions';
import analyticsService from 'gooten-components/src/services/analyticsService';
import { fromJS } from 'immutable';

export function* addToCartRequestAsyncHandler(action) {
  yield put(updateLoadingStatus('initial'));

  if (action.payload.source === 'COF - Hub Products Flow') {
    // We use store-api in COF HubProducts
    // it requires making additional API call to get gooten mappings
    const gootenMappingIds = action.payload.request.toJS().products.map(product => {
      if (product.gooten_mapping) {
        return product.gooten_mapping.id;
      } else {
        return product.id;
      }
    });

    const fetchedGootenMappings = yield call(
      [CartViewService, CartViewService.loadGootenMappingData],
      {
        ids: gootenMappingIds.toString()
      }
    );
    const updatedAddToCartRequest = mapAddToCartRequestFromGootenMappings(
      fromJS(fetchedGootenMappings.gooten_mappings)
    );
    yield put(updateRequestData(updatedAddToCartRequest));
  }

  const hasRequiredProductInfo = yield select(hasRequiredProductInfoSelector);
  if (!hasRequiredProductInfo) {
    // Skip if required product info missing
    return;
  }

  // load products
  const hasProducts = yield select(hasProductsSelector);

  if (!hasProducts) {
    yield put(fetchAsync(PRODUCTS_FETCH));
    yield take(PRODUCTS_FETCH.SUCCESS);
  }
  yield put(updateLoadingStatus('productsLoaded'));

  let requiredProductInfo = yield select(requiredProductInfoSelector);

  const failure = function* (reason, details) {
    console.log(
      `Adding product to Cart - failed, reason: ${reason}`,
      requiredProductInfo.toJS(),
      details
    );
    yield put(updateLoadingStatus(reason));
    // Delay to show the message
    yield call(delay, 1000);
    yield put(updateLoadingStatus('ready'));
  };

  const productId = requiredProductInfo.productId;

  yield put(selectProducts([productId]));

  // This to measure time of add to cart processing
  const addToCartStartTime = new Date().getTime();

  analyticsService.track('COF - Add To Cart', 'Add To Cart Started', 'COF');
  let selectedProduct = yield select(selectedProductSelector);

  if (!selectedProduct) {
    yield failure('productNotAvailable');
    return;
  }
  // TODO: In parallel
  // load variants
  if (!selectedProduct.get('variants')) {
    yield put(fetchAsync(PRODUCT_VARIANTS_FETCH, selectedProduct.get('name')));
    yield take(PRODUCT_VARIANTS_FETCH.SUCCESS);
    selectedProduct = yield select(selectedProductSelector);
  }
  yield put(updateLoadingStatus('variantsLoaded'));

  // load product details
  if (!selectedProduct.get('details')) {
    yield put(fetchAsync(PRODUCT_DETAILS_FETCH, selectedProduct.get('name')));
    yield take(PRODUCT_DETAILS_FETCH.SUCCESS);
    selectedProduct = yield select(selectedProductSelector);
  }
  yield put(updateLoadingStatus('detailsLoaded'));

  // Get currently added products to the cart details (hub products or past order items)
  const possibleVendors = yield select(possibleVendorsSelector);
  const unorderableSKUs = AddToCartService.filterUnorderableSKUs(
    requiredProductInfo.skus.map(s => s.sku).toArray(),
    selectedProduct.variants,
    possibleVendors
  );
  if (unorderableSKUs.length) {
    yield failure('unorderableSKUs', unorderableSKUs);
    return;
  }

  // Map to the selected skus data format
  const selectedSkus = requiredProductInfo.skus.map(s => ({
    productName: s.productName,
    sku: s.sku,
    prpSKU: s.prpSKU,
    neck_tag_image_url: s.neck_tag_image_url,
    neck_tag_id: s.neck_tag_id,
    orientation: s.orientation,
    spaces: s.images.map(i => ({
      id: i.spaceId,
      images: [{ imageUrl: i.imageUrl, imageId: i.imageId }],
      il: i.il,
      // used in COF for comparsion to check whether prp image was edited
      prpIL: i.prpIL
    })),
    isSample: s.isSample
  }));
  yield put(skuSelect(selectedSkus));
  yield put(fetchAsync(TEMPLATES_FETCH));
  yield take(TEMPLATES_FETCH.SUCCESS);

  // we need to wait until ImageUploadSaga
  // will generate il and previews for every sku space.
  while (true) {
    yield take([WORKING_JOBS_CHANGE, IS_READY_CHANGE]);
    const isImageUploadWorking = yield select(isWorkingSelector);
    if (!isImageUploadWorking) {
      yield put(updateLoadingStatus('imagesLoaded'));
      break;
    }
  }
  const cartItems = yield select(addToCartSelector);
  // cartItems here must have il and preview generated
  yield put(addToCart(cartItems));

  const addToCartTimeInSec = `${(new Date().getTime() - addToCartStartTime) / 1000} sec.`;
  console.log(`Adding product to Cart - Done, took: ${addToCartTimeInSec}`, requiredProductInfo);
  analyticsService.track('COF - Add To Cart', 'Add To Cart Finished', 'COF', null, {
    took: addToCartTimeInSec
  });

  // removes added product from state
  yield put(addToCartRequestProductAdded(productId));
  // If AddToCartRequest has more than 1 product - need repeat
  const hasMoreProductsToAdd = yield select(hasRequiredProductInfoSelector);
  if (hasMoreProductsToAdd) {
    // repeat
    yield addToCartRequestAsyncHandler(addToCartRequestAsync());
  } else {
    yield put(updateLoadingStatus('ready'));
  }
}

export function* watchAddToCartRequestAsync() {
  yield takeLatest(ADD_TO_CART_REQUEST.ASYNC, addToCartRequestAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchAddToCartRequestAsync()]);
}
