import PanelLayout from '../../layouts/PanelLayout';
import React from 'react';
import PropTypes from 'prop-types';
import OptionsPanel from './atoms/OptionsPanel';
import ProductPanel from './atoms/ProductPanel';
import Loader from '../shared/Loader';
import SideBar from '../shared/SideBar';
import ProductDetails from '../ProductSelection/atoms/ProductDetails';
import Config from '../../config';
import windowService from '../../services/windowService';
import analyticsService from '../../services/analyticsService';

import './SKUSelection.scss';
import Dialogue from '../shared/Dialogue';
import PageBody from '../shared/PageBody';
import ApparelPanel from './atoms/ApparelPanel';

class SKUSelection extends React.Component {
  static propTypes = {
    config: PropTypes.object.isRequired,
    collapsed: PropTypes.bool,
    hasProductDetails: PropTypes.bool.isRequired,
    hasProductVariants: PropTypes.bool.isRequired,
    selectedProductName: PropTypes.string.isRequired,
    loadProductVariants: PropTypes.func.isRequired,
    loadProductDetails: PropTypes.func.isRequired,
    createProduct: PropTypes.func.isRequired,
    gotoNextStep: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    cleanSelectedOptions: PropTypes.func.isRequired,
    cleanSelectedRegions: PropTypes.func.isRequired,
    gotoNextGroup: PropTypes.func.isRequired,
    gotoPrevGroup: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    filteredSkus: PropTypes.array,
    optionsGroup: PropTypes.array,
    maxGroupId: PropTypes.number,
    currentGroupId: PropTypes.number,
    isOptionsApplied: PropTypes.bool,
    isRegionsApplied: PropTypes.bool,
    isGroupCompleted: PropTypes.bool,
    isGroupCancellable: PropTypes.bool,
    deselectBatchOptions: PropTypes.func,
    setCurrentImage: PropTypes.func,
    modalValidationMessage: PropTypes.string,
    sidebar: PropTypes.object,
    closeSideBar: PropTypes.func,
    cancelSkuSelection: PropTypes.func,
    isApparel: PropTypes.bool
  };

  state = {
    isSkusOverflowModalOpen: false,
    showErrors: false
  };

  onClickBackButton() {
    if (this.props.isGroupCancellable) {
      if (Config.get('editMode')) {
        // in edit mode the sku selection is first step
        // on click back should redirect to hub
        window.history.go(-1);
      } else {
        this.props.goBack();
        this.props.cleanSelectedOptions();
        this.props.cleanSelectedRegions();
        this.props.setCurrentImage(0);
      }
    } else {
      let isArtworkAppliedToSku = this.props.filteredSkus
        ? this.props.filteredSkus.find(
            x => x.spaces && x.spaces.find(s => s.images && s.images.length)
          )
        : false;
      if (isArtworkAppliedToSku) {
        let alert = confirm('You have unsaved changes. Are you sure you want to leave?');
        if (!alert) return;
      }
      this.props.gotoPrevGroup();
      this.props.deselectBatchOptions(this.props.optionsGroup.map(x => x.id));
    }
  }

  resetErrors() {
    this.setState({ showErrors: false });
  }

  onClickNextButton() {
    this.setState({ showErrors: false });
    const nextButtonActive =
      (this.props.currentGroupId < this.props.maxGroupId ||
        (this.props.isOptionsApplied && this.props.filteredSkus.length > 0)) &&
      this.props.isGroupCompleted &&
      this.props.isRegionsApplied;

    if (!nextButtonActive) {
      this.setState({ showErrors: true });
    } else if (this.props.currentGroupId < this.props.maxGroupId) {
      this.gotoNextGroup();
    } else if (this.props.isOptionsApplied && this.props.filteredSkus.length > 0) {
      // check overflow SKUs and show warning
      if (this.props.modalValidationMessage.length > 0) {
        this.showSkusOverflowWarning();
      } else {
        this.onClickGetStarted();
      }
    } else {
    }
  }

  onClickGetStarted() {
    this.props.createProduct(this.props.filteredSkus);
    this.props.gotoNextStep();
  }

  gotoNextGroup() {
    this.props.gotoNextGroup();
    windowService.scrollSidePanelToTop();
  }

  componentDidMount() {
    analyticsService.trackWithConfigSource('SKU Selection Page Reached');
    if (!this.props.hasProductDetails) {
      this.props.loadProductDetails(this.props.selectedProductName);
    }

    if (!this.props.hasProductVariants) {
      this.props.loadProductVariants(this.props.selectedProductName);
    }
  }

  ignoreSkusOverflowWarning() {
    this.hideSkusOverflowWarning();
    this.onClickGetStarted();
  }

  showSkusOverflowWarning() {
    this.setState({ isSkusOverflowModalOpen: true });
  }

  hideSkusOverflowWarning() {
    this.setState({ isSkusOverflowModalOpen: false });
  }

  render() {
    let content = null;
    if (!this.props.hasProductDetails || !this.props.hasProductVariants) {
      content = <Loader />;
    } else {
      const nextBtnText =
        this.props.currentGroupId < this.props.maxGroupId ? (
          <span>Continue</span>
        ) : (
          <span>
            Continue <span> with {this.props.filteredSkus.length} SKU(s)</span>
          </span>
        );

      content = (
        <div>
          <PanelLayout
            collapsed={this.props.collapsed}
            togglePanel={() => this.props.togglePanel()}
            // in Edit mode don't allow user to go back to Product selection page,
            // instead return him to Save and Publish page, because Sku selection page
            // is the last page in navigation steps, where user can go in edit mode...
            onBackButtonClick={() =>
              Config.get('editMode') ? this.props.cancelSkuSelection() : this.onClickBackButton()
            }
            backButtonLabel={Config.get('editMode') ? 'Cancel' : 'Previous step'}
            onNextButtonClick={() => this.onClickNextButton()}
            nextButtonLabel={nextBtnText}
            ignoreBackConfirmation
            noStatusBar={this.props.config.hideStatusBar}
          >
            <ProductPanel key="content" />
            <OptionsPanel
              resetErrors={() => this.resetErrors()}
              showErrors={this.state.showErrors}
              key="panel"
            />
          </PanelLayout>
          <Dialogue
            isOpen={this.state.isSkusOverflowModalOpen}
            className="sku-overflow"
            cancelText="Update SKU Selection"
            actionText="Continue Anyway"
            cancelClick={() => {
              this.hideSkusOverflowWarning();
            }}
            primaryClick={() => {
              this.ignoreSkusOverflowWarning();
            }}
            title="Did you know..."
          >
            {this.props.modalValidationMessage}
          </Dialogue>
          <SideBar isOpen={this.props.sidebar.isOpen} onClose={() => this.props.closeSideBar()}>
            <ProductDetails hidePick onGetStarted={() => this.props.closeSideBar()} />
          </SideBar>
        </div>
      );
    }

    return (
      <PageBody size="full" className="sku-selection-container position-relative">
        {this.props.isApparel && this.props.currentGroupId < this.props.maxGroupId ? (
          <ApparelPanel
            onBackButtonClick={() =>
              Config.get('editMode') ? this.props.cancelSkuSelection() : this.onClickBackButton()
            }
          />
        ) : (
          content
        )}
        <style jsx>
          {`
            :global(.sku-overflow button) {
              width: 100% !important;
            }
            :global(.sku-selection-container) {
              min-height: calc(100vh - 55px) !important;
            }
          `}
        </style>
      </PageBody>
    );
  }
}

export default SKUSelection;
