import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Spinner from './Spinner/index.js';
import Icon from './Icon.js';

const Thumbnail = ({ className = 'medium', image, alt }) => {
  const [isLoading, setIsLoading] = React.useState(image);
  const [showPlaceholderImage, setShowPlaceholderImage] = React.useState(!image);

  React.useEffect(() => {
    if (!image) {
      setIsLoading(false);
      setShowPlaceholderImage(true);
    } else {
      setShowPlaceholderImage(false);
    }
  }, [image]);

  const onImageError = () => {
    setIsLoading(false);
    setShowPlaceholderImage(true);
  };

  return (
    <div className={`${className} cerulean-thumbnail d-flex align-items-center`}>
      {isLoading && <Spinner />}
      {!showPlaceholderImage && (
        <img
          onLoad={() => setIsLoading(false)}
          onError={() => onImageError()}
          alt={alt}
          src={image}
        />
      )}
      {showPlaceholderImage && <Icon className="m-auto" icon="image" />}
      <style jsx>
        {`
          img {
            width: 100%;
            opacity: ${isLoading ? '0' : '1'};
          }
          .cerulean-thumbnail {
            border: 1px solid ${COLORS.neutralLight300};
            margin: auto;
            box-sizing: border-box;
            border-radius: 2px;
            position: relative;
            overflow: hidden;
          }
          .extra-large {
            width: 10rem;
            height: 10rem;
            min-width: 10rem;
            min-height: 10rem;
          }
          .large {
            width: 6rem;
            height: 6rem;
            min-width: 6rem;
            min-height: 6rem;
          }
          .medium {
            width: 4rem;
            height: 4rem;
            min-width: 4rem;
            min-height: 4rem;
          }
          .small {
            width: 2.5rem;
            height: 2.5rem;
            min-width: 2.5rem;
            min-height: 2.5rem;
          }
        `}
      </style>
    </div>
  );
};

Thumbnail.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string
};

export default Thumbnail;
