import { customActionFor } from '../BaseCard/BaseCardActions';

const EMAIL_PREFERENCES = 'emailPreferences';

// ------------------------------------
// Action Types
// ------------------------------------
export const UPDATE_OCCASIONAL_EMAILS = customActionFor(EMAIL_PREFERENCES).async(
  'UPDATE_OCCASIONAL_EMAILS'
);

export const ADD_FULFILLMENT_CONTACT_EMAIL = customActionFor(EMAIL_PREFERENCES).async(
  'ADD_FULFILLMENT_CONTACT_EMAIL'
);

export const DELETE_FULFILLMENT_CONTACT_EMAIL = customActionFor(EMAIL_PREFERENCES).async(
  'DELETE_FULFILLMENT_CONTACT_EMAIL'
);

export const ENABLE_FULFILLMENT_CONTACT_EMAIL = customActionFor(EMAIL_PREFERENCES).async(
  'ENABLE_FULFILLMENT_CONTACT_EMAIL'
);

export const DISABLE_FULFILLMENT_CONTACT_EMAIL = customActionFor(EMAIL_PREFERENCES).async(
  'DISABLE_FULFILLMENT_CONTACT_EMAIL'
);

// ------------------------------------
// Actions
// ------------------------------------

export function updateOccasionalEmails(enabled) {
  return {
    type: UPDATE_OCCASIONAL_EMAILS.ASYNC,
    payload: { enabled }
  };
}

export function addFulfillmentContactEmail(email) {
  return {
    type: ADD_FULFILLMENT_CONTACT_EMAIL.ASYNC,
    payload: { email }
  };
}

export function deleteFulfillmentContactEmail(email) {
  return {
    type: DELETE_FULFILLMENT_CONTACT_EMAIL.ASYNC,
    payload: { email }
  };
}

export function disableFulfillmentContactEmail(email) {
  return {
    type: DISABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC,
    payload: { email }
  };
}

export function enableFulfillmentContactEmail(email) {
  return {
    type: ENABLE_FULFILLMENT_CONTACT_EMAIL.ASYNC,
    payload: { email }
  };
}
