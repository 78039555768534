import { List } from 'immutable';
import { Map } from 'immutable';
import {
  LOAD_INITIAL_DATA,
  SET_SELECTION,
  UPDATE_SELECTION,
  GET_COUNTRIES,
  UPDATE_COUNTRIES,
  UPDATE_PARTNER_DETAILS,
  GET_ADDRESS_TYPES,
  UPDATE_ADDRESS_TYPES,
  SELECTION_UPDATED,
  SAVE_RETURN_ADDRESS,
  UPDATE_RETURN_ADDRESS,
  USE_EXISTING_RETURN_ADDRESS,
  CANCEL_SAVING
} from './ReturnAddressActions';

const defaultState = new Map({
  loading: false,
  saving: false,
  data: new Map({
    countries: new List(),
    addressTypes: new List(),
    partnerDetails: new Map()
  })
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_INITIAL_DATA:
      return state.setIn(['loading'], true);
    case SET_SELECTION:
      return state.setIn(['saving'], true);
    case UPDATE_SELECTION:
      return state
        .setIn(['saving'], false)
        .setIn(['data', 'partnerDetails', 'ReturnAddressType'], action.payload.selection);
    case UPDATE_COUNTRIES:
      return state.setIn(['data', 'countries'], action.payload.data);
    case UPDATE_ADDRESS_TYPES:
      return state.setIn(['data', 'addressTypes'], action.payload.data);
    case UPDATE_PARTNER_DETAILS:
      return state.setIn(['data', 'partnerDetails'], action.payload.data).setIn(['loading'], false);
    case SAVE_RETURN_ADDRESS:
      return state.setIn(['saving'], true);
    case UPDATE_RETURN_ADDRESS:
      const returnData = action.payload.data.toJS();
      const address = returnData.Address;
      const suggestedAddress = returnData.Validation.SuggestedAddress;

      const newState = state
        .setIn(['saving'], false)
        .setIn(['data', 'partnerDetails', 'ReturnAddress'], address);

      if (suggestedAddress) {
        return newState.setIn(['data', 'partnerDetails', 'SuggestedAddress'], suggestedAddress);
      }
      return newState;
    case USE_EXISTING_RETURN_ADDRESS:
      return state.setIn(['data', 'partnerDetails', 'SuggestedAddress'], new Map());
    case CANCEL_SAVING:
      return state.setIn(['data', 'saving'], false);
    default:
      return state;
  }
};
