import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../../utils/price';
import './ProductSection.scss';

const priceDelta = 0.001;

export const ProductSection = ({
  title,
  minPrice,
  maxPrice,
  regions,
  productType,
  isMultiproduct,
  openProductInfo,
  skuOverride
}) => {
  const onClickOpenProductInfo = () => openProductInfo(productType);

  const price = useMemo(
    () =>
      !maxPrice || Math.abs(maxPrice - minPrice) < priceDelta
        ? formatPrice(minPrice)
        : `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`,
    [minPrice, maxPrice]
  );

  return (
    <div className="product-section-container">
      <style jsx>
        {`
          div.production-time {
            color: #646b72;
            font-size: 15px;
            margin-top: 8px;
          }

          a.product-info {
            cursor: pointer;
          }
        `}
      </style>

      <h2>
        <span>{title}</span>
        <small>{price}</small>
      </h2>
      <div className="production-time">
        <a className="product-info" onClick={onClickOpenProductInfo}>
          see product info
        </a>
      </div>
    </div>
  );
};

ProductSection.propTypes = {
  title: PropTypes.string.isRequired,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  regions: PropTypes.array,
  productType: PropTypes.string.isRequired,
  isMultiproduct: PropTypes.bool.isRequired,
  openProductInfo: PropTypes.func.isRequired,
  skuOverride: PropTypes.array
};

export default ProductSection;
