import { connect } from 'react-redux';
import CategoriesNav from './CategoriesNav';
import * as Actions from './CategoriesNavActions';
import * as Selectors from './CategoriesNavSelectors';

const mapDispatchToProps = {
  onSelectCategory: cat => {
    return Actions.selectCategory(Actions.CATEGORY_SELECT, cat);
  },
  onSelectSubCategory: cat => {
    return Actions.selectCategory(Actions.SUBCATEGORY_SELECT, cat);
  },
  onHoverCategory: cat => {
    return Actions.selectCategory(Actions.CATEGORY_HOVER, cat);
  }
};

const mapStateToProps = state => {
  // Do all state data mapping only inside selectors
  const categories = Selectors.allCategoriesSelector(state);
  const selectedCategory = Selectors.selectedCategorySelector(state);
  const hoveredCategory = Selectors.hoveredCategorySelector(state);
  const subcategories = Selectors.subCategoriesSelector(state);
  const selectedSubCategory = Selectors.selectedSubCategorySelector(state);

  return {
    categories,
    selectedCategory,
    hoveredCategory,
    subcategories,
    selectedSubCategory
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesNav);
