import { Record } from 'immutable';

export const BillingAddressModel = new Record({
  FirstName: '',
  LastName: '',
  CompanyName: '',
  Website: '',
  Line1: '',
  Line2: '',
  CountryCode: '',
  City: '',
  State: '',
  PostalCode: '',
  Phone: '',
  Email: ''
});
