import { createSelector } from 'reselect';
import { fromJS, List, Map } from 'immutable';
import { removeDuplicatesBy } from '../../utils/array';
import Config from '../../config';
import { createImmutableSelector } from '../../utils/reselect';
import {
  dataSelector,
  selectedSKUsSelector,
  selectedProductIdSelector,
  allProductsSelector,
  selectedProductIdsSelector,
  selectedProductsSelector
} from '../../store/selectors/productDataSelectors';
import printAreaService from './services/printAreaService';
import { NECK_LABEL_PRICE } from '../../const/NeckLabel';

export const imageUploadStateSelector = state => state.get('imageUpload');
export const selectedVariantIndexSelector = state =>
  imageUploadStateSelector(state).get('selectedVariant');
export const selectedSpaceIndexSelector = state =>
  imageUploadStateSelector(state).get('selectedSpace');
export const totalItemsToProcessSelector = state =>
  imageUploadStateSelector(state).get('totalItemsToProcess');
export const remainingItemsToProcessSelector = state =>
  imageUploadStateSelector(state).get('remainingItemsToProcess');
export const noPreviewsThresholdNumber = 35;

export const neckLabelsPreviewsLoadingSelector = state =>
  imageUploadStateSelector(state).get('loadingNeckTagPreviews');

const immutableVariantsSelector = state => state.get('data').get('selectedSkus') || new List();

const selectedProduct = createSelector(
  [selectedProductIdSelector, allProductsSelector],
  (selectedProductId, allProducts) => allProducts.find(p => p.id === selectedProductId)
);

export const selectedProductName = createSelector(
  [selectedProduct],
  selectedProduct => selectedProduct.name
);

export const hasProductDetails = createSelector(
  [selectedProduct],
  selectedProduct => !!selectedProduct.get('details')
);

export const hasProductVariants = createSelector(
  [selectedProduct],
  selectedProduct => !!selectedProduct.get('variants')
);

const allProductSkusSelector = createSelector(
  [allProductsSelector, selectedProductIdsSelector],
  (products, selectedProductIds) =>
    selectedProductIds.reduce((a, c) => {
      const product = products.find(p => p.get('id') === c);
      return a.concat(product ? product.get('variants') : []);
    }, [])
);

const productVariantsBySkuSelector = createImmutableSelector(
  [allProductSkusSelector],
  allVariants =>
    allVariants.reduce((acc, variant) => {
      acc[variant.sku.toLowerCase()] = variant;

      return acc;
    }, {})
);

const immutableSelectedVariantSelector = createSelector(
  [immutableVariantsSelector, selectedVariantIndexSelector],
  (variants, selectedSkuIndex) => variants.get(selectedSkuIndex)
);

const immutableSelectedSpaceSelector = createSelector(
  [immutableSelectedVariantSelector, selectedSpaceIndexSelector],
  (variant, selectedSpaceIndex) => variant.get('spaces').get(selectedSpaceIndex)
);

export const isWorkingSelector = createSelector(
  [imageUploadStateSelector],
  imageUploadState => !imageUploadState.get('isReady') || imageUploadState.get('workingJobs') > 0
);

export const bulkLoaderProgressSelector = createSelector(
  [imageUploadStateSelector],
  imageUploadState => imageUploadState.get('bulkLoaderProgress')
);

export const selectedVariantSelector = createSelector(immutableSelectedVariantSelector, variant =>
  variant ? variant.toJS() : null
);

export const selectedSpaceSelector = createSelector(immutableSelectedSpaceSelector, space =>
  space ? space.toJS() : null
);

// This is a derivative selector that maps over data.selectedSkus to create a list of the
// sku strings currently selected.  It's designed to prevent recalculation of child selectors
// dependent only on the currently selected sku list when other data changes within data.selectedSkus
const variantSkusSelector = createSelector(
  immutableVariantsSelector,
  // TODO: Fix selectedSKUs should always use immutable structures inside
  // It became fully immutable after restore cart and edit cart item
  // which copy to selectedSkus from cartItems state
  variants => variants.map(v => (v.sku || v.get('sku')).toLowerCase()).sort()
);

// This is an optimization to cache the mapping between the products and
// the currently selected SKUs.  variantSkusSelector pulls out the current
// sku list and createImmutableSelector ensures that the operation will
// only rerun when that changes based on `Immutable.is`.
const skuLookupSelector = createImmutableSelector(
  [variantSkusSelector, productVariantsBySkuSelector],
  (variantSkus, productSkus) =>
    variantSkus.toSet().reduce((acc, sku) => {
      const productSku = productSkus[sku.toLowerCase()];

      if (productSku) {
        return acc.set(sku, new Map(productSku));
      }

      return acc;
    }, new Map())
);

export const productVariantsMinPriceSelector = createSelector(
  [immutableVariantsSelector, skuLookupSelector],
  (variants, skus) =>
    variants
      .map(v => {
        const sku = skus.get((v.sku || v.get('sku')).toLowerCase(), new Map());
        const cost =
          sku.get('minCost', 0) +
          (v.get('neck_tag_id') || v.get('neck_tag_image_url') ? NECK_LABEL_PRICE : 0);

        return cost;
      })
      .min()
);

export const productVariantsMaxPriceSelector = createSelector(
  [immutableVariantsSelector, skuLookupSelector],
  (variants, skus) =>
    variants
      .map(v => {
        const sku = skus.get((v.sku || v.get('sku')).toLowerCase(), new Map());
        const cost =
          sku.get('maxCost', 0) +
          (v.get('neck_tag_id') || v.get('neck_tag_image_url') ? NECK_LABEL_PRICE : 0);

        return cost;
      })
      .max()
);

export const isAllImagesFilledSelector = createSelector(
  immutableVariantsSelector,
  variants =>
    !variants.find(v => v.get('spaces').find(s => !s.get('images') || !s.get('images').size))
);

export const isAnyImageFilledSelector = createSelector(
  immutableVariantsSelector,
  variants =>
    !!variants.find(v => v.get('spaces').find(s => s.get('images') && s.get('images').size))
);

export const variantsSelector = createSelector(immutableVariantsSelector, variants =>
  variants.toJS().map((v, index) => Object.assign(v, { index: index }))
);

export const detailedVariantsSelector = createSelector(
  [variantsSelector, skuLookupSelector],
  (variants, skus) => {
    if (!skus) return undefined;

    return variants.map(v => ({
      ...v,
      ...skus.get(v.sku.toLowerCase()).toJS()
    }));
  }
);

export const skuOverflow = createSelector(variantsSelector, variants => {
  const isCof = Config.get('cof');
  if (isCof) {
    return 0;
  }
  return variants.length - 100;
});

export const spacesLoadedSelector = createSelector(
  [variantsSelector],
  variants => !variants.some(v => v.spaces.filter(s => !!s.template).length === 0)
);

const cartSelector = state => dataSelector(state).get('cart');
const cartItemsSelector = state => cartSelector(state).get('items');

export const editItemIndexSelector = state => dataSelector(state).get('editItemIndex');

export const selectedProductSelector = createSelector(
  [allProductsSelector, selectedProductIdSelector],
  (products, productId) => products.find(p => p.get('id') === productId)
);

const selectedVariantsSelector = createImmutableSelector(
  [productVariantsBySkuSelector, variantSkusSelector],
  (variantsBySku, skus) => {
    // NOTE: product variants may be empty b/c of data inconsistency
    // filter out undefined items from result array
    return skus.map(sku => variantsBySku[sku]).filter(s => !!s);
  }
);

const getCombinedOptions = (product, variants) => {
  let productDetails = product.details.product;
  // get options for product except 'REGIONS' option
  let options = productDetails.options.filter(option => option.id !== 'REGIONS');

  const productOptionValues = productDetails.regions
    .map(region => region['sub-categories'])
    .reduce((a, b) => a.concat(b), [])
    .map(sub => sub.attributes)
    .reduce((a, b) => a.concat(b), [])
    .filter(attr => options.some(opt => opt.id === attr.id))
    .map(o => o.values)
    .reduce((a, b) => a.concat(b), []);

  let combined = variants.map(variant => {
    let variantValues = options.map(opt => variant[opt.id]);

    // find option values for selected sku
    let array = productOptionValues.filter(val => variantValues.some(opt => opt === val.id));

    let result = options
      .map(option => {
        const optVal = array.find(item => item.id === variant[option.id]);
        if (!optVal) {
          return;
        }
        return {
          name: option.title,
          value: optVal.name,
          optionId: option.id,
          valueId: variant[option.id],
          colorHex: optVal.hex
        };
      })
      .filter(opt => !!opt);

    return {
      sku: variant.sku,
      options: result
    };
  });

  return combined;
};

export const selectedOptionsSelector = createSelector(
  [selectedProductSelector, selectedVariantsSelector],
  (product, variants) => getCombinedOptions(product, variants)
);

/**
 * Returns options from all selected products ids (used in multiproduct) and variants
 */
export const selectedAllOptionsSelector = createSelector(
  [selectedProductsSelector, selectedVariantsSelector],
  (products, variants) => {
    return products
      .reduce((a, c) => a.concat(getCombinedOptions(c, variants)), new List())
      .filter(o => o && o.options && o.options.length);
  }
);

const optionsBySkuSelector = createSelector([selectedOptionsSelector], options =>
  options.reduce((acc, opt) => {
    acc[opt.sku.toLowerCase()] = opt;

    return acc;
  }, {})
);

export const addToCartSelector = createSelector(
  [
    selectedProductSelector,
    productVariantsBySkuSelector,
    selectedSKUsSelector,
    optionsBySkuSelector
  ],
  (product, variants, skus, options) =>
    skus.map(sku => {
      const variant = variants[sku.sku.toLowerCase()];
      const skuOptions = options[sku.sku.toLowerCase()];

      if (!skuOptions) {
        console.warn(`SKU: ${sku.sku} options not found`);
      }

      return fromJS({
        _random_id: Math.random(),
        // partnerId of the current partner, to whom product belongs to.
        // used to support CS ordering partner products
        partnerId: Config.get('partnerId'),
        name: product.name,
        productId: product.id,
        quantity: 1,
        variant: variant,
        sku: sku,
        options: removeDuplicatesBy(key => key.optionId, skuOptions ? skuOptions.options : []),
        isSample: sku.isSample
      });
    })
);

export const isCartItemEditMode = state => {
  let config = state.get('data').get('imageUploadConfig');
  return config ? config.get('isCartItemEditMode') : false;
};

export const editItemSelector = createSelector(
  [cartItemsSelector, editItemIndexSelector],
  (cartItems, editItemIndex) => {
    return cartItems.get(editItemIndex);
  }
);

export const sideBarOpenedSelector = createSelector([imageUploadStateSelector], imageUploadState =>
  imageUploadState.get('sideBarOpened')
);

export const showPrintGuidelinesSelector = createSelector(
  [selectedSKUsSelector, editItemSelector, isCartItemEditMode],
  (selectedSkus, editItem, isCartEditMode) => {
    const hasBleedLayer = spaces =>
      spaces.some(space =>
        space
          .getIn(['template', 'layers'])
          .some(layer => layer.get('type').toUpperCase() === 'BLEED')
      );

    return isCartEditMode
      ? hasBleedLayer(editItem.getIn(['sku', 'spaces']))
      : selectedSkus.some(sku => hasBleedLayer(sku.get('spaces')));
  }
);

export const bulkUpdatesSelector = createSelector([dataSelector], data => data.get('bulkUpdates'));

export const printAreaColorSelector = createSelector(
  [selectedSKUsSelector, selectedVariantIndexSelector, selectedAllOptionsSelector],
  (skus, index, options) => {
    return printAreaService.getPrintAreaColor(skus.get(index), options);
  }
);

export const customOrderFormSelector = state => state.get('customOrderForm');
export const originalDesignsSelector = createSelector(customOrderFormSelector, cof => {
  return cof.toJS().editDesignView.item.images;
});

export const ordersSelector = state => state.get('orders');
export const latestDesignsSelector = createSelector(ordersSelector, order => {
  return order.toJS().orderDetails.itemsData[0].Images;
});
