import * as Actions from '../../atoms/CategoriesNav/CategoriesNavActions';

export const selectedCategoryReducer = (state = null, action) => {
  switch (action.type) {
    case Actions.CATEGORY_SELECT: {
      return action.payload;
    }

    default:
      return state;
  }
};

export const hoveredCategoryReducer = (state = null, action) => {
  switch (action.type) {
    case Actions.CATEGORY_HOVER: {
      return action.payload;
    }

    default:
      return state;
  }
};

export const selectedSubCategoryReducer = (state = null, action) => {
  switch (action.type) {
    case Actions.SUBCATEGORY_SELECT: {
      return action.payload;
    }

    case Actions.CATEGORY_SELECT: {
      return null;
    }

    default:
      return state;
  }
};

export const forceScrollReducer = (state = false, action) => {
  switch (action.type) {
    case Actions.SUBCATEGORY_SELECT: {
      return true;
    }

    case Actions.CATEGORY_SELECT: {
      return true;
    }

    case Actions.SUBCATEGORY_FORCE_SCROLL_DONE: {
      return false;
    }

    default:
      return state;
  }
};
