import createSagaMiddleware from 'redux-saga';
import registerHubSagas from '../../Hub/HubSaga';
import registerOrdersSagas from '../../Orders/OrdersSaga';
import registerCustomOrderFormSagas from '../../CustomOrderForm/CustomOrderFormSaga';
import registerDashboardSagas from '../../OnboardingDashboard/DashboardSaga';
import registerSettingsSagas from '../../Settings/SettingsSaga';
import registerStoresSagas from '../../Stores/StoresSaga';
import registerReprintSelfServiceSagas from '../../ReprintSelfService/ReprintSelfServiceSaga';
import registerCustomerRefundSagas from '../../CustomerRefund/CustomerRefundSaga';
import registerProductionReportSagas from '../../ProductionReport/ProductionReportSaga';
import registerAdminSagas from '../../Tools/AdminSaga';
import Log from 'gooten-components/src/services/logService';

const sagaMiddleware = createSagaMiddleware();

export const injectSaga = saga => {
  sagaMiddleware.run(saga).done.catch(err => {
    // Log unhandled saga errors
    const errorMsg = `Saga ${err.friendlyMsg || err}`;
    Log.error(err, errorMsg, { ...err.details });

    throw err;
  });
};

export const registerLocalSagas = () => {
  registerHubSagas();
  registerOrdersSagas();
  registerCustomOrderFormSagas();
  registerDashboardSagas();
  registerSettingsSagas();
  registerStoresSagas();
  registerReprintSelfServiceSagas();
  registerCustomerRefundSagas();
  registerProductionReportSagas();
  registerAdminSagas();
};

export default sagaMiddleware;
