import { postJson } from '../../../utils/http';
import Config from '../../../config';

const getAuthQueryParams = () => {
  return {
    queryParams: {
      recipeId: Config.get('recipeId'),
      partnerBillingKey: Config.get('storeApiKey')
    }
  };
};

const getItemsPriceInfoUrl = () => `${Config.get('api')}UniqueProductNew/GetItemsPriceInfo`;

export const getSkuItemPriceKey = cartItem =>
  cartItem.getIn(['sku', 'neck_tag_image_url'])
    ? `${cartItem.getIn(['sku', 'sku'])}+necktag_image_url`
    : cartItem.getIn(['sku', 'sku']);

export const getItemsPriceInfo = (shippingCountry, cartItems) => {
  let params = getAuthQueryParams();
  params.queryParams.countryCode = shippingCountry;

  const skus = cartItems.map(getSkuItemPriceKey);

  return new Promise((resolve, reject) => {
    postJson(getItemsPriceInfoUrl(), { skus }, params)
      .then(res => {
        if (!res.error) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => reject(err));
  });
};
