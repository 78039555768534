import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  FETCH_HISTORY_DATA,
  UPDATE_PAGE_NUMBER,
  fetchHistoryDataSuccess,
  fetchHistoryDataFail,
  UPDATE_ORDER_ID
} from './HistoryViewActions';
import HistoryViewService from './HistoryViewService';
import { filterData } from './HistoryViewSelectors';

export function* fetchDataRequest() {
  try {
    let filterDataObject = yield select(filterData);
    filterDataObject = filterDataObject.toJS();
    const fetchHistoryData = yield call(
      [HistoryViewService, HistoryViewService.fetchHistory],
      filterDataObject
    );
    yield put(fetchHistoryDataSuccess(fetchHistoryData));
  } catch (error) {
    yield put(fetchHistoryDataFail());
  }
}

export function* watchSearch() {
  yield takeLatest(FETCH_HISTORY_DATA.ASYNC, fetchDataRequest);
  yield takeLatest(UPDATE_PAGE_NUMBER, fetchDataRequest);
  yield takeLatest(UPDATE_ORDER_ID, fetchDataRequest);
}

export default function* rootSaga() {
  yield all([watchSearch()]);
}
