import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { TEST_CONNECTION } from './WebhooksActions';
import { cardsSelector } from '../BaseCard/BaseCardSelectors';
import { watchInitAsync, watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import Webhooks from './Webhooks';

export function* testConnectionAsyncHandler(action) {
  let result = null;
  try {
    const cards = yield select(cardsSelector);
    const request = { ...cards.getIn(['webhooks', 'data']).toJS() };
    result = yield call(settingsApiService.testWebhooks, request);
    if (result.error) {
      yield put(fetchAsyncFail(TEST_CONNECTION, { error: result.error.message }));
    } else {
      yield put(fetchAsyncSuccess(TEST_CONNECTION, action.payload));
    }
  } catch (err) {
    yield put(fetchAsyncFail(TEST_CONNECTION, err));
    yield put(push('Failed to test webhooks endpoint', 'failure'));
  }
}

export function* watchTestConnection() {
  yield takeLatest(TEST_CONNECTION.ASYNC, testConnectionAsyncHandler);
}

export default function* rootSaga() {
  yield all([
    watchTestConnection(),
    watchInitAsync(Webhooks.name, Webhooks.messages),
    watchUpdateAsync(Webhooks.name, Webhooks.messages)
  ]);
}
