import { createSelector } from 'reselect';
import { selectedProductsSelector } from '../../../../store/selectors/productDataSelectors';

/*
  Checks if product is apparel (in category 'Clothing').
*/
export const isApparelProduct = product =>
  product && product.get('categories').includes('Clothing');

/*
  Checks the same as above, except here we're using selected product
*/
export const isApparelProductSelected = createSelector(
  [selectedProductsSelector],
  selectedProducts =>
    !!(
      selectedProducts &&
      selectedProducts.size &&
      selectedProducts.first() &&
      isApparelProduct(selectedProducts.first())
    )
);
