import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { fromJS } from 'immutable';
import { hashHistory } from 'react-router';
import productSelectionService from './../../services/productSelectionService';
import {
  ALL_PRODUCTS_FETCH,
  setQuery,
  QUERY_NEXT_PAGE,
  QUERY_SEARCH,
  SELECT_PRODUCT,
  queryInProgress,
  updateQuery,
  VARIANTS_FETCH,
  PUBLISH_PRODUCT,
  publishInProgress,
  showTopNotification
} from './HubProductSelectionActions';
import {
  cachedProductsSelector,
  querySelector,
  selectedProductSelector,
  publishRequestSelector,
  publishConfigSelector
} from './HubProductSelectionSelectors';
import { providerPathSelector } from './../../../../../ProductSelectionView/ProductSelectionViewSelectors';
import { setReady } from './../../../../SKUsSelectionViewActions';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import Log from 'gooten-components/src/services/logService';
import publishService from 'gooten-components/src/components/ProductPublish/services/publishService';
import {
  showNotification,
  queryLoad
} from './../../../../../../HubView/components/Products/ProductsActions';
import { unselectAll } from './../../../../../../HubView/components/Actions/ActionsActions';
import { searchChange } from './../../../../../../HubView/components/Search/SearchActions';
import { productsQuerySelector } from '../../../../../../HubView/components/Products/ProductsSelectors';

export function* fetchAllProducts() {
  try {
    const query = yield select(querySelector);
    yield put(queryInProgress(true));
    const data = yield call(
      [productSelectionService, productSelectionService.fetchAllProducts],
      query.toJS()
    );
    yield put(fetchAsyncSuccess(ALL_PRODUCTS_FETCH, data));

    // after data is fetced, update total number of products for current query
    yield put(updateQuery('total', data.res.total));
  } catch (err) {
    yield put(fetchAsyncFail(ALL_PRODUCTS_FETCH, err));
    throw Log.withFriendlyMsg('Failed to fetch Hub products', err);
  } finally {
    yield put(queryInProgress(false));
  }
}

export function* queryNextPageHandler() {
  const query = yield select(querySelector);
  yield put(updateQuery('page', query.get('page') + 1));
  yield fetchAllProducts();
}

export function* querySearchHandler(action) {
  // action.payload holds filter(search) string
  // first check do we have query products for this filter cached
  const cachedProducts = yield select(cachedProductsSelector);
  const cachedProductsForFilterQuery = cachedProducts
    .toJS()
    .filter(
      item =>
        Object.keys(item)[0].search(productSelectionService.getRegexForFilter(action.payload)) > -1
    );

  // if there's no cached products, fetch it with initial query
  if (!cachedProductsForFilterQuery.length) {
    yield call(delay, 500);
    const query = fromJS(productSelectionService.getInitialQuery(action.payload));
    yield put(setQuery(query));
    yield fetchAllProducts();
  } else {
    // products for filter are cached, just update current query in state
    const query = productSelectionService.getInitialQuery(action.payload);

    // update total
    query.total = Object.values(cachedProductsForFilterQuery[0])[0].total;

    // update current page to latest
    query.page = cachedProductsForFilterQuery.length;

    yield put(setQuery(fromJS(query)));
  }
}

export function* productSelectAsyncHandler(action) {
  if (action.payload) {
    yield fetchVariants();
  }
}

export function* fetchVariants() {
  try {
    const selectedProduct = yield select(selectedProductSelector);

    if (!selectedProduct.get('variants').size) {
      yield put(setReady(false));
      const data = yield call(
        [productSelectionService, productSelectionService.fetchVariants],
        selectedProduct.toJS()
      );
      yield put(
        fetchAsyncSuccess(VARIANTS_FETCH, {
          productId: selectedProduct.get('id'),
          data
        })
      );
      yield put(setReady(true));
    }
  } catch (err) {
    yield put(setReady(true));
    yield put(fetchAsyncFail(VARIANTS_FETCH, err));
    throw err;
  }
}

export function* publishProduct() {
  const request = yield select(publishRequestSelector);
  const config = yield select(publishConfigSelector);
  try {
    yield put(publishInProgress(true));
    const results = yield call(publishService.publish, request.toJS());
    const [error, _] = yield call(publishService.validateResponse, results, config);
    if (!error) {
      yield put(unselectAll());
      yield put(searchChange());

      const providerPath = yield select(providerPathSelector);
      hashHistory.push(providerPath);

      // after switch to products page, reload query in order to show newsly linked product
      const hubProductsQuery = yield select(productsQuerySelector);
      yield put(queryLoad(hubProductsQuery));

      yield put(
        showNotification({
          show: true,
          message: request.getIn(['product', 'name'])
        })
      );
    } else {
      // show error message
      yield put(showTopNotification(true, error));
    }

    yield put(publishInProgress(false));
  } catch (error) {
    yield put(publishInProgress(false));
    // show error
    yield put(showTopNotification(true, error));
  }
}

/*
 * WATCH
 */
export function* watchQueryNextPage() {
  yield takeLatest(QUERY_NEXT_PAGE, queryNextPageHandler);
}

export function* watchQuerySearch() {
  yield takeLatest(QUERY_SEARCH, querySearchHandler);
}

export function* watchProductSelectAsync() {
  yield takeLatest(SELECT_PRODUCT, productSelectAsyncHandler);
}

export function* watchPublishProduct() {
  yield takeLatest(PUBLISH_PRODUCT, publishProduct);
}

export default function* rootSaga() {
  yield all([
    watchQueryNextPage(),
    watchQuerySearch(),
    watchProductSelectAsync(),
    watchPublishProduct()
  ]);
}
