import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Icon from './Icon.js';

const Card = ({
  title,
  type = 'standard', // compact, fullwidth
  blank = false,
  description,
  children,
  collapsible = false,
  margin,
  startsOpen,
  className,
  id
}) => {
  const [collapsed, setCollapsed] = useState(!collapsible ? false : !startsOpen);
  return (
    <div id={id} className={`card ${className || ''}`}>
      {!blank && (
        <div className="text-container">
          <div
            className="card-header"
            onClick={collapsible && type !== 'compact' ? () => setCollapsed(!collapsed) : null}
          >
            {title ? (
              <div className="d-flex title-and-button">
                <div
                  title={typeof title === 'string' ? title : undefined}
                  className={`product-title ${type === 'compact' ? 'overline' : 'title-2'}`}
                >
                  {title}
                </div>
                <div className="icon">
                  {collapsible && type !== 'compact' && <Icon icon="chevronDown"> </Icon>}
                </div>
              </div>
            ) : (
              <div className="mt-n3" />
            )}
            {description && <div className={`caption-text mt-1`}>{description}</div>}
          </div>
          <div className="mt-3">{!collapsed && children}</div>
        </div>
      )}

      <style jsx>
        {`
          .card {
            border-radius: 4px;
            width: ${type === 'compact' ? '18.5rem' : type === 'standard' ? '25rem' : '100%'};
            min-height: ${collapsed ? '4rem' : '12rem'};
            border: 1px solid ${COLORS.neutralLight300};
            display: flex;
            flex-direction: column;
            margin: ${margin || 'auto'};
            transition-duration: 0.3s;
            height: ${collapsed ? (description ? '5.5rem' : '4rem') : 'fit-content'};
            flex-grow: 1;
          }
          .card-header {
            min-height: ${description ? '5.5rem' : '0'};
            white-space: ${collapsed ? 'nowrap' : 'normal'};
            cursor: ${collapsed ? 'pointer' : 'auto'};
          }
          .title-and-button {
            align-items: ${collapsed ? 'center' : 'flex-start'};
            justify-content: space-between;
            height: ${type === 'compact' ? '1rem' : 'auto'};
          }
          .title-2 {
            max-width: calc(100% - 5rem);
            overflow: hidden;
            text-overflow: ellipsis;
            color: ${type === 'compact' ? COLORS.neutralDark200 : COLORS.neutralDark500};
          }
          .caption-text {
            overflow: hidden;
            text-overflow: ${collapsed ? 'ellipsis' : 'unset'};
          }
          .text-container {
            padding: 1rem 1.5rem;
            text-align: left;
            position: relative;
          }
          .icon {
            transform: ${collapsed ? 'rotate(0deg)' : 'rotate(180deg)'};
            transition-duration: 0.3s;
            transform-origin: center;
            height: 24px;
          }
        `}
      </style>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  blank: PropTypes.bool,
  description: PropTypes.string,
  children: PropTypes.any,
  collapsible: PropTypes.bool,
  margin: PropTypes.string,
  className: PropTypes.string,
  startsOpen: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Card;
