import {
  FETCH_HISTORY_DATA,
  SET_FILTERS,
  UPDATE_PAGE_NUMBER,
  UPDATE_ORDER_ID
} from './HistoryViewActions';
import initialState from '../../../store/initialState';
import { fromJS } from 'immutable';

const defaultState = initialState.getIn(['orders', 'historyView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_HISTORY_DATA.ASYNC:
      return state
        .set('errors', [])
        .setIn(['data', 'items'], [])
        .setIn(['data', 'total'], 0)
        .set('loading', true);
    case FETCH_HISTORY_DATA.SUCCESS:
      return state
        .setIn(['data', 'items'], action.payload.history)
        .setIn(['data', 'total'], action.payload.total)
        .set('loading', false);
    case FETCH_HISTORY_DATA.FAIL:
      return state
        .set('errors', ['There was a problem fetching the order history'])
        .set('loading', false);
    case SET_FILTERS:
      return state.set('filters', fromJS({ ...state.toJS().filters, ...action.payload }));
    case UPDATE_PAGE_NUMBER:
      return state
        .setIn(['data', 'items'], [])
        .setIn(['data', 'total'], 0)
        .set('errors', [])
        .set('filters', fromJS({ ...state.toJS().filters, Page: action.payload.pageNumber }))
        .set('loading', true);
    case UPDATE_ORDER_ID:
      return state
        .setIn(['data', 'items'], [])
        .setIn(['data', 'total'], 0)
        .set('errors', [])
        .set('loading', true)
        .set(
          'filters',
          fromJS({ ...defaultState.get('filters').toJS(), OrderId: action.payload.orderId })
        );
    default:
      return state;
  }
};
