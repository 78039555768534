import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/Modal';
import PropTypes from 'prop-types';
import { Tab, TabContainer } from '../../../shared/Tab';
import { Cell, TableRow } from '../../../shared/Table';
import DropDownContainer from '../../../shared/DropDown/DropDownContainer';
import Alert from '../../../shared/Alert';
import Loader from '../../../shared/Loader';
import ListItem from '../../../shared/ListItem';
import Tooltip from '../../../shared/Tooltip';
import Icon from '../../../shared/Icon';
import { pricingListUrl } from './PricingModalSelectors';

export const PricingModal = props => {
  const [tabView, setTabView] = useState('Product');
  const [shippingData, setShippingData] = useState(null);
  const [pricingData, setPricingData] = useState(null);
  const [shippingCountry, setShippingCountry] = useState(null);
  const [errors, setErrors] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    let fileName = props.productName
      .replace(/[^a-z]/gi, '_') // replace all non-alpha characters with an underscore
      .replace(/_+/g, '_') // combine multiple underscores next to each other into one
      .replace(/_$/, '') // remove trailing underscores
      .toLowerCase(); // make all characters lowercase

    fetch(`${pricingListUrl()}${fileName}_shipping.json`)
      .then(response => response.json())
      .then(data => {
        setShippingData(data);
        setShippingCountry(Object.keys(data)[0]);
      })
      .catch(error => {
        setErrors(error);
      });

    fetch(`${pricingListUrl()}${fileName}_pricing.json`)
      .then(response => response.json())
      .then(data => {
        setPricingData(data);
        setSelectedVariant(Object.values(data)[0]);
      })
      .catch(error => {
        setErrors(error);
      });
  }, [props.productName]);

  const tabItems = [{ name: 'Product' }, { name: 'Shipping' }];

  const shippingCountryOptions = () => {
    let shippingCountries = [];
    Object.entries(shippingData).forEach(([key, value]) => {
      shippingCountries.push(
        <ListItem key={key} text={`${key}`} onSelect={() => setShippingCountry(key)} />
      );
    });
    return shippingCountries;
  };

  const shippingPriceValues = () => {
    let shippingPrices = [];
    Object.entries(shippingData[shippingCountry]).forEach(([key, value]) => {
      // if there is a min price, then this shipping type is used and will have pricing in all values
      if (value.min) {
        let price;
        if (value.min === value.max) {
          price = `$${formatPrice(value.min)}`;
        } else {
          price = `$${formatPrice(value.min)} - $${formatPrice(value.max)}`;
        }

        let additional;
        if (value.additional.min) {
          if (value.additional.min === value.additional.max) {
            additional = `$${formatPrice(value.additional.min)}`;
          } else {
            additional = `$${formatPrice(value.additional.min)} - $${formatPrice(
              value.additional.max
            )}`;
          }
        } else {
          additional = '-';
        }
        shippingPrices.push({ type: key, price, additional });
      }
    });
    return shippingPrices;
  };

  const formatPrice = price => {
    return Number(price).toFixed(2);
  };

  const getShippingDrownDownName = country => {
    const countryImage = () => {
      switch (country) {
        case 'US':
        case 'US Territories':
          return <img src="https://appassets.azureedge.net/app-imgs/US.png" />;
        case 'Canada':
          return <img src="https://appassets.azureedge.net/app-imgs/CA.png" />;
        default:
          return <Icon icon="globe" />;
      }
    };

    return (
      <span className="ml-2 d-flex align-items-center dropdown-name">
        {countryImage()} {country} Shipping
      </span>
    );
  };

  const showProductPricing = selectedVariant => {
    const minPrice = selectedVariant.minCost;
    const maxPrice = selectedVariant.maxCost;

    if (minPrice === maxPrice) return `$${formatPrice(minPrice)}`;
    else return `$${formatPrice(minPrice)} - $${formatPrice(maxPrice)}`;
  };

  return (
    <div>
      <Modal
        isOpen={true}
        className="medium pricing-modal"
        title={`${props.productName} Pricing`}
        primaryClick={
          !shippingData
            ? null
            : () => {
                props.exportPricingList(props.productName);
              }
        }
        actionText="Download Full Price List"
        cancelClick={props.closePricingModal}
        cancelText="Cancel"
      >
        <>
          {errors ? (
            <div className="m-4">
              <Alert isOpen type="warning">
                Unable to load pricing for this product. Please try again later.
              </Alert>
            </div>
          ) : !shippingData ? (
            <Loader />
          ) : (
            <div className="m-4">
              <TabContainer className="ml-n2">
                {tabItems.map((tab, key) => (
                  <Tab onClick={() => setTabView(tab.name)} key={key} active={tab.name === tabView}>
                    {tab.name}
                  </Tab>
                ))}
              </TabContainer>
              {tabView === 'Product' ? (
                // PRODUCT PRICING VIEW
                <div className="product-pricing-container mt-4 mb-4">
                  {pricingData && !pricingData.Default && (
                    <DropDownContainer
                      className="mb-2 w-100"
                      title={
                        selectedVariant
                          ? Object.entries(pricingData).find(x => x[1] === selectedVariant)[0]
                          : 'Select variant'
                      }
                      fullWidth
                      scrollHeight={20}
                    >
                      {console.log(pricingData)}
                      {Object.keys(pricingData).map((subcategory, index) => (
                        <ListItem
                          key={index}
                          onSelect={() => setSelectedVariant(pricingData[subcategory])}
                          text={subcategory}
                          fullWidth
                        />
                      ))}
                    </DropDownContainer>
                  )}
                  <div className="pricing-table">
                    <TableRow
                      columns="3fr 2fr"
                      scrollableX
                      size="small"
                      className="price-modal-rows"
                    >
                      <Cell header>Variant</Cell>
                      <Cell header>Pricing</Cell>
                    </TableRow>
                    {selectedVariant &&
                      selectedVariant.map((selected, index) => (
                        <TableRow
                          key={index}
                          columns="3fr 2fr"
                          scrollableX
                          size="small"
                          className="price-modal-rows"
                        >
                          <Cell>{selected.variant}</Cell>
                          <Cell>{showProductPricing(selected)}</Cell>
                        </TableRow>
                      ))}
                  </div>
                </div>
              ) : (
                // SHIPPING PRICING VIEW
                <div className="shipping-pricing-container mt-4 mb-4">
                  <DropDownContainer
                    className="mb-2"
                    title={getShippingDrownDownName(shippingCountry)}
                    fullWidth
                  >
                    {shippingCountryOptions()}
                  </DropDownContainer>
                  <TableRow
                    columns="4fr 3fr 3fr"
                    scrollableX
                    size="small"
                    className="price-modal-rows w-100"
                  >
                    <Cell header>Shipping Options</Cell>
                    <Cell header>Price</Cell>
                    <Cell header>
                      Each Additional
                      <Tooltip
                        containerClassName="ml-1 mt-1"
                        content={
                          'This is the base price for each additional product; price may vary depending on the quantity'
                        }
                      >
                        <Icon className="info-icon" icon="info" height={16} width={16} />
                      </Tooltip>
                    </Cell>
                  </TableRow>
                  {shippingPriceValues().map((item, key) => (
                    <TableRow
                      columns="4fr 3fr 3fr"
                      scrollableX
                      size="small"
                      className="price-modal-rows w-100"
                      key={key}
                    >
                      <Cell>{item.type}</Cell>
                      <Cell>{item.price}</Cell>
                      <Cell>{item.additional}</Cell>
                    </TableRow>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      </Modal>
      <style jsx>
        {`
          :global(.price-modal-rows) {
            overflow: visible !important;
          }
          :global(.modal-bod) {
            min-height: ${shippingData && !errors ? '360px' : '0px'};
            overflow: visible !important;
          }
          :global(.loader-container) {
            margin: 50px;
          }
          :global(.price-modal-rows:nth-child(odd) .table-cell) {
            background: #f2f4f7;
          }
          :global(.dropdown-name img) {
            height: 1.25rem;
            width: 1.25rem;
            margin: 0 0.75rem 0 -0.5rem;
            border-radius: 0.25rem;
          }
          .pricing-table {
            max-height: calc(100vh - 18rem);
            overflow-y: auto;
          }
        `}
      </style>
    </div>
  );
};

PricingModal.propTypes = {
  productName: PropTypes.string,
  closePricingModal: PropTypes.func
};

export default PricingModal;
