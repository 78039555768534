import { createSelector } from 'reselect';
import {
  selectedSKUsSelector,
  allProductsSelector,
  selectedProductIdSelector,
  selectedProductIdsSelector
} from '../../../store/selectors/productDataSelectors';
import { publishDataSelector } from '../../ProductPublish/ProductPublishSelectors';

const selectedOptions = state => state.get('SKUSelection').get('selectedOptions');
const selectedRegions = state => state.get('SKUSelection').get('selectedRegions');

const productSelector = createSelector(
  [allProductsSelector, selectedProductIdSelector],
  (products, productId) => products.find(p => p.get('id') === productId).toJS()
);

export const nameSelector = createSelector(
  [
    productSelector,
    selectedSKUsSelector,
    selectedOptions,
    selectedRegions,
    selectedProductIdsSelector,
    publishDataSelector
  ],
  (product, selectedSkus, options, regions, selectedProductIds, publishData) => {
    // if it's a multiproduct, show generic name or published product name
    if (selectedProductIds.size > 1) {
      return publishData.getIn(['storage', 'productName']) || 'Product';
    }
    let filtered = product.details.product.regions
      .filter(region => (!regions.isEmpty() ? regions.includes(region.name) : true))
      .map(region => region['sub-categories'])
      .reduce((a, b) => a.concat(b), [])
      .map(item => item.attributes.filter(a => a.name === 'Brand' || a.name === 'Model'))
      .reduce((a, b) => a.concat(b), []);

    const brand = filtered.find(a => a.name === 'Brand');
    const model = filtered.find(a => a.name === 'Model');

    if (brand && model) {
      const variant =
        options && !options.isEmpty()
          ? options.toJS()
          : selectedSkus && !selectedSkus.isEmpty()
          ? product.variants.find(
              v => v.sku.toLowerCase() === selectedSkus.first().get('sku').toLowerCase()
            )
          : product.name;
      const brandOptionId = Array.isArray(variant[brand.id])
        ? variant[brand.id][0]
        : variant[brand.id];
      const modelOptionId = Array.isArray(variant[model.id])
        ? variant[model.id][0]
        : variant[model.id];

      if (brandOptionId && modelOptionId) {
        filtered = filtered.map(a => a.values).reduce((a, b) => a.concat(b), []);
        const brandOption = filtered.find(a => a.id === brandOptionId);
        const modelOption = filtered.find(a => a.id === modelOptionId);

        if (brandOption && modelOption) {
          return `${brandOption.name} ${modelOption.name}`;
        }
      }
    }
    return product.name;
  }
);

export const multiproductSelector = createSelector(
  [selectedProductIdsSelector],
  selectedProductIds => selectedProductIds.size > 1
);
