import { createSelector } from 'reselect';
import { cofShippingCountrySelector } from '../../../store/selectors/countriesSelectors';
import { roundFloatPrice, isPrice } from '../../../utils/price';
import { dataSelector } from '../../../store/selectors/productDataSelectors';

const cartSelector = state => dataSelector(state).get('cart');
export const cartItemsSelector = state => cartSelector(state).get('items');
export const cartTotalsSelector = state => cartSelector(state).get('totals');
export const orderSummaryErrorsSelector = state => cartSelector(state).get('errors');

const checkoutSelector = state => state.get('checkout');

const shippingObjectSelector = createSelector(checkoutSelector, checkout => {
  return checkout.get('shipping');
});

export const surchargeSelector = createSelector(checkoutSelector, checkout =>
  checkout.getIn(['shipping', 'methods', 'surcharge', 'surchargeCosts'], [])
);

const addressesObjectSelector = createSelector(shippingObjectSelector, shipping => {
  return shipping.get('addresses');
});

export const selectedShippingAddressSelector = createSelector(
  addressesObjectSelector,
  addressesObject => {
    return addressesObject.getIn(['selected', 'address']);
  }
);

const shippingMethodsSelector = createSelector(shippingObjectSelector, shipping => {
  return shipping.get('methods');
});

const shippingGroupsSelector = createSelector(shippingMethodsSelector, shippingMethods => {
  return shippingMethods.get('groups');
});

export const getPriceEstimateRequest = createSelector(
  [
    cofShippingCountrySelector,
    cartItemsSelector,
    selectedShippingAddressSelector,
    shippingGroupsSelector
  ],
  (shippingCountry, cartItems, selectedShippingAddress, shippingGroups) => {
    let convertedShippingAddress = {
      CountryCode: shippingCountry
    };

    if (selectedShippingAddress) {
      if (selectedShippingAddress.get('country') !== shippingCountry) {
        console.warn('Selected shipping address:', selectedShippingAddress.toJS());
        console.warn('Selected shipping country:', shippingCountry);
        throw new Error('Selected shipping address does not belong to selected shipping country');
      }
      convertedShippingAddress = {
        Line1: selectedShippingAddress.get('addressLine1'),
        Line2: selectedShippingAddress.get('addressLine2'),
        City: selectedShippingAddress.get('city'),
        State: selectedShippingAddress.get('state'),
        CountryCode: selectedShippingAddress.get('country'),
        PostalCode: selectedShippingAddress.get('zipCode')
      };
    }

    let items = cartItems.map(item => {
      const sku = item.getIn(['sku', 'sku']);
      const neckTagImgUrl = item.getIn(['sku', 'neck_tag_image_url']);
      const addons = neckTagImgUrl ? { [`necktag_image_url`]: neckTagImgUrl } : null;
      // NOTE: We are not sending ShipCarrierMethodId on priceEstimate
      // Since we don't need shipping price from here
      // We use /ShippingPrices API
      // default ShipMethod - not always exists, hmm(
      // let shippingMethodId = 1
      //
      // if (shippingGroups && shippingGroups.size !== 0) {
      //   const skuShippingGroup = shippingGroups.find(group => group.get('SKUs').contains(sku))
      //   if (skuShippingGroup) {
      //     shippingMethodId = skuShippingGroup.get('SelectedMethodId')
      //   }
      // }

      return {
        Quantity: item.get('quantity'),
        SKU: sku,
        // ShipCarrierMethodId: shippingMethodId,
        Images: [],
        AddOns: addons
      };
    });

    return {
      ShipToAddress: convertedShippingAddress,
      Payment: {
        CurrencyCode: 'USD'
      },
      Items: items
    };
  }
);

export const isLoadingSelector = createSelector([cartTotalsSelector], totals => {
  return totals.get('isLoading');
});

export const itemsInCartCountSelector = createSelector([cartItemsSelector], cartItems =>
  cartItems.reduce((totalQuantity, cartItem) => totalQuantity + cartItem.get('quantity'), 0)
);

export const subtotalSelector = createSelector([cartTotalsSelector], totals => {
  return totals.get('items');
});

export const shippingSelector = createSelector([cartTotalsSelector], totals => {
  return totals.get('shipping');
});

export const highestSurchargeSelector = createSelector(cartTotalsSelector, totals =>
  totals.get('surcharge', 0)
);

export const taxSelector = createSelector([cartTotalsSelector], totals => {
  return totals.get('tax');
});

const isAllCartItemsHasPricingSelector = createSelector([cartItemsSelector], cartItems =>
  cartItems.every(c => !!c.get('pricing'))
);

export const totalSelector = createSelector(
  [
    isAllCartItemsHasPricingSelector,
    subtotalSelector,
    shippingSelector,
    highestSurchargeSelector,
    taxSelector
  ],
  (isAllCartItemsHasPricing, subtotalPrice, shippingPrice, surchargePrice, taxPrice) => {
    // NOTE: !!! Check is all cart Items has pricing
    // If not do not calc total !!!
    let total;
    if (isAllCartItemsHasPricing && isPrice(subtotalPrice)) {
      total = subtotalPrice;
      if (isPrice(shippingPrice)) {
        total += shippingPrice;
        total = roundFloatPrice(total);
      }

      if (isPrice(surchargePrice)) {
        total += surchargePrice;
        total = roundFloatPrice(total);
      }

      if (taxPrice && isPrice(taxPrice.Total)) {
        total += taxPrice.Total;
        total = roundFloatPrice(total);
      }
    }

    if (!isPrice(total)) {
      // return null - to avoid showing bad prices like NaN or 99.0000001
      return null;
    }

    return total;
  }
);
