import { Map, List } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import AccountActivity from './AccountActivity';
import { AccountActivityModel } from '../../Models/AccountActivityModel';

export default baseCardReducer(
  AccountActivity.name,
  (
    state = new Map({
      data: new List(),
      page: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      busy: false,
      open: true
    })
  ) => state,
  AccountActivityModel
);
