import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './SideBar.scss';
import CloseButton from '../CloseButton';

class SideBar extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.object,
    onClose: PropTypes.func,
    showBackdrop: PropTypes.func.isRequired,
    hideBackdrop: PropTypes.func.isRequired,
    sameWidthAsPanel: PropTypes.bool
  };

  close() {
    this.props.onClose();
    this.props.hideBackdrop();
    this.handleBody(false);
  }

  render() {
    let container = document.getElementsByClassName('gooten-components-container')[0];
    let style = {
      top: window.scrollY - (container ? container.offsetTop : 0)
    };

    return (
      <div className="component-side-bar">
        <ReactCSSTransitionGroup
          transitionName={
            this.props.sameWidthAsPanel
              ? 'side-bar-panel-width-transitions'
              : 'side-bar-transitions'
          }
          transitionEnterTimeout={700}
          transitionLeaveTimeout={700}
        >
          {this.props.isOpen && (
            <div
              className={`side-bar-container ${this.props.sameWidthAsPanel ? 'panel-width' : ''}`}
              style={style}
              id="sidebar"
              ref={div => {
                this.sideBarContainer = div;
              }}
            >
              <CloseButton close={() => this.close()} />
              <div className="sb-content"> {this.props.children} </div>
            </div>
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      this.props.showBackdrop();
    }

    this.handleBody(this.props.isOpen);
  }

  componentWillUnmount() {
    this.props.hideBackdrop();
    this.handleBody(false);
  }

  handleBody(shown) {
    let openModals = document.getElementsByClassName('modal-backdrop-open');
    if (openModals.length < 1 && !shown) {
      document.body.className = document.body.className.replace(/ ?modal-open/, '');
    } else if (document.body.className.indexOf('modal-open') === -1) {
      document.body.className += document.body.className.length ? ' modal-open' : 'modal-open';
    }
  }
}

SideBar.displayName = 'SideBar';

export default SideBar;
