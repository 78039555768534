import React from 'react';
import { COLORS } from '../../constants.js';
import PropTypes from 'prop-types';
import Icon from './Icon.js';

const Select = ({
  id,
  name,
  type = 'text',
  label,
  required,
  disabled,
  placeholder,
  className,
  value,
  onChange,
  hasError = false,
  noLabel = false,
  autoComplete,

  onKeyPress,
  readOnly,
  onFocus,
  onBlur,
  autoFocus,
  errorText,
  children
}) => {
  return (
    <div className="select-container">
      <select
        id={id}
        type={type}
        name={name || null}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        className={`${className} ${disabled ? 'disabled' : ''}`}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        readOnly={readOnly}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
      >
        {placeholder && (
          <option default hidden>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {!noLabel && (
        <label htmlFor={id || label} className="body-text-2 heavy">
          {label}
        </label>
      )}
      <Icon fill={COLORS.neutralDark200} icon="chevronDown" />
      {!!errorText && (
        <div className="error-field caption-text">
          <Icon small icon="alertCircle" />
          {errorText}
        </div>
      )}
      <style jsx>
        {`
          div.select-container {
            width: 100%;
            position: relative;
            margin-bottom: ${noLabel ? '0' : '2rem'};
            display: flex;
          }
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            box-sizing: border-box;
            font-size: 1rem;
            color: ${COLORS.neutralDark200};
            height: ${noLabel ? '2.5rem' : '3rem'};
            width: 100%;
            border-radius: 4px;
            border: 1px solid ${hasError ? COLORS.cayennePepper : COLORS.neutralLight400};
            padding: ${noLabel ? '0 1rem' : '1.25rem 1rem .25rem'};
            position: relative;
            padding-right: 3rem;
            outline: none;
            cursor: pointer;
          }
          select:focus {
            border: 1px solid ${COLORS.gootenBlue500};
          }

          .select-container > :global(svg) {
            position: absolute;
            top: ${noLabel ? '-.25rem' : '0'};
            transform: translateY(50%);
            right: 1rem;
            pointer-events: none;
          }

          label {
            display: ${noLabel ? 'none' : 'block'};
            position: absolute;
            top: 0.25rem;
            left: 1rem;
            transform: translateY(0);
            font-size: 0.8125rem;
            color: ${hasError ? COLORS.cayennePepper : COLORS.neutralDark200};
            transition: all 150ms cubic-bezier(0.6, 0, 0.3, 1);
            pointer-events: none;
          }
          select:focus + label {
            font-size: 0.8125rem;
            top: 0.25rem;
            color: ${COLORS.gootenBlue500};
          }
          .error-field {
            position: absolute;
            top: ${noLabel ? '2.75rem' : '3.25rem'};
            color: ${COLORS.cayennePepper};
            display: flex;
            align-items: center;
            fill: ${COLORS.cayennePepper};
            font-size: 0.8125rem;
            line-height: 0.8125rem;
          }
          .error-field :global(svg) {
            margin-right: 0.25rem;
          }
          .disabled {
            cursor: not-allowed;
            background: ${COLORS.neutralLight200};
          }
        `}
      </style>
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  noLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  onKeyPress: PropTypes.func,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  errorText: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Select;
