import { Record } from 'immutable';

export const ProfileInformationModel = new Record({
  FirstName: '',
  LastName: '',
  EmailAddress: '',
  // We can reach phone in Partner->Person.Phone (Person is obsolete entity in db)
  // or Partner->Address.Phone (Edited in Billing Address sections)
  // TODO: remove
  Phone: '555-555555'
});
