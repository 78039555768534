import { customActionFor } from '../BaseCard/BaseCardActions';

// ------------------------------------
// Action Types
// ------------------------------------
export const UPLOAD_LOGO = customActionFor('EMAILS').async('UPLOAD_LOGO');
export const TOGGLE_UPLOAD_FROM_URL = customActionFor('EMAILS')('TOGGLE_UPLOAD_FROM_URL');
export const CHANGE_ULOAD_FROM_URL = customActionFor('EMAILS')('CHANGE_ULOAD_FROM_URL');
export const UPLOAD_FROM = customActionFor('EMAILS').async('UPLOAD_FROM');

// ------------------------------------
// Actions
// ------------------------------------

export function uploadStart(payload) {
  return {
    type: UPLOAD_LOGO.ASYNC,
    payload
  };
}

export function uploadDone(payload) {
  return {
    type: UPLOAD_LOGO.SUCCESS,
    payload
  };
}

export function uploadFailed(payload) {
  return {
    type: UPLOAD_LOGO.FAIL,
    payload
  };
}

export function toggleUploadFromUrl() {
  return {
    type: TOGGLE_UPLOAD_FROM_URL
  };
}

export function changeUploadFromUrl(payload) {
  return {
    type: CHANGE_ULOAD_FROM_URL,
    payload
  };
}

export function uploadFrom(url) {
  return {
    type: UPLOAD_FROM.ASYNC,
    payload: { url }
  };
}
