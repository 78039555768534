import { createSelector } from 'reselect';
import { parseCurrency } from '../../utils/price';
import { getProductImageResizerUrl } from 'gooten-js-utils/src/url';
import { removeDuplicatesBy } from '../../utils/array';
import DataMappingService from './services/DataMappingService';
import Config from '../../config';
import { getPossibleVendors } from '../../utils/vendor';
import { cofShippingCountrySelector } from '../../store/selectors/countriesSelectors';
import orientationService from './atoms/OptionsPanel/Options/services/orientationService';
import {
  selectedProductIdSelector,
  allProductsSelector,
  selectedSKUsSelector,
  selectedSKUsJSSelector
} from '../../store/selectors/productDataSelectors';
import { backupSkusSelector } from '../ProductPublish/ProductPublishSelectors';

const isCOFSelector = state => Config.get('cof');

const selectedOptions = state => state.get('SKUSelection').get('selectedOptions');
export const selectedOptionsSelector = state => state.getIn(['SKUSelection', 'selectedOptions']);
export const searchSelector = state => state.get(['SKUSelection', 'searchString']);
const orientationState = state => state.getIn(['SKUSelection', 'orientation']);
const selectedRegions = state => state.get('SKUSelection').get('selectedRegions');
const currentGroup = state => state.get('SKUSelection').get('groupId');

const hubStores = state => state.getIn(['hub', 'hubView', 'stores', 'items']);

const RIGHT_PLACEMENT = 'sku-select-right';
const REGIONS_OPTION_ID = 'REGIONS';

export const selectedProduct = createSelector(
  [selectedProductIdSelector, allProductsSelector],
  (selectedProductId, allProducts) => allProducts.find(p => p.id === selectedProductId)
);

export const hasProductDetails = createSelector(
  [selectedProduct],
  selectedProduct => !!selectedProduct.get('details')
);

export const hasProductVariants = createSelector(
  [selectedProduct],
  selectedProduct => !!selectedProduct.get('variants')
);

const selectedProductDetails = createSelector(
  [selectedProduct],
  selectedProduct => selectedProduct.get('details').product
);

export const allOptionsSelector = createSelector(
  [selectedProductDetails],
  selectedProduct => selectedProduct.options
);

const unfilteredRegionsSelector = createSelector(
  [selectedProductDetails],
  selectedProduct => selectedProduct.regions
);

export const possibleVendorsSelector = createSelector(
  [selectedProductDetails, cofShippingCountrySelector],
  (selectedProductDetails, shippingCountry) =>
    shippingCountry && getPossibleVendors(selectedProductDetails.vendor_routing, shippingCountry)
);

const allRegionsSelector = createSelector(
  [isCOFSelector, cofShippingCountrySelector, selectedProductDetails, unfilteredRegionsSelector],
  (isCOF, shippingCountry, selectedProductDetails, unfilteredRegions) => {
    if (isCOF && shippingCountry) {
      const possibleVendors = getPossibleVendors(
        selectedProductDetails.vendor_routing,
        shippingCountry
      );
      return unfilteredRegions.filter(region =>
        possibleVendors.some(vendorId => region['vendor-ids'].includes(vendorId))
      );
    } else {
      return unfilteredRegions;
    }
  }
);

const selectedSKUSRaw = createSelector(
  [isCOFSelector, cofShippingCountrySelector, selectedProduct, possibleVendorsSelector],
  (isCOF, shippingCountry, selectedProduct, possibleVendors) => {
    if (isCOF && shippingCountry) {
      return selectedProduct.get('variants').filter(variant =>
        possibleVendors.some(vendorId =>
          variant.vendors
            .filter(v => v.orderable)
            .map(vendor => vendor.vendorId)
            .includes(vendorId)
        )
      );
    } else {
      return selectedProduct
        .get('variants')
        .filter(variant => variant.vendors.some(v => v.creatable));
    }
  }
);

// if dropdown option is last in group
// option becomes trigger to proceed to next group
// const _isTrigger = (options, option, currentGroup, maxGroupId) => {
//   if (currentGroup === maxGroupId || option['control-type'] !== 'drop-down') {
//     return false
//   }
//   return options.filter(x => x['group-id'] === currentGroup).slice(-1)[0].id === option.id
// }

// TODO: handle trigger options
const _getResetGroup = (options, option) => {
  if (option['control-type'] !== 'drop-down') {
    return false;
  }
  return options.filter(x => x.id === option.id && !x.trigger)[0]['group-id'];
};

const _aggregateCascade = (
  currentGroup,
  allOptions,
  allRegions,
  allVariants,
  selectedOptions$,
  selectedRegions$,
  maxGroupId,
  forCurrentGroup
) => {
  let selectedOptions = selectedOptions$.toJS();
  let selectedRegions = selectedRegions$.toJS();

  return (
    allOptions
      // forCurrentGroup === true used to get dropdown options for sku selection page
      // forCurrentGroup === false used to filter options by resulting cascade
      .filter(x => {
        return forCurrentGroup
          ? x['group-id'] === currentGroup && x['control-type'] === 'drop-down'
          : x['group-id'] <= currentGroup && x['control-type'] === 'drop-down';
      })

      .map((opt, i) => {
        let parentOptionId = null;
        let selectedParentValue = null;
        let topOptionsIds;
        if (i > 0) {
          parentOptionId = allOptions.filter(x => x['control-type'] === 'drop-down')[i - 1].id;

          if (selectedOptions[parentOptionId]) {
            selectedParentValue = selectedOptions[parentOptionId][0];
          }

          topOptionsIds = [];
          let allDropDownOptionsInGroup = allOptions.filter(
            x => x['group-id'] === currentGroup && x['control-type'] === 'drop-down'
          );

          for (var j = 0; j < allDropDownOptionsInGroup.length; j++) {
            if (allDropDownOptionsInGroup[j].id === opt.id) {
              break;
            }

            topOptionsIds.push(allDropDownOptionsInGroup[j].id);
          }
        }

        let valuesRaw = allRegions
          .filter(region => !selectedRegions.length || selectedRegions.includes(region.name))
          .map(region => region['sub-categories'])
          .reduce((a, b) => a.concat(b), [])

          // filter by selected values from top of cascade
          .filter(s => {
            if (!selectedParentValue) {
              return true;
            }

            return s.attributes.some(
              a => a.values && a.values.some(v => selectedParentValue === v.id)
            );
          })
          .map(sub => sub.attributes)
          .reduce((a, b) => a.concat(b), [])
          .filter(attr => attr.id && attr.id === opt.id)
          .map(o => o.values)
          .reduce((a, b) => a.concat(b), []);

        // get uniq
        let values = [];
        valuesRaw.forEach(val => {
          if (!values.some(x => x.id === val.id)) {
            values.push(val);
          }
        });

        // hide any option values not found in vendorId-filtered SKUs
        values = values.filter(value =>
          allVariants.some(variant => Object.values(variant).includes(value.id))
        );

        return {
          ...opt,
          topOptionsIds,
          values,
          resetGroup: _getResetGroup(allOptions, opt)
        };
      })
  );
};

export const getMaxGroupId = createSelector([allOptionsSelector], allOptions => {
  let options = [...allOptions];
  return Math.max.apply(
    null,
    options.map(x => x['group-id'])
  );
});

export const getCascade = createSelector(
  [
    currentGroup,
    allOptionsSelector,
    allRegionsSelector,
    selectedSKUSRaw,
    selectedOptions,
    selectedRegions,
    getMaxGroupId
  ],
  (
    currentGroup,
    allOptions,
    allRegions,
    allVariants,
    selectedOptions$,
    selectedRegions$,
    maxGroupId
  ) => {
    let result = _aggregateCascade(
      currentGroup,
      allOptions,
      allRegions,
      allVariants,
      selectedOptions$,
      selectedRegions$,
      maxGroupId,
      true
    );
    return result;
  }
);

const excludedSelectAllProperties = ['Print Area'];
const _isSelectAllDisabledInOption = option => {
  return excludedSelectAllProperties.indexOf(option.title) >= 0;
};

const _areAllValuesSelectedInOption = (selectedOptions, option, values) => {
  if (!selectedOptions[option.id] || !values.length) {
    return false;
  }
  return values.filter(x => !!x.id).every(x => selectedOptions[option.id].includes(x.id));
};

const _isAnyValueSelectedInOption = (selectedOptions, option, values) => {
  if (!selectedOptions[option.id] || !values.length) {
    return false;
  }
  return values.filter(x => !!x.id).some(x => selectedOptions[option.id].includes(x.id));
};

const _getSelectedOptionValuesForGroup = (selectedOptions, options, groupId) => {
  let groupOpts = options.filter(x => x['group-id'] === groupId && !!selectedOptions[x.id]);
  let selectedOptsForGroup = Object.assign(
    {},
    ...groupOpts.map(x => x.id).map(key => ({ [key]: selectedOptions[key] }))
  );
  return Object.values(selectedOptsForGroup).reduce((a, b) => a.concat(b), []);
};

export const getOptionsGroup = createSelector(
  [
    currentGroup,
    allOptionsSelector,
    allRegionsSelector,
    selectedSKUSRaw,
    selectedOptions,
    selectedRegions,
    getMaxGroupId,
    backupSkusSelector
  ],
  (
    currentGroup,
    allOptions,
    allRegions,
    allVariants,
    selectedOptions$,
    selectedRegions$,
    maxGroupId,
    backupSkus
  ) => {
    let options = [...allOptions];
    let selectedRegions = selectedRegions$.toJS();
    let selectedOptions = selectedOptions$.toJS();
    const isEditVariantsMode = backupSkus?.count();

    let selectedOptionValues = _getSelectedOptionValuesForGroup(
      selectedOptions,
      allOptions,
      currentGroup - 1
    );

    const p1 = allRegions
      .filter(region => selectedRegions.includes(region.name))
      .map(region => region['sub-categories'])
      .reduce((a, b) => a.concat(b), [])

      // filter by selected values, by cascade options
      .filter(s => {
        if (isEditVariantsMode && selectedOptionValues.length) {
          return selectedOptionValues.some(sv =>
            s.attributes.some(a => a.values && a.values.some(v => sv === v.id))
          );
        }
        return selectedOptionValues.every(sv =>
          s.attributes.some(a => a.values && a.values.some(v => sv === v.id))
        );
      })
      .map(sub => sub.attributes)
      .reduce((a, b) => a.concat(b), []);

    let p2 = allVariants.reduce((acc, v) => {
      Object.values(v).forEach(x => acc.add(x));
      return acc;
    }, new Set());

    let result = options
      .filter(
        x =>
          x['group-id'] === currentGroup &&
          x['control-type'] !== 'drop-down' &&
          x['id'] !== REGIONS_OPTION_ID
      )
      .map(option => {
        let valuesRaw = p1
          .filter(attr => attr.id && attr.id === option.id)
          .map(o => o.values)
          .reduce((a, b) => a.concat(b), []);

        // deduplication
        let values = [];
        valuesRaw.forEach(val => {
          if (!values.some(x => x.id.toUpperCase() === val.id.toUpperCase())) {
            values.push(val);
          }
        });

        // hide any option values not found in SKUs
        values = values.filter(v => p2.has(v.id));

        // indicate if all values for option were selected
        let allValuesSelected = _areAllValuesSelectedInOption(selectedOptions, option, values);
        let anyValueSelected = _isAnyValueSelectedInOption(selectedOptions, option, values);
        let isSelectAllDisabled = _isSelectAllDisabledInOption(option);

        return {
          ...option,
          values,
          allValuesSelected,
          anyValueSelected,
          isSelectAllDisabled
        };
      });

    const orientationOption = result.find(o => o.id.toLowerCase() === 'orientation');
    if (orientationOption) {
      const orientationOptionIndex = result.findIndex(o => o.id.toLowerCase() === 'orientation');
      const anySquareOption = allVariants.find(v => orientationService.isSquareSize(v.sku));
      // replace Orientation option populated with value
      result[orientationOptionIndex] = orientationService.getOrientationOptionJSON(
        anySquareOption,
        orientationOption['group-id']
      );
    }

    return result;
  }
);

export const getRegionNames = createSelector([allRegionsSelector], allRegions => {
  return allRegions.map(x => x.name);
});

export const getRightPanel = createSelector([getOptionsGroup], optionsGroup => {
  return optionsGroup.filter(x => x.placement === RIGHT_PLACEMENT);
});

// Not used - all filters now on the right side
// export const getLeftPanel = createSelector([getOptionsGroup], optionsGroup => {
//   return optionsGroup.filter(x => x.placement === 'sku-select-left')
// })

export const getProductDetails = createSelector(
  [
    selectedProductDetails,
    allRegionsSelector,
    selectedOptions,
    selectedRegions,
    getMaxGroupId,
    currentGroup
  ],
  (selectedProduct, allRegions, selectedOptions$, selectedRegions$, maxGroupId, currentGroup) => {
    let selectedRegions = selectedRegions$.toJS();
    let priceRange = allRegions
      .filter(region => !selectedRegions.length || selectedRegions.includes(region.name))
      .map(region => region['sub-categories'])
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.attributes)
      .reduce((a, b) => a.concat(b), [])
      .filter(x => x['attr-type'] === 'prices')
      .map(x => {
        let result = {};
        if (x['min-price']) {
          result.min = parseCurrency(x['min-price']);
        }

        if (x['max-price']) {
          result.max = parseCurrency(x['max-price']);
        }

        return result;
      })
      .reduce((a, b) => {
        b.min = a.min ? Math.min(a.min, b.min) : b.min;
        b.max = a.max ? Math.max(a.max, b.max) : b.max;
        return b;
      }, {});

    // As detailed in https://gooten.atlassian.net/browse/TECH-6881?focusedCommentId=63001
    // It was not possible to override each prices for CBH partners under region, sub-categories, prices, so we put max_price and min_price under product level
    // We use these prices for CBH partners
    let minPrice = priceRange.min;
    let maxPrice = priceRange.max;
    const partnerGroup = Config.get('partnerGroup');
    if (
      partnerGroup &&
      partnerGroup.get('Name') &&
      partnerGroup.get('Name').toLowerCase() != 'general'
    ) {
      minPrice = parseCurrency(selectedProduct.min_price);
      maxPrice = parseCurrency(selectedProduct.max_price);
    }

    let selectedOptionValuesRaw = Object.values(selectedOptions$.toJS());
    let selectedOptionValues = selectedOptionValuesRaw.length
      ? selectedOptionValuesRaw.reduce((a, b) => a.concat(b))
      : [];

    // remove Orientation options from selectedOptionValues, in order to get
    // product images
    selectedOptionValues = selectedOptionValues.filter(
      opt => opt !== 'portrait' && opt !== 'landscape' && opt !== 'square'
    );

    let subCategories = allRegions
      .filter(region => !selectedRegions.length || selectedRegions.includes(region.name))
      .map(region => region['sub-categories'])
      .reduce((a, b) => a.concat(b), []);

    let images = subCategories
      .filter(s =>
        selectedOptionValues.every(sv =>
          s.attributes.some(a => a.values && a.values.some(v => sv === v.id))
        )
      )
      .map(sub => sub.images)
      .reduce((a, b) => a.concat(b), [])
      .map(img => getProductImageResizerUrl(img.url));

    // show fuzzy subset of images
    // if strict filter returns nothing
    if (!images.length) {
      images = subCategories
        .filter(s =>
          selectedOptionValues.some(sv =>
            s.attributes.some(a => a.values && a.values.some(v => sv === v.id))
          )
        )
        .map(sub => sub.images)
        .reduce((a, b) => a.concat(b), [])
        .map(img => getProductImageResizerUrl(img.url));
    }
    return {
      name: selectedProduct.name,
      images: removeDuplicatesBy(x => x, images),
      minCost: minPrice,
      maxCost: maxPrice
    };
  }
);

// export const getFilterTiles = createSelector([getRightPanel], options =>
//   options.filter(f => f['control-type'] === 'tiles')
// )

// export const getFilterDropDowns = createSelector([getCascade], options =>
//   options.filter(f => f['control-type'] === 'drop-down')
// )

export const isRegionsShown = createSelector(
  [currentGroup, allOptionsSelector],
  (currentGroup, allOptions) => {
    return allOptions.some(x => x.id === REGIONS_OPTION_ID && x['group-id'] === currentGroup);
  }
);

export const selectedOptionsJson = createSelector([selectedOptions], options => options.toJS());

export const isRegionsApplied = createSelector(
  [isRegionsShown, selectedRegions],
  (isRegionsShown, selectedRegions$) => {
    let selectedRegions = selectedRegions$.toJS();
    return !isRegionsShown || !!selectedRegions.length;
  }
);

export const isOptionsApplied = createSelector(
  [selectedOptions, currentGroup, getMaxGroupId],
  (options, currentGroup, maxGroupId) => {
    return !!options.size && !!options.find((v, k) => v.size);
  }
);

export const isGroupCompleted = createSelector(
  [selectedOptions, currentGroup, allOptionsSelector],
  (selectedOptions, currentGroup, allOptions) => {
    selectedOptions = selectedOptions.toJS();
    // check for Orientation option
    if (selectedOptions.orientation) {
      selectedOptions.ORIENTATION = selectedOptions.orientation;
      delete selectedOptions.orientation;
    }

    return allOptions
      .filter(x => x['group-id'] === currentGroup && x.id !== REGIONS_OPTION_ID)
      .every(
        option =>
          Object.keys(selectedOptions).includes(option.id) && selectedOptions[option.id].length
      );
  }
);

export const getAvailableSkus = createSelector(
  [selectedSKUSRaw, isOptionsApplied],
  (variants, optionsApplied) => {
    if (!optionsApplied) {
      return [];
    }

    return variants;
  }
);

export const filterOptionKeysSelector = createSelector(
  [currentGroup, allOptionsSelector],
  (currentGroup, allOptions) => {
    return [
      ...new Set(
        allOptions
          .filter(x => x['group-id'] < currentGroup && x['id'] !== REGIONS_OPTION_ID)
          .map(x => x.id)
      )
    ];
  }
);

export const getFilterSkusByGroup = createSelector(
  [filterOptionKeysSelector, selectedOptionsSelector, selectedSKUSRaw, isOptionsApplied],
  (filterOptionKeys, selectedOptions, variants, optionsApplied) => {
    if (!optionsApplied) {
      return [];
    }

    // Filter available Sku by current group options
    let filteredSkusByGroupOptions = variants.filter(sku => {
      return filterOptionKeys.every(key => {
        if (sku.hasOwnProperty(key)) {
          return selectedOptions.get(key).includes(sku[key]);
        }
        return false;
      });
    });
    return filteredSkusByGroupOptions;
  }
);

export const getFilteredSkus = createSelector(
  [getFilterSkusByGroup, selectedOptionsJson, isOptionsApplied, selectedSKUsJSSelector],
  (variants, options, optionsApplied, selectedSkus) => {
    if (!optionsApplied) {
      return [];
    }

    if (selectedSkus.length) {
      variants = [
        ...selectedSkus.map(s =>
          Object.assign(
            s,
            variants.find(v => v.sku.toLowerCase() === s.sku.toLowerCase())
          )
        ),
        ...variants.filter(
          v => !selectedSkus.find(s => v.sku.toLowerCase() === s.sku.toLowerCase())
        )
      ];
    }

    // remove Orientation to get proper filtering
    const orientations = options['orientation'];
    delete options['orientation'];

    let result = variants.filter(v => {
      return Object.keys(options)
        .filter(k => options[k])
        .every(key => {
          return (
            v[key] &&
            (!options[key].length ||
              options[key].map(x => x.toUpperCase()).includes(v[key].toUpperCase()))
          );
        });
    });

    // filter Orientation option
    if (orientations && orientations.length === 1 && orientations[0] === 'square') {
      result = result.filter(res => orientationService.isSquareSize(res.sku));
    }

    return result;
  }
);

export const skuOverflow = createSelector([getFilteredSkus], filteredSkus => {
  const isCof = Config.get('cof');
  if (isCof) {
    return 0;
  }
  return filteredSkus.length - 100;
});

export const shouldInitFromRawSkus = createSelector([selectedOptions], selectedOptions$ => {
  return !!(!selectedOptions$.toJS().length && Config.get('editMode'));
});

export const getSelectedOptionsFromRawSkus = createSelector(
  [selectedProduct, selectedSKUSRaw, shouldInitFromRawSkus, selectedSKUsSelector],
  (selectedProduct, variantsWithOptions, shouldInitFromRawSkus, selectedSkus) => {
    if (!shouldInitFromRawSkus) {
      return [];
    }

    return DataMappingService.getOptionsFromVariants(
      selectedSkus,
      selectedProduct,
      variantsWithOptions
    );
  }
);

export const isGroupCancellable = createSelector(
  [currentGroup, getMaxGroupId],
  (currentGroup, maxGroupId) => {
    return currentGroup === 1 || (maxGroupId > 2 && currentGroup === 2);
  }
);

export const getFilteredSkusMinCost = createSelector(
  [getFilteredSkus, getProductDetails],
  (variants, productDetails) =>
    !variants.length
      ? productDetails.minCost
      : variants.reduce(
          (min, curr) => (curr.minCost < min ? curr.minCost : min),
          variants[0].minCost
        )
);

export const getFilteredSkusMaxCost = createSelector(
  [getFilteredSkus, getProductDetails],
  (variants, productDetails) =>
    !variants.length
      ? productDetails.maxCost
      : variants.reduce(
          (max, curr) => (curr.maxCost > max ? curr.maxCost : max),
          variants[0].maxCost
        )
);

export const isPanelCollapsed = state => state.get('SKUSelection').get('panelCollapsedState');

export const savedOrientationsSelector = createSelector([orientationState], orientation =>
  orientation.get('saved')
);

export const defaultOrientationSelector = createSelector([orientationState], orientation =>
  orientation.get('default')
);

export const isOrientationChangedSelector = createSelector([orientationState], orientation =>
  orientation.get('changed')
);

export const publishedOrientationSelector = createSelector([orientationState], orientation =>
  orientation.get('published')
);

export const shouldChangeOrientation = createSelector(
  [defaultOrientationSelector, selectedOptionsSelector],
  (defaultOrientation, selectedOptions) => {
    // defaultOrientation can be 'portrait', 'landscape' or 'square'
    // if defaultOrientation is already 'portrait' and if user selects Portrait under options
    // we dont need to change anything, so this selector should return 'false' in that case,
    // because when it returns 'true', rotation will happen
    if (
      (selectedOptions.get('orientation') &&
        selectedOptions.get('orientation').indexOf('portrait') !== -1 &&
        defaultOrientation === 'landscape') ||
      (selectedOptions.get('orientation') &&
        selectedOptions.get('orientation').indexOf('landscape') !== -1 &&
        defaultOrientation === 'portrait')
    ) {
      return true;
    }
    return false;
  }
);

export const connectedStoresSelector = createSelector([hubStores], stores =>
  stores
    .map(s => s.get('provider'))
    .toSet()
    .toList()
);

export const modalValidationMessageSelector = createSelector([connectedStoresSelector], stores => {
  if (stores.includes('shopify')) {
    return (
      'Shopify only allows 100 SKUs per product listing. Deselect some options to reduce the SKUs' +
      ' below 100. Or Continue.'
    );
  } else if (stores.includes('woocommerce')) {
    return (
      'WooCommerce typically allows a limit of 100 SKUs per product listing. Deselect some options' +
      ' to reduce the SKUs below 100. Or Continue.'
    );
  } else if (stores.includes('tiktok')) {
    return (
      'The number of SKUs in TikTok US market should not exceed 100. Deselect some options' +
      ' to reduce the SKUs below 100. Or Continue.'
    );
  } else {
    return '';
  }
});

export const shouldPreselectOptionsSelector = createSelector(
  [selectedRegions, selectedOptions, getRightPanel],
  (regions, sOptions, options) => {
    // if region is selected, and there're no other selected options on current option's page,
    // return true, so preselection can be executed (if needed)
    sOptions = sOptions.toJS();
    return (
      regions.size === 1 &&
      options.map(o => o.id).filter(o => Object.keys(sOptions).includes(o)).length === 0
    );
  }
);

export const isApparelSelector = createSelector([selectedProduct], selectedProduct => {
  // we decided that Apparel product has to have "Brand" AND "Model" options...
  // + are in Clothing category
  return (
    selectedProduct.get('categories').includes('Clothing') &&
    selectedProduct
      .get('details')
      ?.product?.options?.filter(
        o => o?.title?.toLowerCase().includes('brand') || o?.title?.toLowerCase().includes('model')
      ).length >= 2
  );
});
