import { getActionName } from '../../CheckoutActions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const SET_FORM_VISIBLE = getActionName('SET_FORM_VISIBLE');
export const FETCH_SHIPPING_ADDRESSES = getActionName.async('FETCH_SHIPPING_ADDRESSES');
export const STORE_USER_ADDRESS = getActionName('STORE_USER_ADDRESS');
export const DELETE_SHIPPING_ADDRESS = getActionName.async('DELETE_SHIPPING_ADDRESS');
export const DELETE_USER_ENTERED_SHIPPING_ADDRESS = getActionName(
  'DELETE_USER_ENTERED_SHIPPING_ADDRESS'
);

export function setFormVisible(isVisible) {
  return {
    type: SET_FORM_VISIBLE,
    payload: {
      isVisible
    }
  };
}

export function storeUserAddress(address) {
  return {
    type: STORE_USER_ADDRESS,
    payload: { address }
  };
}

export function deleteShippingAddress(addressId) {
  return {
    type: DELETE_SHIPPING_ADDRESS.ASYNC,
    payload: { addressId }
  };
}

export function deleteUserEnteredShippingAddress(addressId) {
  return {
    type: DELETE_USER_ENTERED_SHIPPING_ADDRESS,
    payload: { addressId }
  };
}
