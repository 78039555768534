import React from 'react';
import PropTypes from 'prop-types';
import CategoryMobileList from './CategoryMobileList';
import { List } from 'immutable';
import { Category } from '../../../../models/Category';

export const CategoriesNav = props => {
  let content = (
    <div>
      <CategoryMobileList
        categories={props.categories}
        selectedCategory={props.selectedCategory}
        onSelectSubCategory={props.onSelectSubCategory}
        onSelectCategory={props.onSelectCategory}
      />
    </div>
  );

  const hoverOutCategory = () => {
    props.onHoverCategory(props.selectedCategory);
  };

  return (
    <div className="categories-nav-container" onMouseLeave={hoverOutCategory}>
      {content}
    </div>
  );
};

CategoriesNav.propTypes = {
  onSelectCategory: PropTypes.func.isRequired,
  onSelectSubCategory: PropTypes.func.isRequired,
  onHoverCategory: PropTypes.func.isRequired,

  categories: PropTypes.instanceOf(List).isRequired,
  selectedCategory: PropTypes.instanceOf(Category).isRequired
};

export default CategoriesNav;
