import { Map } from 'immutable';
import {
  EDIT_DESIGN,
  INIT_EDIT_WITH_PRP_SKU,
  UPDATE_LOADING_STATUS,
  UPDATE_PRODUCT_ID
} from './EditDesignViewActions';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import EditDesignItem from '../../models/EditDesignItem';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const defaultState = new Map({
  item: new EditDesignItem(),
  loadingStatus: 'initial',
  skipCountryCheck: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case EDIT_DESIGN:
      return state.set('item', action.payload.item);
    case UPDATE_PRODUCT_ID:
      return state.set('item', action.payload);
    case UPDATE_LOADING_STATUS:
      return state.set('loadingStatus', action.payload.status);
    case INIT_EDIT_WITH_PRP_SKU:
      // this action is called from OrderDetails page, when user init edit sku (Image Editor)
      // in that case, no matter which country is selected, we should allow user to edit an image
      return state.set('skipCountryCheck', true);
    case RESET:
      return defaultState;
    default:
      return state;
  }
};
