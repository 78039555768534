import { createSelector } from 'reselect';
import { publishStateSelector } from '../../ProductPublishSelectors';
import { availableProvidersSelector } from '../ProvidersSelectionPanel/ProvidersSelectionPanelSelectors';
import { selectedSkuArchivedReasonSelector } from '../../ProductPublishSelectors';
import { createImmutableSelector } from '../../../../utils/reselect';

const providersTabSelector = createSelector([publishStateSelector], publishState =>
  publishState.get('providersTab')
);

export const selectedProviderSelector = createSelector([providersTabSelector], providersTab =>
  providersTab.get('selected')
);

export const providersSelector = createSelector([availableProvidersSelector], allProviders =>
  allProviders.filter(p => p.get('enabled'))
);

export const isSelectedProviderAvailableSelector = createSelector(
  [providersSelector, selectedProviderSelector],
  (providers, sProvider) =>
    !!providers.find(p => sProvider && p.get('name') === sProvider.get('name'))
);

const providerVariantsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('variants')
);

export const selectedVariantsSelector = createImmutableSelector(
  [providerVariantsSelector, selectedSkuArchivedReasonSelector],
  (variants, selectedSkuWithArchivedReason) => {
    return variants.map(v =>
      v.set(
        'archivedReason',
        selectedSkuWithArchivedReason?.find(
          s => s.sku?.toLowerCase() == v.get('sku')?.toLowerCase()
        )?.archivedReason || 0
      )
    );
  }
);

export const selectedOptionsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('selectedOptions')
);

export const selectedCollectionsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('selectedCollections')
);

export const selectedTagsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('selectedTags')
);

export const productTypeSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('productType')
);

export const productNameSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('productName')
);

export const failedReasonSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('failedReason')
);

export const productDescriptionSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('productDesc')
);

export const productIdSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('productId')
);

export const optionsSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('options')
);

export const storeIdSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('id')
);

export const draftSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('draft')
);

export const providerNameSelector = createSelector([selectedProviderSelector], provider =>
  provider.get('provider')
);
