import { all } from 'redux-saga/effects';
import { watchInitAsync, watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import FulfillmentSettings from './FulfillmentSettings';
import analyticsService from 'gooten-components/src/services/analyticsService';

function* onSuccess() {
  analyticsService.track(
    'Settings - Fulfillment Settings',
    'Fulfillment Settings updated',
    'Settings'
  );
}

export default function* rootSaga() {
  yield all([
    watchInitAsync(FulfillmentSettings.name, FulfillmentSettings.messages, false),
    watchUpdateAsync(FulfillmentSettings.name, FulfillmentSettings.messages, false, onSuccess)
  ]);
}
