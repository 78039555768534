import { connect } from 'react-redux';
import FulfillmentContactEmails from './FulfillmentContactEmails';
import {
  addFulfillmentContactEmail,
  deleteFulfillmentContactEmail,
  disableFulfillmentContactEmail,
  enableFulfillmentContactEmail
} from '../EmailPreferencesActions';

const mapDispatchToProps = {
  addFulfillmentContactEmail,
  deleteFulfillmentContactEmail,
  disableFulfillmentContactEmail,
  enableFulfillmentContactEmail
};

export default connect(null, mapDispatchToProps)(FulfillmentContactEmails);
