import { connect } from 'react-redux';
import ProductList from './ProductList';
import { filteredProductsSelector } from './ProductListSelectors';
import {
  selectedCategorySelector,
  selectedSubCategorySelector,
  needForceScrollSelector
} from '../CategoriesNav/CategoriesNavSelectors';
import { forceScrollDone } from '../CategoriesNav/CategoriesNavActions';
import { openSideBar } from '../ProductDetails/ProductDetailsActions';
import {
  PRODUCT_DETAILS_FETCH,
  fetchAsync,
  selectProducts
} from '../../../../store/actions/dataActions';
import { next } from '../../../../containers/NavManager/NavManagerActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = dispatch => ({
  onClickLearnMore: productName => {
    dispatch(openSideBar());
    dispatch(fetchAsync(PRODUCT_DETAILS_FETCH, productName));
  },

  // NOTE goto SKUSelection
  gotoNextStep: () => dispatch(next()),
  selectProduct: productId => dispatch(selectProducts([productId])),
  forceScrollDone: () => dispatch(forceScrollDone())
});

const mapStateToProps = state => {
  const productLists = filteredProductsSelector(state);
  const selectedCategory = selectedCategorySelector(state);
  const selectedSubCategory = selectedSubCategorySelector(state);

  return {
    selectedCategory,
    selectedSubCategory,
    productLists,
    needForceScroll: needForceScrollSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
