import { connect } from 'react-redux';
import Section from './Section';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

export default connect(null, null)(Section);
