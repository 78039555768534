import { customActionFor } from '../BaseCard/BaseCardActions';

export const CREATE = customActionFor('NECK_LABELS').async('CREATE');
export const UPDATE = customActionFor('NECK_LABELS').async('UPDATE');
export const REMOVE = customActionFor('NECK_LABELS').async('REMOVE');

export const сreateNeckLabel = payload => ({
  type: CREATE.ASYNC,
  payload
});

export const updateNeckLabel = payload => ({
  type: UPDATE.ASYNC,
  payload
});

export const removeNeckLabel = payload => ({
  type: REMOVE.ASYNC,
  payload
});
