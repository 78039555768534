import { createSelector } from 'reselect';

// these selectors below are shared between gooten-hub and gooten-components
// gooten-components use these selectors to access gooten-hub redux state

const hubProductsStateSelector = state => state.getIn(['hub', 'hubView', 'products']);
const editDesignViewSelector = state => state.getIn(['customOrderForm', 'editDesignView']);
const orderDetailsSelector = state => state.getIn(['orders', 'orderDetails']);

export const selectedProductVariantsSelector = createSelector(
  [hubProductsStateSelector],
  productsState => productsState.get('variants')
);

export const requiredProductInfoSelector = createSelector(
  [editDesignViewSelector],
  editDesignView => editDesignView.get('item')
);

export const itemsDataSelector = createSelector([orderDetailsSelector], orders =>
  orders.get('itemsData') ? orders.get('itemsData').toJS() : []
);
