import { ActionNameFactory } from '../../../../../utils/actions';

export const getActionName = ActionNameFactory('PUBLISH-PRODUCT', 'VARIANTS');
export const SELECT_VARIANT = getActionName('SELECT_VARIANT');
export const SELECT_ALL_VARIANTS = getActionName('SELECT_ALL_VARIANTS');
export const RENAME_SKU_STORE = getActionName('RENAME_SKU_STORE');
export const RENAME_SKU_STORAGE = getActionName('RENAME_SKU_STORAGE');
export const SAVE_SKU_MOCKUP = getActionName('SAVE_SKU_MOCKUP');
export const UPDATE_SKU_PREVIEW = getActionName('UPDATE_SKU_PREVIEW');
export const UPDATE_SKU_NECKTAG = getActionName('UPDATE_SKU_NECKTAG_IMGURL');
export const CLEAR_SKU_NECKTAG = getActionName('CLEAR_SKU_NECKTAG');
export const DELETE_VARIANTS = getActionName('DELETE_VARIANTS');
export const DELETE_PRODUCT = getActionName.async('DELETE_PRODUCT');
export const ADD_VARIANT = getActionName('ADD_VARIANT');

export const selectVariant = payload => ({
  type: SELECT_VARIANT,
  payload
});

export const selectAllVariants = payload => ({
  type: SELECT_ALL_VARIANTS,
  payload
});

export const renameSkuStorage = payload => ({
  type: RENAME_SKU_STORAGE,
  payload
});

export const renameSkuStore = payload => ({
  type: RENAME_SKU_STORE,
  payload
});

export const saveSkuMockup = payload => ({
  type: SAVE_SKU_MOCKUP,
  payload
});

export const updateSkuNeckTagImgUrl = neckTagImages => ({
  type: UPDATE_SKU_NECKTAG,
  payload: { neckTagImages: neckTagImages }
});

export const updateSkuPreview = payload => ({
  type: UPDATE_SKU_PREVIEW,
  payload
});

export const addVariant = () => ({
  type: ADD_VARIANT
});

export const deleteVariants = () => ({
  type: DELETE_VARIANTS
});

export const clearSkuNeckTagPreview = () => ({
  type: CLEAR_SKU_NECKTAG
});
