import initialState from './../../store/initialState';
import { RESET } from './../../store/actions/globalActions';
import {
  IS_READY,
  SELECT_VARIANT,
  SELECT_ALL_SKUS,
  DESELECT_ALL_SKUS,
  SET_EDIT_PRODUCT_ID,
  REMOVE_VARIANT,
  SET_ORDER_AS_SAMPLE
} from './PlaceOrderViewActions';
import {
  PRODUCT_SELECT,
  PRODUCT_SELECT_BY_ID,
  PRODUCT_UNSELECT
} from './../HubView/components/Products/ProductsActions';
import { SELECT_ALL, UNSELECT_ALL } from './../HubView/components/Actions/ActionsActions';
import { List } from 'immutable';

const defaultState = initialState.getIn(['hub', 'placeOrder']);

const placeOrderViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        return defaultState;
      } else {
        return state;
      }
    }

    case IS_READY:
      return state.set('isReady', action.payload);

    case SELECT_VARIANT:
      return state.update('selectedVariantIDs', selected =>
        selected.includes(action.payload)
          ? selected.filterNot(e => e === action.payload)
          : selected.push(action.payload)
      );

    // when editDesign is clicked in Place Order list, remove selected variant
    case REMOVE_VARIANT:
      return state.update('selectedVariantIDs', selected =>
        selected.filterNot(e => e === action.payload)
      );

    case SELECT_ALL_SKUS: {
      return state.update('selectedVariantIDs', selected =>
        selected.find(e => action.payload.includes(e))
          ? selected.filterNot(e => action.payload.includes(e))
          : selected.concat(action.payload)
      );
    }

    case DESELECT_ALL_SKUS:
      return state.set('selectedVariantIDs', new List());

    case SET_EDIT_PRODUCT_ID:
      return state.set('editProductID', action.payload);

    case PRODUCT_SELECT:
    case PRODUCT_SELECT_BY_ID:
    case PRODUCT_UNSELECT:
    case SELECT_ALL:
    case UNSELECT_ALL:
      return state.set('editProductID', 0);

    case SET_ORDER_AS_SAMPLE:
      return state.set('setOrderAsSample', true);

    default:
      return state;
  }
};

export default placeOrderViewReducer;
