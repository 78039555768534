import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import EmailPreferences from './EmailPreferences';
import { EmailPreferencesModel } from '../../Models/EmailPreferencesModel';
import { UPDATE_OCCASIONAL_EMAILS } from './EmailPreferencesActions';

export default baseCardReducer(
  EmailPreferences.name,
  (
    state = new Map({
      data: new EmailPreferencesModel(),
      loading: true,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case UPDATE_OCCASIONAL_EMAILS.ASYNC:
        return state.set('busy', true);
      case UPDATE_OCCASIONAL_EMAILS.SUCCESS:
      case UPDATE_OCCASIONAL_EMAILS.FAIL:
        return state.set('busy', false);
      default:
        return state;
    }
  },
  EmailPreferencesModel
);
