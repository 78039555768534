import { GET_ARCHIVED_STATUSES } from './OrderItemsSelectionActions';
import { fromJS } from 'immutable';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['orders', 'orderItemsSelectionView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ARCHIVED_STATUSES.ASYNC:
      return state.setIn(['archivedStatuses', action.payload.vendorId, 'isLoading'], true);

    case GET_ARCHIVED_STATUSES.SUCCESS:
      return state.setIn(
        ['archivedStatuses', action.payload.vendorId],
        fromJS({
          isLoading: false,
          data: action.payload.data
        })
      );

    case GET_ARCHIVED_STATUSES.FAIL:
      return state.setIn(['archivedStatuses', action.payload.vendorId, 'isLoading'], false);
    default:
      return state;
  }
};
