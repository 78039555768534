import { all } from 'redux-saga/effects';
import { watchInitAsync, watchChangePageAsync } from '../BaseCard/BaseCardSaga';
import BillingHistory from './BillingHistory';

export default function* rootSaga() {
  yield all([
    watchInitAsync(BillingHistory.name, BillingHistory.messages),
    watchChangePageAsync(BillingHistory.name)
  ]);
}
