import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('GLOBAL');

// ------------------------------------
// Constants
// ------------------------------------
export const RESET = getActionName('RESET');

// ------------------------------------
// Actions
// ------------------------------------
export function globalReset() {
  return {
    type: RESET,
    payload: {
      scope: 'global'
    }
  };
}

export function customReset(scope) {
  return {
    type: RESET,
    payload: {
      scope
    }
  };
}
