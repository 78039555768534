import { createSelector } from 'reselect';
import { viewByOptionsSelector } from '../HubView/components/Actions/ActionsSelectors';
import { allStoresSelector } from '../HubView/components/Stores/StoresSelectors';
import { fromJS } from 'immutable';

const allViewByOption = fromJS({ key: 'all', name: 'All' });

export const rootSelector = state => state.getIn(['hub', 'linkProduct']);

export const commonDataSelector = state => state.get('data');

export const gootenProductsSelector = createSelector([commonDataSelector], data =>
  data.get('products')
);

export const selectedStoreProductSelector = createSelector([rootSelector], root =>
  root.get('productSelectionView').get('selectedProduct')
);

export const selectedGootenProductSelector = createSelector(
  [gootenProductsSelector, rootSelector],
  (products, root) =>
    products.find(p => p.get('id') === root.getIn(['SKUsSelectionView', 'selectedProductId']))
);

export const selectedMappingsSelector = createSelector([rootSelector], root =>
  root.get('SKUsSelectionView').get('variantsMappings')
);

export const storeIdSelector = createSelector([rootSelector], root =>
  root.getIn(['productSelectionView', 'storeId'])
);

export const selectedStoreNameSelector = createSelector(
  [allStoresSelector, storeIdSelector],
  (allStores, storeId) => allStores.find(s => s.get('id') === storeId)
);

export const flowVerifiedSelector = createSelector(
  [selectedStoreNameSelector],
  selectedStoreName => !!selectedStoreName
);

export const productsViewByOptionsSelector = createSelector(
  [viewByOptionsSelector],
  viewByOptions => viewByOptions.insert(0, allViewByOption)
);
