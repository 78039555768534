import React from 'react';
import PropTypes from 'prop-types';
import './PrintGuidelinesContent.scss';

export const PrintGuidelinesContent = props => {
  return (
    <div className="print-guidelines-content">
      <h3>Print Guidelines</h3>
      <hr />
      <span className="subtitle">1. Printing Guides</span>
      <span className="inner-subtitle">BLEED AREA</span>
      <span className="content">{`Ensure your artwork fills the edge of the print area so your
        product doesn't print with any white edges along the rim.`}</span>
      <span className="inner-subtitle">SAFE AREA</span>
      <span className="content">
        The best area for text and other important design elements. This area is safe from being cut
        off during production.
      </span>
      <hr />
      <span className="subtitle">2. Edge to Edge Printing</span>
      <span className="content">
        When you'd like your image to print from edge to edge, fill the entire print area.
      </span>
      <div className="image-bleed" />
      <hr />
      <span className="subtitle">3. Resolution</span>
      <span className="content">For best results, your artwork should be at least 300 DPI.</span>
      {props.showSectionOrientation ? (
        <React.Fragment>
          <hr />
          <span className="subtitle">4. Orientation</span>
          <span className="content">
            <b>Canvas Wrap: </b>When necessary rotate your artwork left for portrait canvas wraps.
          </span>
          <span className="content">
            <b>Other Products: </b>Rotate your artwork in any direction to orient it based off your
            preferences.
          </span>
        </React.Fragment>
      ) : null}
    </div>
  );
};

PrintGuidelinesContent.propTypes = {
  showSectionOrientation: PropTypes.bool
};

export default PrintGuidelinesContent;
