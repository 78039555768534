import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../config';

class HelpDataService {
  fetchHelpData() {
    const url = `${Config.get('adminApi')}OrdersApi/GetHelpData`;

    return fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }
}

export default new HelpDataService();
