import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('ORDERS', 'SUMMARY_VIEW');

export const GET_COUNTRIES = getActionName('GET_COUNTRIES');
export const GET_ADDRESS_TYPES = getActionName('GET_ADDRESS_TYPES');
export const GET_PRODUCTION_TIMES = getActionName('GET_PRODUCTION_TIMES');
export const UPDATE_COUNTRIES = getActionName('UPDATE_COUNTRIES');
export const UPDATE_ADDRESS_TYPES = getActionName('UPDATE_ADDRESS_TYPES');
export const UPDATE_PRODUCTION_TIMES = getActionName('UPDATE_PRODUCTION_TIMES');
export const GET_VARIANTS_FOR_SKU = getActionName('GET_VARIANTS_FOR_SKU');
export const UPDATE_VARIANTS_FOR_SKU = getActionName('UPDATE_VARIANTS_FOR_SKU');
export const CLEAR_SUMMARY_DATA = getActionName('CLEAR_SUMMARY_DATA');
export const CLEAR_PRODUCTION_TIMES = getActionName('CLEAR_PRODUCTION_TIMES');
export const EDITING_SHIPPING_ADDRESS = getActionName('EDITING_SHIPPING_ADDRESS');
export const EDITING_BILLING_ADDRESS = getActionName('EDITING_BILLING_ADDRESS');
export const DISABLE_SHIPPING_ADDRESS_EDIT = getActionName('DISABLE_SHIPPING_ADDRESS_EDIT');

export function getCountries() {
  return {
    type: GET_COUNTRIES
  };
}

export function getAddressTypes() {
  return {
    type: GET_ADDRESS_TYPES
  };
}

export function getProductionTimes(recipeId, data) {
  return {
    type: GET_PRODUCTION_TIMES,
    payload: { recipeId, data }
  };
}

export function updateCountries(data) {
  return {
    type: UPDATE_COUNTRIES,
    payload: { data }
  };
}

export function updateAddressTypes(data) {
  return {
    type: UPDATE_ADDRESS_TYPES,
    payload: { data }
  };
}

export function updateProductionTimes(data) {
  return {
    type: UPDATE_PRODUCTION_TIMES,
    payload: { data }
  };
}

export function clearSummaryData() {
  return {
    type: CLEAR_SUMMARY_DATA
  };
}

export function updateEditingShippingAddress(data) {
  return {
    type: EDITING_SHIPPING_ADDRESS,
    payload: { data }
  };
}

export function updateEditingBillingAddress(data) {
  return {
    type: EDITING_BILLING_ADDRESS,
    payload: { data }
  };
}

export function getVariantsForSku(partnerId, vendorId, sku) {
  return {
    type: GET_VARIANTS_FOR_SKU,
    payload: { partnerId, vendorId, sku }
  };
}

export function updateVariantsForSku(partnerInfo, data) {
  return {
    type: UPDATE_VARIANTS_FOR_SKU,
    payload: { partnerInfo, data }
  };
}

export function clearProductionTimes() {
  return {
    type: CLEAR_PRODUCTION_TIMES
  };
}

export const disableShippingAddressEdit = payload => ({
  type: DISABLE_SHIPPING_ADDRESS_EDIT,
  payload
});
