import { Map } from 'immutable';
import initialState from '../../../../store/initialState';
import { PROVIDER_SELECT } from './ProvidersActions';
import { STORES_FETCH, STORE_LOG_OUT } from '../Stores/StoresActions';
import { RESET } from '../../../../store/actions/globalActions';

const defaultState = initialState.getIn(['hub', 'hubView', 'providers']);

const providersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        // reset state only if scope is global
        return defaultState;
      } else {
        return state;
      }
    }
    case PROVIDER_SELECT: {
      return state.set('selected', action.payload.provider);
    }

    case STORES_FETCH.SUCCESS: {
      // add providers available from connected stores
      return state.update('items', items => {
        let uniqueProviders = items.reduce((res, item) => res.add(item.get('id')), new Set());
        action.payload.stores.forEach(s => {
          if (!uniqueProviders.has(s.provider)) {
            items = items.push(
              new Map({
                id: s.provider,
                name: s.provider
              })
            );
            uniqueProviders.add(s.provider);
          }
        });

        return items;
      });
    }

    case STORE_LOG_OUT.SUCCESS: {
      return state
        .set('selected', 'all')
        .update('items', items =>
          !action.payload.providerToRemove
            ? items
            : items.filter(i => i.get('name') !== action.payload.providerToRemove)
        );
    }

    default:
      return state;
  }
};

export default providersReducer;
