export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

// TODO: Use strongest randomization: https://github.com/kelektiv/node-uuid/blob/master/lib/rng-browser.js
export const getGuid = (len = undefined, radix = undefined) => {
  // Private array of chars to use
  const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  let chars = CHARS;
  let guid = [];
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (var i = 0; i < len; i++) guid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;
    // rfc4122 requires these characters
    guid[8] = guid[13] = guid[18] = guid[23] = '-';
    guid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!guid[i]) {
        r = 0 | (Math.random() * 16);
        guid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return guid.join('');
};

const pad2 = n => (n < 10 ? '0' + n : n);

// yyyyMMddHHmmssSSS - 20171013200431123
export const getDateNowStr = () => {
  const date = new Date();
  const yyyy = date.getFullYear().toString();
  var MM = pad2(date.getMonth() + 1);
  var dd = pad2(date.getDate());
  var HH = pad2(date.getHours());
  var mm = pad2(date.getMinutes());
  var ss = pad2(date.getSeconds());
  var SSS = pad2(date.getMilliseconds());

  return `${yyyy}${MM}${dd}${HH}${mm}${ss}${SSS}`;
};

// yyyy-MM-dd - 2018-03-30
export const getDateUTCNowShortStr = () => {
  const date = new Date();
  const yyyy = date.getUTCFullYear().toString();
  var MM = pad2(date.getUTCMonth() + 1);
  var dd = pad2(date.getUTCDate());

  return `${yyyy}-${MM}-${dd}`;
};

// detects option value pattern "11 oz (2)" or "11 oz [2]" and returns 2.
export const getLastIndex = s => {
  const lastIndexRegex = /^.*?[\(\[](\d+)[\)\]]$/;
  const lastIndex = lastIndexRegex.exec(s);
  return lastIndex ? lastIndex[1] : -1;
};
