import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { updateActionNameFor } from '../BaseCard/BaseCardActions';
import { cardsSelector } from '../BaseCard/BaseCardSelectors';
import {
  UPLOAD_LOGO,
  UPLOAD_FROM,
  uploadStart,
  uploadDone,
  uploadFailed
} from './EmailsContentActions';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import EmailsContent from './EmailsContent';

export function* uploadDoneAsyncHandler(action) {
  yield put(push('Logo updated.', 'success'));
}

export function* watchUploadDone() {
  yield takeLatest(UPLOAD_LOGO.SUCCESS, uploadDoneAsyncHandler);
}

export function* uploadFailAsyncHandler(action) {
  yield put(push('Failed to update logo.', 'failure'));
}

export function* watchUploadFail() {
  yield takeLatest(UPLOAD_LOGO.FAIL, uploadFailAsyncHandler);
}

const update = updateActionNameFor(EmailsContent.name);

export function* UpdateCardAsyncHandler(action) {
  try {
    const cards = yield select(cardsSelector);
    const data = cards.getIn(['emailsContent', 'data']);
    const request = {
      ...data.toJS()
    };
    yield call(settingsApiService.updateNotificationSettings, request, 'mailContent');
    yield put(fetchAsyncSuccess(update, { data }));
    yield put(push('Emails content updated', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(update, err));
    yield put(push('Failed to update Emails content', 'failure'));
  }
}

export function* watchUpdateCard() {
  yield takeLatest(update.ASYNC, UpdateCardAsyncHandler);
}

export function* UploadFromAsyncHandler(action) {
  let result = null;
  try {
    yield put(uploadStart());
    result = yield call(settingsApiService.uploadFromUrl, action.payload.url);
    yield put(uploadDone(result));
  } catch (err) {
    yield put(uploadFailed(err));
  }
}

export function* watchUploadFrom() {
  yield takeLatest(UPLOAD_FROM.ASYNC, UploadFromAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchUpdateCard(), watchUploadDone(), watchUploadFail(), watchUploadFrom()]);
}
