import { Map, List } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import NeckLabels from './NeckLabels';
import NeckLabel from 'gooten-components/src/models/NeckLabel';
import { CREATE, UPDATE, REMOVE } from './NeckLabelsActions';

export default baseCardReducer(
  NeckLabels.name,
  (
    state = new Map({
      data: new List(),
      loading: true,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case CREATE.ASYNC:
      case UPDATE.ASYNC:
      case REMOVE.ASYNC:
        return state.set('busy', true);
      case CREATE.FAIL:
      case UPDATE.FAIL:
      case REMOVE.FAIL:
        return state.set('busy', false);
      case CREATE.SUCCESS:
        return state
          .set('busy', false)
          .update('data', data => data.push(new NeckLabel(action.payload)));
      case UPDATE.SUCCESS:
        return state.set('busy', false).update('data', data =>
          data.update(
            data.findIndex(i => i.get('id') === action.payload.id),
            () => new NeckLabel(action.payload)
          )
        );
      case REMOVE.SUCCESS:
        return state
          .set('busy', false)
          .update('data', data => data.filter(i => i.get('id') !== action.payload.id));
      default:
        return state;
    }
  },
  NeckLabel
);
