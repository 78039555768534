import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import { PasswordModel } from '../../Models/PasswordModel';
import Password from './Password';
import { updateActionNameFor, changeActionNameFor } from '../BaseCard/BaseCardActions';
import { SEND_EMAIL } from './PasswordActions';

const change = changeActionNameFor(Password.name);
const update = updateActionNameFor(Password.name);
export default baseCardReducer(
  Password.name,
  (
    state = new Map({
      loading: false,
      isPristine: true,
      busy: false,
      validationError: null,
      data: new PasswordModel(),
      open: false
    }),
    action
  ) => {
    switch (action.type) {
      case SEND_EMAIL.ASYNC:
        return state.set('busy', true);
      case SEND_EMAIL.SUCCESS:
      case SEND_EMAIL.FAIL:
        return state.set('busy', false);
      case update.FAIL:
        return state
          .set('isPristine', false)
          .set('validationError', action.payload.err ? action.payload.err.msg : null);
      case update.SUCCESS:
      case change:
        return state.set('isPristine', false).set('validationError', null);
      default:
        return state;
    }
  }
);
