import { createSelector } from 'reselect';
import { selectedProductsSelector } from '../../../../store/selectors/productDataSelectors';
import Config from '../../../../config';
import { variantsSelector } from '../../../../components/ImageUpload/ImageUploadSelectors';

/*
  Checks if product has embroidery flag, and if partner id is in a list of supported partners.
  This is used in products list (grid) where any product is not yet selected
*/
export const isEmbroideryProduct = product => product && product.get('embroidery');

/*
  Checks the same as above, except here we're using selected product
*/
export const isEmbroiderySelected = createSelector(
  [selectedProductsSelector],
  selectedProducts =>
    !!(
      selectedProducts &&
      selectedProducts.size &&
      selectedProducts.first() &&
      isEmbroideryProduct(selectedProducts.first())
    )
);

export const requiredImageSizeSelector = createSelector([variantsSelector], variants =>
  variants && variants.length
    ? [
        ...new Map(
          variants
            .filter(v => v?.template?.size?.width && v.template?.size?.height)
            .map(v => ({
              width: v.template.size.width,
              height: v.template.size.height,
              type: 'png'
            }))
            .map(item => [`${item.width}-${item.height}`, item])
        ).values()
      ]
    : []
);

export const templatesUrl = () =>
  'https://s3.amazonaws.com/gooten-admin-assets/embroidery_template.zip';

// TEMPORARY - enable DST only for Push Entertainment and milostestingnewhub partner
export const supportDST = () => [125640, 54644, 20784].includes(Config.get('partnerId'));
