import type { Rect, Point } from '../flow/types';

//NOTE: Make height/width smaller to allow padding
const PADDING = 40;

export class PositioningService {

  constructor() {}

  /**
   * Return rect for given layer
   * @param {Object} template
   * @param {Number} surfaceWidth
   * @param {Number} surfaceHeight
   */
  getViewportRect(layer: any): Rect {
    const targetRect = { x: layer.x1, y: layer.y1, width: layer.x2 - layer.x1, height: layer.y2 - layer.y1 };

    return targetRect;
  }

  /**
   * Add real width, height, scale to template
   * @param {Object} template
   * @param {Number} surfaceWidth
   * @param {Number} surfaceHeight
   */
  initTemplatePosition(template: any, surfaceWidth: number, surfaceHeight: number) {
    const imageLayers = template.layers.filter((l) => l.type === 'image');

    const templateRect = this.getMaxRect(template.layers);
    const printRect = this.getMaxRect(imageLayers);

    const scale = Math.min(
      surfaceWidth/templateRect.width,
      surfaceHeight/templateRect.height
    );

    Object.assign(template, {
      templateWidth: templateRect.width,
      templateHeight: templateRect.height,
      finalX1: printRect.x1,
      finalY1: printRect.y1,
      finalX2: printRect.x2,
      finalY2: printRect.y2,
      scale: scale
    });
  }

  /**
   * Returns max boundaries rect
   * @param {Array} layers
   */
  getMaxRect(layers: any[]) {
    var maxX = 0, maxY = 0;
    var minX = layers[0].x1;
    var minY = layers[0].y1;

    layers.forEach((layer) => {
      maxX = Math.max(maxX, layer.x2);
      maxY = Math.max(maxY, layer.y2);

      minX = Math.min(minX, layer.x1);
      minY = Math.min(minY, layer.y1);
    });

    return {
      x1: minX,
      x2: maxX,
      y1: minY,
      y2: maxY,
      width: maxX-minX,
      height: maxY-minY
    };
  }

  /**
   * Add real x, y, width, height, scale to image
   * @param {Object} template
   * @param {Number} surfaceWidth
   * @param {Number} surfaceHeight
   */
  initImagePosition(image: any, template: any) {
    let imageLayer = template.layers.find(l => l.id === image.layerId);
    // Viewport coordinates in canvas
    let vW = (imageLayer.x2 - imageLayer.x1);
    let vH = (imageLayer.y2 - imageLayer.y1);


    let dW = vW / image.realSourceWidth;
    let dH = vH / image.realSourceHeight;

    //Will extend/squize image to fill viewport width or height
    let scale = Math.max(dW, dH);

    let viewportRect = this.getViewportRect(imageLayer);
    Object.assign(image, {
      x: viewportRect.x + (viewportRect.width / 2),
      y: viewportRect.y + (viewportRect.height / 2),
      //Save in state to calculate max scale for small images
      initialScale : scale,
      scale: scale
    });
  }

  centerImage(state: any) {
    let currentImage = !!state.images.current.selected.imageId
      ? state.images.current.images.find((i) => i.id === state.images.current.selected.imageId)
      : null;

    if (!currentImage || !currentImage.sourceWidth) {
      return {};
    }

    let imageLayer = state.template.layers.find((l) => l.id === state.images.current.selected.layerId);

    let viewportRect = this.getViewportRect(imageLayer);

    return {
      x: viewportRect.x + (viewportRect.width / 2),
      y: viewportRect.y + (viewportRect.height / 2)
    };
  }

  reconstituteEditor(state: any) {
    var imageLayers = state.template.layers.filter((l) => l.type === 'image');

    let templateRect = this.getMaxRect(state.template.layers);
    let printRect = this.getMaxRect(imageLayers);

    let scale = Math.min(
      state.editor.width/templateRect.width,
      state.editor.height/templateRect.height
    );

    state.template.layers.forEach((layer) => {
      let updated = Object.assign({}, layer);
      let sWidth = layer.x2 - layer.x1;
      let sHeight = layer.y2 - layer.y1;
      updated.width = sWidth * scale;
      updated.height = sHeight * scale;
    });

    return {
      editor : {},
      template : {
        templateWidth: templateRect.width,
        templateHeight: templateRect.height,
        finalX1: printRect.x1,
        finalY1: printRect.y1,
        finalX2: printRect.x2,
        finalY2: printRect.y2,
        scale: scale
      }
    };
  }

  rotate90Image(state: any) {
    let currentImage = !!state.images.current.selected.imageId
      ? state.images.current.images.find((i) => i.id == state.images.current.selected.imageId)
      : null;

    if (!currentImage) {
      return null;
    }

    return (currentImage.rotation || 0) + 90;
  }

  getTemplateSize(options) {
    let isPrintPreview = options.preview === 'print';
    var targetRect;
    if (!isPrintPreview) {
      targetRect = { x: 0, y: 0, width: options.template.templateWidth, height: options.template.templateHeight };
    }
    else {
      targetRect = { x: options.template.finalX1, y: options.template.finalY1, width: options.template.finalX2 - options.template.finalX1, height: options.template.finalY2 - options.template.finalY1 };
    }

    return targetRect;
  }

  getCurrentScale(canvasSize, templateRect) {
    let scale = Math.min(canvasSize.width / templateRect.width, canvasSize.height / templateRect.height);
    return scale;
  }

  convertToCanvasSize(rect, options) {
    let templateRect = this.getTemplateSize(options);

    // NOTE: Make height/width smaller to allow padding
    let canvasSize = { width: options.width - PADDING, height: options.height - PADDING };

    let scale = this.getCurrentScale(canvasSize, templateRect);
    let width = rect.width * scale;
    let height = rect.height * scale;
    let finalRect = {
      width: width,
      height: height,
      x: (rect.x - templateRect.x) * scale + PADDING/2,
      y: (rect.y - templateRect.y) * scale + PADDING/2,
      scale: scale
    };

    // NOTE: Center Vertical/Horizontal
    if (canvasSize.width / templateRect.width < canvasSize.height / templateRect.height) {
      finalRect.y += (canvasSize.height - templateRect.height * scale) / 2;
    }
    else {
      finalRect.x += (canvasSize.width - templateRect.width * scale) / 2;
    }

    return finalRect;
  }

  convertToTemplateSize(rect, state) {
    //NOTE: Make height/width smaller to allow padding
    let templateRect = this.getTemplateSize(state);
    let canvasSize = { width: state.editor.width - PADDING, height: state.editor.height - PADDING };

    let scale = this.getCurrentScale(canvasSize, templateRect);
    let  finalRect = {
      x: ((rect.x - PADDING/2)  - (canvasSize.width - templateRect.width * scale) / 2) / scale  + templateRect.x,
      y: ((rect.y - PADDING/2) - (canvasSize.height - templateRect.height * scale) / 2) / scale  + templateRect.y
    };

    return finalRect;
  }

  /**
   * Rotate point(x, y) according center(x, y) to given angle degrees
   * @param {Point} point
   * @param {Point} centerPoint
   * @param {Number} angle
   */
  getRotatedPoint(point: Point, centerPoint: Point, angle: number): Point {
    let radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (point.x - centerPoint.x)) + (sin * (point.y - centerPoint.y)) + centerPoint.x,
      ny = (cos * (point.y - centerPoint.y)) - (sin * (point.x - centerPoint.x)) + centerPoint.y;
    return {
      x: nx,
      y: ny
    };
  }

  /**
   * Return max rect for rotated rect
   * imagine romb inside rect
   * @param {Number} centerX
   * @param {Number} centerY
   * @param {Number} width
   * @param {Number} height
   * @param {Number} rotation
   */
  getRotatedMaxRect(centerX, centerY, width, height, rotation){
    const halfWidth = width/2;
    const halfHeight = height/2;

    const center = {
      x: centerX,
      y: centerY
    };

    const notRotatedTopLeft = {
      x: (centerX - halfWidth),
      y: (centerY - halfHeight)
    };
    const notRotatedBottomLeft = {
      x: (centerX - halfWidth),
      y: (centerY + halfHeight)
    };
    const notRotatedTopRight = {
      x: (centerX + halfWidth),
      y: (centerY - halfHeight)
    };
    const notRotatedBottomRight = {
      x: (centerX + halfWidth),
      y: (centerY + halfHeight)
    };

    const rotatedTopLeft = this.getRotatedPoint(
      notRotatedTopLeft,
      center,
      -rotation || 0
    );
    const rotatedBottomLeft = this.getRotatedPoint(
      notRotatedBottomLeft,
      center,
      -rotation || 0
    );
    const rotatedTopRight = this.getRotatedPoint(
      notRotatedTopRight,
      center,
      -rotation || 0
    );
    const rotatedBottomRight = this.getRotatedPoint(
      notRotatedBottomRight,
      center,
      -rotation || 0
    );

    const maxRect = this.getMaxRect([
      {x1: rotatedBottomLeft.x, y1: rotatedBottomLeft.y, x2: rotatedTopRight.x, y2: rotatedTopRight.y},
      {x1: rotatedTopLeft.x, y1: rotatedTopLeft.y, x2: rotatedBottomRight.x, y2: rotatedBottomRight.y},
      {x1: rotatedTopRight.x, y1: rotatedTopRight.y, x2: rotatedBottomLeft.x, y2: rotatedBottomLeft.y},
      {x1: rotatedBottomRight.x, y1: rotatedBottomRight.y, x2: rotatedTopLeft.x, y2: rotatedTopLeft.y}
    ]);

    return maxRect;
  }

  /**
   * rounds number to 3 digit after comma
   * @param {Number} number to round
   */
  round(number: number){
    return number.toFixed(3)/1;
  }
}

// singleton
export default new PositioningService();
