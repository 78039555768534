import { Map, List, fromJS } from 'immutable';
import {
  ALL_PRODUCTS_FETCH,
  SET_QUERY,
  QUERY_IN_PROGRESS,
  UPDATE_QUERY,
  SELECT_PRODUCT,
  VARIANTS_FETCH,
  PUBLISH_IN_PROGRESS,
  SHOW_TOP_NOTIFICATION
} from './HubProductSelectionActions';
import {
  SET_OPTION_SELECTED_VALUES,
  SET_PAGE
} from './../../../SKUsList/HubProductSKUsList/HubSKUsListActions';

export const resetHubProductSelectionState = state => {
  return state
    .setIn(['hubProductSelection', 'selectedProductId'], null)
    .setIn(['hubProductSelection', 'page'], 1)
    .setIn(['hubProductSelection', 'options'], new List())
    .set(
      'variantsMappings',
      state.get('variantsMappings').map(m => m.set('uniqueProduct', null).set('skiped', false))
    )
    .set('canGoNext', false)
    .set('currentVariantIndex', 0);
};

export const hubProductSelectionReducer = (state, action) => {
  switch (action.type) {
    case ALL_PRODUCTS_FETCH.SUCCESS: {
      return state.updateIn(['hubProductSelection', 'cachedProducts'], items =>
        items.push(new Map().set(action.payload.key, fromJS(action.payload.res)))
      );
    }

    case SET_QUERY: {
      return state.setIn(['hubProductSelection', 'query'], action.payload);
    }

    case UPDATE_QUERY: {
      return state.setIn(
        ['hubProductSelection', 'query', action.payload.field],
        action.payload.value
      );
    }

    case QUERY_IN_PROGRESS: {
      return state.setIn(['hubProductSelection', 'queryInProgress'], action.payload);
    }

    case SELECT_PRODUCT: {
      return !action.payload
        ? resetHubProductSelectionState(state)
        : state
            .setIn(['hubProductSelection', 'selectedProductId'], action.payload)
            .setIn(
              ['hubProductSelection', 'options'],
              state
                .getIn(['hubProductSelection', 'cachedProducts'])
                .filter(
                  cachedProduct =>
                    cachedProduct
                      .getIn([new List(cachedProduct.keys()).first(), 'products'])
                      .filter(a => a.get('id') === action.payload).size
                )
                .map(item =>
                  item
                    .getIn([new List(item.keys()).first(), 'products'])
                    .filter(a => a.get('id') === action.payload)
                    .first()
                    .get('options')
                )
                .first()
            )
            .updateIn(['hubProductSelection', 'options'], options =>
              options.map(a => a.set('selectedValues', new List()))
            );
    }

    case VARIANTS_FETCH.SUCCESS: {
      return state.updateIn(['hubProductSelection', 'cachedProducts'], cachedProducts =>
        cachedProducts.map(item =>
          item.updateIn([new List(item.keys()).first(), 'products'], products =>
            products.map(p =>
              p.get('id') === action.payload.productId
                ? p.update('variants', value => fromJS(action.payload.data.res))
                : p
            )
          )
        )
      );
    }

    case SET_OPTION_SELECTED_VALUES: {
      return state
        .updateIn(['hubProductSelection', 'options'], options =>
          options.update(
            options.findIndex(o => o.get('id') === action.payload.optionId),
            o =>
              o.set(
                'selectedValues',
                o
                  .get('selectedValues')
                  .filterNot(sv => sv.get('isSuggested') === action.payload.isSuggested)
                  .concat(fromJS(action.payload.values))
              )
          )
        )
        .setIn(['hubProductSelection', 'page'], 1);
    }

    case SET_PAGE: {
      return state.setIn(['hubProductSelection', 'page'], action.payload);
    }

    case PUBLISH_IN_PROGRESS: {
      return state.setIn(['hubProductSelection', 'publishInProgress'], action.payload);
    }

    case SHOW_TOP_NOTIFICATION: {
      return state
        .setIn(['hubProductSelection', 'topNotification', 'show'], action.payload.show)
        .setIn(
          ['hubProductSelection', 'topNotification', 'message'],
          action.payload.message ||
            state.getIn(['hubProductSelection', 'topNotification', 'message'])
        );
    }

    default:
      return state;
  }
};
