import ImagePreviewControl from 'gooten-js-preview/src/_scripts/main';
import { postJson } from '../../../utils/http';
import { downloadImages } from '../../../utils/zip';
import { prepareIlForManipCommand } from '../../../utils/imgmanip';
import { mapRetryableTask, runRetryableTasks } from '../../../utils/promise';
import Config from '../../../config';
import logService from '../../../services/logService';
import proPreviewService from '../../../services/proPreviewService';
import externalMockupsService, {
  getSmallPrintImages
} from '../../../services/externalMockupsService';
import imageEditorService from '../../ImageUpload/services/imageEditorService';
import { canSubmitSourceImage } from '../../Checkout/CheckoutSelectors';

// We proxy all image generation requests via pro-previews API
// Depeneds on command name it will proxy pass request to Python or .Net ImageManips
// This is request format required by ProPreview API, until we fix req validation, we pass placeholders in Areas
// The only actual param is a first command object
export const getImgManipReq = (name, args, rotation = null) => {
  if (rotation !== null && args.commands?.length > 0) {
    // force image rotation for provided degrees, it will be injected into img manip command
    const rotateCmd = args.commands.find(cmd => cmd.name === 'rotate');
    if (rotateCmd) {
      rotateCmd.args = { ...rotateCmd.args, degrees: rotation };
    } else {
      args.commands.push({
        name: 'rotate',
        args: {
          degrees: rotation
        }
      });
    }
  }

  return {
    // Means that it's not pro preview - and will be processed by Python or .Net img manip
    template: 'classic',
    // Stubs to pass pro previews API request validation scheme, TODO: FIX
    areas: [
      {
        layerId: 'string',
        spaceId: 'string',
        objId: 'string',
        url: 'string',
        commands: [
          {
            name: 'string',
            args: {}
          }
        ]
      }
    ],
    sku: 'string',
    // If keep false it will place it temp bucket with 30 days expiration
    // Only staging and small print images should go there
    keep: Config.get('env') === 'live' && name !== 'print-small',
    commands: [
      {
        // Available command names
        // `necktag` -> Python ImgManip
        // `thumbnail` -> Python ImgManip
        // `preview` -> Python ImgManip
        // `print-small` -> Python ImgManip
        // `print` -> .Net ImgManip (async)
        name: name,
        args: args
      }
    ]
  };
};

export const getImageProcessUrl = () => `${Config.get('proPreviewApi')}generate/`;

export const getAuthQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId'),
    partnerBillingKey: Config.get('storeApiKey'),
    staging: Config.get('env') === 'staging'
  },
  headers: {
    'Content-Type': 'application/json'
  }
});

const generateUniquePreviewKeyFromVariant = (variant, optionIdsMap) => {
  return (
    variant[optionIdsMap.color] +
    variant[optionIdsMap.printPlacement] +
    variant[optionIdsMap.brand] +
    variant[optionIdsMap.model] +
    variant[optionIdsMap.gender]
  ).replace(/\s/g, '-');
};

const findOptionIdsMap = variant => {
  // This check if passed variant belongs to one of the products
  // where we can aggreate previews and return option ids map
  if (!variant) {
    return null;
  }
  /*
   * This is not very elegant; however, we must somehow determin
   * what we are dealing with. Each garment assigns different key to size,
   * color, etc. so we need to hardcode each in order to know how to handle
   * those peculiarities...
   */
  const options = [
    {
      // T-Shirts
      color: '09259805-645B-41C3-80E1-E2E1C89579CF',
      size: 'E42A9DAF-060E-43D1-9C7E-6BE1D74025DB',
      medium: 'DB0982D3-4B80-4DDB-85F3-95161E8D0E1D',
      large: 'DAFD48AC-19FC-427F-B198-4EFA05CC3924',
      printPlacement: '2333EE6B-1AA5-478B-8D63-2BA2E9EA72DE',
      brand: 'A8983CA8-CB74-406E-91B3-F032325D2798',
      model: 'CBBDB745-7007-46A2-9781-816D151C5031',
      gender: 'D12E7F74-78DC-455A-A598-5162534A5B51'
    },
    {
      // T-Shirts youth
      color: '60CC18AB-2FDD-4C25-AFA0-D565965184AE',
      size: '56E4A698-EA78-4DDD-B867-2C6A9F7201A3',
      medium: '85BE43E3-DB0C-4E57-8296-AC3E75798430',
      large: '540EBBDC-6890-491C-B5DC-06F374E1165F',
      printPlacement: '8EEA7702-25F5-4B26-A852-F87761CA11B8',
      brand: 'FB4A095D-B470-498F-B2A8-A2F1E9B903AF',
      model: '1F817541-5EDD-462A-AA38-3DF1DB3C6D69',
      gender: '208DCE41-0979-466A-B2D8-FCB04D8BC49D'
    },
    {
      // T-Shirts infant
      color: 'A0C7592B-CAFF-4DC1-85E6-A81165728A93',
      size: '654F6DBD-1B49-471A-AAFC-A58B11D221AF',
      medium: '368CD8DB-473C-4E3B-90CD-8D8A35C6A8A5',
      large: '97F686D6-C5F7-41A0-B394-37F7A553ED8D',
      printPlacement: 'E2E57BBD-34DB-400A-AC0F-37F2037E88F2',
      brand: '3AF63D03-DF28-429C-8A47-2EE709EC2954',
      model: 'C5FF34CE-5025-47D6-B0F0-DBDB359D10FF',
      gender: '48D75CD1-9D21-4973-869C-6E0CEC385CFE'
    },
    {
      // Hoodies with zipper
      color: 'EF325F9F-B60F-43F9-BB89-79580DDCA71A',
      size: 'C30AE6B9-FF44-4A4E-8985-CD4ED55F3B24',
      medium: '48030651-ED56-4647-A72E-9F8C76B60B55',
      large: '0F5986CC-BE83-4608-9B94-6AE39C29988A',
      printPlacement: '99CA5CAC-A7B8-4091-BCF5-71AFECE26782',
      brand: 'A976A4FC-B9AB-4E57-99C2-0042D187DF3B',
      model: '4CB85121-3107-4DCF-86CE-C04CF9C03F87',
      gender: '28EF3A14-CA83-4133-91ED-22E399E12ADA'
    },
    {
      // Hoodies with no zipper
      color: '50326F09-9863-4F78-B51C-5E7F68F2DDE3',
      size: 'D0AD1CA1-09D2-48E0-9E24-F9347F1D4AC8',
      medium: '62B047B7-9788-466D-BB41-636F8CE16414',
      large: 'A2C7AA3B-745A-4933-8F79-BAFFDB7B2451',
      printPlacement: '3F7E8F48-4FD9-46AE-B8A6-AF4CC0E54E7A',
      brand: 'C366F110-4544-4BDC-93DC-F55D5CCC5A3D',
      model: '34BD065A-DFE0-4292-92ED-10594D7DFBA7',
      gender: '9C6A6A75-BFB7-49E6-B1FB-7E40D76F8948'
    },
    {
      // Hoodies junior
      color: '5D6F82E6-AA51-4906-B337-F8F6AC956060',
      size: '2FA665C2-8629-4883-89EA-736C17D6F1F6',
      medium: '2271CEDB-5242-416D-A8CF-7B1E70B85A3D',
      large: '27524673-70CB-4E19-8457-BB439F1CFCF7',
      printPlacement: 'D8F75421-6C02-409D-A25A-5101961A4369',
      brand: '1E75BB7B-1556-4EF8-927B-8CD3C22ED6F0',
      model: 'E02137B4-E018-4534-9AD9-11F6E96254AB',
      gender: 'C50A9F97-8FC3-4DCE-9B33-70579A0FD805'
    },
    {
      // Sweatshirts
      color: 'A4EFAF9C-E5DA-48E5-A776-42BA5E8DC892',
      size: 'CD91AD23-9CA1-4D79-8159-82B5B0A75562',
      medium: '361520DD-14DE-430D-B45A-E48340BBFCA2',
      large: '228AD1D5-CD25-4EC9-88EF-0CEEADD64A14',
      printPlacement: 'B21EA0AB-A244-4383-87F7-A8391787A4CD',
      brand: '833C0D79-608F-4A3F-A8A3-19D7627AAEAC',
      model: '14D796BD-0B44-40E7-84A8-8D3EFC1720AB',
      gender: 'F21A1388-6594-4E21-8D0F-812F26F7F2DD'
    },
    {
      // Sweatshirts junior
      color: '002EC798-C88F-4D93-9D56-708805D83C68',
      size: '94A583BE-D616-4967-81ED-9D1D67AC9068',
      medium: 'ABDF321E-5266-43A1-AE9B-DBC25BB6BC3B',
      large: '0D578B3E-1433-4B28-AB18-896ACA795AB0',
      printPlacement: '92AF1B5F-BBD6-4A3C-9CAD-397E38DACC46',
      brand: '0F713EDC-9E0E-4979-9BE3-AA5AC2E27441',
      model: '56FD288F-1F02-41F5-BB14-E9E9E854A054',
      gender: '1C89B3E4-009C-4EF3-A08B-BBDE2DC08013'
    },
    {
      // Tank tops
      color: '5061DC63-BCE7-4AEC-BD35-0C6ED269D1BA',
      size: 'EE986371-7A8A-4E82-B010-F0D6C5AB7AC4',
      medium: '149517A4-69D6-43E2-BCBF-E94713AB67FD',
      large: '54DAC229-4CFE-4818-8472-9B051047C8C1',
      printPlacement: 'CF5BB0DA-9E88-4C64-BE16-E58B33A63B95',
      brand: '60593DF6-551B-4CB1-BE90-08E70CEC28D5',
      model: 'D75C7565-686A-49E6-B19D-ED6921924BCE',
      gender: 'BCB7D3D3-8E22-4347-9967-E6E1DC9482F9'
    },
    {
      // Toddler
      color: '93C26943-F846-4E08-B14F-CB6796007407',
      size: '6FFC9186-55F7-4299-BFA0-3460DF55D2F8',
      medium: 'B5F57207-E358-4F56-8AE7-61AE88B69004',
      large: '9FCD69B5-F772-4053-9453-144F8842BADF',
      printPlacement: '4E6F9003-A4BD-4828-B5E1-0F12FF420CDB',
      brand: 'DEC5AAA7-EAB3-48CB-9501-A4A743151A84',
      model: '1C2F250A-A688-43FF-B343-A5B5B5098A88',
      gender: 'C127D946-6958-41CD-8B6D-70F2DACBA73A'
    },
    {
      // Long Sleeves
      color: 'F3976B6D-CACB-4B04-BC6A-2AEB736EDD95',
      size: '253FF284-18E4-4D71-A703-2E19249836EF',
      medium: '5DE432C2-5D26-4825-9D83-5017C48851DE',
      large: 'B1C62C46-9BB6-463C-BC6B-851FA302EB77',
      printPlacement: 'CF48ECD9-5F32-4CC6-ABD3-20BDFB0D8BE2',
      brand: '3C72C7B2-E7EE-4218-8C1A-4FA69CE0A89F',
      model: '0D37B055-0C4B-4FF4-9E49-6789B8BC8328',
      gender: '094007DD-E976-43EF-BE2C-0831059B0269'
    },
    {
      // Onesies
      color: 'E5CD4B04-C858-44FE-8F8A-060D434CD30A',
      size: '82DB944D-E33E-435A-91A6-445E78DDA76D',
      medium: '0EFF111D-D2AF-4E64-9FEE-FEC7B71C679B',
      large: '9E3EB743-462D-40B5-90DE-E087144B467C',
      printPlacement: '4564700A-0769-4493-BE3E-74D49CE9DAA2',
      brand: 'C7B24E15-626A-4EE8-9D04-F0931EC3EC25',
      model: '776BDC75-6A7B-402C-99D3-EE6C1476A076',
      gender: 'E86F3BDC-BA74-4E89-A99C-27FCC16EB65B'
    },
    {
      // Tie Dye T-Shirts
      color: '3FCD4A92-AD04-4349-B444-1877255F23AC',
      size: '75DA051A-8CD3-4A41-85A8-29B2FE18CA3A',
      medium: '55C04EA7-ED57-4516-85E6-D70BCDB88E95',
      large: 'FE31F057-3087-47A8-8631-06266CB35968',
      printPlacement: '70055992-A8DF-41A3-81BE-4A363E177F2D',
      brand: '8D2AFC26-CC53-4A65-8BE1-DC8CDD88D048',
      model: '27A7BF6B-8064-43D1-AA38-1314C39A83FE',
      gender: '4F08B233-CF50-406D-9E19-A11989190E88'
    }
  ];

  for (let key of Object.keys(variant)) {
    for (let option of options) {
      if (key === option.color) {
        return option;
      }
    }
  }

  return null;
};

export const dedupePreviewSKUs = (
  skus,
  variants,
  aggregatedSKUsMap,
  skuToKeyMap,
  associatedSkus
) => {
  // Deduplicate skus and select M or L size for each sku.
  // Differences in skus like placement should be respected.
  let aggregatedSKUs = [];
  let optionIdsMap = null;

  if (variants) {
    optionIdsMap = findOptionIdsMap(
      variants.find(variant => variant.sku.toLowerCase() === skus[0].sku.toLowerCase())
    );
  }

  /*
   * We aggregating previews only for apparel, other products like mugs for example
   * need to be processed as usual - each sku has own preview.
   */
  if (!optionIdsMap) {
    aggregatedSKUs = skus;
  } else {
    // create uniq key based on image manip command
    const keyFromImgManip = il => {
      if (
        il &&
        il.layers &&
        il.layers.length &&
        il.layers[0].images &&
        il.layers[0].images.length
      ) {
        const imageLayer = il.layers[0].images[0];
        const keyObj = {
          top: imageLayer.top.toFixed(1),
          left: imageLayer.left.toFixed(1),
          rotation: imageLayer.rotation.toFixed(1),
          imageUrl: imageLayer.url
        };
        return JSON.stringify(keyObj);
      }
      return '';
    };

    for (let s of skus) {
      for (let space of s.spaces) {
        if (space.previewImgManipCmd) {
          const variant = variants.find(
            variant => variant.sku.toLowerCase() === s.sku.toLowerCase()
          );
          if (!variant) {
            console.log('variant not found, sku: ' + s.sku);
          }
          const currentSize = variant[optionIdsMap.size];
          optionIdsMap = findOptionIdsMap(variant);
          const key =
            generateUniquePreviewKeyFromVariant(variant, optionIdsMap) +
            space.name +
            keyFromImgManip(space.il);

          skuToKeyMap[s.sku + s.index + space.name] = key;
          if (associatedSkus) {
            associatedSkus.push({ sku: s.sku, index: s.index, key });
          }
          /*
           * We always take the first size we come accross in order to cover
           * our basis. Then as we see other sizes come in, then if we can,
           * we will refine until the ultimate goal of having Medium in
           * the best case, or Large if eiter one exists ...
           */
          if (aggregatedSKUsMap.hasOwnProperty(key)) {
            if (
              currentSize === optionIdsMap.medium ||
              (currentSize === optionIdsMap.large &&
                aggregatedSKUsMap[key].size !== optionIdsMap.medium)
            ) {
              aggregatedSKUsMap[key] = { sku: s.sku, size: currentSize };
            }
          } else {
            /*
             * We take whatever size comes in first as it maybe the only one
             * we ever see for this color. User can delete or otherwise manipulate
             * SKUs ...
             */
            aggregatedSKUsMap[key] = { sku: s.sku, size: currentSize };
          }
        }
      }
    }

    /*
     * Let's reorder skus so that we generate preview images for desired skus
     * first and then reuse created preview images for the remainding skus
     */
    for (let i = 0; i < skus.length; i++) {
      const match = Object.values(aggregatedSKUsMap).find(
        item => item.sku.toLowerCase() === skus[i].sku.toLowerCase()
      );
      if (match) {
        aggregatedSKUs.push(skus[i]);
      }
    }
  }

  return aggregatedSKUs;
};

// Generate img manip commands objects for print and preview
export async function prepareManipCommands(skus, previewSize, changeOrientation) {
  let manipCommandsToGenerate = [];
  for (const sku of skus) {
    let skuToGenerate = {
      index: sku.index,
      sku: sku.sku,
      dataIndex: sku.dataIndex,
      spaces: [],
      proPreview: sku.proPreview,
      proPreviewStyle: 'Studio-Minimal',
      externalMockup: sku.externalMockup
    };

    // eslint-disable-next-line
    for (const [sIndex, s] of sku.spaces.entries()) {
      const space = sku.spaces[sIndex];
      const template = space.template;

      // if any sku is missing IL, create it with defaults
      if (!space.il && space.images.length) {
        const il = await imageEditorService.initImageState(
          space.template,
          space.images[0],
          changeOrientation
        );
        space.il = il.print;
      }

      // NOTE: prepareIlForManipCommand replace resizer urls to full size image urls
      const il = prepareIlForManipCommand(space.il);
      // TODO: refactor this to have it inside space
      const cmds = ImagePreviewControl.exportImgManipCmd(
        { template, il },
        sku.backgroundColor,
        previewSize,
        changeOrientation
      );

      // NOTE: We use small print img manip to generate small print ready image
      // to be used by pro preview API.
      // it is just small version of print image - specifically generated for pro previews
      const smallPrintImgManipCmd = ImagePreviewControl.exportSmallPrintImgManipCmd(
        { template, il },
        previewSize,
        changeOrientation
      );

      const isPreviewImageManipChanged =
        !space.previewImgManipCmd ||
        space.previewImgManipCmd !== JSON.stringify(cmds.previewImgManip);

      const isExclusiveMockupDisabled = !sku.proPreviewStyle;

      // NOTE: If only background or preview size changed
      // then print manip command should not be resubmited!!!
      const isPrintImageManipChanged =
        !space.printImgManipCmd || space.printImgManipCmd !== JSON.stringify(cmds.printImgManip);

      if ((isPreviewImageManipChanged && isExclusiveMockupDisabled) || isPrintImageManipChanged) {
        skuToGenerate.spaces.push({
          id: space.id,
          il: space.il, // used later to compare previews to avoid duplicated
          name: space.name,
          previewImgManipCmd:
            isPreviewImageManipChanged && isExclusiveMockupDisabled ? cmds.previewImgManip : null,
          printImgManipCmd: isPrintImageManipChanged ? cmds.printImgManip : null,
          smallPrintImgManipCmd: smallPrintImgManipCmd || null,
          // if the size of image is up to spec and it was positioned in editor
          // viewport without adjustments, we should use source image,
          // instead of generating print image...
          printImgUrl: canSubmitSourceImage(space)
        });
      }
    }

    if (skuToGenerate.spaces.length) {
      manipCommandsToGenerate.push(skuToGenerate);
    }
  }

  return manipCommandsToGenerate;
}

class ProductPreviewService {
  *downloadPreviews(selectedItems, fileName, onFinishPart, onFinishAsyncFn) {
    const itemsToDownload = selectedItems.map(item => ({
      url: item.previewImageUrl,
      sku: item.sku + (item.view ? `-${item.view}` : `-${item.index}`)
    }));
    var position = 0;
    var part = 1;
    const numberOfImagesInPart = 20;

    const getFileName = (fileName, part) =>
      itemsToDownload.length <= numberOfImagesInPart
        ? `${fileName}.zip`
        : `${fileName}_${part}.zip`;

    do {
      yield onFinishPart(part, Math.ceil(itemsToDownload.length / numberOfImagesInPart));
      yield downloadImages(
        itemsToDownload.slice(position, position + numberOfImagesInPart),
        getFileName(fileName, part)
      );
      position += numberOfImagesInPart;
      part++;
    } while (position < itemsToDownload.length);

    yield onFinishAsyncFn();
  }

  mergeConfigs(defaultConfig, customConfig) {
    return defaultConfig.mergeDeep(customConfig).toJS();
  }

  reIndexItems(items) {
    return items.map((item, index) => ({ ...item, index }));
  }

  updateBackgrounds(skus, color) {
    return skus.map(item => ({ ...item, backgroundColor: color }));
  }

  preparePreviewsManipCommands(previewItems, previewSize, changeOrientation) {
    let manipCommandsToGenerate = [];

    previewItems.forEach(item => {
      const il = prepareIlForManipCommand(item.space.il);
      const cmds = ImagePreviewControl.exportImgManipCmd(
        { template: item.space.template, il },
        item.backgroundColor,
        previewSize,
        changeOrientation
      );

      const isPreviewImageManipChanged =
        !item.space.previewImgManipCmd ||
        item.space.previewImgManipCmd !== JSON.stringify(cmds.previewImgManip);

      if (isPreviewImageManipChanged) {
        item.space.previewImgManipCmd = cmds.previewImgManip;
        manipCommandsToGenerate.push(item);
      }
    });
    return manipCommandsToGenerate;
  }

  // Submit image manip commands to ImageManip Async Service to generate Images
  submitPrintImageManipCmdsAsync(skus, changeOrientation) {
    const tasks = [];
    skus.forEach((sku, pIndex) => {
      sku.spaces.forEach((s, sIndex) => {
        if (s.printImgManipCmd && !s.printImgUrl) {
          const imgManipReq = getImgManipReq('print', s.printImgManipCmd);
          if (changeOrientation) {
            imgManipReq.commands[0].args.layers[0].layers[0].commands.push({
              name: 'rotate',
              args: {
                degrees: -180
              }
            });
            imgManipReq.commands[0].args.commands.push({
              name: 'rotate',
              args: {
                degrees: -90
              }
            });
          }
          tasks.push(
            postJson(getImageProcessUrl(), imgManipReq, getAuthQueryParams()).then(res => {
              if (res.url) {
                s.printImgUrl = res.url;
              }
            })
          );
        }
      });
    });

    return Promise.all(tasks);
  }

  // generator
  *submitPreviewImageManipCmdsSync(skus, onProgressAsyncFn, variants) {
    const tasks = [];
    // These are going to be populated in
    let aggregatedSKUsMap = {};
    let skuToKeyMap = {};
    const aggregatedSKUs = dedupePreviewSKUs(skus, variants, aggregatedSKUsMap, skuToKeyMap);
    for (let sku of aggregatedSKUs) {
      // get preview for each space
      for (let s of sku.spaces) {
        if (s.previewImgManipCmd) {
          const imgManipReq = getImgManipReq('preview', s.previewImgManipCmd);
          const asyncFn = () =>
            postJson(getImageProcessUrl(), imgManipReq, getAuthQueryParams()).then(res => {
              if (res.url) {
                const key = sku.sku + sku.index + s.name;
                if (
                  Object.keys(aggregatedSKUsMap).length !== 0 &&
                  aggregatedSKUsMap[skuToKeyMap[key]] &&
                  aggregatedSKUsMap[skuToKeyMap[key]].sku.toLowerCase() === sku.sku.toLowerCase()
                ) {
                  aggregatedSKUsMap[skuToKeyMap[key]].previewImgUrl = res.url;
                }
                s.previewImgUrl = res.url;
              } else {
                throw new Error('Preview was not generated');
              }
            });

          const params = {
            previewImgManipCmd: s.previewImgManipCmd
          };

          const maxRetryCount = 3;

          const onMaxRetryReachedFn = params => {
            logService.report('Preview generation task failed, max re-try reached', { params });
            throw new Error('Preview generation task failed, max re-try reached');
          };

          tasks.push(mapRetryableTask(asyncFn, params, maxRetryCount, onMaxRetryReachedFn));
        }
      }
    }

    yield runRetryableTasks(tasks, onProgressAsyncFn);

    /*
     * If the two vars point to different objects we know we created a subset
     * of preview images. Now we need to populate every sku from the original
     * structure with a correct preview image.
     */
    if (aggregatedSKUs !== skus) {
      for (let sku of skus) {
        for (let s of sku.spaces) {
          if (s.previewImgManipCmd) {
            const key = sku.sku + sku.index + s.name;
            if (
              skuToKeyMap.hasOwnProperty(key) &&
              aggregatedSKUsMap.hasOwnProperty(skuToKeyMap[key]) &&
              aggregatedSKUsMap[skuToKeyMap[key]].hasOwnProperty('previewImgUrl')
            ) {
              // each space should have preview...
              s.previewImgUrl = aggregatedSKUsMap[skuToKeyMap[key]].previewImgUrl;
            }
          }
        }
      }
    }
  }

  *generateExclusiveMockupsUrls(skus, onProgressAsyncFn, commands, productId) {
    // Each sku can have Pro Preview with multiple scenes OR/AND External mockups attached
    // We re-use 1 small print img for all pro and external mockups of single sku
    //
    // 1. Generate small print img urls - which then will be used for external mockups and pro previews
    // 2. Use small print img urls and generate pro previews for all scenes of each sku
    let smallPrintImgReqs = [];
    let exclusiveMockupsReqs = [];
    const exclusiveMockupsLength = skus.reduce(
      (acc, sku) => acc + sku.externalMockup?.mockups?.length + sku.proPreview?.scenes.length,
      0
    );

    const maxRetryCount = 3;

    const onExclusiveMockupApiError = params => {
      logService.report('Exclusive mockups generation failed', { params });
      throw new Error('Exclusive mockups generation failed');
    };

    const onSmallPrintImgError = params => {
      logService.report('Small print image generation task failed, max re-try reached', { params });
      throw new Error('Small print image generation task failed, max re-try reached');
    };

    // creating small print images will take some time
    // so we have to trigger progress bar update...
    yield onProgressAsyncFn(0, exclusiveMockupsLength);

    //
    // Generate small print images for each space...
    //
    skus.forEach(sku => {
      sku.spaces.forEach(s => {
        if (s.smallPrintImgManipCmd) {
          // create print-small image here
          let imgManipReq = getImgManipReq('print-small', s.smallPrintImgManipCmd);
          const asyncFn = () =>
            postJson(getImageProcessUrl(), imgManipReq, getAuthQueryParams()).then(res => {
              if (res.url) {
                s.smallPrintImgUrl = res.url;
              } else {
                throw new Error('Small print image was not generated');
              }
            });

          const params = {
            smallPrintImgManipCmd: s.smallPrintImgManipCmd
          };

          smallPrintImgReqs.push(
            mapRetryableTask(asyncFn, params, maxRetryCount, onSmallPrintImgError)
          );

          // If space print image was changed then we must
          // clear all pro previews scenes related to that space and
          // all external mockups related to that space
          if (sku.proPreview) sku.proPreview.imageUrls = [];
          if (sku.externalMockup) sku.externalMockup.imageUrls = [];
        }
      });
    });
    yield runRetryableTasks(smallPrintImgReqs, () => {});

    //
    // Generate exclusive mockups for each sku...
    //
    skus.forEach(sku => {
      //
      // Create previews for every external mockup...
      //
      if (sku.externalMockup?.mockups?.length > 0) {
        sku.externalMockup.mockups.forEach((mockup, mockupIdx) => {
          // Reuse image if nothing changed
          if (sku.externalMockup.imageUrls[mockupIdx]) return;
          const rotation = commands?.changeOrientation ? 90 : null;

          let asyncFn = () =>
            new Promise((resolve, reject) => {
              getSmallPrintImages(sku, rotation)
                .then(urls => {
                  externalMockupsService.generate(mockup, sku, productId, urls).then(res => {
                    if (res !== undefined && !res.error) {
                      sku.externalMockup.imageUrls[mockupIdx] = res[0].url;
                    } else {
                      // DO NOT throw an error if external mockup generation fails...
                      // We might reach out API limit, so we will just display other mockups instead...
                      logService.error('External mockup generation failed', {
                        type: 'External Mockup',
                        externalMockupId: mockup.ID,
                        imgUrl: sku.spaces[0].smallPrintImgUrl
                      });
                    }
                    resolve();
                  });
                })
                .catch(() => {
                  reject();
                  onExclusiveMockupApiError();
                });
            });
          exclusiveMockupsReqs.push(mapRetryableTask(asyncFn, {}, 3, onExclusiveMockupApiError));
        });
      }

      //
      // Create pro previews for each scene...
      //
      if (sku.proPreview) {
        const scenes = sku.proPreview.scenes.filter(s => s.style === sku.proPreviewStyle);
        scenes.forEach((scene, sceneIdx) => {
          // Reuse image if nothing changed
          if (sku.proPreview.imageUrls[sceneIdx]) return;

          const asyncFn = () =>
            proPreviewService
              .generateProPreview(scene.name, sku, scene.areas, commands)
              .then(res => {
                if (!res.error) {
                  sku.proPreview.imageUrls[sceneIdx] = res.url;
                } else {
                  onExclusiveMockupApiError({
                    type: 'Pro Preview',
                    templateName: scene.name,
                    imgUrl: sku.spaces[0].smallPrintImgUrl
                  });
                }
              })
              .catch(onExclusiveMockupApiError);
          exclusiveMockupsReqs.push(mapRetryableTask(asyncFn, {}, 3, onExclusiveMockupApiError));
        });
      }
    });

    yield runRetryableTasks(exclusiveMockupsReqs, onProgressAsyncFn);

    return skus;
  }

  *generatePreviewUrls(previewItems, onProgressAsyncFn) {
    const tasks = [];
    for (let previewItem of previewItems) {
      const s = previewItem.space;

      if (s.previewImgManipCmd) {
        const imgManipReq = getImgManipReq('preview', s.previewImgManipCmd);
        const asyncFn = () =>
          postJson(getImageProcessUrl(), imgManipReq, getAuthQueryParams()).then(res => {
            if (res.url) {
              s.previewImgUrl = res.url;
            } else {
              throw new Error('Preview was not generated');
            }
          });

        const params = {
          previewImgManipCmd: s.previewImgManipCmd
        };

        const maxRetryCount = 3;

        const onMaxRetryReachedFn = params => {
          logService.report('Preview generation task failed, max re-try reached', { params });
          throw new Error('Preview generation task failed, max re-try reached');
        };

        tasks.push(mapRetryableTask(asyncFn, params, maxRetryCount, onMaxRetryReachedFn));
      }
    }
    yield runRetryableTasks(tasks, onProgressAsyncFn);
  }
}

// singleton
export default new ProductPreviewService();
