import { delay } from 'redux-saga';
import { takeLatest, takeEvery, call, put, select, take, all } from 'redux-saga/effects';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail
} from 'gooten-components/src/store/actions/dataActions';
import { INIT, LOAD_STORE_PRODUCTS, CHECK_STORE_INDEXING } from './ProductSelectionViewActions';
import { getProductSelectionQuery, providerPathSelector } from './ProductSelectionViewSelectors';
import linkProductService from '../LinkProductService';
import { flowVerifiedSelector, storeIdSelector } from '../LinkProductSelectors';
import Log from 'gooten-components/src/services/logService';
import {
  CHANGE_SEARCH,
  CHANGE_VIEWBY
} from './components/ProductsFiltering/ProductsFilteringActions';
import { PAGE_SELECT } from './components/ProductList/ProductListActions';
import { STORES_FETCH } from '../../HubView/components/Stores/StoresActions';
import { storesLoadedSelector } from '../../HubView/components/Stores/StoresSelectors';

export function* checkStoreIndexingAsyncHandler(action) {
  const providerPath = yield select(providerPathSelector);
  if (action.payload.providerPath && action.payload.providerPath !== providerPath) {
    // route was changed - check not actual
    return;
  }
  let productsIndexing = {};
  try {
    const storeId = yield select(storeIdSelector);
    productsIndexing = yield call(linkProductService.checkStoreIndexing, storeId);
    yield put(fetchAsyncSuccess(CHECK_STORE_INDEXING, productsIndexing));
  } catch (err) {
    throw Log.withFriendlyMsg('Failed to fetch store indexing data', err, { action });
  } finally {
    if (typeof productsIndexing.indexingProducts === 'number') {
      // do next check with delay
      yield call(delay, 1000);
      yield put(fetchAsync(CHECK_STORE_INDEXING, { providerPath }));
    }
  }
}

export function* loadStoreProductsAsyncHandler(action) {
  let productDetails = null;
  try {
    const productSelectionQuery = yield select(getProductSelectionQuery);
    const productsData = yield call(linkProductService.getStoreProducts, productSelectionQuery);
    yield put(fetchAsyncSuccess(LOAD_STORE_PRODUCTS, productsData));
  } catch (err) {
    yield put(fetchAsyncFail(LOAD_STORE_PRODUCTS, err));
    throw Log.withFriendlyMsg('Failed to fetch store products', err, { action, productDetails });
  }
}

export function* initAsyncHandler(action) {
  // to support dirrect Link Flow call
  // initiate stores loading if it not done before
  const storesLoaded = yield select(storesLoadedSelector);
  if (!storesLoaded) {
    yield put(fetchAsync(STORES_FETCH));
    yield take(STORES_FETCH.SUCCESS);
  }

  const flowVerified = yield select(flowVerifiedSelector);
  // to support forward/back browser behavior
  // we are verifing link flow setting
  // for now it's just store name
  // in future other povider parameters can be used here
  if (!flowVerified) {
    // flow is broken - redirect to listing page of store products from current URL
    window.location.hash = window.location.hash.split('/link-product')[0];
    return;
  }

  const storedProviderPath = yield select(providerPathSelector);

  if (action.payload.providerPath !== storedProviderPath) {
    yield put(fetchAsyncSuccess(INIT, action.payload));
    yield put(fetchAsync(LOAD_STORE_PRODUCTS));
    yield put(fetchAsync(CHECK_STORE_INDEXING, {}));
  }
}

export function* watchInitAsync() {
  yield takeEvery(INIT.ASYNC, initAsyncHandler);
}

export function* watchCheckStoreIndexingAsync() {
  yield takeLatest(CHECK_STORE_INDEXING.ASYNC, checkStoreIndexingAsyncHandler);
}

export function* watchLoadStoreProductsAsync() {
  yield takeLatest(LOAD_STORE_PRODUCTS.ASYNC, loadStoreProductsAsyncHandler);
}

export function* productsQueryParamsChangeHandler() {
  yield put(fetchAsync(LOAD_STORE_PRODUCTS));
}

export function* watchProductsQueryParamsChange() {
  // watches when some of products query related params are changed
  yield all([
    takeLatest(CHANGE_VIEWBY, productsQueryParamsChangeHandler),
    takeLatest(CHANGE_SEARCH, productsQueryParamsChangeHandler),
    takeLatest(PAGE_SELECT, productsQueryParamsChangeHandler)
  ]);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    watchInitAsync(),
    watchCheckStoreIndexingAsync(),
    watchLoadStoreProductsAsync(),
    watchProductsQueryParamsChange()
  ]);
}
