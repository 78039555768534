import { fromJS } from 'immutable';
import { RESET } from '../../../store/actions/globalActions';
import {
  INIT,
  SELECT_PRODUCT,
  LOAD_SKUS,
  SKU_SELECT,
  SKIP_MAPPING,
  SELECT_REGION_INDEX,
  SELECT_SUB_CATEGORY_INDEX,
  CAN_GO_NEXT,
  SET_READY,
  SET_SELECTION,
  SET_REGIONS_TO_ACTIVE,
  SET_SUB_CATEGORIOS_TO_ACTIVE,
  DESELECT_LAST_SKU,
  RESET_NOTIFICATION
} from './SKUsSelectionViewActions';
import { SET_FLOW_SELECTED_INDEX } from './components/ProductSelection/ProductSelectionActions';
import { SET_VARIANT_INDEX } from './components/VariantsSelection/VariantsSelectionActions';
import {
  SET_PAGE,
  SET_OPTION_SELECTED_VALUES
} from './components/SKUsList/NewProductSKUsList/SKUsListActions';
import {
  hubProductSelectionReducer,
  resetHubProductSelectionState
} from './components/ProductSelection/components/HubProductSelection/HubProductSelectionReducer';
import initialState from '../../../store/initialState';
import linkProductService from '../LinkProductService';

const defaultState = initialState.getIn(['hub', 'linkProduct', 'SKUsSelectionView']);

const resetState = (state, payload) => {
  return resetHubProductSelectionState(
    state
      .set('selectedProductId', payload)
      .set('regions', defaultState.get('regions'))
      .set('selectedRegionIndex', defaultState.get('selectedRegionIndex'))
      .set('selectedSubCategoryIndex', defaultState.get('selectedSubCategoryIndex'))
      // Product unselect not affecting ready state
      .set('ready', !payload)
      .set('canGoNext', false)
  );
};

const SKUsSelectionViewReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global' || action.payload.scope === 'link') {
        return defaultState;
      } else {
        return state;
      }
    }

    case INIT.SUCCESS: {
      return state
        .set('variantsMappings', fromJS(action.payload.variantsMappings))
        .set('hash', action.payload.hash)
        .set('selectedProductId', defaultState.get('selectedProductId'))
        .set('regions', defaultState.get('regions'))
        .set('canGoNext', false)
        .set('canUpdateSelection', false)
        .set('ready', true);
    }

    case SET_READY: {
      // disable update mode on back from design page
      return state.set('canUpdateSelection', false).set('ready', action.payload);
    }

    case SELECT_PRODUCT: {
      return !action.payload ||
        !state.get('selectedProductId') ||
        action.payload !== state.get('selectedProductId')
        ? resetState(state, action.payload)
        : state;
    }

    case LOAD_SKUS.SUCCESS: {
      return state
        .set('page', defaultState.get('page'))
        .set('regions', action.payload.regions)
        .set(
          'options',
          linkProductService.mapRegionOptions(
            action.payload.regions,
            action.payload.selectedRegionIndex,
            defaultState.get('selectedSubCategoryIndex')
          )
        )
        .set('selectedRegionIndex', action.payload.selectedRegionIndex)
        .set('selectedSubCategoryIndex', defaultState.get('selectedSubCategoryIndex'))
        .set('regionsActive', action.payload.regions.size > 1)
        .set(
          'subCategoriesActive',
          action.payload.regions.getIn([action.payload.selectedRegionIndex, 'sub-categories'])
            .size > 1
        )
        .set('ready', true);
    }

    case SELECT_REGION_INDEX: {
      return state
        .set('page', defaultState.get('page'))
        .set(
          'options',
          linkProductService.mapRegionOptions(
            state.get('regions'),
            action.payload,
            defaultState.get('selectedSubCategoryIndex')
          )
        )
        .set('selectedRegionIndex', action.payload)
        .set('regionsActive', false)
        .set(
          'subCategoriesActive',
          state.getIn(['regions', action.payload, 'sub-categories']).size > 1
        )
        .set('selectedSubCategoryIndex', defaultState.get('selectedSubCategoryIndex'));
    }

    case SELECT_SUB_CATEGORY_INDEX: {
      return state
        .set('page', defaultState.get('page'))
        .set(
          'options',
          linkProductService.mapRegionOptions(
            state.get('regions'),
            state.get('selectedRegionIndex'),
            action.payload
          )
        )
        .set('subCategoriesActive', false)
        .set('selectedSubCategoryIndex', action.payload);
    }

    case SKU_SELECT: {
      const index = state.get('currentVariantIndex');
      return state
        .update('variantsMappings', vm =>
          vm.update(index, m => m.set('uniqueProduct', action.payload.sku))
        )
        .set('skuSelectedNotification', fromJS({ show: true, value: action.payload.value }));
    }

    case DESELECT_LAST_SKU: {
      const lastIndex = state.get('variantsMappings').count() - 1;
      return state
        .setIn(['variantsMappings', lastIndex, 'uniqueProduct'], null)
        .set('canGoNext', false)
        .set('currentVariantIndex', state.get('variantsMappings').size - 1);
    }

    case SKIP_MAPPING: {
      const index = state.get('currentVariantIndex');
      return !state.getIn(['variantsMappings', index, 'uniqueProduct'])
        ? state.update('variantsMappings', vm => vm.update(index, m => m.set('skiped', true)))
        : state;
    }

    case SET_REGIONS_TO_ACTIVE: {
      return state.set('regionsActive', true);
    }

    case SET_SUB_CATEGORIOS_TO_ACTIVE: {
      return state.set('subCategoriesActive', true);
    }

    case CAN_GO_NEXT: {
      return state.set('canGoNext', action.payload);
    }

    case SET_VARIANT_INDEX: {
      return state.set('currentVariantIndex', action.payload);
    }

    case SET_PAGE: {
      return state.set('page', action.payload);
    }

    case SET_SELECTION: {
      return state.set('canUpdateSelection', action.payload);
    }

    case SET_OPTION_SELECTED_VALUES: {
      return state.set('page', defaultState.get('page')).update('options', options =>
        options.update(
          options.findIndex(o => o.get('id') === action.payload.optionId),
          o =>
            o.set(
              'selectedValues',
              o
                .get('selectedValues')
                .filterNot(sv => sv.get('isSuggested') === action.payload.isSuggested)
                .concat(fromJS(action.payload.values))
            )
        )
      );
    }

    case RESET_NOTIFICATION: {
      return state.setIn(['skuSelectedNotification', 'show'], false);
    }

    case SET_FLOW_SELECTED_INDEX: {
      return action.payload === -1
        ? resetState(state, null).set('flowSelectedIndex', -1)
        : state.set('flowSelectedIndex', action.payload);
    }
  }

  // handle Hub Product Selection...
  // moved from this reducer, to keep all in one place
  return hubProductSelectionReducer(state, action);
};

export default SKUsSelectionViewReducer;
