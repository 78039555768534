import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { watchInitAsync } from '../BaseCard/BaseCardSaga';
import { updateActionNameFor } from '../BaseCard/BaseCardActions';
import { cardsSelector } from '../BaseCard/BaseCardSelectors';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import EmailServices from './EmailServices';

const update = updateActionNameFor(EmailServices.name);

export function* UpdateCardAsyncHandler(action) {
  try {
    const cards = yield select(cardsSelector);
    const data = cards.getIn(['emailServices', 'data']);
    const request = {
      ...data.toJS(),
      ...cards.getIn(['emailCredentials', 'unmodified']).toJS(),
      // MailMethod reqiured to send emails
      MailMethod: cards.getIn(['emailCredentials', 'unmodified', 'MailMethod']) || 1
    };
    if (request.SendConfirmation && !request.OrdersEmail) {
      request.OrdersEmail = data.get('PartnerContactEmail');
    }
    if (request.SendShipping && !request.ShippingEmail) {
      request.ShippingEmail = data.get('PartnerContactEmail');
    }
    yield call(settingsApiService.updateNotificationSettings, request, 'emailCustomers');
    yield put(fetchAsyncSuccess(update, { data }));
    yield put(push('Email services updated', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(update, err));
    yield put(push('Failed to update Email services', 'failure'));
  }
}

export function* watchUpdateCard() {
  yield takeLatest(update.ASYNC, UpdateCardAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchInitAsync(EmailServices.name, EmailServices.messages), watchUpdateCard()]);
}
