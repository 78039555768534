import { connect } from 'react-redux';

import {
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH,
  fetchAsync,
  skuSelect
} from '../../store/actions/dataActions';
import { next, back } from '../../containers/NavManager/NavManagerActions';
import withRouteStep from '../../hocs/withRouteStep';
import { closeSideBar } from '../ProductSelection/atoms/ProductDetails/ProductDetailsActions';

import {
  hasProductDetails,
  hasProductVariants,
  selectedProduct,
  isPanelCollapsed,
  getFilteredSkus,
  isOptionsApplied,
  getMaxGroupId,
  isGroupCancellable,
  isGroupCompleted,
  skuOverflow,
  isRegionsApplied,
  getOptionsGroup,
  modalValidationMessageSelector,
  connectedStoresSelector,
  isApparelSelector
} from './SKUSelectionSelectors';
import {
  gotoNextGroup,
  gotoPrevGroup,
  gotoGroup,
  cleanSelectedOptions,
  togglePanel,
  deselectBatchOptions,
  cleanSelectedRegions,
  setCurrentImage,
  cancelSkuSelection
} from './SKUSelectionActions';

import { sidebarSelector } from '../ProductSelection/ProductSelectionSelectors';

import SKUSelection from './SKUSelection';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapDispatchToProps = {
  loadProductVariants: productName => fetchAsync(PRODUCT_VARIANTS_FETCH, productName),
  loadProductDetails: productName => fetchAsync(PRODUCT_DETAILS_FETCH, productName),
  createProduct: skus => skuSelect(skus),
  gotoNextStep: () => next(),
  gotoNextGroup: () => gotoNextGroup(),
  gotoPrevGroup: () => gotoPrevGroup(),
  gotoGroup: groupId => gotoGroup(groupId),
  goBack: () => back(),
  cleanSelectedOptions: () => cleanSelectedOptions(),
  cleanSelectedRegions: () => cleanSelectedRegions(),
  togglePanel: () => togglePanel(),
  deselectBatchOptions: optionIds => deselectBatchOptions(optionIds),
  selectBatchOptions: options => deselectBatchOptions(options),
  setCurrentImage: index => setCurrentImage(index),
  closeSideBar: () => closeSideBar(),
  cancelSkuSelection: () => cancelSkuSelection()
};

const mapStateToProps = state => {
  let selectedProductName = selectedProduct(state).name;
  let isApparel = isApparelSelector(state);
  let productDetails = hasProductDetails(state);
  let productVariants = hasProductVariants(state);
  if (!productDetails || !productVariants) {
    return {
      selectedProductName: selectedProductName,
      hasProductDetails: productDetails,
      hasProductVariants: productVariants
    };
  } else {
    const overflow = skuOverflow(state);
    const connectedStores = connectedStoresSelector(state);
    const modalValidationMessage =
      connectedStores.size && modalValidationMessageSelector(state).length && overflow > 0
        ? modalValidationMessageSelector(state)
        : '';
    return {
      selectedProductName: selectedProductName,
      hasProductDetails: productDetails,
      hasProductVariants: productVariants,
      collapsed: isPanelCollapsed(state),
      filteredSkus: getFilteredSkus(state),
      maxGroupId: getMaxGroupId(state),
      isGroupCancellable: isGroupCancellable(state),
      isGroupCompleted: isGroupCompleted(state),
      currentGroupId: state.get('SKUSelection').get('groupId'),
      isOptionsApplied: isOptionsApplied(state),
      isRegionsApplied: isRegionsApplied(state),
      optionsGroup: getOptionsGroup(state),
      modalValidationMessage,
      sidebar: sidebarSelector(state),
      isApparel
    };
  }
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(SKUSelection),
  'sku-selection'
);
