import { createSelector } from 'reselect';
import storageService from './../../services/storageService';
import { dataSelector } from '../../store/selectors/productDataSelectors';

export const defaultCountryCodeSelector = () => 'US';

export const shippingCountrySelector = createSelector(dataSelector, dataState =>
  dataState.get('shippingCountry')
);

export const cofShippingCountrySelector = createSelector(
  [dataSelector, defaultCountryCodeSelector],
  (dataState, defaultCC) => {
    let cc;
    try {
      cc =
        JSON.parse(storageService.getItem('pio.shippingCountry') || null) ||
        JSON.parse(storageService.getItem('pio.1.countryCode') || null);
    } catch (err) {}
    return dataState.get('shippingCountry', cc) || cc || defaultCC;
  }
);

export const countriesSelector = createSelector(dataSelector, dataState =>
  dataState.get('countries')
);

export const shippingCountryFullNameSelector = createSelector(
  [cofShippingCountrySelector, countriesSelector],
  (shippingCountry, countries) => {
    if (shippingCountry && countries.size) {
      const selectedCountry = countries.find(x => x.get('code') === shippingCountry);
      if (selectedCountry) {
        // check if country exists
        return selectedCountry.get('name');
      } else {
        // if not exists just return 'Unknown'
        return 'Unknown';
      }
    }
  }
);

export const supportedShippingCountriesOptionsSelector = createSelector(
  [countriesSelector],
  countries =>
    countries
      .filter(country => country.isSupported)
      .map(country => ({
        key: country.name,
        code: country.code,
        label: `${country.code} - ${country.name}`
      }))
      .toJS()
);
