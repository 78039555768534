import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Icon from './Icon.js';
import Button from './Button';

const Alert = ({
  isOpen,
  className,
  type,
  closeClick,
  collapsible,
  buttonText,
  textButtonClick,
  children,
  buttonClassName,
  iconClassName,
  disabled
}) => {
  const [openAlert, setOpenAlert] = useState(true);
  const getAlertStyle = () => {
    switch (type) {
      case 'important':
      case 'failure':
        return {
          icon: 'alertOctagon',
          background: COLORS.cayennePepper200,
          color: COLORS.cayennePepper600
        };
      case 'warning':
        return {
          icon: 'alertTriangle',
          background: COLORS.bananaPeel200,
          color: COLORS.bananaPeel600
        };
      case 'promotion':
        return {
          icon: 'announcementSpeaker',
          background: COLORS.gootenBlue200,
          color: COLORS.gootenBlue500
        };
      case 'good':
      case 'success':
        return { icon: 'checkCircle', background: COLORS.monstera200, color: COLORS.monstera600 };
      default:
        return { icon: 'info', background: COLORS.neutralLight300, color: COLORS.neutralDark500 };
    }
  };

  return (
    <div className={`${className || ''} cerulean-alert`}>
      <div className="d-flex alert-content" onClick={() => !disabled && setOpenAlert(!openAlert)}>
        <Icon icon={iconClassName || getAlertStyle().icon} className="mr-2 alert-icon" />
        <div className="ml-1 alert-body body-text-2">{children}</div>
      </div>
      <div className="button-holder ml-2 d-flex">
        {!collapsible && buttonText && (
          <Button
            onClick={textButtonClick}
            className={`${buttonClassName || 'button-default'} medium mt-n1`}
          >
            {buttonText}
          </Button>
        )}
        {closeClick && (
          <div onClick={closeClick} className="pl-1 pr-1 close-action">
            <Icon icon="x" />
          </div>
        )}
        {collapsible && (
          <div onClick={() => setOpenAlert(!openAlert)}>
            <Icon icon={openAlert ? 'chevronUp' : 'chevronDown'} />
          </div>
        )}
      </div>
      {/* {openAlert && collapsible && buttonText && <div className='alert-on-bottom'>
      <Button onClick={textButtonClick} className='button-default medium'>{buttonText}</Button></div>} */}
      <style jsx>
        {`
          .cerulean-alert :global(br) {
            display: ${openAlert ? 'flex' : 'none'};
          }
          .cerulean-alert {
            position: relative;
            box-sizing: border-box;
            font-family: Avenir Heavy, sans-serif;
            min-height: 3.5rem;
            width: 100%;
            max-width: 50rem;
            padding: 1rem;
            text-align: left;
            line-height: 1.6;
            border-radius: 4px;
            align-items: flex-start;
            background: ${getAlertStyle().background};
            justify-content: space-between;
            transition-duration: 0.3s;
            display: ${isOpen ? 'flex' : 'none'};
            margin: auto;
          }
          .alert-content {
            align-items: flex-start;
            overflow: hidden;
            fill: ${getAlertStyle().color};
          }
          .alert-body {
            white-space: ${openAlert ? 'auto' : 'nowrap'};
            overflow: hidden;
            text-overflow: ellipsis;
            transition-duration: 0.3s;
            color: ${getAlertStyle().color};
          }
          .button-holder {
            height: 25px;
          }
          .w-100 {
            max-width: 100%;
          }
          :global(.alert-icon) {
            min-width: fit-content;
          }
          :global(.cerulean-alert .link) {
            font-weight: 800;
            text-decoration: underline;
            color: inherit !important;
            cursor: pointer;
          }
          .close-action {
            cursor: pointer;
          }
        `}
      </style>
    </div>
  );
};

Alert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.any.isRequired,
  closeClick: PropTypes.func,
  textButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  collapsible: PropTypes.bool,
  buttonClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  disabled: PropTypes.bool
};

Alert.defaultProps = {
  disabled: false
};

export default Alert;
