import { ActionNameFactory } from 'gooten-components/src/utils/actions';

export const getActionName = ActionNameFactory('SETTINGS', 'RETURN_ADDRESS');

export const LOAD_INITIAL_DATA = getActionName('LOAD_INITIAL_DATA');
export const SET_SELECTION = getActionName('SET_SELECTION');
export const UPDATE_SELECTION = getActionName('UPDATE_SELECTION');
export const UPDATE_COUNTRIES = getActionName('UPDATE_COUNTRIES');
export const UPDATE_ADDRESS_TYPES = getActionName('UPDATE_ADDRESS_TYPES');
export const UPDATE_RETURN_ADDRESS = getActionName('UPDATE_RETURN_ADDRESS');
export const UPDATE_PARTNER_DETAILS = getActionName('UPDATE_PARTNER_DETAILS');
export const SAVE_RETURN_ADDRESS = getActionName('SAVE_RETURN_ADDRESS');
export const USE_EXISTING_RETURN_ADDRESS = getActionName('USE_EXISTING_RETURN_ADDRESS');
export const CANCEL_SAVING = getActionName('CANCEL_SAVING');

export function loadInitialData(selection) {
  return {
    type: LOAD_INITIAL_DATA
  };
}

export function setSelection(selection) {
  return {
    type: SET_SELECTION,
    payload: { selection }
  };
}

export function updateSelection(selection) {
  return {
    type: UPDATE_SELECTION,
    payload: { selection }
  };
}

export function updateCountries(data) {
  return {
    type: UPDATE_COUNTRIES,
    payload: { data }
  };
}

export function updatePartnerDetails(data) {
  return {
    type: UPDATE_PARTNER_DETAILS,
    payload: { data }
  };
}

export function updateAddressTypes(data) {
  return {
    type: UPDATE_ADDRESS_TYPES,
    payload: { data }
  };
}

export function cancelSaving() {
  return {
    type: CANCEL_SAVING
  };
}

export function saveReturnAddress(data) {
  return {
    type: SAVE_RETURN_ADDRESS,
    payload: { data }
  };
}

export function updateReturnAddress(data) {
  return {
    type: UPDATE_RETURN_ADDRESS,
    payload: { data }
  };
}

export function useExistingReturnAddress() {
  return {
    type: USE_EXISTING_RETURN_ADDRESS
  };
}
