import { ActionNameFactory } from 'gooten-components/src/utils/actions';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
export const customActionFor = card => ActionNameFactory('SETTINGS', card);
export const toggleActionNameFor = card => customActionFor(card)('TOGGLE');
export const initActionNameFor = card => customActionFor(card).async('INIT');
export const changeActionNameFor = card => customActionFor(card)('CHANGE');
export const cancelActionNameFor = card => customActionFor(card)('CANCEL');
export const updateActionNameFor = card => customActionFor(card).async('UPDATE');
export const changePageActionNameFor = card => customActionFor(card)('CHANGE_PAGE');

export const CLEAR_DATA = ActionNameFactory('SETTINGS')('CLEAR_DATA');

export const COUNTRIES_LOADED = ActionNameFactory('SETTINGS')('COUNTRIES_LOADED');

// ------------------------------------
// Actions
// ------------------------------------

export const toggleFor = card => payload => ({
  type: toggleActionNameFor(card),
  payload
});

export const initFor = card => payload => ({
  type: initActionNameFor(card).ASYNC,
  payload
});

export const changeFor = card => (value, field) => ({
  type: changeActionNameFor(card),
  payload: { field, value }
});

export const cancelFor = card => () => ({
  type: cancelActionNameFor(card)
});

export const updateFor = card => payload => ({
  type: updateActionNameFor(card).ASYNC,
  payload
});

export const changePageFor = card => (page, pageSize) => ({
  type: changePageActionNameFor(card),
  payload: { page, pageSize }
});

export const clearData = () => ({
  type: CLEAR_DATA
});

export const countriesLoaded = data => ({
  type: COUNTRIES_LOADED,
  payload: data
});
