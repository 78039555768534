import React from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss';
import Input from '../Input';
import Icon from '../Icon';

class TextInput extends React.PureComponent {
  nonEmptyRegex = /\S/;

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      isEmpty: false,
      isTooLong: false,
      isInvalid: false,
      errorsVisible: false
    };
  }

  componentDidMount() {
    if (this.props.preValidate) {
      this.validate();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateValue(this.props.value);
    }
  }

  static defaultProps = {
    value: ''
  };

  onChange = event => {
    this.updateValue(event.target.value);
  };

  updateValue = newValue => {
    if (newValue !== this.state.value) {
      this.setState(
        {
          value: newValue
        },
        () => {
          this.updateErrorsStatus();
          if (this.props.onChange) {
            this.props.onChange(newValue, this.props.id);
          }
        }
      );
    }
  };

  hideErrors = () => {
    this.setState(
      {
        errorsVisible: false
      },
      () => {
        if (this.props.onFocus) {
          this.props.onFocus();
        }
      }
    );
  };

  revealErrors = () => {
    this.setState({
      errorsVisible: true
    });
  };

  validate = () => {
    this.updateErrorsStatus();
    this.revealErrors();
  };

  onBlur = () => {
    this.updateValue(this.state.value.trim());
    this.validate();
  };

  updateErrorsStatus = () => {
    let isEmpty = this.checkIfEmpty();
    let isTooLong = this.checkIfTooLong();
    let isInvalid = this.checkIfInvalid();
    this.setState({
      isEmpty,
      isTooLong,
      isInvalid
    });
    if (this.props.onErrorsStatusUpdated) {
      this.props.onErrorsStatusUpdated();
    }
  };

  checkIfEmpty = () => {
    if (this.props.isRequired) {
      return !this.nonEmptyRegex.test(this.state.value);
    }
    return false;
  };

  checkIfTooLong = () => {
    if (this.props.maxLength) {
      return this.state.value.length > this.props.maxLength;
    }
    return false;
  };

  checkIfInvalid = () => {
    if (
      this.props.regexp &&
      !this.props.regexp.test(
        this.props.caseIgnore ? (this.state.value || '').toLowerCase() : this.state.value
      )
    ) {
      return true;
    }
    return false;
  };

  isValid = () => {
    return !(this.checkIfEmpty() || this.checkIfTooLong() || this.checkIfInvalid());
  };

  clear = () => {
    this.setState({
      value: '',
      isEmpty: false,
      isTooLong: false,
      isInvalid: false,
      errorsVisible: false
    });
  };

  render() {
    const errorIsEmpty = this.props.isRequired && this.state.isEmpty ? this.props.emptyMsg : null;

    const maxChars = this.props.maxLength;
    const extraChars = this.state.value.length - this.props.maxLength;

    const errorIsTooLong =
      this.props.maxLength && this.state.isTooLong
        ? `Maximum ${maxChars} characters. (${extraChars} ${
            extraChars === 1 ? 'character' : 'characters'
          } too many)`
        : null;

    const errorIsInvalid =
      this.props.showValidation && this.state.isInvalid ? this.props.validationMsg : null;

    const hasCustomError = this.props.customError ? this.props.customError : null;

    const errors = errorIsEmpty || errorIsTooLong || errorIsInvalid || hasCustomError;

    const charsOverLimitIndicator = <span className="chars-over-limit">{-extraChars}</span>;

    const inputContainerClass = this.props.inputAddon
      ? 'input-container input-group'
      : 'input-container';

    return (
      <div
        className={
          'text-input-container form-group text-left' +
          ((this.state.errorsVisible &&
            ((this.props.isRequired && this.state.isEmpty) ||
              (this.props.showValidation && this.state.isInvalid))) ||
          // eslint-disable-next-live no-mixed-operators
          (this.props.maxLength && this.state.isTooLong) ||
          hasCustomError
            ? ' has-error '
            : ' ')
        }
      >
        <div className={inputContainerClass}>
          <Input
            id={this.props.id}
            className={this.props.className}
            label={this.props.label}
            type={this.props.inputType || 'text'}
            onKeyPress={this.props.onKeyPress}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
            onChange={this.onChange}
            autoComplete={this.props.autoComplete}
            onFocus={this.hideErrors}
            maxLength={this.props.maxLength}
            noLabel={this.props.noLabel}
            onBlur={this.onBlur}
            placeholder={this.props.placeholder}
            value={this.state.value}
            errorText={this.state.errorsVisible || errorIsTooLong || hasCustomError ? errors : null}
            hasError={
              (this.state.errorsVisible &&
                ((this.props.isRequired && this.state.isEmpty) ||
                  (this.props.showValidation && this.state.isInvalid))) ||
              // eslint-disable-next-live no-mixed-operators
              (this.props.maxLength && this.state.isTooLong) ||
              hasCustomError
            }
            autoFocus={this.props.autoFocus}
          />
          {this.props.inputAddon}
          {errorIsTooLong && charsOverLimitIndicator}
          {!errors &&
            (this.props.note ? (
              <p className="caption-text d-flex mt-n5 pt-3">
                <Icon className="mr-1" icon="helpCircle" small />
                {this.props.note}
              </p>
            ) : null)}
        </div>
        {this.props.children}
        <style jsx>
          {`
            .caption-text {
              align-items: center;
            }
          `}
        </style>
      </div>
    );
  }
}

TextInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  emptyMsg: PropTypes.string,
  maxLength: PropTypes.number,
  showValidation: PropTypes.bool,
  regexp: PropTypes.object,
  caseIgnore: PropTypes.bool,
  autoComplete: PropTypes.string,
  preValidate: PropTypes.bool,
  validationMsg: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  noLabel: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onErrorsStatusUpdated: PropTypes.func,
  customError: PropTypes.string,
  inputType: PropTypes.string,
  inputAddon: PropTypes.object,
  note: PropTypes.any,
  autoFocus: PropTypes.bool
};

export default TextInput;
