import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('GLOBAL');

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const HIDE_LOADING = getActionName('HIDE_LOADING');
export const NOTIFY_ERROR = getActionName('NOTIFY_ERROR');
export const SHOW_BACKDROP = getActionName('SHOW_BACKDROP');
export const HIDE_BACKDROP = getActionName('HIDE_BACKDROP');
export const GLOBAL_CLOSE_SIDEBAR = getActionName('CLOSE_SIDEBAR');
export const RESET = getActionName('RESET');
export const PARTNER_DATA_LOADED = getActionName('PARTNER_DATA_LOADED');

// ------------------------------------
// Actions
// ------------------------------------
export function showLoading() {
  return {
    type: SHOW_LOADING
  };
}

export function hideLoading() {
  return {
    type: HIDE_LOADING
  };
}

export function showBackdrop() {
  return {
    type: SHOW_BACKDROP
  };
}

export function hideBackdrop() {
  return {
    type: HIDE_BACKDROP
  };
}

export function globalCloseSidebar() {
  return {
    type: GLOBAL_CLOSE_SIDEBAR
  };
}

export function notifyError(err) {
  return {
    type: NOTIFY_ERROR,
    error: err
  };
}

export function reset() {
  return {
    type: RESET,
    payload: {
      scope: 'components'
    }
  };
}

export function partnerDataLoaded() {
  return {
    type: PARTNER_DATA_LOADED
  };
}
