import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'NAV_MANAGER');

// ------------------------------------
// Constants
// ------------------------------------
export const INIT = getActionName('INIT');
export const GOTO = getActionName('GOTO');
export const NEXT = getActionName('NEXT');
export const BACK = getActionName('BACK');

// ------------------------------------
// Actions
// ------------------------------------
export function init(steps = []) {
  return {
    type: INIT,
    payload: steps
  };
}

export function goto(step = '') {
  return {
    type: GOTO,
    payload: step
  };
}

export function next() {
  return {
    type: NEXT
  };
}

export function back() {
  return {
    type: BACK
  };
}
