import { Record } from 'immutable';

export const CreditCardModel = new Record({
  IsAllowedHybrid: false,
  PaymentSettingsInfo: null,
  BraintreePaymentMethods: [],
  BraintreeCustomerNotFound: true,
  PaymentMethods: [],
  BraintreeCustomerId: '',
  IsOnCredit: false,
  StripeCustomerId: null
});
