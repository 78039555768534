import { applyMiddleware, compose, createStore } from 'redux';
import { hashHistory } from 'react-router';
import defaultState from './initialState';
import makeRootReducer from './reducers';
import { updateLocation } from './actions/locationActions';
import sagaMiddleware, { registerLocalSagas } from './sagas';
import loggingMiddleware from './middlewares/loggingMiddleware';

// ========================================================
// State Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__ || defaultState;

// ======================================================
// Middleware Configuration
// ======================================================

// Logging middleware going first - it means it will catch all unhandled errors
// from reducers.
let middleware = [loggingMiddleware, sagaMiddleware];

// if (__PROD__) {
//   middleware = [loggingMiddleware, ...middleware]
// }

// ======================================================
// Store Enhancers
// ======================================================
const enhancers = [];
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

// ========================================================
// Store Instantiation and HMR Setup
// ========================================================
let asyncReducers = {};
const store = createStore(
  makeRootReducer(asyncReducers),
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);
store.asyncReducers = asyncReducers;
store.makeRootReducer = makeRootReducer;
store.sagaMiddleware = sagaMiddleware;
store.asyncSagas = {};
registerLocalSagas();

// To unsubscribe, invoke `store.unsubscribeHistory()` anytime
store.unsubscribeHistory = hashHistory.listen(updateLocation(store));

if (module.hot) {
  module.hot.accept('./reducers/index', () => {
    const reducers = require('./reducers/index').default;
    store.replaceReducer(reducers(store.asyncReducers));
  });
}

export default store;
