import { takeLatest, put, select, all, call } from 'redux-saga/effects';
import {
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH,
  fetchAsync,
  selectProducts,
  skuSelect,
  TEMPLATES_FETCH
} from 'gooten-components/src/store/actions/dataActions';
import {
  UPDATE_LOADING_STATUS,
  editDesign,
  UPDATE_PRODUCT_ID,
  updateLoadingStatus,
  EDIT_DESIGN,
  INIT_EDIT_WITH_PRP_SKU
} from './EditDesignViewActions';
import {
  loadingStatusSelector,
  hasRequiredProductInfoSelector,
  hasProductsSelector,
  selectedProductSelector,
  skipCountryCheckSelector
} from './EditDesignViewSelectors';
import { requiredProductInfoSelector } from 'gooten-components/src/store/selectors/hubSelectors';
import AddToCartService from '../shared/AddToCart/AddToCartService';
import { possibleVendorsSelector } from 'gooten-components/src/components/SKUSelection/SKUSelectionSelectors';
import { setPublishedOrientationChanged } from 'gooten-components/src/components/SKUSelection/SKUSelectionActions';
import editDesignViewService from './EditDesignViewService';
import { mapEditDesignItemFromProductVariantUpdated } from '../../models/EditDesignItem';
import partnerService from '../../OnboardingDashboard/shared/services/ServiceWrapper';
import { summaryData } from '../../Orders/OrderDetails/OrderDetailsSelectors';
import ConfigHUB from '../../config'; // HUB config
import Config from 'gooten-components/src/config'; // COMPONENTS config

// TODO: This is similar to Hub/EditProductView consider refactoring in future
export function* loadingStatusUpdateHandler(action) {
  const hasRequiredProductInfo = yield select(hasRequiredProductInfoSelector);
  if (!hasRequiredProductInfo) {
    // Skip if required product info missing
    return;
  }

  // Will be defined below
  let selectedProduct, requiredProductInfo;

  switch (action.payload.status) {
    case 'initial':
      const hasProducts = yield select(hasProductsSelector);
      if (!hasProducts) {
        yield put(fetchAsync(PRODUCTS_FETCH));
      } else {
        yield put(updateLoadingStatus('productsLoaded'));
      }
      break;
    case 'productsLoaded':
      requiredProductInfo = yield select(requiredProductInfoSelector);
      yield put(selectProducts([requiredProductInfo.get('productId')]));
      selectedProduct = yield select(selectedProductSelector);
      if (!selectedProduct) {
        yield put(updateLoadingStatus('productNotAvailable'));
        break;
      }
      if (!selectedProduct.get('variants')) {
        yield put(fetchAsync(PRODUCT_VARIANTS_FETCH, selectedProduct.get('name')));
      } else {
        yield put(updateLoadingStatus('variantsLoaded'));
      }
      break;
    case 'variantsLoaded':
      selectedProduct = yield select(selectedProductSelector);
      if (!selectedProduct.get('details')) {
        yield put(fetchAsync(PRODUCT_DETAILS_FETCH, selectedProduct.get('name')));
      } else {
        yield put(updateLoadingStatus('detailsLoaded'));
      }
      break;
    case 'detailsLoaded':
      // Get currently edited products details (hub products or past order items)
      requiredProductInfo = yield select(requiredProductInfoSelector);
      selectedProduct = yield select(selectedProductSelector);
      const skipCountryCheck = yield select(skipCountryCheckSelector);

      if (!skipCountryCheck) {
        const possibleVendors = yield select(possibleVendorsSelector);
        const unorderableSKUs = AddToCartService.filterUnorderableSKUs(
          [requiredProductInfo.sku],
          selectedProduct.variants,
          possibleVendors
        );

        if (unorderableSKUs.length) {
          yield put(updateLoadingStatus('unorderableSKUs'));
          break;
        }
      }

      // Map to the selected skus data format
      const selectedSkus = [
        {
          sku: requiredProductInfo.get('sku'),
          prpSKU: requiredProductInfo.get('prpSKU'),
          neck_tag_id: requiredProductInfo.get('neck_tag_id'),
          neck_tag_image_url: requiredProductInfo.get('neck_tag_image_url'),
          spaces: requiredProductInfo.get('images').map(i => {
            // By default use print ready image url
            return {
              id: i.spaceId,
              images: [{ imageUrl: i.imageUrl, imageId: i.imageId }],
              il: i.il,
              // used in COF for comparsion to check whether prp image was edited
              prpIL: i.prpIL
            };
          })
        }
      ];
      yield put(skuSelect(selectedSkus));
      yield put(fetchAsync(TEMPLATES_FETCH));
      break;
    case 'templatesLoaded':
      yield put(updateLoadingStatus('ready'));
      break;
  }
}

export function* watchLoadingStatusUpdate() {
  yield takeLatest(UPDATE_LOADING_STATUS, loadingStatusUpdateHandler);
}

export function* productsLoadedHandler(action) {
  const currentStatus = yield select(loadingStatusSelector);
  if (currentStatus === 'initial') {
    yield put(updateLoadingStatus('productsLoaded'));
  }
}

export function* watchProductsLoaded() {
  yield takeLatest(PRODUCTS_FETCH.SUCCESS, productsLoadedHandler);
}

export function* productVariantsLoadedHandler(action) {
  const currentStatus = yield select(loadingStatusSelector);
  if (currentStatus === 'productsLoaded') {
    yield put(updateLoadingStatus('variantsLoaded'));
  }
}

export function* watchProductVariantsLoaded() {
  yield takeLatest(PRODUCT_VARIANTS_FETCH.SUCCESS, productVariantsLoadedHandler);
}

export function* productDetailsLoadedHandler(action) {
  const currentStatus = yield select(loadingStatusSelector);
  if (currentStatus === 'variantsLoaded') {
    yield put(updateLoadingStatus('detailsLoaded'));
  }
}

export function* watchProductDetailsLoaded() {
  yield takeLatest(PRODUCT_DETAILS_FETCH.SUCCESS, productDetailsLoadedHandler);
}

export function* productTemplatesLoadedHandler(action) {
  const currentStatus = yield select(loadingStatusSelector);
  if (currentStatus === 'detailsLoaded') {
    yield put(updateLoadingStatus('templatesLoaded'));
  }
}

function* editDesignHandler(action) {
  // get saved orientation from gooten_mapping, and store it
  if (action.payload.item.orientation === 'changed') {
    yield put(setPublishedOrientationChanged('changed'));
  }
}

export function* watchProductTemplatesLoaded() {
  yield takeLatest(TEMPLATES_FETCH.SUCCESS, productTemplatesLoadedHandler);
}

export function* watchEditDesign() {
  yield takeLatest(EDIT_DESIGN, editDesignHandler);
}

export function* fetchData() {
  const productData = yield select(requiredProductInfoSelector);
  const gootenMappingData = yield call(
    [editDesignViewService, editDesignViewService.loadGootenMappingData],
    {
      id: productData.productId
    }
  );
  const editMapping = yield mapEditDesignItemFromProductVariantUpdated(
    gootenMappingData.gooten_mappings[0]
  );
  yield put(editDesign(editMapping));
  window.location.hash = '#/custom-order-form/edit-design/start';
}

const reInitConfig = partnerData => {
  // re-init Config (both configs) with fetched partner data...
  const config = Config.toJS(); // COMPONENTS config
  const hubConfig = ConfigHUB.toJS(); // HUB config

  Config.destroy();
  ConfigHUB.destroy();

  config.partnerId = partnerData.PartnerId;
  hubConfig.partnerId = partnerData.PartnerId;
  config.recipeId = partnerData.RecipeKey;
  hubConfig.recipeId = partnerData.RecipeKey;
  config.storeApiKey = partnerData.PrivateBillingKey;
  hubConfig.storeApiKey = partnerData.PrivateBillingKey;

  Config.init(config);
  ConfigHUB.init(hubConfig);
};

export function* initEditWithPrpSku(action) {
  // we get here from Order Details page, when user initialize prp image editing
  // if we don't have 'recipeId', it means that we're logged in as Admin. Image Editor needs recipeId, in order
  // to fetch templates data, user images, etc...
  // in that case we will load partner data first, to get partner's api key and recipeId, and then re-init Config...
  const data = yield select(summaryData);
  const partnerData = yield call(
    [partnerService, partnerService.getPartnerDataWithId],
    data.PartnerInternalId
  );
  reInitConfig(partnerData);

  // we have to fetch gooten_mapping info, in order to get all needed data (ONLY orientation for now...)
  if (action.payload) {
    const gootenMappingData = yield call(
      [editDesignViewService, editDesignViewService.loadGootenMappingDataBySkus],
      {
        skus: action.payload
      }
    );

    if (
      gootenMappingData &&
      gootenMappingData.gooten_mappings &&
      gootenMappingData.gooten_mappings[0]
    ) {
      // get saved orientation from gooten_mapping, and store it
      if (gootenMappingData.gooten_mappings[0].orientation === 'changed') {
        yield put(setPublishedOrientationChanged('changed'));
      }
    }
  }
  yield put(updateLoadingStatus('initial'));
}

export function* watchProductId() {
  yield takeLatest(UPDATE_PRODUCT_ID, fetchData);
}

export function* watchInitEditWithPrpSku() {
  yield takeLatest(INIT_EDIT_WITH_PRP_SKU, initEditWithPrpSku);
}

export default function* rootSaga() {
  yield all([
    watchLoadingStatusUpdate(),
    watchProductsLoaded(),
    watchProductVariantsLoaded(),
    watchProductDetailsLoaded(),
    watchProductTemplatesLoaded(),
    watchEditDesign(),
    watchProductId(),
    watchInitEditWithPrpSku()
  ]);
}
