import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import { SET_TOAST } from './PackingSlipsActions';

export function* handleToast(action) {
  yield put(push(action.payload.message));
}

export function* watch() {
  yield takeLatest(SET_TOAST, handleToast);
}

export default function* rootSaga() {
  yield all([watch()]);
}
