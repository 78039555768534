import { all } from 'redux-saga/effects';
import { watchInitAsync, watchChangePageAsync } from '../BaseCard/BaseCardSaga';
import AccountActivity from './AccountActivity';

export default function* rootSaga() {
  yield all([
    watchInitAsync(AccountActivity.name, AccountActivity.messages),
    watchChangePageAsync(AccountActivity.name)
  ]);
}
