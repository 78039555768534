import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  FETCH_STORES,
  fetchStoresSuccess,
  cleanStores,
  VALIDATE_CSV,
  PUBLISH_CSV,
  LOAD_CSV,
  loadCsvSuccess,
  loadCsvFail,
  FETCH_HISTORY,
  fetchHistorySuccess
} from './StoreBulkMigrationActions';
import {} from './StoreBulkMigrationSelectors';
import service from '../StoreBulkService';
import storesService from '../../Hub/HubView/components/Stores/StoresService';
import adminApiService from '../../services/adminApiService';
import { CSVTextToObject } from '../Shared/CSVHelpers';

function* handleFetchStoresAsync(action) {
  try {
    yield put(cleanStores());
    const partnerId = action.payload;
    const resp = yield call(
      adminApiService.get,
      `ShopifyAccounts/GetPartnerData?partnerId=${partnerId}`
    );

    const args = {
      recipeId: resp.RecipeKey,
      apiKey: resp.PrivateBillingKey
    };

    const stores = yield call(
      [storesService, storesService.getUserStoresByParams],
      args.recipeId,
      args.apiKey
    );
    yield put(fetchStoresSuccess(stores));
  } catch (err) {
    yield put({
      type: FETCH_STORES.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to fetch stores.' }
    });
  }
}

function* handleLoadCsvAsync(action) {
  try {
    const data = CSVTextToObject(action.payload);
    yield put(loadCsvSuccess(data));
  } catch {
    yield put(loadCsvFail('Failed to parse CSV data'));
  }
}

function* handleValidateCsv(action) {
  try {
    const response = yield call(
      [service, service.bulkMigrationValidateCsv],
      action.payload.partnerId,
      action.payload.provider,
      action.payload.storeName,
      action.payload.csv
    );
    yield put({
      type: VALIDATE_CSV.SUCCESS,
      payload: { ...action.payload, response: response }
    });
  } catch (err) {
    yield put({
      type: VALIDATE_CSV.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to validate csv' }
    });
  }
}

function* handlePublishCsv(action) {
  try {
    const userData = yield call(adminApiService.get, `UsersApi/Details/0`);
    const emailAddress = userData.EmailAddress;

    const response = yield call(
      [service, service.bulkMigrationPublishCsv],
      action.payload.partnerId,
      emailAddress,
      action.payload.provider,
      action.payload.storeName,
      action.payload.uploadMockups,
      action.payload.csv
    );
    yield put({
      type: PUBLISH_CSV.SUCCESS,
      payload: { ...action.payload, response: response }
    });
  } catch (err) {
    yield put({
      type: PUBLISH_CSV.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to publish csv' }
    });
  }
}

function* handleFetchHistory(action) {
  try {
    const history = yield call([service, service.bulkMigrationFetchHistory]);
    yield put(fetchHistorySuccess(history));
  } catch (err) {
    yield put({
      type: FETCH_HISTORY.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to fetch history.' }
    });
  }
}

export function* watchFetchStores() {
  yield takeLatest(FETCH_STORES.ASYNC, handleFetchStoresAsync);
}

export function* watchLoadCsv() {
  yield takeLatest(LOAD_CSV.ASYNC, handleLoadCsvAsync);
}

export function* watchValidateCsv() {
  yield takeLatest(VALIDATE_CSV.ASYNC, handleValidateCsv);
}

export function* watchPublishCsv() {
  yield takeLatest(PUBLISH_CSV.ASYNC, handlePublishCsv);
}

export function* watchFetchHistory() {
  yield takeLatest(FETCH_HISTORY.ASYNC, handleFetchHistory);
}

export default function* rootSaga() {
  yield all([
    watchFetchStores(),
    watchValidateCsv(),
    watchPublishCsv(),
    watchLoadCsv(),
    watchFetchHistory()
  ]);
}
