import { createSelector } from 'reselect';
import { selectedProviderSelector } from '../Providers/ProvidersSelectors';

const storesStateSelector = state => state.getIn(['hub', 'hubView', 'stores']);
const providersStateSelector = state => state.getIn(['hub', 'hubView', 'providers']);

export const storesLoadedSelector = createSelector([storesStateSelector], storesState =>
  storesState.get('loaded')
);

export const availableStoresSelector = createSelector(
  [storesStateSelector, selectedProviderSelector],
  (storesState, selectedProvider) =>
    storesState.get('items').filter(s => s.get('provider') === selectedProvider)
);

export const selectedStoreSelector = createSelector([storesStateSelector], storesState =>
  storesState.get('selected')
);

export const selectedStoreNameSelector = createSelector(
  [availableStoresSelector, selectedStoreSelector],
  (availableStores, selectedStore) =>
    selectedStore ? availableStores.find(s => s.get('id') === selectedStore).get('storeName') : null
);

export const selectedStoreHasProductsSelector = createSelector(
  [availableStoresSelector, selectedStoreSelector],
  (availableStores, selectedStore) =>
    selectedStore
      ? availableStores.find(s => s.get('id') === selectedStore).get('hasProducts')
      : false
);

export const selectedStoreHasProductsToLinkSelector = createSelector(
  [availableStoresSelector, selectedStoreSelector],
  (availableStores, selectedStore) =>
    selectedStore
      ? availableStores.find(s => s.get('id') === selectedStore).get('hasProductsToLink')
      : false
);

export const storageSelectionSelector = createSelector(
  [providersStateSelector],
  provider => provider.get('selected') === 'storage'
);

export const allStoresSelector = createSelector([storesStateSelector], storesState =>
  storesState.get('items')
);

export const anyStoreWithProductsSelector = createSelector([allStoresSelector], allStores => {
  return allStores.find(s => s.get('provider') && s.get('hasProducts'));
});
