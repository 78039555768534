import { all, takeLatest, put } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { LOAD_VARIANTS, setReady, variantsFetched } from './PlaceOrderViewActions';
import productsService from './../HubView/components/Products/ProductsService';

export function* handleLoadVariants(action) {
  yield put(setReady(false));
  const tasks = [];
  const variants = [];

  for (let product of action.payload) {
    const asyncFn = productsService.loadProductVariants(product.toJS()).then(res => {
      variants.push({ productId: product.get('id'), variants: res });
    });
    tasks.push(asyncFn);
  }

  yield all(tasks);
  yield put(variantsFetched(fromJS(variants)));
  yield put(setReady(true));
}

export function* watchLoadVariants() {
  yield takeLatest(LOAD_VARIANTS, handleLoadVariants);
}

export default function* rootSaga() {
  yield all([watchLoadVariants()]);
}
