import { getActionName } from '../../CheckoutActions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const FETCH_PAYMENT_METHODS = getActionName.async('FETCH_PAYMENT_METHODS');
export const REMOVE_METHOD = getActionName.async('REMOVE_METHOD');
export const UPDATE_CARD = getActionName.async('UPDATE_CARD');
export const UPDATE_PAYPAL = getActionName.async('UPDATE_PAYPAL');

export const fetchPaymentMethods = () => ({
  type: FETCH_PAYMENT_METHODS.ASYNC
});

export const removeMethod = token => ({
  type: REMOVE_METHOD.ASYNC,
  payload: token
});

export const updateCard = card => ({
  type: UPDATE_CARD.ASYNC,
  payload: card
});

export const updateCardSuccess = updatedPaymentMethods => ({
  type: UPDATE_CARD.SUCCESS,
  payload: updatedPaymentMethods
});

export const updateCardFailure = error => ({
  type: UPDATE_CARD.FAIL,
  payload: { error }
});

export const updatePaypal = (nonce, braintreeToken) => ({
  type: UPDATE_PAYPAL.ASYNC,
  payload: { nonce, braintreeToken }
});
