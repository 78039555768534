import { ActionNameFactory } from '../../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'IMAGE_SELECTION_MODAL');

// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_OPEN = getActionName('MODAL_OPEN');
export const MODAL_CLOSE = getActionName('MODAL_CLOSE');
export const IMAGES_CHECK = getActionName.async('IMAGES_CHECK');
export const IMAGES_FETCH = getActionName.async('IMAGES_FETCH');
export const IMAGE_URL_UPLOAD = getActionName.async('IMAGE_URL_UPLOAD');
export const IMAGE_FILE_UPLOAD = getActionName.async('IMAGE_FILE_UPLOAD');
export const IMAGE_REMOVE = getActionName.async('IMAGE_REMOVE');
export const SHOW_LOADING = getActionName('SHOW_LOADING');
export const SHOW_SCREEN = getActionName('SHOW_SCREEN');

export const modalOpen = payload => ({
  type: MODAL_OPEN,
  payload
});

export const modalClose = payload => ({
  type: MODAL_CLOSE,
  payload
});

/**
 * Set which screen to show
 *
 * @param {} SCREEN_TYPE
 * @returns
 */
export const showScreen = payload => ({
  type: SHOW_SCREEN,
  payload
});

export const showLoading = payload => ({
  type: SHOW_LOADING,
  payload
});
