import { createSelector } from 'reselect';
import { selectedProduct, searchSelector } from '../../SKUSelectionSelectors';

export const allItemsSelector = createSelector(
  [selectedProduct, searchSelector],
  (selectedProduct, searchString) => {
    const productDetails = selectedProduct.get('details')?.product;

    const items = productDetails?.regions
      .map(region => {
        return region?.['sub-categories']
          .filter(subcat => subcat.name.includes(searchString ?? ''))
          .map(subCategory => {
            return {
              id: subCategory.id,
              name: subCategory.name,
              thumbnailUrl: subCategory.images?.[0].url,
              options: subCategory.attributes
                .filter(
                  x =>
                    x.name.includes('Gender') ||
                    x.name.includes('Model') ||
                    x.name.includes('Brand')
                )
                .map(attr => {
                  return {
                    id: attr.id,
                    name: attr.name,
                    value: attr.values[0]
                  };
                })
            };
          });
      })
      ?.flat();
    return items;
  }
);

export const genderOptions = createSelector([selectedProduct], selectedProduct => {
  const productDetails = selectedProduct.get('details')?.product;

  const distinctGenders = productDetails.regions.reduce((acc, region) => {
    region['sub-categories'].forEach(subCategory => {
      subCategory.attributes.forEach(attr => {
        if (attr.name === 'Gender') {
          attr.values.forEach(gender => {
            // Check if the gender ID is not already in the accumulator
            if (!acc.some(existing => existing.id === gender.id)) {
              acc.push(gender);
            }
          });
        }
      });
    });
    return acc;
  }, []);
  distinctGenders.unshift({ id: null, name: null });

  return distinctGenders;
});

export const brandOptions = createSelector([selectedProduct], selectedProduct => {
  const productDetails = selectedProduct.get('details')?.product;

  const distinctOptions = productDetails.regions.reduce((acc, region) => {
    region['sub-categories'].forEach(subCategory => {
      subCategory.attributes.forEach(attr => {
        if (attr.name === 'Brand') {
          attr.values.forEach(gender => {
            // Check if the brand ID is not already in the accumulator
            if (!acc.some(existing => existing.id === gender.id)) {
              acc.push(gender);
            }
          });
        }
      });
    });
    return acc;
  }, []);
  distinctOptions.unshift({ id: null, name: null });

  return distinctOptions;
});
