import React from 'react';
import { COLORS } from '../../constants.js';
import PropTypes from 'prop-types';
import Icon from './Icon.js';
import Button from './Button.js';
import Thumbnail from './Thumbnail.js';
import Tooltip from './Tooltip.js';

const ListItem = ({
  leftIcon,
  rightIcon,
  buttonIcon,
  text,
  secondaryText,
  className,
  active,
  inactive,
  thumbnail,
  onSelect,
  subItem,
  tooltip,
  fullWidth
}) => {
  return (
    <div
      onClick={onSelect}
      className={`list-item ${className || ''} ${subItem ? 'pl-4' : ''} pt-2 pb-2`}
    >
      {leftIcon && (
        <Icon
          fill={active ? COLORS.gootenBlue : COLORS.neutralDark200}
          className="left-icon"
          icon={leftIcon}
        />
      )}
      {thumbnail && <Thumbnail className="small" image={thumbnail} />}
      <Tooltip containerClassName="w-100" content={tooltip}>
        <div className="text-container">
          <div className="main-text m-0 body-text-2 heavy">{text}</div>
          {secondaryText && <p className="m-0 caption-text">{secondaryText}</p>}
        </div>
      </Tooltip>

      {rightIcon && (
        <Icon
          className="right-icon"
          icon={rightIcon}
          fill={rightIcon === 'check' || active ? COLORS.gootenBlue500 : COLORS.neutralDark200}
        />
      )}
      {buttonIcon && (
        <Button className="button-default small" iconOnly>
          <Icon small icon={buttonIcon} />
        </Button>
      )}

      <style jsx>
        {`
          .list-item {
            position: relative;
            box-sizing: border-box;
            height: ${secondaryText || thumbnail ? '4rem' : '3rem'};
            border-radius: 4px;
            width: 100%;
            max-width: ${fullWidth ? '100%' : '20rem'};
            padding-right: ${buttonIcon || rightIcon ? '2.25rem' : '.5rem'};
            padding-left: ${leftIcon ? '2.25rem' : thumbnail ? '3.25rem' : '.5rem'};
            display: flex;
            align-items: center;
            cursor: ${inactive ? 'default' : 'pointer'};
          }
          .text-container {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 50vw;
          }
          p,
          .main-text {
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .main-text {
            line-height: ${secondaryText || thumbnail ? '1.5rem' : '2rem'};
            overflow: hidden;
            color: ${active ? COLORS.gootenBlue : 'inherit'};
            min-width: 8rem;
          }
          .list-item:after {
            content: '';
            background: ${active
              ? COLORS.gootenBlue200
              : inactive
              ? COLORS.neutralLight200
              : COLORS.neutralLight50};
            position: absolute;
            left: -0.5rem;
            right: -0.5rem;
            top: 0.25rem;
            bottom: 0.25rem;
            z-index: -1;
            border-radius: 4px;
            transition-duration: 0.2s;
          }
          // .list-item:hover:after {
          //    background: ${COLORS.neutralLight200}
          // }
          .list-item :global(.left-icon) {
            position: absolute;
            left: 0;
            top: 0.75rem;
          }
          .list-item :global(.cerulean-thumbnail) {
            position: absolute;
            left: 0;
            top: 0.75rem;
          }
          .list-item :global(.right-icon) {
            position: absolute;
            right: 0;
            top: 0.75rem;
          }
          .list-item :global(button) {
            position: absolute;
            right: 0;
            top: 0.75rem;
          }
        `}
      </style>
    </div>
  );
};

ListItem.propTypes = {
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  buttonIcon: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryText: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  thumbnail: PropTypes.string,
  onSelect: PropTypes.func,
  subItem: PropTypes.bool,
  tooltip: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default ListItem;
