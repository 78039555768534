import { List } from 'immutable';
import {
  CLEAR_DATA,
  toggleActionNameFor,
  initActionNameFor,
  updateActionNameFor,
  changeActionNameFor,
  cancelActionNameFor,
  changePageActionNameFor
} from './BaseCardActions';

const mapDataToState = (state, data, Model) => {
  // TODO: Records for NeckLabels
  if (data.Records) {
    const oldData = state.get('data');
    const newData = data.Records.map(i => new Model(i));
    state = state
      .set(
        'data',
        data.Total && List.isList(oldData) && state.get('page') > 1
          ? oldData.concat(newData)
          : new List(newData)
      )
      .set('total', data.Total);
  } else {
    state = state.set('data', new Model(data));
  }
  return data.list ? state : state.set('unmodified', state.get('data'));
};

export default (card, reducer, Model) => {
  const toggle = toggleActionNameFor(card);
  const init = initActionNameFor(card);
  const change = changeActionNameFor(card);
  const cancel = cancelActionNameFor(card);
  const update = updateActionNameFor(card);
  const changePage = changePageActionNameFor(card);

  return (state, action) => {
    state = reducer(state, action);
    switch (action.type) {
      case toggle:
        return state.set('open', !state.get('open'));
      case init.SUCCESS:
        return mapDataToState(state, action.payload.data, Model).set('loading', false);
      case init.FAIL:
        return mapDataToState(state, action.payload.err, Model).set('loading', false);
      case CLEAR_DATA:
        return reducer(undefined, action);
      case change:
        return state.update('data', d => d.set(action.payload.field, action.payload.value));
      case cancel:
        return state.set('data', state.get('unmodified'));
      case update.ASYNC:
        return state.set('busy', true);
      case update.FAIL:
        return state.set('busy', false);
      case update.SUCCESS:
        return state.set('unmodified', action.payload.data).set('busy', false);
      case changePage:
        return state.set('page', action.payload.page);
      default:
        return state;
    }
  };
};
