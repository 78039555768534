import { Record, List, fromJS } from 'immutable';
import { mapExistingVariantSpace } from './VariantSpace';
export const Variant = new Record({
  index: 0,
  sku: undefined,
  template: undefined,
  spaces: new List(),
  proPreview: undefined,
  externalMockup: undefined,
  neck_tag_id: undefined,
  neck_tag_image_url: undefined,
  orientation: undefined,
  savedOrientation: undefined,
  hasMainMockup: undefined,
  // Used in COF
  prpSKU: undefined,
  productName: undefined,
  isSample: false,
  archivedReason: 0
});

export const mapVariant = (variant, index) =>
  new Variant({
    index,
    sku: variant.sku,
    template: fromJS(variant.template),
    spaces: new List((variant.spaces || []).map(s => mapExistingVariantSpace(s))),
    proPreview: fromJS(variant.proPreview),
    externalMockup: fromJS(variant.externalMockup),
    neck_tag_id: variant.neck_tag_id,
    neck_tag_image_url: variant.neck_tag_image_url,
    orientation: variant.orientation,
    savedOrientation: variant.savedOrientation,
    hasMainMockup: variant.hasMainMockup,
    prpSKU: variant.prpSKU,
    productName: variant.productName,
    isSample: variant.isSample,
    archivedReason: variant.archivedReason
  });

export const mapVariants = variants => {
  // these indexes are already assigned to skus, keep them (in order to preserve sku state, like image edit changes)
  // and avoid duplicated indexes...
  const selectedIndexes = variants.filter(v => v.index >= 0).map(v => v.index);
  // find which indexes are free, and assign them to newly added skus...
  const newIndexes = selectedIndexes.concat(
    Array.from({ length: variants.length }, (_, i) => i).filter(
      (_, i) => !selectedIndexes.includes(i)
    )
  );
  return variants.map((v, i) => mapVariant(v, newIndexes[i]));
};
