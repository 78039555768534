import {
  GET_COUNTRIES,
  GET_ADDRESS_TYPES,
  UPDATE_COUNTRIES,
  UPDATE_ADDRESS_TYPES,
  UPDATE_PRODUCTION_TIMES,
  GET_PRODUCTION_TIMES,
  CLEAR_PRODUCTION_TIMES,
  GET_VARIANTS_FOR_SKU,
  UPDATE_VARIANTS_FOR_SKU,
  EDITING_SHIPPING_ADDRESS,
  EDITING_BILLING_ADDRESS,
  DISABLE_SHIPPING_ADDRESS_EDIT
} from './SummaryViewActions';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['orders', 'summaryView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return state.setIn(['countries', 'loading'], true);
    case GET_PRODUCTION_TIMES:
      return state.setIn(['productionTimes', 'loading'], true);
    case CLEAR_PRODUCTION_TIMES:
      return state
        .setIn(['productionTimes', 'data'], [])
        .setIn(['productionTimes', 'indexedData'], []);
    case UPDATE_COUNTRIES:
      return state
        .setIn(['countries', 'loading'], false)
        .setIn(['countries', 'data'], action.payload.data);
    case UPDATE_PRODUCTION_TIMES:
      const prodTimeData = action.payload.data.toJS();
      let indexedData = [];
      if (prodTimeData.Result) {
        for (let i = 0; i < prodTimeData.Result.length; i++) {
          indexedData = [...indexedData, ...prodTimeData.Result[i].ProductionTimesOptions];
        }
      }
      return state
        .setIn(['productionTimes', 'loading'], false)
        .setIn(['productionTimes', 'data'], action.payload.data)
        .setIn(['productionTimes', 'indexedData'], indexedData);
    case EDITING_SHIPPING_ADDRESS:
      return state.setIn(['editingShippingAddress'], action.payload.data);
    case EDITING_BILLING_ADDRESS:
      return state.setIn(['editingBillingAddress'], action.payload.data);
    case GET_ADDRESS_TYPES:
      return state.setIn(['addressTypes', 'loading'], true);
    case UPDATE_ADDRESS_TYPES:
      return state
        .setIn(['addressTypes', 'loading'], false)
        .setIn(['addressTypes', 'data'], action.payload.data);
    case GET_VARIANTS_FOR_SKU:
      return state
        .setIn(['changeSkuView', 'loading'], true)
        .setIn(['changeSkuView', 'variants'], []);
    case UPDATE_VARIANTS_FOR_SKU:
      return state
        .setIn(['changeSkuView', 'loading'], false)
        .setIn(['changeSkuView', 'variants'], action.payload.data)
        .setIn(['changeSkuView', 'partnerInfo'], action.payload.partnerInfo);
    case DISABLE_SHIPPING_ADDRESS_EDIT:
      return state.set('disableShippingAddressEdit', action.payload);
    default:
      return state;
  }
};
