import React from 'react';
import PropTypes from 'prop-types';

export const Loader = props => (
  <div className={`${props.className} tracking-bar w-100 d-flex justify-content-start`}>
    <div className="fill-bar" />
    <style jsx>
      {`
        .tracking-bar {
          height: 2rem;
          border-radius: 4px;
          overflow: hidden;
          box-shadow: inset 2px 1px 7px #e2e2e2;
        }
        .fill-bar {
          background: #092dc5;
          height: 2rem;
          width: ${Math.floor((props.current / props.total) * 100) || 0}%;
        }
      `}
    </style>
  </div>
);

Loader.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default Loader;
