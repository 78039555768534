import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Button from './Button.js';
import Spinner from './Spinner';

const ProductCard = ({
  title,
  price = 'unavailable',
  selected = false,
  loading = false,
  image,
  selectClick,
  productClick,
  moreInfoClick,
  chip
}) => {
  return (
    <div className="card">
      <div className="image-container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="image-holder">
            <img onClick={productClick} src={image} alt={title} />
          </div>
        )}
        <div className="catalog-buttons d-flex align-items-center justify-content-center w-100 p-3">
          <Button onClick={selectClick} className="top dropshadow-2 button-primary large w-100 m-1">
            Select Product
          </Button>
          <Button
            onClick={moreInfoClick}
            className="bottom dropshadow-2 button-secondary large w-100 m-1"
          >
            View Details
          </Button>
        </div>
      </div>
      <div className="text-container d-flex justify-content-between h-100">
        <div>
          <div title={title} className="product-title truncated-text title-3">
            {title}
          </div>
          <div className={`product-type caption-text`}>Starting at {price}</div>
        </div>
        {chip && chip}
      </div>

      <style jsx>
        {`
          * {
            transition-duration: 0.2s;
            box-sizing: border-box;
          }
          .card {
            border-radius: 4px;
            width: 100%;
            height: 100%;
            max-width: 18rem;
            border: 1px solid ${COLORS.neutralLight300};
            display: flex;
            flex-direction: column;
            margin: auto;
            overflow: hidden;
            position: relative;
            background: ${COLORS.neutralLight50};
          }
          .card img {
            max-width: 100%;
            max-height: 100%;
            transition: all ease 150ms;
            border-radius: 0;
          }
          .image-container {
            width: 100%;
            padding-bottom: 100%;
            height: 0;
            position: relative;
          }
          .image-holder {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          .image-container .catalog-buttons {
            opacity: ${selected ? '1' : '0'};
            flex-direction: column;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
          }
          .image-container:hover .catalog-buttons {
            opacity: 1;
          }
          .text-container {
            padding: 0.75rem 1rem 1rem;
            text-align: left;
            height: auto;
            position: relative;
            flex-direction: column;
          }
          .caption-text {
            color: ${COLORS.monstera500};
          }
        `}
      </style>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string,
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  image: PropTypes.string,
  selectClick: PropTypes.func,
  productClick: PropTypes.func,
  moreInfoClick: PropTypes.func,
  chip: PropTypes.element
};

export default ProductCard;
