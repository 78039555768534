import { createSelector } from 'reselect';
import {
  selectedSKUsSelector,
  allProductsSelector,
  selectedProductIdsSelector,
  dataSelector
} from '../../store/selectors/productDataSelectors';
import { fromJS, List } from 'immutable';
import Config from '../../config';
import Log from '../../services/logService';
import { getDateNowStr, getLastIndex } from '../../utils/random';
import { flatten, getDuplicates } from '../../utils/array';
import { selectAllVariants } from './atoms/shared/ProductVariants/ProductVariantsActions';

export const publishStateSelector = state => state.get('publish');

const hubSelectedProductSelector = state => state.getIn(['hub', 'hubView', 'products', 'selected']);
const hubSelectedVariantsSelector = state =>
  state.getIn(['hub', 'hubView', 'products', 'variants']);
const publishVariantsSelector = state => state.getIn(['publish', 'data', 'storage', 'variants']);

const connectExistingDataSelector = state =>
  Config.get('linkMode')
    ? state.getIn(['hub', 'linkProduct', 'productDesignView', 'connectExistingData']).toJS()
    : null;

const previewDataImmutableSelector = state => state.getIn(['preview', 'data']);

const previewDataSelector = createSelector(previewDataImmutableSelector, pd => pd.toJS());

export const publishingStateSelector = createSelector([publishStateSelector], publish =>
  publish.get('publishingState')
);

export const productsSelector = createSelector(
  [dataSelector, selectedProductIdsSelector],
  (data, selectedProductIds) =>
    data
      .get('products')
      .filter(p => selectedProductIds.includes(p.get('id')))
      .toJS()
);

export const selectedProductsSelector = createSelector(
  [selectedProductIdsSelector, allProductsSelector],
  (selectedProductIds, allProducts) => {
    return allProducts.filter(p => selectedProductIds.includes(p.get('id')));
  }
);

const previewSkusSortOrderSelector = createSelector([previewDataSelector], previewData => {
  const getSkusArrayFn = v => {
    if (v.associatedSkus && v.associatedSkus.length) {
      return v.associatedSkus.map(obj => obj.sku + obj.dataIndex);
    } else {
      return [v.sku + v.dataIndex];
    }
  };

  return previewData.items.reduce((acc, c) => acc.concat(getSkusArrayFn(c)), []);
});

export const sortedSelectedSkusSelector = createSelector(
  [selectedSKUsSelector, previewSkusSortOrderSelector],
  (skus$, previewSkusSortOrder) => {
    const skus = skus$.toJS();

    // Don't sort SKUs if no previews
    if (!previewSkusSortOrder.length) {
      return skus;
    }

    // Sort SKUs by previews order defined by user in Preview step
    const sortedSKUs = skus.sort(
      (a, b) =>
        previewSkusSortOrder.indexOf(a.sku + a.index) -
        previewSkusSortOrder.indexOf(b.sku + b.index)
    );
    return sortedSKUs;
  }
);

export const allDetailedSkusSelector = createSelector(
  [sortedSelectedSkusSelector, productsSelector],
  (selectedSkus, products) => {
    const allVariants = products
      .map(p => p.variants.map(v => ({ ...v, productId: p.id })))
      .reduce((a, b) => a.concat(b), []);
    return (
      selectedSkus &&
      fromJS(
        selectedSkus.map(s => ({
          ...allVariants.find(v => v.sku.toLowerCase() === s.sku.toLowerCase()),
          ...s
        }))
      )
    );
  }
);

export const publishDataSelector = createSelector([publishStateSelector], publish =>
  publish.get('data')
);

export const publishDataReadySelector = createSelector(
  [publishDataSelector],
  publishData => publishData && publishData.get('ready')
);

export const publishConfigSelector = createSelector([publishStateSelector], publish =>
  publish.get('config')
);

const productConnectionsSelector = state => {
  // TODO: Publish should not depend on hub state
  // Need to pass it via config or etc.
  const products = state.getIn(['hub', 'hubView', 'products']);
  return Config.get('editMode')
    ? products
        .get('connections')
        .unshift(products.get('selected').get(0).set('variants', products.get('variants')))
        .toJS()
    : null;
};

export const selectedProductsAttributes = createSelector(
  [selectedProductsSelector],
  selectedProducts => {
    const selectedProductsDetails = selectedProducts.map(sp => sp.get('details')).toJS();
    const result = selectedProductsDetails.length
      ? selectedProductsDetails
          .map(details =>
            details.product.regions.reduce((r1, reg) => {
              if (reg['sub-categories']) {
                r1.push.apply(
                  r1,
                  reg['sub-categories'].reduce((r2, sub) => {
                    if (sub.attributes) {
                      r2.push.apply(r2, sub.attributes);
                    }
                    return r2;
                  }, [])
                );
              }
              return r1;
            }, [])
          )
          .reduce((a, b) => a.concat(b), [])
      : [];
    return result;
  }
);

const getProductImages = (selectedSkus, previewData) => {
  // If sku has pro preview enabled
  // only one pro preview will be used as variant image
  // but pro previews has multiple scenes
  // so we can use others scenes as product images
  // Multiple variants could share the same proPreview, so Set() to dedup.
  const seenUrls = new Set();
  const productImages = [];

  if (previewData.items.length) {
    for (const sku of selectedSkus) {
      const previewImageUrls = previewData.items
        .slice(0, 249)
        .filter(
          item => item.sku.toLowerCase() === sku.sku.toLowerCase() && item.dataIndex === sku.index
        )
        .map(item => item.previewImageUrl);

      // Skip 1st since it will be used as variant image
      for (const url of previewImageUrls.slice(1)) {
        if (!seenUrls.has(url)) {
          productImages.push({
            id: sku.proPreview
              ? `generated-proPreview-${productImages.length}`
              : `generated-preview-${productImages.length}`,
            index: productImages.length,
            url: url
          });
          seenUrls.add(url);
        }
      }
    }
  }

  return productImages;
};

const getVariantImage = (sku, previewData) => {
  const items = previewData.items.filter(item =>
    item.associatedSkus
      ? item.associatedSkus.find(z => z.sku.toLowerCase() === sku.sku.toLowerCase())
      : item.sku.toLowerCase() === sku.sku.toLowerCase() && item.dataIndex === sku.index
  );

  if (items.length) {
    return items[0].previewImageUrl;
  } else if (sku.proPreview && sku.proPreview.imageUrls && sku.proPreview.imageUrls.length) {
    return sku.proPreview.imageUrls[0];
  } else {
    return sku.spaces[0].previewImgUrl;
  }
};

const getBackgroundColor = (sku, previewData) => {
  const items = previewData.items.filter(
    item => item.sku === sku.sku && item.dataIndex === sku.index
  );
  return items.length ? items[0].backgroundColor : '';
};

const getExistingVariantOptionValues = (variant, connectExistingData) => {
  const existingVariant = connectExistingData.mappings.find(
    m => m.variant.id === variant.id
  ).variant;
  const optionValues = existingVariant.options.map((o, i) => ({ position: i + 1, value: o.value }));
  return { option_values: optionValues };
};

const mapOptions = (source, publishData) =>
  source.selectedOptions.map((o, i) => ({
    position: i + 1,
    name: publishData.storage.options.find(x => x.id === o.value).title
  }));

const mapOptionsValues = (variant, source, selectedSkus) => {
  let sku, optionId, optionValueId;
  try {
    const selectedSku = selectedSkus.find(s => s.sku.toLowerCase() === variant.sku.toLowerCase());

    const result = source.selectedOptions
      .map((o, i) => {
        const valueId = selectedSku[o.value];
        sku = selectedSku.sku;
        optionId = o.value;
        optionValueId = valueId;
        const value = selectedSku.attrs
          .reduce((res, a) => {
            if (a.id === o.value) {
              res.push.apply(res, a.values);
            }
            return res;
          }, [])
          .find(v => v.id === valueId);
        return {
          position: i + 1,
          value: o.id && o.id === 'keep_value' ? o.value : value && value.name
        };
      })
      .filter(a => a.value);

    return result;
  } catch (error) {
    Log.error(
      error,
      `Problem getting option value for sku:${sku}, option id: ${optionId}, value id ${optionValueId}`
    );
    throw error;
  }
};

const cleanOption = s => s && s.replace(/\s/g, '').toLowerCase();

const optionsEqual = (o1, o2) => cleanOption(o1) === cleanOption(o2);

// const optionsSimilar = (o1, o2) =>
//   cleanOption(o1).indexOf(cleanOption(o2)) !== -1 ||
//   cleanOption(o2).indexOf(cleanOption(o1)) !== -1

const optionsSimilar = (o1, o2) =>
  o1 &&
  o2 &&
  !!o1
    .trim()
    .split(' ')
    .filter(k => k.length > 2)
    .find(m =>
      o2
        .trim()
        .split(' ')
        .filter(k => k.length > 2)
        .find(
          k =>
            k.toLowerCase().indexOf(m.toLowerCase()) !== -1 ||
            m.toLowerCase().indexOf(k.toLowerCase()) !== -1
        )
    );

const mapEtsyOptionValues = (storeOption, options) => {
  const etsyProperties = [
    { name: 'size', id: 100 },
    { name: 'color', id: 200 },
    { name: 'finish', id: 500 },
    { name: 'dimensions', id: 501 },
    { name: 'fabric', id: 502 },
    { name: 'height', id: 505 },
    { name: 'length', id: 506 },
    { name: 'material', id: 507 },
    { name: 'pattern', id: 508 },
    { name: 'style', id: 510 },
    { name: 'width', id: 512 },
    { name: 'device', id: 515 },
    { name: 'glitter', id: 49673508761 },
    { name: 'material', id: 47626760362 },
    { name: 'set', id: 49673508857 },
    { name: 'capacity', id: 52047898162 },
    { name: 'pattern', id: 99837394318 },
    { name: 'design', id: 99837394318 },
    { name: 'hypoallergenic', id: 148789511799 },
    { name: 'graphic', id: 396998957792 },
    { name: 'theme', id: 409632661047 }
  ];

  const matchedEtsyOption = etsyProperties.find(p => p.id === storeOption.id);
  return (
    matchedEtsyOption &&
    options.find(o => o.title.toLowerCase().indexOf(matchedEtsyOption.name) !== -1)
  );
};

// Makes sure there are no option values duplicates which will cause publish request failure
const unifyOptionsValues = variants => {
  // merge all option values into single string with format `option1-option2-option3`
  const uniqValue = v =>
    v.option_values &&
    v.option_values
      .map(ov => ov.value)
      .join('-')
      .toLowerCase();

  // make last option values unique, ensure that all variants have unique option values
  const lastValues = variants.map(
    v =>
      v.option_values && v.option_values.length && v.option_values[v.option_values.length - 1].value
  );
  let lastIndex = Math.max(...lastValues.map(v => getLastIndex(v)));
  lastIndex = lastIndex === -1 ? 0 : lastIndex;

  const valuesCombined = variants.map(v => uniqValue(v));

  // modify variants with unique option_values
  variants.forEach(vo => {
    if (vo.option_values) {
      const lastPosition = Math.max(...vo.option_values.map(v => v.position));

      vo.option_values = vo.option_values.map(i => ({
        position: i.position,
        value:
          // if this last option value and the variant is new - check for option value uniqueness
          i.position === lastPosition && !vo.id
            ? valuesCombined.filter(vk => vk.toLowerCase() === uniqValue(vo)).length > 1
              ? `${i.value} (${++lastIndex})`
              : i.value
            : i.value
      }));
    }
  });
};

const getStorageProductUpsertTask = (publishData, selectedSkus, storageProductCandidate) => {
  const obj = {
    name: 'storage-product-upsert',
    options: {},
    overrides: {}
  };

  // Edit flow
  if (storageProductCandidate) {
    obj.overrides = {
      product: {
        id: !publishData.storage.wasConnected ? null : storageProductCandidate.id,
        // NOTE: make sure the id's of variants in the same sort order as selected skus
        variants: selectedSkus.map((sku, i) => {
          const variant = storageProductCandidate.variants.find(
            v => v.gootenMapping.items[0].sku === sku.sku
          );
          // prp alredy exist in edit flow
          // will get it from gootenMapping
          return {
            id: variant && variant.gootenMapping.id
          };
        })
      }
    };
  }

  return obj;
};

const getStoreProductUpsertForEditTask = (
  store,
  publishData,
  selectedSkus,
  productConnections,
  previewData,
  duplicatedSkus
) => ({
  name: 'store-product-upsert',
  options: {
    store_id: store.id,
    publish: !store.draft,
    update_preview: store.updatePreview
  },
  ...combineOverridesForEdit(
    store,
    publishData,
    selectedSkus,
    productConnections,
    previewData,
    duplicatedSkus
  )
});

const getStoreProductLinkTask = (
  store,
  publishData,
  selectedSkus,
  connectExistingData,
  previewData
) => ({
  name: 'store-product-link',
  options: {
    store_id: store.id,
    publish: !store.draft,
    update_preview: store.updatePreview
  },
  ...combineOverrides(store, publishData, selectedSkus, connectExistingData, previewData)
});

const getStoreProductUpsertTask = (store, publishData, selectedSkus, previewData) => ({
  name: 'store-product-upsert',
  options: {
    store_id: store.id,
    publish: !store.draft,
    // if update_preview is false, no preview will be published in store...
    update_preview: store.updatePreview
  },
  ...combineOverrides(store, publishData, selectedSkus, null, previewData)
});

const getStoreProductUpsertForDuplicateTask = (
  store,
  publishData,
  selectedSkus,
  productConnections,
  previewData
) => ({
  name: 'store-product-upsert',
  options: {
    store_id: store.id,
    publish: !store.draft,
    update_preview: true
  },
  ...combineOverridesForDuplicate(store, publishData, selectedSkus, productConnections, previewData)
});

const combineOverridesForEdit = (
  store,
  publishData,
  selectedSkus,
  productConnections,
  previewData,
  duplicatedSkus
) => {
  // case when 1 product published to multiple stores
  // this hold information for the product from the selected store

  // storeProductConnection for current store may not exist
  const storeProductConnection = productConnections.find(p => p.storeId === store.id);

  const productOverrides = {
    ...setNameOverrides(store, publishData),
    ...setDescriptionOverrides(store, publishData),
    ...setTypeOverrides(store, publishData),
    ...setTagsOverrides(store, publishData),
    ...setCollectionsOverrides(store, publishData),
    ...setOptionsOverridesForEdit(store, publishData, storeProductConnection),
    ...setVariantsOverridesForEdit(
      store,
      publishData,
      selectedSkus,
      storeProductConnection,
      previewData,
      duplicatedSkus
    )
  };
  return {
    overrides: {
      product: {
        ...productOverrides,
        id: !store.wasConnected ? null : storeProductConnection.id
      }
    }
  };
};

const combineOverridesForDuplicate = (
  store,
  publishData,
  selectedSkus,
  productConnections,
  previewData
) => {
  // case when 1 product published to multiple stores
  // this hold information for the product from the selected store

  // storeProductConnection for current store may not exist
  const storeProductConnection = productConnections.find(p => p.storeId === store.id);

  const productOverrides = {
    ...setNameOverrides(store, publishData),
    ...setDescriptionOverrides(store, publishData),
    ...setTypeOverrides(store, publishData),
    ...setTagsOverrides(store, publishData),
    ...setCollectionsOverrides(store, publishData),
    ...setOptionsOverridesForEdit(store, publishData, storeProductConnection),
    ...setVariantsOverridesForDuplicate(
      store,
      publishData,
      selectedSkus,
      storeProductConnection,
      previewData
    )
  };
  return {
    overrides: {
      product: {
        ...productOverrides
      }
    }
  };
};

const combineOverrides = (
  store,
  publishData,
  selectedSkus,
  connectExistingData = null,
  previewData
) => {
  const productOverrides = {
    ...setConnectedProduct(connectExistingData),
    ...setNameOverrides(store, publishData),
    ...setDescriptionOverrides(store, publishData),
    ...setTypeOverrides(store, publishData),
    ...setTagsOverrides(store, publishData),
    ...setCollectionsOverrides(store, publishData),
    ...setOptionsOverrides(store, publishData, connectExistingData),
    ...setVariantsOverrides(store, publishData, selectedSkus, connectExistingData, previewData)
  };

  return Object.keys(productOverrides).length ? { overrides: { product: productOverrides } } : {};
};

const setConnectedProduct = connectExistingData =>
  connectExistingData ? { id: connectExistingData.storeProduct.id } : {};

const setNameOverrides = (store, publishData) =>
  store.productName === publishData.storage.productName ? {} : { name: store.productName };

const setDescriptionOverrides = (store, publishData) =>
  store.productDesc === publishData.storage.productDesc
    ? {}
    : { description_html: store.productDesc };

const setTypeOverrides = (store, publishData) =>
  store.productType === publishData.storage.productType ? {} : { type: store.productType };

const setTagsOverrides = (store, publishData) =>
  JSON.stringify(store.selectedTags) === JSON.stringify(publishData.storage.selectedTags)
    ? {}
    : { tags: store.selectedTags };

const setCollectionsOverrides = (store, publishData) => {
  const previousCollectionsList = store.selectedCollections;
  const currentCollectionsList = publishData.storage.selectedCollections;

  // check if all collections in current selected and previously existing are the same.
  // if they are the same overrides should return an empty object else new collections
  // array
  return previousCollectionsList.length === currentCollectionsList.length
    ? previousCollectionsList.filter(prevColl =>
        currentCollectionsList.find(curColl => curColl.id === prevColl.id)
      ).length === previousCollectionsList.length
      ? {}
      : { collections: store.selectedCollections }
    : { collections: store.selectedCollections };
};

const setOptionsOverrides = (store, publishData, connectExistingData) =>
  !connectExistingData &&
  JSON.stringify(store.selectedOptions.map(o => o.value)) ===
    JSON.stringify(publishData.storage.selectedOptions.map(o => o.value))
    ? {}
    : {
        options: connectExistingData
          ? connectExistingData.storeProduct.options.map(({ position, name }) => ({
              position,
              name
            }))
          : mapOptions(store, publishData)
      };

const setOptionsOverridesForEdit = (store, publishData, storeProductConnection) =>
  // use old for connected
  !store.wasConnected
    ? setOptionsOverrides(store, publishData)
    : {
        options: storeProductConnection.options.map((p, i) => ({
          position: p.position || i + 1,
          name: p.name
        }))
      };

const setVariantsOverridesForEdit = (
  store,
  publishData,
  selectedSkus,
  storeProductConnection,
  previewData,
  duplicatedSkus
) => {
  // it might happen, that we have duplicated skus, so we need to check that first...
  const variantsOverrides = selectedSkus.map(sku => {
    const variant =
      duplicatedSkus.indexOf(sku.sku) !== -1
        ? store.variants.find(v => v.index === sku.index)
        : store.variants.find(v => v.sku === sku.sku);

    // storeProductConnectionVariant is matching variant from connected store, but keep in mind that
    // storeProductConnection can be null (that means we are adding new variant)...
    const storeProductConnectionVariant =
      store.wasConnected && duplicatedSkus.indexOf(sku.sku) !== -1
        ? storeProductConnection.variants[variant.index]
        : storeProductConnection &&
          storeProductConnection.variants.find(va => va.gootenMapping.items[0].sku === variant.sku);

    // we will use this for newly added variants, to try to match options, and compare it with already
    // published variant options... if we can't match, we need to let partner to update options manually on store...
    const storageOptions = publishData.storage.options;
    const storageSource = {
      selectedOptions: store.selectedOptions.map((so, i) => {
        const matchedStoreOption = store.options.find(s => s.id === so.value);

        // 1. try direct matching...
        const matchedStorageOption =
          matchedStoreOption &&
          storageOptions.find(o => optionsEqual(o.title, matchedStoreOption.title));
        if (matchedStorageOption) {
          return { id: sku[matchedStorageOption.id], value: matchedStorageOption.id };
        }

        // 2. if provider is Etsy, try diferent matching...
        if (store && store.provider && store.provider.toLowerCase() === 'etsy') {
          const mappedOption = mapEtsyOptionValues(matchedStoreOption, storageOptions);
          if (mappedOption) {
            return { id: sku[mappedOption.id], value: mappedOption.id };
          }

          // if we cant find mapped option, check if we have merged options "option_1 | option_2"
          // and try matching...
          const mergedOptions = matchedStoreOption.title.split('|');
          if (mergedOptions && mergedOptions.length > 1) {
            const mappedOptions = mergedOptions
              .map(m => storageOptions.find(a => optionsSimilar(a.title, m)))
              .filter(m => m);
            if (mappedOptions) {
              const sOptions = mappedOptions.map(k => ({ id: sku[k.id], value: k.id }));
              const mappedOptionValues = mapOptionsValues(
                variant,
                { selectedOptions: sOptions },
                selectedSkus
              );
              // keep_value means, that later if we try again to map options with gooten's, we should keep this value below
              // which is made from merged options
              return { id: 'keep_value', value: mappedOptionValues.map(v => v.value).join(' | ') };
            }
          }
        }

        // 3. try position matching...
        return storageOptions[i]
          ? { id: sku[storageOptions[i].id], value: storageOptions[i].id }
          : // if number of selected options in store is greater than storage options, then take the last storage option
            // for all missing options, and unifyOptionsValues will take care about not duplication option names...
            {
              id: sku[storageOptions[storageOptions.length - 1].id],
              value: storageOptions[storageOptions.length - 1].id
            };
      })
    };

    const obj =
      !store.wasConnected || !storeProductConnectionVariant
        ? // we are here, because we are adding new variant to existig product,
          // or adding variants to new store
          {
            ...setCustomerPriceOverrides(variant, publishData),
            option_values: !storeProductConnectionVariant
              ? mapOptionsValues(variant, storageSource, selectedSkus)
              : mapOptionsValues(variant, store, selectedSkus)
          }
        : // for connected use old options and id...
          {
            ...setCustomerPriceOverrides(variant, publishData),
            id: storeProductConnectionVariant.id,
            option_values: storeProductConnectionVariant.options.map((ov, ovi) => ({
              position: ovi + 1,
              value: ov.value
            }))
          };

    // this means that we are adding new variant to existing product or to new store...
    // if number of product options is not equal with new variant number of options,
    // update new variant options, to match store product options, in order to add this variant
    // to existing product or to new store...
    if (
      !storeProductConnectionVariant &&
      store.selectedOptions.length !== obj.option_values.length
    ) {
      if (store.selectedOptions.length > obj.option_values.length) {
        obj.option_values = store.selectedOptions.map(
          (so, i) => obj.option_values[i] || { position: i + 1, value: 'update value' }
          // this means that we don't have matched option, so we will need to let user to update it on store
        );
      } else {
        obj.option_values = obj.option_values.slice(0, store.selectedOptions.length);
      }
    }
    return {
      ...setPreviewOverrides(variant, previewData, store),
      ...setSkuOverrides(variant, publishData),
      ...obj
    };
  });

  unifyOptionsValues(variantsOverrides);

  return {
    variants: variantsOverrides
  };
};

const setVariantsOverridesForDuplicate = (
  store,
  publishData,
  selectedSkus,
  storeProductConnection,
  previewData
) => {
  // NOTE: make sure the store variants in the same sort order as selected skus
  const variantsOverrides = selectedSkus.map(sku => {
    const variant = store.variants.find(v => v.index === sku.index);
    // NOTE: In edit mode customSku is taken from store product variants.sku
    // and variant sku - is our default auto generated sku name - which is product-name + our sku
    const storeProductConnectionVariant =
      store.wasConnected && storeProductConnection.variants[variant.index];
    // for connected use old options and id
    const obj = !store.wasConnected
      ? {
          ...setCustomerPriceOverrides(variant, publishData),
          option_values: mapOptionsValues(variant, store, selectedSkus)
        }
      : {
          ...setCustomerPriceOverrides(variant, publishData),
          // id: storeProductConnectionVariant.id,
          option_values: storeProductConnectionVariant.options.map((ov, ovi) => ({
            position: ovi + 1,
            value: ov.value
          }))
        };

    return {
      ...setPreviewOverrides(variant, previewData, store),
      ...setSkuOverrides(variant, publishData),
      ...obj
    };
  });

  unifyOptionsValues(variantsOverrides);

  return {
    variants: variantsOverrides
  };
};

const setConnectedVariant = (variant, connectExistingData) => {
  return connectExistingData && connectExistingData.mappings.find(m => m.variant.id === variant.id)
    ? { id: variant.id }
    : {};
};

const setSkuOverrides = (variant, publishData) =>
  variant.customSku === publishData.storage.variants.find(v => v.index === variant.index).customSku
    ? {}
    : { sku: variant.customSku };

const setCustomerPriceOverrides = (variant, publishData) =>
  parseFloat(variant.customerPrice).toFixed(2) ===
  parseFloat(
    publishData.storage.variants.find(v => v.index === variant.index).customerPrice
  ).toFixed(2)
    ? {}
    : { customer_price: Number(parseFloat(variant.customerPrice).toFixed(2)) };

const setOptionValuesOverrides = (
  variant,
  publishData,
  store,
  selectedSkus,
  connectExistingData
) => {
  return connectExistingData
    ? getExistingVariantOptionValues(variant, connectExistingData)
    : JSON.stringify(publishData.storage.selectedOptions.map(o => o.value)) ===
      JSON.stringify(store.selectedOptions.map(o => o.value))
    ? {}
    : { option_values: mapOptionsValues(variant, store, selectedSkus) };
};

export const setPreviewOverrides = (variant, previewData, store) => {
  const noPreviewObj = {
    images: [],
    gooten_mapping: {
      images: [],
      preview_url: ''
    }
  };

  if (!store.updatePreview) {
    return noPreviewObj;
  }

  const skuIndexesWithPreviews = flatten(
    previewData.items.map(item =>
      item.associatedSkus && item.associatedSkus.length
        ? item.associatedSkus.map(as => as.dataIndex)
        : [item.dataIndex]
    )
  );
  return skuIndexesWithPreviews.indexOf(variant.index) !== -1 ? {} : noPreviewObj;
};

const setVariantsOverrides = (
  store,
  publishData,
  selectedSkus,
  connectExistingData = null,
  previewData
) => {
  // TODO: optionsValuesAggr WILL be changed in setOptionValuesOverrides fn
  // TODO: Refactor it - b/c it is not explicit that this will be changed somewhere and some rely on it
  // NOTE: Make sure the store variants in the same sort order as selected skus
  // NOTE: Make sure overrides variants count equal to base product variants count
  // if no overrides - leave empty object - REQUIRED - since arrays merge happens by index

  let variantsOverrides = selectedSkus.map(sku => {
    const variant = store.variants.find(v => v.index === sku.index);

    const obj = {
      ...setConnectedVariant(variant, connectExistingData),
      ...setSkuOverrides(variant, publishData),
      ...setCustomerPriceOverrides(variant, publishData),
      ...setOptionValuesOverrides(variant, publishData, store, selectedSkus, connectExistingData),
      ...setPreviewOverrides(variant, previewData, store)
    };
    return obj;
  });

  unifyOptionsValues(variantsOverrides);

  return {
    variants: variantsOverrides
  };
};

// Publish request selector
export const publishRequestSelector = createSelector(
  [
    publishDataSelector,
    previewDataSelector,
    allDetailedSkusSelector,
    connectExistingDataSelector,
    productConnectionsSelector,
    selectedProductsAttributes
  ],
  (publishData, previewData, selectedSkus, connectExistingData, productConnections, attrs) => {
    const mutableSkus = selectedSkus.toJS();
    mutableSkus.forEach(sku => {
      sku.attrs = attrs;
    });

    if (Config.get('editMode') && Config.get('duplicateMode')) {
      return getDuplicateRequest(publishData.toJS(), previewData, mutableSkus, productConnections);
    } else if (Config.get('editMode')) {
      return getEditRequest(publishData.toJS(), previewData, mutableSkus, productConnections);
    } else if (Config.get('linkMode')) {
      return getLinkRequest(publishData.toJS(), previewData, mutableSkus, connectExistingData);
    } else {
      return getPublishRequest(publishData.toJS(), previewData, mutableSkus);
    }
  }
);

const getEditRequest = (publishData, previewData, selectedSkus, productConnections) => {
  // try get storage product either from connections or use selected product
  // same logic as on edit flow to prefill publish storage data
  const storageProductCandidate =
    productConnections.find(c => c.type === 'storage') || productConnections[0];

  // in edit flow we might have duplicated skus. we need to handle them by index, not by sku
  const duplicatedSkus = getDuplicates(selectedSkus.map(s => s.sku));
  let request = {
    product: {
      personalize: publishData.personalize,
      name: publishData.storage.productName,
      description_html: publishData.storage.productDesc,
      type: publishData.storage.productType,
      tags: publishData.storage.selectedTags,
      collections: publishData.storage.selectedCollections,
      images: getProductImages(selectedSkus, previewData),
      options: mapOptions(publishData.storage, publishData),
      variants: selectedSkus.map((sku, position) => {
        // NOTE: selectedSkus and storage variants are connected via index
        // because selectedSkus got sorted by template size on design step
        const variant =
          duplicatedSkus.indexOf(sku.sku) !== -1
            ? publishData.storage.variants.find(v => v.index === sku.index)
            : publishData.storage.variants.find(v => v.sku === sku.sku);
        const productConnectionVariant =
          duplicatedSkus.indexOf(sku.sku) !== -1
            ? storageProductCandidate.variants[variant.index]
            : storageProductCandidate.variants.find(
                v => v.gootenMapping.items[0].sku === variant.sku
              );
        const gootenSku =
          (productConnectionVariant && productConnectionVariant.gootenMapping.sku) ||
          variant.customSku;
        const previewUrl = getVariantImage(sku, previewData);
        const images = previewUrl.length
          ? [
              {
                id: 'generated-preview',
                index: 0,
                url: previewUrl
              }
            ]
          : [];

        // Some Embroidery PRP's are missing ImageId property
        // and failing upon submitting an order
        // Check if SKU is embroidery and throw an error to prevent creation of broken PRPs
        // TODO: the cause still needs to be identified
        // https://gooten.atlassian.net/browse/TECH-9604
        if (sku.sku.includes('-Embroidery-')) {
          const space = sku.spaces[0];
          if (space.images && space.images.length && space.images[0] && space.images[0].imageId) {
            // imageId exists
          } else {
            console.log('Embroidery imageId is missing', space.images);
            throw new Error('Embroidery imageId is missing');
          }
        }

        return {
          sku: variant.customSku,
          customer_price: Number(Number(variant.customerPrice).toFixed(2)),
          max_cost: Number(Number(variant.maxCost).toFixed(2)),
          option_values: mapOptionsValues(variant, publishData.storage, selectedSkus, true),
          images,
          gooten_mapping: {
            sku: gootenSku,
            items: [
              {
                product_id: sku.productId,
                // gooten sku
                product_variant_sku: sku.sku,
                preconfigurations: sku.spaces.map(space => ({
                  image_id:
                    space.images &&
                    space.images.length &&
                    space.images[0] &&
                    space.images[0].imageId,
                  space_id: space.id,
                  space_desc: space.name,
                  url: space.printImgUrl,
                  il: { ...space.il, backgroundColor: getBackgroundColor(sku, previewData) }
                }))
              }
            ],
            images,
            preview_url: previewUrl,
            neck_tag_id: variant.neckTagId,
            neck_tag_image_url: variant.neckTagUrl,
            orientation: publishData.orientation,
            has_main_mockup: position === 0
          }
        };
      })
    },
    tasks: []
  };

  unifyOptionsValues(request.product.variants);

  if (publishData.storage.enabled) {
    request.tasks.push(
      getStorageProductUpsertTask(publishData, selectedSkus, storageProductCandidate)
    );
  }
  publishData.stores.forEach(store => {
    if (store.enabled) {
      request.tasks.push(
        getStoreProductUpsertForEditTask(
          store,
          publishData,
          selectedSkus,
          productConnections,
          previewData,
          duplicatedSkus
        )
      );
    }
  });
  return request;
};

const getProductNameForPublish = publishData =>
  publishData.storage.enabled
    ? publishData.storage.productName
    : publishData.stores.find(x => x.enabled).productName;

const getLinkRequest = (publishData, previewData, selectedSkus, connectExistingData) => {
  let request = {
    product: {
      personalize: publishData.personalize,
      name: getProductNameForPublish(publishData),
      description_html: publishData.storage.productDesc,
      type: publishData.storage.productType,
      tags: publishData.storage.selectedTags,
      collections: publishData.storage.selectedCollections,
      images: getProductImages(selectedSkus, previewData),
      options: [],
      variants: selectedSkus.map((sku, position) => {
        const variant = publishData.storage.variants.find(v => v.index === sku.index);
        const previewUrl = getVariantImage(sku, previewData);
        const images = previewUrl.length
          ? [
              {
                id: 'generated-preview',
                index: 0,
                url: previewUrl
              }
            ]
          : [];

        // https://gooten.atlassian.net/browse/TECH-9604
        if (sku.sku.includes('-Embroidery-')) {
          const space = sku.spaces[0];
          if (space.images && space.images.length && space.images[0] && space.images[0].imageId) {
            // imageId exists
          } else {
            console.log('Embroidery imageId is missing', space.images);
            throw new Error('Embroidery imageId is missing');
          }
        }

        return {
          sku: variant.customSku,
          customer_price: Number(Number(variant.customerPrice).toFixed(2)),
          max_cost: Number(Number(variant.maxCost).toFixed(2)),
          option_values: [],
          images,
          gooten_mapping: {
            // prp-sku
            // need add date hash to prevent conflicts
            // we use Shopify SKU as base if exists or Gooten SKU
            sku: `${variant.customSku || variant.sku}-${getDateNowStr()}`,
            items: [
              {
                // gooten product id
                product_id: sku.productId,
                // gooten sku
                product_variant_sku: sku.sku,
                preconfigurations: sku.spaces.map(space => ({
                  image_id:
                    space.images &&
                    space.images.length &&
                    space.images[0] &&
                    space.images[0].imageId,
                  space_id: space.id,
                  space_desc: space.name,
                  url: space.printImgUrl,
                  il: { ...space.il, backgroundColor: getBackgroundColor(sku, previewData) }
                }))
              }
            ],
            images,
            preview_url: previewUrl,
            neck_tag_id: variant.neckTagId,
            neck_tag_image_url: variant.neckTagUrl,
            orientation: publishData.orientation,
            has_main_mockup: position === 0
          }
        };
      })
    },
    tasks: []
  };
  // TODO: Check for edit flow
  if (publishData.storage.enabled) {
    request.tasks.push({
      name: 'storage-product-upsert',
      options: {}
    });
  }

  publishData.stores.forEach(store => {
    const isLink = connectExistingData && connectExistingData.storeId === store.id;
    if (isLink && store.enabled) {
      request.tasks.push(
        getStoreProductLinkTask(store, publishData, selectedSkus, connectExistingData, previewData)
      );
    }
  });

  return request;
};

const getPublishRequest = (publishData, previewData, selectedSkus) => {
  let request = {
    product: {
      personalize: publishData.personalize,
      name: getProductNameForPublish(publishData),
      description_html: publishData.storage.productDesc,
      type: publishData.storage.productType,
      tags: publishData.storage.selectedTags,
      collections: publishData.storage.selectedCollections,
      images: getProductImages(selectedSkus, previewData),
      options: mapOptions(publishData.storage, publishData),
      variants: selectedSkus.map((sku, position) => {
        const variant = publishData.storage.variants.find(v => v.index === sku.index);
        const previewUrl = getVariantImage(sku, previewData);
        const images = previewUrl.length
          ? [
              {
                id: 'generated-preview',
                index: 0,
                url: previewUrl
              }
            ]
          : [];

        // https://gooten.atlassian.net/browse/TECH-9604
        if (sku.sku.includes('-Embroidery-')) {
          const space = sku.spaces[0];
          if (space.images && space.images.length && space.images[0] && space.images[0].imageId) {
            // imageId exists
          } else {
            console.log('Embroidery imageId is missing', space.images);
            throw new Error('Embroidery imageId is missing');
          }
        }

        return {
          sku: variant.customSku,
          customer_price: Number(Number(variant.customerPrice).toFixed(2)),
          max_cost: Number(Number(variant.maxCost).toFixed(2)),
          option_values: mapOptionsValues(variant, publishData.storage, selectedSkus),
          images,
          gooten_mapping: {
            // prp sku
            sku: variant.customSku,
            items: [
              {
                // gooten product id
                product_id: sku.productId,
                // gooten sku
                product_variant_sku: sku.sku,
                preconfigurations: sku.spaces.map(space => ({
                  image_id:
                    space.images &&
                    space.images.length &&
                    space.images[0] &&
                    space.images[0].imageId,
                  space_id: space.id,
                  space_desc: space.name,
                  url: space.printImgUrl,
                  il: { ...space.il, backgroundColor: getBackgroundColor(sku, previewData) }
                }))
              }
            ],
            images,
            preview_url: previewUrl,
            neck_tag_id: variant.neckTagId,
            neck_tag_image_url: variant.neckTagUrl,
            orientation: publishData.orientation,
            has_main_mockup: position === 0
          }
        };
      })
    },
    tasks: []
  };

  unifyOptionsValues(request.product.variants);

  if (publishData.storage.enabled) {
    request.tasks.push(getStorageProductUpsertTask(publishData, selectedSkus));
  }
  // check stores
  publishData.stores.forEach(store => {
    if (store.enabled) {
      request.tasks.push(getStoreProductUpsertTask(store, publishData, selectedSkus, previewData));
    }
  });

  return request;
};

const getDuplicateRequest = (publishData, previewData, selectedSkus, productConnections) => {
  let request = {
    product: {
      personalize: publishData.personalize,
      name: getProductNameForPublish(publishData),
      description_html: publishData.storage.productDesc,
      type: publishData.storage.productType,
      tags: publishData.storage.selectedTags,
      collections: publishData.storage.selectedCollections,
      images: getProductImages(selectedSkus, previewData),
      options: mapOptions(publishData.storage, publishData),
      variants: selectedSkus.map((sku, position) => {
        const variant = publishData.storage.variants.find(v => v.index === sku.index);
        const previewUrl = getVariantImage(sku, previewData);
        const images = previewUrl.length
          ? [
              {
                id: 'generated-preview',
                index: 0,
                url: previewUrl
              }
            ]
          : [];
        return {
          sku: variant.customSku,
          customer_price: Number(Number(variant.customerPrice).toFixed(2)),
          max_cost: Number(Number(variant.maxCost).toFixed(2)),
          option_values: mapOptionsValues(variant, publishData.storage, selectedSkus),
          images,
          gooten_mapping: {
            // prp sku
            sku: variant.customSku,
            items: [
              {
                // gooten product id
                product_id: sku.productId,
                // gooten sku
                product_variant_sku: sku.sku,
                preconfigurations: sku.spaces.map(space => ({
                  space_id: space.id,
                  space_desc: space.name,
                  url: space.printImgUrl,
                  il: { ...space.il, backgroundColor: getBackgroundColor(sku, previewData) }
                }))
              }
            ],
            images,
            preview_url: previewUrl,
            neck_tag_id: variant.neckTagId,
            neck_tag_image_url: variant.neckTagUrl,
            orientation: publishData.orientation,
            has_main_mockup: position === 0
          }
        };
      })
    },
    tasks: []
  };

  unifyOptionsValues(request.product.variants);

  if (publishData.storage.enabled) {
    request.tasks.push(getStorageProductUpsertTask(publishData, selectedSkus));
  }
  // check stores
  publishData.stores.forEach(store => {
    if (store.enabled) {
      request.tasks.push(
        getStoreProductUpsertForDuplicateTask(
          store,
          publishData,
          selectedSkus,
          productConnections,
          previewData
        )
      );
    }
  });

  return request;
};

export const publishValidationSelector = createSelector(
  [publishDataSelector],
  data => data && data.get('validation')
);

export const publishErrorsSelector = createSelector(
  [publishValidationSelector],
  validation =>
    // eslint-disable-next-line no-mixed-operators
    (validation && validation.get('errors')) || new List([])
);

const makeError = (message, storeId, skuRelated, storageRelated) => ({
  message,
  storeId,
  skuRelated,
  storageRelated
});

export const serverErrorsSelector = createSelector([publishValidationSelector], validation => {
  if (!validation) {
    return fromJS({
      errors: [],
      notificationMessage: ''
    });
  }

  let errors = [];

  // first try to find issues
  const issues = validation.get('issues');
  if (issues.get('productName')) {
    errors.push(makeError(issues.get('productName'), -1, false, true));
  }

  if (issues.get('customSku')) {
    errors.push(makeError(issues.get('customSku'), -1, true, true));
  }

  // find store errors
  validation.get('stores').forEach(s => {
    const issues = s.getIn(['issues', 'variantsSkus']);
    const failures = s.getIn(['failures', 'variantsSkus']);

    issues.forEach((issue, i) => {
      if (issue) {
        errors.push(
          makeError(
            issue + (failures.get(i) ? ` "${failures.get(i)}"` : ''),
            s.get('id'),
            true,
            false
          )
        );
      }
    });
  });

  return fromJS({
    errors,
    notificationMessage: Array.from(new Set(errors.map(err => err.message))).join(', ')
  });
});

export const associatedErrorNames = createSelector([publishDataSelector], error =>
  error.getIn(['validation', 'failures', 'productNames'])
);

const previewsSelector = state => state.get('preview');
const previewGenerationStateSelector = createSelector([previewsSelector], preview =>
  preview.getIn(['generationState', 'state'])
);

export const areMockupsLoadingSelector = createSelector(
  [previewGenerationStateSelector],
  previewGeneration => previewGeneration !== 'SUCCESS' && previewGeneration !== 'CANCELLED'
);

export const skusWithNeckTagSelector = state => {
  const skus = state.getIn(['data', 'selectedSkus']);
  const skusWithNeckTagSpace = !!skus && skus.filter(x => !!x.getIn(['template', 'NeckTagSpace']));
  return skusWithNeckTagSpace.map(x => ({
    sku: x.get('sku'),
    skuIndex: x.get('index'),
    neckTagId: x.get('neck_tag_id'),
    neckTagSpace: x.getIn(['template', 'NeckTagSpace']).toJS()
  }));
};

export const multiproductSelector = createSelector(
  [selectedProductIdsSelector],
  selectedProductIds => selectedProductIds.size > 1
);

export const backupSkusSelector = createSelector([publishStateSelector], state =>
  state?.getIn(['backup', 'skus'])
);

// locally used 'personalize' for setting checkbox state on S&P...
export const holdForPersonalizationSelector = createSelector([publishDataSelector], data =>
  data.get('personalize')
);

// holds 'personalize' option from hub products...
export const hubPersonalizationSelector = createSelector([hubSelectedProductSelector], sp =>
  sp.getIn([0, 'personalize'])
);

export const savedMainMockupSelector = createSelector([hubSelectedVariantsSelector], variants => {
  // these are variants loaded in hub. find the variant which has "has_main_mockup=true"...
  const mainMockupVariant = variants.find(
    v => v.getIn(['gootenMapping', 'hasMainMockup']) === true
  );
  return mainMockupVariant?.getIn(['gootenMapping', 'images', 0, 'url']);
});

export const disabledSkusInProductHubSelector = createSelector(
  [hubSelectedProductSelector],
  selectedProducts => {
    if (selectedProducts) {
      let dSkus = [];
      selectedProducts.forEach(sp => {
        dSkus = dSkus.concat(sp.disabledSkus);
      });
      return dSkus;
    } else {
      return [];
    }
  }
);

export const selectedSkuArchivedReasonSelector = createSelector(
  [selectedSKUsSelector],
  selectedSkus => {
    return selectedSkus?.map(selectedSku => ({
      sku: selectedSku.sku,
      archivedReason: selectedSku.archivedReason
    }));
  }
);

export const variantNeckLabelPreviewSelector = createSelector(
  [publishVariantsSelector],
  publishVariants =>
    publishVariants.map(pv => {
      return {
        sku: pv.get('sku'),
        neckTagUrl: pv.get('neckTagUrl')
      };
    })
);

export const infoSelector = createSelector([publishStateSelector], publishState =>
  publishState.get('info')
);

// check if we have any warnings to show...
export const warningDialogSelector = createSelector([infoSelector], info =>
  info.get('warningDialog')
);
