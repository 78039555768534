import {
  fetch,
  postJson,
  fileUpload,
  tryPrefillWithQueryParams
} from 'gooten-components/src/utils/http';
import _fetch from 'isomorphic-fetch';
import Config from 'gooten-components/src/config';

const getAuthQueryParams = () => ({
  Authorization: 'Bearer ' + Config.get('adminApiToken')
});

const getQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId')
  }
});

class ProductionReportService {
  getReportData(partnerId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/partnerProductsApi/getProductionTime?partnerId=${partnerId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }
}

// singleton
export default new ProductionReportService();
