import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';

const Tooltip = ({
  className,
  containerClassName,
  children,
  content,
  isToggleable,
  onClick,
  position,
  disabled,
  light,
  long,
  tooltipChildren
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [positionClass, setPositionClass] = React.useState('');
  const [containerInfo, setContainerInfo] = React.useState(null);
  const nodeRef = React.useRef(null);

  const getContainerViewInfo = React.useCallback(
    e => {
      if (e !== null) {
        setContainerInfo(e.getBoundingClientRect());
      }
    },
    [showTooltip]
  );

  const getTooltipViewInfo = React.useCallback(
    e => {
      if (e !== null) {
        nodeRef.current = e;

        if (containerInfo) {
          const { top: t, height: h, width: w, left: l, right: r } = e.getBoundingClientRect();
          const visibleTop = t <= window.innerHeight && containerInfo.top - h - 92 >= 0;
          const visibleLeft = l <= window.innerWidth && containerInfo.left - w - 92 >= 0;
          const visibleRight = r <= window.innerWidth && containerInfo.right - w - 92 >= 0;
          if (!visibleTop) {
            setPositionClass('bottom-fixed');
          } else if (!visibleLeft) {
            setPositionClass('right-fixed');
          } else if (!visibleRight) {
            setPositionClass('left-fixed');
          } else {
            setPositionClass('top-fixed');
          }
        }
      }
    },
    [showTooltip]
  );

  // Close the tooltip when clicking off
  React.useEffect(() => {
    const clickOffHandler = e => setShowTooltip(false);

    document.addEventListener('mousedown', clickOffHandler);
    return () => document.removeEventListener('mousedown', clickOffHandler);
  }, [nodeRef]);

  React.useEffect(() => {
    if (nodeRef.current) {
      nodeRef.current.addEventListener('mousedown', e => e.stopPropagation());
    }
  }, [nodeRef.current]);

  const hasContent = content || tooltipChildren;

  const closeFn = () => setShowTooltip(false);

  const handlers =
    isToggleable && hasContent
      ? {
          onClick: () => setShowTooltip(!showTooltip)
        }
      : {
          onMouseEnter: () => setShowTooltip(true),
          onMouseLeave: closeFn
        };

  return (
    <div
      ref={getContainerViewInfo}
      className={`tooltip-container ${containerClassName || ''}`}
      {...handlers}
    >
      {showTooltip && hasContent && (
        <div
          ref={getTooltipViewInfo}
          onClick={e => {
            e.stopPropagation();
            onClick && onClick(e);
          }}
          className={`${className || ''} cerulean-tooltip caption-text ${
            position ? position + '-fixed' : positionClass
          }`}
          dangerouslySetInnerHTML={content ? { __html: content } : undefined}
        >
          {tooltipChildren && tooltipChildren(closeFn)}
        </div>
      )}
      {children}
      <style jsx>
        {`
          .cerulean-tooltip {
            display: flex;
            border-radius: 4px;
            min-height: 2rem;
            padding: 0.5rem 0.75rem;
            width: max-content;
            min-width: fit-content;
            max-width: ${long ? '20rem' : '10rem'};
            color: ${light ? COLORS.neutralDark500 : COLORS.neutralLight50};
            background: ${light ? COLORS.neutralLight50 : COLORS.neutralDark500};
            align-items: center;
            position: absolute;
            z-index: 1050;
            text-align: center;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
          }
          :global(.tooltip-container .cerulean-tooltip a) {
            color: ${COLORS.neutralLight50};
            text-decoration: underline;
          }
          .tooltip-container:hover .cerulean-tooltip {
            display: ${!disabled && content ? 'flex' : 'none'};
          }
          .top-fixed {
            bottom: 100%;
            left: 50%;
            transform: translateX(-65%);
          }
          .right-fixed {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
          .left-fixed {
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
          .bottom-fixed {
            top: 100%;
            left: 50%;
            transform: translateX(-65%);
          }
          .tooltip-container {
            position: relative;
            width: fit-content;
          }
        `}
      </style>
    </div>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  isToggleable: PropTypes.bool,
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  content: PropTypes.any,
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  long: PropTypes.bool,
  tooltipChildren: PropTypes.func
};

export default Tooltip;
