import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import StatusViewService from './StatusViewService';
import { updateAcceptableStatuses, GET_ACCEPTABLE_STATUSES } from './StatusViewActions';

export function* handleAcceptableStatusesRequest(action) {
  let statusesData = yield call(
    [StatusViewService, StatusViewService.fetchAcceptableStatuses],
    action.payload.orderId
  );
  yield put(updateAcceptableStatuses(fromJS(statusesData)));
}

export function* watchAcceptableStatus() {
  yield takeLatest(GET_ACCEPTABLE_STATUSES, handleAcceptableStatusesRequest);
}

export default function* rootSaga() {
  yield all([watchAcceptableStatus()]);
}
