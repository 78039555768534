import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';
import Modal from 'gooten-components/src/components/shared/Modal';
import Dialogue from 'gooten-components/src/components/shared/Dialogue';
import TextInput from 'gooten-components/src/components/shared/TextInput';
import Input from 'gooten-components/src/components/shared/Input';
import { COLORS } from 'gooten-components/src/constants';
import security from '../../../SecurityService';
import { emailRegex } from 'gooten-components/src/utils/regex';

const DISABLED_EMAIL = '[disabled]';

const FulfillmentContactEmails = props => {
  const emailInput = useRef();
  const readOnly = !security.isPartnerOwner();
  const [addRecipientModalOpen, toggleAddRecipientModal] = useState(false);
  const [deleteRecipientModalOpen, toggleDeleteRecipientModal] = useState(false);
  const [deleteRecipientEmail, setDeleteRecipientEmail] = useState(undefined);
  const [addRecipientEmail, setAddRecipientEmail] = useState(undefined);

  const deleteRecipient = () => {
    if (deleteRecipientEmail) {
      props.deleteFulfillmentContactEmail(deleteRecipientEmail);
    }
    toggleDeleteRecipientModal(false);
  };

  const disableRecipient = email => {
    props.disableFulfillmentContactEmail(email);
  };

  const enableRecipient = email => {
    props.enableFulfillmentContactEmail(email);
  };

  const addRecipient = () => {
    if (emailInput.current.isValid()) {
      props.addFulfillmentContactEmail(addRecipientEmail);
      setAddRecipientEmail(undefined);
      toggleAddRecipientModal(false);
    } else {
      emailInput.current.validate();
    }
  };

  const closeAddRecipientModal = () => {
    // reset the input state
    setAddRecipientEmail(undefined);
    toggleAddRecipientModal(false);
  };

  const showDeleteRecipientModal = email => {
    setDeleteRecipientEmail(email);
    toggleDeleteRecipientModal(true);
  };

  const renderEmail = email =>
    email.startsWith(DISABLED_EMAIL) ? email.replace(DISABLED_EMAIL, '') : email;

  const renderFulfillmentContacts = () => {
    if (props.emails.length) {
      const lastIdx = props.emails.length - 1;
      return props.emails.map((email, idx) => {
        const disabled = email.startsWith(DISABLED_EMAIL);
        return (
          <React.Fragment key={email}>
            <div className="row">
              <div className="col-xs-12 item-line-row">
                <div className="row">
                  <div className="col-md-9 col-sm-9 col-xs-9">
                    <span
                      style={{ color: disabled ? COLORS.neutralDark200 : COLORS.neutralDark600 }}
                    >
                      {renderEmail(email)}
                    </span>
                  </div>
                  <div className="col-sm-3 col-xs-3">
                    <div className="pull-right">
                      <Button
                        className="button-default medium mr-2"
                        disabled={readOnly}
                        onClick={() =>
                          disabled ? enableRecipient(email) : disableRecipient(email)
                        }
                      >
                        {disabled ? 'Enable' : 'Disable'}
                      </Button>
                      <Button
                        iconOnly
                        disabled={readOnly}
                        className="button-default medium"
                        onClick={() => showDeleteRecipientModal(email)}
                      >
                        <Icon icon="xCircle" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {idx === lastIdx ? null : <hr className="section-line mb-2 mt-2" />}
          </React.Fragment>
        );
      });
    } else {
      return <p>No recipients</p>;
    }
  };

  return (
    <>
      <Modal
        isOpen={addRecipientModalOpen}
        title={'Add recipient'}
        className="small"
        cancelClick={closeAddRecipientModal}
        cancelText="Cancel"
        primaryClick={addRecipient}
        actionText="Add recipient"
      >
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-xs-12">
              {addRecipientModalOpen && (
                <TextInput
                  id="email"
                  isRequired
                  autoFocus
                  emptyMsg={'Field required'}
                  showValidation
                  regexp={emailRegex}
                  validationMsg={'Invalid email address'}
                  maxLength={69}
                  label={'Email'}
                  value={addRecipientEmail}
                  onChange={setAddRecipientEmail}
                  ref={emailInput}
                />
              )}

              {/* <Input
                label="Email"
                icon={'mail'}
                iconPlacement={'leading'}
                hasError
                errorText={true ? 'Invalid email address' : null}
                onBlur={e => setAddRecipientEmail(e.target.value)}
              /> */}
            </div>
          </div>
        </div>
      </Modal>
      <Dialogue
        title="Delete order notification"
        isOpen={deleteRecipientModalOpen}
        cancelClick={() => toggleDeleteRecipientModal(false)}
        destructiveClick={deleteRecipient}
        actionText="Delete"
      >
        <span>Are you sure you want to delete this notification?</span>
      </Dialogue>
      <hr className="section-line mb-2" />
      <div className="row">
        <div className="col-xs-12">
          <h5 className="notification-label">Critical Order Notifications</h5>
          <p className="notification-description mb-4">
            If a critical issue is preventing orders from processesing we will notify all recipients
            listed below.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-sm-9 col-xs-9">
          <b>Recipients</b>
        </div>
        <div className="col-sm-3 col-xs-3">
          <Button
            className="button-default medium pull-right"
            disabled={readOnly}
            onClick={() => toggleAddRecipientModal(true)}
          >
            Add recipient
          </Button>
        </div>
      </div>
      <hr className="section-line mb-2 mt-2" />
      {renderFulfillmentContacts()}
    </>
  );
};

// TODO: Check for permissions to show
//  validation email
// cleanup

FulfillmentContactEmails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
  addFulfillmentContactEmail: PropTypes.func.isRequired,
  deleteFulfillmentContactEmail: PropTypes.func.isRequired,
  disableFulfillmentContactEmail: PropTypes.func.isRequired,
  enableFulfillmentContactEmail: PropTypes.func.isRequired
};

export default FulfillmentContactEmails;
