import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const withRouteStep = (WrappedComponent, step) => {
  class RouteStepHOC extends React.Component {
    static propTypes = {
      router: PropTypes.object
    };

    componentDidMount() {
      if (this.props.router && this.props.router.params.step !== step) {
        this.props.router.replace(
          this.props.router.params.step
            ? this.props.router.location.pathname.replace(this.props.router.params.step, step)
            : `${this.props.router.location.pathname}/${step}`
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return withRouter(RouteStepHOC);
};

export default withRouteStep;
