import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from '../BaseCard';
import Icon from 'gooten-components/src/components/shared/Icon';
import Button from 'gooten-components/src/components/shared/Button';

const Container = styled.div`
  .col-xs-9.title-row {
    padding: 0;
  }
`;

class EmailServices extends React.Component {
  static get name() {
    return 'emailServices';
  }
  static get messages() {
    return {
      onInitFailure: 'Failed to load Email services info',
      onFailure: 'Failed to update  Email services',
      onSuccess: 'Your  Email services has been updated'
    };
  }

  componentDidMount() {
    this.props.init();
  }

  toggle(flag) {
    const newValue = !this.props.data.get(flag);
    this.props.change(newValue, flag);
    this.props.update({ data: this.props.data.set(flag, newValue) });
  }

  renderInput(flag, title) {
    // const btnStyle = this.props.data.get(flag) ? 'success' : 'default'
    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="col-md-2 col-xs-3">
            <Icon
              className="mt-n2"
              icon={'mail'}
              width={48}
              height={48}
              fill={this.props.data.get(flag) ? '#000000' : '#AAAAAA'}
            />
          </div>
          <div className="col-sm-5 col-xs-9 title-row">
            <h5>{title}</h5>
          </div>
          <Button
            className={`button-default large col-sm-3 col-xs-12 pull-right col-lg-2`}
            disabled={!this.props.ready}
            onClick={() => this.toggle(flag)}
          >
            {this.props.data.get(flag) ? 'Stop Emails' : 'Install'}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <BaseCard card={EmailServices.name} title="Automated Email Services">
        <Container>
          <div className="email-services-card">
            <div className="row">
              <div className="col-xs-12">
                <p className="card-description">
                  If you are interested, we can send your customers order and shipping confirmation
                  emails on your behalf through either the Gooten mail services or through your mail
                  service provider. You'll be able to customize the emails with your logo and email
                  address and turn this service off at any point.
                </p>
              </div>
            </div>
            <br />
            {this.renderInput('SendConfirmation', 'Send Order Confirmation Emails')}
            <hr />
            {this.renderInput('SendShipping', 'Send Shipping Confirmation Emails')}
          </div>
        </Container>
      </BaseCard>
    );
  }
}

EmailServices.propTypes = {
  init: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  data: PropTypes.object,
  ready: PropTypes.bool
};

export default EmailServices;
