import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { TEST_CONNECTION, updateEmailsData } from './EmailCredentialsActions';
import { cardsSelector } from '../BaseCard/BaseCardSelectors';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { updateActionNameFor } from '../BaseCard/BaseCardActions';
import EmailCredentials from './EmailCredentials';
import EmailServices from '../EmailServices/EmailServices';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';

const emailServicesUpdate = updateActionNameFor(EmailServices.name);

export function* testConnectionAsyncHandler(action) {
  let result = null;
  try {
    const cards = yield select(cardsSelector);
    const request = {
      // ...cards.getIn(['emailServices', 'unmodified']).toJS(),
      ...cards.getIn(['emailCredentials', 'data']).toJS()
    };
    result = yield call(settingsApiService.testConnection, request);
    if (result.error) {
      yield put(fetchAsyncFail(TEST_CONNECTION, { error: result.error.message }));
    } else {
      yield put(fetchAsyncSuccess(TEST_CONNECTION, action.payload));
    }
  } catch (err) {
    yield put(fetchAsyncFail(TEST_CONNECTION, err));
    yield put(push('Failed to test connection', 'failure'));
  }
}

export function* watchTestConnection() {
  yield takeLatest(TEST_CONNECTION.ASYNC, testConnectionAsyncHandler);
}

const update = updateActionNameFor(EmailCredentials.name);

export function* UpdateCardAsyncHandler(action) {
  try {
    const cards = yield select(cardsSelector);
    const data = cards.getIn(['emailCredentials', 'data']);
    const request = {
      ...cards.getIn(['emailServices', 'unmodified']).toJS(),
      ...data.toJS()
    };
    yield call(settingsApiService.updateNotificationSettings, request, 'emailCustomers');
    yield put(fetchAsyncSuccess(update, { data }));
    yield put(push('Emails content updated', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(update, err));
    yield put(push('Failed to update Emails content', 'failure'));
  }
}

export function* watchUpdateCard() {
  yield takeLatest(update.ASYNC, UpdateCardAsyncHandler);
}

export function* UpdateEmailServicesHandler(action) {
  const cards = yield select(cardsSelector);
  const emailServicesData = cards.getIn(['emailServices', 'data']);
  // Mail method should be selected if someone from email notifications turned on
  const mailMethod =
    (emailServicesData.SendConfirmation || emailServicesData.SendShipping) &&
    cards.getIn(['emailCredentials', 'data', 'MailMethod']) === 0
      ? 1
      : cards.getIn(['emailCredentials', 'data', 'MailMethod']);
  const ordersEmail =
    emailServicesData.SendConfirmation && !cards.getIn(['emailCredentials', 'data', 'OrdersEmail'])
      ? cards.getIn(['emailCredentials', 'data', 'PartnerContactEmail'])
      : cards.getIn(['emailCredentials', 'data', 'OrdersEmail']);
  const shippingEmail =
    emailServicesData.SendShipping && !cards.getIn(['emailCredentials', 'data', 'ShippingEmail'])
      ? cards.getIn(['emailCredentials', 'data', 'PartnerContactEmail'])
      : cards.getIn(['emailCredentials', 'data', 'ShippingEmail']);

  yield put(updateEmailsData(mailMethod, ordersEmail, shippingEmail));
}

export function* watchEmailServicesUpdate() {
  yield takeLatest(emailServicesUpdate.SUCCESS, UpdateEmailServicesHandler);
}

export default function* rootSaga() {
  yield all([watchUpdateCard(), watchTestConnection(), watchEmailServicesUpdate()]);
}
