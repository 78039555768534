import { connect } from 'react-redux';
import ProductDetails from './ProductDetails';
import {
  getProductName,
  getProductCategory,
  getProductId,
  getMinPrice,
  getMaxPrice,
  getCheapestShipping,
  getProductImages,
  getProductOptions
} from './ProductDetailsSelectors';
import { next } from '../../../../containers/NavManager/NavManagerActions';
import { selectProducts } from '../../../../store/actions/dataActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  return {
    productName: getProductName(state),
    productCategory: getProductCategory(state),
    productId: getProductId(state),
    minPrice: getMinPrice(state),
    maxPrice: getMaxPrice(state),
    cheapestShipping: getCheapestShipping(state),
    productImages: getProductImages(state),
    productOptions: getProductOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    gotoNextStep: () => {
      // NOTE goto SKUSelection
      dispatch(next());
    },
    selectProduct: productName => {
      dispatch(selectProducts([productName]));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
