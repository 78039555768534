import { FETCH_BILLING_DATA } from './BillingViewActions';
import initialState from '../../../store/initialState';
import { fromJS, List } from 'immutable';

const defaultState = initialState.getIn(['orders', 'billingView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_BILLING_DATA.ASYNC:
      return state
        .set('loading', true)
        .set('errors', List([]))
        .set('costBreakdown', List([]))
        .set('orderBilling', {})
        .set('userState', {});
    case FETCH_BILLING_DATA.SUCCESS:
      return state
        .set('loading', false)
        .set('costBreakdown', List(action.payload.costBreakdown))
        .set('orderBilling', fromJS(action.payload.orderBilling))
        .set('userState', fromJS(action.payload.getState));
    case FETCH_BILLING_DATA.FAIL:
      return state
        .set('loading', false)
        .set('errors', List(['There was a problem loading the billing data']));
    default:
      return state;
  }
};
