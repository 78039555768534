import { List, fromJS } from 'immutable';
import { getDateNowStr } from '../../../../gooten-components/src/utils/random';

const getMetaOption = (product, option) =>
  (product.meta.find(m => m.name === option) || { value: '' }).value;

/**
 * Map `publish.data.storage` or `publish.data.stores[i]` state
 * @param {Object} realProduct - (nullable) Real storage or store product
 * If map storage product - this will contains real storage product data
 * If map store product - this will contains real store product data
 * NOTE: ===> Can be null - if it is not exist or can't be restored.
 * @param {Object} product - Real product or currently edited product whatever it is.
 * NOTE: ===> It may contain data from storage but used to restore store product state.
 * @param {Array} variants - Real product variants or currently edited product variants.
 */
const fillData = (realProduct, product, variants, isStorageProduct) => {
  return {
    // map only from real product
    productId: realProduct ? realProduct.id : null,
    productName: realProduct ? `${realProduct.name} (Copy)` : `${product.name} (Copy)`,
    productDesc: (realProduct ? realProduct.description : product.description) || '',
    productType: getMetaOption(product, 'product_type'),
    selectedTags: product.tags,
    selectedCollections: product.collections,
    // set only from real product
    // default selected options will be filled on publish step
    selectedOptions: !realProduct
      ? null
      : realProduct.options.map(o => ({ id: o.id, value: o.id })),
    // Make sure to map id's and SKUs only from real product variants
    variants: variants.map((variant, index) => {
      return {
        id: realProduct ? variant.id : null,
        // Real Gooten UniqueProduct SKU
        sku: variant.gootenMapping.items[0].sku,
        index,
        customSku: isStorageProduct
          ? `${variant.gootenMapping.sku.replace(/-\d+$/, '')}-${getDateNowStr()}`
          : // autofill store sku if empty
            `${variant.sku.replace(/-\d+$/, '')}-${getDateNowStr()}` ||
            `${variant.gootenMapping.items[0].sku.replace(/-\d+$/, '')}-${getDateNowStr()}`,
        customerPrice: variant.price ? variant.price.price : null
      };
    })
  };
};

class DuplicateProductViewService {
  getSpaceData(preconfig) {
    return {
      id: preconfig.spaceId,
      il: preconfig.il,
      images: [{ imageUrl: preconfig.url }]
    };
  }

  getSelectedSkusData(product, variants) {
    let gootenMappings = variants.map(v => v.gootenMapping);

    // NOTE: Only 1 item in gooten mapping is allowed now
    let preConfigs = gootenMappings.map(v => v.items[0]);
    let skus = preConfigs.map((s, i) => ({
      // this is Gooten UniqueProduct SKU
      sku: s.sku,
      spaces: s.preconfigurations.map(p => this.getSpaceData(p))
    }));
    return skus;
  }

  getPublishData(product, variants, stores, connections) {
    // NOTE: Upon publishing products we allow to publish it to any store and storage
    // In that case we create 1 set of PRP's which are connected to these published products
    // Upon editing we trying to resolve all these connections and restore publish component
    // to allow update all products in all places
    // ---
    // There are some restrictions which may prevent us restore it:
    // 1. If products were edited and some variants removed
    //    In that case we just re-store currently edited product and don't restore it's connections
    // 2. Storage product was edited separately via API or CSV Import
    // ---
    // Rules:
    // Upon restoring connections it is very important to follow these rules:
    // 1. We restoring `publish.data` state where:
    //    `publish.data.storage` - is actually storage product which exists or potentially can be created
    //    `publish.data.storage.variants` - contains items which are PRP's
    // 2. `publish.data.stores` - contains data for each store product
    //    `publish.data.stores.variants` - contains items which are store variants
    //    `publish.data.stores.variants.gooten_mapping` - is actually PRP data

    // Combine all valid connections with edited product for convenience
    const validConnections = [product, ...connections];

    // Try to get storage product from the connections or use edited product whatever it is
    const realStorageProduct = validConnections.find(c => c.type === 'storage');
    const storageProductCandidate = realStorageProduct || product;
    const storageProductVariantsCandidate = storageProductCandidate.variants.length
      ? storageProductCandidate.variants
      : variants;

    // If real storage product found, it may not have some data (tags, colletions, meta, prices)
    // Try to add this data from any connected store product
    if (storageProductCandidate.type === 'storage') {
      const connectedStoreProduct =
        product.type === 'store' ? product : validConnections.find(c => c.type === 'store');
      if (connectedStoreProduct) {
        storageProductCandidate.tags = connectedStoreProduct.tags;
        storageProductCandidate.collections = connectedStoreProduct.collections;
        storageProductCandidate.meta = connectedStoreProduct.meta;

        const storeProductVariants = connectedStoreProduct.variants.length
          ? connectedStoreProduct.variants
          : variants;

        // map price from store product variants match by gooten_mapping id
        storageProductVariantsCandidate.forEach(v => {
          if (!v.price) {
            const matchedVariant = storeProductVariants.find(va => va.gootenMapping.id === v.id);
            if (matchedVariant) {
              v.price = matchedVariant.price;
            }
          }
        });
      }
    }

    // Restore `publish.data` state
    const data = {
      storage: {
        ...fillData(
          realStorageProduct,
          storageProductCandidate,
          storageProductVariantsCandidate,
          true
        ),
        selected: storageProductCandidate.type === 'storage',
        // All uniq tag names from all stores, including selected ones
        tags: Array.from(
          new Set(
            storageProductCandidate.tags.concat(
              Array.from(
                stores
                  .toJS()
                  .reduce((tags, store) => new Set([...tags, ...store.tags.map(t => t.name)]), '')
              )
            )
          )
        ),
        // Storafe does not support collections.
        collections: []
        // NOTE: storage product can't has own options
        //       his options will be filled by common options on publish step
      },
      stores: stores
        ? stores.map(store => {
            const realStoreProduct = validConnections.find(c => c.storeId === store.get('id'));
            const storeProductCandidate = realStoreProduct || product;

            let mappedStore = {
              id: store.get('id'),
              name: store.get('storeName'),
              // Etsy store is not active, can't have published products, only unpublished (drafts)
              inactive:
                store.get('provider').toLowerCase() === 'etsy' &&
                store.getIn(['settings', 'about', 'status']) !== 'active',
              provider: store.get('provider'),
              // set only if product exist in store to override common options
              options: !realStoreProduct
                ? null
                : realStoreProduct.options.map(o => ({
                    id: o.id,
                    title: o.name
                  })),
              ...fillData(
                realStoreProduct,
                storeProductCandidate,
                storeProductCandidate.variants.length ? storeProductCandidate.variants : variants
              ),
              selected:
                storeProductCandidate.type === 'store' &&
                storeProductCandidate.storeId === store.get('id'),
              draft: true,
              collections: store.collections,
              updatePreview: true // always enabled by default
            };

            return fromJS(mappedStore);
          })
        : []
    };

    data.validation = {
      errors: [],
      issues: {
        variantsSkus: storageProductVariantsCandidate.map(() => null)
      },
      failures: {
        variantsSkus: [],
        productNames: []
      },
      stores: data.stores.map(s =>
        fromJS({
          id: s.get('id'),
          failures: {
            variantsSkus: [],
            productNames: []
          },
          issues: {
            variantsSkus: s.get('variants').map(() => null)
          }
        })
      )
    };

    return data;
  }

  filterValidConnections(connections$, variants) {
    // Filter out connections which has diff variants then actual edited product
    let invalidConnections = [];
    const editedProductVariantsSKUsHash = variants
      .map(v => v.gootenMapping.sku)
      .sort()
      .join(',');
    const validConnections$ = new List(
      connections$.filter(c => {
        const currentProductVariantsSKUsHash = c
          .get('variants')
          .map(v => v.getIn(['gootenMapping', 'sku']))
          .sort()
          .join(',');
        // compare variants.gooten_mapping.skus hash
        if (currentProductVariantsSKUsHash === editedProductVariantsSKUsHash) {
          return true;
        } else {
          invalidConnections.push(c.toJS());
          return false;
        }
      })
    );

    if (invalidConnections.length) {
      console.warn(
        'Some product connections are not supported anymore, since they have different variants',
        invalidConnections
      );
    }

    return validConnections$;
  }
}

// singleton
export default new DuplicateProductViewService();
