import React from 'react';
import PropTypes from 'prop-types';
import './ColorsPreview.scss';

export const ColorsPreview = ({ colors }) => {
  if (colors) {
    return (
      <div className="component-colors-preview">
        {colors.map((color, i) => {
          if (typeof color.hex === 'string') {
            return (
              <div
                className="color-item flex-inline-container"
                style={{ backgroundColor: color.hex }}
                key={i}
              />
            );
          } else if (color.hex instanceof Array) {
            return (
              <div className="two-color-item flex-inline-container" key={i}>
                <div className="color-one" style={{ backgroundColor: color.hex[0] }} />
                <div className="color-two" style={{ backgroundColor: color.hex[1] }} />
              </div>
            );
          } else if (typeof color === 'string') {
            return (
              <div className="color-item-text" key={i}>
                {color}
              </div>
            );
          } else if (typeof color.name === 'string') {
            return (
              <div className="color-item-text" key={i}>
                {color.name}
              </div>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

ColorsPreview.propTypes = {
  colors: PropTypes.array.isRequired
};

ColorsPreview.displayName = 'ColorsPreview';

export default ColorsPreview;
