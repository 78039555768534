import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../LoadingSpinner';

export const Spinner = props => (
  <>
    <LoadingSpinner
      size={40}
      className={`${props.customClass || ''} spinner-container ${props.inline ? 'inline' : ''} `}
    />
  </>
);

Spinner.propTypes = {
  inline: PropTypes.bool,
  customClass: PropTypes.string
};

export default Spinner;
