import { ActionNameFactory } from '../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'STORES');

// ------------------------------------
// Constants
// ------------------------------------
export const RESET = getActionName('RESET');
export const STORES_FETCH = getActionName.async('STORES_FETCH');
export const STORES_CONNECT_STEP = getActionName('STORES_CONNECT_STEP');
export const STORES_CONNECT_PROVIDER = getActionName('STORES_CONNECT_PROVIDER');
export const INIT_CONNECT = getActionName.async('INIT_CONNECT');
export const STORE_CONNECT = getActionName.async('STORE_CONNECT');
export const WOO_STORE_CONNECT = getActionName.async('WOO_STORE_CONNECT');
export const STORE_LOG_OUT = getActionName.async('STORE_LOG_OUT');
export const STORE_DISCONNECT_PROMPT = getActionName('STORE_DISCONNECT_PROMPT');
export const STORE_DISCONNECT_CANCEL = getActionName('STORE_DISCONNECT_CANCEL');

// ------------------------------------
// Actions
// ------------------------------------
export function reset() {
  return {
    type: RESET
  };
}

export function storesFetch() {
  return {
    type: STORES_FETCH.ASYNC
  };
}

export function storesFetchSuccess(stores) {
  return {
    type: STORES_FETCH.SUCCESS,
    payload: { stores }
  };
}

export function storesFetchFail(errorMsg) {
  return {
    type: STORES_FETCH.FAIL,
    payload: { errorMsg }
  };
}

export function storesConnectStep(step) {
  return {
    type: STORES_CONNECT_STEP,
    payload: { step }
  };
}

export function storesConnectProvider(provider) {
  return {
    type: STORES_CONNECT_PROVIDER,
    payload: { provider }
  };
}

export function initStoreConnect(provider) {
  return {
    type: INIT_CONNECT.ASYNC,
    payload: { provider }
  };
}

export function connectStore(provider, store) {
  return {
    type: STORE_CONNECT.ASYNC,
    payload: { provider, store }
  };
}

export function connectStoreSuccess(store) {
  return {
    type: STORE_CONNECT.SUCCESS,
    payload: { store }
  };
}

export function connectStoreFail(errorMsg) {
  return {
    type: STORE_CONNECT.FAIL,
    payload: { errorMsg }
  };
}

export function connectStoreCancel() {
  return {
    type: STORE_CONNECT.CANCEL,
    payload: {}
  };
}

export function storeDisconnectPrompt(provider, storeId) {
  return {
    type: STORE_DISCONNECT_PROMPT,
    payload: { provider, storeId }
  };
}

export function storeDisconnectCancel() {
  return {
    type: STORE_DISCONNECT_CANCEL
  };
}

export function storeLogOut(storeId) {
  return {
    type: STORE_LOG_OUT.ASYNC,
    payload: { storeId }
  };
}

export function storeLogOutSuccess() {
  return {
    type: STORE_LOG_OUT.SUCCESS
  };
}

export function storeLogOutFail(errorMsg) {
  return {
    type: STORE_LOG_OUT.FAIL,
    payload: { errorMsg }
  };
}
