import { delay } from 'redux-saga';
import { all, takeLatest, put, select, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { searchFields, ordersList } from './OrdersViewSelectors';
import OrdersViewService from './OrdersViewService';
import {
  updateSearchData,
  UPDATE_PAGE_NUMBER,
  UPDATE_TAB_NUMBER,
  SEARCH_START,
  UPDATE_SEARCH_FIELDS,
  UPDATE_SEARCH_FIELDS_OBJECT,
  UPDATE_SEARCH_FIELDS_OBJECT_INITIAL,
  CHANGE_STATUS,
  CHANGE_TRACKING,
  UPDATE_ORDER_ORDER_ITEM,
  changeStatusSuccess,
  changeStatusFail,
  changeTrackingSuccess,
  updateSearchDataFail,
  UPDATE_PERSONALIZED_IMAGES,
  updatePersonalizedImagesSucess,
  updatePersonalizedImagesFail,
  toggleSelectedRows
} from './OrdersViewActions';
import { allStatuses } from '../OrderDetails/StatusView/allStatuses';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import OrdersDetailsService from 'gooten-components/src/services/orderDetailsService';

export function* handleSearchRequest() {
  try {
    yield call(delay, 500);
    const searchFieldsData = yield select(searchFields);

    // Parse the reducer data for api
    const data = {
      searchOrders: {},
      searchOrderItems: {}
    };

    const searchFieldsDataObject = searchFieldsData.toJS();

    // Used for parsing ids for request when selection is made in the filters
    // The else in this function passes the key from the url when a shared url is used
    if (searchFieldsDataObject.PartnerIds.length) {
      searchFieldsDataObject.PartnerIds = searchFieldsDataObject.PartnerIds.map(item => {
        if (item instanceof Object) {
          return item.key;
        } else {
          return item;
        }
      });
    }

    if (searchFieldsDataObject.itemsMode === 'OrderItem') {
      delete data.searchOrders;
    } else {
      delete data.searchOrderItems;
    }

    for (const property in searchFieldsDataObject) {
      const filterData = ['group', 'itemsType', 'page', 'pageSize'];

      if (filterData.includes(property)) {
        data[property] = searchFieldsDataObject[property];
      } else {
        if (searchFieldsDataObject.itemsMode === 'OrderItem') {
          data.searchOrderItems[property] = searchFieldsDataObject[property];
        } else {
          data.searchOrders[property] = searchFieldsDataObject[property];
        }
      }
    }

    const ordersListData = yield call([OrdersViewService, OrdersViewService.fetchOrderData], data);

    yield put(updateSearchData(fromJS(ordersListData)));
  } catch (error) {
    yield put(updateSearchDataFail(['There was an issue retrieving the orders list']));
  }
}

export function* handleChangeStatusRequest(action) {
  const ordersListData = yield select(ordersList);
  const allItems = ordersListData.get('items').toJS();

  const selectedItems = allItems.filter(item => item.selectedByUser).map(x => x.OrderItemId);
  const selectedItemsOrderIds = allItems.filter(item => item.selectedByUser).map(x => x.OrderId);

  const bodyData = { ids: selectedItems, statusId: action.payload.statusId };

  function* ordersWithlastCustomizationItem() {
    // NOTE: This logic is for the edge case where a partner cancels/holds
    // the last item in a needs personalization order. It would still trigger the
    // had_customization endpoint
    let ordersToSendToHadCustomization = [];
    let orders = {};

    const selected = allItems.filter(item => item.selectedByUser);

    selected.forEach(item => {
      orders[item.OrderId] = orders[item.OrderId]
        ? [...orders[item.OrderId], item.OrderItemId]
        : [item.OrderItemId];
    });

    for (let order in orders) {
      const itemsData = yield call(
        [OrdersDetailsService, OrdersDetailsService.getItemsData],
        order
      );

      const itemsInOneOrder = itemsData.reduce((a, b) => a.concat(b.Items), []);
      // 35 is needsPersonalization
      const itemsNeedingPersonalization = itemsInOneOrder.filter(x => x.StatusId === 35).length;

      if (orders[order].length === itemsNeedingPersonalization) {
        // check to see if needsPersonalization items are last items
        ordersToSendToHadCustomization.push(order);
      }
    }
    return ordersToSendToHadCustomization;
  }

  // Handle for when partner manually changes from NeedsCusomization to SendToProduction (FakeReadyToPrint)
  function* handleCustomization(orderIds, showSuccessToken) {
    try {
      yield call([OrdersViewService, OrdersViewService.hadCustomization], {
        ids: orderIds
      });
    } catch (err) {
      if (err.Message) {
        yield put(changeStatusFail([`${err.Message}. Cannot update this to ready to print.`]));
      } else {
        // The success action is in error catch because API returns nothing when successful
        if (showSuccessToken) {
          yield put(push('Updated status to ready to print'));
        }

        const updatedOrderList = ordersListData
          .get('items')
          .toJS()
          .map(item => {
            if (item.selectedByUser) {
              item.OrderStatusId = 26;
            }
            return item;
          });

        yield put(changeStatusSuccess(updatedOrderList));
      }
    }
  }

  if (bodyData.statusId === 'FakeReadyToPrint') {
    yield call(handleCustomization, [...new Set(selectedItemsOrderIds)], true);
  } else {
    try {
      // NOTE: Only check if we need to use hadCustomization endpoint if
      // order is set to cancelled (34) or hold (31). Check before status change
      let ordersToSendToHadCustomization = null;
      if ([31, 34].includes(action.payload.statusId)) {
        const ordersNP = yield call(ordersWithlastCustomizationItem);
        ordersToSendToHadCustomization = ordersNP;
      }
      // ***

      const changedItems = yield call(
        [OrdersViewService, OrdersViewService.changeStatus],
        bodyData
      );

      const chosenStatus = allStatuses.find(x => x.Id === action.payload.statusId).Name;

      if (changedItems.FailedItems.length) {
        const error = `Fail: Status cannot be changed to '${chosenStatus}' for item(s) ${changedItems.FailedItems.join(
          ', '
        )}`;
        yield put(push(error));
      }

      const changedItemsWithoutFailedItems = selectedItems.filter(
        it => !changedItems.FailedItems.includes(it)
      );

      const updatedOrderList = ordersListData
        .get('items')
        .toJS()
        .map(item => {
          if (item.selectedByUser && changedItemsWithoutFailedItems.includes(item.OrderItemId)) {
            item.OrderStatusId = action.payload.statusId;
          }
          return item;
        });

      // NOTE: Use hadcustomization endpoint after status change
      if (ordersToSendToHadCustomization?.length) {
        yield call(handleCustomization, ordersToSendToHadCustomization);
      }
      // ***

      yield put(
        push(`Status updated to '${chosenStatus}' for item(s) ${bodyData.ids?.join(', ')}`)
      );
      yield put(changeStatusSuccess(updatedOrderList));

      // NOTE: In NeedsPersonalization tab (group 5), we need to clear the selected
      // items since they are removed from view
      const currentTab = yield select(searchFields);
      if (currentTab.get('group') === 5) {
        yield put(toggleSelectedRows(true));
      }
      // ***
    } catch (error) {
      yield put(
        changeStatusFail(['There was a problem changing the status of your selected orders'])
      );
    }
  }
}

export function* handleChangeTrackingRequest(action) {
  const payload = action.payload;

  let result;
  try {
    result = yield call([OrdersDetailsService, OrdersDetailsService.changeTrackingNumber], payload);
  } catch (error) {
    // handle errors below
  }

  if (!result || !result.FailedItems) {
    return yield put(push('Failed to change tracking number(s)'));
  }

  if (result.FailedItems.length) {
    yield put(push('Cannot change tracking number for item(s) ' + result.FailedItems.join(',')));
  }

  const succeeded = (payload.ids || []).filter(id => !result.FailedItems.includes(id));

  if (succeeded.length) {
    yield put(push('Changed tracking numbers successfully for item(s) ' + succeeded.join(',')));
  }

  return yield put(
    changeTrackingSuccess({
      ...payload,
      ids: succeeded
    })
  );
}

export function* handleUpdatePersonalizedImages(action) {
  const mappedData = Object.entries(action.data.filesToSubmit);
  try {
    yield all(
      mappedData.map(img => {
        if (img[1].type === 'file') {
          return call(
            [OrdersDetailsService, OrdersDetailsService.postOrderImageFileUpdate],
            img[0],
            img[1].image
          );
        } else if (img[1].type === 'url') {
          return call(
            [OrdersDetailsService, OrdersDetailsService.postOrderImageUrlUpdate],
            img[0],
            img[1].image
          );
        }
      })
    );

    yield call([OrdersViewService, OrdersViewService.hadCustomizationOrderItem], {
      itemId: action.data.itemId,
      comments: 'Updated in needs personalization tab'
    });

    if (mappedData.length) {
      yield put(push('Artwork updated'));
    } else {
      yield put(push('Order status updated'));
    }

    yield call(delay, 1000);
    yield call(handleSearchRequest);
    yield put(updatePersonalizedImagesSucess(action.data));
  } catch (err) {
    yield put(updatePersonalizedImagesFail(action.data));
    yield put(push(`Failed updating order item ${action.data.itemId}: ${err.Message}`));
  }
}

export function* watchSearch() {
  yield takeLatest(
    [
      SEARCH_START,
      UPDATE_PAGE_NUMBER,
      UPDATE_TAB_NUMBER,
      UPDATE_SEARCH_FIELDS,
      UPDATE_SEARCH_FIELDS_OBJECT,
      UPDATE_SEARCH_FIELDS_OBJECT_INITIAL,
      UPDATE_ORDER_ORDER_ITEM
    ],
    handleSearchRequest
  );
  yield takeLatest(CHANGE_STATUS.ASYNC, handleChangeStatusRequest);
  yield takeLatest(CHANGE_TRACKING.ASYNC, handleChangeTrackingRequest);
}

export function* watchPersonalizationActions() {
  yield takeLatest(UPDATE_PERSONALIZED_IMAGES.ASYNC, handleUpdatePersonalizedImages);
}

export default function* rootSaga() {
  yield all([watchSearch(), watchPersonalizationActions()]);
}
