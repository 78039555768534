import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import Webhooks from './Webhooks';
import { WebhooksModel } from '../../Models/WebhooksModel';
import { TEST_CONNECTION } from './WebhooksActions';
import { changeActionNameFor } from '../BaseCard/BaseCardActions';
import TestConnection from './components/TestConnection';

const changeAction = changeActionNameFor(Webhooks.name);

export default baseCardReducer(
  Webhooks.name,
  (
    state = new Map({
      data: new WebhooksModel(),
      loading: true,
      testConnectionStatus: TestConnection.status.none,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case TEST_CONNECTION.ASYNC:
        return state.set('busy', true).set('testConnectionStatus', TestConnection.status.testing);
      case TEST_CONNECTION.FAIL:
        return state
          .set('busy', false)
          .set('testConnectionStatus', TestConnection.status.failed)
          .set('testConnectionError', action.payload.error);
      case TEST_CONNECTION.SUCCESS:
        return state.set('busy', false).set('testConnectionStatus', TestConnection.status.success);
      case changeAction:
        return state.set('testConnectionStatus', TestConnection.status.none);
      default:
        return state;
    }
  },
  WebhooksModel
);
