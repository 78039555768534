import React, { useState } from 'react';
import { COLORS } from '../../constants.js';
import PropTypes from 'prop-types';
import Icon from './Icon.js';

const Input = React.forwardRef(
  (
    {
      id,
      name,
      type = 'text',
      label,
      required,
      iconPlacement,
      icon,
      disabled,
      placeholder,
      className,
      value,
      onChange,
      hasError = false,
      noLabel = false,
      autoComplete,

      onKeyPress,
      readOnly,
      onFocus,
      maxLength,
      onBlur,
      autoFocus,
      errorText,
      small
    },
    ref
  ) => {
    const [inputText, setInputText] = useState('');

    return (
      <div className="input-container">
        <input
          id={id || label || placeholder}
          type={type}
          ref={ref}
          name={name || null}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          className={`${className} ${disabled ? 'disabled' : ''}`}
          value={value}
          onChange={onChange || (e => setInputText(e.target.value))}
          onKeyPress={onKeyPress}
          readOnly={readOnly}
          autoComplete={autoComplete}
          onFocus={onFocus}
          maxLength={maxLength}
          onBlur={onBlur}
          autoFocus={autoFocus}
        />
        {!noLabel && (
          <label htmlFor={id || label || placeholder} className="body-text-2 heavy">
            {label || placeholder}
          </label>
        )}
        {icon && <Icon fill={COLORS.neutralDark200} icon={icon} />}
        {!!errorText && (
          <div className="error-field caption-text">
            <Icon small icon="alertCircle" />
            {errorText}
          </div>
        )}
        <style jsx>
          {`
            div.input-container {
              width: 100%;
              position: relative;
              margin-bottom: ${noLabel ? '0' : '2rem'};
              display: flex;
            }
            input {
              box-sizing: border-box;
              font-size: 1rem;
              color: ${COLORS.neutralDark200};
              height: ${noLabel ? (small ? '2rem' : '2.5rem') : '3rem'};
              width: 100%;
              border-radius: 4px;
              border: 1px solid ${hasError ? COLORS.cayennePepper : COLORS.neutralLight400};
              padding: ${noLabel
                ? '1rem'
                : !(inputText || value)
                ? '1.75rem 1rem .75rem 1rem'
                : '1.25rem 1rem .25rem'};
              position: relative;
              padding-left: ${iconPlacement === 'leading' ? '3rem' : 'auto'};
              padding-right: ${iconPlacement === 'trailing' ? '3rem' : 'auto'};
              outline: none;
              transition-duration: 0.2s;
              -webkit-text-fill-color: ${!(inputText || value) && !noLabel
                ? 'transparent'
                : COLORS.neutralDark200};
              text-align: ${small ? 'right' : 'left'};
              background: ${COLORS.neutralLight50};
            }
            input:focus {
              border: 1px solid ${COLORS.gootenBlue500};
              padding: ${noLabel ? '1rem' : '1.25rem 1rem .25rem'};
              padding-left: ${iconPlacement === 'leading' ? '3rem' : 'auto'};
              padding-right: ${iconPlacement === 'trailing' ? '3rem' : 'auto'};
              -webkit-text-fill-color: ${COLORS.neutralDark200};
            }
            ::-webkit-input-placeholder {
              opacity: ${noLabel ? '1' : '0'};
            }
            :-ms-input-placeholder {
              opacity: ${noLabel ? '1' : '0'};
            }
            ::placeholder {
              opacity: ${noLabel ? '1' : '0'};
            }
            input:focus ::-webkit-input-placeholder {
              opacity: 1;
            }
            input:focus :-ms-input-placeholder {
              opacity: 1;
            }
            input:focus ::placeholder {
              opacity: 1;
            }

            .input-container > :global(svg) {
              position: absolute;
              top: ${noLabel ? (small ? '-.5rem' : '-.25rem') : '0'};
              transform: translateY(50%);
              left: ${iconPlacement === 'leading' ? '1rem' : 'auto'};
              right: ${iconPlacement === 'trailing' ? '1rem' : 'auto'};
            }

            label {
              display: ${noLabel ? 'none' : 'block'};
              position: absolute;
              top: ${inputText || value ? '.25rem' : '50%'};
              left: ${iconPlacement === 'leading' ? '3rem' : '1rem'};
              transform: ${inputText || value ? 'translateY(0)' : 'translateY(-50%)'};
              font-size: ${inputText || value ? '0.8125rem' : '1rem'};
              color: ${hasError ? COLORS.cayennePepper : COLORS.neutralDark200};
              transition: all 150ms cubic-bezier(0.6, 0, 0.3, 1);
              font-family: Avenir Heavy, sans-serif;
              pointer-events: none;
              white-space: nowrap;
            }
            input:focus + label {
              font-size: 0.8125rem;
              top: 0.25rem;
              transform: translateY(0);
              color: ${COLORS.gootenBlue500};
            }
            .error-field {
              position: absolute;
              top: ${noLabel ? '2.75rem' : '3.25rem'};
              color: ${COLORS.cayennePepper};
              display: flex;
              align-items: center;
              fill: ${COLORS.cayennePepper};
              font-size: 0.8125rem;
              line-height: 0.8125rem;
            }
            .error-field :global(svg) {
              margin-right: 0.25rem;
            }
            .disabled {
              cursor: not-allowed;
              background: ${COLORS.neutralLight200};
            }
          `}
        </style>
      </div>
    );
  }
);

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  iconPlacement: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  hasError: PropTypes.bool,
  noLabel: PropTypes.bool,
  autoComplete: PropTypes.string,
  onKeyPress: PropTypes.func,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  errorText: PropTypes.string,
  small: PropTypes.bool
};

export default Input;
