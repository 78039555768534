import { connect } from 'react-redux';
import { fetchAsync } from '../../../../store/actions/dataActions';
import PricingModal from './PricingModal';
import { getSomeData } from './PricingModalActions';
import { productsData } from './PricingModalSelectors';
// import { dataSelector } from './PricingModalSelectors';

const mapDispatchToProps = {
  doSearch: query => getSomeData(query)
};

const mapStateToProps = state => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingModal);
