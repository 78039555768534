import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants';

const PageBody = ({ children, size = 'standard', className, noBottomPadding, background }) => {
  // the code below is to make sure the page content is always the full height
  const [containerHeight, setContainerHeight] = React.useState(0);
  const getContainerInfo = React.useCallback(e => {
    if (e !== null) {
      setContainerHeight(e.getBoundingClientRect().top);
    }
  }, []);

  return (
    <div
      ref={getContainerInfo}
      className={`adaptive-body m-auto ${className || ''} ${size}-layout`}
    >
      {children}
      <style jsx>
        {`
          .adaptive-body {
            padding: 1.5rem 2rem ${noBottomPadding ? '0' : '6.5rem'} 2rem;
            width: 100%;
            background: ${background || COLORS.neutralLight50};
            min-height: ${noBottomPadding ? 'auto' : `calc(100vh - ${containerHeight}px)`};
          }
          @media screen and (max-width: 767px) {
            .adaptive-body {
              padding: 1rem 1rem ${noBottomPadding ? '0' : '6.5rem'} 1rem;
            }
          }
          .standard-layout {
            max-width: 66rem;
          }
          .wide-layout {
            max-width: 72.5rem;
          }
          .full-layout {
            max-width: 100%;
          }
          .small-layout {
            max-width: 52rem;
          }
          .extra-small-layout {
            max-width: 40rem;
          }
        `}
      </style>
    </div>
  );
};

PageBody.propTypes = {
  children: PropTypes.any,
  size: PropTypes.oneOf(['standard', 'wide', 'full', 'small', 'extra-small']),
  className: PropTypes.string,
  noBottomPadding: PropTypes.bool,
  background: PropTypes.string
};

export default PageBody;
