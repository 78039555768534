// export const imageEditorSelector = state => state.get('imageUpload').get('imageEditor')
import { List } from 'immutable';
import { createSelector } from 'reselect';
import {
  bulkUpdatesSelector,
  selectedSpaceIndexSelector,
  selectedVariantIndexSelector
} from '../../ImageUploadSelectors';
import { selectedSKUsSelector } from '../../../../store/selectors/productDataSelectors';

const skuSelectionState = state => state.get('SKUSelection');

export const selectedOptionsSelector = createSelector([skuSelectionState], skuSelection =>
  skuSelection.get('selectedOptions').toJS()
);

export const bulkEditAvailableSelector = createSelector(selectedSKUsSelector, selectedSkus => {
  return selectedSkus.size > 1;
});
export const ILHistorySelector = createSelector(
  [
    selectedSKUsSelector,
    bulkUpdatesSelector,
    selectedSpaceIndexSelector,
    selectedVariantIndexSelector
  ],
  (selectedSkus, bulkUpdates, selectedSpaceIndex, selectedVariant) => {
    const src = selectedSkus.getIn([
      selectedVariant,
      'spaces',
      selectedSpaceIndex,
      'images',
      0,
      'imageUrl'
    ]);
    if (!src) {
      return { past: [], future: [] };
    }
    const selectedSkuName = selectedSkus.getIn([selectedVariant, 'sku']);
    const past =
      bulkUpdates.getIn([selectedSkuName, `${selectedSpaceIndex}`, 'past']) || new List();
    const future =
      bulkUpdates.getIn([selectedSkuName, `${selectedSpaceIndex}`, 'future']) || new List();
    return { past: past.toJS(), future: future.toJS() };
  }
);
