import { ActionNameFactory } from '../../../utils/actions';

// ------------------------------------
// Component Action Names Factory
// Create All Component Action Names
// ------------------------------------
export const getActionName = ActionNameFactory('COMPONENTS', 'NOTIFICATIONS');

// ------------------------------------
// Constants
// ------------------------------------
export const DISMISS = getActionName('DISMISS');
export const PUSH = getActionName('PUSH');

// ------------------------------------
// Actions
// ------------------------------------
export const dismiss = key => {
  return {
    type: DISMISS,
    key
  };
};

export const push = (text, type) => {
  return {
    type: PUSH,
    payload: {
      text,
      type,
      date: new Date()
    }
  };
};
