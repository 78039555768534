import { flatten, sortByName } from '../utils/array';
import { mapProduct } from '../models/Product';
import { mapCategory } from '../models/Category';
import { fetch } from '../utils/http';
import { getProductImageResizerUrl, toWebSafeString } from 'gooten-js-utils/src/url';
import Config from '../config';

const getProductsDataUrl = () => {
  // hardcoded list of partner groups that have a custom catalog
  const customCatalogPartners = ['fujifilm', 'sensaria'];

  // returns specific products data url, based on partner group
  const partnerGroup = Config.get('partnerGroup');
  return partnerGroup != null &&
    customCatalogPartners.includes(partnerGroup.get('Name').toLowerCase())
    ? `${Config.get('productsDataURL')}${partnerGroup.get('Name').toLowerCase()}/`
    : `${Config.get('productsDataURL')}`;
};

class ProductDataService {
  getProductDetails(productName) {
    const pName = toWebSafeString(productName);
    return fetch(`${getProductsDataUrl()}${pName}${'.json'}`);
  }

  getProductsWithCategories() {
    return fetch(`${getProductsDataUrl()}catalog.json`).then(data =>
      this.map(data['product-catalog'])
    );
  }

  getProductVariants(productName) {
    const pName = toWebSafeString(productName);
    return fetch(`${getProductsDataUrl()}${pName}_skus.json`);
  }

  map(productData) {
    // filter categories with subcategories
    const categories = productData
      .map(cat => ({
        name: cat.name.replace('&amp;', '&'),
        subcategories: cat.items
          .filter(item => item.type === 'category')
          .map(i => ({
            name: i.name.replace('&amp;', '&'),
            parentCategoryName: cat.name.replace('&amp;', '&')
          }))
      }))
      .map(c => mapCategory(c));
    // filter all products, and set category/sub for each
    const productIdPropName = Config.get('productIdPropName');
    const products = Object.values(
      flatten(
        productData.map(i =>
          i.items.map(item => {
            if (Array.isArray(item.items)) {
              return item.items.map(subItem => ({
                ...subItem,
                subcategory: item.name.replace('&amp;', '&'),
                category: i.name.replace('&amp;', '&'),
                image: getProductImageResizerUrl(subItem.url)
              }));
            }
            return {
              ...item,
              category: i.name.replace('&amp;', '&'),
              image: getProductImageResizerUrl(item.url)
            };
          })
        )
      ).reduce((res, p) => {
        let product = res[p[productIdPropName]] || mapProduct(p);
        res[p[productIdPropName]] = product
          .update('categories', categories => categories.push(p.category))
          .update('subcategories', subcategories =>
            p.subcategory ? subcategories.push(p.subcategory) : subcategories
          );
        return res;
      }, {})
    );
    return { categories, products };
  }

  sortSubCategories(data) {
    data.product.regions.forEach(r => {
      r['sub-categories'].sort(sortByName);
    });
  }

  filterOutProducts(products, condition) {
    return products.filter(product => condition(product));
  }
}

// singleton
export default new ProductDataService();
