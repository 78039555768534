import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import settingsApiService from '../../SettingsApiService';
import { partnerUserIdSelector } from '../../SettingsSelectors';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import { SEND_EMAIL } from './PasswordActions';
import Password from './Password';

export function* sendEmailAsyncHandler(action) {
  let result = null;
  try {
    const userId =
      action.payload && action.payload.userId
        ? action.payload.userId
        : yield select(partnerUserIdSelector);
    result = yield call(settingsApiService.sendPasswordResetEmail, userId);
    yield put(fetchAsyncSuccess(SEND_EMAIL, action.payload));
    yield put(push(`Your password reset request was sent to ${result.Email}`, 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(SEND_EMAIL, err));
    yield put(push('Failed to send reset password email.', 'failure'));
  }
}

export function* watchSendEmail() {
  yield takeLatest(SEND_EMAIL.ASYNC, sendEmailAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchSendEmail(), watchUpdateAsync(Password.name, Password.messages, true)]);
}
