import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from 'gooten-components/src/utils/price';
import { TableRow, Cell } from 'gooten-components/src/components/shared/Table';
import moment from 'moment';
import { COLORS } from 'gooten-components/src/constants';
import Button from 'gooten-components/src/components/shared/Button';
import Icon from 'gooten-components/src/components/shared/Icon';

const CustomerRefundHistory = ({ customerRefundHistory }) => {
  return (
    <>
      <div className="title-2">Refund History</div>
      {customerRefundHistory.size ? (
        <>
          <TableRow
            scrollableX
            columns="minmax(7rem, auto) minmax(7rem, auto) minmax(5rem, auto) minmax(5rem, auto) minmax(10rem, auto)
          minmax(7rem, auto) minmax(7rem, auto)"
          >
            <Cell sticky header>
              Request Id
            </Cell>
            <Cell header>User Name</Cell>
            <Cell header>Date</Cell>
            <Cell header>Refunded Amount</Cell>
            <Cell header>Reason</Cell>
            <Cell header>Status</Cell>
            <Cell header>Message</Cell>

            {customerRefundHistory.map((item, key) => {
              return <HistoryTableRow key={key} item={item} />;
            })}
          </TableRow>
        </>
      ) : (
        <div>
          <p>No refund transactions found.</p>
        </div>
      )}

      <style jsx>{`
        margin: 20px;
        width: 80%;
        display: flex;
        flex-direction: column;
      `}</style>
    </>
  );
};

CustomerRefundHistory.propTypes = {
  customerRefundHistory: PropTypes.object.isRequired
};

export default CustomerRefundHistory;

const HistoryTableRow = props => {
  const [openRequestData, setOpenRequestdata] = useState(false);
  const renderStatus = status => {
    if (status === true) {
      return <ChipBody color="green" title="Success" />;
    } else if (status === false) {
      return <ChipBody color="red" title="Failed" />;
    } else {
      return <ChipBody color="yellow" title="Pending" />;
    }
  };

  const amount =
    props.item.get('ItemRefund') +
    props.item.get('ItemTaxRefund') +
    props.item.get('ShippingRefund') +
    props.item.get('ShippingTaxRefund');

  const hasRequestsdata =
    (props.item.get('Status') === false && props.item.get('RefundRequestResponse')) ||
    props.item.get('RefundStatusUpdatePayload');

  const formatPayload = payload => {
    try {
      const json = JSON.parse(payload);
      return JSON.stringify(json, undefined, 2);
    } catch {
      return payload;
    }
  };

  return (
    <>
      <Cell sticky>{props.item.get('RequestId')}</Cell>
      <Cell>{props.item.get('RequestedByUserName')}</Cell>
      <Cell>{moment(props.item.get('RequestedAt')).format('MMM DD, YYYY, hh:mm A')}</Cell>
      <Cell>
        <div className="break-all">{formatPrice(amount, 'USD {1}')}</div>
      </Cell>
      <Cell>
        {props.item.get('ReasonCode')} - {props.item.get('Reason')}
      </Cell>
      <Cell>{renderStatus(props.item.get('Status'))}</Cell>
      <Cell>
        {hasRequestsdata && !openRequestData ? (
          <Button
            iconLeft="eye"
            className="button-default m-1 large"
            onClick={() => setOpenRequestdata(true)}
          >
            Show Message
          </Button>
        ) : null}
        {hasRequestsdata && openRequestData ? (
          <Button
            iconLeft="eyeOff"
            className="button-default m-1 large"
            onClick={() => setOpenRequestdata(false)}
          >
            Hide Message
          </Button>
        ) : null}
      </Cell>

      {/* Display initial response data in toggleable dropdown if available */}
      {/* Only if initial request failed*/}
      {props.item.get('RefundRequestResponse') &&
      !props.item.get('RefundStatusUpdatePayload') &&
      props.item.get('Status') === false &&
      openRequestData ? (
        <>
          <Cell className="gray-bg mb-3" sticky></Cell>
          <Cell className="gray-bg mb-3 p-2 column-span-five">
            <div className="d-flex flex-column prior-data w-100 pt-2 pb-2 pl-2 pr-2">
              <pre>{formatPayload(props.item.get('RefundRequestResponse'))}</pre>
            </div>
          </Cell>
          <Cell className="gray-bg mb-3"></Cell>
        </>
      ) : null}
      {props.item.get('RefundStatusUpdatePayload') && openRequestData ? (
        <>
          <Cell className="gray-bg mb-3" sticky></Cell>
          <Cell className="gray-bg mb-3 p-2 column-span-five">
            <div className="d-flex flex-column prior-data w-100 pt-2 pb-2 pl-2 pr-2">
              <pre>{formatPayload(props.item.get('RefundStatusUpdatePayload'))}</pre>
            </div>
          </Cell>
          <Cell className="gray-bg mb-3"></Cell>
        </>
      ) : null}
      <style jsx>
        {`
          :global(.column-span-five) {
            grid-column: auto / span 5;
          }
          :global(.table-cell.gray-bg) {
            background: ${COLORS.neutralLight100};
          }
          .prior-data {
            max-height: 10rem;
            overflow-y: auto;
            max-width: calc(100vw - 11rem);
            word-wrap: break-word;
          }
          .prior-data pre {
            border: none;
            background: transparent;
          }
          :global(.cursor-pointer) {
            cursor: pointer !important;
          }
          .prior-data-row {
            display: grid;
            grid-template-columns: 8.5rem auto;
            word-break: break-all;
          }
          @media screen and (max-width: 576px) {
            .prior-data-row {
              grid-template-columns: auto;
            }
          }
          .break-all {
            word-break: break-all;
          }
        `}
      </style>
    </>
  );
};

HistoryTableRow.propTypes = {
  item: PropTypes.object
};

const ChipBody = ({ color, title }) => (
  <div className={`chip button-1`}>
    <div className={`${color} circle mr-1 ml-n1`} />
    {title}
    <style jsx>
      {`
        .chip {
          border-radius: 16px;
          height: 1.8rem;
          padding: 0 1rem;
          width: fit-content;
          color: ${COLORS.neutralDark500};
          background: ${COLORS.neutralLight200};
          font-weight: 800;
          display: flex;
          align-items: center;
        }
        .circle {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
        }
        .default {
          color: ${COLORS.neutralDark500};
          background: ${COLORS.neutralLight200};
        }
        .green {
          color: ${COLORS.monstera600};
          fill: ${COLORS.monstera600};
          background: ${COLORS.monstera200};
        }
        .red {
          color: ${COLORS.cayennePepper600};
          fill: ${COLORS.cayennePepper600};
          background: ${COLORS.cayennePepper200};
        }
        .yellow {
          color: ${COLORS.bananaPeel600};
          fill: ${COLORS.cayennePepper600};
          background: ${COLORS.bananaPeel200};
        }
      `}
    </style>
  </div>
);
