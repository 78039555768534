import { fetch, put, postJson, $delete, fileUpload } from 'gooten-components/src/utils/http';
import Config from '../config';

const getAuthQueryParams = () => ({
  Authorization: 'Bearer ' + Config.get('adminApiToken')
});

class AdminApiService {
  getAdminApiUrl(url) {
    return `${Config.get('adminApi')}${url}`;
  }

  getAuthHeaders() {
    return getAuthQueryParams();
  }

  get(url, queryParams) {
    const headers = {
      ...getAuthQueryParams(),
      Accept: 'application/json'
    };
    return fetch(`${Config.get('adminApi')}${url}`, { method: 'GET', queryParams, headers });
  }

  put(url, data, queryParams) {
    const headers = {
      ...getAuthQueryParams(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return put(`${Config.get('adminApi')}${url}`, JSON.stringify(data), { queryParams, headers });
  }

  post(url, data, queryParams) {
    const headers = {
      ...getAuthQueryParams(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    return postJson(`${Config.get('adminApi')}${url}`, data, { queryParams, headers });
  }

  delete(url, data, queryParams) {
    const headers = {
      ...getAuthQueryParams()
    };
    return $delete(`${Config.get('adminApi')}${url}`, data, { queryParams, headers });
  }

  uploadFile(url, file, name) {
    const formData = new FormData();
    formData.append(name, file);
    const headers = {
      ...getAuthQueryParams()
    };
    return fileUpload(`${Config.get('adminApi')}${url}`, formData, { headers });
  }
}

export default new AdminApiService();
