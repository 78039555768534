import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import FulfillmentSettings from './FulfillmentSettings';
import { FulfillmentSettingsModel } from '../../Models/FulfillmentSettingsModel';

export default baseCardReducer(
  FulfillmentSettings.name,
  (
    state = new Map({
      data: new FulfillmentSettingsModel(),
      loading: true,
      busy: false,
      open: true
    })
  ) => state,
  FulfillmentSettingsModel
);
