import { takeLatest, call, put, all, take } from 'redux-saga/effects';
import {
  getShopifyOAuthUrl,
  parseStoreUrl,
  openOAuthPage,
  OAUTH_STATUS
} from 'gooten-components/src/utils/oauth';
import Log from 'gooten-components/src/services/logService';
import {
  STORE_CONNECT,
  INIT_CONNECT,
  connectStoreFail,
  connectStoreSuccess
} from './ConnectStoreViewActions';
import storesService from 'gooten-components/src/services/storesService';
import analyticsService from 'gooten-components/src/services/analyticsService';
import { hashHistory } from 'react-router';
import adminApiService from '../../services/adminApiService';
import Config from '../../config';
import { eventChannel, END } from 'redux-saga';

export function* storeConnectHandler(action) {
  switch (action.payload.provider) {
    case 'tiktok':
      yield tiktokStoreConnectHandler(action);
      break;
    case 'etsy':
      yield etsyStoreConnectHandler(action);
      break;
    case 'shopify':
      yield shopifyStoreConnectHandler(action);
      break;
    case 'woocommerce':
      yield wooStoreConnectHandler(action);
      break;
    case 'bigcommerce':
      yield bigcommerceStoreConnectHandler(action);
      break;
    default:
      yield put(connectStoreFail('Unsupported eCommerce Platform.'));
  }
}

const getGenericOAuthRedirectUrl = () =>
  window.location.protocol +
  '//' +
  window.location.hostname +
  '/admin-assets/scripts/areas/shopify/vendors/' +
  'generic-oauth.html';

export function* wooStoreConnectHandler(action) {
  const domainName = (action.payload.store.domainName || action.payload.store)
    .replace(/(^\w+:|^)\/\//, '')
    .replace(/\/+$/, '');

  const recipeId = action.payload.store.recipeId;
  // const callbackUrl = 'https://bogie.ngrok.io/oauth/woocommerce/'
  // + domainName + '/' + recipeId
  // const returnUrl = 'https://bogie-dev.ngrok.io/generic-oauth.html'

  const { error } = yield call(
    [storesService, storesService.getOAuth],
    action.payload.provider,
    domainName
  );

  if (error) {
    analyticsService._track('Hub - Integrations', 'Woo Store Connect Failure', 'Woo', null, {
      error
    });
    // Show API validation error
    yield put(connectStoreFail(error));
    return;
  }

  const callbackUrl =
    `${Config.get('storeApi')}`.replace(/gooten.com.*/, 'gooten.com') +
    '/oauth/woocommerce/' +
    domainName +
    '/' +
    recipeId;

  const returnUrl = getGenericOAuthRedirectUrl();

  const url =
    'http://' +
    domainName +
    '/wc-auth/v1/authorize?app_name=Gooten&user_id=' +
    recipeId +
    '&scope=read_write&return_url=' +
    returnUrl +
    '&callback_url=' +
    callbackUrl;

  try {
    const oauth = yield openOAuthPage(decodeURIComponent(url));
    if (oauth.query.success === '1') {
      const stores = yield call([storesService, storesService.getUserStores]);

      const hit = stores.filter(
        s => s.provider === 'woocommerce' && s.settings.get('shop_domain').includes(domainName)
      );

      if (hit && hit.size === 1) {
        const newStore = hit.get(0);
        // Adds store to all stores in state
        yield put(connectStoreSuccess(newStore));

        // Redirect to product selection
        hashHistory.push('/hub/create-product/product-selection');
      } else {
        yield put(
          connectStoreFail('Cannot connect to your store, please check domain name and try again')
        );
      }
    } else {
      yield put(
        connectStoreFail('Cannot connect to your store, please check domain name and try again')
      );
    }
  } catch (err) {
    yield put(connectStoreFail('Please allow popup window.'));
  }
}

export function* tiktokStoreConnect(storeAuth) {
  const store = yield call([storesService, storesService.tiktokShopConnect], storeAuth);
  if (store.error) {
    analyticsService._track('Hub - Integrations', 'TikTok Shop Connect Failure', 'TikTok', null, {
      error: store.error
    });
    yield put(connectStoreFail(store.error));
    return;
  } else {
    analyticsService._track('Hub - Integrations', 'TikTok Store Connect Success', 'TikTok');
    // Adds store to all stores in state
    yield put(connectStoreSuccess(store));
  }

  // Redirect to product selection
  hashHistory.push('/hub/create-product/product-selection');
}

export function* tiktokStoreConnectHandler(action) {
  try {
    const redirectUrl = getGenericOAuthRedirectUrl();

    const { url, state, error } = yield call(
      [storesService, storesService.getOAuth],
      action.payload.provider,
      action.payload.store, //here we providing region
      redirectUrl
    );

    if (error || !url) {
      analyticsService._track('Hub - Integrations', 'TikTok Shop Connect Failure', 'TikTok', null, {
        error
      });
      // Show API validation error
      yield put(connectStoreFail(error || `Connection error.`));
      return;
    }

    try {
      const oauth = yield openOAuthPage(decodeURIComponent(url));
      if (oauth.query.state !== state) {
        throw 'TikTok OAuth failed.';
      }

      const storeAuth = {
        //OAuth 2.0 for TikTok API
        code: oauth.query.code,
        state: oauth.query.state,
        region: action.payload.store,
        redirectUrl
      };

      yield tiktokStoreConnect(storeAuth);
    } catch (err) {
      analyticsService._track(
        'Hub - Integrations',
        'TikTok Store Connect Failure',
        'TikTok',
        null,
        {
          error: 'Popup not allowed'
        }
      );
      yield put(
        connectStoreFail(
          'Please try again, or contact partnersupport@gooten.com with a screenshot.'
        )
      );
    }
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    yield put(connectStoreFail('TikTok oauth failed.'));
  }
}

export function* etsyStoreConnectHandler(action) {
  try {
    const redirectUrl = getGenericOAuthRedirectUrl();

    const {
      url,
      codeVerifier, //v3
      state, //v3
      version, //v3
      tokenKey, //v2
      tokenSecret, //v2
      error
    } = yield call(
      [storesService, storesService.getOAuth],
      //action.payload.provider
      action.payload.provider + '-v3', //Step in Etsy API v3
      action.payload.store,
      redirectUrl
    );

    if (error || (!url && !tokenKey && !tokenSecret)) {
      analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
        error
      });
      // Show API validation error
      yield put(connectStoreFail(error || `Store doesn't exist.`));
      return;
    }

    try {
      const oauth = yield openOAuthPage(decodeURIComponent(url));
      if (oauth.query.state !== state) {
        throw 'Etsy V3 OAuth failed.';
      }

      const storeAuth = {
        //OAuth 2.0 for Etsy API v3
        code: oauth.query.code,
        state: oauth.query.state,
        redirectUrl,
        codeVerifier,
        //OAuth 1.0 for Etsy API v2
        storeName: action.payload.store,
        tokenKey,
        tokenSecret,
        verifierCode: oauth.query.oauth_verifier
      };
      let connect = version === 'v3' ? storesService.storeV3Connect : storesService.storeConnect;
      // TODO: API should return connected store info
      // so we can add it to stores in state and redirect user to it
      const store = yield call([storesService, connect], storeAuth);
      if (store.error) {
        analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
          error: store.error
        });
        yield put(connectStoreFail(store.error));
        return;
      } else {
        analyticsService._track('Hub - Integrations', 'Etsy Store Connect Success', 'Etsy');
        // Adds store to all stores in state
        yield put(connectStoreSuccess(store));
      }

      // Redirect to product selection
      hashHistory.push('/hub/create-product/product-selection');
    } catch (err) {
      analyticsService._track('Hub - Integrations', 'Etsy Store Connect Failure', 'Etsy', null, {
        error: 'Popup not allowed'
      });
      yield put(connectStoreFail('Please allow popup window.'));
    }
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    yield put(connectStoreFail('Etsy oauth failed.'));
  }
}

// This function is for connecting a new Shopify Store to OAuth directly
export function* shopifyStoreConnectHandler(action) {
  try {
    const partnerId = Config.get('partnerId');
    const storeName = parseStoreUrl(action.payload.store);
    //Save partner id for super-admin user
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 3600;
    now.setTime(expireTime);
    document.cookie = `hubPartnerId=${partnerId};max-age=${now.toUTCString()};path=/`;

    const kData = yield call(
      adminApiService.get,
      `ShopifyAccounts/GetAppClientKey?partnerId=${partnerId}`
    );
    const sData = yield call(adminApiService.post, `ShopifyAccounts/IsStoreConnected`, {
      Store: storeName,
      PartnerId: partnerId
    });

    // Don't check for existing store on ReAuthorization
    if ((sData.isConnected || !sData.exist) && !action.payload.isReauth) {
      const error = sData.isConnected
        ? 'This store is already connected to a different Gooten account.'
        : 'This store is temporarily unavailable or not exist.';
      analyticsService._track(
        'Hub - Integrations',
        'Shopify Store Connect Failure',
        'Shopify',
        null,
        { error }
      );
      yield put(connectStoreFail(error));
      return;
    }

    // this one for Install App
    const url = getShopifyOAuthUrl(storeName, kData.Key);
    window.location.replace(decodeURIComponent(url));
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    switch (err ? err.status : null) {
      case OAUTH_STATUS.POPUP_BOCKED:
        yield put(connectStoreFail('Popup blocked.'));
        break;
      case OAUTH_STATUS.REQUEST_FAILED:
        yield put(connectStoreFail('Shopify oauth request failed.'));
        break;
      default:
        yield put(connectStoreFail('Shopify oauth failed.'));
    }
  }
}

export function* bigcommerceStoreConnectHandler(action) {
  const appId = Config.get('env') === 'staging' ? '29325' : '26835';
  try {
    const storeTruncated = action.payload.store.replace('.mybigcommerce.com', '');
    const storeId = storeTruncated.replace(/[^a-zA-Z0-9-]/g, '');
    const store = yield call([storesService, storesService.storeConnectBigCommerce], {
      storeId,
      token: ''
    });
    if (store.error) {
      if (store.error === 'No Access Token') {
        // The store hasn't installed the Gooten app yet
        if (window.localStorage.isFirstLogin) {
          // Open bigcommerce in a new window tab if it is first login
          window.open(
            'https://' + storeId + '.mybigcommerce.com/manage/marketplace/apps/' + appId,
            '_blank'
          );
        } else {
          window.location =
            'https://' + storeId + '.mybigcommerce.com/manage/marketplace/apps/' + appId;
        }
      } else {
        yield put(connectStoreFail(store.error));
      }
    } else {
      analyticsService._track(
        'Hub - Integrations',
        'BigCommerce Store Connect Success',
        'BigCommerce'
      );
      yield put(connectStoreSuccess(store));

      hashHistory.push(`/hub/bigcommerce/${store.id || ''}`);
    }
  } catch (err) {
    // Don't throw - we show error modal
    Log.report('Store connect error', {
      ...action.payload,
      error: err
    });
    switch (err ? err.status : null) {
      case OAUTH_STATUS.POPUP_BOCKED:
        yield put(connectStoreFail('Popup blocked.'));
        break;
      case OAUTH_STATUS.REQUEST_FAILED:
        yield put(connectStoreFail('BigCommerce oauth request failed.'));
        break;
      default:
        yield put(connectStoreFail('BigCommerce oauth failed.'));
    }
  }
}

export function* watchStoreConnect() {
  yield takeLatest(STORE_CONNECT.ASYNC, storeConnectHandler);
}

export function* storeInitConnectHandler(action) {
  switch (action.payload.provider) {
    case 'shopify':
      yield shopifyInitStoreConnectHandler(action);
      break;
    case 'tiktok':
      yield tiktokInitStoreConnectHandler(action);
      break;
    default:
      yield put({ type: INIT_CONNECT.SUCCESS, payload: action.payload });
  }
}

export function* shopifyInitStoreConnectHandler(action) {
  try {
    const partnerId = Config.get('partnerId');
    const resp = yield call(
      adminApiService.get,
      `ShopifyAccounts/GetPartnerData?partnerId=${partnerId}`
    );
    const initData = {
      storeNameFromSignup: resp.Settings ? resp.Settings.StoreNameFromSignup : null
    };
    yield put({ type: INIT_CONNECT.SUCCESS, payload: { ...action.payload, initData } });
  } catch (err) {
    yield put({
      type: INIT_CONNECT.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to init store connection.' }
    });
  }
}

export function* tiktokInitStoreConnectHandler(action) {
  try {
    const storeAuth = {
      state: action.payload.state.state,
      code: action.payload.state.code,
      region: action.payload.state.shop_region,
      redirectUrl: getGenericOAuthRedirectUrl()
    };

    yield tiktokStoreConnect(storeAuth);
  } catch (err) {
    yield put({
      type: INIT_CONNECT.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to init store connection.' }
    });
  }
}

export function* watchInitConnect() {
  yield takeLatest(INIT_CONNECT.ASYNC, storeInitConnectHandler);
}

export default function* rootSaga() {
  yield all([watchInitConnect(), watchStoreConnect()]);
}
