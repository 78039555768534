import { delay } from 'redux-saga';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import hubProductsService from './HubProductsService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { HUB_PRODUCTS_LOAD } from './HubProductsActions';

export function* hubProductsLoadHandler(action) {
  yield call(delay, 500);

  try {
    let hubProducts = yield call(
      [hubProductsService, hubProductsService.loadHubProductItems],
      action.payload.query
    );
    yield put(fetchAsyncSuccess(HUB_PRODUCTS_LOAD, { hubProducts }));
  } catch (err) {
    yield put(fetchAsyncFail(HUB_PRODUCTS_LOAD, err));
    throw err;
  }
}

export function* watchHubProductsLoad() {
  yield takeLatest(HUB_PRODUCTS_LOAD.ASYNC, hubProductsLoadHandler);
}

export default function* rootSaga() {
  yield all([watchHubProductsLoad()]);
}
