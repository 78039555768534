import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import EmailServices from './EmailServices';
import { EmailServicesModel } from '../../Models/EmailServicesModel';

export default baseCardReducer(
  EmailServices.name,
  (
    state = new Map({
      data: new EmailServicesModel(),
      loading: true,
      busy: false,
      open: true
    })
  ) => state,
  EmailServicesModel
);
