import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';

export const Loader = props => (
  <div className="loader-container text-center">
    <div className={'loader-box' + (props.inline ? ' inline' : '')}>
      <LoadingSpinner />
    </div>
    <style jsx>
      {`
        .loader-box {
          display: block;
          height: 64px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          width: 64px;
          z-index: 1000;
        }
        .inline {
          margin: 0 auto;
          position: relative;
          padding-top: 1px;
        }
      `}
    </style>
  </div>
);

Loader.propTypes = {
  inline: PropTypes.bool
};

export default Loader;
