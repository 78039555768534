import { delay } from 'redux-saga';
import { takeLatest, takeEvery, call, put, select, all } from 'redux-saga/effects';
import { hashHistory } from 'react-router';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { STORES_FETCH, STORE_REFRESH, STORE_LOG_OUT } from './StoresActions';
import storeService from './StoresService';
import { searchChange } from '../Search/SearchActions';
import { unselectAll } from '../Actions/ActionsActions';
import { availableStoresSelector, allStoresSelector } from './StoresSelectors';

export function* storesFetchAsyncHandler(action) {
  try {
    let skipCache = (action.payload && action.payload.skipCache) || false;
    // call and pass correct this as 1st array param
    let data = yield call([storeService, storeService.getUserStores], skipCache);
    yield put(fetchAsyncSuccess(STORES_FETCH, { stores: data }));
  } catch (err) {
    yield put(fetchAsyncFail(STORES_FETCH, err));
    throw err;
  }
}

export function* storeRefreshAsyncHandler(action) {
  yield call(delay, 5000);
  try {
    const allStores = yield select(allStoresSelector);

    // because this runs after 5 secs, we need to check the case if store is disconnected in the meantime
    if (allStores.map(s => s.get('id')).contains(action.payload.storeId)) {
      // call and pass correct this as 1st array param
      let data = yield call([storeService, storeService.getStoreData], action.payload.storeId);
      yield put(fetchAsyncSuccess(STORE_REFRESH, { data }));
    }
  } catch (err) {
    yield put(fetchAsyncFail(STORE_REFRESH, err));
  }
}

export function* storeLogOutAsyncHandler(action) {
  const storeId = action.payload.storeId;
  try {
    yield call([storeService, storeService.storeLogOut], storeId);
    const availableStores = yield select(availableStoresSelector);
    const payload = { storeId };
    if (availableStores.size === 1 && availableStores.get(0).get('id') === storeId) {
      payload.providerToRemove = availableStores.get(0).get('provider');
    }
    yield put(fetchAsyncSuccess(STORE_LOG_OUT, payload));
  } catch (err) {
    yield put(fetchAsyncFail(STORE_LOG_OUT, { storeId, err }));
    throw err;
  }
}

export function* storeLogOutAsyncSuccessHandler(action) {
  yield put(unselectAll());
  // don't reload stores, we just removing disconnected
  // yield put(storesLoad())
  yield put(searchChange());
  hashHistory.push('/hub/all');
}

export function* watchStoresFetchAsync() {
  yield takeEvery(STORES_FETCH.ASYNC, storesFetchAsyncHandler);
}

export function* watchStoreRefreshAsync() {
  yield takeLatest(STORE_REFRESH.ASYNC, storeRefreshAsyncHandler);
}

export function* watchStoreLogOutAsync() {
  yield takeEvery(STORE_LOG_OUT.ASYNC, storeLogOutAsyncHandler);
}

export function* watchStoreLogOutAsyncSuccess() {
  // This watch when publish successfully submitted and processing being started by backend
  yield takeEvery(STORE_LOG_OUT.SUCCESS, storeLogOutAsyncSuccessHandler);
}

export default function* rootSaga() {
  yield all([
    watchStoresFetchAsync(),
    watchStoreRefreshAsync(),
    watchStoreLogOutAsync(),
    watchStoreLogOutAsyncSuccess()
  ]);
}
