import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../config';

export const fetchShippingData = bodyData => {
  const url = `${Config.get('adminApi')}OrderDetailsApi/getOrderShipments`;

  return fetch(url, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${Config.get('adminApiToken')}`,
      'content-type': `application/json`
    },
    queryParams: {
      orderId: bodyData
    }
  });
};
