import React from 'react';
import PropTypes from 'prop-types';
import './VariantsList.scss';
import VariantListLine from './VariantListLine';

class VariantsList extends React.Component {
  render() {
    let groups = this.props.variants.reduce((result, v) => {
      let title = this.props.shouldChangeOrientation
        ? `${v.template.size.height} x ${v.template.size.width} px`
        : `${v.template.size.width} x ${v.template.size.height} px`;
      let group = result.find(g => g.title === title);
      if (!group) {
        group = { title: title, items: [] };
        result.push(group);
      }

      group.items.push(v);
      return result;
    }, []);

    let handleUploadClick = item => {
      if (this.props.skuOverflow > 0) {
        this.props.showOverflowWarning();
        return;
      }

      this.props.initImageModal({
        sku: item.sku,
        variantIndex: item.index,
        spaces: item.template.Spaces
      });
    };

    this.groupedLines = groups.map(g => {
      let lines = g.items.map(item => (
        <VariantListLine
          disableDelete={this.props.disableDelete}
          variant={item}
          key={item.index}
          selected={item.index === this.props.currentVariantIndex}
          space={
            item.index === this.props.currentVariantIndex ? this.props.currentSpace : item.spaces[0]
          }
          onSelect={() =>
            this.props.variantSelect(
              item.index,
              0,
              this.props.currentVariantIndex,
              this.props.currentSpaceIndex
            )
          }
          onUploadImageClick={() => handleUploadClick(item)}
          onVariantDeleteClick={() => this.props.variantDelete(item.index)}
        />
      ));

      return (
        <div className="variants-group" key={g.title}>
          <span className="group-title">Printable Area {g.title}</span>
          {lines}
        </div>
      );
    });

    return (
      <div className="variants-list-container">
        <div>{this.groupedLines}</div>
      </div>
    );
  }
}

VariantsList.propTypes = {
  disableDelete: PropTypes.bool,
  variants: PropTypes.array.isRequired,
  currentVariantIndex: PropTypes.number,
  currentSpace: PropTypes.object,
  currentSpaceIndex: PropTypes.number,
  variantSelect: PropTypes.func.isRequired,
  initImageModal: PropTypes.func.isRequired,
  variantDelete: PropTypes.func.isRequired,
  skuOverflow: PropTypes.number.isRequired,
  showOverflowWarning: PropTypes.func.isRequired,
  shouldChangeOrientation: PropTypes.bool.isRequired
};

export default VariantsList;
