import { SEARCH_CHANGE } from './SearchActions';
import { PROVIDER_SELECT } from '../Providers/ProvidersActions';
import { STORE_SELECT } from '../Stores/StoresActions';
import { RESET } from '../../../../store/actions/globalActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [RESET]: (state, action) => (action.payload.scope === 'global' ? '' : state),

  [SEARCH_CHANGE]: (state, action) => action.payload,

  [PROVIDER_SELECT]: (state, action) => (action.payload.reset ? '' : state),

  [STORE_SELECT]: (state, action) => (action.payload.reset ? '' : state)
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = '', action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
