import React from 'react';
import PropTypes from 'prop-types';
import DropDown from '../DropDown';
import closeOnClickOutsideManager from '../../../services/closeOnClickOutsideManager';

class DropDownContainer extends React.PureComponent {
  state = {
    isOpen: false
  };

  componentDidMount() {
    closeOnClickOutsideManager.register(this);
  }

  componentWillUnmount() {
    closeOnClickOutsideManager.unregister(this);
  }

  toggle = () => {
    if (!this.state.isOpen) {
      closeOnClickOutsideManager.closeOthers(this);
      this.props.onClick && this.props.onClick();
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  close = () => {
    if (this.state.isOpen) this.setState({ isOpen: false });
  };

  render() {
    return (
      <DropDown
        isOpen={this.state.isOpen}
        toggle={!this.props.disabled ? this.toggle : () => void 0}
        title={this.props.title}
        disabled={this.props.disabled}
        buttonOnly={this.props.buttonOnly}
        rightAlign={this.props.rightAlign}
        bottomAlign={this.props.bottomAlign}
        className={this.props.className}
        hasError={this.props.hasError}
        fullWidth={this.props.fullWidth}
        scrollHeight={this.props.scrollHeight}
        size={this.props.size}
      >
        {this.props.children}
      </DropDown>
    );
  }
}

DropDownContainer.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonOnly: PropTypes.bool,
  rightAlign: PropTypes.bool,
  bottomAlign: PropTypes.bool,
  hasError: PropTypes.bool,
  fullWidth: PropTypes.bool,
  scrollHeight: PropTypes.number,
  size: PropTypes.string
};

export default DropDownContainer;
