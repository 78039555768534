export const CSVTextToObject = (str, delim = ',') => {
  const newStr = str.replace(/(?:\r\n|\r)/g, '\n');
  const headers = newStr.slice(0, newStr.indexOf('\n')).split(delim);
  const rows = newStr
    .slice(newStr.indexOf('\n') + 1)
    .split('\n')
    ?.filter(m => m?.length > 10); // filter some empty lines...

  const parseRow = (row, delim) => {
    const values = [];
    let insideQuotes = false;
    let field = '';

    for (let i = 0; i < row.length; i++) {
      const currentChar = row[i];

      if (insideQuotes) {
        if (currentChar === '"') {
          if (i + 1 < row.length && row[i + 1] === '"') {
            field += currentChar;
            i++; // Skip the next quote character
          } else {
            insideQuotes = false;
          }
        } else {
          field += currentChar;
        }
      } else {
        if (currentChar === delim) {
          values.push(field);
          field = '';
        } else if (currentChar === '"') {
          insideQuotes = true;
        } else {
          field += currentChar;
        }
      }
    }
    values.push(field);

    return values;
  };

  const newArray = rows.map(row => {
    const values = parseRow(row, delim);
    const eachObject = headers.reduce((obj, header, i) => {
      obj[header] = values[i];
      return obj;
    }, {});
    return eachObject;
  });

  return newArray;
};
