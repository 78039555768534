import React from 'react';
import Tile from '../../../shared/Tile';
import './ApparelPanelItem.scss';
import OptionsService from '../OptionsPanel/Options/services/optionsService';

const ApparelPanelItem = ({ item, selectBatchOptions, selectedOptions }) => {
  let modelOption = item?.options?.find(opt => opt.name == 'Model');
  let genderOption = item?.options?.find(opt => opt.name == 'Gender');
  let brandOption = item?.options?.find(opt => opt.name == 'Brand');

  const setOverride = (option, valueId) => {
    //select gender option
    if (genderOption) {
      selectBatchOptions([{ optionId: genderOption.id, values: [genderOption.value.id] }]);
    }

    //select brand option
    selectBatchOptions([{ optionId: brandOption.id, values: [brandOption.value.id] }]);

    //select model option
    selectBatchOptions([{ optionId: option.id, values: [valueId] }]);
  };

  const isValueSelected = (optionId, valueId) => {
    return OptionsService.isValueSelected(optionId, valueId, selectedOptions.toJS());
  };

  return (
    <div key={item.id} className="apparel-panel-item">
      <Tile
        title={item.name}
        hasNecklabel={!!modelOption.value.necklabel}
        option={modelOption}
        valueId={modelOption.value.id}
        active={isValueSelected(modelOption.id, modelOption.value.id)}
        disabled={false}
        toggleValue={(option, valueId) => setOverride(option, valueId)}
        imageUrl={item.thumbnailUrl}
      />
    </div>
  );
};

export default ApparelPanelItem;
