import { takeLatest, takeEvery, call, put, select, take, all } from 'redux-saga/effects';
import {
  fetchAsync,
  fetchAsyncSuccess,
  fetchAsyncFail,
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH
} from 'gooten-components/src/store/actions/dataActions';
import {
  INIT,
  LOAD_SKUS,
  SELECT_PRODUCT,
  SKIP_MAPPING,
  setReady,
  canGoNext
} from './SKUsSelectionViewActions';
import { setCurrentVariantIndex } from './components/VariantsSelection/VariantsSelectionActions';
import {
  hashSelector,
  variantsMappingsSelector,
  currentVariantIndexSelector,
  getSKUsSelectionQuery,
  gootenProductDetailsSelector,
  SKUsSelector,
  getProductVariantsSelectionQuery
} from './SKUsSelectionViewSelectors';
import linkProductService from '../LinkProductService';
import Log from 'gooten-components/src/services/logService';
import { flowVerifiedSelector, selectedStoreProductSelector } from '../LinkProductSelectors';
import { providerPathSelector } from '../ProductSelectionView/ProductSelectionViewSelectors';
import { fromJS } from 'immutable';

export function* initAsyncHandler(action) {
  const flowVerified = yield select(flowVerifiedSelector);
  if (!flowVerified) {
    // flow is broken - redirect to listing page of store products from current URL
    window.location.hash = window.location.hash.split('/link-product')[0];
    return;
  }
  let productsData = null;
  const providerPath = yield select(providerPathSelector);
  try {
    const selectedStoreProduct = yield select(selectedStoreProductSelector);
    if (!selectedStoreProduct || action.payload.providerPath !== providerPath) {
      // state is broken, redirect to listing
      window.location.hash = action.payload.providerPath;
    }

    const productVariantsSelectionQuery = yield select(getProductVariantsSelectionQuery);
    const hash = yield select(hashSelector);

    // test selected product change, store already validated
    if (!hash || hash.productId !== productVariantsSelectionQuery.productId) {
      const productVariants = yield call(
        linkProductService.getStoreProductVariants,
        productVariantsSelectionQuery
      );
      const variantsMappings = productVariants
        // skip already connected
        .filter(variant => !variant.gooten_mapping)
        .map(variant => ({ variant, uniqueProduct: null }));

      yield put(fetchAsync(PRODUCTS_FETCH));
      yield take(PRODUCTS_FETCH.SUCCESS);

      yield put(fetchAsyncSuccess(INIT, { variantsMappings, hash: productVariantsSelectionQuery }));
    } else {
      yield put(setReady(true));
    }
  } catch (err) {
    yield put(fetchAsyncFail(INIT, err));
    throw Log.withFriendlyMsg('Failed to fetch Gooten products', err, { action, productsData });
  }
}

export function* productChangeAsyncHandler(action) {
  if (action.payload) {
    const existingProductDetails = yield select(gootenProductDetailsSelector);
    if (!existingProductDetails) {
      // was not loaded yet
      const { productName } = yield select(getSKUsSelectionQuery);
      yield put(fetchAsync(PRODUCT_DETAILS_FETCH, productName));
      yield take(PRODUCT_DETAILS_FETCH.SUCCESS);
    }
    yield put(fetchAsync(LOAD_SKUS));
  }
}

export function* loadSKUsAsyncAsyncHandler(action) {
  let skusSelectionQuery;
  try {
    skusSelectionQuery = yield select(getSKUsSelectionQuery);
    const existingSKUs = yield select(SKUsSelector);
    if (!existingSKUs) {
      // was not loaded yet
      yield put(fetchAsync(PRODUCT_VARIANTS_FETCH, skusSelectionQuery.productName));
      yield take(PRODUCT_VARIANTS_FETCH.SUCCESS);
    }
    const productDetails = yield select(gootenProductDetailsSelector);
    yield put(
      fetchAsyncSuccess(LOAD_SKUS, {
        selectedRegionIndex: linkProductService.getDefaultRegionIndex(productDetails.regions),
        regions: fromJS(productDetails.regions)
      })
    );
  } catch (err) {
    yield put(fetchAsyncFail(LOAD_SKUS, err));
    throw Log.withFriendlyMsg('Failed to fetch Gooten SKUs', err, { action, skusSelectionQuery });
  }
}

export function* skipHandler(action) {
  const mappings = yield select(variantsMappingsSelector);
  const currentIndex = yield select(currentVariantIndexSelector);
  // Try find after
  let nextIndex = mappings
    .skip(currentIndex + 1)
    .findIndex(m => !m.get('uniqueProduct') && !m.get('skiped'));
  if (nextIndex === -1) {
    // Try find before
    mappings.findIndex(m => !m.get('uniqueProduct') && !m.get('skiped'));
  } else {
    nextIndex = nextIndex + currentIndex + 1;
  }

  // all filled or skiped
  if (nextIndex === -1) {
    if (mappings.find(m => m.get('uniqueProduct'))) {
      // can go to next step
      yield put(canGoNext(true));
      nextIndex = mappings.get(currentIndex + 1) ? currentIndex + 1 : 0;
    } else {
      // all skiped - redirect to losting
      const providerPath = yield select(providerPathSelector);
      window.location.hash = providerPath;
    }
  } else {
    yield put(canGoNext(false));
  }

  yield put(setCurrentVariantIndex(nextIndex));
}

export function* watchInitAsync() {
  yield takeEvery(INIT.ASYNC, initAsyncHandler);
}

export function* watchProductChangeAsync() {
  yield takeLatest(SELECT_PRODUCT, productChangeAsyncHandler);
}

export function* watchLoadSKUsAsync() {
  yield takeLatest(LOAD_SKUS.ASYNC, loadSKUsAsyncAsyncHandler);
}

export function* watchSkip() {
  yield takeEvery(SKIP_MAPPING, skipHandler);
}

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([watchInitAsync(), watchProductChangeAsync(), watchLoadSKUsAsync(), watchSkip()]);
}
