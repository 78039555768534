import React from 'react';
import PropTypes from 'prop-types';

import './CoreLayout.scss';

export const CoreLayout = ({ children }) => {
  return <div className="core-layout-container">{children}</div>;
};

CoreLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
};

export default CoreLayout;
