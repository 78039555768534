export const reflect = promise =>
  promise
    .then(data => ({ data, status: 'resolved' }))
    .catch(error => ({ error, status: 'rejected' }));

export const cancelable = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => !hasCanceled_ && resolve(val));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const cancelableWithError = promise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    /* eslint-disable prefer-promise-reject-errors */
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    );
    /* eslint-enable prefer-promise-reject-errors */
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};

export const mapRetryableTask = (asyncFn, paramsObj, maxRetryCount, onMaxRetryReachedFn) => {
  const task = {
    promise: undefined,
    start: () => {
      task.promise = asyncFn()
        .then(() => {
          task.finished = true;
        })
        .catch(() => {
          task.retry();
        });

      return task;
    },
    retry: () => {
      if (task.retryCount < maxRetryCount) {
        task.retryCount++;
        task.start();
      } else {
        onMaxRetryReachedFn(task.params);
      }
    },
    retryCount: 0,
    params: paramsObj,
    finished: false
  };

  return task.start();
};

export function* runRetryableTasks(tasks, onProgressAsyncFn) {
  if (!tasks.length) return;

  let current = 0;
  const total = tasks.length;

  // init progress - show 0 of n
  yield onProgressAsyncFn(current, total);

  while (tasks.filter(t => !t.finished).length) {
    let promises = tasks.filter(t => !t.finished).map(t => t.promise);
    // wait until some finished
    yield Promise.race(promises);
    // update progress
    current = tasks.filter(t => t.finished).length;
    yield onProgressAsyncFn(current, total);
  }
}
