import { List } from 'immutable';
import PagedCollection from '../../../../models/PagedCollection';
import { mapProduct } from '../../../../models/Product';
import { mapProductVariant } from '../../../../models/ProductVariant';
import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../../config';

const viewByStatusOptions = ['published', 'unpublished'];

class ProductsService {
  _loadProducts(url, queryParams, viewBy) {
    if (viewBy) {
      if (viewByStatusOptions.indexOf(viewBy) > -1) {
        queryParams.status = viewBy;
      } else {
        queryParams.collection = viewBy;
      }
    }

    // TODO: replace map product
    return fetch(url, { queryParams }).then(resp => {
      return { items: resp.products, total: resp.total };
    });
  }

  _loadAllProducts(query) {
    const url = `${Config.get('storeApi')}products/all`;
    let queryParams = {
      ...this.getAuthQueryParams(),
      connected: true,
      search: query.get('search'),
      sort: query.get('sortBy'),
      page: query.get('page'),
      pageSize: query.get('pageSize')
    };
    const viewBy = query.get('viewBy');

    return this._loadProducts(url, queryParams, viewBy);
  }

  _loadStoreProducts(query) {
    const store = query.get('store');
    const url = `${Config.get('storeApi')}stores/${store}/products`;
    let queryParams = {
      ...this.getAuthQueryParams(),
      connected: true,
      search: query.get('search'),
      page: query.get('page'),
      sort: query.get('sortBy'),
      pageSize: query.get('pageSize')
    };

    const viewBy = query.get('viewBy');

    if (viewBy) {
      // load products from specific collection or status
      return this._loadProducts(url, queryParams, viewBy);
    } else {
      return this._loadProducts(url, queryParams);
      // search without viewBy
    }
  }

  _loadStorageProducts(query) {
    const url = `${Config.get('storeApi')}storage/products`;
    const queryParams = {
      ...this.getAuthQueryParams(),
      search: query.get('search'),
      page: query.get('page'),
      sort: query.get('sortBy'),
      pageSize: query.get('pageSize')
    };

    return this._loadProducts(url, queryParams);
  }

  loadProductsQuery(query) {
    if (!this.isQueryValid(query)) {
      throw new Error('loadProductsQuery() failed because query is not valid');
    }
    // NOTE: Use /products/all endpoint in case of all provider
    // otherwise use /storage/products or /store/id/products respectively

    const provider = query.get('provider');
    let loadProductsTask = null;

    switch (provider) {
      case 'all':
        // load all products
        loadProductsTask = this._loadAllProducts(query);
        break;

      case 'storage':
        // load storage products
        loadProductsTask = this._loadStorageProducts(query);
        break;

      case 'shopify':
      case 'etsy':
      case 'woocommerce':
      case 'bigcommerce':
      case 'tiktok':
        // load store products
        loadProductsTask = this._loadStoreProducts(query);
        break;

      default:
        throw new Error(
          `loadProductsQuery() failed because provider is unknown, provider: ${provider}`
        );
    }

    return loadProductsTask.then(res => {
      return new PagedCollection({
        items: new List(res.items.map(p => mapProduct(p))),
        total: res.total
      });
    });
  }

  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      apiKey: Config.get('storeApiKey')
    };
  }

  _loadStoreProductVariants(product) {
    let url = `${Config.get('storeApi')}stores/${product.storeId}/products/${product.id}/variants`;

    // NOTE: taking only variants which are connected, has gooten_mapping obj
    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(
      result =>
        new List(
          result.variants
            .filter(v => !!v.gooten_mapping)
            .sort((x, y) => x.gooten_mapping.id - y.gooten_mapping.id)
            .map(mapProductVariant)
        )
    );
  }

  _loadStorageProductVariants(product) {
    let url = `${Config.get('storeApi')}storage/products/${product.id}/variants`;

    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(
      result =>
        new List(
          result.variants
            .sort((x, y) => x.gooten_mapping.id - y.gooten_mapping.id)
            .map(mapProductVariant)
        )
    );
  }

  loadProductVariants(product) {
    if (!product) {
      throw new Error('loadProductVariants() failed because product is not specified');
    }

    return product.type === 'store'
      ? this._loadStoreProductVariants(product)
      : this._loadStorageProductVariants(product);
  }

  loadProductCollections(product) {
    let url = `${Config.get('storeApi')}stores/${product.storeId}/products/${
      product.id
    }/collections`;

    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(
      result => new List(result.collections)
    );
  }

  _getStoreProductConnections(product) {
    let url = `${Config.get('storeApi')}stores/${product.storeId}/products/${
      product.id
    }/connections`;
    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(
      res =>
        new List(
          res.connections.map(c => {
            let mappedProduct = mapProduct(c.product);
            // NOTE: always sort variants by gooten_mapping.id b/c they may returns in random order
            // which then cause issue on publish
            // NOTE: taking only variants which are connected, has gooten_mapping obj
            return mappedProduct.set(
              'variants',
              new List(
                c.product.variants
                  .filter(v => !!v.gooten_mapping)
                  .sort((x, y) => x.gooten_mapping.id - y.gooten_mapping.id)
                  .map(mapProductVariant)
              )
            );
          })
        )
    );
  }

  _getStorageProductConnections(product) {
    let url = `${Config.get('storeApi')}storage/products/${product.id}/connections`;
    return fetch(url, { queryParams: this.getAuthQueryParams() }).then(
      res =>
        new List(
          res.connections.map(c => {
            let mappedProduct = mapProduct(c.product);
            // NOTE: taking only variants which are connected, has gooten_mapping obj
            return mappedProduct.set(
              'variants',
              new List(
                c.product.variants
                  .filter(v => !!v.gooten_mapping)
                  .sort((x, y) => x.gooten_mapping.id - y.gooten_mapping.id)
                  .map(mapProductVariant)
              )
            );
          })
        )
    );
  }

  getProductConnections(product) {
    if (!product) {
      throw new Error('getProductConnections() failed because product is not specified');
    }

    return product.type === 'store'
      ? this._getStoreProductConnections(product)
      : this._getStorageProductConnections(product);
  }

  isQueryValid(query) {
    if (!query.get('provider')) {
      return false;
    }

    if (
      query.get('provider') &&
      query.get('provider') !== 'all' &&
      query.get('provider') !== 'storage' &&
      !query.get('store')
    ) {
      return false;
    }

    if (!query.get('page')) {
      return false;
    }

    if (!query.get('pageSize')) {
      return false;
    }

    return true;
  }

  getTaskResult(taskId) {
    return fetch(`${Config.get('storeApi')}tasks/${taskId}/status`, {
      queryParams: this.getAuthQueryParams()
    });
  }

  getQueryCacheKey(query) {
    return [
      query.get('provider'),
      query.get('store'),
      query.get('viewBy'),
      query.get('sortBy'),
      query.get('search'),
      query.get('pageSize'),
      query.get('page')
    ]
      .filter(x => !!x)
      .join('-');
  }

  getProductUrlInStore(store, product) {
    if (!store || !product) {
      return null;
    }

    if (product.get('status') === 'storage' || product.get('status') === 'unpublished') {
      return null;
    }

    if (!product.get('handle')) {
      return null;
    }

    if (product.get('image') && product.get('image').includes('tiktokcdn')) {
      return null;
    }

    if (product.get('handle').startsWith('http')) {
      return product.get('handle');
    } else {
      return `https://${store}.myshopify.com/products/${product.get('handle')}`;
    }
  }
}

export default new ProductsService();
