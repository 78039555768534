import { fromJS } from 'immutable';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import {
  SAVE_IMAGE_URL,
  SAVE_REPRINT_DATA,
  SAVE_ORDER_SUMMARY,
  SHOW_LOADING,
  SAVE_PARTNER_DETAILS,
  SAVE_PARTNER_PREFERENCES,
  TOAST_TEXT,
  INIT_ALL_REASONS,
  SET_SELECTED_RULES,
  REASON_SELECT
} from './ReprintSelfServiceActions';

export const defaultState = fromJS({
  imagesEvidence: [],
  reprintData: {},
  orderSummary: {},
  loading: false,
  partnerDetails: {},
  partnerPreferences: {},
  toastText: '',
  allReasons: [],
  selectedRules: [],
  selectedReason: 0
});

const reprintSelfServiceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SAVE_IMAGE_URL:
      return state.update('imagesEvidence', images => images.push(...action.payload));

    case SAVE_REPRINT_DATA:
      return state.set('reprintData', action.payload);

    case SAVE_ORDER_SUMMARY:
      return state.set('orderSummary', action.payload);

    case SHOW_LOADING:
      return state.set('loading', action.payload);

    case SAVE_PARTNER_DETAILS:
      return state.set('partnerDetails', action.payload);

    case SAVE_PARTNER_PREFERENCES:
      return state.set('partnerPreferences', action.payload);

    case TOAST_TEXT:
      return state.set('toastText', action.payload);

    case INIT_ALL_REASONS:
      return state.set('allReasons', action.payload);

    case SET_SELECTED_RULES:
      return state.set('selectedRules', action.payload);

    case REASON_SELECT:
      return state.set('selectedReason', action.payload);

    case RESET:
      return defaultState;

    default:
      return state;
  }
};

export default reprintSelfServiceReducer;
