import { connect } from 'react-redux';
import ProductSelection from './ProductSelection';
import {
  PRODUCTS_FETCH,
  cleanSelection,
  fetchAsync,
  selectProducts
} from '../../store/actions/dataActions';
import {
  productsCountSelector,
  sidebarSelector,
  hasSelectedProductSelector
} from './ProductSelectionSelectors';
import { clickOutside } from './ProductSelectionActions';
import { closeSideBar } from './atoms/ProductDetails/ProductDetailsActions';
import withRouteStep from '../../hocs/withRouteStep';
import { next } from '../../containers/NavManager/NavManagerActions';

/*
    This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component
*/

const mapDispatchToProps = {
  useProduct: productId => selectProducts([productId]),
  gotoNextStep: () => next(),
  loadData: () => fetchAsync(PRODUCTS_FETCH),
  cleanSelection: () => cleanSelection(),
  clickOutside: () => clickOutside(),
  closeSideBar: () => closeSideBar()
};

const mapStateToProps = state => {
  let hasData = productsCountSelector(state) > 0;

  return {
    hasData,
    hasSelectedProduct: hasSelectedProductSelector(state),
    sidebar: sidebarSelector(state)
  };
};

export default withRouteStep(
  connect(mapStateToProps, mapDispatchToProps)(ProductSelection),
  'product-selection'
);
