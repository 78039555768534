import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import FulfillmentContactEmails from './FulfillmentContactEmails';
import BaseCard from '../BaseCard';
import RadioButtonGroup from 'gooten-components/src/components/shared/RadioButtonGroup';

const enabled = 'enabled';
const disabled = 'disabled';
const radioButtonOptions = new List([enabled, disabled]);

class EmailPreferences extends React.Component {
  static get name() {
    return 'emailPreferences';
  }
  static get messages() {
    return {
      onFailure: 'Failed to update Email Preferences',
      onSuccess: 'Your Email Preferences has been updated'
    };
  }

  componentDidMount() {
    this.props.init();
  }

  updateField(value, field) {
    this.props.change(value, field);
    if (field === 'ReceiveConfirmationShippingEmails') {
      const data = this.props.data.set(field, value);
      this.props.update({ data });
    } else if (field === 'ReceiveOccasionalEmails') {
      this.props.updateOccasionalEmails(value);
    }
  }

  get canUpdate() {
    return this.props.ready;
  }

  renderInput(name) {
    return (
      <RadioButtonGroup
        items={radioButtonOptions}
        disabled={!this.canUpdate}
        selectionIndex={this.props.data.get(name) ? 0 : 1}
        onChange={value => this.updateField(value === enabled, name)}
      />
    );
  }

  render() {
    return (
      <div>
        <BaseCard card={EmailPreferences.name} title="Email Preferences">
          <div className="email-preferences-card">
            <div className="row">
              <div className="col-xs-12">
                <h5 className="notification-label mb-1">Occasional emails from Gooten</h5>
                <p className="notification-description mb-4">
                  It’s the best way to hear about product updates and new features in Gooten.
                </p>
              </div>
              <div className="col-xs-12">{this.renderInput('ReceiveOccasionalEmails', true)}</div>
            </div>
            <hr className="section-line mb-2" />
            <div className="row">
              <div className="col-xs-12">
                <h5 className="notification-label">Order and Shipping Notifications</h5>
                <p className="notification-description mb-4">
                  Recieve emails when an order is placed by your customer and when an order is
                  shipped by the Gooten vendor network.
                </p>
              </div>
              <div className="col-xs-12">
                {this.renderInput('ReceiveConfirmationShippingEmails')}
              </div>
            </div>
            <FulfillmentContactEmails emails={this.props.data.get('FulfillmentContactEmails')} />
          </div>
        </BaseCard>
      </div>
    );
  }
}

EmailPreferences.propTypes = {
  init: PropTypes.func.isRequired,
  data: PropTypes.object,
  change: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateOccasionalEmails: PropTypes.func.isRequired,
  ready: PropTypes.bool
};

export default EmailPreferences;
