import { List } from 'immutable';
import PagedCollection from '../../../../models/PagedCollection';
import { fetch } from 'gooten-components/src/utils/http';
import Config from '../../../../config';
import { mapOrderItem } from '../../../../models/OrderItem';

class PastOrdersService {
  loadPastOrderItems(query) {
    // url
    const url = `${Config.get('api')}orderitemssearch/`;

    // request params
    const queryParams = {
      ...this.getAuthQueryParams(),
      genericValues: query.searchText,
      page: query.page,
      pageSize: query.pageSize,
      sortField: 'orderItemId',
      desc: true,
      countryCode: query.countryCode
    };

    // do fetch call
    return fetch(url, { queryParams }).then(res => {
      return new PagedCollection({
        items: new List(
          // Use OrderItem model to map each item of PagedCollection
          res.OrderItems.map(mapOrderItem)
        ),
        totalPages: res.TotalPages,
        total: res.Total
      });
    });
  }

  // recipeId, partnerBillingKey auth params
  getAuthQueryParams() {
    return {
      recipeId: Config.get('recipeId'),
      partnerBillingKey: Config.get('storeApiKey')
    };
  }
}

export default new PastOrdersService();
