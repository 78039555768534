const cardSelector = (state, cardName) => state.getIn(['settings', 'cards', cardName]);

export const openStateSelector = (state, cardName) => cardSelector(state, cardName).get('open');

export const loadingStatusSelector = (state, cardName) =>
  cardSelector(state, cardName).get('loading');

export const busyStatusSelector = (state, cardName) => cardSelector(state, cardName).get('busy');

export const cardsSelector = state => state.getIn(['settings', 'cards']);

export const cardSelectorFor = card => state => state.getIn(['settings', 'cards', card]);
