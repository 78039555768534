import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_BILLING_DATA,
  fetchBillingDataSuccess,
  fetchBillingDataFail
} from './BillingViewActions';
import BillingViewService from './BillingViewService';

export function* fetchCostBreakDownRequest(action) {
  try {
    const [costBreakdown, orderBilling] = yield all([
      call([BillingViewService, BillingViewService.fetchOrderItemsCostsBreakdown], action.payload),
      call([BillingViewService, BillingViewService.fetchOrderBilling], action.payload)
    ]);

    yield put(fetchBillingDataSuccess({ costBreakdown, orderBilling }));
  } catch (error) {
    yield put(fetchBillingDataFail());
  }
}

export function* watchSearch() {
  yield takeLatest(FETCH_BILLING_DATA.ASYNC, fetchCostBreakDownRequest);
}

export default function* rootSaga() {
  yield all([watchSearch()]);
}
