import { combineReducers } from 'redux-immutable';
import editProductViewReducer from './EditProductView/EditProductViewReducer';
import duplicateProductViewReducer from './DuplicateProductView/DuplicateProductViewReducer';
import linkProductReducer from './LinkProduct/LinkProductReducer';
import hubViewReducer from './HubView/HubViewReducer';
import connectStoreViewReducer from './ConnectStoreView/ConnectStoreViewReducer';
import placeOrderViewReducer from './PlaceOrderView/PlaceOrderViewReducer';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

export default combineReducers({
  connectStoreView: connectStoreViewReducer,
  hubView: hubViewReducer,
  editProductView: editProductViewReducer,
  duplicateProductView: duplicateProductViewReducer,
  linkProduct: linkProductReducer,
  placeOrder: placeOrderViewReducer
});
