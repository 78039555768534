import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Icon from './Icon';

const Pager = ({ current, goTo, maxSize = 5, items, fullButtons }) => {
  const renderItems = items => {
    return items.map(i => (
      <React.Fragment key={i.id}>
        {i.name === '...' ? (
          <span className="p-2 title-2">...</span>
        ) : (
          <Button
            iconOnly={!fullButtons}
            className={
              'medium mr-1 ml-1 ' +
              (i.selected ? 'button-secondary ' : '') +
              (i.active ? 'button-default ' : '') +
              (i.special ? '' : '')
            }
            selected={i.selected}
            key={i.id}
            onClick={() => (i.active ? goTo(i.id) : null)}
          >
            {i.name}
          </Button>
        )}
      </React.Fragment>
    ));
  };

  const prepareItem = (item, index) => {
    let selected = index === current;
    return { id: index, name: item.name, selected: selected, active: !selected };
  };

  const prepareItems = () => {
    if (items.length <= maxSize) {
      return items.map((item, index) => prepareItem(item, item.index || index));
    }
    let start = current - Math.floor(maxSize / 2);
    if (start < 0) {
      start = 0;
    }
    let finish = start + (start > 0 ? maxSize - 1 : maxSize);
    if (finish >= items.length) {
      finish = items.length - 1;
    }

    let elements = [];

    if (start > 0) {
      elements.push(prepareItem(items[0], items[0].index || 0));
    }

    if (start > 1) {
      elements.push({ id: 'separator', name: '...', active: false });
    }

    let lastIndex;
    items.slice(start, finish + 1).forEach((item, index) => {
      lastIndex = item.index || start + index;
      elements.push(prepareItem(item, lastIndex));
    });

    if (finish < items.length - 1) {
      elements.push({
        id: lastIndex + 1,
        name: (
          <>
            &nbsp;
            <Icon className="m-0" icon="chevronRight" />
            &nbsp;
          </>
        ),
        active: true,
        special: true
      });
    }

    return elements;
  };

  let allItems = prepareItems();
  return (
    <>
      {items.length <= 1 ? (
        <div className="component-pager" />
      ) : (
        <center className="component-pager mb-4">{renderItems(allItems)}</center>
      )}
    </>
  );
};

Pager.propTypes = {
  current: PropTypes.number,
  maxSize: PropTypes.number,
  items: PropTypes.array,
  goTo: PropTypes.func,
  fullButtons: PropTypes.bool
};

export default Pager;
