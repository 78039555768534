import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';

const Toast = ({ className, closeClick, buttonText, textButtonClick, children, href, isOpen }) => {
  return (
    <div className={`${className || ''} toast m-auto dropshadow-2`}>
      <div className="toast-body body-text-2 d-flex">{children}</div>
      {closeClick && (
        <button onClick={closeClick} className="toast-button button-1 dismiss">
          Dismiss
        </button>
      )}
      {(buttonText || href) && (
        <a className="button-1 heavy" href={href}>
          <button onClick={textButtonClick || null} className="toast-button">
            {buttonText}
          </button>
        </a>
      )}
      <style jsx>
        {`
          .toast {
            display: ${!isOpen ? 'none' : 'flex'};
            z-index: 1008;
            position: relative;
            max-width: 30rem;
            box-sizing: border-box;
            font-family: Avenir-Roman, Avenir Light, sans-serif;
            min-height: 3rem;
            width: fit-content;
            text-align: left;
            line-height: 1.6;
            border-radius: 4px;
            align-items: center;
            background: ${COLORS.neutralLight50};
            color: ${COLORS.neutralDark500};
            fill: ${COLORS.neutralDark500};
            justify-content: space-between;
            animation-duration: 0.5s;
            animation-name: slide-in;
            pointer-events: auto;
          }
          @media screen and (max-width: 576px) {
            .toast {
              width: calc(100vw - 4rem);
            }
          }
          a {
            line-height: 0;
            height: 100%;
            text-decoration: none !important;
            cursor: pointer;
          }
          @keyframes slide-in {
            from {
              transform: translateY(-100%);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
          .toast-body {
            padding: 0.75rem 1rem;
            align-items: center;
            box-shadow: inset -1px 0px 0px ${COLORS.neutralLight200};
            word-break: break-word;
          }

          .toast-button {
            height: 100%;
            min-height: 3rem;
            border: none;
            outline: none;
            box-shadow: none;
            background: ${COLORS.neutralLight50};
            border-radius: 0 0.25rem 0.25rem 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            letter-spacing: 0.03em;
            color: ${COLORS.gootenBlue};
            white-space: nowrap;
            cursor: pointer;
            text-decoration: none;
          }
          .dismiss {
            box-shadow: inset -1px 0px 0px ${COLORS.neutralLight200};
          }
          .toast-on-bottom {
            position: absolute;
            right: 0.75rem;
            bottom: 0.75rem;
          }
        `}
      </style>
    </div>
  );
};

Toast.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  closeClick: PropTypes.func,
  textButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  isOpen: PropTypes.bool
};

export default Toast;

export const ToastContainer = ({ children, onClick, duration }) => {
  const [toastContent, setToastContent] = useState(children);
  useEffect(() => {
    setTimeout(() => setToastContent(null), duration || 8000);
  }, [children]);

  return (
    <div onClick={onClick} className="toast-container">
      {toastContent}
      <style jsx>
        {`
          .toast-container {
            display: ${toastContent ? 'unset' : 'none'};
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: ${onClick ? 'unset' : 'none'};
          }
        `}
      </style>
    </div>
  );
};

ToastContainer.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  duration: PropTypes.duration
};
