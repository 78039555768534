export const sortSKUs = skus => {
  const pattern = [
    '-3XS-',
    '-2XS-',
    '-XS-',
    '-S-',
    '-M-',
    '-L-',
    '-XL-',
    '-2XL-',
    '-3XL-',
    '-4XL-',
    '-5XL-'
  ];

  return skus.sort((a, b) => {
    const aPattern = pattern.find(p => a.sku.includes(p));
    const bPattern = pattern.find(p => b.sku.includes(p));
    if (aPattern && bPattern) {
      const aIndex = a.sku.indexOf(aPattern) + aPattern.length;
      const aStr = a.sku.substr(aIndex, a.sku.indexOf('-', aIndex) - aIndex).toUpperCase();
      const bIndex = b.sku.indexOf(bPattern) + bPattern.length;
      const bStr = b.sku.substr(bIndex, b.sku.indexOf('-', bIndex) - bIndex).toUpperCase();

      if (aStr < bStr) return -1;
      if (aStr > bStr) return 1;
      if (pattern.indexOf(aPattern) < pattern.indexOf(bPattern)) return -1;
      if (pattern.indexOf(aPattern) > pattern.indexOf(bPattern)) return 1;
    }
    return 0;
  });
};
