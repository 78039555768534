import { connect } from 'react-redux';
import SideBar from './SideBar';
import { showBackdrop, hideBackdrop } from '../../../store/actions/globalActions';

/*
 This is a container component. Notice it does not contain any JSX,
 nor does it import React. This component is **only** responsible for
 wiring in the actions and state necessary to render a presentational
 component
 */

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  showBackdrop: () => showBackdrop(),
  hideBackdrop: () => hideBackdrop()
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
