import { takeLatest, put, call, all } from 'redux-saga/effects';
import { CREATE, UPDATE, REMOVE } from './NeckLabelsActions';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import NeckLabels from './NeckLabels';
import { watchInitAsync } from '../BaseCard/BaseCardSaga';
import { createNeckTag, removeNeckTag } from 'gooten-components/src/services/addOns';

export function* createAsyncHandler(action) {
  try {
    const response = yield call(createNeckTag, action.payload);
    action.payload.id = response.id;
    yield put(fetchAsyncSuccess(CREATE, action.payload));
    yield put(push('Neck Label created.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(CREATE, err));
    yield put(push('Failed to create Neck Label.', 'failure'));
  }
}

export function* watchCreate() {
  yield takeLatest(CREATE.ASYNC, createAsyncHandler);
}

export function* updateAsyncHandler(action) {
  try {
    yield call(createNeckTag, action.payload);
    yield put(fetchAsyncSuccess(UPDATE, action.payload));
    yield put(push('Neck Label updated.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE, err));
    yield put(push('Failed to update Neck Label.', 'failure'));
  }
}

export function* watchUpdate() {
  yield takeLatest(UPDATE.ASYNC, updateAsyncHandler);
}

export function* removeAsyncHandler(action) {
  try {
    yield call(removeNeckTag, action.payload.id);
    yield put(fetchAsyncSuccess(REMOVE, action.payload));
    yield put(push('Neck Label removed.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(REMOVE, err));
    yield put(push('Failed to remove Neck Label.', 'failure'));
  }
}

export function* watchRemove() {
  yield takeLatest(REMOVE.ASYNC, removeAsyncHandler);
}

export default function* rootSaga() {
  yield all([
    watchInitAsync(NeckLabels.name, NeckLabels.messages),
    watchCreate(),
    watchUpdate(),
    watchRemove()
  ]);
}
