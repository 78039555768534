import React from 'react';
import PropTypes from 'prop-types';
import Product from '../Product';
import { List } from 'immutable';

export const ProductListLine = props => {
  const subCategory = props.subcategoryName ? (
    <p className="title-1 ml-3 mb-1 mt-3">{props.subcategoryName}</p>
  ) : null;

  const activeProductId = props.activeProductId;

  const onActivate = props.onActivate;

  return (
    <div
      className="product-list-line-container mt-4 w-100"
      id={`product-list-line-${
        props.subcategoryName ? props.subcategoryName.toLowerCase().replace(/ /g, '-') : 'all'
      }`}
    >
      {subCategory}
      <div className="products-list-line-grid w-100">
        {props.products.map((p, i) => (
          <Product
            key={p.id}
            product={p}
            onActivate={() => onActivate(p.id)}
            isActive={activeProductId === p.id}
            onClickGetStarted={props.onClickGetStarted}
            onClickLearnMore={props.onClickLearnMore}
            showPricingModal={props.showPricingModal}
            exportPricingList={props.exportPricingList}
          />
        ))}
      </div>
      <style jsx>
        {`
          .products-list-line-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
            row-gap: 1rem;
            column-gap: 1rem;
            padding: 1rem 0;
          }
        `}
      </style>
    </div>
  );
};

ProductListLine.propTypes = {
  subcategoryName: PropTypes.string,
  products: PropTypes.instanceOf(List).isRequired,
  activeProductId: PropTypes.number,
  onActivate: PropTypes.func,
  onClickLearnMore: PropTypes.func,
  onClickGetStarted: PropTypes.func
};

export default ProductListLine;
