import Log from 'gooten-components/src/services/logService';

const loggingMiddleware = store => next => action => {
  try {
    // log every action so they appear in the telemetry timeline
    return next(action);
  } catch (err) {
    // Dump state remove big data from there
    let stateDump = store.getState().toJS();
    delete stateDump.data.products;
    delete stateDump.data.categories;
    // Log unhandled reducer errors
    const errorMsg = `Reducer ${err.friendlyMsg || err}`;
    Log.error(err, errorMsg, { ...err.details, action, stateDump });
    throw err;
  }
};

export default loggingMiddleware;
