import { takeLatest, put, select, call, take, all } from 'redux-saga/effects';
import { CHANGE_STEP, changeStep } from './EditProductViewActions';
import {
  VARIANTS_LOAD,
  CONNECTIONS_LOAD,
  COLLECTIONS_LOAD
} from '../HubView/components/Products/ProductsActions';
import { updatePublishData } from 'gooten-components/src/components/ProductPublish/ProductPublishActions';
import {
  PRODUCTS_FETCH,
  PRODUCT_DETAILS_FETCH,
  PRODUCT_VARIANTS_FETCH,
  fetchAsync,
  fetchAsyncSuccess,
  selectProducts,
  skuSelect,
  TEMPLATES_FETCH
} from 'gooten-components/src/store/actions/dataActions';
import {
  hasProductsSelector,
  productsSelector,
  selectedProductForEditSelector
} from './EditProductViewSelectors';
import { connectionsSelector } from '../HubView/components/Products/ProductsSelectors';
import { selectedProductVariantsSelector } from 'gooten-components/src/store/selectors/hubSelectors';
import { allStoresSelector } from '../HubView/components/Stores/StoresSelectors';
import { spacesLoadedSelector } from 'gooten-components/src/components/ImageUpload/ImageUploadSelectors';
import editProductViewService from './EditProductViewService';
import productsService from '../HubView/components/Products/ProductsService';
import { setPublishedOrientationChanged } from 'gooten-components/src/components/SKUSelection/SKUSelectionActions';
import { selectedSKUsSelector } from 'gooten-components/src/store/selectors/productDataSelectors';

export function* watchStepChange() {
  yield takeLatest(CHANGE_STEP, stepChangeHandler);
}

export function* stepChangeHandler(action) {
  let product = yield select(selectedProductForEditSelector);
  let immutableVariants = yield select(selectedProductVariantsSelector);
  let variants = immutableVariants ? immutableVariants.toJS() : null;

  if (!product) {
    return;
  }

  switch (action.payload.step) {
    case 'initial':
      let hasProducts = yield select(hasProductsSelector);
      if (!hasProducts) {
        yield put(fetchAsync(PRODUCTS_FETCH));
        yield take(PRODUCTS_FETCH.SUCCESS);
      }
      yield put(changeStep('productsLoaded'));
      break;
    case 'productsLoaded':
      if (!variants) {
        yield put(fetchAsync(VARIANTS_LOAD, { product }));
        yield take(VARIANTS_LOAD.SUCCESS);
      }
      yield put(changeStep('variantsLoaded'));
      break;
    case 'variantsLoaded':
      yield put(fetchAsync(COLLECTIONS_LOAD, { product }));
      yield take(COLLECTIONS_LOAD.SUCCESS);
      yield put(changeStep('collectionsLoaded'));
      break;
    case 'collectionsLoaded':
      const gootenMappings = variants.map(v => v.gootenMapping);
      if (!gootenMappings.length) {
        yield put(changeStep('productMappingsInvalid'));
        break;
      }
      const stores = yield select(allStoresSelector);
      const productIds = Array.from(
        new Set(gootenMappings.map(item => item.items[0] && item.items[0].productId))
      );
      const productsList = yield select(productsSelector);
      const gootenProducts = productsList.filter(p => productIds.some(pId => pId === p.id));
      if (!gootenProducts.length) {
        return yield put(changeStep('productNotAvailable'));
      }

      // get other products with which this product was published with
      const connections = yield call(
        [productsService, productsService.getProductConnections],
        product
      );
      const validConnections = editProductViewService.filterValidConnections(connections, variants);
      yield put(fetchAsyncSuccess(CONNECTIONS_LOAD, { connections: validConnections }));

      // load collections for all connections, and update...
      for (let p of validConnections) {
        if (p.get('id') !== product.id) {
          yield put(fetchAsync(COLLECTIONS_LOAD, { product: p.toJS() }));
          yield take(COLLECTIONS_LOAD.SUCCESS);
        }
      }

      const updatedConnections = yield select(connectionsSelector);
      const skusData = editProductViewService.getSelectedSkusData(product, variants);
      const publishData = editProductViewService.getPublishData(
        product,
        variants,
        stores,
        updatedConnections.toJS()
      );

      yield put(selectProducts(productIds));
      yield put(skuSelect(skusData));
      yield put(updatePublishData(publishData));

      for (const gProduct of gootenProducts) {
        yield put(fetchAsync(PRODUCT_VARIANTS_FETCH, gProduct.name));
        yield put(fetchAsync(PRODUCT_DETAILS_FETCH, gProduct.name));
        yield all([take(PRODUCT_VARIANTS_FETCH.SUCCESS), take(PRODUCT_DETAILS_FETCH.SUCCESS)]);
      }

      // get saved orientation from gooten_mapping, and store it
      const orientationChanged = gootenMappings.find(m => m.orientation === 'changed');
      if (orientationChanged) {
        yield put(setPublishedOrientationChanged('changed'));
      }

      yield put(changeStep('detailsLoaded'));
      break;
    case 'detailsLoaded':
      let spacesLoaded = yield select(spacesLoadedSelector);
      if (!spacesLoaded) {
        yield put(fetchAsync(TEMPLATES_FETCH));
        yield take(TEMPLATES_FETCH.SUCCESS);
      }
      yield put(changeStep('templatesLoaded'));
      break;
    case 'templatesLoaded':
      // check if all skus are disabled
      const selectedSkus = yield select(selectedSKUsSelector);

      if (!selectedSkus.size) {
        yield put(changeStep('productNotAvailable'));
        break;
      }
      yield put(changeStep('ready'));
      break;
  }
}

export default function* rootSaga() {
  yield all([watchStepChange()]);
}
