import { STORE_CONNECT, INIT_CONNECT } from './ConnectStoreViewActions';
import { RESET } from '../../store/actions/globalActions';
import initialState from '../../store/initialState';

const defaultState = initialState.getIn(['hub', 'connectStoreView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET: {
      if (action.payload.scope === 'global') {
        // reset state only if scope is global
        return defaultState;
      } else {
        return state;
      }
    }

    case INIT_CONNECT.ASYNC: {
      return state.set('ready', false).set('errorMsg', '');
    }
    case INIT_CONNECT.FAIL: {
      return state.set('errorMsg', action.payload.errorMsg);
    }
    case INIT_CONNECT.SUCCESS: {
      return state
        .set('ready', true)
        .setIn(['initData', action.payload.provider], action.payload.initData);
    }

    case STORE_CONNECT.ASYNC: {
      return state
        .set('provider', action.payload.provider)
        .set('connecting', true)
        .set('errorMsg', '');
    }

    case STORE_CONNECT.FAIL: {
      return state.set('connecting', false).set('errorMsg', action.payload.errorMsg);
    }

    case STORE_CONNECT.SUCCESS: {
      return state.set('connecting', false).set('errorMsg', '');
    }

    case STORE_CONNECT.CANCEL: {
      const initData = state.get('initData');
      return defaultState.set('initData', initData);
    }

    default:
      return state;
  }
};
