import { createSelector } from 'reselect';
import { dedupePreviewSKUs } from './services/productPreviewService';
import { getProductVariants } from './../../store/selectors/productDataSelectors';
import { deepEqual } from './../../utils/object';
import { selectedProviderSelector } from '../ProductPublish/atoms/ProvidersTab/ProvidersTabSelectors';
import { productsSelector } from '../ProductPublish/ProductPublishSelectors';
import { doesOrientationMatch } from '../../services/externalMockupsService';
import { selectedOptionsSelector } from '../SKUSelection/SKUSelectionSelectors';

export const previewConfigSelector = state => state.getIn(['preview', 'config']).toJS();
export const generationStateSelector = state => state.getIn(['preview', 'generationState']).toJS();
export const isMaximizedSelector = state => state.getIn(['preview', 'maximized']);

export const isFullScreenPreviewSelector = state => {
  const fullScreenState = state.getIn(['preview', 'fullScreenPreview']);
  return fullScreenState && fullScreenState.get('enabled');
};

export const isExclusiveMockupSelector = createSelector(
  [state => state.getIn(['data', 'selectedSkus'])],
  skus => {
    return !skus
      .filter(sku => sku.get('proPreview') !== undefined || sku.get('externalMockup') !== undefined)
      .isEmpty();
  }
);

export const selectedItemsSelector = state => state.getIn(['preview', 'selectedItems']).toJS();

export const allSKUsSelector = createSelector(
  [state => state.getIn(['data', 'selectedSkus'])],
  skus => skus && skus.toJS()
);

const previewDataSelectorImmutable = state => state.getIn(['preview', 'data']);

export const previewDataSelector = createSelector(
  [previewDataSelectorImmutable],
  data => data && data.toJS()
);

export const sortPreviewItems = (previewItems, previewData) => {
  // sort new data comparing it to old data
  if (previewData.items?.length) {
    const arrayToCompare = previewData.items.filter(item =>
      previewItems.some(a => a.sku.toLowerCase() === item.sku.toLowerCase())
    );
    const oldDataWithIndex = arrayToCompare.map(item => item.sku + item.sId + item.dataIndex);

    // check if any sku has been added or removed
    const match = oldDataWithIndex.every(a =>
      previewItems.some(b => b.sku + b.sId + b.dataIndex === a)
    );
    if (match) {
      // Sort by old preview data items order with SKUs index (dataIndex)
      previewItems.sort(
        (a, b) =>
          oldDataWithIndex.indexOf(a.sku + a.sId + a.dataIndex) -
          oldDataWithIndex.indexOf(b.sku + b.sId + b.dataIndex)
      );
    } else {
      // Sort by old preview data items order if any sku has been removed or added (without SKUs index)
      const oldDataWOIndex = arrayToCompare.map(item => item.sku + item.sId);
      previewItems.sort(
        (a, b) => oldDataWOIndex.indexOf(a.sku + a.sId) - oldDataWOIndex.indexOf(b.sku + b.sId)
      );
    }
  }
};

export const sortExclusiveMockups = skusForPreviews => {
  // Check do we have pro and external mockups at all...
  if (
    skusForPreviews.filter(item => item.proPreview).length === 0 &&
    skusForPreviews.filter(item => item.externalMockup).length === 0
  ) {
    return skusForPreviews;
  }

  // Sort external mockups first...
  const externalMockups = skusForPreviews.filter(item => item.externalMockup);
  const proPreviews = skusForPreviews.filter(item => item.proPreview);
  const otherMockups = skusForPreviews.filter(item => !item.proPreview && !item.externalMockup);

  const sorted = [...externalMockups, ...proPreviews, ...otherMockups];

  // Keep in mind that one sku could have external + pro previews...
  const unique = sorted.filter(
    (item, index, self) =>
      index === self.findIndex(t => t.sku === item.sku && t.index === item.index)
  );

  return unique;
};

export const previewItemsSelector = createSelector(
  [allSKUsSelector, previewDataSelector, getProductVariants, selectedOptionsSelector],
  (skus, previewData, variants, selectedOptions) => {
    // check for apparel rules, and show only previews that will be published
    let aggregatedSKUsMap = {};
    let associatedSkusWithKeys = [];
    const aggregatedSKUs = dedupePreviewSKUs(
      skus.map(sku => ({
        sku: sku.sku,
        index: sku.index,
        spaces: sku.spaces.map(space => ({
          ...space,
          previewImgManipCmd: 'previewImgManipCmd'
        }))
      })),
      variants,
      aggregatedSKUsMap,
      {},
      associatedSkusWithKeys
    );

    let skusForPreviews = [];
    if (Object.keys(aggregatedSKUsMap).length) {
      for (const key in aggregatedSKUsMap) {
        const accociatedSkus = associatedSkusWithKeys.filter(x => x.key === key);
        const firstSku = accociatedSkus[0];
        const skuItem = skus.find(x => x.index === firstSku.index);

        // if 'skuForPreviews' already contains 'skuItem' with same sku and index, don't add it again...
        if (!skusForPreviews.find(s => s.sku === skuItem.sku && s.index === skuItem.index)) {
          skusForPreviews.push({
            ...skuItem,
            accociatedSkus: accociatedSkus.map(item => ({
              sku: item.sku,
              dataIndex: item.index
            }))
          });
        }
      }
    } else {
      skusForPreviews = skus
        .filter(item => aggregatedSKUs.some(a => a.sku.toLowerCase() === item.sku.toLowerCase()))
        .map(i => ({
          ...i,
          accociatedSkus: []
        }));
    }

    // On first load (previewData is empty)  we need to sort mockups by external first.
    // Put pro and external mockups at the top..
    if (!previewData.length && skusForPreviews.length) {
      skusForPreviews = sortExclusiveMockups(skusForPreviews);
    }

    const selectedOrientations = selectedOptions?.get('orientation');

    const previewItems =
      skusForPreviews &&
      skusForPreviews
        .reduce((a, c) => {
          // create pro previews if exist...
          const proPreviews =
            c.proPreview !== undefined
              ? c.proPreview.scenes.map(scene => ({
                  ...previewData.items.find(
                    item =>
                      item.sku.toLowerCase() === c.sku.toLowerCase() &&
                      item.sId === scene.name &&
                      item.dataIndex === c.index
                  ),
                  sku: c.sku,
                  isExclusiveMockup: true,
                  sId: scene.name,
                  view: scene.angle,
                  dataIndex: c.index,
                  base64: c.spaces[0].base64
                }))
              : [];

          // create external mockups if exist...
          const externalMockups =
            c.externalMockup !== undefined
              ? c.externalMockup.mockups
                  // we have to filter out external mockups by orientation, because
                  // template holds external mockups for both orientations
                  .filter(externalMockup =>
                    doesOrientationMatch(externalMockup, selectedOrientations)
                  )
                  .map(externalMockup => ({
                    ...previewData.items.find(
                      item =>
                        item.sku.toLowerCase() === c.sku.toLowerCase() &&
                        item.externalMockupId === externalMockup.ID &&
                        item.sId === '' &&
                        item.dataIndex === c.index
                    ),
                    externalMockupId: externalMockup.ID,
                    sId: '',
                    isExclusiveMockup: true,
                    sku: c.sku,
                    dataIndex: c.index,
                    base64: c.spaces[0].base64
                  }))
              : [];

          // create all other previews...
          const allOtherPreviews =
            c.proPreview === undefined
              ? c.spaces.map(space => {
                  const oldData = previewData.items.find(
                    item =>
                      item.sku.toLowerCase() === c.sku.toLowerCase() &&
                      item.sId === space.id &&
                      item.dataIndex === c.index
                  );
                  return {
                    ...oldData,
                    backgroundColor:
                      (oldData && oldData.backgroundColor) ||
                      (space.il && space.il.backgroundColor),
                    sku: c.sku,
                    isExclusiveMockup: false,
                    sId: space.id,
                    dataIndex: c.index,
                    previewImageUrl: space.previewImgUrl || (oldData && oldData.previewImageUrl),
                    base64: space.base64,
                    associatedSkus: c.accociatedSkus
                  };
                })
              : [];

          // Here we have some rules for pro previews and external mockups:
          // 1. If we have pro previews only, then show only them, and not all other previews...
          // 2. if we have external mockups only, then show External mockups first, and then all other previews...
          // 3. If we have pro previews and external mockups, then show external mockups first and then pro previews, without other previews...
          // 4. If we only have simple previews, then show only them...

          if (proPreviews.length && !externalMockups.length) {
            return a.concat(proPreviews);
          }
          if (!proPreviews.length && externalMockups.length) {
            return a.concat(externalMockups, allOtherPreviews);
          }
          if (proPreviews.length && externalMockups.length) {
            return a.concat(externalMockups, proPreviews);
          }
          if (!proPreviews.length && !externalMockups.length) {
            return a.concat(allOtherPreviews);
          }
        }, [])
        .filter(
          item =>
            !previewData.removedItems.some(
              a =>
                a.sku.toLowerCase() === item.sku.toLowerCase() &&
                a.sId === item.sId &&
                a.dataIndex === item.dataIndex
            )
        )
        .map(a => {
          // check if base64 preview was changed
          const base64Changed = !previewData.items.some(item => item.base64 === a.base64);

          // find preview item ('a') in old data
          const oldItem = previewData.items.find(
            item =>
              item.sku.toLowerCase() === a.sku.toLowerCase() &&
              item.sId === a.sId &&
              item.dataIndex === a.dataIndex
          );

          // condition to recreate preview:
          const recreate = base64Changed || !oldItem;

          return {
            ...a,
            generateHighRes: oldItem?.generateHighRes || recreate,
            generateFullscreen: oldItem?.generateFullscreen || recreate,
            generatePreview:
              oldItem?.generatePreview || recreate || a.smallPreviewImageUrl === undefined,
            fullscreenImageUrl:
              oldItem?.generateFullscreen || recreate ? null : a.fullscreenImageUrl
          };
        });

    sortPreviewItems(previewItems, previewData);

    return previewItems;
  }
);

export const showRemoveModalSelector = state =>
  state.getIn(['preview', 'modals', 'showRemoveModal']);
export const removeItemsModalDataSelector = state =>
  state.getIn(['preview', 'modals', 'removeItemsModal']).toJS();
export const proPreviewModalSelector = state =>
  state.getIn(['preview', 'modals', 'proPreviewsModal']).toJS();
export const downloadModalSelector = state =>
  state.getIn(['preview', 'modals', 'downloadModal']).toJS();
export const generatingModalSelector = state =>
  state.getIn(['preview', 'modals', 'generatingModal']).toJS();
export const shopifyImagesCountModalSelector = state =>
  state.getIn(['preview', 'modals', 'shopifyImagesCountModal']).toJS();

export const isEditMode = state => {
  return state && state.getIn(['preview', 'editMode', 'modeOn']);
};

export const editDataSelector = state => {
  return state && state.getIn(['preview', 'editMode']).toJS();
};

export const previewSizeSelector = state => {
  const previewSize = state.getIn(['preview', 'data', 'previewSize']);
  return previewSize && previewSize.toJS();
};

export const previewCountSelector = state => {
  const previewCount = state.getIn(['preview', 'generationState', 'previewGeneration']);
  return previewCount ? previewCount.toJS() : { done: 0, from: 0 };
};

export const generatedPreviewSizeSelector = state => {
  const previewSize = state.getIn(['preview', 'data', 'generatedPreviewSize']);
  return previewSize && previewSize.toJS();
};

export const shouldRegeneratePreviewsSelector = createSelector(
  [allSKUsSelector, previewDataSelector, previewSizeSelector],
  (skus, previewData, previewSize) => {
    const hasCreatedPrintUrl = skus.every(sku =>
      sku.spaces.every(space => space.printImgUrl && space.printImgUrl.length)
    );

    if (!hasCreatedPrintUrl) {
      return true;
    }

    if (!previewData.items.length) {
      return false;
    }

    if (
      previewData.items.some(
        item =>
          item.generateHighRes ||
          // if previews size has been changed, we need to regenerate high res previews
          !deepEqual(item.generatedHighResSize, previewSize)
      )
    ) {
      return true;
    }

    let isBackgroundChanged = false;
    skus.forEach(sku =>
      previewData.items
        .filter(
          item => item.sku.toLowerCase() === sku.sku.toLowerCase() && item.dataIndex === sku.index
        )
        .forEach(item => {
          const space = sku.spaces.find(s => s.id === item.sId);
          if (space && space.previewImgManipCmd) {
            const extentCmd = space.previewImgManipCmd.commands.find(com => com.name === 'extent');
            if (extentCmd) {
              if (item.backgroundColor && extentCmd.args.color !== item.backgroundColor) {
                isBackgroundChanged = true;
              }
            }
          }
        })
    );
    return isBackgroundChanged;
  }
);

export const disableControls = createSelector(
  [generationStateSelector, selectedItemsSelector],
  (generationState, selectedItems) => {
    return (
      generationState.state === 'WORKING' &&
      !selectedItems.every(item =>
        generationState.finishedSKUs.has(item.sku + item.sId + item.dataIndex)
      )
    );
  }
);

/*
  This selector is used for getting product name, which we will use for zip file name
  for downloaded mockups. if it's edit mode, then use custom product name, otherwise use
  Gooten product name
*/
export const productNameSelector = createSelector(
  [selectedProviderSelector, productsSelector],
  (selectedProvider, products) =>
    (selectedProvider && selectedProvider.get('productName')
      ? selectedProvider.get('productName')
      : products[0].name
    ).replace(/\s/g, '')
);
