import { fromJS } from 'immutable';
import { RESET } from 'gooten-components/src/store/actions/globalActions';
import {
  FETCH_REPORT_DATA,
  RESET_FILTER_AND_SORT,
  SET_SELECTED_PERIOD,
  SET_SORT
} from './ProductionReportActions';

export const defaultState = fromJS({
  data: [],
  error: '',
  inProgress: false,
  period: 1, // selected period is 1, "Last 30 days" is default,
  sort: {
    type: 'product', // default sort by product name,
    aToZ: true
  }
});

const productionReportReducer = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REPORT_DATA.ASYNC:
      return state.set('inProgress', true);

    case FETCH_REPORT_DATA.SUCCESS:
      return state.set('data', fromJS(action.payload)).set('error', '').set('inProgress', false);

    case FETCH_REPORT_DATA.FAIL:
    case FETCH_REPORT_DATA.CANCEL:
      return state.set('error', action.payload).set('inProgress', false);

    case SET_SELECTED_PERIOD:
      return state.set('period', action.payload);

    case SET_SORT:
      return state.set(
        'sort',
        fromJS({
          type: action.payload,
          aToZ:
            state.getIn(['sort', 'type']) === action.payload ? !state.getIn(['sort', 'aToZ']) : true
        })
      );

    case RESET_FILTER_AND_SORT:
      return state
        .set('period', 1) // selected period is 1, "Last 30 days" is default,
        .setIn(['sort', 'type'], 'product') // default sort by product name,
        .setIn(['sort', 'aToZ'], true);

    case RESET:
      return defaultState;

    default:
      return state;
  }
};

export default productionReportReducer;
