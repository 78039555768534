import { Map } from 'immutable';
import { CHECK_SOURCES } from './OrderFromViewActions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHECK_SOURCES.SUCCESS]: (state, action) =>
    state
      .set('loaded', true)
      .set('hasPastOrders', action.payload.hasPastOrders)
      .set('hasHubProducts', action.payload.hasHubProducts)
};

const defaultState = new Map({
  loaded: false,
  hasPastOrders: false,
  hasHubProducts: false
});

// ------------------------------------
// Reducer
// ------------------------------------
export default function (state = defaultState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
