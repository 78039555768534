import { ActionNameFactory } from 'gooten-components/src/utils/actions';
import { fetchAsync } from 'gooten-components/src/store/actions/dataActions';
import PagedQuery from '../../../../models/PagedQuery';

// ------------------------------------
// Component Action Names Factory
// ------------------------------------
// Creates getActionNameFn with ModuleName and ComponentName
export const getActionName = ActionNameFactory('COF_HUB_PRODUCTS', 'HUB_PRODUCTS');

// ------------------------------------
// Action Types
// ------------------------------------
export const HUB_PRODUCTS_LOAD = getActionName.async('HUB_PRODUCTS_LOAD');
export const TOGGLE_HUB_PRODUCT_SELECTED = getActionName('TOGGLE_HUB_PRODUCT_SELECTED');
export const DESELECT_ALL = getActionName('DESELECT_ALL');
export const SET_CURRENT_PAGE = getActionName('SET_CURRENT_PAGE');
export const SET_SORT_VALUE = getActionName('SET_SORT_VALUE');

// ------------------------------------
// Actions
// ------------------------------------
export function hubProductsLoad(query = new PagedQuery()) {
  return fetchAsync(HUB_PRODUCTS_LOAD, { query });
}

export function toggleHubProductSelected(hubProduct, isSelected) {
  return {
    type: TOGGLE_HUB_PRODUCT_SELECTED,
    payload: { hubProduct, isSelected }
  };
}

export function deselectAll() {
  return {
    type: DESELECT_ALL
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: { page }
  };
}

export function setSortValue(sortValue) {
  return {
    type: SET_SORT_VALUE,
    payload: { sortValue }
  };
}
