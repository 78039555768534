import {
  fetch,
  postJson,
  fileUpload,
  tryPrefillWithQueryParams
} from 'gooten-components/src/utils/http';
import _fetch from 'isomorphic-fetch';
import Config from 'gooten-components/src/config';

// configuration for image uploader
export const imageEvidenceOptions = callback => {
  return {
    wrapperDisplay: 'block',
    baseUrl: '',
    multiple: true,
    numberLimit: 5,
    accept: 'image/jpeg,image/png',
    chooseFile: files => {
      callback(files);
    }
  };
};

const getAuthQueryParams = () => ({
  Authorization: 'Bearer ' + Config.get('adminApiToken')
});

const getQueryParams = () => ({
  queryParams: {
    recipeId: Config.get('recipeId')
  }
});

let cache = {};

class ReprintSelfServiceService {
  // 'files' can be FilesList or string presenting image url. If it's a FilesList,
  // it will contain 'length' ...
  uploadImageEvidence(files) {
    const headers = { ...getAuthQueryParams() };

    if (typeof files !== 'string') {
      const uploadUrl = `${window.location.origin}/admin/api/TempImagesApi/Upload`;

      const fileUploads = Array.from(files).map(f => {
        let formData = new FormData();
        formData.append('file', f, f.name);
        return fileUpload(uploadUrl, formData, { headers }).then(res => res.url);
      });

      return Promise.allSettled(fileUploads);
    }

    const uploadUrl = `${window.location.origin}/admin/api/TempImagesApi/UploadFromUrl?url=${files}`;
    const config = { method: 'GET', headers };
    return _fetch(tryPrefillWithQueryParams(uploadUrl, config), {
      headers: {
        Accept: 'application/json'
      },
      ...config
    }).then(res => {
      if (res.ok) {
        return res.json().then(parsed => parsed.url);
      } else {
        return new Promise((resolve, reject) => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Error while uploading file');
        });
      }
    });
  }

  // gets reprint data from local storage, passed in from simple order form...
  // pass data through 'localStorage' is used only while SOF exist (Angular)
  // this should be changed when SOF is completely removed from Admin...
  getReprintData() {
    const reprintData =
      localStorage.getItem('pio.reprintData') &&
      JSON.parse(localStorage.getItem('pio.reprintData'));
    return reprintData ? { orderId: reprintData.orderId, items: reprintData.items } : null;
  }

  getOrderSummary(orderId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/OrderDetailsApi/GetSummary?orderId=${orderId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }

  getPartnerDetails(partnerId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/PartnersApi/GetDetails?partnerId=${partnerId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }

  getPartnerPreferences(partnerId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/PartnersApi/GetPartnerPreferences?partnerId=${partnerId}`;
    return fetch(url, { method: 'GET', headers }).then(res => res);
  }

  submitReprint(request) {
    const headers = { ...getQueryParams() };
    const url = `${Config.get('api')}orders`;

    return new Promise((resolve, reject) => {
      postJson(url, request, headers)
        .then(res => {
          if (!res.HadError) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getAllReasons() {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/RefundRules/GetAllReasons`;
    return cache.reasons
      ? new Promise((resolve, reject) => {
          resolve(cache.reasons);
        })
      : fetch(url, { method: 'GET', headers }).then(res => {
          cache.reasons = res;
          return res;
        });
  }

  getRules(reasonId) {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/RefundRules/GetRules?reasonId=${reasonId}`;
    const key = `rules${reasonId}`;
    return cache[key]
      ? new Promise((resolve, reject) => {
          resolve(cache[key]);
        })
      : fetch(url, { method: 'GET', headers }).then(res => {
          cache[key] = res;
          return res;
        });
  }

  getSupportedVendorIds() {
    const headers = { ...getAuthQueryParams() };
    const url = `${window.location.origin}/admin/api/RefundRules/GetSelfServiceSupportedVendorIds`;
    return cache.supportedVendorIds
      ? new Promise((resolve, reject) => {
          resolve(cache.supportedVendorIds);
        })
      : fetch(url, { method: 'GET', headers }).then(res => {
          cache.supportedVendorIds = res;
          return res;
        });
  }
}

// singleton
export default new ReprintSelfServiceService();
