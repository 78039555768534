import { injectSaga } from '../store/sagas';
import pastOrdersSaga from './PastOrdersView/components/PastOrders/PastOrdersSaga';
import hubProductsSaga from './HubProductsView/components/HubProducts/HubProductsSaga';
import editDesignViewSaga from './EditDesignView/EditDesignViewSaga';
import cartViewSaga from './CartView/CartViewSaga';
import orderFromViewSaga from './OrderFromView/OrderFromViewSaga';

const registerCustomOrderFormSagas = () => {
  injectSaga(pastOrdersSaga);
  injectSaga(hubProductsSaga);
  injectSaga(editDesignViewSaga);
  injectSaga(cartViewSaga);
  injectSaga(orderFromViewSaga);
};

export default registerCustomOrderFormSagas;
