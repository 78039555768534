import { all, takeLatest, put, call } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import SummaryViewService from './SummaryViewService';
import {
  updateCountries,
  updateAddressTypes,
  updateProductionTimes,
  updateVariantsForSku,
  GET_COUNTRIES,
  GET_ADDRESS_TYPES,
  GET_PRODUCTION_TIMES,
  GET_VARIANTS_FOR_SKU
} from './SummaryViewActions';

export function* handleCountriesRequest(action) {
  let countries = yield call([SummaryViewService, SummaryViewService.fetchCountries]);

  // we need to support GB, because some vendors requests it, instead of UK...
  // CS agent will manually edit shipping address country if needed.
  countries.push({ Name: 'United Kingdom', Id: 500, CountryCode: 'GB', Status: true });

  yield put(updateCountries(fromJS(countries)));
}

export function* handleAddressTypesRequest(action) {
  let addressTypes = yield call([SummaryViewService, SummaryViewService.fetchAddressTypes]);
  yield put(updateAddressTypes(fromJS(addressTypes)));
}

export function* handleProductionTimesRequest(action) {
  let productionTimes = yield call(
    [SummaryViewService, SummaryViewService.fetchProductionTimes],
    action.payload
  );
  yield put(updateProductionTimes(fromJS(productionTimes)));
}

export function* handleGetVariantsForSkuRequest(action) {
  let partnerInfo = yield call(
    [SummaryViewService, SummaryViewService.fetchPartnerInfo],
    action.payload
  );
  if (partnerInfo.PartnerBillingKey) {
    let variants = yield call(
      [SummaryViewService, SummaryViewService.fetchVariantsForSku],
      partnerInfo,
      action.payload
    );
    if (variants.HadError) {
      yield put(push('Failed to load sku variants'));
    } else {
      yield put(updateVariantsForSku(partnerInfo, fromJS(variants)));
    }
  } else {
    yield put(push('Failed to get partner info'));
  }
}

export function* watchSearch() {
  yield takeLatest(GET_COUNTRIES, handleCountriesRequest);
  yield takeLatest(GET_ADDRESS_TYPES, handleAddressTypesRequest);
  yield takeLatest(GET_PRODUCTION_TIMES, handleProductionTimesRequest);
  yield takeLatest(GET_VARIANTS_FOR_SKU, handleGetVariantsForSkuRequest);
}

export default function* rootSaga() {
  yield all([watchSearch()]);
}
