export const flatten = ary => ary.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export const sortBy = field => (a, b) => {
  const fieldA = a[field].toUpperCase();
  const fieldB = b[field].toUpperCase();
  if (fieldA < fieldB) {
    return -1;
  }
  if (fieldA > fieldB) {
    return 1;
  }
  return 0;
};

export const sortByName = sortBy('name');

export const removeDuplicatesBy = (keyFn, array) => {
  const mySet = new Set();
  return array.filter(function (x) {
    const key = keyFn(x);
    const isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
};

// Get all possible combinations of array
export const cartesian = source => {
  let result = [];
  let max = source.length - 1;

  let helper = function (arr, i) {
    for (var j = 0, l = source[i].length; j < l; j++) {
      let a = arr.slice(0); // clone arr
      a.push(source[i][j]);
      if (i === max) {
        result.push(a);
      } else {
        helper(a, i + 1);
      }
    }
  };
  helper([], 0);
  return result;
};

export const getDuplicates = array =>
  array.reduce((a, c, i) => {
    if (array.indexOf(c) !== i && a.indexOf(c) < 0) {
      a.push(c);
    }
    return a;
  }, []);
