import { delay } from 'redux-saga';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import pastOrdersService from './PastOrdersService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { ORDER_ITEMS_LOAD } from './PastOrdersActions';

export function* orderItemsLoadHandler(action) {
  // NOTE: can occure too offten
  // wait 500ms so this can be canceled while waiting
  yield call(delay, 500);
  // Call PastOrdersService to load order items
  try {
    // debugger
    let orderItems = yield call(
      [pastOrdersService, pastOrdersService.loadPastOrderItems],
      action.payload.query
    );
    // debugger
    // dispatch async success action with type ORDER_ITEMS_LOAD and pass order items data
    yield put(fetchAsyncSuccess(ORDER_ITEMS_LOAD, { orderItems }));
  } catch (err) {
    // dispatch async error action with type ORDER_ITEMS_LOAD and pass error data
    yield put(fetchAsyncFail(ORDER_ITEMS_LOAD, err));
    throw err;
  }
}

export function* watchOrderItemsLoad() {
  // Watch when ORDER_ITEMS_LOAD.ASYNC got dispatched
  yield takeLatest(ORDER_ITEMS_LOAD.ASYNC, orderItemsLoadHandler);
}

export default function* rootSaga() {
  yield all([watchOrderItemsLoad()]);
}
