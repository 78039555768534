import { createSelector } from 'reselect';
import { List } from 'immutable';
import { compareProductNames } from '../../../../utils/search';
import { countryFilteredProductsSelector } from '../../ProductSelectionSelectors';
import { searchSelector } from '../SearchBar/SearchBarSelectors';
import {
  selectedCategorySelector,
  selectedSubCategorySelector
} from '../CategoriesNav/CategoriesNavSelectors';

export const filteredProductsSelector = createSelector(
  [
    countryFilteredProductsSelector,
    searchSelector,
    selectedCategorySelector,
    selectedSubCategorySelector
  ],
  (allProducts, search, selectedCategory, selectedSubCategory) => {
    let filteredProducts = allProducts;
    // filter products by search string
    if (search.length) {
      filteredProducts = filteredProducts.filter(p =>
        compareProductNames(p.get('name'), search.trim())
      );
    } else if (selectedCategory) {
      // filter products by category
      filteredProducts = filteredProducts.filter(p =>
        p.get('categories').includes(selectedCategory.get('name'))
      );

      switch (selectedCategory.get('name').toLowerCase()) {
        case 'all': // sort abc on All tab only
          filteredProducts = filteredProducts.sortBy(x => x.get('name'));
          break;

        case 'trending': // if it's Trending tab, sort by newest product
          filteredProducts = filteredProducts.sortBy(x => x.get('id')).reverse();
          break;
      }
    }

    // create product lists
    let productLists;
    if (selectedCategory.get('subcategories').size === 0 || search.length) {
      productLists = new List().push({
        name: selectedSubCategory && search.length === 0 ? selectedSubCategory.get('name') : null,
        products: filteredProducts
          .groupBy(x => x.get('name'))
          .map(x => x.first())
          .toList()
      });
    } else {
      productLists = selectedCategory.get('subcategories').map(subCat => ({
        name: subCat.get('name'),
        products: filteredProducts.filter(product =>
          product.get('subcategories').includes(subCat.get('name'))
        )
      }));
    }

    return productLists;
  }
);
