import { createSelector } from 'reselect';
import SecurityService from '../../Settings/SecurityService';

const orderDetailsSelector = state => state.getIn(['orders', 'orderDetails']);

export const summaryData = createSelector([orderDetailsSelector], orders =>
  orders.get('summaryData') ? orders.get('summaryData').toJS() : {}
);

export const billingData = createSelector([orderDetailsSelector], orders =>
  orders.get('billingData') ? orders.get('billingData').toJS() : {}
);

export const itemsDataOrig = createSelector([orderDetailsSelector], orders =>
  orders.get('itemsDataOrig') ? orders.get('itemsDataOrig').toJS() : []
);

export const itemsData = createSelector([orderDetailsSelector], orders =>
  orders.get('itemsData') ? orders.get('itemsData').toJS() : []
);

export const loadingStatus = createSelector([orderDetailsSelector], orders =>
  orders.get('loading')
);

export const errorMessage = createSelector([orderDetailsSelector], orders =>
  orders.get('errorMessage')
);

export const failedOrderInfo = createSelector([orderDetailsSelector], orders =>
  orders.get('failedOrderInfo') ? orders.get('failedOrderInfo') : null
);

export const orderLogs = createSelector([orderDetailsSelector], orders =>
  orders.get('orderLogs') ? orders.get('orderLogs') : null
);

export const showCustomerCosts = createSelector([orderDetailsSelector], orders =>
  orders.getIn(['stateData', 'Settings', 'OrderSources', 'ShowCustomerCosts'])
    ? orders.getIn(['stateData', 'Settings', 'OrderSources', 'ShowCustomerCosts'])
    : null
);

export const activeImage = createSelector(
  [orderDetailsSelector],
  orders => orders.get('activeImage') || null
);

export const ableToEditShippingStatuses = () => {
  // Rule for SuperAdmin. Able to edit address and method when order is in statuses below:
  //
  // AddressIssue 27
  // Cancelled 34
  // CancelledRefunded 33
  // Digitizing 36
  // DiscontinuedItem 39
  // Hold 31
  // HoldByCIImageIssue 13
  // ImageIssue 12
  // NeedsManualApproval 32
  // NeedsPersonalization 35
  // New 14
  // OrderDataIssue 38
  // OutOfStockItem 40
  // PaymentIssue 29
  // PaymentProcessing 28
  // Pending 7
  // PrePayment 30
  // ReadyForAccounting 18
  // ReadyForImageDl 26
  // ReadyForPrint 8
  // Test 15
  // VendorAPIIssue 20
  if (SecurityService.isSuperAdmin()) {
    return [27, 34, 33, 36, 39, 31, 13, 12, 32, 35, 14, 38, 40, 29, 28, 7, 30, 18, 26, 8, 15, 20];
  }

  // Rule for Partner, PartnerAdmin and Admin. Able to edit address and method
  // when order is in statuses below:
  //
  // AddressIssue 27
  // Digitizing 36
  // DiscontinuedItem 39
  // Hold 31
  // HoldByCIImageIssue 13
  // ImageIssue 12
  // NeedsManualApproval 32
  // NeedsPersonalization 35
  // New 14
  // OrderDataIssue 38
  // OutOfStockItem 40
  // PaymentIssue 29
  // PaymentProcessing 28
  // Pending 7
  // PrePayment 30
  // ReadyForAccounting	18
  // ReadyForImageDl 26
  // VendorAPIIssue 20
  if (
    SecurityService.isPartner() ||
    SecurityService.isPartnerAdmin() ||
    SecurityService.isAdmin()
  ) {
    return [27, 36, 39, 31, 13, 12, 32, 35, 14, 38, 40, 29, 28, 7, 30, 18, 26, 20];
  }

  // for everything else return nothing
  return [];
};

export const ableToEditShipping = orderItemsStatuses => {
  const supportedStatuses = ableToEditShippingStatuses();
  return supportedStatuses.length && orderItemsStatuses?.every(s => supportedStatuses.includes(s));
};
