import { takeLatest, put, call, all } from 'redux-saga/effects';
import { UPDATE_ACCCESS, REMOVE_MEMBER } from './YourTeamActions';
import settingsApiService from '../../SettingsApiService';
import { fetchAsyncSuccess, fetchAsyncFail } from 'gooten-components/src/store/actions/dataActions';
import { watchInitAsync } from '../BaseCard/BaseCardSaga';
import YourTeam from './YourTeam';
import { push } from 'gooten-components/src/components/shared/Notifications/NotificationsActions';
import analyticsService from 'gooten-components/src/services/analyticsService';

export function* updateAccessAsyncHandler(action) {
  try {
    yield call(settingsApiService.updateAccess, action.payload);
    yield put(fetchAsyncSuccess(UPDATE_ACCCESS, action.payload));
    yield put(push('Access updated.', 'success'));
    analyticsService.track('Settings - Team', 'Team Member Permissions Updated', 'Settings');
  } catch (err) {
    yield put(fetchAsyncFail(UPDATE_ACCCESS, err));
    yield put(push('Failed to update access.', 'failure'));
  }
}

export function* watchUpdateAccess() {
  yield takeLatest(UPDATE_ACCCESS.ASYNC, updateAccessAsyncHandler);
}

export function* removeMemberAsyncHandler(action) {
  try {
    yield call(settingsApiService.removeMember, action.payload);
    yield put(fetchAsyncSuccess(REMOVE_MEMBER, action.payload));
    yield put(push('Member removed.', 'success'));
  } catch (err) {
    yield put(fetchAsyncFail(REMOVE_MEMBER, err));
    yield put(push('Failed to remove member.', 'failure'));
  }
}

export function* watchRemoveMember() {
  yield takeLatest(REMOVE_MEMBER.ASYNC, removeMemberAsyncHandler);
}

export default function* rootSaga() {
  yield all([watchInitAsync(YourTeam.name), watchUpdateAccess(), watchRemoveMember()]);
}
