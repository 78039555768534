import { fetch, post } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class ReturnAddressService {
  fetchCountries() {
    const url = `${Config.get('adminApi')}countries`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  fetchAddressTypes() {
    const url = `${Config.get('adminApi')}AddressTypes`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  fetchPartnerDetails() {
    const url = `${Config.get('adminApi')}PartnersApi/GetDetails`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        partnerId: Config.get('partnerId')
      }
    });
  }

  setReturnAddressType(selection) {
    const url = `${Config.get('adminApi')}PartnersApi/setReturnAddressType`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        partnerId: Config.get('partnerId')
      },
      body: JSON.stringify({
        type: selection
      })
    });
  }

  saveReturnAddress(payload) {
    const url = `${Config.get('adminApi')}PartnersApi/saveReturnAddress`;

    return fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        partnerId: Config.get('partnerId')
      },
      body: JSON.stringify(payload.data)
    });
  }
}

export default new ReturnAddressService();
