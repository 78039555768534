import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Category, SubCategory } from '../../../../models/Category';
import ListItem from '../../../shared/ListItem';
import DropDown from '../../../shared/DropDown';
import { COLORS } from '../../../../constants';

class CategoryMobileList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.createItems(),
      expand: false
    };

    this.clickOnTitle = this.clickOnTitle.bind(this);
  }

  createItems(expandCategory) {
    this.expandedCategory = null;

    return this.props.categories.map(category => {
      const subcategories = category.subcategories.map(subcategory => {
        const handleClickOnSubcategory = this.clickOnSubCategory.bind(this, subcategory);

        return (
          <ListItem
            // eslint-disable-next-line
            onSelect={handleClickOnSubcategory}
            key={subcategory.get('name')}
            subItem
            text={subcategory.get('name')}
          />
        );
      });
      let subContent = expandCategory === category ? subcategories : null;
      if (subContent !== null) {
        this.expandedCategory = category;
      }

      /*eslint-disable */
      const handleClickOnCategory = this.clickOnCategory.bind(this, category);

      return (
        <div className="catalogue-list-menu" key={category.get('name')}>
          <ListItem
            text={category.get('name')}
            onSelect={handleClickOnCategory}
            active={
              expandCategory
                ? expandCategory === category
                : this.props.selectedCategory === category
            }
            rightIcon={category.subcategories.size ? 'chevronDown' : null}
          />
          {subContent}
          <style jsx>
            {`
              .catalogue-list-menu :global(.right-icon) {
                transform: ${expandCategory === category ? 'rotate(180deg)' : 'rotate(0)'};
                transition-duration: 0.3s;
              }
            `}
          </style>
        </div>
      );
      /* eslint-enable */
    });
  }

  clickOnTitle() {
    this.setState({ expand: !this.state.expand });
  }

  clickOnCategory(category) {
    this.props.onSelectCategory(category);
    if (!category.subcategories.size) {
      this.setState({ expand: !this.state.expand });
      this.setState({
        items: this.createItems(this.expandedCategory === category ? null : category)
      });
    } else {
      this.setState({
        items: this.createItems(this.expandedCategory === category ? null : category)
      });
    }
  }

  clickOnSubCategory(subcategory) {
    this.props.onSelectSubCategory(subcategory);
    this.setState({ expand: false });
  }

  render() {
    return (
      <>
        <div className="categories-mobile-list visible-xs visible-sm p-4">
          <DropDown
            isOpen={this.state.expand}
            toggle={this.clickOnTitle}
            title={this.props.selectedCategory.get('name')}
          >
            {this.state.items}
          </DropDown>
        </div>
        <div className="hidden-sm hidden-xs categories-desktop-container">
          <div className="categories-desktop p-2 pr-3 mt-2 ml-2">{this.state.items}</div>
        </div>
        <style jsx>
          {`
            .categories-mobile-list :global(.dropdown-container),
            .categories-mobile-list :global(.dropdown-list) {
              max-width: 100%;
              min-width: 18rem;
            }
            .categories-mobile-list :global(.dropdown-list) {
              overflow-y: scroll;
              max-height: calc(100vh - 10rem);
            }
            .categories-mobile-list {
              border-bottom: 1px solid ${COLORS.neutralLight300};
              position: absolute;
              z-index: 2;
              background-color: ${COLORS.neutralLight50};
              width: 100%;
              top: 0;
              left: 0;
              right: 0;
            }
            .categories-desktop-container {
              width: 17rem;
            }
            .categories-desktop {
              position: absolute;
              top: 4.75rem;
              width: 15.5rem;
              max-height: calc(100vh - 11rem);
              overflow-y: auto;
              overflow-x: hidden;
            }
          `}
        </style>
      </>
    );
  }
}

CategoryMobileList.propTypes = {
  categories: PropTypes.instanceOf(List).isRequired,
  selectedCategory: PropTypes.oneOfType([
    PropTypes.instanceOf(Category),
    PropTypes.instanceOf(SubCategory)
  ]),
  onSelectCategory: PropTypes.func.isRequired,
  onSelectSubCategory: PropTypes.func.isRequired
};

export default CategoryMobileList;
