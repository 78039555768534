import React from 'react';
import PropTypes from 'prop-types';
import './ImageEditor.scss';
import imageEditorService from '../../services/imageEditorService';

class ImageEditor extends React.Component {
  static propTypes = {
    key: PropTypes.string,
    config: PropTypes.object,
    space: PropTypes.object,
    ilHistory: PropTypes.object,
    bulkEdit: PropTypes.bool,
    mode: PropTypes.string,
    maximized: PropTypes.bool,
    saveState: PropTypes.func.isRequired,
    saveDx: PropTypes.func.isRequired,
    undo: PropTypes.func.isRequired,
    redo: PropTypes.func.isRequired,
    toggleBulkMode: PropTypes.func.isRequired,
    bulkEditingAvailability: PropTypes.bool,
    changeOrientation: PropTypes.bool.isRequired,
    disableImageEditing: PropTypes.bool.isRequired
  };

  editorInstance = null;
  images = [];
  key = null;
  mode = null;

  editorChanged(oldProps, newProps) {
    return (
      !imageEditorService.editorInstanceExists() ||
      oldProps.key !== newProps.key ||
      oldProps.mode !== newProps.mode ||
      oldProps.maximized !== newProps.maximized ||
      oldProps.changeOrientation !== newProps.changeOrientation
    );
  }

  imagesChanged(oldProps, newProps) {
    return (
      !oldProps.space ||
      !newProps.space.il ||
      oldProps.space.images.length !== newProps.space.images.length ||
      !oldProps.space.images.every((v, i) => v.imageUrl === newProps.space.images[i].imageUrl)
    );
  }

  initEditor(force, unMaximized) {
    // template already rendered
    if (!this.editorChanged(this, this.props) && !force) {
      return;
    }

    this.key = this.props.key;
    this.mode = this.props.mode;

    imageEditorService.createEditor(
      this.props.config,
      this.props.space,
      this.props.ilHistory,
      this.props.disableImageEditing
    );
    imageEditorService.setBulkEditingAvailability(this.props.bulkEditingAvailability);
    if (this.props.bulkEditingAvailability) {
      imageEditorService.changeBulkEditMode(this.props.bulkEdit);
    }
    imageEditorService.onChange(state => this.props.saveState(state));
    imageEditorService.onChangeDx(dx => this.props.saveDx(dx));
    imageEditorService.onUndo(() => this.props.undo());
    imageEditorService.onRedo(() => this.props.redo());
    imageEditorService.onBulkModeToggle(bulk => this.props.toggleBulkMode(bulk));

    // NOTE: Usual space has either dumpedState or il
    // and images already there
    // but in case if we added new image dumpedState and il are removed
    if (!this.props.space.dumpedState && !this.props.space.il) {
      this.props.space.images.forEach(image => {
        imageEditorService.addImage(image.imageUrl, image.layerId, image.width, image.height);
      });
    }

    imageEditorService.changeEditorMode(this.props.mode);

    if (this.mode === 'product') {
      imageEditorService.setContainerColor('white');
    }

    const mobileMargins = 30;
    if (screen.width < this.props.config.width + mobileMargins) {
      // handle mobile ui
      imageEditorService.changeEditorSize({
        width: this.props.config.mobileWidth,
        height: this.props.config.mobileHeight
      });
    } else if (screen.width > this.props.config.maximizedWidth && this.props.maximized) {
      // handle maximized state
      imageEditorService.changeEditorSize({
        width: this.props.config.maximizedWidth,
        height: this.props.config.maximizedHeight
      });
    } else if (unMaximized) {
      // NOTE: Resize to default size in case if it was unmaximized - right panel toggle
      imageEditorService.changeEditorSize({
        width: this.props.config.width,
        height: this.props.config.height
      });
    }

    // set Orientation
    imageEditorService.changeOrientation(this.props.changeOrientation);
  }

  refreshData(prevProps) {
    // data not ready yet
    if (!this.props.space.template) {
      return;
    }

    if (
      prevProps &&
      !this.imagesChanged(prevProps, this.props) &&
      !this.editorChanged(prevProps, this.props)
    ) {
      return;
    }

    const unMaximized = prevProps && prevProps.maximized && !this.props.maximized;
    const forceUpdate = true;

    // init editor only if we can attach it to container
    if (document.querySelector(this.props.config.container)) {
      this.initEditor(forceUpdate, unMaximized);
    }
  }

  componentDidUpdate(prevProps) {
    this.refreshData(prevProps);
  }

  componentDidMount() {
    // disable bulk mode if it's not available
    if (!this.props.bulkEditingAvailability) {
      this.props.toggleBulkMode(false);
    }
    this.refreshData();
  }

  componentWillUnmount() {
    imageEditorService.destroyEditor();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.editorChanged(this.props, nextProps) || this.imagesChanged(this.props, nextProps);
  }

  render() {
    let mobile = screen.width < this.props.config.width;
    let maximized = screen.width > this.props.config.maximizedWidth && this.props.maximized;
    let editorClassName =
      'editor-placeholder gooten-image-editor' +
      (mobile ? ' mobile' : '') +
      (maximized ? ' maximized' : '');
    return (
      <div className="image-editor-container">
        <div className={editorClassName} />
      </div>
    );
  }
}

export default ImageEditor;
