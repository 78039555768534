import { createSelector } from 'reselect';
import { flatten } from '../../utils/array';

// Common product data selectors
export const dataSelector = state => state.get('data');
export const selectedSKUsSelector = createSelector([dataSelector], data =>
  data.get('selectedSkus')
);

export const selectedSKUsJSSelector = createSelector([selectedSKUsSelector], selectedSKUs => {
  return selectedSKUs ? selectedSKUs.toJS() : [];
});

export const disabledSKUsSelector = createSelector([dataSelector], data =>
  data.get('disabledSkus')
);
export const selectedProductIdSelector = createSelector([dataSelector], data =>
  data.get('selectedProductId')
);
export const selectedProductIdsSelector = createSelector([dataSelector], data =>
  data.get('selectedProductIds')
);
export const allProductsSelector = createSelector([dataSelector], data => data.get('products'));

export const selectedProductsSelector = createSelector(
  [selectedProductIdsSelector, allProductsSelector],
  (selectedProductIds, products) =>
    products.filter(product => selectedProductIds.contains(product.id))
);

export const getProductVariants = createSelector([selectedProductsSelector], selectedProducts =>
  selectedProducts.size ? flatten(selectedProducts.map(f => f.variants || [])) : null
);

export const bulkEditSelector = state => {
  return state.getIn(['imageUpload', 'bulkEdit']);
};
