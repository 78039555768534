import { takeLatest, put, select, all } from 'redux-saga/effects';
import { PREPARING, getReady } from './ProductDesignViewActions';
import { updatePublishData } from 'gooten-components/src/components/ProductPublish/ProductPublishActions';
import { selectProducts, skuSelect } from 'gooten-components/src/store/actions/dataActions';
import { rootSelector } from './ProductDesignViewSelectors';
import linkProductService from '../LinkProductService';

export function* prepareLinkDataHandler(action) {
  yield put(
    selectProducts([parseInt(action.payload.connectExistingData.get('product').get('id'))])
  );
  let linkState = yield select(rootSelector);
  if (linkState.get('preparing')) {
    const connectExistingData = linkState.get('connectExistingData').toJS();
    const stores = linkState.get('stores');
    const skus = connectExistingData.mappings.map(m => ({
      id: m.variant.id,
      sku: m.uniqueProduct.sku
    }));

    yield put(skuSelect(skus));
    const publishData = linkProductService.getPublishData(connectExistingData, stores);
    yield put(updatePublishData(publishData));
    yield put(getReady());
  }
}

export function* watchPrepareLinkData() {
  yield takeLatest(PREPARING, prepareLinkDataHandler);
}

export default function* rootSaga() {
  yield all([watchPrepareLinkData()]);
}
