import { all, takeEvery, put, call } from 'redux-saga/effects';
import AdminApiService from '../../../services/adminApiService';
import Config from '../../../config';

import { GET_ARCHIVED_STATUSES } from './OrderItemsSelectionActions';

export function* handleArchivedStatusesRequest({ payload: { vendorId, skus } }) {
  try {
    const promises = [...new Set(skus)].reduce((acc, sku) => {
      const p = AdminApiService.get('VendorProductsApi/GetProductArchivedReason', {
        sku,
        vendorId
      });

      return {
        ...acc,
        [sku]: p
      };
    }, {});

    const archivedReasons = yield all(promises);

    yield put({
      type: GET_ARCHIVED_STATUSES.SUCCESS,
      payload: {
        vendorId,
        data: archivedReasons
      }
    });
  } catch (err) {
    yield put({
      type: GET_ARCHIVED_STATUSES.FAIL,
      payload: {
        vendorId
      }
    });
  }
}

export function* watchGetArchivedStatuses() {
  yield takeEvery(GET_ARCHIVED_STATUSES.ASYNC, handleArchivedStatusesRequest);
}

export default function* rootSaga() {
  yield all([watchGetArchivedStatuses()]);
}
