const POSTERS = 'posters';

// used to customize order of some product options...
export const rearrangeOptions = payload => {
  const containsIgnoreCase = (str, keyword) => str.toLowerCase().includes(keyword);

  const { product } = payload;
  const productName = product.name.toLowerCase();

  switch (productName) {
    case POSTERS:
      {
        // rearrange product options by title, so that the first option is the paper finish
        const paperOption = product.options.find(option =>
          containsIgnoreCase(option.title, 'paper')
        );

        // second options is the size
        const sizeOption = product.options.find(option => containsIgnoreCase(option.title, 'size'));

        const otherOptions = product.options.filter(
          option =>
            !containsIgnoreCase(option.title, 'paper') && !containsIgnoreCase(option.title, 'size')
        );
        product.options = [paperOption, sizeOption, ...otherOptions];
      }
      break;
  }

  // place Orientation option before Size(s) option, if product supports Orientation,
  const orientationOption = product.options.find(o => o.id.toLowerCase() === 'orientation');
  const sizeOption = product.options.find(o => containsIgnoreCase(o.title, 'size'));
  if (orientationOption && sizeOption) {
    const sizeIndex = product.options.indexOf(sizeOption);
    const orientationIndex = product.options.indexOf(orientationOption);
    if (sizeIndex < orientationIndex) {
      product.options = product.options.filter(o => o.id.toLowerCase() !== 'orientation');
      orientationOption['group-id'] = Math.min(
        orientationOption['group-id'],
        sizeOption['group-id']
      );
      product.options.splice(sizeIndex, 0, orientationOption);
    }
  }

  return payload;
};
