import React from 'react';
import PropTypes from 'prop-types';
import CancelConfirmationModal from '../ConfirmationModal/CancelConfirmationModal';
import Button from '../Button';

class CancelButton extends React.Component {
  static propTypes = {
    children: PropTypes.string,
    cancel: PropTypes.func,
    ignoreConfirmation: PropTypes.bool,
    className: PropTypes.string
  };

  clickHandler = () => {
    return this.props.ignoreConfirmation ? this.props.cancel() : this.modal.openModal();
  };

  cancel = () => {
    this.props.cancel();
  };

  render() {
    return (
      <div className="cancel-button">
        <Button
          onClick={() => this.clickHandler()}
          className={`button-default large full-width ${this.props.className || ''}`}
        >
          {this.props.children || 'Cancel'}
        </Button>
        <CancelConfirmationModal
          ref={instance => {
            this.modal = instance;
          }}
          confirm={this.cancel}
        />
        <style jsx>
          {`
            .cancel-button {
              display: inline-block;
            }
          `}
        </style>
      </div>
    );
  }
}

CancelButton.displayName = 'CancelButton';

export default CancelButton;
