import { all, call, put } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import Config from '../../../config';
import { watchInitAsync, watchUpdateAsync } from '../BaseCard/BaseCardSaga';
import { initFor, countriesLoaded } from '../BaseCard/BaseCardActions';
import CreditCard from '../CreditCard/CreditCard';
import BillingAddress from './BillingAddress';
import analyticsService from 'gooten-components/src/services/analyticsService';

function* onSuccess(state, payload) {
  analyticsService.track('Settings - Billing Address', 'Billing address added', 'Settings');
  yield put(initFor(CreditCard.name)());
}

export function* preInit() {
  const countriesGetter = Config.get('countriesGetter');
  const result = yield call(countriesGetter, Config.get('recipeId'));
  const list = fromJS(result.Countries)
    .groupBy(x => x.get('Code'))
    .map(p => ({
      key: p.getIn([0, 'Code']),
      label: p.getIn([0, 'Name'])
    }))
    .toList()
    .toJS();
  yield put(countriesLoaded(list));
}

export default function* rootSaga() {
  yield all([
    watchInitAsync(BillingAddress.name, BillingAddress.messages, false, preInit),
    watchUpdateAsync(BillingAddress.name, BillingAddress.messages, false, onSuccess)
  ]);
}
