import { fetch, post } from 'gooten-components/src/utils/http';
import Config from '../../../config';

class SummaryViewService {
  fetchCountries() {
    const url = `${Config.get('adminApi')}countries`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  fetchAddressTypes() {
    const url = `${Config.get('adminApi')}AddressTypes`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      }
    });
  }

  fetchProductionTimes(payload) {
    return post(
      `${Config.get('api')}shippingprices/?recipeid=${payload.recipeId}`,
      JSON.stringify(payload.data),
      {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      }
    );
  }

  fetchPartnerInfo(payload) {
    const url = `${Config.get('adminApi')}shippingCalculatorApi/getPartnerInfo`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        partnerId: payload.partnerId
      }
    });
  }

  fetchVariantsForSku(partnerInfo, payload) {
    const url = `${Config.get('api')}v/1/source/api/PRPAnalogs/`;

    return fetch(url, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${Config.get('adminApiToken')}`,
        'content-type': `application/json`
      },
      queryParams: {
        recipeid: partnerInfo.PartnerSafeId,
        sku: payload.sku,
        countryCode: partnerInfo.PartnerCountry,
        vendorId: payload.vendorId,
        partnerBillingKey: partnerInfo.PartnerBillingKey
      }
    });
  }
}

export default new SummaryViewService();
