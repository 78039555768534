import { GET_SHIPMENT_DATA, UPDATE_SHIPMENT_DATA } from './ShipmentViewActions';
import initialState from '../../../store/initialState';

const defaultState = initialState.getIn(['orders', 'shipmentView']);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SHIPMENT_DATA:
      return state.set('loading', true);
    case UPDATE_SHIPMENT_DATA:
      return state.set('loading', false).set('shipmentData', action.payload.data);
    default:
      return state;
  }
};
