import { createSelector } from 'reselect';
import logService from '../../../../services/logService';
import { getProductImageResizerUrl } from 'gooten-js-utils/src/url';
import { removeDuplicatesBy } from '../../../../utils/array';
import { parseCurrency, formatPrice } from '../../../../utils/price';
import Config from '../../../../config';
import { Map, List } from 'immutable';
import {
  selectedProduct,
  getSelectedOptionsFromRawSkus
} from '../../../SKUSelection/SKUSelectionSelectors';

const getSidebar = state => state.get('productSelection').get('sidebar');

export const getSidebarIsOpen = state => getSidebar(state).isOpen;

export const getSelectedSKUOptions = state => state.getIn(['SKUSelection', 'selectedOptions']);

export const getSidebarProductInfo = state => getSidebar(state).productInfo;

export const getProductName = state => getSidebarProductInfo(state).name;

export const getProductCategory = state => {
  if (state.get('productSelection').get('selectedCategory')) {
    return state.get('productSelection').get('selectedCategory').get('name');
  } else {
    return state.get('data').get('categories').first().name;
  }
};

export const getProductId = state => getSidebarProductInfo(state)[Config.get('productIdPropName')];

export const getMinPrice = createSelector(getSidebarProductInfo, productInfo => {
  let minPrice = 0;
  if (productInfo['min_price']) {
    minPrice = parseCurrency(productInfo['min_price']);
  }

  return formatPrice(minPrice);
});

export const getMaxPrice = createSelector(getSidebarProductInfo, productInfo => {
  let maxPrice = 0;
  if (productInfo['max_price']) {
    maxPrice = parseCurrency(productInfo['max_price']);
  }

  return formatPrice(maxPrice);
});

export const getCheapestShipping = createSelector(getSidebarProductInfo, productInfo => {
  var minShipping;
  if (productInfo.regions) {
    productInfo.regions.forEach(region => {
      const subCategories = region['sub-categories'];
      subCategories.forEach(subCategory => {
        subCategory.attributes.forEach(attribute => {
          if (attribute.name === 'Shipping') {
            const newMinShipping = attribute['min-price'];
            if (!minShipping || minShipping > newMinShipping) {
              minShipping = newMinShipping;
            }
          }
        });
      });
    });
  }
  return minShipping;
});

export const getProductImages = createSelector(getSidebarProductInfo, productInfo => {
  const images = [];
  if (productInfo.regions) {
    productInfo.regions.forEach(region => {
      const subCategories = region['sub-categories'];
      subCategories.forEach(subCategory => {
        subCategory.images.forEach(image => {
          images.push(image);
        });
      });
    });
  }

  return removeDuplicatesBy(x => x.url, images).map(x => ({
    ...x,
    url: getProductImageResizerUrl(x.url)
  }));
});

export const getProductOptions = state =>
  createSelector([selectedProduct], selectProduct =>
    selectProduct
      ? createSelector(
          [getSidebarProductInfo, getSelectedSKUOptions, getSelectedOptionsFromRawSkus],
          getProoductOptions$
        )(state)
      : createSelector([getSidebarProductInfo, getSelectedSKUOptions], getProoductOptions$)(state)
  )(state);

const getProoductOptions$ = (productInfo, selectedSkuOptions, preSelectedOptions = []) => {
  let productOptions = [];
  let selectedSkuOptionId;
  const preSelectedSkuOptions = Map(preSelectedOptions.map(o => [o.optionId, List(o.values)]));

  if (productInfo.regions) {
    productInfo.regions.forEach(region => {
      const subCategories = region['sub-categories'];

      subCategories.forEach(subCategory => {
        subCategory.attributes.forEach(attribute => {
          if (
            attribute.name !== 'Prices' &&
            attribute.name !== 'Shipping' &&
            attribute.name !== 'Templates'
          ) {
            let index = -1;
            for (let i = 0; i < productOptions.length; i++) {
              const option = productOptions[i];
              if (option.name === attribute.name) {
                if (selectedSkuOptions && [...selectedSkuOptions.keys()].includes(option.id)) {
                  selectedSkuOptionId = selectedSkuOptions.get(option.id).first();
                  if (
                    selectedSkuOptionId &&
                    attribute.values &&
                    attribute.name === 'Model' &&
                    attribute.values.some(att => att.id === selectedSkuOptionId)
                  ) {
                    productOptions = subCategory.attributes;
                  }
                } else if (
                  preSelectedSkuOptions &&
                  [...preSelectedSkuOptions.keys()].includes(option.id)
                ) {
                  selectedSkuOptionId = preSelectedSkuOptions.get(option.id).first();
                  if (
                    selectedSkuOptionId &&
                    attribute.values &&
                    attribute.name === 'Model' &&
                    attribute.values.some(att => att.id === selectedSkuOptionId)
                  ) {
                    productOptions = subCategory.attributes;
                  }
                }
                index = i;
                break;
              }
            }
            if (index === -1 && !selectedSkuOptionId) {
              productOptions.push(attribute);
            } else if (!selectedSkuOptionId) {
              const option = { ...productOptions[index] };
              switch (option['attr-type']) {
                case 'color-list':
                  option.values = removeDuplicatesBy(
                    x => x.name,
                    option.values.concat(attribute.values)
                  );
                  break;
                case 'two-color-list':
                  option.values = removeDuplicatesBy(
                    x => x.name,
                    option.values.concat(attribute.values)
                  );
                  break;
                case 'list':
                  if (!option.values) {
                    break;
                  }
                  option.values = removeDuplicatesBy(
                    x => x.name,
                    option.values.concat(attribute.values)
                  );
                  break;
                default:
                  logService.report(
                    `Product Data Error: Unknown attr-type: ${option['attr-type']}`,
                    { attribute }
                  );
                  break;
              }
            }
          }
        });
      });
    });
  }
  return productOptions;
};
