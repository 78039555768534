import { injectReducer } from '../../store/reducers';
import { injectSaga } from '../../store/sagas';
import ProductSelectionReducer from './ProductSelectionReducer';
import ProductSelectionSaga from './ProductSelectionSaga';
import ProductSelectionComponent from './ProductSelectionContainer';

const componentName = 'productSelection';

/* Sync Inject reducers and return Component */

export default store => {
  injectReducer(store, { key: componentName, reducer: ProductSelectionReducer });
  injectSaga(store, { key: componentName, saga: ProductSelectionSaga });

  return ProductSelectionComponent;
};

/*
  Async Inject reducers and return Component
  Assets for product selection will be loaded only when component activated
*/

// export default (store) => ({
//   path : 'productSelection',
//   /*  Async getComponent is only invoked when route matches   */
//   getComponent (nextState, cb) {
//     /*  Webpack - use 'require.ensure' to create a split point
//         and embed an async module loader (jsonp) when bundling   */
//
//     require.ensure([], (require) => {
//       /*  Webpack - use require callback to define
//           dependencies for bundling   */
//       const ProductSelectionComponent = require('./ProductSelectionContainer').default
//       const ProductSelectionReducer = require('./ProductSelectionReducer').default
//       const ProductSelectionSaga = require('./ProductSelectionSaga').default
//
//       /*  Add the reducer to the store on key 'counter'  */
//       injectReducer(store, { key: componentName, reducer: ProductSelectionReducer })
//       injectSaga(ProductSelectionSaga)
//
//       /*  Return getComponent   */
//       cb(null, ProductSelectionComponent)
//
//     /* Webpack named bundle   */
//     }, 'productSelection')
//   }
// })
