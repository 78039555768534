const propsIgnoreList = ['sku', 'minCost', 'maxCost', 'vendors'];

class DataMappingService {
  getOptionsFromVariants(variants, product, variantsWithOptions) {
    let skus = variants.map(x => x.sku);
    const supportedOptions = Array.from(
      new Set(product.details.product.options.map(opt => opt.id))
    );

    // filter by selected skus
    let filteredVariantsWithOptions = variantsWithOptions.filter(x => skus.includes(x.sku));

    // get optionIds from variants with options
    let optionsIdsRaw = filteredVariantsWithOptions
      .map(x =>
        Object.keys(x).filter(s => !propsIgnoreList.includes(s) && supportedOptions.includes(s))
      )
      .reduce((a, b) => a.concat(b), []); // get only opts and vals

    // get uniq option Ids
    let options = new Set();
    optionsIdsRaw.forEach(x => options.add(x));

    return Array.from(options).map(option => {
      return {
        optionId: option,
        values: this._getValuesForOptions(option, filteredVariantsWithOptions)
      };
    });
  }

  _getValuesForOptions(optionId, filteredVariantsWithOptions) {
    let result = new Set();
    filteredVariantsWithOptions.map(x => x[optionId]).forEach(x => result.add(x));

    return Array.from(result);
  }
}

export default new DataMappingService();
