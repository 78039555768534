import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants.js';
import Icon from './Icon.js';
import Button from './Button.js';

const Modal = ({
  title,
  maxWidth,
  children,
  className,
  cancelClick,
  cancelText,
  actionText,
  destructiveClick,
  primaryClick,
  primaryDisabled,
  isLoading,
  ctaClick,
  hideCloseButton,
  hideCancelButton,
  isOpen,
  disableOutsideClose,
  customButtons
}) => {
  return (
    <>
      <div
        className={`cerulean-modal dropshadow-3 ${className || ''} ${
          typeof title === 'string' ? title.replace(/\s+/g, '-').toLowerCase() : ''
        }`}
      >
        {title && (
          <div className="modal-head d-flex heavy ml-4 title-2">
            <div title={title} className="title-text">
              {title}
            </div>
          </div>
        )}
        {!hideCloseButton && (
          <Button onClick={cancelClick} iconOnly className="close-modal button-white medium">
            <Icon icon="x" />
          </Button>
        )}
        <div className="modal-bod"> {children}</div>
        {(cancelText || actionText || customButtons) && (
          <div className="modal-buttons pt-3 pb-3 pr-4 pl-4 d-flex">
            {cancelClick && !hideCancelButton && (
              <Button className="button-default large" onClick={cancelClick} disabled={isLoading}>
                {cancelText || 'Cancel'}
              </Button>
            )}
            {destructiveClick && (
              <Button
                className="button-destructive large ml-2"
                onClick={destructiveClick}
                disabled={isLoading}
              >
                {actionText || 'Cancel'}
              </Button>
            )}
            {primaryClick && (
              <Button
                className="button-primary large ml-2"
                onClick={primaryClick}
                disabled={primaryDisabled || isLoading}
                loading={isLoading}
              >
                {actionText || 'Cancel'}
              </Button>
            )}
            {ctaClick && (
              <Button
                className="button-cta large ml-2"
                onClick={ctaClick}
                disabled={isLoading}
                loading={isLoading}
              >
                {actionText || 'Cancel'}
              </Button>
            )}
            {customButtons && customButtons}
          </div>
        )}
      </div>
      <div className="cerulean-backdrop" onClick={disableOutsideClose ? null : cancelClick} />
      <style jsx>
        {`
          * {
            box-sizing: border-box;
          }
          .modal-head {
            font-family: Avenir Heavy, Avenir-Roman, sans-serif;
            height: 3.5rem;
            min-height: 3.5rem;
            text-align: left;
            padding-right: 3.5rem;
            align-items: center;
            white-space: nowrap;
          }
          .title-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .modal-head[type='text'] {
            text-overflow: ellipsis;
          }
          .cerulean-modal :global(.close-modal) {
            position: absolute;
            right: 1rem;
            top: 0.75rem;
            line-height: 0;
            cursor: pointer;
            fill: ${COLORS.neutralDark200};
          }
          .cerulean-backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: ${COLORS.overlay500};
            z-index: 1009;
            display: ${isOpen ? 'block' : 'none'};
          }
          .modal-bod {
            height: fit-content;
            border-top: 1px solid ${COLORS.neutralLight300};
            overflow: auto;
          }
          .modal-buttons {
            height: 4.5rem;
            justify-content: flex-end;
            border-top: 1px solid ${COLORS.neutralLight300};
          }
          .cerulean-modal {
            border-radius: 6px;
            text-align: left;
            flex-direction: column;
            width: 100vw;
            max-width: 100%;
            max-height: 100vh;
            box-shadow: none;
            position: relative;
            display: ${isOpen ? 'flex' : 'none'} !important;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: ${COLORS.neutralLight50};
            animation-duration: 0.5s;
            animation-name: fade-in;
            height: fit-content;
            z-index: 1050;
          }
          @keyframes fade-in {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @media screen and (min-width: 576px) {
            .cerulean-modal {
              width: 25rem;
              box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.04);
              max-height: calc(100vh - 3rem);
            }
          }
          @media screen and (min-width: 768px) {
            .cerulean-modal {
              width: 40rem;
              max-height: calc(100vh - 3rem);
            }
          }
          @media screen and (min-width: 992px) {
            .cerulean-modal {
              width: ${maxWidth || '56rem'};
              max-height: calc(100vh - 3rem);
            }
          }

          .large {
            width: ${maxWidth || '56rem'};
          }
          .medium {
            width: 40rem;
          }

          .small {
            width: 25rem;
          }

          .mobile {
            width: 100vw;
            max-width: 100%;
            box-shadow: none;
          }
        `}
      </style>
    </>
  );
};

Modal.propTypes = {
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  cancelClick: PropTypes.func,
  cancelText: PropTypes.string,
  actionText: PropTypes.string,
  destructiveClick: PropTypes.func,
  primaryClick: PropTypes.func,
  primaryDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  ctaClick: PropTypes.func,
  isOpen: PropTypes.bool,
  disableOutsideClose: PropTypes.bool,
  customButtons: PropTypes.any,
  hideCancelButton: PropTypes.bool
};

export default Modal;
