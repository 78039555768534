import { combineReducers } from 'redux-immutable';

export const makeRootReducer = asyncReducers => {
  if (!asyncReducers) {
    return (state, action) => state;
  }

  return combineReducers({
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(store.makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
