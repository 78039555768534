import React from 'react';
import PropTypes from 'prop-types';
import './ImageGrid.scss';

export const ImageGrid = ({ images }) => (
  <div className="component-image-grid">
    {images.map(image => (
      <img src={image.url} alt={image.name} className="image" key={image.url} />
    ))}
  </div>
);

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired
};

ImageGrid.displayName = 'ImageGrid';

export default ImageGrid;
