// eslint-disable-next-line
export const firstNameRegex = /^[^\d!@#\$%\^&\*\(\)\[\]:;",]+$/;
// eslint-disable-next-line
export const lastNameRegex = /^[^\d!@#\$%\^&\*\(\)\[\]:;",]+$/;
// eslint-disable-next-line
export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
export const emailOrEmptyRegex = /^$|^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
export const zipCodeRegex = /^[ a-zA-Z\d-]+$/;
// eslint-disable-next-line
export const usZipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
// eslint-disable-next-line
export const phoneNumberRegex = /^[+]?[\d]+[\d- ]*[\d]+$/;
// eslint-disable-next-line
export const companyNameRegex = /^[^!@#\$%\^&\*\(\)\[\]:;",]+$/;
// eslint-disable-next-line
export const shopifyUrlRegex = /^[A-Za-z0-9_-]+$/;
// eslint-disable-next-line
export const urlRegex = /^$|^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,13}(:[0-9]{1,5})?(\/.*)?$/;
// eslint-disable-next-line
export const whiteSpaces = /\s/g;
// eslint-disable-next-line
export const letterPatern = /\W/g;
// eslint-disable-next-line
export const imagesPattern = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i;
// eslint-disable-next-line
export const imagesPNGPattern = /(https?:\/\/.*\.(?:png))/i;
// eslint-disable-next-line
export const decimalPatern = /^$|^(\d*\.)?\d+$/;
// eslint-disable-next-line
export const pendingTimePatern = /^$|^(\d{0,2}\.)?\d{0,2}$/;
// eslint-disable-next-line
export const singleNumberRegex = /^[0-9]$/;
export const dstStitchCountPattern = /\ST:\s+(\d+)/;
