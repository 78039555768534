import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import {
  selectedGootenProductSelector,
  selectedMappingsSelector,
  storeIdSelector,
  selectedStoreProductSelector
} from '../LinkProductSelectors';

export const rootSelector = state => state.getIn(['hub', 'linkProduct', 'SKUsSelectionView']);

export const gootenProductDetailsSelector = createSelector(
  [selectedGootenProductSelector],
  product => {
    return product && product.get('details')
      ? // TODO make details immutable in components
        product.get('details').product
      : null;
  }
);

export const SKUsSelector = createSelector([selectedGootenProductSelector], product => {
  return product && product.get('variants')
    ? // TODO: variants not immutable - need update in components
      fromJS(product.get('variants').filter(variant => variant.vendors.some(v => v.creatable)))
    : null;
});

export const regionsSelector = createSelector([rootSelector], root => root.get('regions'));

export const selectedRegionIndexSelector = createSelector([rootSelector], root =>
  root.get('selectedRegionIndex')
);

export const selectedSubCategoryIndexSelector = createSelector([rootSelector], root =>
  root.get('selectedSubCategoryIndex')
);

export const selectedSubCategorySelector = createSelector(
  [regionsSelector, selectedRegionIndexSelector, selectedSubCategoryIndexSelector],
  (regions, regionIndex, subCategoryIndex) => {
    return !regions || !regions.size
      ? null
      : regions.get(regionIndex).get('sub-categories').get(subCategoryIndex);
  }
);

export const getSKUsSelectionQuery = createSelector(
  [selectedGootenProductSelector],
  selectedProduct => ({
    productName: selectedProduct.get('name')
  })
);

export const isReadySelector = createSelector([rootSelector], root => root.get('ready'));

export const hashSelector = createSelector([rootSelector], root => root.get('hash'));

export const variantsMappingsSelector = createSelector([rootSelector], root =>
  root.get('variantsMappings')
);

export const canGoNextSelector = createSelector([rootSelector], root => root.get('canGoNext'));

export const regionsActiveSelector = createSelector([rootSelector], root =>
  root.get('regionsActive')
);

export const subCategoriesActiveSelector = createSelector([rootSelector], root =>
  root.get('subCategoriesActive')
);

export const getProductVariantsSelectionQuery = createSelector(
  [storeIdSelector, selectedStoreProductSelector],
  (storeId, selectedStoreProduct) => ({
    storeId,
    productId: selectedStoreProduct.get('id')
  })
);

export const currentVariantIndexSelector = createSelector([rootSelector], root =>
  root.get('currentVariantIndex')
);

export const currentVariantSelector = createSelector(
  [variantsMappingsSelector, currentVariantIndexSelector],
  (mappings, index) => mappings.get(index)
);

export const mappingsProgressSelector = createSelector(
  [rootSelector, selectedMappingsSelector],
  (root, mappings) => {
    return {
      canUpdate: root.get('canUpdateSelection'),
      selected: mappings.filter(m => m.get('uniqueProduct')).size,
      total: mappings.size
    };
  }
);

export const selectedOptionsLabelSelector = createSelector([currentVariantSelector], variant => {
  const options = variant.getIn(['variant', 'options']);
  return options && options.size ? options.map(o => o.get('value')).join('/') : 'SKU';
});
