import { Map } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import EmailCredentials from './EmailCredentials';
import { EmailCredentialsModel } from '../../Models/EmailCredentialsModel';
import { TEST_CONNECTION, UPDATE_EMAILS_DATA } from './EmailCredentialsActions';
import EmailServices from '../EmailServices/EmailServices';
import { initActionNameFor, changeActionNameFor } from '../BaseCard/BaseCardActions';
import TestConnection from './components/TestConnection';

const initAction = initActionNameFor(EmailServices.name);

const changeAction = changeActionNameFor(EmailCredentials.name);

export default baseCardReducer(
  EmailCredentials.name,
  (
    state = new Map({
      data: new EmailCredentialsModel(),
      loading: true,
      testConnectionStatus: TestConnection.status.none,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case initAction.SUCCESS:
        const data = new EmailCredentialsModel(action.payload.data);
        return state
          .set('data', data)
          .set('unmodified', data)
          .set('logo', action.payload.data.logo)
          .set('loading', false);
      case UPDATE_EMAILS_DATA:
        return state
          .update('data', data => data.set('MailMethod', action.payload.mailMethod))
          .update('unmodified', data => data.set('MailMethod', action.payload.mailMethod))
          .update('data', data => data.set('OrdersEmail', action.payload.ordersEmail))
          .update('unmodified', data => data.set('OrdersEmail', action.payload.ordersEmail))
          .update('data', data => data.set('ShippingEmail', action.payload.shippingEmail))
          .update('unmodified', data => data.set('ShippingEmail', action.payload.shippingEmail));
      case TEST_CONNECTION.ASYNC:
        return state.set('busy', true).set('testConnectionStatus', TestConnection.status.testing);
      case TEST_CONNECTION.FAIL:
        return state
          .set('busy', false)
          .set('testConnectionStatus', TestConnection.status.failed)
          .set('testConnectionError', action.payload.error);
      case TEST_CONNECTION.SUCCESS:
        return state.set('busy', false).set('testConnectionStatus', TestConnection.status.success);
      case changeAction:
        return state.set('testConnectionStatus', TestConnection.status.none);
      default:
        return state;
    }
  },
  EmailCredentialsModel
);
