import { combineReducers } from 'redux-immutable';
import SearchBarReducer from './atoms/SearchBar/SearchBarReducer';
import * as CategoriesNavReducers from './atoms/CategoriesNav/CategoriesNavReducers';
import * as ProductDetailsReducers from './atoms/ProductDetails/ProductDetailsReducers';
import * as PricingModalReducers from './atoms/PricingModal/PricingModalReducers';
import { RESET } from '../../store/actions/globalActions';

// Reducer is a pure function
// DO NOT: Mutate its arguments;
// DO NOT: Perform side effects like API calls and routing transitions;
// DO NOT: Call non-pure functions, e.g. Date.now() or Math.random().

const combinedReducer = combineReducers({
  search: SearchBarReducer,
  selectedCategory: CategoriesNavReducers.selectedCategoryReducer,
  selectedSubcategory: CategoriesNavReducers.selectedSubCategoryReducer,
  hoveredCategory: CategoriesNavReducers.hoveredCategoryReducer,
  forceScrollToSelectedSubCategory: CategoriesNavReducers.forceScrollReducer,
  sidebar: ProductDetailsReducers.sidebarReducer,
  pricingModal: PricingModalReducers.pricingModelReducer
});

// make root reducer
export default (state, action) => {
  if (action.type === RESET) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
