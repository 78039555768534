import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  FETCH_USER_DATA,
  FETCH_STORES,
  VALIDATE_CSV,
  PUBLISH_CSV,
  LOAD_CSV,
  loadCsvFail,
  loadCsvSuccess,
  FETCH_HISTORY,
  fetchHistorySuccess
} from './StoreBulkCreationActions';
import storesService from '../../Hub/HubView/components/Stores/StoresService';
import adminApiService from '../../services/adminApiService';
import service from '../StoreBulkService';
import { CSVTextToObject } from '../Shared/CSVHelpers';

function* handleFetchUserEmailAsync(action) {
  try {
    const resp = yield call(adminApiService.get, `UsersApi/Details/0`);
    const emailAddress = resp.EmailAddress;

    yield put({ type: FETCH_USER_DATA.SUCCESS, payload: { ...action.payload, emailAddress } });
  } catch (err) {
    yield put({
      type: FETCH_USER_DATA.FAIL,
      payload: { ...action.payload, err: 'Failed to fetch email address.' }
    });
  }
}

function* handleValidateCsvData(action) {
  try {
    var response = yield call(
      [service, service.bulkCreationValidateCsv],
      action.payload.partnerId,
      action.payload.provider,
      action.payload.storeName,
      action.payload.csv
    );
    yield put({
      type: VALIDATE_CSV.SUCCESS,
      payload: { ...action.payload, response: response }
    });
  } catch (err) {
    yield put({
      type: VALIDATE_CSV.FAIL,
      payload: { ...action.payload, err: 'Failed to validate cvs data.' }
    });
  }
}

function* handleFetchStoresAsync(action) {
  try {
    const partnerId = action.payload;
    const resp = yield call(
      adminApiService.get,
      `ShopifyAccounts/GetPartnerData?partnerId=${partnerId}`
    );

    const args = {
      recipeId: resp.RecipeKey,
      apiKey: resp.PrivateBillingKey
    };

    const stores = yield call(
      [storesService, storesService.getUserStoresByParams],
      args.recipeId,
      args.apiKey
    );

    yield put({ type: FETCH_STORES.SUCCESS, payload: { ...action.payload, stores } });
  } catch (err) {
    yield put({
      type: FETCH_STORES.FAIL,
      payload: { ...action.payload, err: 'Failed to fetch stores for Partner Id.' }
    });
  }
}

function* handlePublishCsv(action) {
  try {
    const userData = yield call(adminApiService.get, `UsersApi/Details/0`);
    const emailAddress = userData.EmailAddress;

    const response = yield call(
      [service, service.bulkCreationPublishCsv],
      action.payload.partnerId,
      emailAddress,
      action.payload.provider,
      action.payload.storeName,
      action.payload.publishToStore,
      action.payload.csv
    );
    yield put({
      type: PUBLISH_CSV.SUCCESS,
      payload: { ...action.payload, response: response }
    });
  } catch (err) {
    yield put({
      type: PUBLISH_CSV.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to publish csv data.' }
    });
  }
}

function* handleLoadCsvAsync(action) {
  try {
    const data = CSVTextToObject(action.payload);
    yield put(loadCsvSuccess(data));
  } catch {
    yield put(loadCsvFail('Failed to parse CSV data.'));
  }
}

function* handleFetchHistory(action) {
  try {
    const history = yield call([service, service.bulkCreationFetchHistory]);
    yield put(fetchHistorySuccess(history));
  } catch (err) {
    yield put({
      type: FETCH_HISTORY.FAIL,
      payload: { ...action.payload, errorMsg: 'Failed to fetch history.' }
    });
  }
}

export function* watchFetchStores() {
  yield takeLatest(FETCH_STORES.ASYNC, handleFetchStoresAsync);
}

export function* watchFetchUserEmail() {
  yield takeLatest(FETCH_USER_DATA.ASYNC, handleFetchUserEmailAsync);
}

export function* watchValidateCsvData() {
  yield takeLatest(VALIDATE_CSV.ASYNC, handleValidateCsvData);
}

export function* watchPublishCsv() {
  yield takeLatest(PUBLISH_CSV.ASYNC, handlePublishCsv);
}

export function* watchLoadCsv() {
  yield takeLatest(LOAD_CSV.ASYNC, handleLoadCsvAsync);
}

export function* watchFetchHistory() {
  yield takeLatest(FETCH_HISTORY.ASYNC, handleFetchHistory);
}

export default function* rootSaga() {
  yield all([
    watchFetchUserEmail(),
    watchFetchStores(),
    watchValidateCsvData(),
    watchPublishCsv(),
    watchLoadCsv(),
    watchFetchHistory()
  ]);
}
