import { Map, List } from 'immutable';
import baseCardReducer from '../BaseCard/BaseCardReducer';
import PendingInvitations from './PendingInvitations';
import { PendingInvitationsModel } from '../../Models/PendingInvitationsModel';
import { RESEND_INVITE, REMOVE_INVITE } from './PendingInvitationsActions';
import YourTeam from '../YourTeam/YourTeam';
import { initActionNameFor } from '../BaseCard/BaseCardActions';

// Information for pending invitations comes together with your team memners
const initYourTeam = initActionNameFor(YourTeam.name);

export default baseCardReducer(
  PendingInvitations.name,
  (
    state = new Map({
      data: new List(),
      loading: true,
      busy: false,
      open: true
    }),
    action
  ) => {
    switch (action.type) {
      case initYourTeam.ASYNC:
        return state.set('busy', true);
      case initYourTeam.SUCCESS:
        return state
          .set(
            'data',
            new List(action.payload.data.PendingInvites.map(i => new PendingInvitationsModel(i)))
          )
          .set('busy', false)
          .set('loading', false);
      case REMOVE_INVITE.ASYNC:
      case RESEND_INVITE.ASYNC:
        return state.set('busy', true);
      case REMOVE_INVITE.FAIL:
      case RESEND_INVITE.FAIL:
      case RESEND_INVITE.SUCCESS:
        return state.set('busy', false);
      case REMOVE_INVITE.SUCCESS:
        return state.set('busy', false).set(
          'data',
          state.get('data').filter(i => i.get('Id') !== action.payload.get('Id'))
        );
      default:
        return state;
    }
  },
  PendingInvitationsModel
);
